<template>
  <div
    :class="{'menu-item-shrink':!sidebar.opened}"
    class="menu-item"
    v-if="show"
    v-on:keydown.ctrl.prevent="handleClick(route.path,route.children)"
  >
    <el-submenu :index="route.path" popper-append-to-body v-if="route.children&&route.children.length>0">
      <template #title>
        <span :class="`iconfont ${route.meta.icon}`" class="icon" v-show="route.meta.icon&&route.meta.icon!='none'"></span>
        <span v-show="sidebar.opened||mobile">{{route.meta.title}}</span>
      </template>
      <v-item :key="it.path" :route="it" v-for="it in route.children"></v-item>
    </el-submenu>
    <el-menu-item :index="route.path" @click="handleAddTagsView(route)" v-else>
      <span :class="`iconfont ${route.meta.icon}`" class="icon" v-show="route.meta.icon&&route.meta.icon!='none'"></span>
      <template #title>
        <span class="title">{{route.meta.title}}</span>
      </template>
    </el-menu-item>
  </div>
</template>

<script lang="ts">
import { appStore } from '@/store/modules/app'
import { computed, defineComponent, toRef } from 'vue'
import type { AppRouteRecordRawChildren } from '@/router/types'
import { useRoute, useRouter } from 'vue-router'
import { TagsViewStore } from '@/store/modules/tagsView'
export default defineComponent({
  name: 'v-item',
  props: ['route', 'mobile'],
  setup(props) {
    const route = toRef(props, 'route')
    const router = useRouter()
    const _route = useRoute()
    // const ctrlFlag = ref(false);
    const sidebar = computed(() => appStore.getSidebar)
    const show = computed(() => !route.value.hidden && route.value.meta)
    const toPath = (url: string) => {
      console.log('url :>> ', url)
      // router.push(url);
    }
    const handleClick = (path: string, children: any) => {
      console.log('path,children :>> ', path, children)
      if (children) return
      const routeData = router.resolve({
        path,
      })
      window.open(routeData.href, '_blank')
    }
    const handleAddTagsView = ({ name, path, meta }: any) => {
      if (path == _route.path) return
      TagsViewStore.addTages({ name, path, meta })
    }
    return {
      show,
      sidebar,
      toPath,
      handleClick,
      handleAddTagsView,
    }
  },
})
</script>

<style lang="scss" scoped>
.menu-item {
  .icon {
    margin-right: 16px;
  }
}
</style>
