<!--开启愿意归还的，后来没有归还-->
<template>
  <div style="height: 450px; margin-left: 24px; margin-top: 12px;">
    <el-steps direction="vertical" :active="0">
        <el-step title="归还" description=""></el-step>
        <el-step title="第9天短信" description="未通知"></el-step>
        <el-step title="发起扣费"></el-step>
        <el-step title="支付成功" description=""></el-step>
        <el-step title="订单完结" ></el-step>
    </el-steps>
  </div>
</template>
<script  lang='ts'>
import { ref,reactive,defineComponent} from 'vue'
export default defineComponent({
})
</script>
<style scoped lang='scss'>
</style>