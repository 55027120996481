<!--第三方投诉查询-->
<template>
    <div class="container">
    <header>
      <!-- <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="searchValue"
      >
      <template #prepend>
          <el-select @change="changSearch()" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input> -->
      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="结束时间"
        range-separator="至"
        start-placeholder="开始时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.timeBN"
      ></el-date-picker>
    </header>
      <el-tabs v-model="activeName" style="margin-top: 16px;" @tab-click="handleClick" >
       
        <el-tab-pane label="微信" name="wechat">
         
        </el-tab-pane>
        <el-tab-pane  label="支付宝" name="alipay">
          
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, readonly, inject, onMounted } from 'vue'

  export default defineComponent({
    components: {

    },
    setup() {
      const activeName = ref("wechat")
      const searchValue = ref<any>("");
      const searchSelect = ref({ search: 'name' })
      const alipayRef = ref<any>("")
      const wechatRef = ref<any>("")
      const mixin_router_search = ref({
        timeBN:''
      });
      const mixin_select_search = ref({
        search: {   
        },
      })
      const mixin_select_search_label = readonly({
        search: {
        },
      })
  
      const handleClick = () => {
        // if(activeName.value=='alipay'){
        //   alipayRef.value.reData(searchValue.value)
        // }else{
        //   wechatRef.value.reData(searchValue.value)
        // }
      }
  
      const handelChange = async() => {
        // if(activeName.value=='alipay'){
        //   alipayRef.value.reData(searchValue.value)
        // }else{
        //   wechatRef.value.reData(searchValue.value)
        // }
      }
  
      const changSearch = () => {
      
      }
      onMounted(() => {
        // if(activeName.value=='alipay'){
        //   alipayRef.value.reData(searchValue.value)
        // }else{
        //   wechatRef.value.reData(searchValue.value)
        // }
      })
      return {
        searchSelect,
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,
        onMounted,
        activeName,
        searchValue,
        alipayRef,
        wechatRef,
        handleClick,
        handelChange,
        changSearch
      }
    }
  })
  </script>
  <style lang="scss" scoped>
  .container {
    padding: 18px;
    .input-with-select {
      margin-right: 24px;
      width: 320px;
      /deep/.el-input {
        width: 120px;
      }
      ~ * {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
  
     .el-select{
        width: 130px;
      }
  }
  </style>