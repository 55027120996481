
import { defineComponent, ref } from "vue";
import { detaildModel,getInAndOutDetails,exportInAndOutDetails} from "@/api/Financial/inAndOutTotalMoney";
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
let valueType='';
let name='';
let rechargemode='';
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog
   },
   setup() {
    const title = ref('详情');
    const visible = ref(false);
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const activeName = ref('sharedorderrefund')
    const newTotalMoney = ref(0);
    const oldTotalMoney = ref(0);
    const selNewTotalMoney = ref(0);
    const selOldTotalMoney = ref(0);
    const exportDialogRef = ref<any>();
    const list = ref<detaildModel[]>([]);
    const search = ref({
      timeBN:'',
      fortest:false
    });
    const open = (_type:string,_name:string,_rechargemode:string) => {
      console.log('开启弹窗')
      visible.value = true;
      valueType=_type;
      //valueType="refund";
      rechargemode=_rechargemode;
      newTotalMoney.value=0;
      oldTotalMoney.value=0;
      selNewTotalMoney.value=0;
      selOldTotalMoney.value = 0;
      list.value=[];
      if(_name!=null){
        name=_name
        title.value=_name+"详情"
      }
      search.value.timeBN='';
      search.value.fortest=false;
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsTotal();
      getStatisticsList();
    };

    const getStatisticsTotal = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        // let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,rechargemode:rechargemode,type:valueType};
        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        // }
        // const res = await getInAndOutDetails(rqData);
        // totalInfo.value = res;
        // profitTotalInfo.value=res.alllist[0];
        // profitTimeInfo.value=res.list[0];
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const getStatisticsList = async() => {
      
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,type:valueType,refund:activeName.value};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getInAndOutDetails(rqData);
        console.log('详情信息=',res)
        total.value=res.data.total
        list.value=res.data.records
        newTotalMoney.value=res.data.alllist[0]?res.data.alllist[0].newmoney:0;
        oldTotalMoney.value=res.data.alllist[0]?res.data.alllist[0].oldmoney:0;
        selNewTotalMoney.value=res.data.sectionlist[0]?res.data.sectionlist[0].newmoney:0;
        selOldTotalMoney.value = res.data.sectionlist[0]?res.data.sectionlist[0].oldmoney:0;
        // list.value.map(v => {
        //   v.creationtime = dayjs(parseInt(v.creationtime!)).format("YYYY-MM-DD HH:mm:ss")
        //   return v
        // })
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }

    const handleExport = async() =>{
      exportDialogRef.value.open(title.value)
    }
    const exportFile = async(name:string) =>{
      try {
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,type:valueType,refund:activeName.value,fileName:title.value};
       
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        const res = await exportInAndOutDetails(rqData)

        const blob = new Blob([res.data]);
        saveAs(blob, name)
      } finally {

      }
    }


    return {
      title,
      visible,
      page,
      total,
      limit,
      list,
      search,
      activeName,
      newTotalMoney,
      oldTotalMoney,
      selNewTotalMoney,
      selOldTotalMoney,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
