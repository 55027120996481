<template>
  <el-dialog :destroy-on-close="true" :title="`更新小魔夹广告`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData"   label-width="120px" ref="formRef">
      <el-form-item label="小魔夹SN" class="tip-text" prop="sn">
        <el-input v-model="inputData.sn" style="width: 220px;" :disabled="true" ></el-input>
      </el-form-item>
      <el-form-item label="广告类型" >
        <el-select  placeholder="请选择广告" v-model="inputData.adType">
          <el-option :key="item.adType" :label="item.name" :value="item.adType" v-for="item of adInfoList"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { changeXMJAdHttp } from '@/api/WarehouseManagement/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { getAdInfoList } from '@/api/util'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const adInfoList = getAdInfoList()

    const inputData = ref({
      sn: '',  
      adType:'0'//广告类型
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:any) => {
      visible.value = true
      inputData.value.sn=item.equipmentSn;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        const res = await changeXMJAdHttp(inputData.value)      
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      adInfoList
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>