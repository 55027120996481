
import { defineComponent, ref, readonly, inject } from 'vue'
import { investorProfitDeviceModel, investorProfitDeviceListHttp } from '@/api/Profit/device'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'investor-profit-device',
  components: {

  },
  data() {
    return {
        shortcuts: [{
                text: '今天',             
                value: (() => {
                    const date = new Date()
                    console.log(dayjs(date.getTime()).format("YYYY-MM-DD"))
                    return date.getTime()
                })(),
            }, {
                text: '昨天',
                 value: (() => {
                    const date = new Date()
                    return date.getTime() - 3600 * 1000 * 24
                })(),
            }, {
                text: '前天',
                value: (() => {
                    const date = new Date()
                    return date.getTime() - 3600 * 1000 * 48
                })(),
            }
        ]
    }
},
  setup() {
   

    const mixin_router_search = ref({
      fortest: 'false', //
      userid:userStore.userInfo.userid,
      productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4',
      datestr:'',
    })
    const mixin_select_search = ref({
      search: {
        sn:'', //小魔夹SN
          
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        sn: '编号', // 投资人用户编号
      },
    })
    const searchSelect = ref({})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const dateType = ref('0'); //日期选择类型 0年 1月 2日
    const yearValue = ref<any>();
    const monthValue = ref<any>();
    const dayValue = ref<any>();
    const list = ref<investorProfitDeviceModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { currentPage: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
        //   if (rq.order) {
        //     const { order, sort } = rq
        //     delete rq.order
        //     delete rq.sort
        //     rqData = { ...rqData, order, sort }
        //   }
          rqData = { ...rqData,  ...rq }
        }
        const res = await investorProfitDeviceListHttp(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
        if(dateType.value=='0'){
            mixin_router_search.value.datestr=dayjs(yearValue.value).format("YYYY")
        }else if(dateType.value=='1'){
            mixin_router_search.value.datestr=dayjs(monthValue.value).format("YYYY-MM")
        }else if(dateType.value=='2') {
            mixin_router_search.value.datestr=dayjs(dayValue.value).format("YYYY-MM-dd")
        }else{
           mixin_router_search.value.datestr=''
        }
      mixinReRouter()
    }
   
    const handelDateType = () =>{
        if(dateType.value=='0'){
            mixin_router_search.value.datestr=dayjs(yearValue.value).format("YYYY")
        }else if(dateType.value=='1'){
            mixin_router_search.value.datestr=dayjs(monthValue.value).format("YYYY-MM")
        }else if(dateType.value=='2') {
            mixin_router_search.value.datestr=dayjs(dayValue.value).format("YYYY-MM-dd")
        }else{
           mixin_router_search.value.datestr=''
        }
        handelChange();
    }

    const handleDetail = (item:any) =>{
      
    }
    



   
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      list,
      loading,
      dateType,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      handleDetail,
      handelDateType,
      yearValue,
      monthValue,
      dayValue,
    }
  },
})
