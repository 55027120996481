
import {
  SharedorderprivatizationdeductionList,
  sharedorderprivatizationreturnConfirm,
} from '@/api/Order/Privatization'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<SharedorderprivatizationdeductionList>()
    const inputData = ref({
      day: 0,
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: SharedorderprivatizationdeductionList) => {
      row.value = _row
      visible.value = true
      inputData.value.day = Math.ceil(
        dayjs().diff(_row.receivedate, 'day', true)
      )
    }
    const submit = async () => {
      if (!row.value) return
      try {
        btnSumitLoading.value = true
        const rentdays = inputData.value.day - row.value.renewaltotaldays
        const res = await sharedorderprivatizationreturnConfirm({
          negotiationid: row.value.negotiationid,
          paymentamount: rentdays * 3,
          rentdays,
        })
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      row,
      submit,
      inputData,
    }
  },
})
