<template>
  <el-dialog :destroy-on-close="true" :title="name+`担保记录`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="65%">
    <div class="container">
      <header>
        <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="search">
          <template #prepend>
            <el-select @change="changSearch" placeholder="请选择" v-model="searchSelect.search">
              <el-option :key="item" :label="select_search_label.search[item]" :value="item" v-for="item of Object.keys(select_search.search)"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="handelChange" icon="el-icon-search"></el-button>
          </template>
        </el-input>
        <el-select @change="handelChange" clearable placeholder="担保类型" class="header-el-selector" v-model="guaranteetype">
          <el-option value="BALANCE" label="余额担保"></el-option>
          <el-option value="ZHIMA" label="芝麻信用"></el-option>
          <el-option value="PAYSCORE" label="微信支付分"></el-option>
        </el-select>
        <el-select @change="handelChange" clearable placeholder="状态" class="header-el-selector" v-model="enabled">
          <el-option :value="true" label="已确认/已使用"></el-option>
          <el-option :value="false" label="未确认/已完成"></el-option>
        </el-select>
      </header>
      <el-table 
        :data="list" 
        height="560px" 
        style="width: 100%" 
        v-if="list" >
        <el-table-column type="expand">
          <template #default="scope">
            <el-form class="table-expand" inline label-position="left" label-width="120px">
              <el-form-item label="担保订单号">
                {{scope.row.guaranteeno}}
                <my-copy :data="scope.row.guaranteeno" />
              </el-form-item>
              <el-form-item label="用户ID">
                <my-click-data-to url="/user" :data="scope.row.userid" :params="[{search_userid:scope.row.userid}]"></my-click-data-to>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="left" label="用户账号" min-width="49">
          <template #default="scope">{{scope.row.account}}
            <my-copy :data="scope.row.account" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单号" min-width="55">
          <template #default="scope">
            <my-click-data-to url="/order/userorder" :data="scope.row.sharedorderid" :params="[{search_sharedorderid:scope.row.sharedorderid}]"></my-click-data-to>
          </template>
        </el-table-column>
        <el-table-column label="担保类型" min-width="30">
          <template #default="scope">
            <color-icon :type="`icon-${scope.row.guaranteetype}`" style="font-size:40px;color:#50b674"></color-icon>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否确认">
          <template #default="scope">
            <div v-if="scope.row.enabled">
              <el-tag v-if="scope.row.sharedorderid" type="waring">已使用</el-tag>
              <el-tag v-else type="primary"> 已确认</el-tag>
            </div>
            <div v-else>
              <el-tag v-if="scope.row.sharedorderid" type="success"> 已完成</el-tag>
              <el-tag v-else type="info"> 已创建</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" min-width="48" prop="creationdate" sortable="custom"> </el-table-column>
        <el-table-column align="center" label="更新时间" min-width="48" prop="modificationdate"> </el-table-column>

        <el-table-column align="center" label="操作" min-width="50">
          <template #default="scope">
            <el-button @click="handleaToClose(scope.index,scope.row)" :disabled="!scope.row.enabled" size="mini" type="danger" :loading="btncloseLoading">强制关闭</el-button>
          </template>
        </el-table-column>
        </el-table>
        <el-pagination 
          :current-page="page" 
          :page-size="limit" 
          :page-sizes="[10, 30, 50, 100]" 
          :total="total" 
          @current-change="onPageChange" 
          @size-change="handleLimitChange" 
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </div>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, readonly, inject } from "vue";
import {
  userguaranteeorderList,
  UserguaranteeorderList,
  wechatpayscoreorderComplete,
  alipayfundauthorderComplete,
} from "@/api/Order/Guarantee";
import { ElMessage, ElMessageBox } from "element-plus";
let userId = ''
let guaranteeId = ''
let searchTitle='guaranteeno'
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const visible = ref(false)
    const list = ref<any[]>([])
    const name = ref("");
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const guaranteetype = ref("");
    const loading = ref(false);
    const btncloseLoading = ref(false);
    const enabled = ref("");
    const search = ref("");
    const searchSelect = ref({ search: 'guaranteeno' })
    const select_search = ref({
      search: {
        sharedorderid: "",
        guaranteeno: "",
      }
    });
    const select_search_label = readonly({
      search: {
        sharedorderid: "订单号",
        guaranteeno: "担保订单号",
      }
    });
    const changSearch = (type:string) => {
        searchTitle=type;
    }
    const handleClose = () => {
      visible.value = false
    }
    const open = (_userId: string,_name: string,_orderId:string,_guaranteeId:string) => {
      visible.value = true
      userId = _userId
      name.value=_name;
      reData()
    }
    const reData = async () => {
    
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value,order:"creationdate",sort:"desc" };
        let entity :any ={ userid:userId};
        if(guaranteeId!==null&&guaranteeId!==""){
            entity.guaranteeno=guaranteeId;
        }
        if(guaranteetype.value!==null&&guaranteetype.value!==""){
            entity.guaranteetype=guaranteetype.value;
        }
        if(enabled.value!==null&&enabled.value!==""){
            entity.enabled=enabled.value;
        }
        if(search.value!=null&&search.value!==""){
           entity[searchTitle]=search.value
        }
        rqData={...rqData,entity:{...entity}}
        const res = await userguaranteeorderList(rqData);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    }
    

    const handelChange = (type:string) => {
      console.log('改变='+type); 
      page.value=1;
      reData();
    }

    const onPageChange = (val:number) => {
      page.value=val;
      reData();
    }

    const handleLimitChange = (val:number) =>{
      limit.value=val;
      reData();
    }

    const handleaToClose = async(index:number,item:UserguaranteeorderList) =>{
      try {
        await ElMessageBox.confirm(`是否强制关闭此支付分订单？`, `请确认`, {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        btncloseLoading.value = true;
        if (item.guaranteetype == "ZHIMA") {
          await alipayfundauthorderComplete(item.guaranteeno);
        } else {
          await wechatpayscoreorderComplete(item.guaranteeno);
        }
        ElMessage.success("关闭担保订单成功");
      } finally {
        btncloseLoading.value = false;
      }
    }

    return{
      visible,
      name,
      handleClose,
      open,
      list,
      page,
      total,
      limit,
      enabled,
      search,
      searchSelect,
      select_search,
      select_search_label,
      handelChange,
      loading,
      btncloseLoading,
      reData,
      changSearch,
      guaranteetype,
      onPageChange,
      handleLimitChange, 
      handleaToClose,
    }
  }
})    
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>