import FileSaver from "file-saver";
import Excel from "exceljs";
import store from '../store'
import dayjs from "dayjs"
const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// let workbook, worksheet; // excel 变量
export default class myExcelExport {
    worksheet: Excel.Worksheet | null = null;
    workbook: Excel.Workbook | null = null;
    title = ""
    list: any[] = []
    constructor(title: string) {
        this.workbook = new Excel.Workbook();
        this.title = title
        this.worksheet = this.workbook.addWorksheet(title)
        this.workbook.creator = store.getters.nickname;
        this.workbook.lastModifiedBy = store.getters.nickname;
        this.workbook.created = new Date();
        this.workbook.modified = new Date();
        this.workbook.views = [{
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: "visible",
        },];
        return this
    }
    addWorksheet({
        headers,
        list
    }: { headers: Excel.CellValue[], list: any[] }): void {
        if (!this.worksheet) throw Error("初始化Excel失败")
        const _colomns = [];
        const _header = [];
        this.list = list
        for (const key of Object.keys(list[0])) {
            _header.push(key);
            _colomns.push({
                key,
                width: 20
            });
        }
        this.worksheet.getRow(1).values = headers; //设置header
        this.worksheet.getRow(2).values = _header; //设置header
        this.worksheet.columns = _colomns as Excel.Column[]; // 设置列
        list.map(v => {
            this.worksheet?.addRow(v);
        });
        // console.log('this.worksheet :>> ', this.worksheet);
        this.worksheet.eachRow(function (Row, rowNum) {
            // 遍历所有表格
            Row.eachCell(function (Cell, cellNum) {
                // 获取单元格
                Cell.alignment = {
                    // 设置单元格对齐属性
                    vertical: "middle",
                    horizontal: "center",
                };
                Cell.border = {
                    // 设置单元格边框
                    top: {
                        style: "thin"
                    },
                    left: {
                        style: "thin"
                    },
                    bottom: {
                        style: "thin"
                    },
                    right: {
                        style: "thin"
                    },
                };
            });
        });
    }
    async export(mergeCells: any[] = []): Promise<void> {
        try {
            if (!this.workbook) throw Error("初始化Excel失败")
            const len = Object.keys(this.list[0]).length - 1 // 拿到长度 -1
            const lens = ~~(len / 26) // 确保是否超过26
            const end = len % 26  // 得到末尾数值
            const L = lens > 0 ? this.getRowNum(lens) + this.getRowNum(end) : this.getRowNum(end) // 得到末尾字母
            mergeCells = [`A1:${L}1`, ...mergeCells]  // 开始合并
            mergeCells.map(v => {
                this.worksheet?.mergeCells(v); // 合并单元格
            })
            const buff = await this.workbook.xlsx.writeBuffer(); //将工作部输出到buffer
            const blob = new Blob([buff], {
                type: EXCEL_TYPE
            });
            // Given name
            FileSaver.saveAs(
                blob,
                `${this.title}-${dayjs().format("YYYY_MM_DD HH_mm_ss")}.xlsx`
            );
        } catch (error) {
            console.log('export error :>> ', error);
        }
    }
    getRowNum(i: number) {
        return String.fromCharCode(i + 65)
    }
}