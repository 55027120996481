import { ElMessage } from "element-plus"
import { isObject } from "@/utils/is"


export function getErrMessage(e: any): string {
    let msg = ''
    switch (true) {
        case !!e.message:
            msg = e.message
            break;
        case !!e.msg:
            msg = e.msg
            break;
        case !!e.errMsg:
            msg = e.errMsg
            break;
        default:
            msg = e
            break;
    }
    console.log('e :>> ', e);
    return JSON.stringify(msg)
}

export function showErrorMessage(e: any): void {
    ElMessage({
        message: getErrMessage(e),
        type: 'error'
    })
}
/**
 * 
 * @param data request返回的东西，判断请求是否确认
 */
export function requestError(res: any): Promise<any> {
    return new Promise((resolve, reject) => {
        switch (true) {
            // #ifdef MP-WEIXIN                        
            case res.status === 500:
                reject(res.status)
                break
            // #endif
            case res.status != 200: // 其他的状态不等于200的全部拒绝
                reject(res)
                break
            case Boolean(res.data) && isObject(res.data)    : // 存在data 的
               if(res.data.code==0){
                   resolve(res.data)
               }else if (res.data.code) {
                    switch (res.data.code) {                   
                        case 200:  
                        case 500:                    
                        case "10000":
                            resolve(res.data)
                            break;                       
                        default:
                            reject(res.data)
                            break;
                    }
                } else if (res.data.result && res.data.result !== 'false') { // 列表
                    resolve(res.data)
                } else if (res.data.success) { // midway 请求成功
                    resolve(res.data)
                } else if (res.data.token) { // 登录成功
                    resolve(res.data)
                } else if (res.data.num !== undefined && res.data.num !== null) { // 获取当前订单数量
                    resolve(res.data.num)
                }
                // #ifdef MP-WEIXIN                                
                else if (res.data.out_order_no) { //支付分授权成功
                    resolve(res.data)
                }
                // #endif
                // #ifdef MP-WEIXIN
                else if (res.data.openid) { // wx获取openid
                    resolve(res.data)
                }
                // #endif 
                else {
                    reject(res.data)
                }
                break;
            case res.errMsg == 'request:ok':
                if (res.data.result && res.data.result !== 'false') {
                    resolve(res.data)
                }
                else {
                    reject(res.data)
                }
                break;
          
            default:
                reject(res.data)
                break;
        }
    })
}