<template>
  <div class="container">
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <!-- <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item> -->
            <el-form-item label="优惠卷id">
              <span>{{ scope.row.id }}</span>
              <my-copy :data="scope.row.id" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="名称" min-width="40">
        <template #default="scope">
           {{scope.row.title}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="描述" min-width="60">
        <template #default="scope">
            {{scope.row.desc}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="金额" min-width="30">
        <template #default="scope">
          {{scope.row.usedAmount}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="30">
        <template #default="scope">
          <el-tag type="warning"  v-if="scope.row.usedStatus=='0'">待发放</el-tag>
          <el-tag type="warning" v-if="scope.row.usedStatus=='1'">待领取</el-tag>
          <el-tag type="info" v-if="scope.row.usedStatus=='2'">待激活</el-tag>
          <el-tag type="info" v-if="scope.row.usedStatus=='3'">未使用</el-tag>
          <el-tag type="success" v-if="scope.row.usedStatus=='4'">已使用</el-tag>
          <el-tag type="danger" v-if="scope.row.usedStatus=='5'">已失效</el-tag>
          <el-tag type="danger" v-if="scope.row.usedStatus=='6'">已过期</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="生效时间" min-width="40">
        <template #default="scope">
          {{scope.row.validStartTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="到期时间" min-width="40">
        <template #default="scope">
          {{scope.row.validEndTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="领取时间" min-width="40">
        <template #default="scope">
          {{scope.row.receiveTime?scope.row.receiveTime:"未领取"}}
        </template>
      </el-table-column>
      
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { CouponModel, getUserCouponList } from "@/api/Financial/coupon";
export default defineComponent({
  setup() {
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const usedStatus = ref('');
    const list = ref<CouponModel[]>([]);

    const open = () =>{
      console.log("调用打开")
    }

    const reData = async (status:string) => {
      console.log("查询状态=",status)
      usedStatus.value=status
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value,order:'gmtCreate',sort:'desc',usedStatus: status};
        const res = await getUserCouponList(rqData);
        console.log("优惠卷记录 :>> ", res.records);
        list.value = res.rows;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };

    const onPageChange = () =>{

    }
    const handleLimitChange = () =>{

    }
    return {
      list,
      loading,
      page,
      total,
      limit,
      reData,
      open,
      onPageChange,
      handleLimitChange,
    };
  },
})
</script>
<style lang = "scss" scoped >

</style>