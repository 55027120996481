<template>
  <el-dialog
    title="生成新二维码"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-alert
      title="导出二维码，以生成的日期为查询条件，共享和专享的二维码字符串有区别"
      type="warning"
      :closable="false"
      show-icon
      style="margin-bottom: 16px;">
    </el-alert>
    <el-form :model="inputData" :rules="rules" label-width="120px" ref="fromRef">
      <el-form-item label="设备类型" class="tip-text" prop="productid" >
        <el-select  placeholder="请选择设备类型" v-model="inputData.productid">
          <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of productList"></el-option>
        </el-select>
      </el-form-item>
    
      <el-form-item label="生成时间">
        <el-date-picker
          align="right"
          class="header-select"
          end-placeholder="结束时间"
          range-separator="至"
          start-placeholder="开始时间"
          type="datetimerange"
          unlink-panels        
          v-model="inputData.careateBN"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="二维码字符串" >
        <el-radio  v-model="inputData.qrStr" label="xmj">共享(xmj)</el-radio>
        <el-radio  v-model="inputData.qrStr" label="xmjprivate">专享(xmjprivate)</el-radio>
      </el-form-item>
      <el-form-item label="表格名称" prop="content">
        <el-input style="width: 220px;" placeholder="请输入表格名称" v-model="inputData.name"></el-input>
      </el-form-item>
      <el-form-item label="表格类型" >
        <el-radio v-model="inputData.radio" label="1">XLS</el-radio>
        <el-radio v-model="inputData.radio" label="2">XLSX</el-radio>
        <!-- <el-radio v-model="radio" label="3">EXCEL</el-radio> -->
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, nextTick, readonly, ref } from "vue";
import { generateNewSn } from '@/api/Share/Device'
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import {getProductInfoList,getProductIdList} from '@/api/util'
export default defineComponent({
  name: "v-dialog",
  emits: ['export'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const inputData = ref({
      productid: "", 
      qrStr:"xmj",
      careateBN:"",
      name:"二维码数据",
      radio:"1",
    });
    const rules = readonly({
      productid: [Rules.required()],
      careateBN: [Rules.required()],
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
    
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
     
    };
    const open = () => {
      visible.value = true;
      
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        // console.log('生成参数=',inputData.value)
        // const res = await generateNewSn(inputData.value)
        // console.log('返回=',res)
        // handleClose();
        // ElMessage.success(res.message);
        let fileType="";
        if(inputData.value.radio=='1'){
          fileType="xls"
        }else if(inputData.value.radio=='2'){
          fileType="xlsx";
        }else if(inputData.value.radio=='3'){
          fileType="excel";
        }
        emit('export',{productid:inputData.value.productid,
                      type:inputData.value.qrStr,
                      time:inputData.value.careateBN,
                      name:`${inputData.value.name}.${fileType}`})
        handleClose();
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      productList,
      productIdList,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>