<template>
  <el-dialog @closed="close" title="编辑菜单信息" v-if="result" v-model="visible" width="700px">
    <el-form :model="result" :rules="rules" label-width="100px" ref="resultRef">
      <el-form-item label="父级id" prop="name">
        <el-input v-model="result.parenttreeid"></el-input>
      </el-form-item>
      <el-form-item label="菜单标题" prop="name">
        <el-input v-model="result.name"></el-input>
      </el-form-item>
      <el-form-item label="菜单名称">
        <el-input v-model="result.code"></el-input>
      </el-form-item>
      <el-form-item label="链接地址" prop="url">
        <el-input v-model="result.url"></el-input>
      </el-form-item>
      <el-form-item label="路径" prop="path">
        <el-input v-model="result.path"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <el-input v-model="result.icon"></el-input>
      </el-form-item>
      <el-form-item label="序号" prop="order">
        <el-input-number :min="0" label="排序" v-model="result.treeorder"></el-input-number>
      </el-form-item>
      <el-form-item label="是否显示" prop="icon">
        <el-switch active-color="#13ce66" active-value="false" inactive-color="#ff4949" inactive-value="true" v-model="result.hidden"></el-switch>
      </el-form-item>
      <el-form-item label="是否启用" prop="icon">
        <el-switch active-color="#13ce66" active-value="1" inactive-color="#ff4949" inactive-value="0" v-model="result.state"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import Rules from "@/common/Rules";
import { defineComponent, nextTick, ref, toRef } from "vue";
import { treeUpdate, treeSave } from "@/api/System";
import { ElMessage } from "element-plus";
export default defineComponent({
  data() {
    return {
      rules: {
        name: [Rules.required()],
        url: [Rules.required()],
        path: [Rules.required()],
        icon: [Rules.required()],
        address: [Rules.required()],
      },
    };
  },
  props: ["appid"],
  setup(props: any, { emit }) {
    const visible = ref(false);
    const isEdit = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const resultRef = ref<any>();
    const appid = toRef(props, "appid");
    const result = ref({
      name: "",
      url: "",
      path: "",
      icon: "none",
      hidden: "false",
      state: "1",
      treeorder: 0,
      parenttreeid: "",
      code: "",
    });
    const open = (row: any) => {
      isEdit.value = row.isEdit;
      if (isEdit.value) {
        //编辑
        result.value = JSON.parse(JSON.stringify(row));
        if (!row.hidden) result.value.hidden = "false";
      } else {
        //添加
        result.value.parenttreeid = row.parenttreeid;
      }
      visible.value = true;
    };
    const _updateMenu = async () => {
      console.log("result.value :>> ", result.value);
      const _rqData = JSON.parse(JSON.stringify(result.value));
      _rqData.child = _rqData.child.join(",");
      console.log("_rqData :>> ", { ..._rqData, appid: appid.value });
      return await treeUpdate({ ..._rqData, appid: appid.value });
    };

    const _addMenu = async () => {
      return await treeSave({ ...result.value, appid: appid.value });
    };
    const resetData = async () => {
      await nextTick();
      result.value = {
        name: "",
        url: "",
        path: "",
        icon: "none",
        hidden: "false",
        state: "1",
        parenttreeid: "",
        treeorder: 0,
        code: "",
      };
      visible.value = false;
      resultRef.value.clearValidate();
    };

    const submit = async () => {
      loading.value = true;
      try {
        await resultRef.value.validate();
        btnSumitLoading.value = true;
        let res = null;
        if (isEdit.value) {
          res = await _updateMenu();
        } else {
          res = await _addMenu();
        }
        ElMessage.success(res);
        resetData();
        emit("re-data");
      } finally {
        btnSumitLoading.value = false;
      }
    };

    const close = () => {
      resetData();
    };
    return {
      visible,
      result,
      isEdit,
      open,
      submit,
      close,
      resultRef,
      btnSumitLoading,
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
  .el-input{
    width: 312px;
  }
</style>