import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface InvestorListListRes {
    creationdate: string,
    value?: string
    investorname: string
}
export async function investorList(params: ListReq): Promise<ListRes<InvestorListListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<InvestorListListRes>>(
        {
            url: `/investor/list`,
            params
        }
    );
    res.result = res.result.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}
