import { xmj_admin_Http } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
const prefix = '/local/user'
export interface UserListRes {
    creationdate: string;
    userid: string
    account: string
    name: string
    create_time: string
}

// export async function investmentorderList(params: ListReq): Promise<ListRes<UserListRes>> {
export async function investmentorderList(params: ListReq): Promise<any> {
    params.token = true
    const res = await xmj_admin_Http(prefix).request<ListRes<UserListRes>>(
        {
            url: `/list`,
            method: "POST",
            params
        }
    );
    if (res.data.list) {
        res.data.list = res.data.list.map(v => {
            v.create_time = dayjs(v.create_time).format("YYYY-MM-DD HH:mm:ss")
            return v
        })
    }
    return res.data
}

export async function add(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/add`,
            params
        }
    );
    return reData
}
export async function removeUser(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/remove`,
            params
        }
    );
    return reData
}