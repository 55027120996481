<template>
  <el-form inline label-position="left" label-width="auto">
    <el-form-item label="状态">
      <el-tag type="success" v-if="info.returned">{{info.returndate}} 归还</el-tag>
      <el-tag type="danger" v-else>{{info.returnoverduedate}} 超时</el-tag>
    </el-form-item>
    <template v-if="info.returntype=='express'">
      <el-form-item label="快递公司">
        {{info.expressbrand}}
      </el-form-item>
      <el-form-item label="快递单号">
        {{info.expressnum}}
      </el-form-item>
    </template>
  </el-form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
// import { SharedorderprivatizationdeductionList } from "@/api/Order/Privatization";
export default defineComponent({
  name: "return-info",
  props: ["info"],
  // setup(props) {
  //   const info: SharedorderprivatizationdeductionList = toRef(props, "info");
  //   return {
  //     syncInfo: info,
  //   };
  // },
});
</script>
<style lang="scss" scoped>
.el-form {
  width: 500px;
}
</style>