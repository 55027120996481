
import { defineComponent, ref, readonly, inject } from 'vue'
import { repairListModel, repairListHttp, confirmHttp,exportEqmaintainList } from '@/api/fault/repair'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'

import addRepairDialog from './components/addRepairDialog.vue'
import completeRepairDialog from './components/completeRepairDialog.vue'
import replaceSnDialog from './components/replaceSnDialog.vue'
import repairDetailsDrawer from './components/repairDetailsDrawer.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import {getProductInfoList,getProductIdList} from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'fault-order',
  components: {
   addRepairDialog,
   completeRepairDialog,
   replaceSnDialog,
   repairDetailsDrawer,
   exportDialog
  },
  setup() {
    const addRepairDialogRef = ref<any>()
    const detailsDrawerRef = ref<any>()
    const replaceDialogRef = ref<any>()
    const completeDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const mixin_router_search = ref({
      status: '', //状态 0:待入库 1：已入库 2：已出库
      result:'',//维修结果
      careateBN:'',
      order: 'gmtCreate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        inEquipmentSn:'', //设备sn
        inUserAccount:'',//上报手机号    
        outEquipmentSn:'',//上报昵称   
        outUserAccount:'',
      },
    })
 
    const mixin_select_search_label = readonly({
      search: {
        inEquipmentSn: '录入SN', 
        inUserAccount: '录入账号', 
        outEquipmentSn:'完结SN',
        outUserAccount:'完结账号'
      },
    })
    const searchSelect = ref({search: 'inEquipmentSn'})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<repairListModel[]>([])
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.careateBN) {
            rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await repairListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })

    const handelChange = () => {
      console.log('改变回调')
      page.value = 1
      mixinReRouter()
    }

    const handleAdd = async() => {
      addRepairDialogRef.value.open();
    }

    const handleComfirm = async(idx:number,item:any) =>{
      await ElMessageBox.confirm(
        `是否确认接收设备`+item.inEquipmentSn+'的维修单，接收后，设备状态为维修中',
        '接收提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        }
      )
      const res = await confirmHttp(item.id)
      handelChange()
      ElMessage.success(res)
    }

    const handleComplete = async(idx:number,item:any) =>{
       completeDialogRef.value.open(item);     
    }

    const handleReplace = async(idx:number,item:any) =>{
      replaceDialogRef.value.open(item);
       
    }
  
    
    const handleDetails = async(idx:number,item:any) =>{
      detailsDrawerRef.value.open(item)
    }
   
    const handleExport = async() => {
      exportDialogRef.value.open('维修单记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'eqmaintain',order: 'gmtCreate',sort: 'desc'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          searchData.startGmtCreate = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          searchData.endGmtCreate =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportEqmaintainList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }

    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      changSearch,
      list,
      loading,
       page,
      limit,
      total, 
      addRepairDialogRef,
      detailsDrawerRef,
      replaceDialogRef,
      completeDialogRef,      
      exportDialogRef,
      productList,
      productIdList,
      handleAdd,
      handleComfirm,
      handleComplete,
      handleReplace,
      handleDetails,
      handleExport,    
      exportFile 
    }
  },
})
