<template>
  <div class="container">
    <header>
      <!--搜索框-->
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select
            @change="changSearch('search')"
            placeholder="请选择"
            v-model="searchSelect.search"
          >
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select
        @change="handelChange"
        clearable
        placeholder="订单类型"
        v-model="mixin_router_search.fortest"
        class="header-el-selector"
      >
        <el-option :value="true" label="测试订单"></el-option>
        <el-option :value="false" label="正式订单"></el-option>
      </el-select>
      <!-- <el-select
        @change="handelChange"
        clearable
        placeholder="设备类型"
        v-model="mixin_router_search.productModel"
        class="header-el-selector"
      >
        <el-option label="小魔夹MS2" value="MS2"></el-option>
        <el-option label="小魔夹M4S" value="M4S"></el-option>
        <el-option label="雨伞" value="UM01"></el-option>
      </el-select> -->
      <el-select
        @change="handelChange"
        clearable
        placeholder="押金状态"
        v-model="mixin_router_search.deductStatus"
        class="header-el-selector"
      >
        <el-option label="未扣" value="0"></el-option>
        <el-option label="扣款中" value="1"></el-option>
        <el-option label="完成" value="99"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="归还状态"
        v-model="mixin_router_search.userReturnAttitude"
        class="header-el-selector"
      >
        <el-option label="不确定" value="0"></el-option>
        <el-option label="愿意" value="1"></el-option>
        <el-option label="不愿意" value="2"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="投放状态"
        v-model="mixin_router_search.relaunchStatus"
        class="header-el-selector"
      >
        <el-option label="未开始" value="0"></el-option>
        <el-option label="已开始" value="1"></el-option>
        <el-option label="完成" value="99"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="更换状态"
        v-model="mixin_router_search.replaceStatus"
        class="header-el-selector"
      >
        <el-option label="未替换" value="0"></el-option>
        <el-option label="已开始" value="1"></el-option>
        <el-option label="完成" value="99"></el-option>
      </el-select>
      <!-- <el-select
        @change="handelChange"
        clearable
        placeholder="担保类型"
        v-model="mixin_router_search.model"
        class="header-el-selector"
      >
        <el-option label="余额" value="BALANCE"></el-option>
        <el-option label="微信" value="PAYSCORE"></el-option>
        <el-option label="芝麻" value="ZHIMA"></el-option>
      </el-select> -->
      <el-select
        @change="handelChange"
        clearable
        placeholder="处理状态"
        v-model="mixin_router_search.status"
        class="header-el-selector"
      >
        <el-option
          :key="item"
          :label="allStatus[item]"
          :value="item"
          v-for="item of Object.keys(allStatus)"
        ></el-option>
      </el-select>
    </header>
    <el-table
      :data="list"
      :default-sort="{prop: 'gmtCreate', order: 'descending'}"
      @expand-change="handleOpen"
      @sort-change="mixinReRouterTableSortChange"
      height="80vh"
      row-key="sharedOrderId"
      style="width: 100%"
      v-if="list"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="auto">
            <el-form-item label="订单号">
              <my-click-data-to
                :data="scope.row.sharedOrderId"
                :params="[{search_sharedorderid:scope.row.sharedOrderId}]"
                url="/order/userorder"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedOrderId" />
            </el-form-item>
            <el-form-item label="超期id">
              {{scope.row.id}}
            </el-form-item>
            <el-form-item label="扣除押金时间" v-if="scope.row.status<2">
               {{scope.row.deductStartTime}}
                <el-button
                  :loading="reDeductionLoading"
                  @click="handleReDeduction(scope.row)"
                  size="mini"
                  type="warning"
                  v-if="scope.row.status==1&&scope.row.userReturnAttitude==2"
                >修改扣除押金时间</el-button>
            </el-form-item>
            <el-form-item label="上次通知时间">
               {{scope.row.lastRemindTime}}
            </el-form-item>
            <el-form-item label="下次通知时间">
               {{scope.row.nextRemindTime}}
            </el-form-item>
             <el-form-item label="通知次数">
               {{scope.row.remindCount}}
                <el-button  
                  @click="onMsgClick(scope.row)" 
                  type="warning" 
                  style="margin-left: 6px;"
                  size='mini'
                  v-if="scope.row.status==1&&scope.row.remindCount>0&&scope.row.remindCount<3">再次通知</el-button>
            </el-form-item>
            
            <!-- <el-form-item label="沟通进程" v-if="scope.row.negotiationInfo">
              <negotiation-info :info="scope.row.negotiationInfo" :key="scope.row.sharedorderid"></negotiation-info>
            </el-form-item>
            <el-form-item
              label="归还记录"
              v-if="scope.row.returnInfo&&scope.row.negotiationInfo?.willreturn">
              <retrun-info :info="scope.row.returnInfo" :key="scope.row.sharedorderid"></retrun-info>
            </el-form-item>
            <el-form-item label="准备投放" v-if="scope.row.relaunched">{{scope.row.relaunchdate}}</el-form-item>
            <el-form-item
              label="开始投放"
              v-if="scope.row.launchcompleted"
            >{{scope.row.launchcompletedate}}</el-form-item> -->
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="租赁者" min-width="40">
        <template #default="scope">
          {{scope.row.userAccount}}
          <my-copy :data="scope.row.userAccount" />
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="35" align="center">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          <el-button
            @click="toPath_RouterSearchMixin('/share/shareDevice',[{search_articleequipmentsn:scope.row.equipmentSN}])"
            size="mini"
            type="text"
          >{{scope.row.equipmentSN}}</el-button>
          <my-copy :data="scope.row.equipmentSN" />
        </template>
      </el-table-column>

      <el-table-column
        :sort-orders="['ascending', 'descending']"
        align="center"
        label="时间"
        min-width="60"
        prop="order_start_time"
        sortable="true"
      >
        <template #default="scope">
          <!-- <span class="el-icon-time g-color-info"></span>
          {{scope.row.orderStartTime}} -->

          <el-tooltip class="item" content="开始时间" effect="dark" placement="right">
            <div>
              <span class="el-icon-time g-color-info"></span>
              {{scope.row.orderStartTime}}
            </div>
          </el-tooltip>
          <el-tooltip class="item" content="超期时间" effect="dark" placement="right" >
            <div>
              <span class="el-icon-time g-color-success"></span>
              {{scope.row.gmtCreate}}
            </div>
          </el-tooltip>
        </template>
        

      </el-table-column>
      <el-table-column align="left" label="计费天数" min-width="28">
        <template #default="scope">{{scope.row.constDays||0}} 天</template>
      </el-table-column>
      <!-- <el-table-column align="left" label="预计金额" min-width="30">
        <template #default="scope">¥ {{scope.row.amount||0}}</template>
      </el-table-column> -->
      <el-table-column align="left" label="押金扣款" min-width="28">
        <template #default="scope">{{deductStatus[scope.row.deductStatus]}} - ¥{{scope.row.deductAmount||0}}</template>
      </el-table-column>
      <!-- <el-table-column align="left" label="退款金额" min-width="30">
        <template #default="scope">{{deductStatus[scope.row.deductStatus]}} - ¥{{scope.row.deductAmount||0}}</template>
      </el-table-column> -->
      <el-table-column align="center" label="更换设备" min-width="25">
        <template #default="scope">
          <el-tag  type="info" v-if="scope.row.replaceStatus==0">未更换</el-tag>        
          <el-tag  type="danger" v-else>已更换-{{scope.row.replaceEquipmentSN}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="愿意归还" min-width="28">
        <template #default="scope">
          <el-tag  type="danger" v-if="scope.row.userReturnAttitude==0">不确定</el-tag>    
          <el-tag  type="success" v-if="scope.row.userReturnAttitude==1">愿意</el-tag>    
          <el-tag  type="danger"   v-if="scope.row.userReturnAttitude==2">不愿意</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="35">
        <template #default="scope">
          <el-tag :type="allStatusColor[scope.row.status]">{{allStatus[scope.row.status]}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="80">
        <template #default="scope">
          <el-button
            @click="handleNegotiation(scope.row.id)"
            size="mini"
            type="primary"
            v-if="scope.row.status==1&&scope.row.userReturnAttitude==0"
          >开始沟通</el-button>
          <el-button
            @click="handleRetrun(scope.row)"
            size="mini"
            type="primary"
            v-if="scope.row.status==1&&scope.row.userReturnAttitude!=0"
          >确认归还</el-button>        
          <el-button
            @click="handleDeduction(scope.row)"
            size="mini"
            type="danger"
            v-if="scope.row.status==2"
          >强制结算</el-button>
          <el-button
            @click="handleRelaunch(scope.row)"
            size="mini"
            type="primary"
            v-if="scope.row.status==3&&scope.row.relaunchStatus<99"
          >重新投放</el-button>
          <el-button
            @click="handleRelaunchSuccess(scope.row)"
            size="mini"
            type="success"
            v-if="scope.row.status==4||scope.row.status==5"
          >完结订单</el-button>       
          <el-button  
            @click="onReturnMoney(scope.row)" 
            type="warning" 
            size='mini'
            v-if="scope.row.status==4||scope.row.deductStatus==99">发起退款</el-button>  
          <el-button  
            @click="onReplaceDevice(scope.row)" 
            type="warning" 
            style="margin-left: 6px;"
            size='mini'
            v-if="scope.row.replaceStatus<99&&scope.row.investorUserId&&scope.row.deductStatus>0&&scope.row.productModel!='UM01'"
            >更换设备</el-button>  
          <el-button  
            @click="onShowDrawer(scope.row)"
            type="primary" 
            style="margin-left: 6px;" 
            size='mini'>处理详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <negotiation-dialog @success="mixinReRouter()" ref="negotiationDialogRef"></negotiation-dialog>
    <confrim-retrun-dialog-new @success="mixinReRouter()" ref="confrimRetrunDialogRef"></confrim-retrun-dialog-new> 
    <privatizationDrewerNew ref="privatizationDrewerRef"></privatizationDrewerNew>
    <private-refund-dialog @success="mixinReRouter()" ref="refundDialogRef"></private-refund-dialog>
    <replace-device-dialog @success="mixinReRouter()" ref="replaceDeviceDilaogRef"></replace-device-dialog>
    <private-duction-dialog @success="mixinReRouter()" ref="privateDuctionDilaogRef"></private-duction-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import privatizationDrewerNew from './components/privatizationdrawerNew.vue'
import routerChangeReData from '@/common/routerChangeReData'
import negotiationInfo from './components/negotiationInfo.vue'
import retrunInfo from './components/retrunInfo.vue'
import confrimRetrunDialogNew from "./components/confrimRetrunDialogNew.vue"
import privateRefundDialog from './components/privateRefundDialog.vue'
import replaceDeviceDialog from './components/replaceDeviceDialog.vue'
import privateDuctionDialog from './components/privateDuctionDialog.vue'
import {
  sharedorderprivatizationdeductionListNew,
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationprocessNegotiationNew,
  sharedorderprivatizationprocessRelaunchStartNew,
  sharedorderprivatizationprocessLaunchCompleteNew,
  sharedorderprivatizationprocessDeductionNew,
  sharedorderprivatizationDeduction,
  onPrivateOrderReplaceDevice,
} from '@/api/Order/Privatization'
import negotiationDialog from './components/negotiationDialogNew.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from "@/store/modules/user";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'orderManage',
  components: {
    negotiationDialog,
    negotiationInfo,
    retrunInfo,
    confrimRetrunDialogNew,
    privatizationDrewerNew,
    privateRefundDialog,
    replaceDeviceDialog,
    privateDuctionDialog,
  },
  setup() {
    const loading = ref(false)
    const reDeductionLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SharedorderprivatizationdeductionListNew[]>([])
    const negotiationDialogRef = ref<any>({})
    const confrimRetrunDialogRef = ref<any>({})
    const privatizationDrewerRef= ref<any>()
    const refundDialogRef = ref<any>()
    const replaceDeviceDilaogRef = ref<any>()
    const privateDuctionDilaogRef = ref<any>()
    const searchSelect = ref({ search: 'equipmentSN' })

    //0:待处理 1：等待归还 2：超期未归还，开始扣押金 3：已归还待投放 4:押金扣款完成 5：投放完成 99：已完结
    const allStatus = readonly({
      0: '待处理',
      1: '等待归还',
      2: '开始扣押金',
      3: '待投放',
      4: '押金已扣',
      5: '投放完成',
      99: '已完结',
    })
    const allStatusColor = readonly({
      0: 'waring',
      1: 'info',
      2: 'danger',
      3: 'waring',
      4: 'info',
      5: 'success',
      99: 'success',
    })
    const deductStatus = readonly({
      0: '未扣',
      1: '开始',
      99: '已扣',
    })
    const mixin_router_search = ref({
      fortest: false,
      order: 'order_start_time',
      sort: 'desc',
      // model: '',
      productModel: '',
      status: '',
      relaunchStatus:'',
      replaceStatus:'',
      userReturnAttitude:'',
      deductStatus:''
    })
    const mixin_select_search = ref({
      search: {
        equipmentSN: '',
        userAccount: '',
        sharedOrderId: '',
        userId: '',
      },
    })

    const mixin_select_search_label = readonly({
      search: {
        equipmentSN: '设备SN',
        userAccount: '用户账号',
        sharedOrderId: '订单号',
        userId: '用户ID',
      },
    })
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderprivatizationdeductionListNew(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const onShowDrawer = (item:any) => {
      privatizationDrewerRef.value.open(item);
    }

    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })

    /**
     * 参数筛选改变
     */
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }

    /**
     * 开启沟通按钮
     */
    const handleNegotiation = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }
    const handleNegotiationResult = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }

    /**
     * 是否展开获取订单详情
     * 加载私有化订单
     */
    const handleOpen = async (e: SharedorderprivatizationdeductionListNew) => {
      list.value[e.idx].open = !list.value[e.idx].open // 开启关闭状态
      if (!list.value[e.idx].open) return
      const status = parseInt(e.status)
      // if (status > 0) {
      //   const _negotiationInfo = await sharedorderprivatizationnegotiationLoad(
      //     e.negotiationid
      //   )
      //   list.value[e.idx].negotiationInfo = _negotiationInfo
      // }
      // if (status > 0) {
      //   const _returnInfo = await sharedorderprivatizationreturnLoad(
      //     e.negotiationid
      //   );
      //   list.value[e.idx].returnInfo = _returnInfo;
      // }
    }

    const handleDeduction = async (item:any) =>{
      await ElMessageBox.confirm(
        '是否强制结算小魔夹'+item.equipmentSN+'？结算之前需要确认小魔夹归还，用户是否充值足够199，担保是芝麻信用，微信不建议使用该功能',
        '结算提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        }
      )
      const res =await sharedorderprivatizationDeduction(item.id)
      ElMessage.success(res)
      mixinReRouter()
    }

    /**
     * 确认归还
     */
    const handleRetrun = async (item:any) => {
     
      confrimRetrunDialogRef.value.open(item)
    }

    /**
     * 开始投放
     */
    const handleRelaunch = async (item: any) => {
      const overdueId=item.id;
      const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc=time+"投放设备"
      const res = await sharedorderprivatizationprocessRelaunchStartNew({
        handleUserId: userStore.userInfo.userid,
        overdueId: item.id,
        remark:desc,
        excuteInfo:'',
        relaunchStatus:'99'
      })
      ElMessage.success(res)
      mixinReRouter()
    }

    /**
     * 完结订单
     */
    const handleRelaunchSuccess = async (item: any) => {
      const overdueId=item.id;
      const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc=time+"完结超期订单"
      const res = await sharedorderprivatizationprocessLaunchCompleteNew({
        handleUserId: userStore.userInfo.userid,
        overdueId: item.id,
        remark:desc,
        excuteInfo:''
      })
      mixinReRouter()
      ElMessage.success(res)
    }

    /**
     * 再次短信通知
     */
    const onMsgClick = async (item: any) =>{
      const overdueId=item.id;
      const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc=time+'再次通知用户'
      const res = await sharedorderprivatizationprocessNegotiationNew({
        remark: desc,
        handleUserId: userStore.userInfo.userid,
        excuteInfo:'',
        remindTime:time,
        overdueId:overdueId
       
      })
       mixinReRouter()
      ElMessage.success(res);
    }

    /**
     * 开始扣款
     */
    const handleReDeduction = async (item: any) => {
      try {
        privateDuctionDilaogRef.value.open(item)
      //   reDeductionLoading.value = true
      //   await ElMessageBox.confirm(
      //     `是否发起押金扣款？确认后,将在5分钟后开启扣除用户押金199元`,
      //     '提示',
      //     {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning',
      //       distinguishCancelAndClose: true,
      //     }
      //   )
  
       
      //   const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      //   const deductionTime=dayjs(new Date().getTime()+5*60*1000).format("YYYY-MM-DD HH:mm:ss");
      //   const desc=time+'发起押金扣款'
      //   const res = await sharedorderprivatizationprocessDeductionNew({
      //       overdueId:item.id,
      //       handleUserId:userStore.userInfo.userid,
      //       remark:desc,
      //       excuteInfo:'',
      //       deductTime:deductionTime
      //     })
      //   mixinReRouter()
      //   ElMessage.success(res)
      } finally {
        reDeductionLoading.value = false
      }
    }

    /**
     * 发起退款
     */
    const onReturnMoney = async(item: any) => {
      refundDialogRef.value.open(item)
    }

    /**
     * 更换小魔夹二维码
     */
    const onReplaceDevice = async(item:any) =>{
      replaceDeviceDilaogRef.value.open(item)
    }

    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      allStatus,
      allStatusColor,
      deductStatus,
      handleNegotiation,
      negotiationDialogRef,
      mixinReRouter,
      handleNegotiationResult,
      handleOpen,
      handleRetrun,
      handleRelaunch,
      handleRelaunchSuccess,
      handleReDeduction,
      handleDeduction,
      confrimRetrunDialogRef,
      privatizationDrewerRef,
      replaceDeviceDilaogRef,
      privateDuctionDilaogRef,
      onShowDrawer,
      onMsgClick,
      onReturnMoney,
      refundDialogRef,
      onReplaceDevice,
      reDeductionLoading
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>