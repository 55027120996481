<template>
  <div class="linewarp" ref="box">
    <v-line :option="option" class="line" key="activeLine" ref="lineRef" width="100%"></v-line>
  </div>
</template>


<script lang="ts">
import { computed, defineComponent, ref, toRef, nextTick } from "vue";
import line from "@/components/echart/line";
import { getProfitGroupByRoleAndDateDayOfMonth } from "@/api/Index/platfrom";
import dayjs from "dayjs";
import echarts from "@/components/echart/base";
import { unwarp } from "@/utils";
export default defineComponent({
  components: {
    "v-line": line,
  },
  props: {
    height: {
      type: String,
      default: () => "180px",
    },
    width: {
      type: String,
      default: () => "360px",
    },
    chartData: {
      type: Array,
    },
  },
  setup(props) {
    const dataset = ref<any>([]);
    const lineRef = ref<any>("");
    const option = computed(() => {
      return {
        dataset: unwarp(dataset.value),
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        yAxis: {},
        xAxis: { type: "category", boundaryGap: false },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "30%",
          containLabel: true,
        },
        legend: {
          bottom: 0,
          type: "scroll",
          textStyle: {
            color: "#00f4f4",
          },
        },
        series: [
          // These series are in the first grid.
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
          {
            type: "line",
            seriesLayoutBy: "row",
            emphasis: {
              focus: "series",
            },
            smooth: true,
            areaStyle: {},
          },
        ],
      };
    });
    const reData = async (o: { date?: string; productid?: string }) => {
      let { date, productid } = o;
      console.log("redataredataredataredataredata :>> ");
      date = date ? date : dayjs().format("YYYY-MM");
      dataset.value = await getProfitGroupByRoleAndDateDayOfMonth({
        date,
        productid,
      });

      reView();
    };
    const reView = async () => {
      await nextTick();
      console.log("order line chartData.value :>> ", dataset.value);
      lineRef.value.myResize();
    };

    return {
      option,
      lineRef,
      reView,
      reData,
    };
  },
});
</script>


<style lang="scss" scoped>
.linewarp {
  padding-top: 40rpx;
  width: 100%;
  height: 100%;
}
</style>