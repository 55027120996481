<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
          
     <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">添加团队</el-button>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
    
      <el-table-column label="序号" min-width="20">
        <template #default="scope">        
          {{scope.row.id}}
        </template>
      </el-table-column>
      <el-table-column label="名称" align="center" min-width="36">
        <template #default="scope">
          {{scope.row.name}}         
        </template>
      </el-table-column>
          
      
      <el-table-column label="创建时间" align="center" min-width="55">
        <template #default="scope">{{scope.row.gmtCreate}}</template>
      </el-table-column>
      <el-table-column label="修改时间" align="center" min-width="55">
        <template #default="scope">{{scope.row.gmtModified}}</template>
      </el-table-column>
     
      <el-table-column align="center" label="操作" min-width="70">
        <template #default="scope">
         <el-button
            @click="handleRemove(scope.row)"
            size="mini"
            type="danger"
            style="margin-left: 12px;"         
           >删除</el-button>
          <el-button
            @click="handleUpdate(false,scope.row)"
            size="mini"
            type="primary"
            style="margin-left: 12px;"         
           >编辑</el-button>
          <el-button
            @click="toPath(`/user/operate?operationId=${scope.row.id}`)"
            size="mini"
            type="info"
            style="margin-left: 12px;"         
           >查看运营员</el-button>
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-dialog @success="mixinReRouter" ref="addDialogRef"></add-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { operateModel, operateListHttp, removeOperateHttp} from '@/api/Operate/index'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import addDialog from './components/addDialog.vue'
import { useRouter } from "vue-router";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'operate',
  components: {
    addDialog
  },
  setup() {
    const router = useRouter();
   
    const addDialogRef = ref<any>()
    const toPath = (path: string) => {
      router.push(path);
    };
    const mixin_router_search = ref({
      order: "gmt_create",
      sort: "desc"
    })

    const mixin_select_search = ref({
      search: {
        name:'', //包装箱编号
  
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        name: '名称',  
      },
    })

   

    const searchSelect = ref({
      search: 'name',
    })

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<operateModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await operateListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleAdd = () => {
      addDialogRef.value.open(true,null)
    }
 
    const handleUpdate = (isAdd:boolean,item:any) => {
      addDialogRef.value.open(isAdd,item)
    }

    const handleRemove = async(item:any) =>{
        await ElMessageBox.confirm(
          `是否删除运营团队“`+item.name+'”',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await removeOperateHttp(item.id)
        mixinReRouter()
        ElMessage.success(res)
    }

    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      changSearch,
      searchSelect,
      list,
      loading,
      addDialogRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      toPath,
      handleAdd,
      handleUpdate,
      handleRemove,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>