
import { defineComponent, ref, readonly, inject } from 'vue'
import {
  AlippayfundTransfer,
  getAlippayfundTransfer,
} from '@/api/Financial/alipayfundTransfer'
import { valueToNode } from '@babel/types'

export default defineComponent({
  setup() {
    const loading = ref(false)
    const btnEnabledLoaidng = ref(false)
    const btnRemoveLoaidng = ref(false)
    const payeeinfoname = ref('')
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
  
   
    const reData = async (name:string) => {
      payeeinfoname.value=name;
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value,order:"creationdate",sort:"desc" }
        if (name!=null&&name!="") {
          rqData = { ...rqData, entity: {payeeinfoname:name}}
        }
        const res = await getAlippayfundTransfer(rqData)
        console.log('res.records :>> ', res.records)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
   
  
    const onPageChange = () =>{
      console.log('页数改变',page.value)
      if(page.value<total.value){
        page.value++
        reData(payeeinfoname.value);
      }
    }

    const handleLimitChange = () => {
      reData(payeeinfoname.value);
    }
    return {
      list,
      loading,
      page,
      total,
      limit,
      reData,
      onPageChange,
      handleLimitChange,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
    }
  },
})
