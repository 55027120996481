
import { defineComponent, ref, readonly } from 'vue'
import Rules from "@/common/Rules";
let productId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})

    const inputData = ref({
      msg: "",
    })

    const rules = readonly({
      msg: [Rules.required()]
    });
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async (_productId:string) => {
      productId=_productId;
      visible.value = true
    }
   
    const submit = async () => {
      const oneNetMsg = inputData.value.msg;
      console.log('消息传送之前=',oneNetMsg)
      emit('success',productId,oneNetMsg)
      handleClose()
    }
    return {
      visible,
      handleClose,
      open,
      rules,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
