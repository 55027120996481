import { h, defineComponent, ref, toRef, onMounted, watch, nextTick ,watchEffect} from 'vue';
import echarts from "./base"
import {
    LineChart
} from 'echarts/charts';
import { unwarp } from '@/utils';
echarts.use([LineChart])
export default defineComponent({
    name: "my-line",
    props: ["option"],
    setup(props: any) {
        const option = toRef(props, 'option')
        const custemPieRef = ref<any>("")
        const myChart = ref<any>('')
        const myRender = (o?: any) => {
            console.log('o :>> ', o);
            unwarp(myChart.value).setOption && unwarp(myChart.value).setOption(o || option.value)
        }
        onMounted(async () => {
            await nextTick()
            myChart.value = echarts.init(custemPieRef.value);            
            watchEffect(async () => {
                myRender(option.value)
            })
        })

        const myResize = async () => {
            await nextTick()
            myChart.value.resize && myChart.value.resize()
        }
        return {
            custemPieRef,
            myResize,
            myRender
        }
    },
    render() {
        return h('div', {
            ref: 'custemPieRef',
            style: {
                width: "100%",
                height: "100%"
            }
        })
    }
})