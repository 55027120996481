<!-- 分配小魔夹 -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`产品分配`"
    @closed="handleClose"
    class="title"
    v-model="visible"
    width="750px"
  >
    <div class="header" v-show="quantity>0">
      <el-input
        @keyup.enter="addClick"
        class="input-with-select"
        placeholder="请输入添加sn内容"
        v-model="add"
      >
        <template #prepend>
          <el-select placeholder="选择添加类型" v-model="addType">
            <el-option label="小魔夹SN" value="equipmentsn"></el-option>
            <el-option label="包装箱SN" value="boxsn"></el-option>
          </el-select>
        </template>
      </el-input>
      <el-button
        :disabled="quantity<=0||isShow"
        :loading="btnSumitLoading"
        @click="addClick()"
        icon="el-icon-plus"
        type="primary"
      >添加</el-button>

      <el-button @click="handleExport(scope)" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
    </div>

    <el-table :data="list" :loading="loading" stripe style="width: 100%" v-show="list.length>0">
      <el-table-column label="SN" min-width="70" prop="articleequipmentsn"></el-table-column>
      <el-table-column label="创建时间" min-width="70" prop="creationdate"></el-table-column>
      <el-table-column label="操作" min-width="70">
        <template #default="scope">
          <el-button
            :loading="btnCancelLoading"
            @click="removeItem(scope.$index,scope.row.articleequipmentsn)"
            size="mini"
            type="primary"
            v-if="status=='0'"
          >取消分配</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :total="total"
      @current-change="onPageChange"
      layout="prev, pager, next"
      v-if="total"
    ></el-pagination>
    <template #footer>
      <span class="dialog-footer">
        <el-button :loading="btnSumitLoading" @click="handleClose" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { investmentorderarticleList, investmentorderarticleRemove, } from '@/api/Investment/shipment'
import { singleassignXmjHttp, singleassignBoxHttp} from '@/api/Investment/shipment'
import { ElMessage } from 'element-plus'
let equipmentsnMap: any
let equipmentboxMap: any
let investmentorderid: any
let shipmentid: any
export default defineComponent({
  name: 'assign-new-dialog',
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const btnCancelLoading = ref(false)
    const loading = ref(false)
    const status = ref<any>('')
    const isShow = ref(false)
    const addType = ref<any>('equipmentsn')
    const add = ref<any>('')
    const quantity = ref(0)
    const boxsn = ref<any>([])
    const equipmentsn = ref<any>([])
    const list = ref<any[]>([])
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const reDistributionXmjList = async () => {
      try {
        loading.value = true
        const res = await investmentorderarticleList({
          entity: {
            shipmentid,
            investmentorderid,
          },
          current: page.value,
          size: limit.value,
        })
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const handleClose = () => {
      btnSumitLoading.value = false
      visible.value = false
      addType.value = 'equipmentsn'
      add.value = ''
      boxsn.value = []
      equipmentsn.value = []
      list.value = []
      equipmentsnMap = null
      equipmentboxMap = null
      emit('assgin-success')
    }
    const open = (data: any, _isShow = false) => {
      console.log('data :>> ', data)
      equipmentsnMap = new Map()
      equipmentboxMap = new Map()
      shipmentid = data.shipmentid
      investmentorderid = data.investmentorderid
      visible.value = true
      quantity.value = data.quantity
      status.value = data.status
      reDistributionXmjList()
      isShow.value = _isShow
    }
    const addClick = async () => {
      if (!add.value || !add.value.trim()) return ElMessage.warning('请输入SN')
      add.value = add.value.trim().toUpperCase() // 转换为大写
      const _map =
        addType.value == 'equipmentsn'
          ? equipmentsnMap
          : addType.value == 'boxsn'
          ? equipmentboxMap
          : ''
      if (_map.has(add.value)) {
        _map.set(add.value, true)
        return ElMessage.error('这个设备或箱子已添加')
      } else {
        try {
          btnSumitLoading.value = true
          let res = null
          if (addType.value == 'equipmentsn') {
            res = await singleassignXmjHttp({
              shipmentid : shipmentid,
              equipmentsn: add.value,
            })
            equipmentsn.value.push(add.value)
            // list.value.push(res.record);
          } else {
           
            res = await singleassignBoxHttp({
              shipmentid:shipmentid,
              boxsn : add.value,
            });
            boxsn.value.push(add.value);
            // reDistributionXmjList();
          }
          _map.set(add.value, false)
          ElMessage.success(res)
          quantity.value =
            addType.value == 'boxsn'
              ? quantity.value - 60
              : quantity.value - 1
          add.value = ''
          reDistributionXmjList()
        } finally {
          btnSumitLoading.value = false
        }
      }
    }
    const removeItem = async (index: number, articleequipmentsn: string) => {
      try {
        btnCancelLoading.value = true
        const res = await investmentorderarticleRemove({
          articleequipmentsn: articleequipmentsn,
        })
        ElMessage.success(res)
        list.value.splice(index, 1)
      } finally {
        btnCancelLoading.value = false
      }
    }
    const handleExport = (e: any) => {
      ElMessage.warning('暂时不支持')
      console.log('e :>> ', e)
    }
    const cancelAssgin = (e: any) => {
      ElMessage.warning('暂时不支持')
      console.log('e :>> ', e)
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      loading,
      list,
      quantity,
      add,
      addClick,
      addType,
      total,
      page,
      limit,
      equipmentsn,
      boxsn,
      status,
      handleExport,
      btnCancelLoading,
      cancelAssgin,
      isShow,
      removeItem,
    }
  },
})
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    .el-select {
      width: 150px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}
.el-tag {
  margin-left: 12px;
}
</style>