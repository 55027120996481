import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
export interface AdministrativedivisionList {
    areacode: string
    arealevel: number
    areaname: string
    creationtime: number
    islastlevel: number
    modificationtime: number
    municipality: number
    superiorcode: string
}
export async function administrativedivisionList(params: ListReq): Promise<ListRes<AdministrativedivisionList>> {
    params.token = true
    const res = await defHttp.request<ListRes<AdministrativedivisionList>>(
        {
            url: `/administrativedivision/list`,
            params
        },
        {
            headers: {
                ignoreCancelToken: true, // 取消去抖
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
    if (res.totalCount == 0) throw Error('获取城市失败')
    return res
}

export async function administrativedivisionRemove(areacode: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/administrativedivision/remove`,
            params: {
                token: true,
                areacode
            }
        }
    );
    if (!res) throw new Error("请求删除区域码失败");
    return res.message || "删除成功"
}
export async function administrativedivisionSave(areacode: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/administrativedivision/save`,
            params: {
                token: true,
                areacode
            }
        }
    );
    if (!res) throw new Error("添加区域码失败");
    return res.message || "删除成功"
}
export async function administrativedivisionUpdate(areacode: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/administrativedivision/update`,
            params: {
                token: true,
                areacode
            }
        }
    );
    if (!res) throw new Error("更新区域码失败");
    return res.message || "删除成功"
}