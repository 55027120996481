
import { computed, defineComponent, ref, toRef, onMounted, nextTick } from 'vue'
import pie from '@/components/echart/pie'
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => '180px',
    },
    chartData: {
      type: Array,
    },
  },
  components: {
    'v-pie': pie,
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    const option = computed(() => {
      console.log('chartDatachartDatachartDatachartDatachartData :>> ', chartData.value);
      return {
        // legend: {
        //   top: "bottom",
        // },
        color: [
          'rgb(36,106,173)',
          '#1976D2',
          'rgb(17,46,98)',
          '#00F4F4',
          '#005674',
        ],

        dataset: chartData.value,
        series: [
          {
            name: '面积模式',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            itemStyle: {
              // borderRadius: 8,
            },
            // labelLine: {
            //   show: true,
            // },
            label: {
              fontSize: 10,
              show: true,
              position: 'inside',
              formatter: '{@name}:{@num}'
            },
          },
        ],
      }
    })
    const areaPieRef = ref<any>(null)
    const reView = () => {
      areaPieRef.value.myRender()
    }
    return {
      option,
      areaPieRef,
      reView,
    }
  },
})
