import { hotModuleUnregisterModule } from "@/utils/helper/vuexHelper"
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/localStore'
import { getUserRelos } from "@/api/role"
import store from "@/store"
import type { UserRole } from "@/types/user"
import { userStore } from "./user";
const NAME = 'role';
hotModuleUnregisterModule(NAME);
@Module({ namespaced: true, name: NAME, dynamic: true, store })
class Role extends VuexModule {
    role = getLocalStorage('userRole') || {}
    roles = getLocalStorage('userRoles') || {}

    public get getRole(): UserRole {
        return this.role
    }
    public get getRoles(): UserRole[] {
        return this.roles
    }

    @Mutation
    clearStore() {
        this.role = {}
        this.roles = {}
        removeLocalStorage('userRole')
        removeLocalStorage('userRoles')
    }

    @Mutation
    setUserRole(role: UserRole) {
        this.role = role
        setLocalStorage('userRole', role)
    }

    @Mutation
    setUserRoles(roles: UserRole[]) {
        this.roles = roles
        setLocalStorage('userRoles', roles)
    }

    @Action
    syncUserRole(): Promise<UserRole> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                if (!userStore.getCurrentRole.roleid) return
                const roles = await getUserRelos(userStore.getCurrentRole.roleid);
                this.setUserRoles(roles)
                const role = roles.find(v => {
                    return v.concur == "1";
                })
                if (role) {
                    this.setUserRole(role)
                    resolve(role)
                }
                resolve
            } catch (error) {
                reject(error)
            }
        })
    }
}

export const roleStore = getModule<Role>(Role);