
import Item from './Item.vue'  //侧边栏的单条item
import { defineComponent, computed, toRef } from 'vue'
import { appStore } from '@/store/modules/app'  //存储
import { routerStore } from '@/store/modules/router'  //路由管理工具，有两个默认一个主页，登录页面
import { useRoute } from 'vue-router'  //路由
import checkRole from '@/common/checkRole' //检查用户是否有侧边导航权限
export default defineComponent({
  props:['isShow'],
  name: 'v-nav-menu',
  setup() {
    const sidebar = computed(() => appStore.getSidebar)
    const device = computed(() => appStore.getDevice)
    const route = useRoute()
    const { roleShowPermission } = checkRole()
    const routes = computed(() => {
      return routerStore.getAddRouters.map((it) => {
        if (it.children && it.children.length == 1) {
          it = it.children[0]
        }
        return it
      })
    })
    const active = computed(() => route.path)
    const classObj = computed(() => {
      return {
        openSidebar: sidebar.value.opened,
        hideSidebar: !sidebar.value.opened,
        mobile: device.value === 'mobile',
      }
    })
    const toCustomerServive = () => {
      window.open('https://mpkf.weixin.qq.com', '_blank')
    }
    const open = (e: any) => {
      console.log('open e :>> ', e)
    }
    return {
      sidebar,
      device,
      routes,
      active,
      classObj,
      toCustomerServive,
      roleShowPermission,
      open,
    }
  },
  components: {
    'v-item': Item,
  },
})
