
import axios from 'axios'
import { userStore } from '@/store/modules/user'
import { upDatePlace } from '@/api/Share/Place'
import { defineComponent, ref, computed } from "vue";
import { ElMessage, ElMessageBox} from 'element-plus'
let placeId='';
let upImageList=new Array();
export default defineComponent({
  name: "v-show-piace-dialog",
  emits: ['success'],
  setup(props, { emit }) {
    const url = require ("@/config/url.ts")
    const userInfo = computed(() => userStore.userInfo)
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const title = ref('场所修改')
    const disabled = ref(false);
    const showBigVisible = ref(false);
    const fileName = ref<any>()
    const imgList = ref<any[]>([])
    const deviceInfo = ref<any>({});
    const showBigImageUrl=ref('')
    const showBigImageList = ref<string[]>([])

    const handleClose = () => {
      visible.value = false;
      upImageList=[];
      imgList.value=[];
      showBigImageList.value=[];
    };

   
    const open = (item:any) => {
      upImageList=[];
      imgList.value=[];
      showBigImageList.value=[];
      deviceInfo.value=item
      visible.value = true;
      placeId=item.placeid;
      //imgList.value=item.signboardUrls;
      let _signboardUrls=item.signboardUrls;
      //let _signboardkey= "啊啊|订单|哈哈";
      if(null!=_signboardUrls&&_signboardUrls.length>0){
        for(let i=0;i<_signboardUrls.length;i++){
          imgList.value.push({name: 'image+'+i+'.jpeg', url:_signboardUrls[i]})
          showBigImageList.value.push(_signboardUrls[i])
        }
      }
      if(null!=item.signboardkey){
        let _signboardkey=item.signboardkey
        let keys=_signboardkey.split('|')
        keys.forEach((key: any) =>{
          //console.log("切割的数据=",key)
          upImageList.push(key)
        })
       
      }


    };

    const handleRemove = (file:any) =>{
      console.log('图片2=',file)
     
    }

  
    const beforeUpload = (file:any) =>{
      console.log('上传之前=',file);
    }

    const onChange = (file:any, fileList:any) =>{
      // console.log("文件名称=",fileName)
      console.log('上传改变=',file);
      console.log('上传改变21=',typeof(file));
      console.log('上传改变1=',fileList);

    }
  
    const onPreview = (file:any) => {
      console.log('文件onPreview=',file)
      showBigImageUrl.value=file.url
      showBigVisible.value=true;
      console.log('显示大图=',showBigImageUrl.value)
      console.log('显示大图1=', showBigVisible.value)
    }

    const onRemove = (file:any, fileList:any) => {
      console.log('删除=',file)
      console.log('删除1=',fileList)
    }

    const onSuccess = (response:any, file:any, fileList:any) => {
      console.log('成功=',file)
      console.log('成功1=',fileList)
      console.log('成功2=',response)
    }

    const onError = (err:any, file:any, fileList:any) =>{
      console.log('错误=',err)
      console.log('错误1=',file)
      console.log('错误2=',fileList)
    }

    const beforeRemove = async (file:any, fileList:any) =>{
    
      console.log('删除之前=',file)
      console.log('删除之前=',fileList)
      let index=-1;
      for(let i =0;i<showBigImageList.value.length;i++){
        if(file.url==showBigImageList.value[i]||file.uid==showBigImageList.value[i]){
          index=i;
        }
      }
     
      console.log("删除坐标="+index)
      
      try {
        await ElMessageBox({
          type:"warning",
          title:"请确认",
          message:'是否删除第'+(index+1)+'张图片',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showCancelButton:true,
        }).then(() => {
          console.log('点击确定=',index)
          // delete upImageList[index]
          // delete imgList.value[index]
          // delete showBigImageList.value[index]
          upImageList.splice(index,1);
          //imgList.value.splice(index,1);
          showBigImageList.value.splice(index,1);
          console.log('删除之后=',upImageList)
          console.log('删除之后1=',imgList.value)
          console.log('删除之后2=',showBigImageList.value)
        })
        
        // btnRemoveLoaidng.value = true
        // const res = await removePlace({
        //   placeid,
        // })
        // list.value.splice(index, 1)
        // ElMessage.success(res.message)
      } finally {
        
      }
      return false
    }

    const onProgress = (event:any, file:any, fileList:any) =>{
      console.log('进度=',event)
      console.log('进度2=',file)
      console.log('进度3=',fileList)
    }

    const uploadelupload = () => {
     
    }

    const closeImgViewer = (e:any) =>{
      console.log('点击关闭大图=')
      showBigVisible.value=false
    }

    const handleupload = async (params:any) =>{
      const _file = params.file;
      console.log('自动上1传2=',params)
      console.log(_file)
      var formData = new FormData();
      formData.append("file", _file);
      showBigImageList.value.push(params.file.uid)
      await UploadImg(formData,(res:any)=>{
        console.log('返回参222数=,',res)
        try {
          if(res.data.code==200){
            upImageList.push(res.data.data)
            ElMessage.success(res.data.msg)
          }else{
            ElMessage.error(res.data.msg)
          }
       
        } finally {
       
        }
      })
    }


    const submit =async () =>{
      let imageSources='';
   
      console.log("遍历之前=",upImageList.length)
      if(upImageList.length>0){
        console.log("开始遍历")
        if(upImageList.length==1){
          imageSources=upImageList[0];
        }else{
          for(let i=0;i<upImageList.length;i++){
          console.log("遍历数据=",upImageList[i])
          if(i<upImageList.length-1){
            imageSources=imageSources+upImageList[i]+"|"
          }else{
            imageSources=imageSources+upImageList[i]
          }
         }
        }     
        console.log("遍历之后=",imageSources)
        let rqData: any = {placeid:placeId,signboardkey:imageSources }
        const res =await upDatePlace(rqData);
        console.log('图片上传=',res)
        ElMessage.success(res.message)
        emit('success',res.message)
      }
      handleClose();   
    }

    //上传图片
    const UploadImg=async (pram:any,callBack:any)=>{
      let upurl = url.baseUrl+'/v1/shared/place/uploadimg?token='+userStore.userInfo.token;
      await axios.request({
          url: upurl,
          method: 'post',
          data: pram,
      }).then(res => {
          callBack(res);
      }).catch((e) => {
          alert(e);
          
      })
    }

   
  
    return {
      url,
      userInfo,
      deviceInfo,
      title,
      visible,
      showBigVisible,
      btnSumitLoading,
      disabled,
      fileName,
      imgList,
      showBigImageList,
      showBigImageUrl,
      handleClose,
      open,   
      handleRemove,
      beforeUpload,
      onChange,
      onPreview,
      onRemove,
      onSuccess,
      onError,
      beforeRemove,
      onProgress,
      uploadelupload,
      handleupload,
      submit,
      closeImgViewer,
    };
  },
});
