

import { ref,computed, defineComponent, readonly ,reactive} from "vue";
import { repairDetailsModel, getDetailsHttp } from '@/api/fault/repair'
import { toOptional } from "@/types/utils";
import { userStore } from "@/store/modules/user";
export default defineComponent({


    setup() {
        const isVisible = ref(false);
        const title = ref("详情信息");
        const direction=ref("rtl");
        const status=ref(0)
        const sn=ref('')  //设备SN
        const formRef = ref<any>({})
        const faultId=ref('') //故障id

        const orderDetailsInfo=ref<toOptional<repairDetailsModel>>({});
        const handleClose=(done:any)=>{
          formRef.value.resetFields()
          formRef.value.clearValidate()
          done();
        };

      
        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用222');
            console.log(item.id)
            isVisible.value = true;
           
            faultId.value=item.id
            title.value=item.inEquipmentSn+'故障详情'
        
            orderDetailsInfo.value={};
            getFaultDetails()
           
        };

      
        /**
         * 获取详情信息
         */
        const getFaultDetails = async () => {          
          const info = await getDetailsHttp(faultId.value)
          orderDetailsInfo.value=info.data;
        }


        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,
            orderDetailsInfo,         
            sn,          
            formRef,
            status,
        };
    },
    components: {
      
    }
   
});
