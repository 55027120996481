
  import { defineComponent, ref } from "vue";
  import { getComponentsList, ComponentsListRes } from "@/api/Component/Index";
  import { getAnalyDeviceList, analyDeviceModel } from "@/api/Share/Device";
  import { getcrc, sendOnenNetMsg} from "@/api/Share/Panel";
  import { ElMessage } from "element-plus";
  import { toOptional } from "@/types/utils";
  import { getAdInfoList} from '@/api/util'
  import { analy } from "../../../../xmj_admin_vue2/src/api/Url";
  import { any } from "vue-types";
  import editMsgDialog from "./editMsgDialog.vue";
  let deviceId = "";
  let productId="";
  export default defineComponent({
    name: "v-dialog",
    components: {
      editMsgDialog
    },
    setup() {
      const visible = ref(false);
      const loading = ref(false);
      const syncLoaidng = ref(false);
      const sendMsgLoading = ref(false);
      const btnSumitLoading = ref(false);
      const title = ref('设备详情');
      const current = ref(1);
      const size = ref(10);
      const sn = ref("");
      const mac = ref('');
      const list = ref<ComponentsListRes[]>([]);
      const deviceInfo = ref<toOptional<analyDeviceModel>>({});
      const adInfoList = getAdInfoList()
      const editDialogRef = ref<any>();
      const handleClose = () => {
        visible.value = false;
        //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
      };
      const reData = async () => {
        try {
          loading.value = true;
          const { records } = await getComponentsList({
            current: current.value,
            size: size.value,
            entity: {
              equipmentsn: sn.value,
            },
          });
          list.value = records;
          list.value.forEach(item => {
            if(item.type==='BLE'){
              mac.value=item.sign
            }
          });
        } finally {
          loading.value = false;
        }
      };
      const open = (_sn: string,item:any) => {
        visible.value = true;
        title.value=_sn+"设备详情";
        sn.value = _sn;
        if(item !=null){
          deviceInfo.value=item;
          deviceId=item.deviceid;
          productId=item.productid;
          console.log('产品id=',productId);
          console.log('设备id=',deviceId)
        }else{
          getDeviceSn();
        }
        reData();
      };
      const onPageChange = (e: any) => {
        current.value = e.value;
        reData();
      };
      const handleSync = async (deviceid?: string) => {
        try {
          syncLoaidng.value = true;
          const res =  await getcrc({ deviceid });
          ElMessage.success(res);
        } finally {
          syncLoaidng.value = false;
        }
      };

      const getDeviceSn = async () => {
        try {
            let rqData: any = { currentPage: 1, size: 10,sn:sn.value };
            const res = await getAnalyDeviceList(rqData);        
            deviceInfo.value = res.data.records[0];
            deviceId=deviceInfo.value.deviceid!;
            productId=deviceInfo.value.productid!;
        } finally {
            
        }
      };
      
      const handleEditMsg = async() => {
        editDialogRef.value.open();
      }
      
      const sendOneNetMsg = async(args:string ) =>{
        try{
          sendMsgLoading.value = true;
          const res =  await sendOnenNetMsg({ deviceid:deviceId,productId:productId,sn:sn.value,args:args });
          ElMessage.success(res);
        } finally {
          sendMsgLoading.value = false;
        }
      }

      return {
        visible,
        handleClose,
        open,
        list,
        btnSumitLoading,
        current,
        size,
        onPageChange,
        handleSync,
        syncLoaidng,
        sendMsgLoading,
        title,
        mac,
        deviceInfo,
        adInfoList,
        editDialogRef,
        handleEditMsg,
        sendOneNetMsg,
      };
    },
  });
  