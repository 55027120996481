
import { appStore } from '@/store/modules/app'
import { userStore } from '@/store/modules/user'
import {
  defineComponent,
  ref,  
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  toRef,
} from 'vue'
import { useRouter } from 'vue-router'
import { getUserInfo, changeRole } from '@/api/User/index'
import { roleStore } from '@/store/modules/role'
import type { UserRole } from '@/types/user'
import type { toOptional } from '@/types/utils'
import { routerStore } from '@/store/modules/router'
import { ElMessage } from 'element-plus'
import checkRole from '@/common/checkRole' //检查用户是否有侧边导航权限

export default defineComponent({
  name: 'v-layout-header',
  props: {
    isShow: Boolean,
    default:()=>false
  },
  setup(props) {
    const url = require ("@/config/url.ts")
    const avaterIsLoaded = ref(false)
    const router = useRouter()
    const isShow = toRef(props, 'isShow')
    let select: toOptional<UserRole> = reactive({})
    // const background = ref("#efefef");
    // const color = ref("#3ea243");
    const userInfo = computed(() => userStore.userInfo)
    const userRoles = computed(() => userStore.userInfo.roles)
    const userRole = computed(() => userStore.getCurrentRole)
    const getLoading = computed(() => appStore.getLoading)
    const { roleShowPermission } = checkRole()
    let fStr = ''
    const getFrist = computed({
      get: () => fStr.substr(0, 1).toUpperCase(),
      set: (val) => {
        fStr = val
      },
    })
    const internalInstance = getCurrentInstance()
    console.log('internalInstance :>> ', internalInstance)
    const getAvaterBg = computed(() => {
      return isShow.value
        ? {
            background: `url(${'../../assets/img/avaterbg.png'})`,
            color: '#ffffff',
          }
        : { background: '#efefef', color: '#3ea243' }
    })
    onMounted(() => {
      avaterIsLoaded.value = false
    })

    const menuClick = (com: string) => {
      switch (com) {
        case 'logout':
          userStore.loginOut()
          break
        case 'my':
          router.push('/my')
          break
      }
    }

    const avaterLoadingError = async (e: unknown) => {
      console.log('avaterLoadingError', e)
      if (avaterIsLoaded.value) return
      try {
        const { avatarurl } = await getUserInfo()
        userStore.upUserInfo({ avatarurl })
        avaterIsLoaded.value = true
      } catch (error) {
        console.log({ error })
        avaterIsLoaded.value = false
      }
    }
    const RoleClick = async (item: UserRole) => {
      console.log('item :>> ', item, select)
      if (select && select.roleid == item.roleid) return
      if (getLoading.value) return
      select = item
      try {
        appStore.toggleLoading(true)
        router.replace(`/home`)
        await changeRole(item.userroleid)
        roleStore.setUserRole(item)
        userStore.setCurrentRole(item)
        await routerStore.syncRouters()
        location.reload()
      } finally {
        appStore.toggleLoading(false)
      }
    }

    const logout = () => {
      userStore.loginOut()
    }
    const toPath = (url: string) => {
      router.push(url)
    }
    return {
      select,
      // background,
      // color,
      url,
      userInfo,
      userRole,
      userRoles,
      getLoading,
      getAvaterBg,
      avaterLoadingError,
      RoleClick,
      logout,
      toPath,
      menuClick,
      getFrist,
      avaterIsLoaded,
      fStr,
      roleShowPermission,
    }
  },
})
