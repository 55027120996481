
import { createHttp } from '@/api/fault/repair'
import { faultModel, getFaultList } from '@/api/System/fault'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, readonly} from 'vue'
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
let overdueId = 0;
let startTime = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {

    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const list = ref<faultModel[]>([])
    const headerObj=ref<any>({})
    headerObj.value={ Authorization:userStore.info.token };

    const inputData = ref({
      equipmentSn: "",
      reason:'',
      revokePlace:'',
      isRepalce: false,
    })
    const rules = readonly({
      equipmentSn: [Rules.required()]
    });

    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async () => {
      await getFaultTemplate();
      visible.value = true
    }
    
    const getFaultTemplate = async () =>{
      
      let rqData: any = { current: 1, size: 20 }
      const res = await getFaultList(rqData)
      list.value = res.rows
       
    }

    const submit = async () => {
      try {
        btnSumitLoading.value = true

        const time = dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc = userStore.userInfo.account +"在"+time+ '新增维修单'
        const res = await createHttp({        
          equipmentSn: inputData.value.equipmentSn,
          faultDesc: desc,
          reason:inputData.value.reason,
          revokePlace:inputData.value.revokePlace
        })
        ElMessage.success(res)
        handleClose()
        
        emit('success')
        
      } finally {
        
        btnSumitLoading.value = false
      }
    }

    return {
      rules,
      headerObj,
      visible,
      handleClose,
      open,   
      list,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
