<template>
  <div>invite</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Name",
});
</script>
<style lang="scss" scoped>
</style>