<template>
  <div class="container">
    <el-alert
      title="新增采购单，下单数量建议下10，20，50整数，不建议一次性下太大数量，可以下多个采购单"
      type="warning"
      show-icon
      style="margin-bottom: 16px;"
      :closable="false">
      </el-alert>
      <header>
        <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip" >
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
        <el-select @change="handelChange" class="header-select" clearable placeholder="采购单状态" v-model="mixin_router_search.status">
          <el-option label="待受理" value="0"></el-option>
          <el-option label="已受理" value="1"></el-option>
          <el-option label="待接收" value="2"></el-option>
          <el-option label="已完结" value="3"></el-option>
        </el-select>
        <el-button @click="handleAddNewPurchase" icon="el-icon-plus" type="primary" style="margin-left:16px;">新增采购单</el-button>
        <el-button @click="handleAddNoOrderPurchase" icon="el-icon-plus" type="primary" style="margin-left:16px;">新增无投资采购单</el-button>
        <el-button @click="handleExportPurchase" icon="el-icon-download" type="info" style="margin-left:16px;">导出表格</el-button>
         <!-- <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">创建无投资出货单</el-button> -->
       </header>
    
       <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
         <el-table-column type="expand">
           <template #default="scope">
             <el-form class="table-expand" inline label-position="left" label-width="120px">
               
               <el-form-item label="投资订单id">
                 <span>{{ scope.row.investmentOrderId }}</span>
                 <my-copy :data="scope.row.investmentOrderId"></my-copy>
               </el-form-item>
             </el-form>
           </template>
         </el-table-column>
         <el-table-column label="Id" width="120" align="center">
           <template #default="scope">
             <!-- <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
             <color-icon type="icon-zhengshiban" v-else /> -->
             <!-- {{getStatusName[scope.row.status]}} -->
             {{scope.row.id}}
             <hover-show-qr :logo="`${scope.row.productId}`" :text="scope.row.id" :key="scope.row.id"></hover-show-qr>
           </template>
         </el-table-column>
         <el-table-column label="投资人" width="160" align="center">
           <template #default="scope">
              <span v-if="scope.row.nickname">{{scope.row.nickname}}</span>
              <span v-else>无投资人</span>
           </template>
         </el-table-column>
         <!-- <el-table-column label="投资人账号" width="120" align="center">
           <template #default="scope">
              {{scope.row.account}}
           </template>
         </el-table-column> -->
         <el-table-column label="产量类型" width="140" prop="productId"  align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.productId">{{productList[productIdList.indexOf(scope.row.productId)].name}}</el-tag>
          </template>
         </el-table-column>
         <el-table-column label="数量" width="90" prop="quantity"  align="center"></el-table-column>
         <el-table-column label="状态" width="120" prop="status"  align="center">
          <template #default="scope">
            <el-tag type="" v-if="scope.row.status=='0'">待受理</el-tag>
            <el-tag type="info" v-if="scope.row.status=='1'">已受理</el-tag>
            <el-tag type="warning" v-if="scope.row.status=='2'">待接收</el-tag>
            <el-tag type="success" v-if="scope.row.status=='3'">已完结</el-tag>
          </template>
         </el-table-column>
         <el-table-column label="已出货" width="90" prop="shipedQuantity"  align="center"></el-table-column>
         <el-table-column label="已完结" width="90" prop="finishQuantity"  align="center"></el-table-column>
         <el-table-column label="创建人" width="160" prop="createUser"  align="center"></el-table-column>
         <el-table-column label="接收人" width="160" prop="finishUser"  align="center"></el-table-column>
         <el-table-column label="创建时间" width="190" prop="gmtCreate" align="center"></el-table-column>
         <el-table-column label="受理时间" width="190" prop="gmtAccept"  align="center"></el-table-column>
         <el-table-column label="完结时间" width="190" prop="gmtFinish"  align="center"></el-table-column>
         <el-table-column label="操作" width="180" fixed="right" align="center">
           <template #default="scope">
             <el-button
               @click="handlerShipment(scope.row)"
               size="mini"
               type="primary"
             >出货单</el-button>
             <el-button
               @click="handlerAccept(scope.row)"
               size="mini"
               type="primary"
               v-if="scope.row.status=='2'"
             >接收</el-button>
             <el-button
               @click="handlerDelete(scope.row)"
               size="mini"
               type="danger"
               v-if="scope.row.status=='0'"
             >删除</el-button>
           </template>
         </el-table-column>
       </el-table>
       <el-pagination
         :current-page="page"
         :page-size="limit"
         :page-sizes="[10, 30, 50, 100]"
         :total="total"
         @current-change="onPageChange"
         @size-change="handleLimitChange"
         layout="total, sizes, prev, pager, next, jumper"
       ></el-pagination>
       <v-shipment-dialog ref="shipmentDialogRef"></v-shipment-dialog>
       <accectPurchaseDialog ref="accectPurchaseDialogRef"></accectPurchaseDialog>
       <addPurchaseOrderDialog @success="handleSuccess" ref="addPurchaseOrderDialogRef"></addPurchaseOrderDialog>
       <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
       <addNoOrderPurchaseDialog @success="handleSuccess" ref="addNoOrderPurchaseRef"> </addNoOrderPurchaseDialog>
      </div>
   </template> 
   <script lang="ts">
   import { defineComponent, ref,readonly} from "vue";
   import { getShareProductInfo,getProductInfoList,getProductIdList} from '@/api/util'
   import routerChangeReData from '@/common/routerChangeReData'
   import{purchaseListModel, getPurchaseList, removePurchase, accextPurchase,exportPurchase} from "@/api/Investment/purchase"
   import shipmentDialog from '@/components/investorment/shipmentDialog.vue'
   import accectPurchaseDialog from "@/components/investorment/accectPurchaseDialog.vue"
   import addPurchaseOrderDialog from './components/addPurchaseOrderDialog.vue';
   import exportDialog from "@/components/export/exportDialog.vue";
   import addNoOrderPurchaseDialog from "./components/addNoOrderPurchaseDialog.vue";
   import { ElMessage, ElMessageBox } from "element-plus";
   import dayjs from 'dayjs'
   let investorOrderId="";
   export default defineComponent({
     name: "purchase",
     components: {
      'v-shipment-dialog':shipmentDialog,
      accectPurchaseDialog,
      addPurchaseOrderDialog,
      exportDialog,
      addNoOrderPurchaseDialog,
     },
     setup() {
      const loading = ref(false);
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const list = ref<purchaseListModel[]>([]);
      const sheareProductInfo = getShareProductInfo()
      const shipmentDialogRef = ref<any>()
      const accectPurchaseDialogRef = ref<any>();
      const addPurchaseOrderDialogRef = ref<any>();
      const addNoOrderPurchaseRef = ref<any>();
      const productList = getProductInfoList()
      const productIdList = getProductIdList()
      const exportDialogRef = ref<any>();
      const mixin_router_search = ref({
        status: '', //投资订单状态，0 已下单；1 已付款；2 已出仓；3 已开始投放；4 已寄出；
        careateBN: '',
        order: 'gmtCreate',
        sort: 'desc',
      })
      const mixin_select_search = ref({
        search: {
          account:'', //投资人手机号
          investorname:'',//投资人姓名
          investoruserid: '', // 投资人用户编号
          orderid: '', //投资订单编码
        },
      })
      const mixin_select_search_label = readonly({
      search: {
          investoruserid: '投资人ID', // 投资人用户编号
          orderid: '订单ID', //投资订单编码
          account:'手机号', 
          investorname:'姓名',
        },
      })
      const searchSelect = ref({search: 'account'})
      const reData = async(rq?: any) => {
        try {
          loading.value = true;
          let rqData: any = {current: page.value,size: limit.value}

          if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
            }
            console.log("筛选时间",rq.careateBN);
            if (rq.careateBN) {
              rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
              rq.endTime =  dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
              delete rq.careateBN
            }
            rqData = { ...rqData, entity: rq }
          }
          const res = await getPurchaseList(rqData);
          console.log('请求列表='+res)
          list.value = res.rows
          total.value = res.total
        } finally {
          loading.value = false;
        }
      }
   
      const { mixinReRouter, onPageChange, handleLimitChange, changSearch} =
        routerChangeReData({
          mixin_router_search,
          mixin_select_search,
          loading,
          limit,
          page,
          reData,
          searchSelect,
      })
      const handelChange = () => {
        page.value = 1
        mixinReRouter()
      }
      
      
      const handleAddNewPurchase = async() => {
        addPurchaseOrderDialogRef.value.open();
      }
     
      const handleAddNoOrderPurchase = async() =>{
        addNoOrderPurchaseRef.value.open();
      }

      const handleSuccess = (msg:string) =>{
        ElMessage.success(msg)
        page.value = 1
        mixinReRouter()
      }
      const handlerShipment = (item:any) =>{
        shipmentDialogRef.value.open(item.account,item.investmentOrderId,item.id,item.quantity);
      }
      const handlerAccept = async (item:any) =>{
        await ElMessageBox.confirm(
          `是否接收采购单id=${item.id}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await accextPurchase({ id :item.id })
        handelChange();
        ElMessage.success(res.msg)
      }
      const handlerDelete = async(item:any) =>{
        
        await ElMessageBox.confirm(
          `是否删除采购单id=${item.id}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )

        const res = await removePurchase({ id :item.id })
        page.value=1;
        reData();
        ElMessage.success(res.msg)
      }
      const handleExportPurchase = () =>{
        exportDialogRef.value.open('采购单历史表')
      }

      const exportFile = async(name:string) =>{
      try {
        // let rqData: any = { current: page.value, size: limit.value }
        
        // if (rq && JSON.stringify(rq) !== '{}') {
        //   rqData = { ...rqData, entity: rq }
        // }

        let searchData: any = {}
        let rqData: any = {current: page.value,size: limit.value,order:'gmtCreate',sort:'desc',fileName:'purchase'}
       
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          entity.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          entity.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
          delete searchData.careateBN
        }
        if(searchData.status!=''&&searchData.status){
          entity.status=searchData.status;
        }
        delete searchData.status
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportPurchase(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
      return {
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,
        changSearch,      
        searchSelect,
        limit,
        page,
        total,
        list,
        productList,
        productIdList,
        loading,
        sheareProductInfo,
        shipmentDialogRef,
        accectPurchaseDialogRef,
        addPurchaseOrderDialogRef,
        addNoOrderPurchaseRef,
        exportDialogRef,
        reData,
        handleAddNewPurchase,
        handleAddNoOrderPurchase,
        handelChange,
        onPageChange,
        handleLimitChange,
        handleSuccess,
        handlerShipment,
        handlerDelete,
        handlerAccept,
        handleExportPurchase,
        exportFile,
      }
     }
   })    
   
   </script>
   <style lang="scss" scoped> 
   .container {
      padding: 18px;
      header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
    .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
   }
  }  
   </style>