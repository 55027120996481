<!-- 添加投资订单 -->
<template>
  <el-dialog
    :append-to-body="true"
    :destroy-on-close="true"
    :title="`新增投资订单`"
    @closed="handleClose"
    v-model="visible"
    width="700px"
  >
    <el-steps :active=activeStep finish-status="success" simple >
      <el-step title="填写投资人" ></el-step>
      <el-step title="确认订单" ></el-step>
      <el-step title="投放方式" ></el-step>
    </el-steps>
    
    <el-form :model="userData" label-width="120px"  ref="userDataRef" label-position="right" v-show="activeStep==1" style="margin-top: 20px;">
      <el-form-item label="名称" class="tip-text" prop="name">
        <el-input v-model="userData.name" style="width: 220px;" ></el-input>
        <el-button type="primary" @click="onCheckInvestor" style="margin-left: 20px;" :loading="checkInvestorLoading" >查询运营团队</el-button>
      </el-form-item>
      <el-form-item label="运营团队" class="tip-text" prop="teamname">
        <el-input v-model="userData.teamname" style="width: 220px;" :disabled="true"></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="userData" label-width="120px"  style="margin-top: 20px;" v-show="activeStep==2">
      <el-form-item label="运营团队">
          <span class="span-border">{{userData.teamname}}</span>
      </el-form-item>
    </el-form>
    <el-form :model="selectData" label-width="120px" ref="selectDataRef" style="margin-top: 20px;" v-show="activeStep==2">     
      <el-form-item label="设备类型" >
        <el-select placeholder="请选择订单类型" v-model="selectData.articleproductid">
          <el-option label="二代小魔柜" value="9775f7dd993646eaa4977537b0735e8f"></el-option>
          <el-option label="三代小魔柜" value="6951e86506725baaf4dfcdf9aa015395"></el-option>
          <el-option label="雨伞架" value="a2ea3faa86274d3f966ff30aad68b208"></el-option>
          <el-option label="便民柜" value="0a9edc30d4924f989fd9207bf62e6d23"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型" >
        <el-select placeholder="请选择订单类型" v-model="selectData.fortest">
          <el-option :value="false" label="正常订单"></el-option>
          <el-option :value="true" label="测试订单"></el-option>
        </el-select>
      </el-form-item>   
      <el-form-item label="是否为免费赠送">
        <el-select placeholder="请选择是否免费" v-model="selectData.freegift">
          <el-option :value="true " label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
        <my-tip content="请慎重选择"></my-tip>
      </el-form-item>   
    </el-form>
    <el-form
      :model="selectData"
      :rules="rules"
      label-width="120px"
      style="margin-top: 20px;"
      ref="selectDataRef"
      v-show="activeStep==3">
      <el-form-item label="投放类型">
        <el-select placeholder="请选择投放类型" v-model="selectData.launchmode">
          <el-option label="自投放" value="SELF"></el-option>
          <el-option label="平台投放" value="PLATFORM"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form
      :model="selfData"
      :rules="rules"
      label-width="120px"
      ref="selfDataRef"
      v-show="activeStep==3">
      <el-form-item label="收件联系人" prop="mailingcontact">
        <el-input v-model="selfData.mailingcontact"></el-input>
      </el-form-item>
      <el-form-item label="收件联系电话" prop="mailingnumber">
        <el-input v-model="selfData.mailingnumber"></el-input>
      </el-form-item>
      <el-form-item label="收件地址" prop="mailingaddress">
        <el-input v-model="selfData.mailingaddress"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :model="platformData"
      :rules="rules"
      label-width="120px"
      ref="platformDataRef"
      v-show="activeStep==2">
      <el-form-item label="机柜数量" prop="articlenum">
        <el-input-number
          :min="1"
          :precision="0"
          :step="1"
          controls-position="right"
          v-model="platformData.articlenum"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="单价" prop="singleamount">
        <el-input-number
          :min="0"
          :precision="2"
          :step="1"
          controls-position="right"
          v-model="platformData.singleamount"
        ></el-input-number>
        <!-- <span>&ensp;总额{{platformData.singleamount*platformData.articlenum}}</span> -->
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>                     
        <el-button  type="primary" v-show="activeStep>1" @click="onPrevClick">上一步</el-button>
        <el-button  type="primary" v-show="activeStep<3" @click="onNextClick">下一步</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary" v-show="activeStep==3">下 单</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentCabinetOrder,
  investmentorderUpdate,
} from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
import { operateModel, operateListHttp, removeOperateHttp} from '@/api/Operate/index'
import { userList } from "@/api/User/index"
import { userRoleList } from '@/api/User/role'
let index: any; // 编辑的index
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const searchUsertype = ref("phone");
    const visible = ref(false);
    const activeStep=ref(1);
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const orderId = ref("");
    const roleArray = ref<any>([]);
    const userDataRef = ref<any>({});
    const selfDataRef = ref<any>({});
    const selectDataRef = ref<any>({});
    const platformDataRef = ref<any>({});

    const userData = ref({
      name:'',
      teamname:'',
      operationid:'',

    });
    const selectData = ref({
      articleproductid: "9775f7dd993646eaa4977537b0735e8f",
      launchmode: "PLATFORM",
      investortype: "user",
      fortest: false,
      freegift: true,
      operationid:'',
      salesmanuserid:''
    });
    const selfData = ref({
      mailingcontact: "",
      mailingnumber: "",
      mailingaddress: "",      
    });
    const platformData = ref({
      articlenum: 10,
      singleamount: 0.0,
    });
    const rules = readonly({
      articlenum: [Rules.required()],
    });

    const open = (row: any, _index: number) => {
      visible.value = true;
      index = _index;
    };
    const onCheckInvestor = async () => {
      if(userData.value.name){
        checkInvestorLoading.value=true;   
        roleArray.value=[];
        getUserRole()
      }else{
        ElMessage.success("请先输入运营员名称再查询");          
      }
    };

    const handleClose = async () => {
      selectDataRef.value.resetFields();
      selectDataRef.value.clearValidate();
      selfDataRef.value.resetFields();
      selfDataRef.value.clearValidate();
      platformDataRef.value.resetFields();
      platformDataRef.value.clearValidate();
      userDataRef.value.resetFields();
      userDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
      index = "";
      activeStep.value=1;
      roleArray.value=[];
    };

    const onNextClick = async () => {
        if(activeStep.value==1){
          if(userData.value.operationid&&userData.value.operationid!=''){
            activeStep.value=2
          }else{
            ElMessage.success("请先查询需要下单的运营员账号");          
          }
        }else if (activeStep.value==2){
          activeStep.value=3;
        }
    };
    
    const onPrevClick = async () => {
       if(activeStep.value==3){
          activeStep.value=2
        }else if (activeStep.value==2){
          activeStep.value=1;
        }
    };

    
   

    const getUserRole = async () => {
      try {
        let rqData: any = { current: "1", size: "10",order: "gmt_create",sort: "desc",entity:{name:userData.value.name}}
       
        const res = await operateListHttp(rqData)
        console.log('运营员信息=',res)
        roleArray.value = res.rows;
        userData.value.teamname=roleArray.value[0].name;
        userData.value.operationid=roleArray.value[0].id; 
        selectData.value.operationid=roleArray.value[0].id;
        checkInvestorLoading.value=false;
      }catch(err){ 
        checkInvestorLoading.value=false;
        ElMessage.success("查询失败，请确认输入的运营团体是否存在");
        
      }
    }

    const submit = async () => {
      try {
        const rqData = {
          operationid: userData.value.operationid,
          articlenum: platformData.value.articlenum,
          singleamount:platformData.value.singleamount,
          articleproductid:selectData.value.articleproductid,
          fortest:selectData.value.fortest,
          freegift:selectData.value.freegift,
          launchmode:selectData.value.launchmode,
          salesmanuserid: userStore.info.userid
        };
       
        let submitDate = {...rqData};

        if(selfData.value.mailingaddress){
          submitDate={...submitDate,...selfData.value}
        }
      
        btnSumitLoading.value = true;
        const res = await investmentCabinetOrder(submitDate);
        ElMessage.success(res.msg);
        handleClose();
        emit("success", {
          isEdit: !!orderId.value,
          data: res.record,
          index,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeArticletype = (e: string) => {  

    };
    const changeInvestortype = (e: string) => {
      if (e == "platform") {
        
      }
    };
    return {
      searchUsertype,
      activeStep,
      rules,
      userData,
      selectData,
      platformData,
      selfData,
      visible,
      btnSumitLoading,
      checkInvestorLoading,
      open,
      userDataRef,
      selfDataRef,
      selectDataRef,
      platformDataRef,
      onCheckInvestor,
      submit,
      handleClose,
      onNextClick,
      onPrevClick,
      changeArticletype,
      changeInvestortype,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 0 0 24px 36px;
  .el-select {
    margin-right: 21px;
  }
}

.tip-text{
  font-size: 16px;
  font-weight: bold;
}

.span-border {
  border: 1px solid #DCDFE6; 
  border-radius: 5px;
  width: 205px; 
  height: 40px; 
  display: block; 
  padding-left: 14px;
}
</style>