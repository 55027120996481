import { h, defineComponent, ref, toRef, onMounted, watch, nextTick, watchEffect } from 'vue';
import echarts from "./base"
// const liquidFill: any = require("echarts-liquidfill/index.js")
// console.log('liquidFill :>> ', liquidFill);
import { unwarp } from '@/utils';
// echarts.use([liquidFill])
import "echarts-liquidfill";
export default defineComponent({
    name: "my-liquid-fill",
    props: ["option"],
    setup(props: any) {
        const option = toRef(props, 'option')
        const liquidFillRef = ref<any>("")
        const myChart = ref<any>('')
        const myRender = async (_option?: any) => {
            await nextTick()
            unwarp(myChart.value).setOption(_option || option.value)
        }
        onMounted(async () => {
            await nextTick()
            myChart.value = echarts.init(liquidFillRef.value);
            watchEffect(async () => {
                myRender(option.value)
            })
        })
        const myResize = async () => {
            await nextTick()
            myChart.value.resize && myChart.value.resize()
        }
        return {
            liquidFillRef,
            myResize,
            myRender
        }
    },
    render() {
        return h('div', {
            ref: 'liquidFillRef',
            style: {
                width: "100%",
                height: "100%"
            }
        })
    }
})