<template>
  <el-select :class="isBig" v-model="mySelectItem" placeholder="选择类型" @change="change" value-key="name">
    <el-option v-for="item of getItems" :key="item.productid" :label="item.name+type" :value="item"> </el-option>
  </el-select>
</template>


<script lang="ts">
import { getShareProductInfo } from "@/api/util";
import { deepCopy } from "@/utils";
import { defineComponent, ref, readonly, toRef, computed } from "vue";
export default defineComponent({
  // props: ["isBig", "type"],
  props: {
    isBig: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    selectItems: {
      type: Array,
    },
    selectItem: {
      type: String,
      default: () => getShareProductInfo()[0],
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const change = (e: any) => {
      emit("change", e);
    };
    // const items = ref(getShareProductInfo());
    const items = getShareProductInfo();
    const selectItems = toRef(props, "selectItems");
    const selectItem = toRef(props, "selectItem");
    const mySelectItem = ref(selectItem.value);
    const getItems = computed(() => {
      return selectItems.value ? selectItems.value : items;
    });
    return {
      change,
      mySelectItem,
      getItems,
    };
  },
});
</script>


<style lang="scss" scoped>
.el-select {
  color: #00f4f4;
  height: 36px;
  line-height: 36px;
  // width: 130px;
  font-size: 15px !important;
}
.isBig {
  .el-select {
    min-width: 150px;
  }
}
</style>
<style scoped>
/* <style scoped vars="{ size }"> */
.el-select >>> .el-input__inner {
  border: none;
  background: none;
  color: #00f4f4;
  height: 36px;
  line-height: 36px;
  text-align: center;
  /* width: 120px; */
}
.isBig >>> .el-input__inner {
  font-size: 16px;
  /* min-width: 160px; */
}
</style>