<template>
    <div class="container">
      <header>
       <el-input
          @keyup.enter="handelChange"
          class="input-with-select"
          placeholder="请输入搜索内容"
          v-model="mixin_select_search.search[searchSelect.search]"
        >
          <template #prepend>
            <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
              <el-option
                :key="item"
                :label="mixin_select_search_label.search[item]"
                :value="item"
                v-for="item of Object.keys(mixin_select_search.search)"
              ></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="handelChange" icon="el-icon-search"></el-button>
          </template>
        </el-input>
     
         <el-date-picker
          @change="handelChange"
          align="right"
          class="header-select"
          end-placeholder="开始时间"
          range-separator="至"
          start-placeholder="结束时间"
          type="datetimerange"
          unlink-panels        
          v-model="mixin_router_search.careateBN"
        ></el-date-picker>
      </header>
  
      <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
        <el-table-column label="序号" min-width="34">
          <template #default="scope">      
            <img src="@/assets/img/icon_repair.png" alt="" v-if="scope.row.needMaintain" style="width: 16px;margin-top: 8px;"> 
            <span v-else style="width: 16px; display: inline-block;"></span>
            {{scope.row.id}}
            <my-copy :data="scope.row.id"></my-copy>
          </template>
        </el-table-column>
        <el-table-column label="机柜SN" align="center" min-width="32">
          <template #default="scope">      
            <span @click="showCabintDivece(scope.row.panelSn)" class="span-click"> {{scope.row.panelSn}}</span>
            <my-copy :data="scope.row.panelSn"></my-copy>     
          </template>
        </el-table-column>
        <el-table-column label="仓位口" align="center" min-width="24">
          <template #default="scope">{{scope.row.socketSn}}</template>
        </el-table-column>
        <el-table-column label="开始设备" align="center" min-width="52">
          <template #default="scope">sn:{{scope.row.equipmentSnStart}}/mac:{{scope.row.equipmentMacStart}}</template>
        </el-table-column>
        <el-table-column label="完结设备" align="center" min-width="52">
          <template #default="scope">sn:{{scope.row.equipmentSnEnd}}/mac:{{scope.row.equipmentMacEnd}}</template>
        </el-table-column>
        <el-table-column label="故障码" align="center" min-width="32">
          <template #default="scope">{{scope.row.faultCode}}</template>
        </el-table-column>
        <el-table-column align="center" label="故障状态" min-width="24">
            <template #default="scope"> 
                <el-tag  type="waring" v-if="scope.row.status==0">未解决</el-tag>    
                <el-tag  type="success" v-if="scope.row.status==1">已解决</el-tag>
            </template>
        </el-table-column>
        
        <el-table-column label="开始时间" align="center" min-width="50">
          <template #default="scope">{{scope.row.gmtStart}}</template>
        </el-table-column>
        <el-table-column label="完结时间" align="center" min-width="50">
          <template #default="scope">{{scope.row.gmtEnd}}</template>
        </el-table-column>
       
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :page-sizes="[10, 30, 50, 100]"
        :total="total"
        @current-change="onPageChange"
        @size-change="handleLimitChange"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <cabinetDatailsDialog ref ="cabinetDetailsDialogRef"></cabinetDatailsDialog>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, readonly, inject } from 'vue'
  import { listModel, cabientListHttp } from '@/api/fault/cabient'
  import routerChangeReData from '@/common/routerChangeReData'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { userStore } from '@/store/modules/user'
  import { getShareProductInfo } from '@/api/util'
  import cabinetDatailsDialog from '@/components/device/cabinetDatailsDialog.vue'
  import dayjs from 'dayjs'
  export default defineComponent({
    name: 'fault',
    components: {
        cabinetDatailsDialog
    },
    setup() {
      const cabinetDetailsDialogRef = ref<any>();
      const mixin_router_search = ref({
        careateBN:'',
        order: 'gmtCreate',
        sort: 'desc',
      })
      const mixin_select_search = ref({
        search: {
          panelSn:'', //设备sn
        },
      })
      const sheareProductInfo = getShareProductInfo()
      const mixin_select_search_label = readonly({
        search: {
          panelSn: '机柜SN', 
        },
      })
      const searchSelect = ref({search: 'panelSn'})
  
      const loading = ref(false)
      const limit = ref(10)
      const page = ref(1)
      const total = ref(0)
      const list = ref<listModel[]>([])
      const btnRemoveLoading = ref(false)
      const searchData = {
        ...mixin_router_search.value,
        ...mixin_select_search.value,
      }
  
      const reData = async (rq?: any) => {
        try {
          loading.value = true
           let rqData: any = { current: page.value, size: limit.value }
  
          if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
            }
            if (rq.careateBN) {
              rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
              rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
              delete rq.careateBN
            }
            rqData = { ...rqData, entity: rq }
          }
          const res = await cabientListHttp(rqData)
          list.value = res.rows
          total.value = res.total
        } finally {
          loading.value = false
        }
      }
      const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
        routerChangeReData({
          mixin_router_search,
          mixin_select_search,
          loading,
          limit,
          page,
          reData,
          searchSelect,
        })
      const handelChange = () => {
        page.value = 1
        mixinReRouter()
      }
     
      const showCabintDivece =(sn:string) =>{
        cabinetDetailsDialogRef.value.open(sn,null)
      }
      
      return {
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,   
        onPageChange,
        handelChange,
        handleLimitChange,
        mixinReRouter,
        searchSelect,
        changSearch,
        list,
        loading,
        btnRemoveLoading,
        page,
        limit,
        total,   
        sheareProductInfo,
        cabinetDetailsDialogRef,
        showCabintDivece,
      }
    },
  })
  </script>
  <style lang="scss" scoped>
  .container {
    padding: 18px;
    header {
      display: flex;
      flex-wrap: wrap;
      .header-select {
        width: 130px;
        margin: 0 24px 24px 0;
        ~ * {
          margin: 0 24px 24px 0;
        }
       /deep/.el-input {
          width: 130px;
         
        }
         .el-select {
          width: 130px;
          /deep/.el-input{
            width: 130px;
          }
        }
      }
      .content {
        padding: 0;
      }
      .input-with-select{
        width: 320px;
        margin-right: 16px;
        
        /deep/.el-input{
          width: 120px;
        }
      }
      .input-select-tip{
          width: 130px;
      }
    }
    .no-more {
      color: #aaa;
    }
    .el-table {
      .el-button {
        margin: 0.04rem;
      }
    }
  
    .header-el-selector {
      width: 120px;
      margin-right: 12px;
       /deep/.el-input {
        width: 120px;
      }
    }
    .span-click {
        color: #409eff;
        cursor:pointer;
    }
  }
  </style>