<!--侧边栏模板-->
<template>
  <div :class="classObj" class="menu-warp">
    <div :class="{'home-menu-warp':isShow}">
      <!--一级导航栏-->
      <el-menu 
        :background-color="isShow?'#001333':'#304156'"
        :class="{'nav-menu-shrink':sidebar.opened,'is-home':isShow,'nav-menu':!isShow}"
        :collapse="!sidebar.opened"
        :default-active="active"
        :router="true"
        :unique-opened="true"
        @open="open"
        active-text-color="#409EFF"
        text-color="#bfcbd9"
      >
        <v-item :key="route.path" :mobile="device === 'mobile'" :route="route" v-for="(route) in routes"></v-item>
        <!--客服或者平台售后显示客服项-->
        <el-menu-item @click="toCustomerServive" class="menu-item" v-if="roleShowPermission(['ROLE_CALLCENTER','ROLE_POSTSALE','ROLE_OPERATOR'])">
          <span class="icon el-icon-s-custom" style="font-size:12px"></span>
          <template #title>
            <span class="title">客服</span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script lang="ts">
import Item from './Item.vue'  //侧边栏的单条item
import { defineComponent, computed, toRef } from 'vue'
import { appStore } from '@/store/modules/app'  //存储
import { routerStore } from '@/store/modules/router'  //路由管理工具，有两个默认一个主页，登录页面
import { useRoute } from 'vue-router'  //路由
import checkRole from '@/common/checkRole' //检查用户是否有侧边导航权限
export default defineComponent({
  props:['isShow'],
  name: 'v-nav-menu',
  setup() {
    const sidebar = computed(() => appStore.getSidebar)
    const device = computed(() => appStore.getDevice)
    const route = useRoute()
    const { roleShowPermission } = checkRole()
    const routes = computed(() => {
      return routerStore.getAddRouters.map((it) => {
        if (it.children && it.children.length == 1) {
          it = it.children[0]
        }
        return it
      })
    })
    const active = computed(() => route.path)
    const classObj = computed(() => {
      return {
        openSidebar: sidebar.value.opened,
        hideSidebar: !sidebar.value.opened,
        mobile: device.value === 'mobile',
      }
    })
    const toCustomerServive = () => {
      window.open('https://mpkf.weixin.qq.com', '_blank')
    }
    const open = (e: any) => {
      console.log('open e :>> ', e)
    }
    return {
      sidebar,
      device,
      routes,
      active,
      classObj,
      toCustomerServive,
      roleShowPermission,
      open,
    }
  },
  components: {
    'v-item': Item,
  },
})
</script>

<style lang="scss" scoped>
.hideSidebar {
  overflow: hidden;
}
.mobile.hideSidebar {
  display: none;
}
.mobile.openSidebar {
  position: fixed;
  top: 0;
}
.menu-warp {
  .nav-menu-shrink {
    min-width: 180px;
  }
  .home-menu-warp {
    box-sizing: border-box;
    background-image: url('../../../assets/img/menubg.png');
    background-size: 100% 100%;
    padding: 8px 5px;
    // background-image: url("../../../assets/img/homebg.png") !important;
    background-color: none !important;
    .title {
      left: 0;
      right: 0;
      margin: auto;
      top: 40px;
      color: #00f4f4;
    }
  }
  .nav-menu:not(.el-menu--collapse) {
    height: 100vh;
    overflow-y: scroll;
    border: none;
    scrollbar-width: none; // firefox下滚动条不显示
    -ms-overflow-style: none; // IE下滚动条不显示.
    //设置侧边栏滚动条不显示 chrome
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .is-home {
    background: url('../../../assets/img/homebg.png') !important;
    // background-image: url("../../../assets/img/menubg.png");
    height: 100vh;
    border: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .is-home:not(.el-menu--collapse) {
  }
  .el-menu--collapse {
    background: #001333;
    width: 54px;
    height: 100vh;
    border: 1px solid rgb(0, 86, 116);
  }
}
</style>

<style scoped>
.menu-item .icon {
  margin-right: 16px;
}
.menu-warp >>> .nav-menu .is-active {
  background: #263445 !important;
}
.menu-warp >>> .nav-menu .el-submenu .el-menu-item {
  min-width: 180px;
  background: #1f2d3d !important;
}
.menu-warp >>> .el-menu.el-menu--popup-right-start {
  height: 100vh;
  overflow-y: scroll;
}
.menu-warp >>> .el-menu-item {
  font-size: 12px !important;
}
.menu-warp >>> .el-submenu__title {
  font-size: 12px !important;
}
.menu-warp >>> .nav-menu .el-submenu .el-menu-item:hover {
  background: #001528 !important;
}

.menu-warp >>> .menu-item .menu-item .el-submenu__title {
  background: #1f2d3d !important;
}
.menu-warp >>> .menu-item .menu-item .el-submenu__title:hover {
  background: #001528 !important;
}

.menu-warp >>> .is-active {
  background: rgba(255, 255, 255, 0.1) !important;
}
.menu-warp >>> .is-home .el-menu {
  background: rgba(0, 0, 0, 0.1) !important;
}
.menu-warp >>> .is-home .el-submenu .el-menu-item {
  min-width: 180px;
  background: rgba(0, 0, 0, 0.1) !important;
}
.menu-warp >>> .is-home .el-menu-item:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
.menu-warp >>> .is-home .el-submenu__title:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
.menu-warp >>> .is-home .menu-item .menu-item .el-submenu__title {
  background: rgba(0, 0, 0, 0.1) !important;
}
.menu-warp >>> .is-home .menu-item .menu-item .el-submenu__title:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

/* .el-menu--vertical >>> .el-menu-item {
  background: rgba(255, 255, 0) !important;
} */
.menu-item-shrink >>> .el-submenu__icon-arrow {
  display: none !important;
}
</style>

