<template>
  <div class="ringwarp" :style="{width:pieHeight,height:pieHeight}">
    <v-pie :style="{width:pieHeight,height:pieHeight}" :option="option" ref="pieRef" key="pie"></v-pie>
    <div class="chart-container">
      <span class="top now" v-for="(item) of chartData" :key="item.name">
        {{item.name}} : <span class="number">{{item.value}}</span>
      </span>
    </div>
  </div>
</template>


<script lang="ts">
import { computed, defineComponent, ref, toRef } from "vue";
import pie from "@/components/echart/pie";
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => "180px",
    },
    chartData: {
      type: Array,
    },
  },
  components: {
    "v-pie": pie,
  },
  setup(props) {
    const chartData = toRef(props, "chartData");
    const option = computed(() => {
      return {
        tooltip: {
          trigger: "item",
        },
        color: ["#00F4F4", "#005674"],
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            // hoverOffset: 10,
            radius: ["70%", "80%"],
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            data: chartData.value,
          },
        ],
      };
    });
    const pieRef = ref<any>(null);
    const reView = () => {
      pieRef.value.myRender();
    };
    return {
      option,
      pieRef,
      reView,
    };
  },
});
</script>


<style lang="scss" scoped>
.ringwarp {
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 86, 116, 0.1);
  border-radius: 50%;
  position: relative;
  .chart-container {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 36px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transform: translate(-50%, -50%);
    font-size: 12px;
    .number {
      font-weight: bold;
    }
    .now .number {
      font-weight: bold;
      color: #ff6f3e;
    }
  }
}
</style>