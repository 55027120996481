
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentorderList,
  InvestmentorderListRes
} from '@/api/Investment/Order'
import { ceatePurchase } from "@/api/Investment/purchase"
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
import { userList } from "@/api/User/index"
import { userRoleList } from '@/api/User/role'
let index: any; // 编辑的index
export default defineComponent({
  name: "v-dialog",
  setup(props, { emit }) {

    const visible = ref(false);
    const activeStep=ref(1);
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0) 
    const list = ref<InvestmentorderListRes[]>([])
    const investortype = ref("phone");
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const investorDataRef = ref<any>({});
    const ipurchaseDataRef = ref<any>({});
    const investorData = ref({
      investorOrderId:'',
      phone:"",
      name:"",
      deviceType:"",
    });
    const ipurchaseData = ref({
      investorUser:'',
      deviceMolde:"",
      number:'',
    });
    const rules = readonly({
      number: [Rules.required()],
     
    });
    const handleClose = async () => {
      investorDataRef.value.resetFields();
      investorDataRef.value.clearValidate();
      ipurchaseDataRef.value.resetFields();
      ipurchaseDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      btnSumitLoading.value = false;
      index = "";
      activeStep.value=1;
      list.value=[];
    };
    const open = () => {
      visible.value = true; 
    };
    const onNextClick = async () => {
        if(activeStep.value==1){
          console.log('投资人账号',investorData.value.phone)
          if(investorData.value.phone||investorData.value.name){
            activeStep.value=2;
            list.value=[];
            getInvestorOrder();
          }else{
            ElMessage.success("请先输入投资人账号或者姓名");          
          }
         
        }else if (activeStep.value==2){
          if(investorData.value.investorOrderId){
            activeStep.value=3;
          }else{
            ElMessage.success("请先选择一笔投资订单");
          }
          
        }
    };
    
    const onPrevClick = async () => {
       if(activeStep.value==3){
          activeStep.value=2
        }else if (activeStep.value==2){
          activeStep.value=1;
        }
    };

    const changeInvestortype = async(item:any) =>{
      console.log('搜索参数',item)
    
    }
   
    const onCheckInvestorOrder = async() =>{
     
    }

    const getInvestorOrder = async() =>{
      try {
        loading.value = true
        let rqData: any = {current: page.value,size: limit.value,order:"creationdate",sort:"desc"}
        let entity: any = {fortest:true}
        if(investortype.value=='phone'){
          entity.account=investorData.value.phone
        }else{
          entity.investorname=investorData.value.name
        }
        rqData = { ...rqData,entity:{...entity}}
        const res = await investmentorderList(rqData)
        list.value = res.records
        total.value = res.total
        list.value=list.value.map( item =>{
          if(item.orderid==investorData.value.investorOrderId){
            item.check=true
          }else{
            item.check=false
          }
          return item
        })
       
      } finally {
        loading.value = false
      }
    }

    const handleCurrentChange = (item:any) =>{
      console.log('选择数据',item)
      if(item){
        investorData.value.investorOrderId=item.orderid
        ipurchaseData.value.investorUser=`${item.investorname}(${item.account})`
        ipurchaseData.value.deviceMolde=`${item.articleproductname}`
        list.value=list.value.map( v =>{
          console.log('遍历id',v.orderid)
          if(v.orderid==investorData.value.investorOrderId){
            console.log('设置为true')
            v.check=true
          }else{
            v.check=false
          }
          return v
        })
      }
    }

    const onPageChange =(val:number) =>{
      page.value=val;
      getInvestorOrder()
    }

    const handleLimitChange =(val:number) =>{
      limit.value=val;
      getInvestorOrder()
    }

    const submit = async () => {
      try {  
        const res= await ceatePurchase ({investmentOrderId:investorData.value.investorOrderId,quantity:ipurchaseData.value.number}) 
        handleClose()
        emit('success',res.msg)
      } finally {
        btnSumitLoading.value = false
      }
     
    };
   
    return {
      loading,
      list,
      activeStep,
      investortype,
      visible,
      btnSumitLoading,
      checkInvestorLoading,
      investorData,
      investorDataRef,
      ipurchaseData,
      ipurchaseDataRef,
      page,
      limit,
      total,
      rules,
      open,
      submit,
      handleClose,
      onNextClick,
      onPrevClick,
      changeInvestortype,
      onCheckInvestorOrder,
      handleCurrentChange,
      onPageChange,
      handleLimitChange,
    };
  },
});
