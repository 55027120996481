
import { defineComponent, ref, computed,readonly} from "vue";
import assignDialog from "./assignDialog.vue";
import assignNewDialog from "./assignNewDialog.vue";
import {
  investmentordershipmentList,
  InvestmentordershipmentListRes,
  outwarehouse,
  startlaunch,
  addNoInvestorOrderShipmentHttp,
} from "@/api/Investment/shipment";
import {investmentorderCreateshipment} from '@/api/Investment/Order'
import { ElMessage, ElMessageBox } from "element-plus";
import { getStatusName } from "./editData";
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: "v-dialog",
  components: {
    "v-assign-dialog": assignDialog,
    "v-assign-new-dialog":assignNewDialog,
  },
  setup() {
    const name = ref("");  
    const visible = ref(false);
    const activeName = ref("");
    const loading = ref(false);
    const articlenum = ref<number>(0); //下单小魔夹数量
    const btnOutLoading = ref(false);
    const investmentorderid = ref("");
    const platformlaunchbegin = ref();
    const platformlaunchend = ref();
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<InvestmentordershipmentListRes | any>([]);
    const assignDialogRef = ref<any>();
    const assignNewDialogRef = ref<any>();
    const open = (_name:string,_investmentorderid:string,_articlenum:number) =>{
        visible.value = true;
        name.value=_name;
        articlenum.value=_articlenum;
        investmentorderid.value=_investmentorderid;
        reData();
    }

    const reData = async() => {
      try {
        loading.value = true;
        let rqData: any = {
          current: page.value,
          size: limit.value,
          order:"creationdate",
          sort:'desc',
          entity:{
            investmentorderid:investmentorderid.value,
            status:activeName.value,
            platformlaunchbegin:platformlaunchbegin.value,
            platformlaunchend:platformlaunchend.value
          }
        };
        console.log('筛选值='+activeName.value)
        if(activeName.value===null||activeName.value===""||activeName.value.endsWith("6")){
            delete rqData.entity.status
        }
        if(platformlaunchbegin.value===null||platformlaunchbegin.value===""){
            delete rqData.entity.platformlaunchbegin
        }
        if(platformlaunchend.value===null||platformlaunchend.value===""){
            delete rqData.entity.platformlaunchend
        }
        const res = await investmentordershipmentList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    }

    const handleClose = () =>{
      visible.value = false;
    }

    const handelChange = (val:any) =>{
      platformlaunchbegin.value=val
      page.value=1;
      reData();
    }
    const handelLaunchChange = (val:any) => {
      platformlaunchend.value= val
      page.value=1;
      reData();
    }
    const handleClick = () => {
      if(activeName.value==="6"){
        activeName.value=""
      }
      page.value=1;
      reData();
    }
    const handleAddNewShipment = async() => {
      try {
        const input = await ElMessageBox.prompt('请输入出货数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: `请输入 1-`,
          inputType: 'number',
        })
        console.log('input :>> ', input)
        const _value = parseInt(input.value)
        if (_value > articlenum.value || _value < 1)
          return ElMessage.error(`请输入`)
        if (!userStore.info.userid) return userStore.loginOut()
        const res = await investmentorderCreateshipment({
          orderid:investmentorderid.value,
          quantity: _value,
          shipperuserid: userStore.info.userid,
        })
        console.log('res :>> ', res)
        ElMessage.success(res)
        reData();
      } catch (error) {
        if (error == 'cancel') return
        if (error == 'colse') return
        ElMessage.error(error)
      }
    }
    const handleShowAssgin = (item:any) =>{
      assignNewDialogRef.value.open(item);
    }
    const handleAssgin = (item:any) => {
      assignNewDialogRef.value.open(item);
    }
    const handleOut = async(index:number,shipmentid: string) =>{
      try {
        btnOutLoading.value = true;
        const res = await outwarehouse({
          shipmentid,
        });
        ElMessage.success(res.msg);
        reData();
      } finally {
        btnOutLoading.value = false;
      }
    }
    const handleStartLaunch = async(index: number, shipmentid: string) => {
      try {
        btnOutLoading.value = true;
        const res = await startlaunch({
          shipmentid,
        });
        ElMessage.success(res.msg);
        reData();
      } finally {
        btnOutLoading.value = false;
      }
    }
    const onPageChange = (_page:number) =>{
        page.value=_page
        reData();
    }
    const handleLimitChange = (_size:number) =>{
        limit.value=_size
        reData();
    }
    return {
      name,
      visible,
      btnOutLoading,
      activeName,
      platformlaunchbegin,
      platformlaunchend,
      articlenum,
      limit,
      page,
      total,
      list,
      loading,
      getStatusName,
      assignDialogRef,
      assignNewDialogRef,
      handelLaunchChange,
      open,
      reData,
      handleShowAssgin,
      handleAddNewShipment,
      handleClose,
      handelChange,
      handleClick,
      handleAssgin,
      handleOut,
      handleStartLaunch,
      onPageChange,
      handleLimitChange,
    }
  }
})    

