
import { addBoxHttp } from '@/api/WarehouseManagement/whbox'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const inputData = ref({
      count: 0,
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = () => {
     
      visible.value = true
      console.log('新的确认')
      // inputData.value.day = Math.ceil(
      //   dayjs().diff(_row.receivedate, 'day', true)
      // )
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        const res = await addBoxHttp(inputData.value.count)
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
