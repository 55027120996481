
import { defineComponent, ref, computed } from "vue";
import {
  UserwithdraworderListRes,
  userwithdraworderAudit,
} from "@/api/Financial/transfer";
import { userconsumewalletAll, AllWallets } from "@/api/User/index";
import { toOptional } from "@/types/utils/index";
import { userStore } from "@/store/modules/user";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  emits: ["success"],
  setup(props, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const reLoading = ref(false);
    const auditresult = ref("");
    const wallets = ref<toOptional<AllWallets>>({});
    const info = ref<toOptional<UserwithdraworderListRes>>({});
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const balance = computed(() => {
      if (!info.value.articleproductmodel) return 0;
      if (!wallets.value[info.value.articleproductmodel]) return 0;
      return wallets.value[info.value.articleproductmodel]?.amount;
    });
    const reData = async () => {
      try {
        reLoading.value = true;
        wallets.value = (
          await userconsumewalletAll(info.value.userid as string)
        ).userAllWallets;
      } finally {
        reLoading.value = false;
      }
    };
    const open = (row: UserwithdraworderListRes) => {
      visible.value = true;
      info.value = row;
      reData();
    };
    const submit = async (approved: boolean) => {
      try {
        btnSumitLoading.value = true;
        if (!userStore.info.userid || !info.value.withdraworderid) return;
        const res = await userwithdraworderAudit({
          approved,
          auditoruserid: userStore.info.userid,
          auditresult: auditresult.value
            ? auditresult.value
            : approved
            ? "同意"
            : "不同意",
          withdraworderid: info.value.withdraworderid,
        });
        ElMessage.success(res);
        emit("success");
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      balance,
      reLoading,
      info,
      reData,
      submit,
      auditresult,
    };
  },
});
