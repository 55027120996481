<!-- 添加投资订单 -->
<template>
  <el-dialog
    :append-to-body="true"
    :destroy-on-close="true"
    :title="`新增投资订单`"
    @closed="handleClose"
    v-model="visible"
    width="700px"
  >
    <el-steps :active=activeStep finish-status="success" simple >
      <el-step title="填写投资人" ></el-step>
      <el-step title="确认订单" ></el-step>
      <el-step title="投放方式" ></el-step>
    </el-steps>
    <el-form label-width="120px" style="margin-top: 20px;" label-position="right" v-show="activeStep==1">
       <el-form-item label="投资者类型" class="tip-text">
        <el-select @change="changeInvestortype" placeholder="请选择投资者类型" v-model="investortype" >
          <el-option label="用户" value="user"></el-option>
          <el-option label="平台 " value="platform"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form :model="userData" label-width="120px"  ref="userDataRef" label-position="right" v-show="activeStep==1&&investortype=='user'">
      <el-form-item label="账号" class="tip-text" prop="phone">
          <el-input v-model="userData.phone" style="width: 220px;" ></el-input>
          <el-button type="primary" @click="onCheckInvestor" style="margin-left: 20px;" :loading="checkInvestorLoading" >查询投资人</el-button>
      </el-form-item>
      <el-form-item label="姓名" class="tip-text" prop="name">
          <el-input v-model="userData.name" style="width: 220px;" :disabled="true"></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="userData" label-width="120px"  style="margin-top: 20px;" v-show="activeStep==2">
      <el-form-item label="投资人">
          <span class="span-border">{{userData.name+userData.phone}}</span>
      </el-form-item>
    </el-form>
    <el-form :model="selectData" label-width="120px" ref="selectDataRef" style="margin-top: 20px;" v-show="activeStep==2">     
      <el-form-item label="设备类型" >
      <span class="span-border">小魔夹MS2</span>
      </el-form-item>
      <el-form-item label="订单类型" >
        <el-select placeholder="请选择订单类型" v-model="selectData.fortest">
          <el-option :value="false" label="正常订单"></el-option>
          <el-option :value="true" label="测试订单"></el-option>
        </el-select>
      </el-form-item>   
      <div v-show="investortype=='user'">
        <el-form-item label="是否为免费赠送">
          <el-select placeholder="请选择是否免费" v-model="selectData.freegift">
            <el-option :value="true " label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
          <my-tip content="请慎重选择"></my-tip>
        </el-form-item>      
      </div>
    </el-form>
    <el-form
      :model="selectData"
      :rules="rules"
      label-width="120px"
      style="margin-top: 20px;"
      ref="selectDataRef"
      v-show="activeStep==3">
      <el-form-item label="投放类型">
        <el-select placeholder="请选择投放类型" v-model="selectData.launchmode">
          <el-option label="自投放" value="SELF"></el-option>
          <el-option label="平台投放" value="PLATFORM"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form
      :model="selfData"
      :rules="rules"
      label-width="120px"
      ref="selfDataRef"
      v-show="activeStep==3&&selectData.launchmode=='SELF'">
      <el-form-item label="寄件联系人" prop="mailingcontact">
        <el-input v-model="selfData.mailingcontact"></el-input>
      </el-form-item>
      <el-form-item label="寄件联系电话" prop="mailingnumber">
        <el-input v-model="selfData.mailingnumber"></el-input>
      </el-form-item>
      <el-form-item label="寄件地址" prop="mailingaddress">
        <el-input v-model="selfData.mailingaddress"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :model="platformData"
      :rules="rules"
      label-width="120px"
      ref="platformDataRef"
      v-show="activeStep==2">
      <el-form-item label="小魔夹数量" prop="articlenum">
        <el-input-number
          :min="1"
          :precision="0"
          :step="1"
          controls-position="right"
          v-model="platformData.articlenum"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="单价" prop="singleamount">
        <el-input-number
          :min="1"
          :precision="2"
          :step="1"
          controls-position="right"
          v-model="platformData.singleamount"
        ></el-input-number>
        <!-- <span>&ensp;总额{{platformData.singleamount*platformData.articlenum}}</span> -->
      </el-form-item>
      <el-form-item label="投资人分成比例" prop="investorproportion">
        <el-input-number
          :max="100"
          :min="0"
          :precision="1"
          :step="0.1"
          controls-position="right"
          v-model="platformData.investorproportion"
        ></el-input-number>&ensp;%
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>                     
        <el-button  type="primary" v-show="activeStep>1" @click="onPrevClick">上一步</el-button>
        <el-button  type="primary" v-show="activeStep<3" @click="onNextClick">下一步</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary" v-show="activeStep==3">下 单</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentorderOfflineinvest,
  investmentorderUpdate,
} from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
import { userList } from "@/api/User/index"
import { userRoleList } from '@/api/User/role'
let index: any; // 编辑的index
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {

    const visible = ref(false);
    const activeStep=ref(1);
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const orderId = ref("");
    const investortype = ref("user");
    const userArray = ref<any>([]);
    const roleArray = ref<any>([]);
    const userDataRef = ref<any>({});
    const selfDataRef = ref<any>({});
    const selectDataRef = ref<any>({});
    const platformDataRef = ref<any>({});
    const userData = ref({
      phone:'',
      name:'',
      investoruserid:'',
    });
    const selectData = ref({
      articleproductid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
      launchmode: "PLATFORM",
      investortype: "user",
      fortest: false,
      freegift: false,
      investoruserid: "",
    });
    const selfData = ref({
      mailingcontact: "",
      mailingnumber: "",
      mailingaddress: "",      
    });
    const platformData = ref({
      articlenum: 10,
      singleamount: 399.0,
      investorproportion: 50,
    });
    const rules = readonly({
      mailingcontact: [Rules.required()],
      mailingnumber: [Rules.required()],
      mailingaddress: [Rules.required()],
      articlenum: [Rules.required()],
    });

    const open = (row: any, _index: number) => {
      visible.value = true;
      if (!row) return;
      const {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,

        mailingcontact,
        mailingnumber,
        mailingaddress,
        investoruserid,
        articlenum,
        singleamount,
        investorproportion,
        orderid,
      } = row;
      selectData.value = {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,
        investoruserid,
      };
      selfData.value = {
        mailingcontact,
        mailingnumber,
        mailingaddress       
      };
      platformData.value = {
        articlenum,
        singleamount,
        investorproportion,
      };
      orderId.value = orderid;
      index = _index;
    };
    const onCheckInvestor = async () => {
      if(userData.value.phone&&userData.value.phone!=''){
        checkInvestorLoading.value=true;
        userData.value.name='';
        userArray.value=[];
        roleArray.value=[];
        getUserInfo(userData.value.phone)
      }else{
        ElMessage.success("请先输入投资人账号");          
      }
    };

    const handleClose = async () => {
      selfDataRef.value.resetFields();
      selfDataRef.value.clearValidate();
      platformDataRef.value.resetFields();
      platformDataRef.value.clearValidate();
      userDataRef.value.resetFields();
      userDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
      index = "";
      activeStep.value=1;
      userArray.value=[];
      roleArray.value=[];
    };

    const onNextClick = async () => {
        if(activeStep.value==1){
          if(investortype.value == "user"){
            if(userData.value.name&&userData.value.name!=''){
              activeStep.value=2
            }else{
              ElMessage.success("请先查询需要下单的投资人账号");          
            }
          }else{
            userData.value.name='平台'
            userData.value.phone='16673103969'
            userData.value.investoruserid='980e6462ee785a6e9842d1e553f67755'
            activeStep.value=2
          }
         
        }else if (activeStep.value==2){
          activeStep.value=3;
        }
    };
    
    const onPrevClick = async () => {
       if(activeStep.value==3){
          activeStep.value=2
        }else if (activeStep.value==2){
          activeStep.value=1;
        }
    };

    
    const getUserInfo = async (account: string) => {
      try {
       
        let rqData: any = { page: "1", limit: "10" ,account:account};
       
        const res = await userList(rqData);
        userArray.value=res.result;
        const userid=userArray.value[0].userid;
        getUserRole(userid,account);
        
      } catch(err){ 
        checkInvestorLoading.value=false;
        ElMessage.success("查询失败，请确认投资人账号是否正确");
        
      }
    };

    const getUserRole = async (userid: string,phone:string) => {
      try {
       let rqData: any = { page: "1", limit: "10" ,investoruserid:userid};

       const res = await userRoleList(rqData, 'investor')

       roleArray.value = res.result;

       userData.value.name=roleArray.value[0].investorname;
       userData.value.investoruserid=roleArray.value[0].investoruserid;
       selectData.value.investoruserid=userid;
       checkInvestorLoading.value=false;
      }catch(err){ 
        checkInvestorLoading.value=false;
        ElMessage.success("查询失败，请确认账号是否有投资人身份");
        
      }
    }

    const submit = async () => {
      try {
        const rqData = {
          ...platformData.value,
          ...selectData.value,    
          investmode: "offline",
          salesmanuserid: userStore.info.userid,
        };
        const submitDate = selectData.value.launchmode == "SELF"?{...rqData,...selfData.value}:{...rqData};
        btnSumitLoading.value = true;
        const res = orderId.value
          ? await investmentorderUpdate({ ...submitDate, orderid: orderId.value })
          : await investmentorderOfflineinvest(submitDate);
        ElMessage.success(res.message);
        handleClose();
        emit("success", {
          isEdit: !!orderId.value,
          data: res.record,
          index,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeArticletype = (e: string) => {  

    };
    const changeInvestortype = (e: string) => {
      if (e == "platform") {
        selectData.value.freegift = false;
        selectData.value.investoruserid = '';
      }
    };
    return {
      activeStep,
      rules,
      userData,
      selectData,
      platformData,
      selfData,
      visible,
      btnSumitLoading,
      checkInvestorLoading,
      open,
      userDataRef,
      selectDataRef,
      selfDataRef,
      platformDataRef,
      onCheckInvestor,
      submit,
      handleClose,
      onNextClick,
      onPrevClick,
      changeArticletype,
      changeInvestortype,
      investortype,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 0 0 24px 36px;
  .el-select {
    margin-right: 21px;
  }
}

.tip-text{
  font-size: 16px;
  font-weight: bold;
}

.span-border {
  border: 1px solid #DCDFE6; 
  border-radius: 5px;
  width: 205px; 
  height: 40px; 
  display: block; 
  padding-left: 14px;
}
</style>