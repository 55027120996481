<template>
  <el-dialog
      :destroy-on-close="true"
      :title="title"
      @closed="handleClose"
      class="dialog"
      v-model="visible"
      width="960px"
    >
    <div class="container">
     <header>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否为正式" v-model="search.fortest">
          <el-option :value="true" label="测试"></el-option>
          <el-option :value="false" label="正式"></el-option>
        </el-select>
        <el-date-picker
          @change="handelChange"
          align="right"
          class="header-select"
          end-placeholder="结束时间"
          range-separator="至"
          start-placeholder="开始时间"
          type="datetimerange"
          unlink-panels
          v-model="search.timeBN"
        ></el-date-picker>
        <el-button style="margin-left: 16px;" @click="handleExport()" icon="el-icon-tickets" type="primary">导出表数据</el-button>
      </header>
      <div class="total-parent">
        <div class="total-item">
          <div class="item-top">
            <color-icon type="icon-ZHIMA" style="color:#50b674; width: 46px; height: 46px;"></color-icon>
          </div>
          <div class="item-buttom">
            <span>总充值: ￥ <span style="color: #1AAC1B;">{{profitTotalInfo.aliAllTotal}}</span></span>
            <span style="margin-top: 4px;">区间充值: ￥ {{profitTimeInfo.aliTotal}}</span>
          </div>
        </div>
        <div class="total-item" style="margin-left: 8px;">
          <div class="item-top">
            <color-icon type="icon-PAYSCORE" style="color:#50b674;width: 46px; height: 46px;"></color-icon>
          </div>
          <div class="item-buttom">
            <span>总充值: ￥ <span style="color: #1AAC1B;">{{profitTotalInfo.wxAllTotal}}</span></span>
            <span style="margin-top: 4px;">区间充值: ￥ {{profitTimeInfo.wxTotal}}</span>
          </div>
        </div>
        <div class="total-item" style="margin-left: 8px;">
          <div class="item-top">
            <color-icon type="icon-BALANCE" style="color:#50b674;width: 46px; height: 46px;"></color-icon>
          </div>
          <div class="item-buttom">
            <span>总充值: ￥ <span style="color: #1AAC1B;">{{profitTotalInfo.yueAllTotal}}</span></span>
            <span style="margin-top: 4px;">区间充值: ￥ {{profitTimeInfo.yueTotal}}</span>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName"  @tab-click="handleClick" style="margin-top: 6px;">
        <el-tab-pane  label="支付宝" name="alipay"></el-tab-pane>
        <el-tab-pane label="微信" name="wechatpay"></el-tab-pane>
        <el-tab-pane label="平台" name="platform"></el-tab-pane>
      </el-tabs>
      <el-table :data="list" height="45vh" style="width: 100%" v-if="list">
        <el-table-column align="center" label="金额" min-width="40">
          <template #default="scope">
            ￥ {{scope.row.totalcost}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="类型" min-width="60">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.rechargemode=='alipay'">支付宝</el-tag>
            <el-tag type="success" v-if="scope.row.rechargemode=='wechatpay'">微信</el-tag>
            <el-tag type="success" v-if="scope.row.rechargemode=='platform'">平台</el-tag>          
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间" min-width="60">
          <template #default="scope">
          {{scope.row.date}}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination 
        :current-page="page" 
        :page-size="limit" 
        :page-sizes="[10, 30, 50, 100]" 
        :total="total" 
        @current-change="pageChange" 
        @size-change="handleLimitChange" 
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </el-dialog>
  <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
</template>

<script lang = "ts" >
import { defineComponent, ref } from "vue";
import { moneyTotalModel, 
  moneylModel,
  moneyTimeModel,
  profitListModel,
  getMoneyStatisticsTotalDetails,
  getStatisticsDetailsList,
  exportStatisticsDetails } from "@/api/Financial/statisticsDetails";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
import exportDialog from "@/components/export/exportDialog.vue";
let userId='';
let valueType='';
let name='';
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog
   },
   setup() {
    const title = ref('充值详情');
    const visible = ref(false);
    const activeName = ref("alipay")
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const exportDialogRef = ref<any>();
    const totalInfo = ref<moneyTotalModel>();
    const profitTotalInfo = ref<toOptional<moneylModel>>({});  
    const profitTimeInfo = ref<toOptional<moneyTimeModel>>({})  
    const list = ref<profitListModel[]>([]);
    const search = ref({
      timeBN:'',
      fortest:false
    });
    const open = (_userid:string,_type:string,_account:string,_name:string) => {
      visible.value = true;
      userId=_userid;
      valueType=_type;
      if(_name==null){
        name=_name
        title.value=_name+"充值详情"
      }else{
        name=_account
        title.value=_account+"充值详情"
      }
      search.value.timeBN='';
      search.value.fortest=false;
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsTotal();
      getStatisticsList();
    };

    const getStatisticsTotal = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,userid:userId,type:valueType};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getMoneyStatisticsTotalDetails(rqData);
        totalInfo.value = res;
        profitTotalInfo.value=res.alllist[0];
        profitTimeInfo.value=res.list[0];
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const getStatisticsList = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,userid:userId,type:valueType,rechargemode:activeName.value};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getStatisticsDetailsList(rqData);
        total.value=res.data.total
        list.value=res.data.records
        list.value.map(v => {
          v.date = dayjs(parseInt(v.date!)).format("YYYY-MM-DD HH:mm:ss")
          return v
        })
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }

    const handleExport = async() =>{
      exportDialogRef.value.open(`${name}的充值`)
        
    }

    const exportFile = async(name:string) =>{
      try {

        let rqData: any = {fortest:search.value.fortest,fileName:name+'的充值',userid:userId,type:valueType,rechargemode:activeName.value}

        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        const res = await exportStatisticsDetails(rqData)

        const blob = new Blob([res.data]);

        saveAs(blob, name)
      } finally {

      }
    }

    return {
      title,
      visible,
      activeName,
      page,
      total,
      limit,
      list,
      totalInfo,
      profitTotalInfo,
      profitTimeInfo,
      search,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
</script>
<style lang = "scss" scoped >
.container{
  margin-top: -16px;
  .total-parent{
    display: flex;
    flex-direction: row;
    height: 96px;
    width: 100%;
    align-items: center;
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    .total-item{
      width: 25%;
      display: flex;
      flex-direction: row;
      background: #f2f2f2;
      align-items: center;
      padding-top: 18px;
      padding-bottom: 18px;
      .item-top{
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-buttom{
        display: flex;
        margin-top: 8px;
        flex-direction: column;
      }
    }
  } 
  
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>