
import { defineComponent, ref } from "vue";
import { moneyTotalModel, 
  moneylModel,
  moneyTimeModel,
  profitListModel,
  getMoneyStatisticsTotalDetails,
  getStatisticsDetailsList,
  exportStatisticsDetails } from "@/api/Financial/statisticsDetails";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
import exportDialog from "@/components/export/exportDialog.vue";
let userId='';
let valueType='';
let name='';
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog,
   },
   setup() {
    const title = ref('消费详情');
    const visible = ref(false);
    const activeName = ref("alipay")
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const exportDialogRef = ref<any>();
    const totalInfo = ref<moneyTotalModel>();
    const profitTotalInfo = ref<toOptional<moneylModel>>({});  
    const profitTimeInfo = ref<toOptional<moneyTimeModel>>({})  
    const list = ref<profitListModel[]>([]);
    const search = ref({
      timeBN:'',
      fortest:false
    });
    const open = (_userid:string,_type:string,_account:string,_name:string) => {
      visible.value = true;
      userId=_userid;
      valueType=_type;
      if(_name==null){
        name=_name;
        title.value=_name+"消费详情"
      }else{
        name=_account;
        title.value=_account+"消费详情"
      }
      search.value.timeBN='';
      search.value.fortest=false;
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsTotal();
      getStatisticsList();
    };

    const getStatisticsTotal = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,userid:userId,type:valueType};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getMoneyStatisticsTotalDetails(rqData);
        totalInfo.value = res;
        profitTotalInfo.value=res.alllist[0];
        profitTimeInfo.value=res.list[0];
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const getStatisticsList = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,userid:userId,type:valueType,rechargemode:activeName.value};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getStatisticsDetailsList(rqData);
        total.value=res.data.total
        list.value=res.data.records
        // list.value.map(v => {
        //   v.date = dayjs(parseInt(v.date!)).format("YYYY-MM-DD HH:mm:ss")
        //   return v
        // })
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }

    const handleExport = async() =>{
      exportDialogRef.value.open(`${name}的消费`)
       
    }
    const exportFile = async(name:string) =>{
      try {

        let rqData: any = {fortest:search.value.fortest,fileName:name+'的消费',userid:userId,type:valueType,rechargemode:activeName.value}

        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        const res = await exportStatisticsDetails(rqData)

        const blob = new Blob([res.data]);
        saveAs(blob, name)
      } finally {

      }
    }
    return {
      title,
      visible,
      activeName,
      page,
      total,
      limit,
      list,
      totalInfo,
      profitTotalInfo,
      profitTimeInfo,
      search,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
