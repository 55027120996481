
import { administrativedivisionList } from '@/api/System/city'
import { computed, defineComponent, nextTick, onMounted, ref, toRef } from 'vue'
export default defineComponent({
  name: 'my-select',
  props: {
    areacode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  emits: ['changeCode'],
  setup(props, { emit }) {
    const list = ref<any[]>([])
    const areacode = toRef(props, 'areacode')
    const level = toRef(props, 'level')
    const loading = ref(false)
    const select = ref({})
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const initCode = ref('')
    const code = computed(() => areacode.value.substring(0, level.value * 2))
    const getPlaceholder = computed(() => {
      changeInitCode()
      switch (level.value) {
        case 1:
          return '选择省/直辖市'
        case 2:
          return '选择市/辖区类型'
        case 3:
          return '选择区'
        case 4:
          return '选择街道'
        default:
          return '详细地址'
      }
    })

    const loadData = async () => {
      try {
        loading.value = true
        let { result, totalCount } = await administrativedivisionList({
          limit: limit.value,
          page: page.value,
          superiorcode: initCode.value,
        })
        total.value = totalCount
        list.value.push(...result)
      } finally {
        loading.value = false
      }
    }
    const resetData = async () => {
      console.log('resetresetresetresetresetresetresetresetreset')
      list.value = []
      select.value = ''
      page.value = 1
      limit.value = 10
      total.value = 0
      await nextTick()
      loadData()
    }
    onMounted(() => {
      if (code.value == '0') {
        loadData()
      }
    })

    const changeInitCode = () => {
      switch (level.value) {
        case 1:
          initCode.value = '0'
          break
        case 2:
          initCode.value = code.value.substring(0, 2)
          break
        case 3:
          initCode.value = code.value.substring(0, 4)
          break
        case 4:
          initCode.value = code.value.substring(0, 6)
          break
        default:
          initCode.value = code.value.substring(0, 9)
          break
      }
    }

    const changeCode = () => {
      emit('changeCode', { ...select.value, level: level.value })
    }
    const loadmore = async () => {
      if (loading.value) return
      if (page.value * limit.value >= total.value) return
      page.value++
      await loadData()
    }
    return {
      resetData,
      changeCode,
      loadmore,
      getPlaceholder,
      select,
      list,
      total,
      limit,
      page,
      loading,
    }
  },
})
