<template>
  <el-dialog :destroy-on-close="true" :title="title" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      title="更换后，旧小魔夹的投资人成为公司，可以继续共享租赁，收益归属公司，新的小魔夹的投资人为原来旧小魔夹的投资人"
      type="warning"
      center
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="geRules" label-width="120px" style="margin-top: 16px;" ref="formRef">
      <el-form-item label="更换原因" prop="remark">
        <el-input :autosize="{ minRows: 3, maxRows: 5}" type="textarea" v-model="inputData.remark"></el-input>
      </el-form-item>
      <el-form-item label="新小魔夹SN" prop="newSN">
        
        <el-input placeholder="请输入新小魔夹SN"  v-model="inputData.newSN"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from 'vue'
import { onPrivateOrderReplaceDevice } from '@/api/Order/Privatization'
import Rules from '@/common/Rules'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from "@/store/modules/user";
import dayjs from 'dayjs'
let overdueId = '';
let oldSn='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const inputData = ref({
      remark: '超期补偿新小魔夹给投资人',
      newSN: '',
      excuteInfo:'',
      handleUserId:'',
      overdueId:'',
      replaceStatus:'99'
    })
    const geRules = computed(() => {
      return {
        remark: [Rules.required()],
        newSN: [Rules.required()],
      }
    })
    const visible = ref(false)

    const formRef = ref<any>('')
    const btnSumitLoading = ref(false)
    const title=ref('更换小魔夹')
    const handleClose = () => {
      visible.value = false
      overdueId = ''
      oldSn=''
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:any) => {
      visible.value = true
      oldSn=item.equipmentSN;
      title.value=item.equipmentSN+'更换新设备'
      overdueId = item.id;
      
    }
    const submit = async () => {
      try {
        await formRef.value.validate()
        await ElMessageBox.confirm(
          `请确认更换${oldSn}小魔夹为${inputData.value.newSN.trim().toUpperCase()}？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        btnSumitLoading.value = true
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        inputData.value.remark=time+inputData.value.remark;
        inputData.value.handleUserId=userStore.userInfo.userid!;
        inputData.value.overdueId=overdueId;
        inputData.value.newSN=inputData.value.newSN.trim().toUpperCase()
        const res = await onPrivateOrderReplaceDevice({
          ...inputData.value    
        })
        ElMessage.success(res)
        emit('success')
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
      title,
 
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>