import { sharedorderprofitIncreaseLastAndCurrentOfDayAndMonth } from "@/api/Index/platfrom";
import { ref } from "vue"

export default function () {
    const costs = ref({
        data: {
            columns: ["name", "value"],
            rows: [
                {
                    name: "今天",
                    value: 0,
                },
                {
                    name: "昨天",
                    value: 0,
                },
            ],
        },
        type: 1,
        title: "营业额",
        box1: {
            label: "本月营业额",
            value: 0,
        },
        box2: {
            label: "上月营业额",
            value: 0,
        },
    })

    const reCocts = async ({ productid }: { productid?: string }) => {
        const res = await sharedorderprofitIncreaseLastAndCurrentOfDayAndMonth(productid);
        costs.value.data = {
            columns: ["name", "value"],
            rows: [
                {
                    name: "今天",
                    value: res.today,
                },
                {
                    name: "昨天",
                    value: res.yesterday,
                },
            ],
        }
        costs.value.box1.value = res.thisMonth;
        costs.value.box2.value = res.lastMonth;
    };
    return {
        costs,
        reCocts
    }
}