<!-- 查询行政区划列表 -->
<template>
  <div class="content">
    <div :key="level" v-for="(level) of maxLevel">
      <my-select-city
        :areacode="areacode"
        :level="level"
        :ref="el=>{if(el)divs[level] = el}"
        @changeCode="changeCode"
        v-if="selectActiveLevel>=level"
      ></my-select-city>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef } from '@vue/runtime-core'
import { nextTick, onBeforeUpdate } from 'vue'
import select from './components/select.vue'
export default defineComponent({
  name: 'v-select',
  emits: ['cityCodeChange'],
  setup(props, { emit }) {
    const areacode = ref('0')
    const selectActiveLevel = ref(1)
    const loading = ref(false)
    const maxLevel = toRef(props, 'maxLevel')
    const changeTrigger = toRef(props, 'changeTrigger')
    const emptyTrigger = toRef(props, 'emptyTrigger')
    const divs = ref<any[]>([])
    onBeforeUpdate(() => {
      divs.value = []
    })
    const changeCode = async (data: { areacode: string; level: number }) => {
      console.log({ data, arguments })
      if (data.areacode) {
        areacode.value = data.areacode
        selectActiveLevel.value = data.level + 1
        await nextTick()
        divs.value[selectActiveLevel.value]?.resetData()
      } else {
        selectActiveLevel.value = data.level
        switch (data.level) {
          case 1:
            areacode.value = '0'
            break
          case 2:
            areacode.value = areacode.value.substring(0, 2)
            break
          case 3:
            areacode.value = areacode.value.substring(0, 4)
            break
          case 4:
            areacode.value = areacode.value.substring(0, 6)
            break
        }
      }
      if (maxLevel.value == data.level) {
        // 到达节点触发
        emit('cityCodeChange', areacode.value)
      } else if (emptyTrigger.value) {
        // 会不是节点会触发空值
        emit('cityCodeChange', null)
      } else if (changeTrigger.value) {
        if (areacode.value == '0') {
          // 没有选择的时候传个空的
          emit('cityCodeChange', null)
        } else {
          emit('cityCodeChange', areacode.value)
        }
      }
    }
    return {
      loading,
      selectActiveLevel,
      areacode,
      changeCode,
      divs,
    }
  },
  props: {
    maxLevel: {
      type: Number,
      default: 4,
    },
    emptyTrigger: {
      type: Boolean,
      default: false,
    },
    changeTrigger: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    'my-select-city': select,
  },
})
</script>
<style lang='scss' scoped>
.content {
  // padding: 16px 0 32px;
  display: flex;
  > * {
    margin-right: 16px;
  }
}
</style>