<template>
    <el-dialog :destroy-on-close="true" :title="title" @closed="handleClose" align="center" class="dialog" v-model="visible" width="65%">
        <div>
            <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
            <el-table-column type="expand">
                <template #default="scope">
                <el-form class="table-expand" inline label-position="left" label-width="120px">
                    <el-form-item label="用户id">
                    <span>{{ scope.row.userid }}</span>
                    <my-copy :data="scope.row.userid" />
                    </el-form-item>
                </el-form>
                </template>
            </el-table-column>
            <el-table-column label="账号" align="center" min-width="55">
                <template #default="scope">
                <color-icon type="icon-ceshi" v-if="scope.row.fortest=='1'" />
                <color-icon type="icon-zhengshiban" v-else />
                {{scope.row.account}}
                <my-copy :data="scope.row.account"></my-copy>
                </template>
            </el-table-column>
            <el-table-column label="名称" align="center" min-width="56">
                <template #default="scope">
                {{scope.row.name}}
                </template>
            </el-table-column>
            <el-table-column label="昵称" align="center" min-width="56">
                <template #default="scope">
                {{scope.row.nickname}}
                </template>
            </el-table-column>
            <el-table-column label="类型" align="center" min-width="56">
                <template #default="scope">
                <span v-if="scope.row.type=='individual'">个人</span> 
                <span v-else >公司</span>          
                </template>
            </el-table-column>
            <el-table-column align="center" label="收益角色" min-width="44">
                <template #default="scope" >
                <span v-if="scope.row.roletype=='super'">我的上级</span> 
                <span v-else-if="scope.row.roletype=='agent'">我的代理人</span> 
                <span v-else-if="scope.row.roletype=='next'" >推广投资人</span> 
                <span v-else-if="scope.row.roletype=='agentnext'" >代理投资人</span> 
                </template>
            </el-table-column>
            </el-table>
            <el-pagination
                :current-page="page"
                :page-size="limit"
                :page-sizes="[10, 30, 50, 100]"
                :total="total"
                @current-change="onPageChange"
                @size-change="handleLimitChange"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, readonly, inject } from 'vue'
import { RelationModel, getUserRoleRelation } from '@/api/Relation/index'
import routerChangeReData from '@/common/routerChangeReData'
let userId:string;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const title = ref('')
    const list = ref<RelationModel[]>([])
    const searchSelect = ref({
      search: 'userid',
    })
    const mixin_router_search = ref({
      fortest: '', //是否测试
    })

    const mixin_select_search = ref({
      search: {
        account:'',//用户账号    
        userid:userId, //用户id
        
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',    
        userid:'用户id',  
      },
    })

    const open = async(_userid: string,_Phone:string,_nickname:string) => {
      userId=_userid;
      title.value=`${_nickname}的上下级关系`
      reData();
      visible.value = true
    }

    const handleClose = () => {
      visible.value = false

    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { currentPage: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if(!rq.userid){
              rq.userid=userId
          }
          rqData = { ...rqData, ...rq }
        }
        const res = await getUserRoleRelation(rqData)
        console.log('返回数据',res)
        list.value = res.data.records
        total.value = res.data.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }

    return {
        visible,
        handleClose,
        open,
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,   
        onPageChange,
        handelChange,
        handleLimitChange,
        mixinReRouter,
        changSearch,
        searchSelect,
        list,
        loading,
        page,
        limit,
        total,   
        title,
    }
  }
})
</script>