
import { computed, defineComponent, nextTick, onMounted, ref, toRef } from "vue";
import custemPie from "@/components/echart/custemPie";
const _animationDuration = 1000;
const _animationDurationUpdate = 1000;
const _animationEasingUpdate = "quarticInOut";
const _valOnRadianMax = 200;
import renderItem from "./renderItem";
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => "180px",
    },
    chartData: {
      type: Array,
    },
  },
  components: {
    custemPie,
  },
  setup(props) {
    const chartData = toRef(props, "chartData");
    const option = computed(() => {
      return {
        animationEasing: _animationEasingUpdate,
        animationDuration: _animationDuration,
        animationDurationUpdate: _animationDurationUpdate,
        animationEasingUpdate: _animationEasingUpdate,
        dataset: {
          source: [[1, 156]],
        },
        tooltip: {},
        angleAxis: {
          type: "value",
          startAngle: 0,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
          min: 0,
          max: _valOnRadianMax,
        },
        radiusAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
        },
        polar: {},
        series: [
          {
            type: "custom",
            coordinateSystem: "polar",
            renderItem,
          },
        ],
      };
    });
    const custemPieRef = ref<any>(null);
    const reView = () => {
      custemPieRef.value.myRender();
    };
    onMounted(async() => {
        // setInterval(function () {
        //   const nextSource = [[1, Math.round(Math.random() * 200)]];
        //   custemPieRef.value.myRender(nextSource);
        //   // console.log('1 :>> ', 1);
        // }, 3000);
      await nextTick()
      const nextSource = [[1, Math.round(Math.random() * 200)]];
      custemPieRef.value.myRender(nextSource);
    });
    return {
      option,
      custemPieRef,
      reView,
    };
  },
});
