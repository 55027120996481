<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select
        @change="handelChange"
        socketholder="是否测试"
        class="header-el-selector"
        v-model="mixin_router_search.fortest"
      >
        <el-option label="测试" value="false"></el-option>
        <el-option label="正式" value="true"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        socketholder="是否有效"
        class="header-el-selector"
        v-model="mixin_router_search.releasing"
      >
        <el-option label="发布中" value="true"></el-option>
        <el-option label="未发布" value="false"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        socketholder="发布红包"
        class="header-el-selector"
        v-model="mixin_router_search.withredenvelope"
      >
        <el-option label="有红包" value="true"></el-option>
        <el-option label="无红包" value="false"></el-option>
      </el-select>
         <el-select
        @change="handelChange"
        socketholder="锁定状态"
        class="header-el-selector"
        v-model="mixin_router_search.locked"
      >
        <el-option label="已锁定" value="true"></el-option>
        <el-option label="未锁定" value="false"></el-option>
      </el-select>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="发布id">
              <span>{{ scope.row.supplyid }}</span>
            </el-form-item>
            <el-form-item label="发布人id">
              <span>{{ scope.row.supplieruserid }}</span>
            </el-form-item>
            <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="发布人" min-width="35" prop="supplieraccount"></el-table-column>
      <el-table-column label="设备SN" min-width="35" prop="articleequipmentsn"></el-table-column>
      <el-table-column label="设备类型" min-width="35" prop="productname"></el-table-column>
      <el-table-column label="有无红包" min-width="30"   align="center">
       <template  #default="scope">
          <el-tag type="success"  v-if="scope.row.withredenvelope">有</el-tag>
          <el-tag type="info"  v-else>无</el-tag>
       </template>
     </el-table-column>
     <el-table-column label="锁定状态" min-width="35"   align="center">
       <template  #default="scope">
          <el-tag type="danger"  v-if="scope.row.locked">锁定</el-tag>
          <el-tag type="info"  v-else>未锁定</el-tag>
       </template>
     </el-table-column>
     <el-table-column label="是否有效" min-width="35"   align="center">
       <template  #default="scope">
          <el-tag type="danger"  v-if="scope.row.releasing">有效</el-tag>
          <el-tag type="info"  v-else>无效</el-tag>
       </template>
     </el-table-column>
      <el-table-column label="创建时间" min-width="44" prop="creationdate"></el-table-column>   
      <el-table-column label="发布地址" min-width="70" prop="address"></el-table-column>         
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.$index,scope.row.panelequipmentsn)"
            size="mini"
            type="danger"
          >删除</el-button>
          <el-button @click="handleEidt(scope.$index,scope.row)" size="mini">编辑</el-button>
          <el-button @click="handleUpdata(scope.$index,scope.row)" size="mini" type="primary">更新</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
  <!-- <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
  <v-up-data-dialog @success="addSuccess" ref="upDataDialogRef"></v-up-data-dialog> -->
</template>


<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { DeviceReleaseModel, getDeviceReleaseList } from '@/api/Release/Device'
// import addDialog from './components/addDialog.vue'
// import upDataDialog from './components/upDataDialog.vue'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'Name',
  components: {
    // 'v-add-dialog': addDialog,
    // 'v-up-data-dialog':upDataDialog,
  },
  setup() {
    const loading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<DeviceReleaseModel[]>([])
    const addDialogRef = ref<any>()
    const upDataDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'articleequipmentsn',
    })
    const mixin_select_search = ref({
      search: {     
        articleequipmentsn: '', //共享物品的设备序号
        supplieraccount: '', //插口序号，如：小魔柜 01~12 雨伞架 01~09
        supplieruserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        supplieraccount: '发布人手机号',
        supplieruserid: '发布人id',
      },
    })

    const mixin_router_search = ref({ 
      order: 'creationdate',
      sort: 'desc',
      fortest:'',
      withredenvelope:'',
      locked:'',
      releasing:'',
    })

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
          }
        }
        const res = await getDeviceReleaseList(rqData)
        total.value = res.total
        list.value = res.records
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }

    const handleUpdata = (index: number,row:any) =>{
      upDataDialogRef.value.open(index, row)
    }

    const addSuccess = (data: { index: number; data: DeviceReleaseModel }) => {
      if (typeof data.index == 'number' && data.index > -1) {
        list.value[data.index] = data.data
      } else {
        list.value.push(data.data)
      }
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      // try {
      //   btnRemoveLoaidng.value = true
      //   const res = await removeSocket({
      //     panelequipmentsn,
      //   })
      //   list.value.splice(index, 1)
      //   ElMessage.success(res.message)
      // } finally {
      //   btnRemoveLoaidng.value = false
      // }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleUpdata,
      handleRemove,
      addSuccess,
      addDialogRef,
      upDataDialogRef,
      btnRemoveLoaidng,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    .el-select {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width:120px ;
    margin-right: 12px;
  }
}
</style>