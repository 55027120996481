
import { defineComponent, ref } from "vue";
import dayjs from "dayjs";
export default defineComponent({
  data() {
    return {
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
      shortcuts: [
        {
          text: "今天",
          onClick(picker: any) {
            picker.$emit("pick", new Date());
          },
        },
        {
          text: "昨天",
          onClick(picker: any) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit("pick", date);
          },
        },
        {
          text: "一周前",
          onClick(picker: any) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", date);
          },
        },
      ],
    };
  },
  emits: ["timeChange", "timeChange"],
  setup(props, { emit }) {
    const selectDate = ref<string | number>("");
    const days = ref<string | number>(0);
    const showSelectDay = ref(false);
    const selectDay = ref(0);
    const handleData = () => {
      if (!selectDate.value) {
        days.value = 0;
        selectDay.value = 0;
        return;
      }
      if (
        dayjs().format("YYYY-MM") == dayjs(selectDate.value).format("YYYY-MM")
      ) {
        days.value = parseInt(dayjs().format("DD"));
      } else {
        days.value = dayjs(selectDate.value).daysInMonth();
      }
      emit("timeChange", dayjs(selectDate.value).format("YYYY-MM"));
    };
    const changeDay = () => {
      emit(
        "timeChange",
        selectDay.value
          ? dayjs(selectDate.value)
              .set("date", selectDay.value)
              .format("YYYY-MM-DD")
          : dayjs(selectDate.value).format("YYYY-MM")
      );
    };
    return {
      selectDate,
      selectDay,
      days,
      changeDay,
      handleData,
      showSelectDay,
    };
  },
});
