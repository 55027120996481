import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface ComponentsListRes {
    panelequipmentsn: string
    articleequipmentsn: string
    creationdate: string
    enabled: boolean
    type:string
    sign:string
}
export async function getComponentsList(params: ListReq): Promise<ListRes<ComponentsListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<ComponentsListRes>>(
        {
            url: `/component/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        if (!v.articleequipmentsn) v.articleequipmentsn = '空'
        return v
    })
    return res
}