
  import { defineComponent, ref, readonly, onMounted } from 'vue'
  import { faultModel, getFaultList } from '@/api/System/fault'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import dayjs from 'dayjs'
  import addDialog from './components/addDialog.vue'
  export default defineComponent({
    name: 'fault',
    components: {
        addDialog
    },
    setup() {
      const loading = ref(false)
      const limit = ref(20)
      const page = ref(1)
      const total = ref(0)
      const list = ref<faultModel[]>([])
      const addDialogRef = ref<any>();
      const btnRemoveLoading = ref(false)
      onMounted(() => {
        reData()
      })
      const reData = async (rq?: any) => {
        try {
          loading.value = true
           let rqData: any = { current: page.value, size: limit.value }
  
          if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
            }
            
            rqData = { ...rqData, entity: rq }
          }
          const res = await getFaultList(rqData)
          console.log('查询的数据=',res)
          list.value = res.rows
          total.value = res.total
        } finally {
          loading.value = false
        }
      }
   
      const handelChange = () => {
        reData();
      }
      
      const handelAdd = (item?:any) => {
        addDialogRef.value.open(item)
      }

      
      return {
        handelChange,
        list,
        loading,
        btnRemoveLoading,
        page,
        limit,
        total,   
        handelAdd,
        addDialogRef,
      }
    },
  })
  