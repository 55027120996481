import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
import { userStore } from "@/store/modules/user";

export interface repairListModel {
    "equipmentId":string, //设备id
    "faultDesc":string,
    "inEquipmentSn": string, //更换前SN
    "gmtCreate": string, //创建时间
    "gmtModified": string, //更新时间
    "gmtComplete": string, //完结时间
    "inUserAccount": string, //录入账号
    "inUserId": string, //录入id
    "maintainDesc":string,  //维修完毕描述
    "outEquipmentSn":string,//维修后小魔夹SN
    "outUserAccount":string,//完结账号
    "outUserId": string,//完结id
    "result":string,//结果
    "status":number, //状态 0：待维修 1：维修中 2:已完成 
    "id":number,
}

export interface repairDetailsModel {
    "equipmentId": string,
    "faultDesc": string,   
    "gmtComplete": string,
    "gmtCreate": string,
    "gmtModified": string,
    "id": number,
    "inEquipmentSn": string,
    "inUserAccount": string,
    "inUserId": string,
    "maintainDesc": string,
    "newEquipment": boolean,
    "newEquipmentId": string,
    "outEquipmentSn": string,
    "outUserAccount": string,
    "outUserId": string,
    "result": string,
    "status": string
    "faultPicList": any[],
    "maintainPicList": any[],
}

export async function repairListHttp(params: ListReq): Promise<ListRes<repairListModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<repairListModel>>(
        {
            url: `/eqmaintain/page`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {    
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        v.gmtComplete = v.gmtComplete?dayjs(v.gmtComplete).format("YYYY-MM-DD HH:mm:ss"):"暂无";
        return v
    })
    return res
}

//创建维修单
export async function createHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqmaintain/create`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "新增成功"
}

//完结维修单
export async function completeHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqmaintain/complete`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "完结成功"
}

//替换小魔夹SN
export async function replaceSnHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqmaintain/replacesn`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "替换成功"
}


//加载故障详情
export async function getDetailsHttp(id: string): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/eqmaintain/getdetail`,
            method: "GET",
            params: {
                token: true,
                id
            },
        }, 
        {
            prefix: "/shared",  
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }        
        }
    );
    return res
}

//确认接收维修单
export async function confirmHttp(id: string): Promise<string> {
    const res = await defHttp.request<any>(
        {
            url: `/eqmaintain/receive`,
            method: "POST",
            params: {
                token: true,
                id
            },
        }, 
        {
            prefix: "/shared",  
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }        
        }
    );
    return res.msg || "接收成功"
}

export async function exportEqmaintainList(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportEqmaintainInfo`,
            responseType:'blob',
            params
            
        },{
            prefix: "/analy",
            contentType: 'json',
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}