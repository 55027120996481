
import { faultListModel,  faultCompleteHttp, faultDetailsModel,getFaultDetalsHttp,} from '@/api/fault/index'
import { getOrderList, OrderList,} from '@/api/Order'
import { faultModel, getFaultList } from '@/api/System/fault'
import { createHttp } from '@/api/fault/repair'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { userStore } from "@/store/modules/user";
import { toOptional } from "@/types/utils";
let overdueId = 0;
let startTime=0;
let deviceSn="";
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<faultListModel>()
    const list = ref<OrderList[]>([])
    const faultList = ref<faultModel[]>([])
    const orderDetailsInfo=ref<toOptional<faultDetailsModel>>({});
    const inputData = ref({
      needMaintain:false,
      reason:'',
      revokePlace:''
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async (_row: faultListModel) => {
      row.value=_row;
      await getFaultTemplate();
      overdueId=_row.id;
      reData(_row.sharedOrderId) 
    }

    const getFaultTemplate = async () =>{
      
      let rqData: any = { current: 1, size: 20 }
      const res = await getFaultList(rqData)
      faultList.value = res.rows
       
    }

    const submit = async () => {
      if (!row.value) return
      try {
        btnSumitLoading.value = true
        // const rentdays = inputData.value.day;
        // const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        // const desc=time+'确认收到小魔夹'
        // const money= '确认扣除'+inputData.value.day*3+'元费用'
        // const returnTime=dayjs(startTime+rentdays*24*60*60*1000).format("YYYY-MM-DD HH:mm:ss");
        // const res = await faultComfirmHttp({
        //   breakdownId: overdueId,
        //   // handleUserId:userStore.userInfo.userid,
        //   // amount: rentdays * 3,
        //   remark:desc,
        //   excuteInfo:money,
        //   stopBillingTime:returnTime,
      
        // })
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc = userStore.userInfo.account+'完结确认为故障状态'
        const res = await faultCompleteHttp({
          breakdownId:overdueId,
          excuteInfo:desc,
          remark:time+'完结故障',
          needMaintain:inputData.value.needMaintain
        })
        await addRepairOrder();
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }

    const addRepairOrder = async() =>{
      const info = await getFaultDetalsHttp(row.value!.id.toString())
      orderDetailsInfo.value=info.data;
      const time = dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc = userStore.userInfo.account +"在"+time+ '新增维修单'
      const res = await createHttp({        
        equipmentSn: row.value!.equipmentSn,
        faultDesc: orderDetailsInfo.value.content? orderDetailsInfo.value.content:desc,
        reason:inputData.value.reason,
        revokePlace:inputData.value.revokePlace,
        faultPicList:orderDetailsInfo.value.attachList
      })
    }

    const reData = async (shareOrderid: any) => {
      try {
     
        let rqData: any = { current: "1", size: "10" ,entity:{sharedorderid: shareOrderid}}
   
        const res = await getOrderList(rqData)
        list.value = res.records
        startTime=new Date(list.value[0].receivedate).getTime();
      } finally {
        visible.value = true
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      row,
      submit,
      inputData,
      faultList,
    }
  },
})
