
import { ref,reactive,defineComponent,toRef} from 'vue'
import { SharedorderprivatizationdeductionListNew } from "@/api/Order/Privatization";
export default defineComponent({
   props: ["info"],
   setup(props) {
    const info: SharedorderprivatizationdeductionListNew = toRef(props, "info");
    // const status=toRef(props,"statu");
    
    const active=ref(0);
     if(info.status==0){
      active.value=0;
    }else if(info.status==1){
      active.value=1;
    }else if(info.status==2){
      active.value=2;
    }else if(info.status==3){
      active.value=3;
    }else if(info.status==4){
      active.value=4;
    }else if(info.status==5){
      active.value=4;
    }else if(info.status==99){
      active.value=5;
    }
    
    return {
      syncInfo: info,
      active,
    };
  },
})
