<template>
  <el-dialog
      :destroy-on-close="true"
      :title="title"
      @closed="handleClose"
      class="dialog"
      v-model="visible"
      width="960px"
    >
    <div class="container">
     <header>     
        <el-button style="margin-left: 16px;" @click="handleExport()" icon="el-icon-tickets" type="primary">导出表数据</el-button>
      </header>
      
      <el-tabs v-model="activeName"  @tab-click="handleClick" style="margin-top: 12px;">
        <el-tab-pane  label="租借" name="2"></el-tab-pane>
        <el-tab-pane label="归坏" name="7" ></el-tab-pane>
      </el-tabs>
      <el-table :data="list" height="45vh" style="width: 100%" v-if="list">
        <el-table-column align="left" label="小魔夹SN" min-width="40">
          <template #default="scope">
            AADG203
          </template>
        </el-table-column>
        <el-table-column align="left" label="租赁账号" min-width="40">
          <template #default="scope">
            13750541512
          </template>
        </el-table-column>
        <el-table-column align="center" label="机柜SN" min-width="60">
          <template #default="scope">
            <el-tag type="success">AADG203</el-tag>          
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额" min-width="40">
          <template #default="scope">
            ￥ 16.0
          </template>
        </el-table-column>      
        <el-table-column align="center" label="时间" min-width="60">
          <template #default="scope">
            2023-06-03 15:23:36
          </template>
        </el-table-column>
      </el-table>
      <el-pagination 
        :current-page="page" 
        :page-size="limit" 
        :page-sizes="[10, 30, 50, 100]" 
        :total="total" 
        @current-change="pageChange" 
        @size-change="handleLimitChange" 
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </el-dialog>
  <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
</template>

<script lang = "ts" >
import { defineComponent, ref } from "vue";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
import exportDialog from "@/components/export/exportDialog.vue";
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog,
   },
   setup() {
    const title = ref('订单详情');
    const visible = ref(false);
    const activeName = ref("2")
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const exportDialogRef = ref<any>();
    const list = ref<any[]>([]);
    
    const open = () => {
      visible.value = true;
      for(var i=0;i<5;i++){
        list.value.push(i)
      }
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsList();
    };

    const getStatisticsList = async() => {
      console.log('开启调用')
      try {
        // //reLoading.value = true;
        // let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,
        //   userid:userId,type:valueType,rechargeType:activeName.value};
        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        // }
        // const res = await getStatisticsDetailsList(rqData);
        // total.value=res.data.total
        // list.value=res.data.records
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const handleExport = async() =>{
      //exportDialogRef.value.open(`${name}的收益`)
    }
    const exportFile = async(name:string) =>{
      try {

        // let rqData: any = {fortest:search.value.fortest,fileName:name+'的收益',userid:userId,type:valueType,rechargeType:activeName.value}

        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        // }

        // const res = await exportStatisticsDetails(rqData)
      
        // const blob = new Blob([res.data]);
      
        // saveAs(blob, name)
      } finally {

      }
    }
    return {
      title,
      visible,
      activeName,
      page,
      total,
      limit,
      list,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
</script>
<style lang = "scss" scoped >
.container{
  margin-top: -16px;
  .total-parent{
    display: flex;
    flex-direction: row;
    height: 96px;
    width: 100%;
    align-items: center;
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    .total-item{
      width: 25%;
      display: flex;
      flex-direction: column;
      background: #f2f2f2;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      .item-top{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .item-buttom{
        display: flex;
        margin-top: 8px;
        flex-direction: column;
      }
    }
  } 
  
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>