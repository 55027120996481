<template>
  <div style="height: 380px; margin-left: 24px; margin-top: 12px;">
    <el-steps direction="vertical" :active="active">
        <el-step title="不归还" :description="syncInfo.description"></el-step>
        <el-step title="第9天短信" description="未通知"></el-step>
        <el-step title="发起扣费" :description='"预计扣款"+syncInfo.paymentamount+"元"'></el-step>
        <el-step title="支付成功" :description='"订单扣款"+syncInfo.paymentamount+"元"'></el-step>
        <el-step title="订单完结" ></el-step>
    </el-steps>
  </div>
</template>
<script  lang='ts'>
import { ref,reactive,defineComponent,toRef} from 'vue'
import { SharedorderprivatizationdeductionListNew } from "@/api/Order/Privatization";
export default defineComponent({
   props: ["info"],
   setup(props) {
    const info: SharedorderprivatizationdeductionListNew = toRef(props, "info");
    // const status=toRef(props,"statu");
    
    const active=ref(0);
     if(info.status==0){
      active.value=0;
    }else if(info.status==1){
      active.value=1;
    }else if(info.status==2){
      active.value=2;
    }else if(info.status==3){
      active.value=3;
    }else if(info.status==4){
      active.value=4;
    }else if(info.status==5){
      active.value=4;
    }else if(info.status==99){
      active.value=5;
    }
    
    return {
      syncInfo: info,
      active,
    };
  },
})
</script>
<style scoped lang='scss'>
</style>