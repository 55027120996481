
import { defineComponent, nextTick, readonly, ref } from "vue";
import { placeBindpanel, upDatePanel } from "@/api/Share/Panel";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "v-dialog",
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const isEidt = ref(false);
    const title=ref('更新仓位口')
    let index = -1;
    const inputData = ref({
      panelequipmentsn: "", // 机柜SN
      socketsn:"", //仓位口号
      article:'true', //是否有共享物品
      articleequipmentsn:"",//仓位设备SN
      fault:'false', //是否有故障
      equipmentsn:"",//设备序号
      socketstatus:'1'//仓位口状态
    });
    const rules = readonly({
      panelequipmentsn: [Rules.required()]
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
      console.log("fromRef.value :>> ", fromRef.value);
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      isEidt.value = false;
    };
    const open = (_index: number, row?: any) => {
      visible.value = true;
      title.value='更新机柜'+row.panelequipmentsn+"的"+row.socketsn+"口信息"
      inputData.value.socketsn=row.socketsn;
      inputData.value.panelequipmentsn=row.panelequipmentsn;
      if (_index > -1) {
        index = _index;
      }

      // const _inputData: any = {};
      // Object.keys(inputData.value).map((v) => {
      //   _inputData[v] = row[v];
      // });
      // inputData.value = Object.assign({}, inputData.value, _inputData);
    };
    const submit = async () => {
      try {
        inputData.value.equipmentsn=inputData.value.articleequipmentsn;
        console.log(inputData.value)
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        let res: any;
        //if (isEidt.value) {
          res = await upDatePanel(inputData.value);
        // } else {
        //   res = await placeBindpanel(inputData.value);
        // }
        handleClose();
        await nextTick();
        ElMessage.success(res.message);
        emit("success", {
          index,
          data: res.record,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      title,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      isEidt,
    };
  },
});
