<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-select" clearable placeholder="是否返修" v-model="mixin_router_search.needMaintain">
        <el-option label="要返修" value="true"></el-option>
        <el-option label="不返修" value="false"></el-option>
      </el-select>     
      <el-select @change="handelChange" class="header-select" clearable placeholder="故障状态" v-model="mixin_router_search.status">
        <el-option label="待确认" value="0"></el-option>
        <el-option label="已确认" value="1"></el-option>
        <el-option label="已撤回" value="2"></el-option>
        <el-option label="已完结" value="3"></el-option>
        <el-option label="已驳回" value="4"></el-option>
      </el-select>     

       <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="上报时间"
        range-separator="至"
        start-placeholder="上报时间"
        type="datetimerange"
        unlink-panels        
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="订单id">
              <span>{{ scope.row.sharedOrderId }}</span>
             <my-copy :data="scope.row.sharedOrderId" />
            </el-form-item>
            
            <el-form-item label="上报用户id">
              <span>{{ scope.row.submitUserId }}</span>
              <my-copy :data="scope.row.submitUserId"></my-copy>
            </el-form-item>
          
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="序号" min-width="20">
        <template #default="scope">      
          <img src="@/assets/img/icon_repair.png" alt="" v-if="scope.row.needMaintain" style="width: 16px;margin-top: 8px;"> 
          <span v-else style="width: 16px; display: inline-block;"></span>
          {{scope.row.id}}
          <my-copy :data="scope.row.id"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="设备SN" align="center" min-width="44">
        <template #default="scope">      
          {{scope.row.equipmentSn}}
          <my-copy :data="scope.row.equipmentSn"></my-copy>     
        </template>
      </el-table-column>
      <el-table-column label="上报账号" align="center" min-width="44">
        <template #default="scope">{{scope.row.submitUserAccount}}</template>
      </el-table-column>
      <el-table-column label="上报人" align="center" min-width="44">
        <template #default="scope">{{scope.row.submitUserName}}</template>
      </el-table-column>
      <el-table-column align="center" label="进程状态" min-width="24">
        <template #default="scope">
          <el-tag  type="waring" v-if="scope.row.status==0">待确认</el-tag>    
          <el-tag  type="error" v-if="scope.row.status==1">已确认</el-tag>    
          <el-tag  type="info"   v-if="scope.row.status==2">已撤回</el-tag>
          <el-tag  type="success"   v-if="scope.row.status==3">已完结</el-tag>
          <el-tag  type="waring"   v-if="scope.row.status==4">已驳回</el-tag>
        </template>
      </el-table-column>
      
      <el-table-column label="结算时间" align="center" min-width="50">
        <template #default="scope">{{scope.row.stopBillingTime}}</template>
      </el-table-column>
      <el-table-column label="上报时间" align="center" min-width="50">
        <template #default="scope">{{scope.row.gmtCreate}}</template>
      </el-table-column>
     
      <el-table-column align="left" label="操作" min-width="90">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
         <el-button
            @click="handleConfirm(scope.$index,scope.row)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='0'"
          >确认故障</el-button>
          <el-button
            @click="handleComplete(scope.$index,scope.row)"
            size="mini"
            type="success"
            v-if="scope.row.status=='1'"
          >完结故障</el-button>
        <!-- <el-button
            @click="handleRevocation(scope.$index,scope)"
            size="mini"
            type="danger"
            v-if="scope.row.status=='0'"
            style="margin-left: 12px;"
          >撤回故障</el-button> -->
        <el-button
            @click="handleReject(scope.$index,scope.row)"
            size="mini"
            type="danger"
            v-if="scope.row.status=='0'"
            style="margin-left: 12px;"
          >驳回故障</el-button>
          <el-button
            @click="handleDetails(scope.$index,scope.row)"
            size="mini"
            style="margin-left: 12px;"
          >查看详情</el-button>
         
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <comfirm-dialog @success="mixinReRouter" ref="comfirmDialogRef"></comfirm-dialog>
    <complete-dialog @success="mixinReRouter" ref="completeDialogRef"></complete-dialog> 
    <details-drawer ref="detailsDrawerRef"></details-drawer>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { faultListModel, faultListHttp ,faultComfirmHttp, faultCompleteHttp, faultRevocationHttp, faultRejectHttp,exportEqbreakdownInfo} from '@/api/fault/index'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import comfirmDialog from './components/comfirmDialog.vue'
import completeDialog from "./components/completeDialog.vue"
import detailsDrawer from './components/detailsDrawer.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'fault',
  components: {
   comfirmDialog,
   completeDialog,
   detailsDrawer,
   exportDialog,
  },
  setup() {
    const detailsDrawerRef = ref<any>()
    const comfirmDialogRef = ref<any>()
    const completeDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const mixin_router_search = ref({
      status: '', //状态 0:待入库 1：已入库 2：已出库
      careateBN:'',
      needMaintain:'',
      order: 'gmtCreate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        equipmentSn:'', //设备sn
        submitUserAccount:'',//上报手机号    
        submitUserName:'',//上报昵称   
        sharedOrderId:'',
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        equipmentSn: '小魔夹SN', 
        submitUserAccount: '上报账号', 
        submitUserName:'上报昵称',
        sharedOrderId:'订单id'
      },
    })
    const searchSelect = ref({search: 'equipmentSn'})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<faultListModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.careateBN) {
            rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await faultListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleConfirm = async(idx:number,item:any) =>{
       comfirmDialogRef.value.open(item);
        // await ElMessageBox.confirm(
        //   `确认设备`+item.equipmentSn+'为故障状态',
        //   '提示',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //     distinguishCancelAndClose: true,
        //   }
        // )
        // const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        // const desc = userStore.userInfo.account+'确认为故障状态'
        // const res = await faultComfirmHttp({
        //   breakdownId:item.id,
        //   excuteInfo:desc,
        //   remark:time+'确定为故障状态'
        // })
        // mixinReRouter()
        // ElMessage.success(res)
    }

    const handleComplete = async(idx:number,item:any) =>{
       completeDialogRef.value.open(item);
        // await ElMessageBox.confirm(
        //   `完结设备`+item.equipmentSn+'的故障',
        //   '提示',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //     distinguishCancelAndClose: true,
        //   }
        // )
        // const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        // const desc = userStore.userInfo.account+'确认为故障状态'
        // const res = await faultCompleteHttp({
        //   breakdownId:item.id,
        //   excuteInfo:desc,
        //   remark:time+'完结故障'
        // })
       
        // // list.value[idx].status=3;
        // // console.log("消息回调")
        // mixinReRouter()
        // ElMessage.success(res)
       
    }

    const handleRevocation = async(idx:number,item:any) =>{
      await ElMessageBox.confirm(
          `撤销设备`+item.equipmentSn+'的故障',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const res = await faultRevocationHttp({
          breakdownId:item.id,
          excuteInfo:{},
          remark:time+'撤销故障'
        })
        mixinReRouter()
        ElMessage.success(res)
       
    }
  
    const handleReject = async(idx:number,item:any) =>{
      const desc = userStore.userInfo.account+'驳回故障'
      await ElMessageBox.confirm(
          `驳回设备`+item.equipmentSn+'的故障',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const res = await faultRejectHttp({
          breakdownId:item.id,
          excuteInfo:desc,
          remark:time+'驳回故障'
        })
        // list.value[idx].status=4;
        // reload();
        mixinReRouter()
        ElMessage.success(res)
    }

    const handleDetails = async(idx:number,item:any) =>{
      detailsDrawerRef.value.open(item)
    }
   
    const handleExport = async() => {
      exportDialogRef.value.open('故障记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'eqbreakdown',order: 'gmtCreate',sort: 'desc'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          searchData.startGmtCreate = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          searchData.endGmtCreate =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportEqbreakdownInfo(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }

    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      changSearch,
      list,
      loading,
      detailsDrawerRef,
      comfirmDialogRef,
      completeDialogRef,
      exportDialogRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      handleConfirm,
      handleComplete,
      handleRevocation,
      handleReject,
      handleDetails,
      handleExport,    
      exportFile 
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>