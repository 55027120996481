import { ref, computed, nextTick } from "vue"
import { getSharedorderprofitGetProfitAndRatioGroupByRole } from "@/api/Index/platfrom";
import dayjs from "dayjs";
export default function getProfitMuiltPie() {
    const earningsRatio = ref({
        title: `${parseInt(
            dayjs().subtract(1, "month").format("MM")
        )}月收益占比`,
        type: 1,
        chartData: [] as any,
        select: "",
        box1: {
            label: "收益",
            value: "XX",
        },
        box2: {
            label: "平均增长",
            value: "+130%",
        },
    });
    const selectData = computed(() => {
        return earningsRatio.value.chartData?.find((v: any) => v[4] === true) || [];
    })
    const earningsRatioChartData2 = computed(() => {
        const _serise2Data: any = selectData.value;
        return _serise2Data ? [{ name: dayjs().format('YYYY-MM'), value: _serise2Data[1] || 0 }, { name: dayjs().subtract(1, "M").format('YYYY-MM'), value: _serise2Data[2] || 0 }] : []
    })
    const reEarningsRatio = async ({ productid, date }: { productid?: string, date?: string }) => {
        const res = await getSharedorderprofitGetProfitAndRatioGroupByRole({ productid, date: date || dayjs().format('YYYY-MM') });
        earningsRatio.value.chartData = [["角色", "收益", '上月收益', "同比增长", "seleced"], ...res]
        earningsRatio.value.box1.label = res[0][0] + '收益';
        earningsRatio.value.box1.value = res[0][1];
        earningsRatio.value.box2.value = res[0][3];
        console.log('earningsRatioChartData2 :>> ', earningsRatioChartData2.value);
    };
    const changeEarningsRatioData = (data: any) => {
        earningsRatio.value.chartData = data;
        earningsRatio.value.box1.label = selectData.value[0] + '收益';
        earningsRatio.value.box1.value = selectData.value[1];
        earningsRatio.value.box2.value = selectData.value[3];
    }
    return {
        reEarningsRatio,
        earningsRatioChartData2,
        earningsRatio,
        changeEarningsRatioData
    }
}