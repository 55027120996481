
import { defineComponent, ref, onMounted} from 'vue'
import { getInAndOutTotalMoney ,totalMoneyModel} from "@/api/Financial/inAndOutTotalMoney"
import dayjs from 'dayjs'
import oldChangeDetailsDialog from '@/components/financial/inAdnOut/oldChangeDetailsDialog.vue'
import newChangeDetailsDialog from '@/components/financial/inAdnOut/newChangeDetailsDialog.vue'
import oldWithdrawDetailsDialog from '@/components/financial/inAdnOut/oldWithdrawDetailsDialog.vue'
import newWithdrawDetailsDialog from '@/components/financial/inAdnOut/newWithdrawDetailsDialog.vue'
import shareOrderDetailsDialog from '@/components/financial/inAdnOut/shareOrderDetailsDialog.vue'
import shareOrderBalanceDetailsDialog from '@/components/financial/inAdnOut/shareOrderBalanceDetailsDialog.vue'
import refundDetailsDialog from '@/components/financial/inAdnOut/refundDetailsDialog.vue'
export default defineComponent({
  name: 'in-and-out-total-money',
  components: {
    oldChangeDetailsDialog,
    newChangeDetailsDialog,
    oldWithdrawDetailsDialog,
    newWithdrawDetailsDialog,
    shareOrderDetailsDialog,
    shareOrderBalanceDetailsDialog,
    refundDetailsDialog,
  },
  setup() {
    const search = ref({
      fortest: false, //是否用于测试，如：true 是 false 否
      timeBN: ''
    })
    const loading = ref(false)
    const list = ref<any[]>([])
    const oldAlipayCharge = ref("0");
    const oldWechatpayCharge = ref("0");
    const newAlipayCharge = ref("0");
    const newWechatpayCharge = ref("0");
    const oldAlipayWithdraw = ref("0");
    const oldWechatpayWithdraw = ref("0");
    const newAlipayWithdraw = ref("0");
    const newWechatpayWithdraw = ref("0");
    const shareorderAlipay = ref("0");
    const shareorderWechatpay = ref("0");
    const shareorderBalance = ref("0");
    const returnMoney = ref("0");
    const userBalance = ref("0");

    const oldChangeDetailsDilaogRef = ref<any>()
    const newChangeDetailsDialogRef = ref<any>()
    const oldWithdrawDetailsDialogRef = ref<any>()
    const newWithdrawDetailsDialogRef = ref<any>()
    const shareOrderDetailsDialogRef = ref<any>()
    const shareOrderBalanceDetailsDialogRef = ref<any>()
    const refundDetailsDialogRef = ref<any>()
    // const objectSpanMethod = ({ row , column , rowIndex , columnIndex  }) => {
    //     if (columnIndex === 0) {
    //         if(rowIndex<8){
    //             if( rowIndex % 2 === 0){
    //                 return {
    //                   rowspan: 2,
    //                   colspan: 1
    //                 }
    //             }else{
    //                 return {
    //                   rowspan: 0,
    //                   colspan: 0
    //                 }
    //             }
    //         }else{
    //             if(rowIndex==8){
    //                 return {
    //                   rowspan: 3,
    //                   colspan: 1
    //                 }
    //             }else{
    //                 return {
    //                   rowspan: 0,
    //                   colspan: 0
    //                 }
    //             }
    //         }
    //         // if(rowIndex > orderLength.value+1+9){
    //         //     return {
    //         //     rowspan: 0,
    //         //     colspan: 0
    //         //     };
    //         // } else if(rowIndex==9){
    //         //     return {
    //         //     rowspan: orderLength.value+1,
    //         //     colspan: 0
    //         //     };
    //         // }else if (rowIndex<9 && rowIndex % 2 === 0) {
    //         //     return {
    //         //     rowspan: 2,
    //         //     colspan: 1
    //         //     };
    //         // } else {
    //         //     return {
    //         //     rowspan: 0,
    //         //     colspan: 0
    //         //     };
    //         // }
    //     }
    // }

    const reData = async () => {
      oldAlipayCharge.value = "0";
      oldWechatpayCharge.value = "0";
      newAlipayCharge.value = "0";
      newWechatpayCharge.value = "0";
      oldAlipayWithdraw.value = "0";
      oldWechatpayWithdraw.value = "0";
      newAlipayWithdraw.value = "0";
      newWechatpayWithdraw.value = "0";
      shareorderAlipay.value = "0";
      shareorderWechatpay.value = "0";
      shareorderBalance.value = "0";
      userBalance.value = "0";
      try {
        let rqData : any = {fortest:search.value.fortest}
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        const res = await getInAndOutTotalMoney(rqData);
       // orderLength.value=res.data!.shareorder.length;
        res.data?.oldcharge.forEach(item =>{
          if(item.type=='1'){
            oldAlipayCharge.value=item.money
          }else if(item.type=='2'){
            oldWechatpayCharge.value=item.money
          }
        })
         res.data?.newcharge.forEach(item =>{
          if(item.type=='alipay'){
            newAlipayCharge.value=item.money
          }else if(item.type=='wechatpay'){
            newWechatpayCharge.value=item.money
          }
        })
        res.data?.oldwithdraw.forEach(item =>{
          if(item.type=='1'){
            oldAlipayWithdraw.value=item.money
          }else if(item.type=='2'){
            oldWechatpayWithdraw.value=item.money
          }
        })
        res.data?.newwithdraw.forEach(item =>{
          if(item.type=='alipay'){
            newAlipayWithdraw.value=item.money
          }else if(item.type=='wechatpay'){
            newWechatpayWithdraw.value=item.money
          }
        })
        res.data?.shareorder.forEach(item =>{
          if(item.type=='ZHIMA'){
            shareorderAlipay.value=item.money
          }else if(item.type=='PAYSCORE'){
            shareorderWechatpay.value=item.money
          }else if(item.type=='BALANCE'){
            shareorderBalance.value=item.money
          }
        })
        res.data?.balance.forEach(item =>{
          if(item.type=='56c62a11a6a14d6eb7ab29fbf9f2f8b4'){
            userBalance.value=item.money
          }
        })
        res.data?.return.forEach(item =>{
          returnMoney.value=item.money
        })
        // list.value.push({name:"newcharge",data:res.data['newcharge'][0]})
        // list.value.push({name:"newcharge",data:res.data['newcharge'][1]})
        // list.value.push({name:"oldcharge",data:res.data['oldcharge'][0]})
        // list.value.push({name:"oldcharge",data:res.data['oldcharge'][1]})
        // list.value.push({name:"newwithdraw",data:res.data['newwithdraw'][0]})
        // list.value.push({name:"oldwithdraw",data:res.data['oldwithdraw'][1]})
        
        // list.value.push({name:"shareorder",data:res.data['shareorder'][i]})
        
        // list.value.push({name:"balance",data:res.data['balance']})
      
      }catch(err){

      } finally {
        loading.value = false
      }
    }

    const handelChange = () => {
      reData();
    }
    onMounted(()=>{
      reData();
    }) 
    const handleClick =(type:string,name:string,rechargemode:string) =>{
      if(type=='oldCharge'){
        oldChangeDetailsDilaogRef.value.open(type,name,rechargemode)
      }else if(type == 'charge'){
        newChangeDetailsDialogRef.value.open(type,name,rechargemode)
      }else if(type=='oldWithdraw'){
        oldWithdrawDetailsDialogRef.value.open(type,name,rechargemode)
      }else if(type=='newWithdraw'){
        newWithdrawDetailsDialogRef.value.open(type,name,rechargemode)
      }else if(type=="shareOrder"){
        if(rechargemode=='BALANCE'){
          shareOrderBalanceDetailsDialogRef.value.open(type,name,rechargemode)
        }else{
          shareOrderDetailsDialogRef.value.open(type,name,rechargemode)
        }
      }else if(type=='refund'){
        refundDetailsDialogRef.value.open(type,name,rechargemode)
      }
      

    }
    return {
     loading,
     search,
     list,
    //  objectSpanMethod,
     oldAlipayCharge,
     oldWechatpayCharge,
     newAlipayCharge,
     newWechatpayCharge,
     oldAlipayWithdraw,
     oldWechatpayWithdraw,
     newAlipayWithdraw,
     newWechatpayWithdraw,
     shareorderAlipay,
     shareorderWechatpay,
     shareorderBalance,
     returnMoney,
     userBalance,
     handelChange,
     handleClick,
     oldChangeDetailsDilaogRef,
     newChangeDetailsDialogRef,
     oldWithdrawDetailsDialogRef,
     newWithdrawDetailsDialogRef,
     shareOrderDetailsDialogRef,
     shareOrderBalanceDetailsDialogRef,
     refundDetailsDialogRef,
    }
  },
})
