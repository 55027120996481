import { defHttp } from "@/utils/http/axios";
import { RequestRes } from "../../types/requestBaseType"


export interface PlatformprofitwalletLoad {
    "fortest": boolean,
    "articleproductid": string,
    "articleproductname": string,
    "articleproducttype": string,
    "articleproductmodel": string,
    "amount": number,
    "creationdate": string,
    "modificationdate": string
}

export async function platformprofitwalletLoad(params: { articleproductid: string, fortest: boolean }): Promise<PlatformprofitwalletLoad> {
    const res = await defHttp.request<RequestRes<PlatformprofitwalletLoad>>(
        {
            url: `/platformprofitwallet/load`,
            params: {
                token: true, ...params
            }
        }, {
        prefix: "/shared"
    }
    );
    if (!res?.record) throw Error('获取收益钱包信息失败')
    return res.record
}
export interface PlatformexpensewalletLoad {
    "fortest": boolean,
    "articleproductid": string,
    "articleproductname": string,
    "articleproducttype": string,
    "articleproductmodel": string,
    "amount": number,
    "creationdate": string,
    "modificationdate": string
}

export async function platformexpensewalletLoad(params: { articleproductid: string, fortest: boolean }): Promise<PlatformexpensewalletLoad> {
    const res = await defHttp.request<RequestRes<PlatformexpensewalletLoad>>(
        {
            url: `/platformexpensewallet/load`,
            params: {
                token: true, ...params
            }
        }, {
        prefix: "/shared"
    }
    );
    if (!res?.record) throw Error('获取收益钱包信息失败')
    return res.record
}
export async function platformprofitwalletTransfer(params: { amount: number, articleproductid: string, fortest: boolean }): Promise<string> {
    const res = await defHttp.request<RequestRes<PlatformexpensewalletLoad>>(
        {
            url: `/platformprofitwallet/transfer`,
            params: {
                token: true, ...params
            }
        }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        prefix: "/shared"
    }
    );
    if (!res?.message) throw Error('转账失败')
    return res.message
}