
import privateOrderNoReturnVue from "./privateOrderNoReturn.vue"; //私有化不归还流程
import privateOrderReturnVue from "./privateOrderReturn.vue";      //私有化归还流程
import privateOrderOtherReturnVue from "./privateOrderOtherReturn.vue"; //私有化沟通愿意归还后期没有归还
import negotiationDialogNew from "./negotiationDialogNew.vue";
import { ref,computed, defineComponent, onMounted ,reactive} from "vue";
import {
  sharedorderprivatizationdeductionListNew,
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationnegotiationLoadNew,
  SharedorderprivatizationnegotiationLoadNew,
  sharedorderprivatizationreturnConfirm,
  sharedorderprivatizationreturnLoad,
  sharedorderprivatizationprocessRelaunchStart,
  sharedorderprivatizationprocessLaunchComplete,
  sharedorderprivatizationprocessDeduction,
} from '@/api/Order/Privatization'
import { toOptional } from "@/types/utils";
import { userStore } from "@/store/modules/user";
export default defineComponent({


    setup() {
        console.log('进入弹窗');
        const isVisible = ref(false);
        const title = ref("私有化处理");
        const direction=ref("rtl");
        const account=ref('')//租赁账号
        const sn=ref('')  //设备SN
        const orderTime=ref("")//订单时长
        const sharedorderid=ref("")//订单id
        const status=ref(0) //订单状态
        const isOrderTest=ref(false)  //是否测试订单
        const negotiationDialogRef = ref<any>({})
        const orderInfo = ref<toOptional<SharedorderprivatizationdeductionListNew>>({});
        const list = ref<SharedorderprivatizationdeductionListNew[]>([])
        const orderDetailsInfo=ref<SharedorderprivatizationnegotiationLoadNew[]>([]);
        const handleClose=(done:any)=>{
            done();
        };

        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            isOrderTest.value=item.fortest;
            sharedorderid.value=item.sharedOrderId;
           
            reData()
        };

        /**
         * 开启沟通弹窗
         */
        const handleNegotiation= (sharedorderid:string) =>{
            negotiationDialogRef.value.open(sharedorderid)
           
        }

        /**
         * 发送短信
         */
        const handleMsg = () =>{

        }

        /**
         * 确认归还
         */
        const handlerReturn = () =>{

        }

        /**
         * 发起扣款
         */
        const handlerDeduction = () =>{

        }

        /**
         * 完结订单
         */
        const handlerFinish = () =>{

        }

        /**
         * 加载列表数据
         */
         const reData = async () => {
            try {
                let rqData: any = { current: '1', size: '10',entity: {sharedOrderId: sharedorderid.value}}
                console.log(rqData);
                const res = await sharedorderprivatizationdeductionListNew(rqData)
                list.value = res.rows
                orderInfo.value=list.value[0];
                status.value=orderInfo.value.status!;
                console.log('订单')
                console.log(res)
                console.log(status.value)
                //getOrderProgress(orderInfo.value);
                // total.value = res.total
            } finally{

            }
        }

        /**
         * 获取私有化进程
         */
        const getOrderProgress = async (item:any) => {
            console.log('私有化处理加载')
            console.log(item)
            const status = item.status;
           // const overdueId=item.id.toString(); //1476384977803993090
            const overdueId="1476384977803993090"; //1476384977803993090
            const userToken=userStore.userInfo.token!;
            console.log('订单id')
          
            if (status > 0) {
                const info = await sharedorderprivatizationnegotiationLoadNew({ token: userToken,overdueId:overdueId})
                console.log('进程加载')
                console.log(info)
                orderDetailsInfo.value=info.rows;
            }
        }


        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,
            orderInfo,
            sn,
            orderTime,
            sharedorderid,
            account,
            handleNegotiation,
            handleMsg,
            handlerReturn,
            handlerDeduction,
            handlerFinish,
            negotiationDialogRef,
            status,
        };
    },
    components: {
        privateOrderNoReturnVue,
        privateOrderReturnVue,
        privateOrderOtherReturnVue,
        negotiationDialogNew,
    }
   
});
