<template>
  
     <div class="select-div">
        
        <el-select @change="handelTimeTypeChange" class="header-el-selector"  placeholder="时间选择" v-model="timeType">
          <el-option :value="true" label="时间段查"></el-option>
          <el-option :value="false" label="按年月查"></el-option>
        </el-select>
        <div v-if="timeType">
            <el-date-picker
            @change="handelChange('order')"
            align="right"
            class="header-select"
            style="margin-right: 24px;"
            end-placeholder="结束时间"
            range-separator="至"
            start-placeholder="开始时间"
            type="datetimerange"
            unlink-panels
            v-model="search.timeBN"
            
            ></el-date-picker>
        </div>
        <div v-else>
            <el-select @change="handelChange" class="header-el-selector"  placeholder="年选择" v-model="year">
            <el-option :key="item" :label="item.text" :value="item.value" v-for="item of yearData"></el-option>
            </el-select>
            <el-select @change="handelChange" class="header-el-selector" clearable placeholder="月选择" v-model="month">
                <el-option :key="item" :label="item.text" :value="item.value" v-for="item of monthData"></el-option>
            </el-select>
        </div>      
        <el-button type="primary" icon="el-icon-search" @click="handelSearch()">搜索</el-button>
        <el-button type="primary" icon="el-icon-download" @click="handelExport()" >下载</el-button>
     </div>
     <div style="width: 80%;height:560px;" ref="orderEcharRef" >关系图</div>
    <orderDetailsDialog ref="orderDetailsDialogRef"></orderDetailsDialog>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted,nextTick} from "vue";
import { OrderModel,getLoginHttp,exportLogin} from '@/api/Business/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import * as echarts from 'echarts';
import orderDetailsDialog from './orderDetailsDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";
type EChartsOption = echarts.EChartsOption;
var myChart: echarts.ECharts;
import dayjs from 'dayjs'
export default defineComponent({
    name: "Name",
    data() {
        return{
            yearData:[{'text':'2020年',value:2020},{'text':'2021年',value:2021},{'text':'2022年',value:2022},{'text':'2023年',value:2023},
            {'text':'2024年',value:2024},{'text':'2025年',value:2025},{'text':'2026年',value:2026},{'text':'2027年',value:2027}],
            monthData:[{'text':'一月',value:'01'},{'text':'二月',value:'02'},{'text':'三月',value:'03'},{'text':'四月',value:'04'},{'text':'五月',value:'05'},{'text':'六月',value:'06'},
            {'text':'七月',value:'07'},{'text':'八月',value:'08'},{'text':'九月',value:'09'},{'text':'十月',value:10},{'text':'十一月',value:11},{'text':'十二月',value:12}]
        }  
    },
    components: {
        orderDetailsDialog,
        exportDialog
    },
    setup() {
        const orderEcharRef = ref<any>("");
        const orderDetailsDialogRef = ref<any>("")
        const datevalue = ref("")
        const month = ref('')
        const timeType = ref(false)
        const orderXAxisList = ref<any[]>([]);
        const rentData = ref<number[]>([]);
        const returnData = ref<number[]>([]);
        const maxValue = ref<number>(300);
        const stepValue = ref<number>(50)
        const exportDialogRef = ref<any>();

        const year = ref(2023)
        const search = ref({
            timeBN:''
        });
        
        const initChart = () =>{
            const option = ref<EChartsOption> ({
                //dataset:dataset.value,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'],title:{line:'折线图',bar:'柱状图'} },
                        // restore: { show: true },
                        // saveAsImage: { show: true, title: '保存' }
                    }
                },
                legend: {data: ['注册', '登录']},
                xAxis: [
                    {
                        type: 'category',
                        data: orderXAxisList.value,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '人',
                        min: 0,
                        max: maxValue.value,
                        interval: stepValue.value,
                            axisLabel: {
                                formatter: '{value} 人'
                            }
                    }
                ],
                series: [
                    {
                        name:'注册',
                        type:'bar',
                        tooltip:{},
                        data: rentData.value
                    },               
                    {
                        name: '登录',
                        type: 'bar',
                        tooltip: {},
                        data: returnData.value
                    }
                    // {
                    //     name: 'Temperature',
                    //     type: 'line',
                    //     yAxisIndex: 1,
                    //     tooltip: {},
                    //     data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                    // }
                ],
            });
            myChart = echarts.init(orderEcharRef.value);
            myChart.setOption(option.value)
            console.log('参数',option.value)
            myChart.on('click', function(params) {
                console.log('点击了',params)
                orderDetailsDialogRef.value.open()
            });
        }
        const handelTimeTypeChange = async() =>{
           // timeType.value=!timeType.value
        }
        const handelChange = async(type:string) =>{

        }
        const handelSearch = async() =>{
            getOrderList();
        }
       
        const handelExport = async() =>{
            exportDialogRef.value.open('登录注册统计表')
        }

        const getOrderList=async()=>{
            console.log("获取周分布点")
            let rqData: any = {}
            if(timeType.value){
                rqData.startDate = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
                rqData.mintime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD") 
                rqData.endDate =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
                rqData.maxtime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD")  
                const dayEnd=new Date(rqData.endDate.toString())
                const datStart=new Date(rqData.startDate.toString())
                const timeDiff=dayEnd.getTime()-datStart.getTime()
                console.log('查询时间差=',timeDiff)
                if(timeDiff>30*24*60*60*1000){
                    await ElMessageBox.confirm(
                        '查询天数不能超过30天',
                        '信息提示',
                        {
                        confirmButtonText: '确定',
                        type: 'warning',
                        showCancelButton: false,
                        distinguishCancelAndClose:true
                        }
                    )
                    return
                }
            }else{
                rqData.year=year.value
                if(month.value&&month.value!=''){
                    rqData.month=month.value
                }
            }
          
            const res = await getLoginHttp(rqData,false) 
            
   
            let leaseValue: any[] = [];
            let returnValue: any[]= [];
            let timeValue: any[] = [];
            //orderData.value=res.data;    
            if(res.data.zclist){  
                res.data.zclist.forEach((item:any) => {
                    leaseValue.push(parseInt(item.count))
                    if(!timeType.value&&(!month.value||month.value=='')){
                        timeValue.push(parseInt(item.month))
                    }else{
                        timeValue.push(parseInt(item.day))
                    }
                });         
                
                rentData.value=leaseValue;
            }else{
                rentData.value=[]; 
            }
            if(res.data.loglist){
                res.data.loglist.forEach((item:any) => {
                    returnValue.push(parseInt(item.count))
                });   
                returnData.value=returnValue;     
            }else{
                returnData.value=[];
            }
            orderXAxisList.value=timeValue;
            
           
            console.log('X数据',orderXAxisList.value)
            console.log('注册数据=',rentData.value)
            console.log('登录数据=',returnData.value)
            let max=0;
            rentData.value.forEach(item=>{
                if(item>max){
                    max=item;
                }
            })
            returnData.value.forEach(item=>{
                if(item>max){
                    max=item;
                }
            })
            if(max<20){
                maxValue.value=20;
                stepValue.value=2;
            }else if(max<50){
                maxValue.value=50;
                stepValue.value=5;
            }else if(max<100){
                maxValue.value=100;
                stepValue.value=10;
            }else if(max<200){
                maxValue.value=200;
                stepValue.value=20;
            }else if(max<300){
                maxValue.value=300;
                stepValue.value=30;
            }else if(max<500){
                maxValue.value=500;
                stepValue.value=50;
            }else if(max<1000){
                maxValue.value=1000;
                stepValue.value=100;
            }else if(max<2000){
                maxValue.value=2000;
                stepValue.value=200;
            }else if(max<4000){
                maxValue.value=4000;
                stepValue.value=400;
            }else if(max<6000){
                maxValue.value=6000;
                stepValue.value=600;
            }
            reView();
        }
        
        const reView = async () => {
            console.log('重置关系树')
            nextTick(()=>{
                initChart();
            });
            
        };
        onMounted(async()=>{
            initChart();
            getOrderList();
            //reView();
        })

        const exportFile = async(name:string) =>{
            try {
                let rqData: any = {}
                if(timeType.value){
                    rqData.startDate = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
                    rqData.mintime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD") 
                    rqData.endDate =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
                    rqData.maxtime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD")  
                    const dayEnd=new Date(rqData.endDate.toString())
                    const datStart=new Date(rqData.startDate.toString())
                    const timeDiff=dayEnd.getTime()-datStart.getTime()
                    if(timeDiff>30*24*60*60*1000){
                        await ElMessageBox.confirm(
                            '查询天数不能超过30天',
                            '信息提示',
                            {
                            confirmButtonText: '确定',
                            type: 'warning',
                            showCancelButton: false,
                            distinguishCancelAndClose:true
                            }
                        )
                        return
                    }
                }else{
                    rqData.year=year.value
                    if(month.value&&month.value!=''){
                        rqData.month=month.value
                    }
                }
             

                const res = await exportLogin(rqData,false)
            
                const blob = new Blob([res.data]);
            
                saveAs(blob, name)
                
            } finally {
            
            }
        }

        return {
            year,
            month,
            timeType,
            datevalue,
            orderEcharRef,
            orderDetailsDialogRef,
            exportDialogRef,
            search,
            handelChange,
            handelTimeTypeChange,
            handelSearch,
            handelExport,
            exportFile,
        }
    }
})
</script>
<style lang="scss" scoped>
.select-div{
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        // .header-select{
        //     margin-right: 24px;
        // }
    }
    .input-with-select {
      margin-right: 24px;
      width: 320px;
      /deep/.el-input {
        width: 120px;
      }
      ~ * {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
    .header-el-selector {
      width: 120px;
      margin-right: 12px;
       /deep/.el-input {
        width: 120px;
      }
    }
  
    .a-item{
      cursor: pointer;
      color:#409eff;
      padding-left: 4px;
      padding-right: 4px;
      height:32px;
      line-height:32px;
    }
  
    .el-table{
      /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
      }
    }
</style>