<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.articleproductmodel">
        <el-option :key="item.productid" :label="item.name" :value="item.productmodel" v-for="item of deviceList"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="是否测试" class="header-el-selector" v-model="mixin_router_search.fortest">
        <el-option :value="false" label="正式"></el-option>
        <el-option :value="true" label="测试"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="退款状态" class="header-el-selector" v-model="mixin_router_search.refunded">
        <el-option :value="false" label="未退款"></el-option>
        <el-option :value="true" label="已退款"></el-option>
      </el-select>
      <!-- <el-select @change="handelChange" clearable placeholder="运营审核状态" class="header-el-selector" v-model="mixin_router_search.masterAudited">
        <el-option :value="false" label="未审核"></el-option>
        <el-option :value="true" label="已审核"></el-option>
      </el-select> -->
      <!-- <el-select @change="handelChange" clearable placeholder="审核状态" class="header-el-selector" v-model="mixin_router_search.audited">
        <el-option :value="false" label="未审核"></el-option>
        <el-option :value="true" label="已审核"></el-option>
      </el-select> -->
      <el-select @change="handelChange" clearable placeholder="是否完结" class="header-el-selector" v-model="mixin_router_search.closed">
        <el-option :value="false" label="未完结"></el-option>
        <el-option :value="true" label="已完结"></el-option>
      </el-select>
    </header>
    <el-table
      :data="list"
      :default-sort="{prop: 'creationdate', order: 'descending'}"
      @sort-change="mixinReRouterTableSortChange"
      height="80vh"
      style="width: 100%"
      v-if="list"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="auto">
            <el-form-item label="账号">
              <my-click-data-to :data="scope.row.receiveraccount" :params="[{search_account:scope.row.receiveraccount}]" url="/user"></my-click-data-to>
              <my-copy :data="scope.row.receiveraccount" />
            </el-form-item>
            <el-form-item label="订单号">
              <my-click-data-to
                :data="scope.row.sharedorderid"
                :params="[{search_sharedorderid:scope.row.sharedorderid}]"
                url="/order/userorder"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedorderid" />
            </el-form-item>
            <el-form-item label="退款单号">
              {{scope.row.refundid}}
              <my-copy :data="scope.row.refundid" />
            </el-form-item>
            <el-form-item label="发起人">
              <my-click-data-to :data="scope.row.submitteraccount" :params="[{search_account:scope.row.receiveraccount}]" url="/user"></my-click-data-to>
              <my-copy :data="scope.row.submitteraccount" />
            </el-form-item>
            <el-form-item label="审核">
              <my-click-data-to :data="scope.row.auditoraccount" :params="[{search_account:scope.row.receiveraccount}]" url="/user"></my-click-data-to>
              <my-copy :data="scope.row.auditoraccount" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="30" prop="articleequipmentsn"></el-table-column>
      <el-table-column label="型号" min-width="30" prop="articleproductname"></el-table-column>
      <el-table-column label="退款理由" min-width="80" prop="reason"></el-table-column>
      <el-table-column label="退款金额" min-width="30" prop="amount"></el-table-column>
      <el-table-column label="退款类型" min-width="30" >
        <template #default="scope">
         <el-tag :type="scope.row.refundType==0?'info':'warning'" >{{scope.row.refundType==0?'订单退款':'押金退款'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" label="发起时间" min-width="50" prop="creationdate" sortable="custom"></el-table-column>
      <!-- <el-table-column align="center" label="状态" min-width="60">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.audited">已审核</el-tag>
          <el-tag type="info" v-else>未审核</el-tag>
          <span v-if="scope.row.audited">
            <el-tag :type="scope.row.refunded?'success':'danger'" v-if="scope.row.approved">同意{{scope.row.refunded?'-已退款':'-未退款'}}</el-tag>
            <el-tag type="danger" v-else>拒绝</el-tag>
          </span>
        </template>
      </el-table-column> -->
      <el-table-column  label="审核流程" min-width="60">
        <template #default="scope">
          <el-steps :space="70" :active="scope.row.auditedStep" finish-status="success">
            <el-step title="运营"></el-step>
            <el-step title="财务"></el-step>
            <el-step title="完结"></el-step>
          </el-steps>
        </template>
      </el-table-column>
      <el-table-column align="center" label="运营审核状态" min-width="48">
        <template #default="scope">
          <el-tag :type="scope.row.masterApproved?'success':'danger'" v-if="scope.row.masterAudited">已审核{{scope.row.masterApproved?'-同意':'-拒绝'}}</el-tag>
          <el-tag type="info" v-else>未审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="财务审核状态" min-width="48">
        <template #default="scope">
          <el-tag :type="scope.row.approved?'success':'danger'" v-if="scope.row.audited">已审核{{scope.row.approved?'-同意':'-拒绝'}}</el-tag>
          <el-tag type="info" v-else>未审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否退款" min-width="30">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.refunded">已退款</el-tag>
          <el-tag type="danger" v-else>未退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="44" v-if="roleShowPermission(['ROLE_FINANCE','ROLE_ADMIN_DVE','ROLE_FLATFORM','ROLE_POSTSALE'])">
        <template #default="scope">
          <el-button @click="handleRefund(scope.row)" size="mini" type="primary" v-if="(!scope.row.closed&&!scope.row.audited&&scope.row.masterAudited)">财务审核</el-button>
          <el-button @click="handleMasterRefund(scope.row)" size="mini" type="primary" v-if="(!scope.row.closed&&!scope.row.masterAudited)">运营审核</el-button>
          <el-button
            @click="handleReRefund(scope.row)"
            size="mini"
            type="warning"
            v-if="scope.row.audited&&scope.row.approved&&!scope.row.refunded"
          >再次退款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <refund-confrom-dialog @success="mixinReRouter()" ref="refundConfromDialogRef"></refund-confrom-dialog>
    <masterRefundConfromDialog @success="mixinReRouter()" ref="masterRefundConfromDialogRef"></masterRefundConfromDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'

import {
  sharedorderrefundList,
  SharedorderrefundList,
  sharedorderrefundRefund,
} from '@/api/Order/Refund'
import { getShareProductInfo } from '@/api/util'
import refundConfromDialog from './components/refundConfromDialog.vue'
import masterRefundConfromDialog from './components/masterRefundConfromDialog.vue'
import { ElMessage } from 'element-plus'
import { hasCurrentRole } from '@/common/checkRole'
export default defineComponent({
  name: 'renewal',
  components: {
    refundConfromDialog,
    masterRefundConfromDialog,
  },
  setup() {
    const loading = ref(false)
    const reRefundLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SharedorderrefundList[]>([])
    const deviceList = getShareProductInfo()
    const refundConfromDialogRef = ref<any>()
    const masterRefundConfromDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'receiveraccount',
    })
    const mixin_router_search = ref({
      fortest: false,
      
      closed:false,
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        receiveraccount: '',
        articleequipmentsn: '',
        sharedorderid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        receiveraccount: '账号',
        articleequipmentsn: '设备SN',
        sharedorderid: '订单号',
      },
    })
    const roleShowPermission = inject('roleShowPermission')

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderrefundList(rqData)
        list.value = res.records
        total.value = res.total
        // sharedorderrenewalList
        // sharedorderrene
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleRefund = (row: SharedorderrefundList) => {
      if(!row.masterAudited){
          ElMessage.success("需要运营主管先审核")
        return
      }
      refundConfromDialogRef.value.open(row)
    }
    const handleReRefund = async (row: SharedorderrefundList) => {
      try {
        reRefundLoading.value = true
        const res = await sharedorderrefundRefund(row.refundid)
        ElMessage.success(res)
        mixinReRouter()
      } finally {
        reRefundLoading.value = false
      }
    }
    const handleMasterRefund = (row: SharedorderrefundList) =>{
      masterRefundConfromDialogRef.value.open(row)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      changSearch,
      handelChange,
      handleLimitChange,
      onPageChange,
      deviceList,
      refundConfromDialogRef,
      masterRefundConfromDialogRef,
      handleRefund,
      roleShowPermission,
      mixinReRouter,
      handleReRefund,
      mixinReRouterTableSortChange,
      handleMasterRefund,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-left: 24px;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>