<template>
    <el-drawer
        :title="title"
        v-model="isVisible"
        size="40%"
        :direction="direction"
        :before-close="handleClose">
        <div class="header">
            <el-input
                @keyup.enter="addClick"
                class="input-with-select"
                placeholder="请输入小魔夹sn"
                v-model="add">
                <template #prepend>
                    <el-select placeholder="选择添加类型" v-model="addType" class="input-select-tip">
                        <el-option label="小魔夹SN" value="equipmentId"></el-option>
                    </el-select>
                </template>
            </el-input>
           
            <el-button
                @click="handleSearch()"
                icon="el-icon-search"
                type="primary"
            >搜索</el-button>

            <el-button
                @click="addClick()"
                icon="el-icon-plus"
                type="primary"
                v-if="isShowAdd"
            >装入设备</el-button>
        </div>
        <el-table :data="list"  style="margin-left: 16px; margin-right: 16px;" height="80vh" >
           <el-table-column label="序号"   width="60" align='center' >
                <template #default="scope">
                   {{scope.$index+1}}
                </template>
            </el-table-column>    
            <el-table-column label="SN" width="110" align='center'>
                <template #default="scope">
                <div v-if="scope.row.equipmentSn == '空'">{{scope.row.equipmentSn}}</div>
                <div v-else>
                    {{scope.row.equipmentSn}}
                    <hover-show-qr :logo="`icon-${scope.row.prefix=='uh'?'umbrella':'phoneholder'}`" :text="scope.row.equipmentSn"></hover-show-qr>
                </div>
                </template>
            </el-table-column>     
            <el-table-column label="广告类型"   width="120" align='center' >
                <template #default="scope">
                   {{adInfoList[scope.row.adType].name}}
                </template>
            </el-table-column>     
            <el-table-column label="装箱时间" width="180" align='center' >
                 <template #default="scope">
                   {{scope.row.gmtCreate}}
                </template>
            </el-table-column>     
            <el-table-column label="操作" min-width="120"  >
                 <template #default="scope">
                     <el-button
                       @click="handleRemove(scope.$index,scope.row)"
                        size="mini"
                        type="primary"
                    >移除</el-button>
                </template>
            </el-table-column>  
        </el-table>
        <!-- <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        @current-change="onPageChange"
        layout="prev, pager, next"
        v-if="total"
        ></el-pagination> -->
        <add-device-dialog @success="handelChange" ref="addDialogRef"></add-device-dialog>
    </el-drawer>
</template>
<script lang="ts">

import { ref,computed, defineComponent, readonly ,reactive } from "vue";
import { boxDeviceModel, getBoxDeviceOnSnHttp, removeDeviceToBoxHttp} from '@/api/WarehouseManagement/whbox';
import addDeviceDialog from "./addDeviceDialog.vue";
import { getShareProductInfo,getAdInfoList} from '@/api/util'
import { ElMessage, ElMessageBox } from 'element-plus'
let boxSn = "";
let boxId = "";
export default defineComponent({
    components: {
        addDeviceDialog
    },

    setup() {
        console.log('进入弹窗');
        const direction=ref("rtl");
        const isVisible = ref(false);
        const title = ref("包装箱详情");
        const loading = ref(false);
        const page = ref(1);
        const limit = ref(15);
        const list = ref<boxDeviceModel[]>([]);
        const total = ref(0);
        const isShowAdd = ref(true)
        const add = ref<any>('')
        const addType = ref<any>('equipmentId')

        const addDialogRef=ref<any>();
        const sheareProductInfo = getShareProductInfo()  
        const adInfoList = getAdInfoList()
       

        const handleClose=(done:any)=>{
            done();
        };

        
        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            isShowAdd.value=item.status=='0';
            boxSn=item.sn;
            boxId=item.id;
            title.value=boxSn+"包装箱详情"
            reData();
        };

        const handelChange = () => {
            page.value = 1
            reData()
        }
   
        const reData = async () => {
            try {
                loading.value = true;
                const res = await getBoxDeviceOnSnHttp(boxSn);
                console.log('包装箱 :>> ', res);
                total.value = res.total;
                list.value = res.data;
                console.log(list.value)
            } finally {
                loading.value = false;
            }
        };
      
        const onPageChange = (current: any) =>{
            page.value = current.value;
            reData();
        }

        const handleSearch = () =>{

        }

        const addClick = () =>{
            addDialogRef.value.open(boxId);
        }

        const handleRemove = async(idx:number,item:any) =>{
             await ElMessageBox.confirm(
            `是否将小魔夹`+item.equipmentSn+'移出包装箱？！',
            '提示',
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                distinguishCancelAndClose: true,
            }
            )
            const res = await removeDeviceToBoxHttp({adType:item.adType,boxId:boxId,equipmentSn:item.equipmentSn})
            reData()
            ElMessage.success(res)
        }

        return{
            title,
            isVisible,
            isShowAdd,
            addDialogRef,
            adInfoList,
            sheareProductInfo,
            direction,
            addType,
            handleClose,
            onPageChange,    
            handelChange,
            handleRemove,
            handleSearch,
            addClick,        
            open,
            page,
            limit,
            total,
            list,
            add,
        
        };
    },
   
});
</script>
<style scoped lang='scss'>

.el-drawer__body{ 
    overflow: auto; 
}

.header {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
    .input-with-select{
      width: 280px;
      margin-right: 16px;
      margin-left: 24px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
}

</style>