<template>
    <el-drawer
        :title="title"
        v-model="isVisible"
        size="40%"
        :direction="direction"
        :before-close="handleClose">
        <el-table :data="orderDetailsInfo"  
            style="margin-left: 16px; margin-right: 16px;width: 100% "
            height="90vh"
            row-key="id"    
            v-if="orderDetailsInfo" >
            <el-table-column label="处理人" width="120" >
                <template #default="scope">
                    <span v-if="scope.row.handlerUserId=='sys'">系统</span>
                    <span v-else>{{scope.row.handlerUserAccount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="类型" width="110" align='center'>
                <template #default="scope">
                    {{allTypeStatus[scope.row.excuteType]}}
                </template>
            </el-table-column>    
            <el-table-column label="创建时间" width="160" align='center' prop="gmtCreate"></el-table-column> 
            <el-table-column label="描述" width="180" align='center'>
                <template #default="scope">
                {{scope.row.remark}}
                </template>
            </el-table-column>                               
        </el-table>
    </el-drawer>
</template>
<script lang="ts">

import { ref,computed, defineComponent, readonly ,reactive} from "vue";
import {
  sharedorderprivatizationdeductionListNew,
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationnegotiationLoadNew,
  SharedorderprivatizationnegotiationLoadNew
} from '@/api/Order/Privatization'
import { toOptional } from "@/types/utils";
import { userStore } from "@/store/modules/user";
export default defineComponent({


    setup() {
        console.log('进入弹窗');
        const isVisible = ref(false);
        const title = ref("私有化处理详情");
        const direction=ref("rtl");
        const status=ref(0)
        const sn=ref('')  //设备SN
    
        const sharedorderid=ref("")//订单id
   
        const isOrderTest=ref(false)  //是否测试订单
    
        const orderInfo = ref<toOptional<SharedorderprivatizationdeductionListNew>>({});
        const list = ref<SharedorderprivatizationdeductionListNew[]>([])
        const orderDetailsInfo=ref<SharedorderprivatizationnegotiationLoadNew[]>([]);
        const handleClose=(done:any)=>{
            done();
        };

        const allTypeStatus = readonly({
           0: "系统提醒", 
           1: "客服提醒" ,
           2: "机柜确认归还",
           3:"客服确认归还",
           4: "押金扣除",
           5: "设备投放",
           6: "更换设备给投资人",
           7: "押金退款",
           8: "扣款状态变更",
           9:  "替换状态变更",
           10: "投放状态变更",
           11: "更新扣押金时间",
           99: "确认完结"
        })
        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            isOrderTest.value=item.fortest;
            sharedorderid.value=item.sharedOrderId;
            title.value=item.equipmentSN+'私有化处理详情'
            //reData()
            getOrderProgress(item);
        };

        /**
         * 加载列表数据
         */
         const reData = async () => {
            try {
                let rqData: any = { current: '1', size: '10',entity: {sharedOrderId: sharedorderid.value}}
                console.log(rqData);
                const res = await sharedorderprivatizationdeductionListNew(rqData)
                list.value = res.rows
                orderInfo.value=list.value[0];
                status.value=orderInfo.value.status!;
                console.log('订单')
                console.log(res)
                console.log(status.value)
                //getOrderProgress(orderInfo.value);
                // total.value = res.total
            } finally{

            }
        }

        /**
         * 获取私有化进程
         */
        const getOrderProgress = async (item:any) => {
            console.log('私有化处理加载')
            console.log(item)
            const status = item.status;
            const overdueId=item.id.toString(); //1476384977803993090
            //const overdueId="1476384977803993090"; //1476384977803993090
            const userToken=userStore.userInfo.token!;
            console.log('订单id')
          
            if (status > 0) {
                const info = await sharedorderprivatizationnegotiationLoadNew({ token: userToken,overdueId:overdueId})
                console.log('进程加载')
                console.log(info)
                orderDetailsInfo.value=info.data;
            }
        }


        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,
            orderDetailsInfo,
            orderInfo,
            sn,
            allTypeStatus,
            sharedorderid,
        
            status,
        };
    },
    components: {
      
    }
   
});
</script>
<style scoped lang='scss'>

.el-drawer__body{ 
    overflow: auto; 
}

</style>