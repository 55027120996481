
import { defineComponent, ref, readonly, inject } from 'vue'
import privatizationDrewerNew from './components/privatizationdrawerNew.vue'
import routerChangeReData from '@/common/routerChangeReData'
import negotiationInfo from './components/negotiationInfo.vue'
import retrunInfo from './components/retrunInfo.vue'
import confrimRetrunDialogNew from "./components/confrimRetrunDialogNew.vue"
import privateRefundDialog from './components/privateRefundDialog.vue'
import replaceDeviceDialog from './components/replaceDeviceDialog.vue'
import privateDuctionDialog from './components/privateDuctionDialog.vue'
import {
  sharedorderprivatizationdeductionListNew,
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationprocessNegotiationNew,
  sharedorderprivatizationprocessRelaunchStartNew,
  sharedorderprivatizationprocessLaunchCompleteNew,
  sharedorderprivatizationprocessDeductionNew,
  sharedorderprivatizationDeduction,
  onPrivateOrderReplaceDevice,
} from '@/api/Order/Privatization'
import negotiationDialog from './components/negotiationDialogNew.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from "@/store/modules/user";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'orderManage',
  components: {
    negotiationDialog,
    negotiationInfo,
    retrunInfo,
    confrimRetrunDialogNew,
    privatizationDrewerNew,
    privateRefundDialog,
    replaceDeviceDialog,
    privateDuctionDialog,
  },
  setup() {
    const loading = ref(false)
    const reDeductionLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SharedorderprivatizationdeductionListNew[]>([])
    const negotiationDialogRef = ref<any>({})
    const confrimRetrunDialogRef = ref<any>({})
    const privatizationDrewerRef= ref<any>()
    const refundDialogRef = ref<any>()
    const replaceDeviceDilaogRef = ref<any>()
    const privateDuctionDilaogRef = ref<any>()
    const searchSelect = ref({ search: 'equipmentSN' })

    //0:待处理 1：等待归还 2：超期未归还，开始扣押金 3：已归还待投放 4:押金扣款完成 5：投放完成 99：已完结
    const allStatus = readonly({
      0: '待处理',
      1: '等待归还',
      2: '开始扣押金',
      3: '待投放',
      4: '押金已扣',
      5: '投放完成',
      99: '已完结',
    })
    const allStatusColor = readonly({
      0: 'waring',
      1: 'info',
      2: 'danger',
      3: 'waring',
      4: 'info',
      5: 'success',
      99: 'success',
    })
    const deductStatus = readonly({
      0: '未扣',
      1: '开始',
      99: '已扣',
    })
    const mixin_router_search = ref({
      fortest: false,
      order: 'order_start_time',
      sort: 'desc',
      // model: '',
      productModel: '',
      status: '',
      relaunchStatus:'',
      replaceStatus:'',
      userReturnAttitude:'',
      deductStatus:''
    })
    const mixin_select_search = ref({
      search: {
        equipmentSN: '',
        userAccount: '',
        sharedOrderId: '',
        userId: '',
      },
    })

    const mixin_select_search_label = readonly({
      search: {
        equipmentSN: '设备SN',
        userAccount: '用户账号',
        sharedOrderId: '订单号',
        userId: '用户ID',
      },
    })
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderprivatizationdeductionListNew(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const onShowDrawer = (item:any) => {
      privatizationDrewerRef.value.open(item);
    }

    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })

    /**
     * 参数筛选改变
     */
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }

    /**
     * 开启沟通按钮
     */
    const handleNegotiation = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }
    const handleNegotiationResult = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }

    /**
     * 是否展开获取订单详情
     * 加载私有化订单
     */
    const handleOpen = async (e: SharedorderprivatizationdeductionListNew) => {
      list.value[e.idx].open = !list.value[e.idx].open // 开启关闭状态
      if (!list.value[e.idx].open) return
      const status = parseInt(e.status)
      // if (status > 0) {
      //   const _negotiationInfo = await sharedorderprivatizationnegotiationLoad(
      //     e.negotiationid
      //   )
      //   list.value[e.idx].negotiationInfo = _negotiationInfo
      // }
      // if (status > 0) {
      //   const _returnInfo = await sharedorderprivatizationreturnLoad(
      //     e.negotiationid
      //   );
      //   list.value[e.idx].returnInfo = _returnInfo;
      // }
    }

    const handleDeduction = async (item:any) =>{
      await ElMessageBox.confirm(
        '是否强制结算小魔夹'+item.equipmentSN+'？结算之前需要确认小魔夹归还，用户是否充值足够199，担保是芝麻信用，微信不建议使用该功能',
        '结算提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        }
      )
      const res =await sharedorderprivatizationDeduction(item.id)
      ElMessage.success(res)
      mixinReRouter()
    }

    /**
     * 确认归还
     */
    const handleRetrun = async (item:any) => {
     
      confrimRetrunDialogRef.value.open(item)
    }

    /**
     * 开始投放
     */
    const handleRelaunch = async (item: any) => {
      const overdueId=item.id;
      const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc=time+"投放设备"
      const res = await sharedorderprivatizationprocessRelaunchStartNew({
        handleUserId: userStore.userInfo.userid,
        overdueId: item.id,
        remark:desc,
        excuteInfo:'',
        relaunchStatus:'99'
      })
      ElMessage.success(res)
      mixinReRouter()
    }

    /**
     * 完结订单
     */
    const handleRelaunchSuccess = async (item: any) => {
      const overdueId=item.id;
      const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc=time+"完结超期订单"
      const res = await sharedorderprivatizationprocessLaunchCompleteNew({
        handleUserId: userStore.userInfo.userid,
        overdueId: item.id,
        remark:desc,
        excuteInfo:''
      })
      mixinReRouter()
      ElMessage.success(res)
    }

    /**
     * 再次短信通知
     */
    const onMsgClick = async (item: any) =>{
      const overdueId=item.id;
      const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      const desc=time+'再次通知用户'
      const res = await sharedorderprivatizationprocessNegotiationNew({
        remark: desc,
        handleUserId: userStore.userInfo.userid,
        excuteInfo:'',
        remindTime:time,
        overdueId:overdueId
       
      })
       mixinReRouter()
      ElMessage.success(res);
    }

    /**
     * 开始扣款
     */
    const handleReDeduction = async (item: any) => {
      try {
        privateDuctionDilaogRef.value.open(item)
      //   reDeductionLoading.value = true
      //   await ElMessageBox.confirm(
      //     `是否发起押金扣款？确认后,将在5分钟后开启扣除用户押金199元`,
      //     '提示',
      //     {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning',
      //       distinguishCancelAndClose: true,
      //     }
      //   )
  
       
      //   const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      //   const deductionTime=dayjs(new Date().getTime()+5*60*1000).format("YYYY-MM-DD HH:mm:ss");
      //   const desc=time+'发起押金扣款'
      //   const res = await sharedorderprivatizationprocessDeductionNew({
      //       overdueId:item.id,
      //       handleUserId:userStore.userInfo.userid,
      //       remark:desc,
      //       excuteInfo:'',
      //       deductTime:deductionTime
      //     })
      //   mixinReRouter()
      //   ElMessage.success(res)
      } finally {
        reDeductionLoading.value = false
      }
    }

    /**
     * 发起退款
     */
    const onReturnMoney = async(item: any) => {
      refundDialogRef.value.open(item)
    }

    /**
     * 更换小魔夹二维码
     */
    const onReplaceDevice = async(item:any) =>{
      replaceDeviceDilaogRef.value.open(item)
    }

    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      allStatus,
      allStatusColor,
      deductStatus,
      handleNegotiation,
      negotiationDialogRef,
      mixinReRouter,
      handleNegotiationResult,
      handleOpen,
      handleRetrun,
      handleRelaunch,
      handleRelaunchSuccess,
      handleReDeduction,
      handleDeduction,
      confrimRetrunDialogRef,
      privatizationDrewerRef,
      replaceDeviceDilaogRef,
      privateDuctionDilaogRef,
      onShowDrawer,
      onMsgClick,
      onReturnMoney,
      refundDialogRef,
      onReplaceDevice,
      reDeductionLoading
    }
  },
})
