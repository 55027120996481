<template>
  <div class="container">
    <el-row :gutter="26" class="image-fault">
      <el-col :span="12"  >
        <el-carousel  indicator-position="outside" height="260px" >
          <el-carousel-item v-for="item in images" :key="item">
              <!-- <h3 class="medium">{{ item }}</h3> -->
              <img  :src="require('@/assets/'+item.src)" style="height: 100%; width: 100%;" />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="12">
        <div class="fault">
          <el-row :gutter="14">
            <el-col :span="8">
              <div class="fault-item">
                <i class="iconfont icon-repairman-number icon-item" ></i>
                <span class="item-span">维修员总数</span>
                <span class="fault-number-span" style="color: #000;">{{repairmanNum}}人</span>
                <el-button  size="mini"  class="fault-button" @click="toPath(`/user/repairman`)">查看更多</el-button>
              </div>           
            </el-col>       
            <el-col :span="8">             
              <div class="fault-item">
                <i class="iconfont icon-operator-overdue-order icon-item" style="color:#4a8af8;"></i>
                <span class="item-span">待处理超期订单</span>
                <span class="fault-number-span" style="color: #4a8af8;">{{overdueOrderNum}}单</span>
                <el-button  size="mini" class="fault-button" @click="toPath(`/order/privatizationNew`)">查看更多</el-button>
              </div>                        
            </el-col>
            <el-col :span="8">
              <div class="fault-item">
                <i class="iconfont icon-operator-faulting icon-item" style="color:#f8485d;"></i>
                <span class="item-span">待处理用户故障</span>
                <span class="fault-number-span" style="color: #f8485d;">{{faultOrderNum}}个</span>
                <el-button  size="mini" class="fault-button" @click="toPath(`/fault`)">查看更多</el-button>
              </div> 
            </el-col>
          </el-row>
        </div>    
      </el-col>
    </el-row>
    <el-row :gutter="26" class="top">
      <el-col :span="14">
        <div class="top-left">
          <div class="flex-box top-tip">
            <span class="left">机柜总数</span>
            <span class="right">{{ deviceTotal }} 台</span>
          </div>
          <el-row :gutter="14">
            <el-col :span="8">
              <div class="top-item">
                <i class="iconfont icon-zg icon-item" style="color:#2f9afc;"></i>
                <select-item :selectItem="orderSelectItem" @change="cabinetSelectChange" key="mapTypeSelect"
                style="width: 120px;height:48px;color:#000"></select-item>
                <span class="top-item-num" style="margin-top: -4px;">{{ cabinetNum }} 台</span>
              </div>           
            </el-col>       
            <el-col :span="8">             
              <div class="top-item">
                <i class="iconfont icon-umbrella icon-item" style="color:#2f9afc;"></i>
                <span class="item-span">伞柜</span>
                <span class="top-item-num">{{ umbrellaNum }} 台</span>
              </div>                        
            </el-col>
            <el-col :span="8">
              <div class="top-item">
                <i class="iconfont icon-bmg icon-item" style="color:#2f9afc;"></i>
                <span class="item-span">便民柜</span>
                <span class="top-item-num">{{ bmgNum }} 台</span>
              </div> 
            </el-col>
          </el-row>
        </div>
      </el-col>

      <el-col :span="10">
        <div class="top-left">
          <div class="flex-box top-tip">
            <span class="left">故障数量</span>
            <span class="right">{{ faultDeviceTotalNum }} 台</span>
          </div>
          <el-row :gutter="14">
            <el-col :span="12">
              <div class="top-item">
                <i class="iconfont icon-xmj-fault icon-item" style="color:#ff652e;"></i>
                <span class="item-span">小魔柜故障数</span>
                <span class="top-item-num">{{ faultCabinetNum }} 台</span>
              </div> 
            </el-col>
            <el-col :span="12">
              <div class="top-item">
                <i class="iconfont icon-umbrella-fault icon-item" style="color:#ff652e;"></i>
                <span class="item-span">故障伞柜</span>
                <span class="top-item-num">{{ faultUmbrellaNum }} 台</span>
              </div>           
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div class="more">
      <span class="more-tip">更多操作</span>
      <el-row :gutter="14">
        <el-col :span="4">
          <div class="more-item"  @click="toPath('/order/userorder')">
            <i class="iconfont icon-operator-rent-order icon-item" style="color:#ff5c23;"></i>
            <span>租赁订单</span>
            <!-- <div style="position: absolute; bottom: 48px;">
              <span style="color: #01C446;">数量: {{ xmjTotal }}</span>
            </div> -->
          </div>
        </el-col>
         <el-col :span="4" >
          <div class="more-item" @click="toPath('/share/place')">
            <i class="iconfont icon-operator-place icon-item" style="color: #27b53d;"></i>
            <span>场所</span>
            <div style="position: absolute; bottom: 48px;">
              <span style="color: #01C446;">{{ placeTotal }}</span>
            </div>
          </div>
        </el-col>
         <el-col :span="4">
          <div  class="more-item" @click="toPath('/share/panel')">
            <i class="iconfont icon-new_xmj icon-item" style="color: #2e9aff;"></i>
            <span>机柜</span>
          </div>
        </el-col>
         <el-col :span="4" >
          <div class="more-item" @click="toPath('/share/panel')">
            <i class="iconfont icon-umbrella icon-item" style="color: #FF9A23;"></i>
            <span>伞柜</span>
          </div>
        </el-col>
         <el-col :span="4">
          <div  class="more-item" @click="toPath(`/user/repairman`)">
            <i class="iconfont icon-repairman icon-item" ></i>
            <span>维修员</span>
          </div>
        </el-col>
         <el-col :span="4">
          <div  class="more-item" @click="toPath(`/fault`)">
            <i class="iconfont icon-fault icon-item" style="color: #ff9a24;"></i>
            <span>故障处理</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script lang="ts" >
import { defineComponent, computed, ref,onMounted,inject} from "vue";
import selectItem from './components/selectItem.vue'
import { userRoleList } from '@/api/User/role';
import { faultListHttp } from '@/api/fault/index'
import { getCabinetTypeTotal, getCabinetFaultTotal } from '@/api/Share/Device'
import { getPlaceList } from '@/api/Share/Place'
import { getProgressPrivateOrderTotal } from '@/api/Order/Privatization'
import { userStore } from '@/store/modules/user';
import { useRouter } from "vue-router";
import { getShareProductInfo, ProductInfo,getCabinetProductInfo} from '@/api/util'
import { deepCopy } from '@/utils'
import dayjs from 'dayjs'
export default defineComponent({
  name: "operate-home",
  components : {
    selectItem
  },
  data() {
      return {
        images:[
            {
                src:'img/banner1.jpg',
                desc:'测试'
            },
              {
                src:'img/banner2.jpg',
                desc:'测试'
            },
              {
                src:'img/banner3.png',
                desc:'测试'
            }
        ]
      }
  },
  setup() {
    const nowTime=ref('');
    const router = useRouter();
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const repairmanNum=ref('0')
    const overdueOrderNum=ref('0')
    const faultOrderNum = ref('0')
    const deviceTotal = ref(0)
    const cabinetNum = ref('0')
    const cabinetOneNum = ref('0')
    const cabinetTwoNum = ref('0')
    const umbrellaNum = ref('0')
    const bmgNum = ref ('0')
    const faultDeviceTotalNum = ref(0)
    const faultCabinetNum = ref('0')
    const faultUmbrellaNum = ref('0')
    const placeTotal = ref('0')
    const orderSelectItem = ref<ProductInfo>(deepCopy(getCabinetProductInfo()[0]))
    const toPath = (path: string) => {
      router.push(path);
    };

    const cabinetSelectChange = (e: any) => {
       orderSelectItem.value = e
       //getCabinetNum(orderSelectItem.value.productmodel)
       if(orderSelectItem.value.productmodel=='MGS4G2'){
          cabinetNum.value=cabinetOneNum.value;
       }else if(orderSelectItem.value.productmodel=='MGS4G3'){
          cabinetNum.value=cabinetTwoNum.value;
       }
    }
    /**
    * 获取维修员数量
    */
    const getRepairmanNum = async () => {
      try {
        let rqData: any = { page: '1', limit: '1' }
        rqData.operationId=userStore.getCurrentRole.operationId
        const res = await userRoleList(rqData, "repairman")
        repairmanNum.value = res.totalCount.toString();
      } finally {
        
      }
    }
    const getFaultOrderNum = async () => {
      try {
        
        let rqData: any = { current: "1", size: "1" }
        rqData.order='gmtCreate';
        rqData.sort='desc';
        rqData.entity={status: "0"};
        const res = await faultListHttp(rqData)
        faultOrderNum.value = res.total.toString()
      } finally {
        
      }
    }

    const getProgressPrivateOrderNum = async () => {
      try{
        let rqData: any = { fortest: false}
        const res = await getProgressPrivateOrderTotal(rqData);
        overdueOrderNum.value = res!.toString();
      } finally {

      }
    }

    const getCabinetTotal = async() => {
      try{
        deviceTotal.value = 0;
        let rqData: any = { enabled: true}
        rqData.operationId=userStore.getCurrentRole.operationId
        
        const res = await getCabinetTypeTotal(rqData)
        res.data.records.forEach(any =>{
          if(any.productmodel !=null && any.productmodel=='CV01'){
            bmgNum.value=any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='MGS4G2'){
            cabinetOneNum.value = any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }else if (any.productmodel !=null && any.productmodel=='MGS4G3') {
            cabinetTwoNum.value=any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='UMHD'){
            umbrellaNum.value=any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }
        })
      } finally {

      }
    }

    const getDeviceFaultTotal = async() => {
      try{
        faultDeviceTotalNum.value = 0;
        faultCabinetNum.value = '0'
        let rqData: any = { enabled: true}
        rqData.operationId=userStore.getCurrentRole.operationId
        
        const res = await getCabinetFaultTotal(rqData)
        res.data.records.forEach(any =>{
          if(any.productmodel !=null && any.productmodel=='CV01'){
           
            faultDeviceTotalNum.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='MGS4G2'){
            faultCabinetNum.value = (parseInt(faultCabinetNum.value)+parseInt(any.total)).toString();
            faultDeviceTotalNum.value +=parseInt(any.total);
          }else if (any.productmodel !=null && any.productmodel=='MGS4G3') {
            faultCabinetNum.value = (parseInt(faultCabinetNum.value)+parseInt(any.total)).toString();
            faultDeviceTotalNum.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='UMHD'){
            faultUmbrellaNum.value=any.total.toString();
            faultDeviceTotalNum.value +=parseInt(any.total);
          }
        })
      } finally {

      }
    }

     const getPlaceNum = async () => {
      try {
        
        let rqData: any = { current: 1, size: 10 }       
        rqData.operationId=userStore.getCurrentRole.operationId       
        const res = await getPlaceList(rqData)
        placeTotal.value = res.total.toString();
      } finally {
        
      }
    }
    onMounted(()=>{
      nowTime.value=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      //获取总收益
      getRepairmanNum();
      getFaultOrderNum();
      getProgressPrivateOrderNum();
      getCabinetTotal();
      getDeviceFaultTotal();
      getPlaceNum();
    })
    return {
      onMounted,
      toPath_RouterSearchMixin,
      toPath,
      nowTime,
      repairmanNum,
      overdueOrderNum,
      faultOrderNum,
      deviceTotal,
      cabinetNum,
      umbrellaNum,
      bmgNum,
      faultDeviceTotalNum,
      faultCabinetNum,
      faultUmbrellaNum,
      placeTotal,
      orderSelectItem,
      cabinetSelectChange,
    }
  },
 
});
</script>
<style lang="scss" scoped>
html,
body {
   height: 100%;
   background: #eaedf1;
}
.container {
  padding: 18px;
  background: #eaedf1;

 .item-span {
   font-size: 16px;
   color: #666666;
   margin-top: 11px;
 } 
.image-fault{
  margin-bottom: 32px;
  .fault{
    width: 100%;
    background-color: white;
    border-radius: 5px;

    .fault-item{
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .fault-number-span {
        font-size: 17px;
        margin-top: 8px;
      }

      img{
        width: 48px;
        height: 48px;
      }
      span{
        font-size: 16px;  
      }

      .fault-button{
        margin-top: 12px;
      }
    }
  }
}

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    // line-height: 280px;
    margin: 0;
  }
  
//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }
  
//   .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
//   }

  .top {
    margin-bottom: 32px;
    
    .top-left{
      border-radius: 5px;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
    }

    .top-tip{
      height: 60px;
      line-height: 60px;
      padding-left: 24px;
      padding-right: 24px;
      justify-content: space-between;
      .left{
        color: #000;
        font-size: 19px;
      }
      .right{
        color: #656565;
        font-size: 18px;
      }
    }

     .top-item{
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img{
        width: 48px;
        height: 48px;
      }

      .top-item-num{
        color: #000;
        font-size: 18px;
        margin-top: 8px;
      }
     
    }
  }

  .more {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    .more-tip {
      color: #1a1b1c;
      font-size: 19px;
      height: 90px;
      margin-left: 20px;
      line-height: 90px;
    }
    .more-item{
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px dashed #e7e7eb;
      cursor: pointer;

      img{
        width: 48px;
        height: 48px;
      }
      span{
        margin-top: 16px;
        font-size: 16px;
        color: #1a1b1c;
      }
    }

    .more-item:hover{
      background: #F2F2F2;
    }
  }

  .flex-box {
    display: flex;
    align-items: center;
  }

  .icon-item{
    font-size: 52px;
  }
}
</style>