<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select
            @change="changSearch('search')"
            placeholder="请选择"
            v-model="searchSelect.search"
          >
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>

      <el-button @click="handleAdd" icon="el-icon-puls" type="primary">添加投资人</el-button>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column label="姓名" min-width="44" prop="name"></el-table-column>
      <el-table-column label="账号" min-width="44" prop="account"></el-table-column>
      <el-table-column label="id" min-width="44" prop="userid"></el-table-column>
      <el-table-column label="创建时间" min-width="44" prop="create_time"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.$index,scope.row.investoruserid)"
            size="mini"
            type="danger"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-add-dialog @success="dialogSuccess" ref="addDialogRef"></v-add-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import { investmentorderList, UserListRes, removeUser } from '@/api/ERP/user'
import addDialog from './components/addDialog.vue'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'investor',
  components: {
    'v-add-dialog': addDialog,
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const btnSumitLoading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const total = ref(0)
    const list = ref<UserListRes[]>([])
    const addDialogRef = ref<any>()
    const mixin_select_search = ref({
      search: {
        userid: '', // 用户编号
        name: '', // 姓名,
        account: '', // 账号
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        userid: '用户编号', // 投资人用户编号
        name: '姓名', // 投资人用户编号
        account: '账号', //投资订单编码
      },
    })
    const searchSelect = ref({})

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = {
          page: page.value,
          limit: limit.value,
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, data: rq }
        }
        const res = await investmentorderList(rqData)
        total.value = res.total
        list.value = res.list
      } finally {
        loading.value = false
      }
    }

    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
    } = routerChangeReData({
      mixin_select_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open()
    }
    const dialogSuccess = (data: any) => {
      list.value.push(data)
    }
    const handleRemove = async (index: number, id: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removeUser({
          id,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      addDialogRef,
      handleAdd,
      btnSumitLoading,
      dialogSuccess,
      handleRemove,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    .el-select {
      width: 150px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>