
import { defineComponent, ref, readonly } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
// import { getDeviceList, DeviceListRes } from "@/api/Share/Device";
import { getAnalyDeviceList, analyDeviceModel,exportQRSn } from "@/api/Share/Device";
import addDialog from "./components/addDialog.vue";
import componentsDialog from './components/componentsDialog.vue'
import deviceDetailsDialog from "@/components/device/deviceDetailsDialog.vue";
import addNewSnDialog from "./components/addNewSnDialog.vue";
import exportQRSnDialog from "./components/exportQRSnDialog.vue"
import { ElMessage } from "element-plus";
import { getAdInfoList,getProductInfoList,getProductIdList} from '@/api/util'
import { useRouter } from "vue-router";
import { forEach } from "lodash";
import dayjs from 'dayjs'
export default defineComponent({
  name: "Name",
  components: {
    "v-add-dialog": addDialog,
    'v-components-dialog': componentsDialog,
    deviceDetailsDialog,
    addNewSnDialog,
    exportQRSnDialog,
  },
  setup() {
    const loading = ref(false);
    const btnRemoveLoaidng = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const router = useRouter();
    // const list = ref<DeviceListRes[]>([]);
    const list =ref<analyDeviceModel[]>([]);
    const addDialogRef = ref<any>();
    const componentsDialogRef = ref<any>();
    const deviceDetailsDialogRef = ref<any>();  
    const addNewSnDialogRef = ref<any>();
    const exportQRSnDialogRef = ref<any>();
    const adInfoList = getAdInfoList()
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const toPath = (path: string) => {
      router.push(path);
    };
    const searchSelect = ref({
      search: "sn",
    });
    const mixin_select_search = ref({
      search: {
        sn: "", //共享面板的设备序号，如：小魔柜SN；雨伞架SN
      },
    });

    const mixin_select_search_label = readonly({
      search: {
        sn: "设备SN",
      },
    });

    const mixin_router_search = ref({ productid:"",order: "creationdate",sort: "desc" });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          //rqData = { ...rqData, entity: rq };
          rqData = { ...rqData, ...rq }
        }
        const res = await getAnalyDeviceList(rqData);
        console.log('返回数据=',res)
        
        total.value = res.data.total;
        list.value = res.data.records;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleAdd = () => {
      //addDialogRef.value.open(-1, {});
      addNewSnDialogRef.value.open()
    };
    const handleExportQR = async() =>{
      exportQRSnDialogRef.value.open()
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row);
    };
    const addSuccess = (data: { index: number; data: analyDeviceModel }) => {
      if (typeof data.index == "number" && data.index > -1) {
        list.value[data.index] = data.data;
      } else {
        list.value.push(data.data);
      }
    };

    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }

    const handleShowDetails = (sn:string,item:any) => {
      deviceDetailsDialogRef.value.open(sn,item)
    }
    const exportSn = async(data: { productid:string, type:string, time:string, name:string}) =>{
        let rqData: any = {fileName:'QR',productid: data.productid,type: data.type,}
        
        if (data.time) {
          rqData.startDate = dayjs(data.time[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endDate =  dayjs(data.time[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        console.log('请求参数=',rqData)
       
        const res = await exportQRSn(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, data.name)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      adInfoList,
      productList,
      productIdList,
      toPath,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      addSuccess,
      handleExportQR,
      exportSn,
      addDialogRef,
      btnRemoveLoaidng,
      componentsDialogRef,
      deviceDetailsDialogRef,
      addNewSnDialogRef,
      exportQRSnDialogRef,
      handleShowComponenet,
      handleShowDetails,
    };
  },
});
