
import Layout from '@/layout/index.vue'
export default [
    {
        path: '/',
        name: 'default',
        hidden: true,
        component: Layout,  
    },
    {
        name: 'userlogin',
        path: '/userlogin',
        component: () => import('@/views/login/index.vue'),
        hidden: true
    }
]