
import { defineComponent, ref, readonly, inject } from "vue";
import { getRentalfee } from "@/api/util"
import dayjs from "dayjs";
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props,{ emit }) {
    const visible = ref(false)
    const tipMsg = ref<string>("非会员用户，3小时内3元，超过3小时6元一天")
    const time = ref<any>('');
    const isVip=ref(false);
    const isXmj=ref(true);
    const inputData = ref({
      startTime:'',
      endTime:'',
      dueConst: 0,
      durRefund: 0,
    })
    const handleClose = () => {
      visible.value = false
    }
    const open = (_startTime:string,_isVip:boolean,_isXmj:boolean,phone:string,_dueConst?:string,isPrvate?:boolean) => {
      visible.value = true
      inputData.value.startTime=_startTime;
      isVip.value=_isVip;
      isXmj.value=_isXmj;
      tipMsg.value="用户"+phone+"是"+(isVip.value?"会员用户，":"普通用户，")+(isVip.value?"计费是：小魔夹3元一天；雨伞3元一天 ":"计费是：小魔夹3小时内3元，超过3小时6元一天；雨伞3元一天")
      const selectTime=dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if(isPrvate){
        inputData.value.dueConst=parseInt(_dueConst!);
      }else{
        inputData.value.dueConst=parseInt(_dueConst!);
        //inputData.value.dueConst=getRentalfee(inputData.value.startTime,selectTime,isVip.value,isXmj.value); 
      }
      
    }
  
    const submit = async () => {
      handleClose()
      emit('success',{data:inputData.value.durRefund})
    }

    const handlerTimeChange = async(e:any)=>{
      const selectTime=dayjs(e).format("YYYY-MM-DD HH:mm:ss");
      const due=getRentalfee(inputData.value.startTime,selectTime,isVip.value,isXmj.value);
      inputData.value.durRefund=due;
    }

    return{
      visible,
      handleClose,
      open,
      submit,
      inputData,
      tipMsg,
      handlerTimeChange,
      time,
    }
  }
})    
