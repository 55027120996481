<template>
  <el-form inline label-position="left" label-width="auto">
    <el-form-item label="沟通结果">
      <el-tag type="success" v-if="info.willreturn">愿意归还</el-tag>
      <el-tag type="danger" v-else>不愿归还</el-tag>
    </el-form-item>
    <el-form-item label="描述">
      <el-input type="textarea" disabled :rows="2" placeholder="请输入内容" v-model="syncInfo.description"> </el-input>
    </el-form-item>
    <template v-if="info.willreturn">
      <el-form-item label="是否归还">
        <el-tag type="success" v-if="info.returned">{{info.returndate}} 归还</el-tag>
        <el-tag type="danger" v-else>{{info.returnoverduedate}} 超时</el-tag>
      </el-form-item>
      <el-form-item label="归还用时" v-if="info.rentdays">
        {{info.rentdays}}
      </el-form-item>
      <el-form-item label="接收状态" v-if="info.returned">
        <el-tag type="success" v-if="info.returned">{{info.returndate}} 确认接收</el-tag>
        <el-tag type="warning" v-else>未确认接收</el-tag>
      </el-form-item>
    </template>
    <el-form-item label="订单扣款" v-if="info.paymentamount!==null">
      ¥ {{info.paymentamount}}
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, toRef } from "vue";
import { SharedorderprivatizationdeductionList } from "@/api/Order/Privatization";
export default defineComponent({
  name: "negotiation-info",
  props: ["info"],
  setup(props) {
    const info: SharedorderprivatizationdeductionList = toRef(props, "info");
    return {
      syncInfo: info,
    };
  },
});
</script>
<style lang="scss" scoped>
.el-form{
    width: 500px;
}
</style>