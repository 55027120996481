
import { getCabinetProductInfo } from "@/api/util";
import { deepCopy } from "@/utils";
import { defineComponent, ref, readonly, toRef, computed } from "vue";
export default defineComponent({
  // props: ["isBig", "type"],
  props: {
    isBig: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    selectItems: {
      type: Array,
    },
    selectItem: {
      type: String,
      default: () => getCabinetProductInfo()[0],
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const change = (e: any) => {
      emit("change", e);
    };
    // const items = ref(getShareProductInfo());
    const items = getCabinetProductInfo();
    const selectItems = toRef(props, "selectItems");
    const selectItem = toRef(props, "selectItem");
    const mySelectItem = ref(selectItem.value);
    const getItems = computed(() => {
      return selectItems.value ? selectItems.value : items;
    });
    return {
      change,
      mySelectItem,
      getItems,
    };
  },
});
