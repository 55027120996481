<template>
  <div class="container">
    <!-- <div class="info">
      <el-avatar
        :loading="avaterIsLoaded"
        :src="userInfo.avatarurl"
        shape="square"
        class="home-avatar-el"
        size="small"
        v-if="userInfo.avatarurl"
      ></el-avatar>
      <span>{{ userInfo.nickname }}</span>
    </div> -->
    <el-row :gutter="26" class="top">
      <el-col :span="7">
        <div class="top-item flex-box">
          <div>
            <div class="left flex-box">
              <!-- <color-icon type="icon-my-profit"/> -->
              <i class="iconfont icon-my-profit " style="font-size: 40px;color:#FF8400"></i>
              <span style="margin-left: 16px;">我的收益</span>
            </div>

            <div class="profit">
              <div class="profit-item">
                <span class="profit-tip">总收益(元) :</span>
                <span class="profit-money" >{{ totalProfit }}</span>
              </div>
              <div class="profit-item" style="margin-top: 24px;">
                <span class="profit-tip">投资收益(元) :</span>  
                <span class="profit-money" >{{ investorProfit }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="17">
        <div class="top-item">
          <el-row :gutter="2">
            <el-col :span="8" class="flex-box right-profit">
              <span class="right-profit-tip">今日收益(元)</span>
              <span class="right-profit-money">{{ todayProfit }}</span>
            </el-col>
            <el-col :span="8" class="flex-box right-profit">
              <span class="right-profit-tip">昨日收益(元)</span>
              <span class="right-profit-money">{{ yesterdayProfit }}</span>
            </el-col>
            <el-col :span="8" class="flex-box right-profit">
              <span class="right-profit-tip">本月收益(元)</span>
              <span class="right-profit-money">{{ weekProfit }}</span>
            </el-col>
          </el-row>
          <span class="right-bottom-text">数据统计时间: {{ nowTime }}</span>
        </div>
      </el-col>
    </el-row>
    <div class="more">
      <span class="more-tip">更多操作</span>
      <el-row :gutter="14">
        <el-col :span="4">
          <div class="more-item"  @click="toPath('/investment/device/investor')">
            <i class="iconfont icon-xmj1 icon-item" style="color:#438CCB;"></i>
            <span>投资魔夹</span>
            <div style="position: absolute; bottom: 48px;">
              <span style="color: #01C446;">数量: {{ xmjTotal }}</span>
            </div>
          </div>
        </el-col>
         <el-col :span="4" >
          <div class="more-item" @click="toPath('/investment/order/investor')">
            <i class="iconfont icon-order icon-item" style="color: #FF5C23;"></i>
            <span>投资订单</span>
          </div>
        </el-col>
         <el-col :span="4">
          <div  class="more-item" @click="toPath('/profit/order/investor')">
            <i class="iconfont icon-bills icon-item" style="color: #27B53D;"></i>
            <span>收益账单</span>
          </div>
        </el-col>
         <el-col :span="4" >
          <div class="more-item" @click="toPath('/profit/device/investor')">
            <i class="iconfont icon-profit icon-item" style="color: #FF9A23;"></i>
            <span>设备收益</span>
          </div>
        </el-col>
         <el-col :span="4">
          <div  class="more-item" @click="toPath(`/user/userInfo?userid=${userInfo.userid}`)">
            <i class="iconfont icon-wallet icon-item" style="color: #FF9A23;"></i>
            <span>我的钱包</span>
          </div>
        </el-col>
         <el-col :span="4">
          <div  class="more-item" @click="toPath(`/relation/roleRelation?userid=${userInfo.userid}`)">
            <i class="iconfont icon-relation icon-item" style="color: #2E99FF;"></i>
            <span>关联关系</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bottom">

    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref,onMounted,inject} from "vue";
import { userStore } from '@/store/modules/user'
import { getUserInfo, changeRole } from '@/api/User/index'
import { getShareDeviceList } from "@/api/Share/Device";
import { totalProfitModel, getInvsetorTotalProfit, getProfitOnDay } from '@/api/Index/investor'
import dayjs from 'dayjs'
import { useRouter } from "vue-router";
export default defineComponent({
  name: "investor-home",
  setup() {
    const avaterIsLoaded = ref(false)
    const userInfo = computed(() => userStore.userInfo)
    const totalProfit = ref("0");
    const investorProfit = ref(0); 
    const nowTime=ref('');
    const todayProfit = ref('0');
    const yesterdayProfit = ref('0');
    const weekProfit = ref('0');
    const xmjTotal = ref('0');
    const router = useRouter();
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const toPath = (path: string) => {
      router.push(path);
    };

    const avaterLoadingError = async (e: unknown) => {
      console.log('avaterLoadingError', e)
      if (avaterIsLoaded.value) return
      try {
        const { avatarurl } = await getUserInfo()
        userStore.upUserInfo({ avatarurl })
        avaterIsLoaded.value = true
      } catch (error) {
        console.log({ error })
        avaterIsLoaded.value = false
      }
    }

    const getTotalProfit = async() =>{
      let rqData: any = { productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4',fortest:'0',userid:userInfo.value.userid}
       const res = await getInvsetorTotalProfit(rqData)
       console.log('投资人请求数据')
       console.log(res)
       totalProfit.value = res.data.sum
       
      res.data.resList.forEach(function(item,index,array){
        if(item.gainerrole=='investor'){
            investorProfit.value=item.amount
            return
          }
      })
    }

    const getProfitOnDate = async(date:string,type:string,dateType:string) =>{
      let rqData: any = { 
        productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4',
        fortest:'0',
        investoruserid:userInfo.value.userid,
        date:date,
        role:'investor',
        type:type,

      }
       const res = await getProfitOnDay(rqData)      
       if(dateType=='1'){
         todayProfit.value=res.data.sum
         getProfitOnDate(dayjs(new Date().getTime()-24*60*60*1000).format("YYYY-MM-DD"),'1','2')
       }else if(dateType=='2'){
         yesterdayProfit.value=res.data.sum
         getProfitOnDate(dayjs(new Date().getTime()-24*60*60*1000).format("YYYY-MM"),'2','3')
       }else if(dateType=='3'){
         weekProfit.value=res.data.sum
       }
    }

     const getXmjTotal = async () => {
      try {
       
        let rqData: any = { 
            current: '1', 
            size: "1" ,
            entity: {
              fortest: false,
              articleproductid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
              investoruserid: userInfo.value.userid
            } 
          };
       
        const res = await getShareDeviceList(rqData);
        xmjTotal.value = res.total.toString();

      } finally {
       
      }
    };

    onMounted(()=>{
      nowTime.value=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      //获取总收益
      getTotalProfit()
      //按天查询收益
      getProfitOnDate(dayjs(new Date().getTime()).format("YYYY-MM-DD"),'1',"1")

      //查询小魔夹总数
      getXmjTotal();
    })
    return {
      avaterIsLoaded,
      userInfo,
      avaterLoadingError,
      onMounted,
      toPath_RouterSearchMixin,
      toPath,
      totalProfit,
      investorProfit,
      nowTime,
      todayProfit,
      yesterdayProfit,
      weekProfit,
      xmjTotal,
    }
  },
 
});
</script>
<style lang="scss" scoped>
html,
body,
#app,
#layout {
   height: 100%;
   background: #eaedf1;
}
.container {
  padding: 18px;
  height: 100%;
  background: #eaedf1;

  .info {
    width: 100%;
    height: 80px;
    padding-left: 24px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    .home-avatar-el {
      margin-right: 16px;
      width: 60px;
      height: 60px;
    }
  }

  .top {
    height: 270px;
    margin-bottom: 22px;
    .top-item {
      height: 100%;
      background: #fff;
      border-radius: 5px;
      padding-left: 36px;

      .left {
        img {
          width: 36px;
          height: 36px;
        }
      }
      .profit {
        margin-top: 40px;
        .profit-tip {
          display: inline-block;
          width: 120px;
          color: black;
          font-size: 18px;
        }
        .profit-money {
          color: #fe0000;
          font-size: 20px;
          font-weight: 400;
        }
      }
      .right-profit {
        width: 100%;
        height: 210px;
        flex-direction: column;
        justify-content: center;
        .right-profit-tip {
          color: #1a1b1c;
          font-size: 16px;
        }
        .right-profit-money {
          margin-top: 18px;
          color: #ff9446;
          font-size: 32px;
        }
      }

      .right-bottom-text {
        color: #b1b2b3;
        width: 100%;
        text-align: center;
        font-size: 14px;
        display: inline-block;
      }
    }
  }

  .more {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    .more-tip {
      color: #1a1b1c;
      font-size: 19px;
      height: 90px;
      margin-left: 20px;
      line-height: 90px;
    }
    .more-item{
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px dashed #e7e7eb;
      cursor: pointer;

      img{
        width: 48px;
        height: 48px;
      }
      span{
        margin-top: 16px;
        font-size: 16px;
        color: #1a1b1c;
      }
    }

    .more-item:hover{
      background: #F2F2F2;
    }
  }

  .flex-box {
    display: flex;
    align-items: center;
  }

  .icon-item{
    font-size: 52px;
  }
}
</style>