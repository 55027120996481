import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
export interface RoleList {
    appcur: null | string
    areacode: null | string
    concur: null | string
    consoleids: null | string
    name: string
    partneruserid: string
    roleid: string
    rolename: string
    treeArray: null | string
    treeList: null | string
}
export async function roleList(params: ListReq): Promise<ListRes<RoleList>> {
    params.token = true
    const res = await defHttp.request<ListRes<RoleList>>(
        {
            url: `/role/list`,
            params
        }
    );
    if (res.totalCount == 0) throw Error('获取通知消息失败')
    return res
}
export async function roleRemove(params: RequestReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/role/remove`,
            params
        }
    );
    if (!res) throw Error('删除失败')
    return res.message || "删除成功"
}
export async function roleSet(params: RequestReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/role/set`,
            params
        }
    );
    if (!res) throw Error('更新失败')
    return res.message || "更新成功"
}
export async function roleAdd(params: RequestReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/role/add`,
            params
        }
    );
    if (!res) throw Error('添加失败')
    return res.message || "添加成功"
}