<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`添加投资人投资人订单至系统`"
    @closed="handleClose"
    align="center"
    v-if="visible"
    v-model="visible"
    width="750px"
  >
    <header>
      <el-autocomplete
        :fetch-suggestions="querySearchAsync"
        @select="onSelect"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="searchData"
      >
        <!-- v-el-loadmore="loadmore" -->
        <template #prepend>
          <el-select placeholder="请选择" v-model="sharchType">
            <el-option label="姓名" value="investorname"></el-option>
            <el-option label="账号" value="investoraccount"></el-option>
            <el-option label="用户ID" value="investoruserid"></el-option>
          </el-select>
        </template>

        <template #append>
          <el-button @click="reData(true)" icon="el-icon-search"></el-button>
        </template>
      </el-autocomplete>
      <el-select @change="reData(true)" placeholder="订单类型" v-model="orderType">
        <el-option label="老系统" value="old"></el-option>
        <el-option label="新系统" value="new"></el-option>
      </el-select>
    </header>
    <el-table :data="orderList" height="400px" style="width: 80%">
      <el-table-column align="创建时间" label="创建时间" min-width="30">
        <template #default="scope">
          <span v-if="orderType=='new'">{{getStatusName[orderType][scope.row.status]}}</span>
          <span v-else>{{scope.row.creationtime}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="数量" min-width="30">
        <template #default="scope">
          <span v-if="orderType=='new'">{{scope.row.articlenum}}</span>
          <span v-else>{{scope.row.assignphoneholdernum}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="30">
        <template #default="scope">{{getStatusName[orderType][scope.row.status]}}</template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="30">
        <template #default="scope">
          <el-button
            :disabled="parseInt(scope.row.status)<2"
            @click="handleAdd(scope.row)"
            size="mini"
            type="primary"
          >添加</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="orderPage"
      :page-size="orderLimit"
      :total="orderTotal"
      @current-change="onPageChange"
      layout="total, prev, pager, next, jumper"
    ></el-pagination>
  </el-dialog>
  <!-- <div v-el-loadmore="loadmore"></div> -->
</template>
<script lang="ts">
import { defineComponent, ref, nextTick, readonly } from "vue";
import { investorList, InvestorListListRes } from "@/api/User/investor";
import { getInvestorOrder, addInvestorOrder } from "@/api/ERP/InvestorOrder";
import { add } from "@/api/ERP/user";
import { ElMessage } from "element-plus";
import { userStore } from "@/store/modules/user";
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const visible = ref(false);
    const loading = ref(false);
    const tableLoading = ref(false);
    const btnSumitLoading = ref(false);
    const orderType = ref<"new" | "old">("new");
    const nomore = ref(false);
    const sharchType = ref("investorname");
    const searchData = ref("");
    const orderList = ref<any>([]);
    const orderLimit = ref(10);
    const orderPage = ref(1);
    const limit = ref(10);
    const orderTotal = ref(0);
    const page = ref(1);
    const searchList = ref<InvestorListListRes[]>([]);
    const getStatusName = readonly({
      new: {
        "0": "已下单",
        "1": "已付款",
        "2": "已受理",
        "3": "已交付",
      },
      old: {
        "0": "已下单",
        "1": "已付款",
        "2": "已出仓",
        "3": "已开始投放",
        "4": "已寄出",
      },
    });
    let investoruserid = "";
    let mCb: any;
    const handleClose = () => {
      visible.value = false;
      mCb = null;
    };

    const reData = async (re?: boolean) => {
      try {
        tableLoading.value = true;
        if (re) page.value = 1;
        const rqData: any = {};
        if (orderType.value == "new") {
          rqData.size = limit.value;
          rqData.current = page.value;
          rqData.entity = {
            investoruserid,
          };
        } else {
          rqData.limit = limit.value;
          rqData.page = page.value;
          rqData.investoruserid = investoruserid;
        }
        const res = await getInvestorOrder(orderType.value, rqData);
        orderList.value = res.result;
        orderTotal.value = res.total;
      } finally {
        tableLoading.value = false;
      }
    };

    const onSelect = (e: any) => {
      investoruserid = e.investoruserid;
      reData(true);
    };

    const loadData = async (re = false, queryString?: any) => {
      //   if (!sharchType.value) return ElMessage.error("请选择搜索类型");
      //   if (!searchData.value) return ElMessage.error("请输入搜索内容");
      if (re) {
        page.value = 1;
        searchList.value = [];
      }
      try {
        loading.value = true;
        const rqData: any = {
          limit: limit.value,
          page: page.value,
        };
        rqData[sharchType.value] = searchData.value;
        const { total, result } = await investorList(rqData);
        const _result = result.map((v) => {
          v.value = v.investorname;
          return v;
        });
        searchList.value.push(..._result);
        if (page.value * limit.value >= total) nomore.value = true;
        await nextTick();
        if (!result.length) return;
        mCb && mCb(searchList.value);
      } finally {
        loading.value = false;
      }
    };
    const handleSearch = async () => {
      await loadData(true);
    };

    const loadmore = async () => {
      if (nomore.value) return;
      page.value++;
      await loadData();
    };

    const querySearchAsync = async (queryString: any, cb: any) => {
      if (!mCb) mCb = cb;
      await loadData(true, queryString);
    };
    const open = async () => {
      visible.value = true;
      await loadData(true);
    };

    const handleAdd = async (row: any) => {
      try {
        btnSumitLoading.value = true;
        let addData: any = {
          token: userStore.info.token,
          type: orderType.value,
          userid: row.investoruserid,
        };
        if (orderType.value == "new") {
          addData = {
            ...addData,
            orderid: row.orderid,
            // count: row.articlenum,
          };
        } else {
          addData = {
            ...addData,
            orderid: row.investorderid,
            // count: row.phoneholdernum,
          };
        }
        const res = await addInvestorOrder(addData);
        emit("success", res.data);
        ElMessage.success(res.message);
        handleClose();
        console.log("res :>> ", res);
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const onPageChange = (current: any) => {
      page.value = current.value;
      reData();
    };

    return {
      visible,
      open,
      handleClose,
      sharchType,
      searchData,
      querySearchAsync,
      handleSearch,
      loadmore,
      onSelect,
      handleAdd,
      orderType,
      orderList,
      orderLimit,
      orderPage,
      onPageChange,
      orderTotal,
      btnSumitLoading,
      reData,
      getStatusName,
    };
  },
});
</script>
<style lang="scss" scoped>
.input-with-select {
  width: 320px;
  .el-select {
    width: 150px;
  }
  ~ * {
    margin-left: 24px;
    .form {
      margin: 24px auto;
      width: 80%;
    }
  }
}
</style>