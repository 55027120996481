
import { defineComponent, ref, readonly, inject } from 'vue'
import { whRecordModel, whRecordListHttp, outRepairXMJHttp,exportWhrecordInfo} from '@/api/WarehouseManagement/index'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import boxDetailsDrawer from './components/boxDetailsDrawer.vue'
import inDialog from './components/inDialog.vue'
import changeAdDialog from './components/changeAdDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo,getAdInfoList,getProductInfoList,getProductIdList} from '@/api/util'
import myExport from '@/utils/exprotExcel'
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'in-out',
  components: {
   inDialog,
   boxDetailsDrawer,
   changeAdDialog,
   exportDialog,
  },
  setup() {
    const addDialogRef = ref<any>()
    const changeAdDialogRef = ref<any>()
    const boxDetailsDrewerRef = ref<any>()
    const exportDialogRef = ref<any>();
    const sheareProductInfo = getShareProductInfo()  
    const adInfoList = getAdInfoList()
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const mixin_router_search = ref({
      whOpType: '', //状态 0:生产 1：维修
      adType:'', //广告类型
      isOut:false,//是否出库
      careateBN:'',
      order: "gmt_create",
      sort: "desc"
    })

    const mixin_select_search = ref({
      search: {
        boxSn:'', //包装箱编号
        equipmentSn:'',//设备SN
        inUserAccount:'',//入库账号
        outUserAccount:'',//出库账号         
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        boxSn: '包装箱SN', 
        equipmentSn:'设备SN',
        inUserAccount:'入库账号',
        outUserAccount: '出库账号',      
      },
    })

   

    const searchSelect = ref({
      search: 'equipmentSn',
    })

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<whRecordModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.careateBN) {
            rq.startGmtIn = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtIn = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await whRecordListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleAdd = () => {
      addDialogRef.value.open()
    }
    
    const handleRemove = async(idx:number,boxSn:string) =>{

    }

    const handleChangeAD = async(row:any) =>{
      changeAdDialogRef.value.open(row)
    }

     const handleTakeBoxDetail = (item:any) =>{
      boxDetailsDrewerRef.value.open(item)
    }

    const handleOutRerairXMJ = async(item:any,sn:string) =>{
        //reDeductionLoading.value = true
        await ElMessageBox.confirm(
          `是否出库`+sn+',当前设备为维修设备',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await outRepairXMJHttp(sn)
        mixinReRouter()
        ElMessage.success(res)
    }
   
    const handleExport = async() => {
      exportDialogRef.value.open('维修单记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'whrecord',order: 'gmtCreate',sort: 'desc'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          searchData.startGmtIn = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          searchData.endGmtIn =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportWhrecordInfo(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      changSearch,
      searchSelect,
      list,
      loading,
      addDialogRef,
      changeAdDialogRef,
      boxDetailsDrewerRef,
      exportDialogRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      adInfoList,
      productList,
      productIdList,
      handleAdd,
      handleRemove,
      handleChangeAD,
      handleOutRerairXMJ,
      handleTakeBoxDetail,
      handleExport,    
      exportFile 
    }
  },
})
