
import { defineComponent, ref, computed,readonly} from "vue";
import {
  billtypeNames,
  userconsumewalletUnionbill,
  getRechargeOrProfitTypeList,
  getDeductionTypeList,
} from '@/api/Financial/wallet'
export default defineComponent({
  name: "v-dialog",
  components: {
  },
  setup() {
    const name = ref("");
    const visible = ref(false);
    const isTest = ref(false);
    const activeName = ref("");
    const userId = ref("");
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
    const rechargeOrProfitTypeList=getRechargeOrProfitTypeList();
    const deductionTypeList=getDeductionTypeList();
    const open = (_userid:string,_name:string) => {
      userId.value=_userid;
      name.value=_name;
      visible.value=true;
      reData();
    }
    const reData = async () => {
      try {
        let rqData: any = { current: page.value, size: limit.value, order:"date",sort:"desc",
            entity:{fortest:isTest.value,billtype:activeName.value,userid:userId.value} 
        }
        const res = await userconsumewalletUnionbill(rqData)
        console.log('res.records :>> ', res.records)
        list.value = res.records
        total.value = res.total
      } finally {
       
      }
    }
    const handleClose = () => {
      visible.value = false;
    }
    const handelChange = () => {
      page.value=1;
      reData();
    }
    const handleClick = () => {
      if(activeName.value==="0"){
        activeName.value=""
      }
      page.value=1;
      reData();
    }
    const onPageChange = (_page:number) =>{
        page.value=_page
        reData();
    }
    const handleLimitChange = (_size:number) =>{
        limit.value=_size
        reData();
    }
    return {
      visible,
      name,
      isTest,
      activeName,
      limit,
      page,
      total,
      list,
      open,
      handleClose,
      handelChange,
      handleClick,
      onPageChange,
      handleLimitChange,
      deductionTypeList,
      rechargeOrProfitTypeList,
    }
  }
})
