import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface InvestmentorderListRes {
    status: '0' | '1' | "2" | "3",
    creationdate: string
    articlenum: number
    articleproductid: string
    articleproductmodel: string
    articleproductname: string
    articleproducttype: string
    financeuserid: null | string
    fortest: boolean
    freegift: null | boolean
    investmode: string
    investortype: string
    investoruserid: string
    launchmode: string
    mailingaddress: null | string
    mailingcontact: null | string
    mailingnumber: null | string
    modificationdate: string
    orderid: string
    salesmanuserid: null | string
    singleamount: number
    account:string
    investorname:string
    check:boolean

}
export async function investmentorderList(params: ListReq): Promise<ListRes<InvestmentorderListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<InvestmentorderListRes>>(
        {
            url: `/investmentorder/listvo`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}

/**
 * 
 * @param params 导出投资订单excel表
 * @returns 
 */
export async function exportInvestmentorder(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportInvestmentorderData`,
            responseType:'blob',
            params
            
        },{
            prefix: "/analy",
            contentType: 'json',
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}


export async function investmentorderOfflineinvest<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/offlineinvest`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData
}
export async function investmentCabinetOrder<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/operationinvest`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData
}
export async function investmentorderUpdate<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/update`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function investmentorderRemove<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/remove`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData.message
}
export async function investmentorderConfirmpayment<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/confirmpayment`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData.message
}
interface InvestmentorderCreateshipmentReq extends RequestReq {
    orderid: string
    shipperuserid: string
    quantity: number
}
export async function investmentorderCreateshipment(params: InvestmentorderCreateshipmentReq) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/createshipment`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData.message
}



export async function confirmdelivery(params: any) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/confirmdelivery`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData.message
}

export async function confirmexpress(params: any) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentorder/confirmexpress`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared"
    }
    );
    return reData.msg
}