<!-- 小魔夹详情 -->
<template>
  <!-- <div class="container" v-loading="loading"> -->
  <div class="container">
    <el-card v-if="userInfo">
      <div class="user-info">
        <div class="user">
          <div class="avatar" @mouseenter="isHover=true" @mouseleave="isHover=false" @click="changeAvatar">
            <!-- <el-image
              class="img"
              style="width: 100px; height: 100px"
              :src="userInfo.avatarurl"
              fit="fit"
            ></el-image>-->
            <el-avatar v-if="userInfo.avatarurl" :size="88">
              <span v-if="isHover" class="el-icon-edit">修改</span>
              <img v-else :src="userInfo.avatarurl" alt />
            </el-avatar>
            <!-- <el-avatar :size="88" icon="el-icon-eidt "></el-avatar> -->
            <span v-else class="el-dropdown-link">
              <div class="avatar-font" v-if="isHover" style="backgroundColor:#efefef;color:#3ea243;font-size:16px;">上传头像</div>
              <div class="avatar-font" v-else :style="{backgroundColor: '#efefef',color:'#3ea243'}">{{decodeURIComponent(userInfo.nickname).substring(0,1)}}</div>
            </span>
          </div>

          <div class="name">
            <div class="nickname">
              昵&ensp;&ensp;称 :
              <span>{{decodeURIComponent(userInfo.nickname)||"未命名"}}</span>
            </div>
            <div class="username">
              用户名 :
              <span>{{userInfo.account}}</span>
            </div>
          </div>
        </div>
        <div class="item-box" v-if="walletInfo">
          <div class="item">
            <div class="label">收益 :</div>
            <div class="value">{{walletInfo.profit}}</div>
          </div>
          <div class="item">
            <div class="label">积分 :</div>
            <div class="value">{{walletInfo.points}}</div>
          </div>
        </div>

        <div class="time">
          <div class="label">最后登录时间 ：</div>
          <div class="value">{{userInfo.lastlogindate}}</div>
        </div>
      </div>
      <div class="box wallet flex_c">
        <div class="item flex_c" v-for="item of walletInfo" :key="item.name">
          <div class="label">{{item.fortest?"测试钱包":"正式钱包"}} :</div>
          <el-button type="text" class="value" @click="toPath_RouterSearchMixin('/financial/wallet',[{search_userid:userid},{articleproductmodel:item.articleproductmodel}])">￥ {{item.amount}}</el-button>
          <el-button type="text" class="value" @click="toPath_RouterSearchMixin('/financial/frozen',[{search_userid:userid},{articleproductmodel:item.articleproductmodel}])">冻结：￥ {{item.frozenamount}}</el-button>
        </div>
        <div class="item flex_c" v-for="item of walletInfoTest" :key="'test'+item.name">
          <div class="label">{{item.fortest?"测试钱包":"正式钱包"}} :</div>
          <el-button type="text" class="value" @click="toPath_RouterSearchMixin('/financial/wallet',[{search_userid:userid},{wallettype:item.wallettype}])">￥ {{item.amount}}</el-button>
          <el-button type="text" class="value" @click="toPath_RouterSearchMixin('/financial/frozen',[{search_userid:userid},{articleproductmodel:item.articleproductmodel}])">冻结：￥ {{item.frozenamount}}</el-button>
        </div>
      </div>
      <!-- <div class="box item-box">
        <div class="item flex_c">
          <div class="label">押金 :</div>
          <div class="value">{{walletInfo.deposit||0}}</div>
        </div>
      </div> -->

      <!-- <div class="roles">
        <div class="label">身份权限 :</div>
        <div>
          <el-tag
            :type="it.nowRole?'success':it.hasThisRole?'':'info'"
            class="role"
            size="mini"
            @click="handleRole(it)"
            v-for="(it,index) in roles"
            :key="index"
          >{{it.rolename}}</el-tag>
        </div>
      </div> -->
      <div class="income-expenditure">
        <!-- <v-income-expenditure :userId="user"></v-income-expenditure> -->
      </div>
      <v-avatar-dialog ref="incomeExpenditure"></v-avatar-dialog>
    </el-card>
  </div>
</template>

<script lang="ts">
import { AllWallets, userconsumewalletAll, getUserInfo } from "@/api/User";
import AvatarDialog from "./components/avatarDialog.vue";
import dayjs from "dayjs";
import { defineComponent, inject, onMounted, ref, toRef } from "vue";
import { toOptional } from "@/types/utils";
import { UserInfo } from "@/types/user";
export default defineComponent({
  props: ["userid"],
  components: {
    "v-avatar-dialog": AvatarDialog,
  },
  setup(props) {
    const loading = ref(false);
    const applyLoading = ref(false);
    const isHover = ref(false);
    const userInfo = ref<toOptional<UserInfo>>({});
    const walletInfo = ref<toOptional<AllWallets>>({});
    const walletInfoTest = ref<toOptional<AllWallets>>({});
    const userid = toRef(props, "userid");
    const reData = async () => {
      try {
        loading.value = true;
        console.log("userid :>> ", userid);
        userInfo.value = await getUserInfo(userid.value);
        const res = await userconsumewalletAll(userid.value);
        console.log("res :>> ", res);
        walletInfo.value = res.userAllWallets;
        walletInfoTest.value = res.userAllWalletsTest;
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      reData();
    });
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      loading,
      applyLoading,
      isHover,
      userInfo,
      walletInfo,
      walletInfoTest,
      toPath_RouterSearchMixin,
    };
  },
});
</script>
<style lang='scss' scoped>
.container {
  .income-expenditure {
    margin-top: 16px;
  }
  .user-info {
    display: flex;
    position: relative;
    .user {
      display: flex;
      .avatar {
        margin-right: 16px;
        cursor: pointer;
        .el-avatar {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      .avatar-font {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        box-shadow: #888888 1px 1px 2px;
        margin-right: 16px;
        display: flex;
        font-size: 40px;
        // font-weight: bold;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
      }
      .img {
        box-shadow: #888888 1px 1px 2px;
      }
      .name {
        font-weight: bold;
        color: #888888;
        padding-left: 16px;
        div {
          line-height: 44px;
        }
      }
    }
    .item-box {
      padding-left: 72px;
      font-weight: bold;
      color: #888888;
      .item {
        display: flex;
        align-items: center;
        line-height: 44px;
        .value {
          padding-left: 16px;
          font-weight: normal;
          .el-tag {
            margin-right: 10px;
          }
        }
      }
    }
    .time {
      position: absolute;
      font-size: 12px;
      right: 0px;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      color: #ccc;
    }
  }
  .roles {
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #888888;
    display: flex;
    .el-tag {
      margin-left: 16px;
      cursor: pointer;
    }
  }
  .wallet {
    border-top: 1px solid #efefef;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  .box {
    line-height: 44px;
    color: #888888;
    .item {
      padding-right: 30px;
      .value {
        padding-left: 12px;
        font-weight: bold;
      }
    }
  }
}
</style>