import { readonly } from "vue"
export const getStatusName = readonly({
    "0": "未出仓",
    "1": "已出仓",
    "2": "平台投放已开启",
    "3": "平台投放已结束",
    "4": "快递已寄出",
    "5": "快递已签收",
})
export const getArticletypeName = {
    "phoneholder": "小魔夹",
    "umbrella": "雨伞",
}


// export default function () {

// }