
import { defineComponent, ref, readonly, inject } from 'vue'
import { operateModel, operateListHttp, removeOperateHttp} from '@/api/Operate/index'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import addDialog from './components/addDialog.vue'
import { useRouter } from "vue-router";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'operate',
  components: {
    addDialog
  },
  setup() {
    const router = useRouter();
   
    const addDialogRef = ref<any>()
    const toPath = (path: string) => {
      router.push(path);
    };
    const mixin_router_search = ref({
      order: "gmt_create",
      sort: "desc"
    })

    const mixin_select_search = ref({
      search: {
        name:'', //包装箱编号
  
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        name: '名称',  
      },
    })

   

    const searchSelect = ref({
      search: 'name',
    })

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<operateModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await operateListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleAdd = () => {
      addDialogRef.value.open(true,null)
    }
 
    const handleUpdate = (isAdd:boolean,item:any) => {
      addDialogRef.value.open(isAdd,item)
    }

    const handleRemove = async(item:any) =>{
        await ElMessageBox.confirm(
          `是否删除运营团队“`+item.name+'”',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await removeOperateHttp(item.id)
        mixinReRouter()
        ElMessage.success(res)
    }

    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      changSearch,
      searchSelect,
      list,
      loading,
      addDialogRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      toPath,
      handleAdd,
      handleUpdate,
      handleRemove,
    }
  },
})
