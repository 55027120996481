<template>
    <el-drawer
        :title="title"
        v-model="isVisible"
        :direction="direction"
        :before-close="handleClose">
        <el-card class="box-card" v-if="orderInfo">
            <div class="item-line" style=" padding-bottom: 14px;">
                <div class="item">
                    <div class="item-text item-left-one">账号:</div>
                    <div class="item-text">{{orderInfo.userAccount}}</div>
                    <my-copy :data="orderInfo.userAccount" />
                </div>
                <div class="item">
                    <div class="item-text item-left-one" style="width: 25px;">SN:</div>
                    <div class="item-text">{{orderInfo.equipmentSN}}</div>
                     <my-copy :data="orderInfo.equipmentSN" />
                </div>
                 
            </div>
             <div class="item-line" style=" padding-bottom: 14px;">
                <div class="item" style="width: 100%;">
                    <div class="item-text item-left">订单号</div>
                    <div class="item-text">{{sharedorderid}}</div>
                    <my-copy :data="sharedorderid" />
                </div>
            </div>
            <div class="item-line" style=" padding-bottom: 14px;">
                 <div class="item" style="width: 100%;">
                    <div class="item-text item-left">租借时间</div>
                    <div class="item-text">{{orderInfo.gmtCreate}}</div>
                </div>
            </div>
             <div class="item-line">
                 <div class="item">
                    <div class="item-text item-left">扣费时长</div>
                    <div class="item-text">{{orderInfo.constDays||0}}天</div>
                </div>
                 <div class="item" style="display: flex; align-items: center;">
                    <div class="item-text">操作</div>
                    <el-button  type="primary" style="margin-left: 16px;" size='mini' 
                        @click="handleNegotiation(sharedorderid)" v-if="orderInfo.status==0">开始沟通</el-button>
                    <el-button  type="primary" style="margin-left: 16px;" size='mini'
                        @click="handleMsg()" v-if="orderInfo.status==101">短信通知</el-button>
                    <el-button  type="primary" style="margin-left: 16px;" size='mini'
                        @click="handlerReturn()" v-if="orderInfo.status==1">确认接收</el-button>
                    <el-button  type="primary" style="margin-left: 16px;" size='mini'
                        @click="handlerDeduction()" v-if="orderInfo.status==2">发起扣款</el-button>
                    <el-button  type="primary" style="margin-left: 16px;" size='mini'
                        @click="handlerDeduction()" v-if="orderInfo.status==3">开启投放</el-button>
                    <el-button  type="primary" style="margin-left: 16px;" size='mini'  
                        @click="handlerFinish()" v-if="orderInfo.status==5">完结订单</el-button>
                </div>
            </div>
        </el-card>
        <template v-if="orderInfo.status!==0">
            <!-- <privateOrderNoReturnVue :info="orderInfo.negotiationInfo" :statu="status" v-if="!orderInfo.negotiationInfo?.willreturn"></privateOrderNoReturnVue>
            <privateOrderReturnVue :info="orderInfo.negotiationInfo" :statu="status" v-if="orderInfo.negotiationInfo?.willreturn"></privateOrderReturnVue> -->
            <privateOrderOtherReturnVue  v-if="orderInfo.userReturnAttitude==0"></privateOrderOtherReturnVue>
            <privateOrderReturnVue  :info="orderInfo"  v-if="orderInfo.userReturnAttitude==1"></privateOrderReturnVue>
            <privateOrderNoReturnVue :info="orderInfo" v-if="orderInfo.userReturnAttitude==2"></privateOrderNoReturnVue>   
                    
        </template>
        <template v-if="orderInfo.status==0">
            <privateOrderOtherReturnVue ></privateOrderOtherReturnVue>   
        </template>
       <negotiationDialogNew  ref="negotiationDialogRef"></negotiationDialogNew>
    </el-drawer>
</template>
<script lang="ts">
import privateOrderNoReturnVue from "./privateOrderNoReturn.vue"; //私有化不归还流程
import privateOrderReturnVue from "./privateOrderReturn.vue";      //私有化归还流程
import privateOrderOtherReturnVue from "./privateOrderOtherReturn.vue"; //私有化沟通愿意归还后期没有归还
import negotiationDialogNew from "./negotiationDialogNew.vue";
import { ref,computed, defineComponent, onMounted ,reactive} from "vue";
import {
  sharedorderprivatizationdeductionListNew,
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationnegotiationLoadNew,
  SharedorderprivatizationnegotiationLoadNew,
  sharedorderprivatizationreturnConfirm,
  sharedorderprivatizationreturnLoad,
  sharedorderprivatizationprocessRelaunchStart,
  sharedorderprivatizationprocessLaunchComplete,
  sharedorderprivatizationprocessDeduction,
} from '@/api/Order/Privatization'
import { toOptional } from "@/types/utils";
import { userStore } from "@/store/modules/user";
export default defineComponent({


    setup() {
        console.log('进入弹窗');
        const isVisible = ref(false);
        const title = ref("私有化处理");
        const direction=ref("rtl");
        const account=ref('')//租赁账号
        const sn=ref('')  //设备SN
        const orderTime=ref("")//订单时长
        const sharedorderid=ref("")//订单id
        const status=ref(0) //订单状态
        const isOrderTest=ref(false)  //是否测试订单
        const negotiationDialogRef = ref<any>({})
        const orderInfo = ref<toOptional<SharedorderprivatizationdeductionListNew>>({});
        const list = ref<SharedorderprivatizationdeductionListNew[]>([])
        const orderDetailsInfo=ref<SharedorderprivatizationnegotiationLoadNew[]>([]);
        const handleClose=(done:any)=>{
            done();
        };

        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            isOrderTest.value=item.fortest;
            sharedorderid.value=item.sharedOrderId;
           
            reData()
        };

        /**
         * 开启沟通弹窗
         */
        const handleNegotiation= (sharedorderid:string) =>{
            negotiationDialogRef.value.open(sharedorderid)
           
        }

        /**
         * 发送短信
         */
        const handleMsg = () =>{

        }

        /**
         * 确认归还
         */
        const handlerReturn = () =>{

        }

        /**
         * 发起扣款
         */
        const handlerDeduction = () =>{

        }

        /**
         * 完结订单
         */
        const handlerFinish = () =>{

        }

        /**
         * 加载列表数据
         */
         const reData = async () => {
            try {
                let rqData: any = { current: '1', size: '10',entity: {sharedOrderId: sharedorderid.value}}
                console.log(rqData);
                const res = await sharedorderprivatizationdeductionListNew(rqData)
                list.value = res.rows
                orderInfo.value=list.value[0];
                status.value=orderInfo.value.status!;
                console.log('订单')
                console.log(res)
                console.log(status.value)
                //getOrderProgress(orderInfo.value);
                // total.value = res.total
            } finally{

            }
        }

        /**
         * 获取私有化进程
         */
        const getOrderProgress = async (item:any) => {
            console.log('私有化处理加载')
            console.log(item)
            const status = item.status;
           // const overdueId=item.id.toString(); //1476384977803993090
            const overdueId="1476384977803993090"; //1476384977803993090
            const userToken=userStore.userInfo.token!;
            console.log('订单id')
          
            if (status > 0) {
                const info = await sharedorderprivatizationnegotiationLoadNew({ token: userToken,overdueId:overdueId})
                console.log('进程加载')
                console.log(info)
                orderDetailsInfo.value=info.rows;
            }
        }


        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,
            orderInfo,
            sn,
            orderTime,
            sharedorderid,
            account,
            handleNegotiation,
            handleMsg,
            handlerReturn,
            handlerDeduction,
            handlerFinish,
            negotiationDialogRef,
            status,
        };
    },
    components: {
        privateOrderNoReturnVue,
        privateOrderReturnVue,
        privateOrderOtherReturnVue,
        negotiationDialogNew,
    }
   
});
</script>
<style scoped lang='scss'>


.box-card {
    width: 94%;
    margin-left: 16px;
  }

  .item-line{
      display: flex;
     
      .item{
          width: 50%;
          display: flex;
            
        .item-text{
            font-size: 13px;
            font-weight: bold;
        }

        .item-left{
            width: 60px;
        }

        .item-left-one{
            width: 35px;
        }
      }

  }

</style>