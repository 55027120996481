<!-- 角色权限管理 -->
<template>
  <!-- <div class="container" v-loading="loading"> -->
  <div class="container">
    <el-dialog @closed="close" title="角色菜单分配" v-model="visible" width="700px" min-height="700px">
      <header>
        <el-select @change="changeRole" v-model="selectRole" value-key="rolename">
          <el-option :key="index" :label="item.rolename" :value="item" v-for="(item,index) in roles"></el-option>
        </el-select>
      </header>
      <div class="tree-box">
        <el-tree :data="allMenus" :draggable="true" :expand-on-click-node="false" :props="defaultProps" default-expand-all node-key="treeid" ref="treeRef" show-checkbox>
          <!-- v-loading="loading" -->
          <template #default="{ node }">
            <span>{{ node.data.name }}</span>
            <span>{{ node.data.code }}</span>
          </template>
        </el-tree>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, toRef } from "vue";
import type { toOptional } from "@/types/utils";
import {
  updateRoleMenuList,
  treeLoadTree,
  roletreeList,
  roleList,
} from "@/api/System";
import type { UserRole } from "@/types/user";
import { netRotuer } from "@/router/types";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "Name",
  props: ["appid"],
  data() {
    return {
      defaultProps: {
        children: "child",
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const selectRole = ref<toOptional<UserRole>>({});
    const treeRef = ref<any>();
    const roles = ref<UserRole[]>([]);
    const roleMenuList = ref<netRotuer[]>([]);
    const allMenus = ref([]);
    const initAllMenus = ref([]);
    const appid = toRef(props, "appid");

    const getAllMenu = async () => {
      const data = await treeLoadTree({ appid: appid.value });
      if (data.length <= 0) return;
      allMenus.value = JSON.parse(JSON.stringify(data));
      // this.allMenus = this.editData(data);
      // console.log(this.allMenus);
      initAllMenus.value = JSON.parse(JSON.stringify(data));
      console.log("initAllMenus :>> ", initAllMenus);
    };
    const getAllRoles = async () => {
      const data = await roleList();
      if (data.length <= 0) return;
      roles.value = data;
    };
    const getRoleMenuList = async () => {
      if (!selectRole.value.roleid)
        return console.log("selectRole.value.roleid 不存在");
      let data = await roletreeList({
        roleid: selectRole.value.roleid,
        appid: appid.value,
      });
      if (data.length <= 0) return;
      data = data.filter((v) => {
        return v.parenttreeid != "0";
      });
      roleMenuList.value = data;
      console.log("treeRef.value :>> ", data);
      await nextTick();
      treeRef.value.setCheckedNodes(data);
    };
    const reData = async () => {
      try {
        loading.value = true;
        //获取所有菜单
        await getAllMenu();
        //请求角色列表
        await getAllRoles();
        //设置普通用户为默认
        selectRole.value = roles.value[4];
        // 获取指定全新的菜单列表
        await getRoleMenuList();
      } finally {
        loading.value = false;
      }
    };
    const open = async () => {
      visible.value = true;
      reData();
    };
    const close = () => {
      visible.value = false;
    };

    const editData = (data: any) => {
      return data.filter((v: any) => {
        if (v.child.length > 0) {
          editData(v.child);
        }
        return v.handleChecked === true;
      });
    };
    const submit = async () => {
      const rqList = [];
      btnSumitLoading.value = true;
      try {
        rqList.push(...treeRef.value.getHalfCheckedNodes());
        rqList.push(...treeRef.value.getCheckedNodes());
        const _rqList: any = [];
        rqList.map((v) => {
          if (_rqList.findIndex((i: any) => i == v.treeid) == -1) {
            // 去重
            _rqList.push(v.treeid);
          }
        });
        console.log('appid.value :>> ', appid.value);
        const result = await updateRoleMenuList({
          roleid: selectRole.value.roleid,
          name: selectRole.value.name,
          rolename: selectRole.value.rolename,
          treeArray: _rqList.join(","),
          consoleids: appid.value,
        });
        console.log("result :>> 3", result);
        ElMessage.success(result);
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeRole = async () => {
      // roleMenuList.value = {};
      allMenus.value = JSON.parse(JSON.stringify(initAllMenus.value));
      try {
        loading.value = true;
        await getRoleMenuList();
      } finally {
        loading.value = false;
      }
    };
    return {
      open,
      editData,
      visible,
      close,
      treeRef,
      submit,
      loading,
      btnSumitLoading,
      allMenus,
      changeRole,
      selectRole,
      roles,
    };
  },
});
</script>
<style lang='scss' scoped>
.container {
  padding: 16px;
  header {
    .el-button {
      margin-right: 32px;
    }
  }
  .el-dialog {
    .custom-tree-node {
      // flex: 1;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }
    .tree-box {
      margin-top: 32px;
      width: 100%;
      height: 500px;
      overflow-y: scroll;
    }
  }
}
</style>