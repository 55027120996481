
import {ref,onMounted,onUnmounted,watch,defineComponent,provide} from "vue";  //导入ref,组件挂载完成后执行的函数,组件卸载完成后执行的函数,监听器,内容提供者
import ResizeHandler from "@/common/ResizeHandler"; //界面大小调整函数
import { appStore } from "./store/modules/app";   
import { useRoute } from "vue-router";  //导入路由
import checkRole from "@/common/checkRole"; //路由权限检查
import { toPath_RouterSearchMixin } from "@/common/toPath"; //设置跳转方式和默认开启的页面（登录）
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
  name: "App",
  components: { ElConfigProvider},
  setup() {
    const route = useRoute();  //创建路由
    const isRouterAlive = ref(true);  //是否激活路由true
    const { device, sidebar, resizeHandler } = ResizeHandler(); //浏览的是PC还是移动端，滑动模块
    const { roleShowPermission, roleHiddenPermission } = checkRole();
    provide("roleShowPermission", roleShowPermission);
    provide("roleHiddenPermission", roleHiddenPermission);
    provide("toPath_RouterSearchMixin", toPath_RouterSearchMixin);
    /**
     * 组件挂在完成，消失转圈圈
     */
    onMounted(() => {
      const loading = document.getElementById("Loading");
      if (loading) {
        document.body.removeChild(loading);
      }
      resizeHandler();
      window.addEventListener("resize", resizeHandler);
    });
    /**
     * 组件卸载完成
     */
    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });
    watch(
      () => route.path,
      () => {
        if (device.value === "mobile" && sidebar.value.opened) {
          appStore.closeSideBar({
            withoutAnimation: false,
          });
        }
      }
    );
    return {
      isRouterAlive,
      locale: zhCn,
    };
  },
});
