import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface PlaceListRes {
    creationdate: string
    signboardUrls: string
    enabled:boolean
    placeImage:string
    isEnable:boolean
    isHasPic: boolean
}
export async function getPlaceList(params: ListReq): Promise<ListRes<PlaceListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<PlaceListRes>>(
        {
            url: `/place/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.isHasPic = v.signboardUrls!=null
        if(v.isHasPic){
            v.placeImage=v.signboardUrls[0]
        }
        return v
    })
    return res
}

export async function selectPlaceList(params: ListReq): Promise<ListRes<PlaceListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<PlaceListRes>>(
        {
            url: `/place/placeList`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.isHasPic = v.signboardUrls!=null
        // v.isEnable=v.enabled=='1'
        if(v.isHasPic){
            v.placeImage=v.signboardUrls[0]
        }
        return v
    })
    return res
}

export async function exportPlaceDeviceList(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportPlace`,
            responseType:'blob',
            params
        }, {
            prefix: "/analy",
            contentType:'json',
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
}

export async function getPlaceDetails(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/place/load`,
            params
        }, {
        prefix: "/shared"
    }
    );
    return res
}
export async function getPlaceDevices(params: ListReq): Promise<ListRes<any>> {
    params.token = true
    const res = await defHttp.request<ListRes<any>>(
        {
            url: `/sharedpanel/infolist`,
            params
        }, {
        prefix: "/shared"
    }
    );
    return res
}
export async function upDatePlace(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<PlaceListRes>>(
        {
            url: `/place/update`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function removePlace(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<PlaceListRes>>(
        {
            url: `/place/remove`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function addPlace(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<PlaceListRes>>(
        {
            url: `/place/add`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}


/**
 * 获取设备列表
 */
 export async function upPlaceImage(parmas: RequestReq,type:string): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/place/uploadimg`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}