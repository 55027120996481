<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-select" clearable placeholder="出入库状态" v-model="mixin_router_search.status">
        <el-option label="待入库" value="0"></el-option>
        <el-option label="已入库" value="1"></el-option>
        <el-option label="已出库" value="2"></el-option>
      </el-select>     

      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="创建时间"
        range-separator="至"
        start-placeholder="创建时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
     <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">新增包装箱</el-button>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="入库用户id">
              <span>{{ scope.row.inUserId }}</span>
             <my-copy :data="scope.row.inUserId" />
            </el-form-item>
            
            <el-form-item label="出货单id">
              <span>{{ scope.row.shipmentId }}</span>
              <my-copy :data="scope.row.shipmentId"></my-copy>
            </el-form-item>
            <el-form-item label="创建时间">
              {{scope.row.gmtCreate}}
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="序号" min-width="20">
        <template #default="scope">        
          {{scope.row.id}}
        </template>
      </el-table-column>
      <el-table-column label="编号" align="center" min-width="56">
        <template #default="scope">
          {{scope.row.sn}}<my-copy :data="scope.row.sn"></my-copy>
          <hover-show-qr  :text="scope.row.sn" :key="scope.row.sn"></hover-show-qr>
        </template>
      </el-table-column>
      <el-table-column label="入库账号" align="center" min-width="44">
        <template #default="scope">{{scope.row.inUserAccount}}</template>
      </el-table-column>
      <el-table-column label="已装箱" align="center" min-width="26">
        <template #default="scope">{{scope.row.curCount}}</template>
      </el-table-column>
      <el-table-column label="最大数" align="center" min-width="26">
        <template #default="scope">{{scope.row.maxCount}}</template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="24">
        <template #default="scope">
          <el-tag  type="waring" v-if="scope.row.status==0">待入库</el-tag>    
          <el-tag  type="info" v-if="scope.row.status==1">已入库</el-tag>    
          <el-tag  type="success"   v-if="scope.row.status==2">已出库</el-tag>
        </template>
      </el-table-column>
      
      <el-table-column label="入库时间" align="center" min-width="50">
        <template #default="scope">{{scope.row.gmtIn}}</template>
      </el-table-column>
      <el-table-column label="出库时间" align="center" min-width="50">
        <template #default="scope">{{scope.row.gmtOut}}</template>
      </el-table-column>
     
      <el-table-column align="left" label="操作" min-width="90">
        <template #default="scope">
         <!-- <el-button
            @click="handleRemove(scope.$index,scope.row.sn)"
            size="mini"
            type="danger"
            v-if="scope.row.status!='2'"
          >删除</el-button> -->
          <el-button
            @click="handleTakeBoxIn(scope.row,scope.row.sn)"
            size="mini"
            type="primary"
            style="margin-left: 12px;"
            v-if="scope.row.status=='0'"
           >入库</el-button>
          <el-button
            @click="handleTakeBoxDetail(scope.row)"
            size="mini"
            type="primary"
            style="margin-left: 12px;"
           >装箱详情</el-button>
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-box-dialog @success="mixinReRouter" ref="addDialogRef"></add-box-dialog>
    <boxDetailsDrawer ref="boxDetailsDrewerRef"></boxDetailsDrawer>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { WhboxModel, whboxList ,takeBoxInHttp} from '@/api/WarehouseManagement/whbox'
import routerChangeReData from '@/common/routerChangeReData'
import addBoxDialog from './components/addBoxDialog.vue'
import boxDetailsDrawer from './components/boxDetailsDrawer.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'investment-order',
  components: {
   addBoxDialog,
   boxDetailsDrawer,
  },
  setup() {
    const addDialogRef = ref<any>()
    const boxDetailsDrewerRef = ref<any>()

    const mixin_router_search = ref({
      status: '', //状态 0:待入库 1：已入库 2：已出库
      careateBN:'',
      order: 'gmt_create',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        sn:'', //投资人手机号
        in_user_account:'',//投资人姓名       
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        sn: '编号', // 投资人用户编号
        in_user_account: '出库账号', //投资订单编码
      },
    })
    const searchSelect = ref({search:"sn"})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<WhboxModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
           if (rq.careateBN) {
            rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await whboxList(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleAdd = () => {
      addDialogRef.value.open()
    }

    const handleTakeBoxDetail = (item:any) =>{
      boxDetailsDrewerRef.value.open(item)
    }
    
    const handleRemove = async(idx:number,boxSn:string) =>{

    }

    const handleTakeBoxIn = async(item:any,boxSn:string) =>{
          //   reDeductionLoading.value = true
          if(item.curCount==0){
             ElMessage.error("空包装箱，不能入库！！！")
            return;
          }
        await ElMessageBox.confirm(
          `是否将包装箱`+boxSn+'归入仓库,当前装箱设备'+item.curCount+',最大装箱设备为'+item.maxCount,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await takeBoxInHttp(boxSn)
        mixinReRouter()
        ElMessage.success(res)
    }
   
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      changSearch,
      list,
      loading,
      addDialogRef,
      boxDetailsDrewerRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      handleAdd,
      handleRemove,
      handleTakeBoxIn,
      handleTakeBoxDetail,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>