
import {
  defineComponent,
  reactive,
  ref,
  unref,
  computed,
  getCurrentInstance,
} from "vue";
import Rule from "@/common/Rules";
import { appStore } from "@/store/modules/app";
import { setSessionStorage } from "@/utils/localStore";
import { login, getUserAllRole } from "@/api/User";
import { userStore } from "@/store/modules/user";
import { routerStore } from "@/store/modules/router";
import { showMessage } from "@/utils/message";
import { useRouter } from "vue-router";
import { resetRouter } from "@/router/index";
interface From {
  username: string | number | "";
  password: string | "";
}
export default defineComponent({
  name: "Login",
  setup() {
    const isLoginPwd=ref(true);
    const loginFormRef = ref<any>(null);
    const fromData = reactive({
      username: "",
      password: "",
      btnLoginLoading: false,
    });
    const rules = reactive({
      username: [...Rule.phone()],
      password: [Rule.required("请输入密码")],
    });
    const showPassword = ref(false);
    const device = computed(() => appStore.getDevice);
    const internalInstance = getCurrentInstance();
    const router = useRouter();
    const switchLogin = () =>{
      isLoginPwd.value=!isLoginPwd.value
    }
    const submit = async () => {
      const loginForm = unref(loginFormRef);
      await loginForm.validate();
      // 登陆
      try {
        fromData.btnLoginLoading = true;
        const { user, token } = await login({
          username: fromData.username,
          password: fromData.password,
          from:"console"
        });
        user.token = token;
        user.roles = await getUserAllRole({ userid: user.userid, token });
        userStore.setUserInfo(user);
        await routerStore.syncRouters();
        resetRouter();
        setSessionStorage("user", {
          username: fromData.username,
          password: fromData.password,
        });
        console.log("结束了 :>> ");
        showMessage(`${user.nickname || user.account} 欢迎您`);
        router.push("/");
      } catch (error) {
        console.log("error :>> ", error);
        showMessage("请确认账号密码是否正确,网络是否正常")
      } finally {
        fromData.btnLoginLoading = false;
      }
    };
    return {
      device,
      fromData,
      showPassword,
      rules,
      submit,
      switchLogin,
      isLoginPwd,
      loginFormRef,
    };
  },
});
