
import Rules from "@/common/Rules";
import { defineComponent, nextTick, ref, toRef } from "vue";
import { treeUpdate, treeSave } from "@/api/System";
import { ElMessage } from "element-plus";
export default defineComponent({
  data() {
    return {
      rules: {
        name: [Rules.required()],
        url: [Rules.required()],
        path: [Rules.required()],
        icon: [Rules.required()],
        address: [Rules.required()],
      },
    };
  },
  props: ["appid"],
  setup(props: any, { emit }) {
    const visible = ref(false);
    const isEdit = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const resultRef = ref<any>();
    const appid = toRef(props, "appid");
    const result = ref({
      name: "",
      url: "",
      path: "",
      icon: "none",
      hidden: "false",
      state: "1",
      treeorder: 0,
      parenttreeid: "",
      code: "",
    });
    const open = (row: any) => {
      isEdit.value = row.isEdit;
      if (isEdit.value) {
        //编辑
        result.value = JSON.parse(JSON.stringify(row));
        if (!row.hidden) result.value.hidden = "false";
      } else {
        //添加
        result.value.parenttreeid = row.parenttreeid;
      }
      visible.value = true;
    };
    const _updateMenu = async () => {
      console.log("result.value :>> ", result.value);
      const _rqData = JSON.parse(JSON.stringify(result.value));
      _rqData.child = _rqData.child.join(",");
      console.log("_rqData :>> ", { ..._rqData, appid: appid.value });
      return await treeUpdate({ ..._rqData, appid: appid.value });
    };

    const _addMenu = async () => {
      return await treeSave({ ...result.value, appid: appid.value });
    };
    const resetData = async () => {
      await nextTick();
      result.value = {
        name: "",
        url: "",
        path: "",
        icon: "none",
        hidden: "false",
        state: "1",
        parenttreeid: "",
        treeorder: 0,
        code: "",
      };
      visible.value = false;
      resultRef.value.clearValidate();
    };

    const submit = async () => {
      loading.value = true;
      try {
        await resultRef.value.validate();
        btnSumitLoading.value = true;
        let res = null;
        if (isEdit.value) {
          res = await _updateMenu();
        } else {
          res = await _addMenu();
        }
        ElMessage.success(res);
        resetData();
        emit("re-data");
      } finally {
        btnSumitLoading.value = false;
      }
    };

    const close = () => {
      resetData();
    };
    return {
      visible,
      result,
      isEdit,
      open,
      submit,
      close,
      resultRef,
      btnSumitLoading,
      loading,
    };
  },
});
