
import { defineComponent } from "vue";
// import { SharedorderprivatizationdeductionList } from "@/api/Order/Privatization";
export default defineComponent({
  name: "return-info",
  props: ["info"],
  // setup(props) {
  //   const info: SharedorderprivatizationdeductionList = toRef(props, "info");
  //   return {
  //     syncInfo: info,
  //   };
  // },
});
