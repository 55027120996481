import { defHttp} from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq,ListResLoad,ListResForOrder} from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface SharedorderprivatizationdeductionList {
    "deductionid": string,
    "negotiationid": string,
    "sharedorderid": string,
    "userid": string,
    "account": string,
    "model": "BALANCE" | string,
    "wallettype": "MJ_TEST" | string,
    "outorderno": string | null,
    "amount": number,
    "requestdate": string,
    "deducted": boolean,
    "deductiondate": string,
    "deductioncompletedate": string,
    "expirationdate": string
    "finishdate": string
    "launchcompletedate": string
    "negotiationcompletedate": string
    "receivedate": string
    "relaunchdate": string
    "returndate": string
    "startdate": string
    "status": string
    "idx": number
    renewaltotaldays: number
    renewalamount: number
    negotiationInfo?: SharedorderprivatizationnegotiationLoad,
    returnInfo?: SharedorderprivatizationreturnLoad,
    open: boolean
}

export interface SharedorderprivatizationdeductionListNew {
    "id":number,
    "gmtCreate": string,  //创建时间
    "gmtModified": string, //更新时间
    "fortest": boolean,  //是否测试订单
    "sharedOrderId": string, //订单id
    "userId": string,   //用户id
    "equipmentSN": string, //设备sn
    "orderStartTime": string, //订单开始时间
    "amount": number,  //扣款金额(元)
    "deductStatus": number, //扣款状态 0：未开始 1：开始扣款 99：扣款完成
    "deductAmount": number, //实际扣款金额
    "deductStartTime": string, //开始扣款时间    
    "relaunchStatus":number,  //重新投放状态 0:未开始 1：开始投放 99：投放完成
    "replaceStatus": number,  //设备更换处理状态 0：未替换 1:开始替换 99:完成替换
    "status":number|0,  //处理状态 0:待处理 1：等待归还 2：超期未归还，开始扣押金 3：已归还待投放 4:押金扣款完成 5：投放完成 99：已完结
    "userReturnAttitude": number, //用户归还意愿 0：不明确 1:愿意归还 2：不愿归还
    "returned": string|null,  //是否归还
    "returnTime": string|null, //归还时间
    "remindCount": number, //提醒次数
    "lastRemindTime": string|null, //上次提醒时间
    "nextRemindTime": string|null,  //下次提醒时间
    "investorUserId": string, //投资人ID
    "handlerUserId": string, //处理人id
    "investorUserName": string,
    "userAccount": string,
    "userName": string,
    "productType": string,
    "productName": string,
    "productModel": string,
    "idx": number,
    constDays:number, //租借时长
    refunded: boolean, //是否有退款
    negotiationInfo?: SharedorderprivatizationnegotiationLoadNew,  
    open: boolean
}

/**
 * 
 * @param params 获取私有化订单集合
 * @returns 
 */
export async function sharedorderprivatizationdeductionList(params: ListReq): Promise<ListRes<SharedorderprivatizationdeductionList>> {
    params.token = true
    params.fortest = true
    const res = await defHttp.request<ListRes<SharedorderprivatizationdeductionList>>(
        {
            url: `/sharedorderprivatizationprocess/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map((v, index) => {
        v.deductioncompletedate = dayjs(v.deductioncompletedate).format("YYYY-MM-DD HH:mm:ss")
        v.expirationdate = dayjs(v.expirationdate).format("YYYY-MM-DD HH:mm:ss")
        v.finishdate = dayjs(v.finishdate).format("YYYY-MM-DD HH:mm:ss")
        v.launchcompletedate = dayjs(v.launchcompletedate).format("YYYY-MM-DD HH:mm:ss")
        v.deductiondate = dayjs(v.deductiondate).format("YYYY-MM-DD HH:mm:ss")
        v.negotiationcompletedate = dayjs(v.negotiationcompletedate).format("YYYY-MM-DD HH:mm:ss")
        v.receivedate = dayjs(v.receivedate).format("YYYY-MM-DD HH:mm:ss")
        v.returndate = dayjs(v.returndate).format("YYYY-MM-DD HH:mm:ss")
        v.relaunchdate = dayjs(v.relaunchdate).format("YYYY-MM-DD HH:mm:ss")
        v.startdate = dayjs(v.startdate).format("YYYY-MM-DD HH:mm:ss")
        v.idx = index
        v.open = false
        return v
    })
    return res
}

/**
 * 
 * @param params 新获取私有化订单集合
 * @returns 
 */
 export async function sharedorderprivatizationdeductionListNew(params: ListReq): Promise<ListRes<SharedorderprivatizationdeductionListNew>> {
    params.token = true
    // params.fortest = true
    const res = await defHttp.request<ListRes<SharedorderprivatizationdeductionListNew>>(
        {
            url: `/sooverdue/pagevo`,
            params
        }, {
        prefix: "/shared"
    });
    res.rows = res.rows.map((v, index) => {      
        v.idx = index
        v.open = false
        return v
    })
    return res
}

/**
 * 
 * @param parmas 提交沟通结果
 * @returns 
 */
export async function sharedorderprivatizationprocessNegotiation(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderprivatizationprocess/negotiation`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}

/**
 * 
 * @param parmas 提交沟通结果
 * @returns 
 */
 export async function sharedorderprivatizationprocessNegotiationNew(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/remind`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared",
    }
    );
    return res.msg
}

export interface SharedorderprivatizationnegotiationLoad {
    "account": string,
    "articleequipmentsn": string,
    "completed": boolean,
    "completedate": string,
    "creationdate": string,
    "description": string,
    "modificationdate": string,
    "negotiationid": string,
    "negotiatoruserid": string,
    "paymentamount": number,
    "receivedate": string,
    "rentdays": number,
    "returndate": string,
    "returned": boolean,
    "returnoverdue": boolean,
    "returnoverduedate": string,
    "sharedorderid": string,
    "userid": string,
    "willreturn": boolean
}

export interface SharedorderprivatizationnegotiationLoadNew {
    "id": string,
    "gmtCreate": string,
    "gmtModified": string,
    "deleted": boolean,   //删除标志位
    "overdueId": string,   //超期处理编号
    "excuteType": string,   //处理类型  0, "系统提醒" 1, "客服提醒" 2, "机柜确认归还" 3, "客服确认归还" 4, "押金扣除"5, "设备投放"6, "更换设备给投资人"7, "押金退款"8, "扣款状态变更"9, "替换状态变更"10, "投放状态变更"11, "更新扣押金时间"99, "确认完结"
    "handlerUserId": string, //处理人
    "excuteInfo": string,   //处理信息
    "remark": string,    //备注
    "handlerUserAccount": string|null,    //备注
    "handlerUserName": string|null,    //备注
    
}

/**
 * 
 * @param negotiationid 加载私有化订单详情
 * @returns 
 */
export async function sharedorderprivatizationnegotiationLoad(negotiationid: string): Promise<SharedorderprivatizationnegotiationLoad> {
    const res = await defHttp.request<RequestRes<SharedorderprivatizationnegotiationLoad>>(
        {
            url: `/sharedorderprivatizationnegotiation/load`,
            params: {
                token: true,
                negotiationid
            },
        }, {
        prefix: "/shared",
    }
    );
    if (!res?.record) throw Error("获取信息失败")
    return Object.assign(res.record, {
        receivedate: dayjs(res.record.receivedate).format("YYYY-MM-DD HH:mm:ss"),
        returnoverduedate: dayjs(res.record.returnoverduedate).format("YYYY-MM-DD HH:mm:ss"),
        creationdate: dayjs(res.record.creationdate).format("YYYY-MM-DD HH:mm:ss"),
        returndate: dayjs(res.record.returndate).format("YYYY-MM-DD HH:mm:ss")
    })
}

interface loadParams {
    token: string;
    overdueId: string
   
}

/**
 * 
 * @param negotiationid 新加载私有化订单详情
 * @returns 
 */
 export async function sharedorderprivatizationnegotiationLoadNew( {token,overdueId} : loadParams ): Promise<ListResLoad<SharedorderprivatizationnegotiationLoadNew>> {
    const res = await defHttp.request<ListResLoad<SharedorderprivatizationnegotiationLoadNew>>(
        {
            url: `/sooverdue/excuteinfo/listbyoverdueid`,
            method: "GET",
            params: {
                token,
                overdueId,
            },
        },
         {
            prefix: "/shared",
            joinParamsToUrl: true,
        }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res;
}
export interface SharedorderprivatizationreturnLoad {
    "account": string,
    "articleequipmentsn": string,
    "closed": boolean,
    "closedate": string,
    "creationdate": string,
    "expressbrand": string,
    "expressnum": string,
    "modificationdate": string,
    "negotiationid": string,
    "receivedate": string,
    "returndate": string,
    "returned": boolean,
    "returnoverdue": boolean,
    "returnoverduedate": string,
    "returntype": string,
    "sharedorderid": string,
    "userid": string
}
export async function sharedorderprivatizationreturnLoad(negotiationid: string): Promise<SharedorderprivatizationreturnLoad> {
    const res = await defHttp.request<RequestRes<SharedorderprivatizationreturnLoad>>(
        {
            url: `/sharedorderprivatizationreturn/load`,
            params: {
                token: true,
                negotiationid
            },
        }, {
        prefix: "/shared",
    }
    );
    if (!res?.record) throw Error("获取信息失败")
    return Object.assign(res.record, {
        creationdate: dayjs(res.record.creationdate).format("YYYY-MM-DD HH:mm:ss"),
        receivedate: dayjs(res.record.receivedate).format("YYYY-MM-DD HH:mm:ss"),
        closedate: dayjs(res.record.closedate).format("YYYY-MM-DD HH:mm:ss"),
        returnoverduedate: dayjs(res.record.returnoverduedate).format("YYYY-MM-DD HH:mm:ss"),
        returndate: dayjs(res.record.returndate).format("YYYY-MM-DD HH:mm:ss")
    })
}
//强制私有化
export async function sharedorderPrivatizationprocess(sharedorderid: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorder/privatizationprocess`,
            params: {
                token: true,
                sharedorderid
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message || "私有化成功"
}

//新强制私有化
export async function sharedorderPrivatizationprocessNew(sharedorderId: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/add`,
            params: {
                token: true,
                sharedorderId
            },
        }, {
        prefix: "/shared",
       // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg || "私有化成功"
}

export async function sharedorderprivatizationreturnConfirm(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderprivatizationreturn/confirm`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}

/**
 * 
 * @param parmas 新确认归还小魔夹
 * @returns 
 */
export async function sharedorderprivatizationreturnConfirmNew(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/updatereturn`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared"
       // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}

/**
 * 
 * @param sharedorderid 开始投放
 * @returns 
 */
export async function sharedorderprivatizationprocessRelaunchStart(sharedorderid: string): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderprivatizationprocess/relaunch/start`,
            params: {
                token: true,
                sharedorderid
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}

/**
 * 
 * @param sharedorderid 新开始投放
 * @returns 
 */
 export async function sharedorderprivatizationprocessRelaunchStartNew(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/updaterelaunch`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared"
        //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}

/**
 * 
 * @param sharedorderid 小魔夹投放完成接口
 * @returns 
 */
export async function sharedorderprivatizationprocessLaunchComplete(sharedorderid: string): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderprivatizationprocess/launch/complete`,
            params: {
                token: true,
                sharedorderid
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}

/**
 * 
 * @param sharedorderid 私有化订单完结
 * @returns 
 */
 export async function sharedorderprivatizationprocessLaunchCompleteNew(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/complete`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared"
        //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}

/**
 * 
 * @param sharedorderid 开始扣款
 * @returns 
 */
export async function sharedorderprivatizationprocessDeduction(sharedorderid: string): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderprivatizationprocess/deduction`,
            params: {
                token: true,
                sharedorderid
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}
/**
 * 
 * @param sharedorderid 强制结算
 * @returns 
 */
 export async function sharedorderprivatizationDeduction(overdueid: string): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/deduction`,
            params: {
                token: true,
                overdueid
            },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}
/**
 * 
 * @param sharedorderid 开始扣押金
 * @returns 
 */
 export async function sharedorderprivatizationprocessDeductionNew(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/updatedeductstarttime`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared",
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}

/**
 * 
 * @param sharedorderid 退款
 * @returns 
 */
 export async function onPrivateOrderReturnMoney(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/refund`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared",
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}


/**
 * 
 * 更换小魔夹
 * @returns 
 */
 export async function onPrivateOrderReplaceDevice(parmas: RequestReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sooverdue/updatereplace`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/shared",
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.msg
}

/**
 * 
 * @param params 获取未完成的超期订单
 * @returns 
 */
 export async function getProgressPrivateOrderTotal(parmas: ListReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedordermemo/getProgressPrivateOrderNum`,
            params: {
                token: true,
                ...parmas
            },
        },
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
   
    return res.data.total
}