
import { defineComponent, ref } from "vue";
import ImageUpload from "@/components/uploadImg.vue";
import { userStore } from "@/store/modules/user";
export default defineComponent({
  components: {
    "v-image-upload": ImageUpload,
  },
  setup() {
    const visible = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const imageUpload = ref<any>(null);
    const data = ref({});
    const url = "/v1/file/user/avatar?token=" + userStore.info.token;
    const resetData = () => {
      visible.value = false;
      data.value = {};
    };
    const handleCancel = () => {
      resetData();
    };
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        await imageUpload.value.submit();
      } finally {
        btnSumitLoading.value = true;
      }
    };
    return {
      visible,
      loading,
      url,
      data,
      handleCancel,
      btnSumitLoading,
      imageUpload,
      submit,
    };
  },
});
