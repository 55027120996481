import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface SocketListRes {
    panelequipmentsn: string  //共享面板的设备序号，如：小魔柜SN；雨伞架SN
    articleequipmentsn: string  //共享物品的设备序号
    equipmentsn: string  //设备序号
    creationdate: string //创建时间
    enabled: boolean
    socketsn: string   //插口序号，如：小魔柜 01~12 雨伞架 01~09
    article: boolean   //是否有共享物品，如：true 是 false 否
    fault: boolean    //是否有故障，如：true 是 false 否
    faultcode: string  //故障码，如：privatization 私有化物品；unshared 非共享物品；invalid 无效物品；
    batterypower: number //电量，即电量百分比，如： 75 即 75%
    socketstatus: string //插口状态，如：0 正常无设备；1 正常有设备；
    sleeptime: string //设备在插口内的休眠时长
    isSnEqual: boolean  //设备的SN是否相等 articleequipmentsn和equipmentsn
    isEmpty:boolean  //是否持有设备
    isNormal:boolean //仓位含有设备是否为正常
}
export async function getSocketList(params: ListReq): Promise<ListRes<SocketListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<SocketListRes>>(
        {
            url: `/sharedsocket/listvo`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        if (!v.articleequipmentsn) v.articleequipmentsn = '无'
        if(!v.equipmentsn) v.equipmentsn = '无'
        return v
    })
    return res
}
export async function upDateSocket(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<SocketListRes>>(
        {
            url: `/sharedsocket/update`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function removeSocket(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<SocketListRes>>(
        {
            url: `/sharedsocket/remove`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function addSocket(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<SocketListRes>>(
        {
            url: `/sharedsocket/add`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}