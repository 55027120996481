<template>
  <el-dialog :destroy-on-close="true" :title="`确认扣款金额`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      :title="tipMsg"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef" v-if="row" style="margin-top: 20px;">
      <!-- <el-form-item label="续期" prop="day">{{row.renewaltotaldays}}天，扣款{{row.renewalamount}}元</el-form-item> -->
      <el-form-item label="超期使用天数" prop="day">
        <el-input-number :min="0" :step="1" v-model="inputData.day"></el-input-number>
      </el-form-item>
      <el-form-item label="超期扣费" prop="day">{{inputData.day}}天，扣款{{inputData.day*3}}元</el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import {
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationreturnConfirmNew,
} from '@/api/Order/Privatization'
import { userList } from "@/api/User/index";
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { userStore } from "@/store/modules/user";
let overdueId = 0;
let startTime=0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<SharedorderprivatizationdeductionListNew>()
    const userInfoList = ref<any>([]);
    const tipMsg = ref<string>("非会员用户，3小时内3元，超过3小时6元一天")
    const inputData = ref({
      day: 0,
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async (_row: SharedorderprivatizationdeductionListNew) => {
      console.log('传入数据=',_row);
      await getUserInfo(_row.userId)
      row.value=_row;
      overdueId=_row.id;
      visible.value = true
      startTime=new Date(_row.orderStartTime).getTime();
      
    }
    const getUserInfo = async (userid:string) => {     
      let rqData: any = { page: 1, limit: 10, userid:userid };
      const res = await userList(rqData);
      userInfoList.value = res.result;
      const isVip=userInfoList.value[0].isvip==1;
      const phone=userInfoList.value[0].account
      tipMsg.value="用户"+phone+"是"+(isVip?"会员用户，":"普通用户，")+(isVip?"计费是：小魔夹3元一天；雨伞3元一天 ":"计费是：小魔夹3小时内3元，超过3小时6元一天；雨伞3元一天")
    };
    const submit = async () => {
      if (!row.value) return
      try {
        btnSumitLoading.value = true
        const rentdays = inputData.value.day;
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc=time+'确认收到小魔夹'
        const returnTime=dayjs(startTime+rentdays*24*60*60*1000).format("YYYY-MM-DD HH:mm:ss");
        const res = await sharedorderprivatizationreturnConfirmNew({
          overdueId: overdueId,
          handleUserId:userStore.userInfo.userid,
          // amount: rentdays * 3,
          remark:desc,
          excuteInfo:'',
          returnTime:returnTime,
          returnType:'1'
        })
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      tipMsg,
      btnSumitLoading,
      formRef,
      row,
      submit,
      inputData,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>