<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item :key="item.path+index" v-for="(item,index) of levelList">
        <span
          class="no-redirect"
          v-if="item.redirect==='noredirect'||index==levelList.length-1"
        >{{ item.meta.title }}</span>
        <a @click.prevent="handleLink(item)" v-else>{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script lang="ts">
import { routerStore } from "@/store/modules/router";
import {compile} from "path-to-regexp";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { RouteLocationMatched } from "vue-router";
export default defineComponent({
  name:"v-breadcrumb",
  setup() {
    let levelList = ref<any[]>([]);
    const route = useRoute();
    const router = useRouter();
    const getRouters = computed(() => routerStore.getRouters);
    const getBreadcrumb = () => {
      let matched = route.matched.filter((item) => item.path);
      // matched = matched.map((v) => v);
      levelList.value = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    };
    watch(
      () => route.path,
      () => {
        getBreadcrumb();
      }
    );
    onMounted(() => {
      getBreadcrumb();
    });

    const pathCompile = (path: string) => {
      const { params } = route;
      const toPath = compile(path);
      return toPath(params);
    };

    const handleLink = (item: RouteLocationMatched) => {
      console.log('item :>> ', item);
      const { redirect, path } = item;
      if (redirect) {
        return router.push(JSON.stringify(redirect));
      }
      router.push(pathCompile(path));
    };
    return {
      levelList,
      handleLink,
    };
  },
});
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 15px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
