
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'

import {
  sharedorderrefundList,
  SharedorderrefundList,
  sharedorderrefundRefund,
} from '@/api/Order/Refund'
import { getShareProductInfo } from '@/api/util'
import refundConfromDialog from './components/refundConfromDialog.vue'
import masterRefundConfromDialog from './components/masterRefundConfromDialog.vue'
import { ElMessage } from 'element-plus'
import { hasCurrentRole } from '@/common/checkRole'
export default defineComponent({
  name: 'renewal',
  components: {
    refundConfromDialog,
    masterRefundConfromDialog,
  },
  setup() {
    const loading = ref(false)
    const reRefundLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SharedorderrefundList[]>([])
    const deviceList = getShareProductInfo()
    const refundConfromDialogRef = ref<any>()
    const masterRefundConfromDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'receiveraccount',
    })
    const mixin_router_search = ref({
      fortest: false,
      
      closed:false,
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        receiveraccount: '',
        articleequipmentsn: '',
        sharedorderid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        receiveraccount: '账号',
        articleequipmentsn: '设备SN',
        sharedorderid: '订单号',
      },
    })
    const roleShowPermission = inject('roleShowPermission')

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderrefundList(rqData)
        list.value = res.records
        total.value = res.total
        // sharedorderrenewalList
        // sharedorderrene
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleRefund = (row: SharedorderrefundList) => {
      if(!row.masterAudited){
          ElMessage.success("需要运营主管先审核")
        return
      }
      refundConfromDialogRef.value.open(row)
    }
    const handleReRefund = async (row: SharedorderrefundList) => {
      try {
        reRefundLoading.value = true
        const res = await sharedorderrefundRefund(row.refundid)
        ElMessage.success(res)
        mixinReRouter()
      } finally {
        reRefundLoading.value = false
      }
    }
    const handleMasterRefund = (row: SharedorderrefundList) =>{
      masterRefundConfromDialogRef.value.open(row)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      changSearch,
      handelChange,
      handleLimitChange,
      onPageChange,
      deviceList,
      refundConfromDialogRef,
      masterRefundConfromDialogRef,
      handleRefund,
      roleShowPermission,
      mixinReRouter,
      handleReRefund,
      mixinReRouterTableSortChange,
      handleMasterRefund,
    }
  },
})
