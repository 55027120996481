
import { defineComponent, nextTick, readonly, ref } from "vue";
import { bindPrivateDeivce } from "@/api/Share/Private";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "v-dialog",
  emits:['success'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);

    const inputData = ref({
      sn: "", 
    });
    const rules = readonly({
      sn: [Rules.required()],
    
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      visible.value = false;
      
    };
    const open = (_index: number, row?: any) => {
      visible.value = true;
      
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        const res = await bindPrivateDeivce(inputData.value.sn);
        handleClose();
        ElMessage.success(res.msg);
        
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
    };
  },
});
