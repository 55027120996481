<!--  -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`选择付款时间`"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-date-picker
      :picker-options="pickerOptions"
      align="right"
      placeholder="选择日期"
      type="date"
      v-model="date"
    ></el-date-picker>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import { investmentorderConfirmpayment } from "@/api/Investment/Order";
import { userStore } from "@/store/modules/user";
import dayjs from "dayjs"
let orderid: string; //订单号
let index: number; //row index
export default defineComponent({
  name: "Name",
  data() {
    return {
      pickerOptions: {
        disabledDate(time: Date) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker: any) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker: any) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const date = ref("");
    const btnSumitLoading = ref(false);
    const open = (row: any) => {
      orderid = row.orderid;
      index = row.index;
      visible.value = true;
    };
    const handleClose = () => {
      visible.value = false;
      date.value = "";
      orderid = "";
      index = -1;
    };

    const submit = async () => {
      try {
        if (!date.value) return ElMessage.error("请选择付款日期");
        btnSumitLoading.value = true;
        const res = await investmentorderConfirmpayment({
          financeuserid: userStore.info.userid,
          orderid,
          paydate:dayjs( date.value).format("YYYY/MM/DD HH:mm:ss"),
        });
        emit("success", index);
        handleClose();
        ElMessage.success(res)
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      date,
      btnSumitLoading,
      handleClose,
      open,
      submit
    };
  },
});
</script>
<style lang="scss" scoped>
</style>