<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-select" clearable placeholder="广告类型" v-model="mixin_router_search.adType">
        <el-option :key="item.adType" :label="item.name" :value="item.adType" v-for="item of adInfoList"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-select" clearable placeholder="入库类型" v-model="mixin_router_search.whOpType">
        <el-option label="生产" value="0"></el-option>
        <el-option label="维修" value="1"></el-option>
      </el-select> 
       <el-select @change="handelChange" class="header-select" clearable placeholder="是否出库" v-model="mixin_router_search.isOut">
        <el-option label="未出库" :value='false'></el-option>
        <el-option label="已出库" :value='true'></el-option>
      </el-select>         

      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="入库时间"
        range-separator="至"
        start-placeholder="入库时间"
        type="datetimerange"
        unlink-panels        
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
     <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">入库</el-button>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="入库用户id" v-if="scope.row.inUserId">
              <span>{{ scope.row.inUserId }}</span>
             <my-copy :data="scope.row.inUserId" />
            </el-form-item>
            <el-form-item label="出库用户id" v-if="scope.row.outUserId">
              <span>{{ scope.row.outUserId }}</span>
             <my-copy :data="scope.row.outUserId" />
            </el-form-item>
            <!-- <el-form-item label="出货单id">
              <span>{{ scope.row.shipmentId }}</span>
              <my-copy :data="scope.row.shipmentId"></my-copy>
            </el-form-item> -->
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="序号" min-width="20">
        <template #default="scope">        
          {{scope.row.id}}
        </template>
      </el-table-column>
      <el-table-column label="设备SN" align="center" min-width="36">
        <template #default="scope">
          {{scope.row.equipmentSn?scope.row.equipmentSn:"无"}}
          <my-copy :data="scope.row.equipmentSn" v-if="scope.row.equipmentSn"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="设备类型" align="center" min-width="36">
        <template #default="scope">
          <el-tag v-if="scope.row.productid">{{productList[productIdList.indexOf(scope.row.productid)].name}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="包装箱SN" align="center" min-width="56">
        <template #default="scope">
          {{scope.row.boxSn?scope.row.boxSn:"无"}}
           <my-copy :data="scope.row.boxSn" v-if="scope.row.boxSn"></my-copy>
        </template>
      </el-table-column>
      <el-table-column align="center" label="来源" min-width="34">
        <template #default="scope">
          <el-tag  type="waring" v-if="scope.row.whOpType==0">生产</el-tag>    
          <el-tag  type="info" v-if="scope.row.whOpType==1">维修</el-tag>    
        </template>
      </el-table-column>
      <el-table-column align="center" label="广告" min-width="34">
        <template #default="scope" >
          <span v-if="scope.row.adType!=null">{{adInfoList[scope.row.adType].name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="入库账号" align="center" min-width="44">
        <template #default="scope">{{scope.row.inUserAccount?scope.row.inUserAccount:'暂无'}}</template>
      </el-table-column>
      <el-table-column label="出库账号" align="center" min-width="44">
        <template #default="scope">{{scope.row.outUserAccount?scope.row.outUserAccount:'暂无'}}</template>
      </el-table-column>      
      
      <el-table-column label="入库时间" align="center" min-width="55">
        <template #default="scope">{{scope.row.gmtCreate}}</template>
      </el-table-column>
      <el-table-column label="出库时间" align="center" min-width="55">
        <template #default="scope">{{scope.row.gmtOut}}</template>
      </el-table-column>
     
      <el-table-column align="center" label="操作" min-width="70">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button
            @click="handleChangeAD(scope.row)"
            size="mini"
            type="info"
            style="margin-left: 12px;"         
            v-if="scope.row.gmtOut=='暂无'" 
           >更新广告</el-button>
          <el-button
            @click="handleOutRerairXMJ(scope.row,scope.row.equipmentSn)"
            size="mini"
            type="primary"
            style="margin-left: 12px;"      
            v-if="scope.row.whOpType==1&&scope.row.gmtOut=='暂无'"    
           >维修出库</el-button>
          <el-button
            @click="handleTakeBoxDetail(scope.row)"
            size="mini"
            type="primary"
            style="margin-left: 12px;"
            v-if="scope.row.boxSn"
           >装箱详情</el-button>
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <in-dialog @success="mixinReRouter" ref="addDialogRef"></in-dialog>
    <change-ad-dialog @success="mixinReRouter" ref="changeAdDialogRef"></change-ad-dialog>
    <boxDetailsDrawer ref="boxDetailsDrewerRef"></boxDetailsDrawer>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { whRecordModel, whRecordListHttp, outRepairXMJHttp,exportWhrecordInfo} from '@/api/WarehouseManagement/index'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import boxDetailsDrawer from './components/boxDetailsDrawer.vue'
import inDialog from './components/inDialog.vue'
import changeAdDialog from './components/changeAdDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo,getAdInfoList,getProductInfoList,getProductIdList} from '@/api/util'
import myExport from '@/utils/exprotExcel'
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'in-out',
  components: {
   inDialog,
   boxDetailsDrawer,
   changeAdDialog,
   exportDialog,
  },
  setup() {
    const addDialogRef = ref<any>()
    const changeAdDialogRef = ref<any>()
    const boxDetailsDrewerRef = ref<any>()
    const exportDialogRef = ref<any>();
    const sheareProductInfo = getShareProductInfo()  
    const adInfoList = getAdInfoList()
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const mixin_router_search = ref({
      whOpType: '', //状态 0:生产 1：维修
      adType:'', //广告类型
      isOut:false,//是否出库
      careateBN:'',
      order: "gmt_create",
      sort: "desc"
    })

    const mixin_select_search = ref({
      search: {
        boxSn:'', //包装箱编号
        equipmentSn:'',//设备SN
        inUserAccount:'',//入库账号
        outUserAccount:'',//出库账号         
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        boxSn: '包装箱SN', 
        equipmentSn:'设备SN',
        inUserAccount:'入库账号',
        outUserAccount: '出库账号',      
      },
    })

   

    const searchSelect = ref({
      search: 'equipmentSn',
    })

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<whRecordModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.careateBN) {
            rq.startGmtIn = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtIn = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await whRecordListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleAdd = () => {
      addDialogRef.value.open()
    }
    
    const handleRemove = async(idx:number,boxSn:string) =>{

    }

    const handleChangeAD = async(row:any) =>{
      changeAdDialogRef.value.open(row)
    }

     const handleTakeBoxDetail = (item:any) =>{
      boxDetailsDrewerRef.value.open(item)
    }

    const handleOutRerairXMJ = async(item:any,sn:string) =>{
        //reDeductionLoading.value = true
        await ElMessageBox.confirm(
          `是否出库`+sn+',当前设备为维修设备',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await outRepairXMJHttp(sn)
        mixinReRouter()
        ElMessage.success(res)
    }
   
    const handleExport = async() => {
      exportDialogRef.value.open('维修单记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'whrecord',order: 'gmtCreate',sort: 'desc'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          searchData.startGmtIn = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          searchData.endGmtIn =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportWhrecordInfo(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      changSearch,
      searchSelect,
      list,
      loading,
      addDialogRef,
      changeAdDialogRef,
      boxDetailsDrewerRef,
      exportDialogRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      adInfoList,
      productList,
      productIdList,
      handleAdd,
      handleRemove,
      handleChangeAD,
      handleOutRerairXMJ,
      handleTakeBoxDetail,
      handleExport,    
      exportFile 
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>