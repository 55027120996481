import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface operateModel {
     
    "name": string, //创建时间
    "gmtCreate": string, //更新时间
    "gmtModified": string, //出库时间
    "id": number, //
    "operationId":number
}

export async function operateListHttp(params: ListReq): Promise<ListRes<operateModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<operateModel>>(
        {
            url: `/sysoperation/page`,
            params
        },
        {
            prefix: "/default",
            headers: { 'Content-Type': 'application/json' },
        }
    );
   
    return res
}

export async function addOperateHttp(name: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sysoperation/create`,
            params: {
                token: true,
                name
            },
        }   
    );
   
    return res.msg || "运营团队创建成功"
}

export async function updateOperateHttp(params: ListReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sysoperation/update`,
            params
        },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
   
    return res.msg || "更新运营团队成功"
}


export async function removeOperateHttp(id: number): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sysoperation/remove`,
            params : {
                token:true,
                id
            }
        }
    );
   
    return res.msg || "删除运营团队成功"
}

export async function getOperateListHttp(): Promise<ListResLoad<operateModel>> {
    const res = await defHttp.request<ListResLoad<operateModel>>(
        {
            url: `/sysoperation/list`,
            params : {
                token:true,
            }
        },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
   
    return res;
}