<template>
    <div class="container">
        <div ref="mapContainerRef" id="map" class="map-container"/>
    </div>
</template>
<!-- <script src="https://a.amap.com/jsapi_demos/static/geojson/shanghai.js"></script> -->
<script lang="ts">
import AMapLoader from "@amap/amap-jsapi-loader";
import { defineComponent, ref, onMounted,nextTick} from "vue";
import { markModel,getMarkListHttp } from "@/api/Business/map"
import console from "console";

export default defineComponent({
    name: "Name",
    components: {},
    setup() {
        const aMapLoaded=ref<any>()
        const map=ref<any>()
        const mAMap=ref<any>()
        const mapContainerRef = ref<any>();
        const list=ref<markModel[]>([]);
        // 点标记显示内容，HTML要素字符串
        const markerContent = '' +
        '<div class="custom-content-marker">' +
        '   <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">'
        '</div>';
        const initMap= async() =>{
            aMapLoaded.value= AMapLoader.load({
                key: "5b620c14d2007c00ea564c85eed9874e",       // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0",                 // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            }).then((AMap)=>{
                // JS API加载完成后获取AMap对象
                console.log("初始化地图=",AMap)
                mAMap.value=AMap;
                console.log("初始化地图2=",mAMap.value)
                 map.value = new AMap.Map(mapContainerRef.value, {
                    center: [112.938888, 28.228272],
                    zoom: 12,
                    viewMode: '2D',  // 地图设置成 3D 模式，否则图层会失去高度信息
                });
                console.log("初始化地图1=",map)
                // const polygon = new AMap.Polygon({
                //     path: changsha
                //     fillColor: '#ccebc5', // 多边形填充颜色
                //     strokeOpacity: 1, // 线条透明度
                //     fillOpacity: 0.5, //填充透明度
                //     strokeColor: '#2b8cbe', // 线条颜色
                //     strokeWeight: 1, //线条宽度
                //     strokeStyle: 'dashed', // 线样式
                //     strokeDasharray: [5, 5], //轮廓的虚线和间隙的样式
                // });
                // polygon.on('mouseover', () => { // 鼠标移入更改样式
                //     polygon.setOptions({
                //         fillOpacity: 0.7,
                //         fillColor: '#7bccc4'
                //     })
                // })
                // polygon.on('mouseout', () => { // 鼠标移出恢复样式
                //     polygon.setOptions({
                //         fillOpacity: 0.5,
                //         fillColor: '#ccebc5'

                //     })
                // })
                // map.value.add(polygon)
                getMarkList()
            })
           
        }

        const getMarkList=async()=>{
            console.log("获取周分布点",mAMap.value)
            let rqData: any = { currentPage: 200, size: 1 }
            const res = await getMarkListHttp(rqData)   
            list.value=res.data.list;
            list.value.forEach(item =>{
                addMark(item.longitude,item.latitude)
            })
        }

        const addMark=(lng:number,lat:number) =>{
               
            const position = new mAMap.value.LngLat(lng, lat); // Marker经纬度
            const marker = new mAMap.value.Marker({
                position: position,
                content: markerContent, // 将 html 传给 content
                offset: new mAMap.value.Pixel(-13, -30) // 以 icon 的 [center bottom] 为原点
            });
            map.value.add(marker);
        }

        onMounted(async()=>{
            console.log("开始初始地图")
            initMap();
            
        })
        return{
            mapContainerRef
        }
    }
})
</script>
<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
    .map-container {
        width: 100%;
        height: 100%;
    }
    .custom-content-marker {
        position: relative;
        width: 25px;
        height: 34px;
    }

    .custom-content-marker img {
        width: 100%;
        height: 100%;
    }

    .custom-content-marker .close-btn {
        position: absolute;
        top: -6px;
        right: -8px;
        width: 15px;
        height: 15px;
        font-size: 12px;
        background: #ccc;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 15px;
        box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
    }

    .custom-content-marker .close-btn:hover{
        background: #666;
    }
}
</style>
