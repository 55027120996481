
import { faultListModel,  faultComfirmHttp} from '@/api/fault/index'
import { getOrderList, OrderList,} from '@/api/Order'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { userStore } from "@/store/modules/user";
let overdueId = 0;
let startTime=0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<faultListModel>()
    const list = ref<OrderList[]>([])
    const inputData = ref({
      day: 0,
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: faultListModel) => {
      row.value=_row;
      overdueId=_row.id;
      reData(_row.sharedOrderId)
    }
    const submit = async () => {
      if (!row.value) return
      try {
        btnSumitLoading.value = true
        const rentdays = inputData.value.day;
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc=time+'确认收到小魔夹'
        const money= '确认扣除'+inputData.value.day*3+'元费用'
        const returnTime=dayjs(startTime+rentdays*24*60*60*1000).format("YYYY-MM-DD HH:mm:ss");
        const res = await faultComfirmHttp({
          breakdownId: overdueId,
          // handleUserId:userStore.userInfo.userid,
          // amount: rentdays * 3,
          remark:desc,
          excuteInfo:money,
          stopBillingTime:returnTime,
      
        })
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }

    const reData = async (shareOrderid: any) => {
      try {
     
        let rqData: any = { current: "1", size: "10" ,entity:{sharedorderid: shareOrderid}}
   
        const res = await getOrderList(rqData)
        list.value = res.records
        startTime=new Date(list.value[0].receivedate).getTime();
      } finally {
        visible.value = true
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      row,
      submit,
      inputData,
    }
  },
})
