
import { changeXMJAdHttp } from '@/api/WarehouseManagement/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { getAdInfoList } from '@/api/util'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const adInfoList = getAdInfoList()

    const inputData = ref({
      sn: '',  
      adType:'0'//广告类型
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:any) => {
      visible.value = true
      inputData.value.sn=item.equipmentSn;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        const res = await changeXMJAdHttp(inputData.value)      
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      adInfoList
    }
  },
})
