import type { App, Directive } from 'vue';

//加栽更多
const elLoadmore: Directive = {
    mounted(el, binding) {
        console.log('binding.instance :>> ', el, binding.instance);
        console.log('挂在了 :>> ');
        let timer: any = null;
        // let SELECTWRAP_DOM: HTMLElement = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap ') // 下拉选择框
        let SELECTWRAP_DOM = document.querySelector('.my-select-proper  .el-select-dropdown__wrap') // 下拉选择框
        if (!SELECTWRAP_DOM) SELECTWRAP_DOM = document.querySelector('.el-autocomplete-suggestion .el-autocomplete-suggestion__wrap') // 远程搜索框
        if (!SELECTWRAP_DOM) throw new Error("获取dmo失败，请检查您的设置select，proper类名是否是my-select-proper  ")
        SELECTWRAP_DOM.addEventListener('scroll', () => {
            /*
             * scrollHeight 获取元素内容高度(只读)
             * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
             * clientHeight 读取元素的可见高度(只读)
             * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
             * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
             */
            if (!SELECTWRAP_DOM) return
            const CONDITION = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight;
            if (CONDITION) {
                console.log("触发了")
                if (timer) clearTimeout(timer);
                timer = setTimeout(() => {
                    binding.value()
                }, 500);
            }

        })
    }
}

//我的颜色
const myColor: Directive = {
    mounted(el, binding) {
        const colors: any = {
            primary: "#409EFF",
            danger: "#F56C6C",
            success: "#67C23A",
            info: "#909399",
            warning: "#E6A23C"
        }
        if (!binding.value) throw Error("请输入颜色")
        if (!colors[binding.value]) throw Error(`颜色类型 ${binding.value} 不存在，目前仅支持${Object.keys(colors)}`)
        el.style.color = colors[binding.value]
    }
}
export function setupDirectives(app: App<Element>) {
    app.directive('el-loadmore', elLoadmore)
    app.directive('my-color', myColor)
}
