
import { defineComponent, ref } from "vue";
import { getSocketList, SocketListRes } from "@/api/Share/Socket";
export default defineComponent({
  name: "v-device-dialog",
  setup() {
    const visible = ref(false);
    const page = ref(1);
    const limit = ref(15);
    const loading = ref(false);
    const list = ref<SocketListRes[]>([]);
    const total = ref(0);
    const panelequipmentsn = ref("");
    const prefix = ref("");
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };

    const reData = async () => {
      try {
        loading.value = true;
        const res = await getSocketList({
          current: page.value,
          size: limit.value,
          entity: { panelequipmentsn: panelequipmentsn.value },
        });
        console.log('res :>> ', res);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    };
    const open = (sn: string, _prefix: string) => {
      visible.value = true;
      panelequipmentsn.value = sn;
      prefix.value = _prefix;
      reData();
    };
    const onPageChange = (current: any) => {
      page.value = current.value;
      reData();
    };
    return {
      visible,
      panelequipmentsn,
      handleClose,
      open,
      onPageChange,
      prefix,
      page,
      limit,
      total,
      list,
    };
  },
});
