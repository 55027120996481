
import { defineComponent, ref, readonly } from "vue";
import { getStatusName } from "./editData";
import routerChangeReData from "@/common/routerChangeReData";
import assignDialog from "./components/assignDialog.vue";
import assignNewDialog from "./components/assignNewDialog.vue";
import addNoOrderShipmentDialog from "./components/addNoOrderShipmentDialog.vue"
import {getProductInfoList,getProductIdList} from '@/api/util'
import { userStore } from '@/store/modules/user'
import {
  investmentordershipmentList,
  InvestmentordershipmentListRes,
  outwarehouse,
  startlaunch,
  addNoInvestorOrderShipmentHttp,
} from "@/api/Investment/shipment";
import { ElMessage, ElMessageBox } from "element-plus";
import { number } from "echarts/core";
export default defineComponent({
  name: "Name",
  components: {
    "v-assign-dialog": assignDialog,
    "v-assign-new-dialog":assignNewDialog,
    addNoOrderShipmentDialog,
  },
  setup() {
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const mixin_router_search = ref({
      status: "", //投资订单出货状态，0 未出仓；1 已出仓；2 平台投放已开启；3 平台投放已结束；4 快递已寄出；5 快递已签收
      platformlaunchbegin: "", //平台投放是否开启，如：true 是 false 否
      platformlaunchend: "", //平台投放是否结束，如：true 是 false 否
      outwarehouse: "", //平台投放是否开启，如：true 是 false 否
      packagedelivered: "", //快递是否寄出，如：true 是 false 否
      packagesigned: "", //	快递是否被签收，如：true 是 false 否
      order: "creationdate",
      sort: "desc",
    });
    const mixin_select_search = ref({
      search: {
        investoruserid: "", // 投资人用户编号
        investmentorderid: "", //投资订单编码
        trackingnumber: "", // 快递单号
        shipperuserid: "", //投资订单编码       
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        investoruserid: "投资人用户编号", // 投资人用户编号
        investmentorderid: "投资订单编码", //投资订单编码
        trackingnumber: "快递单号", //投资订单编码
        shipperuserid: "投资订单编码", //投资订单编码
      },
    });
    const btnOutLoading = ref(false);
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<InvestmentordershipmentListRes | any>([]);
    const searchSelect = ref({search:"investoruserid"});
    const assignDialogRef = ref<any>();
    const assignNewDialogRef = ref<any>();
    const addNoOrderShipmentRef = ref<any>();
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = {
          current: page.value,
          size: limit.value,
        };
        // if (rq && JSON.stringify(rq) !== "{}") {
        //   rqData = { ...rqData, entity: rq };
        // }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await investmentordershipmentList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
    } = routerChangeReData({
      mixin_router_search,
      mixin_select_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleSuccess =() =>{
      page.value = 1;
      mixinReRouter();
    }
    const handleAssgin = (row: any) => {
      // assignDialogRef.value.open(row);
      assignNewDialogRef.value.open(row);
    };
    const handleShowAssgin = (row: any) => {
      // assignDialogRef.value.open(row, true);
      assignNewDialogRef.value.open(row,true);
    };
    const handleOut = async (index: number, shipmentid: string) => {
      try {
        btnOutLoading.value = true;
        const res = await outwarehouse({
          shipmentid,
        });
        ElMessage.success(res.msg);
        mixinReRouter();
        // list.value[index] = res.record;
      } finally {
        btnOutLoading.value = false;
      }
    };
    const handleStartLaunch = async (index: number, shipmentid: string) => {
      try {
        btnOutLoading.value = true;
        const res = await startlaunch({
          shipmentid,
        });
        ElMessage.success(res.msg);
        mixinReRouter();
        //list.value[index] = res.record;
      } finally {
        btnOutLoading.value = false;
      }
    };

    const handleAdd = async() => {
      addNoOrderShipmentRef.value.open()
      // try {
      //   const input = await ElMessageBox.prompt('请输入出货数', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     inputPattern: /^[0-9]*$/,
      //     inputErrorMessage: `请输入 1-60 `,
      //     inputType: 'number',
      //   })
      //   console.log('input :>> ', input)
      //   const _value = parseInt(input.value)
      //   if (_value > 60 || _value < 1)
      //     return ElMessage.error(`请输入 1-60`)
      //   if (!userStore.info.userid) return userStore.loginOut()
      //     const res = await addNoInvestorOrderShipmentHttp({
      //       quantity: _value,
      //       shipperUserId: userStore.info.userid,
      //     })
      //   console.log('res :>> ', res)
      //   ElMessage.success(res)
      //   mixinReRouter();
      // } catch (error) {
      //   if (error == 'cancel') return
      //   if (error == 'colse') return
      //   ElMessage.error(error)
      // }
        
    }

    return {
      mixin_select_search,
      mixin_select_search_label,
      searchSelect,
      mixin_router_search,
      getStatusName,
      loading,
      limit,
      page,
      total,
      list,
      handelChange,
      onPageChange,
      handleLimitChange,
      assignDialogRef,
      assignNewDialogRef,
      addNoOrderShipmentRef,
      productList,
      productIdList,
      handleAssgin,
      handleOut,
      handleAdd,
      handleStartLaunch,
      handleShowAssgin,
      handleSuccess,
    };
  },
});
