
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import { AxiosCanceler } from './axiosCancel';
import { isFunction } from '@/utils/is';
import { cloneDeep } from 'lodash';
import type { RequestOptions, CreateAxiosOptions, Result, UploadFileParams } from './types';
// import JSONBig from 'json-bigint'
export class VAxios {
    private axiosInstance: AxiosInstance;
    private readonly options: CreateAxiosOptions;
    msd: "POST" | "GET" | undefined
    constructor(options: CreateAxiosOptions) {
        this.options = options;
        this.axiosInstance = axios.create(options);
        this.setupInterceptors();
    }

    public post = () => {
        this.msd = "POST"
        return this
    }

    public get = () => {
        this.msd = "GET"
        return this
    }

    /**
     * @description:  创建axios实例
     */
    private createAxios(config: CreateAxiosOptions): void {
        this.axiosInstance = axios.create(config);
    }

    private getTransform() {
        const { transform } = this.options;
        return transform;
    }

    /**
     * @description: 重新配置axios
     */
    configAxios(config: CreateAxiosOptions) {
        if (!this.axiosInstance) {
            return;
        }
        this.createAxios(config);
    }
    /**
     * @description: 设置通用header
     */
    setHeader(headers: any): void {
        if (!this.axiosInstance) {
            return;
        }
        Object.assign(this.axiosInstance.defaults.headers, headers);
    }

    /**
     * @description: 拦截器配置
     */
    private setupInterceptors() {
        const transform = this.getTransform();
        if (!transform) {
            return;
        }
        const {
            requestInterceptors,
            requestInterceptorsCatch,
            responseInterceptors,
            responseInterceptorsCatch,
        } = transform;

        const axiosCanceler = new AxiosCanceler();

        // 请求拦截器配置处理
        this.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
            const { headers: { ignoreCancelToken } = { ignoreCancelToken: false } } = config;
            !ignoreCancelToken && axiosCanceler.addPending(config);
            if (requestInterceptors && isFunction(requestInterceptors)) {
                config = requestInterceptors(config);
            }
            return config;
        }, undefined);

        // 请求拦截器错误捕获
        requestInterceptorsCatch &&
            isFunction(requestInterceptorsCatch) &&
            this.axiosInstance.interceptors.request.use(undefined, requestInterceptorsCatch);

        // 响应结果拦截器处理
        this.axiosInstance.interceptors.response.use((res: AxiosResponse<any>) => {
            res && axiosCanceler.removePending(res.config);
            if (responseInterceptors && isFunction(responseInterceptors)) {
                res = responseInterceptors(res);
            }
            return res;
        }, undefined);

        // 响应结果拦截器错误捕获
        responseInterceptorsCatch &&
            isFunction(responseInterceptorsCatch) &&
            this.axiosInstance.interceptors.response.use(undefined, responseInterceptorsCatch);
    }

    /**
     * @description:  文件上传
     */
    uploadFile<T = any>(config: AxiosRequestConfig, params: UploadFileParams) {
        const formData = new window.FormData();

        if (params.data) {
            Object.keys(params.data).forEach((key) => {
                if (!params.data) return;
                const value = params.data[key];
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        formData.append(`${key}[]`, item);
                    });
                    return;
                }

                formData.append(key, params.data[key]);
            });
        }

        formData.append(params.name || 'file', params.file, params.filename);

        return this.axiosInstance.request<T>({
            ...config,
            method: 'POST',
            data: formData,
            headers: {
                'Content-type': 'multipart/form-data;charset=UTF-8',
                ignoreCancelToken: true,
            },
        });
    }

    /**
     * @description:   请求方法
     */
    request<T = any>(config: AxiosRequestConfig, options?: RequestOptions): Promise<T> {
        console.log(config)
        let conf: AxiosRequestConfig = cloneDeep(config);
        // delete conf.params._t
        // delete conf.data._t
        console.log('配置信息112')
        console.log(conf)
        const transform = this.getTransform();
       
        const { requestOptions } = this.options;
        const opt: RequestOptions = Object.assign({}, requestOptions, options);
        const { beforeRequestHook, requestCatch, transformRequestData } = transform || {};
        if (beforeRequestHook && isFunction(beforeRequestHook)) {
            if (this.msd) conf.method = this.msd
            conf = beforeRequestHook(conf, opt);
        }
      
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .request<any, AxiosResponse<Result>>(conf)
                .then((res: AxiosResponse<Result>) => {
                    // const _res=JSONBig.parse(res);
                    if (transformRequestData && isFunction(transformRequestData)) {
                        
                        const ret = transformRequestData(res, opt);
                        // console.log('正确哈哈哈')
                        // if(conf.url=='/login'){
                        //     reject("请确认账号密码是否正确,网络是否正常");
                        // }else{
                            ret !== '__requestError__' ? resolve(ret) : reject(new Error('request error!'));
//                        }
                        return;
                    }
                   
                    resolve((res as unknown) as Promise<T>);
                })
                .catch((e: Error) => {
                     console.log('错误哈哈',conf.url?.indexOf('password'))
                    if(conf.url?.indexOf('password')!=-1){
                        reject("请确认账号密码是否正确,网络是否正常");
                    }else{
                        if (requestCatch && isFunction(requestCatch)) {
                            reject(requestCatch(e));
                            return;
                        }
                        reject(e);
                    }
                });
        });
    }
}