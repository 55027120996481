
import { defineComponent, ref } from 'vue'
import { fourthgcommandList } from '@/api/Share/Device'
let datastream: any[] = [],
  order = 'desc',
  sort = 'creationtime',
  deviceid = ''
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const deviceSelect = ref('phoneholdersn')
    const deviceSearch = ref('')
    const date = ref('')
    const list = ref<any[]>([])
    const visible = ref(false)
    const page = ref(1)
    const total = ref(0)
    const limit = ref(10)
    const loading = ref(false)
    const btnSumitLoading = ref(false)
    const handleClose = () => {
      visible.value = false
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    }

    const reData = async (re?: boolean) => {
      try {
        if (re) page.value = 1
        loading.value = true
        const rqData: any = {
          page: page.value,
          limit: limit.value,
          deviceid,
          sort,
          order,
        }
        if (datastream.length > 0) rqData.datastream = datastream.join(',')
        if (deviceSelect.value && deviceSearch.value)
          rqData[deviceSelect.value] = deviceSearch.value
        if (date.value.length > 0) {
          rqData.datatimestampbegin = new Date(rqData.date[0]).getTime()
          rqData.datatimestampend = new Date(rqData.date[1]).getTime()
        }
        let { totalCount, result } = await fourthgcommandList(rqData)
        total.value = totalCount
        list.value = result
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    const open = (_deviceid: string) => {
      visible.value = true
      deviceid = _deviceid
      reData()
    }
    const filterChange = (e: any) => {
      datastream = e.datastream
      reData(true)
    }
    const sortChange = (e: {
      order: 'ascending' | 'descending' | undefined
      prop: string
    }) => {
      switch (e.order) {
        case 'ascending':
          order = 'asc'
          break
        case 'descending':
          order = 'desc'
          break
      }
      reData(true)
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      deviceSelect,
      deviceSearch,
      date,
      list,
      reData,
      filterChange,
      sortChange,
    }
  },
})
