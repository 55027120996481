
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { getShareProductInfo } from '@/api/util'
import {
  platformexpensewalletLoad,
  platformprofitwalletLoad,
  platformprofitwalletTransfer,
} from '@/api/Financial/PartfromWallet'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getSessionStorage } from '@/utils/localStore'
export default defineComponent({
  setup() {
    const myLoading = ref(false)
    const expenditure = ref(0)
    const incom = ref(0)
    const btnChangeLoading = ref(false)
    const walletsProductInfo = readonly(getShareProductInfo())
    const mixin_router_search = ref({
      articleproductid: walletsProductInfo[0].productid,
      fortest: false,
    })
    const reData = async (rq?: any) => {
      try {
        if (typeof rq.fortest == 'string') {
          // 暂时解决router传参boolean 类型变字符串问题
          mixin_router_search.value.fortest = JSON.parse(rq.fortest)
        }
        myLoading.value = true
        expenditure.value = (await platformexpensewalletLoad(rq)).amount
        incom.value = (await platformprofitwalletLoad(rq)).amount
      } finally {
        myLoading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        loading: myLoading,
        reData,
      })
    const handleChange = async () => {
      const res = await ElMessageBox.prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\w{5,17}$/,
        inputErrorMessage: '密码错误',
        inputType: 'password',
      })
      console.log('res :>> ', res)
      const user = getSessionStorage('user')
      if (res.value != user.password) return ElMessage.error('密码错误')
      const { value } = await ElMessageBox.prompt('请输入转账金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: `请输入大于0小于等于${incom.value}数字`,
        inputType: 'number',
        inputValidator: (value): boolean => {
          const _value = parseFloat(value)
          if (isNaN(_value)) return false
          return _value > 0 && _value <= incom.value
        },
      })
      await ElMessageBox.confirm(`请确认是否转账${value}元到支出钱包`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        distinguishCancelAndClose: true,
      })
      const amount = parseFloat(value)
      try {
        btnChangeLoading.value = true
        const res = await platformprofitwalletTransfer({
          ...mixin_router_search.value,
          amount,
        })
        ElMessage.success(res)
        mixinReRouter()
      } finally {
        btnChangeLoading.value = false
      }
    }
    return {
      mixin_router_search,
      changSearch,
      myLoading,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      walletsProductInfo,
      mixinReRouter,
      expenditure,
      incom,
      handleChange,
      btnChangeLoading,
    }
  },
})
