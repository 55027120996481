
import { computed, defineComponent, nextTick, ref, toRef } from 'vue'
import liquidFill from '@/components/echart/liquidFill'
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => '180px',
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  components: {
    'my-liquid-fill': liquidFill,
  },
  emits: ['change-pie-select'],
  setup(props, { emit }) {
    const chartData = toRef(props, 'chartData')
    const option = computed(() => {
      return {
        // dataset: {
        //   source: chartData.value,
        // },
        tooltip: {
          trigger: 'item',
        },
        color: [
          'rgb(36,106,173)',
          '#1976D2',
          'rgb(17,46,98)',
          '#00F4F4',
          '#005674',
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '80%',
            name: chartData.value.name,
            center: ['50%', '50%'],
            emphasis: {
              itemStyle: {
                opacity: 0.8,
              },
            },
            label: {
              formatter(options: any) {
                const { seriesName, value } = options
                console.log('opthions :>> ', options);
                return `${seriesName}\n\n￥${(value).toFixed(2)}`
              },
              fontSize: 18,
            },
            data: [0.42],
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 8,
                borderColor: 'rgb(0,224,226)',
                shadowBlur: 10,
                shadowColor: 'rgba(113, 243, 246, 1)',
              },
            },
          },
        ],
      }
    })
    const myLiquidFillRef = ref<any>(null)
    const reView = async () => {
      await nextTick()
      console.log('chartData.value :>> ', chartData.value)
      myLiquidFillRef.value.myRender()
    }

    return {
      option,
      myLiquidFillRef,
      reView,
    }
  },
})
