<template>
    <div class="container">
      <header>
        <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
          <template #prepend>
            <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
              <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="handelChange" icon="el-icon-search"></el-button>
          </template>
        </el-input>
      </header>
      <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
        <el-table-column label="SN" min-width="90">
          <template #default="scope">         
            {{scope.row.articleequipmentsn}}
            <my-copy :data="scope.row.articleequipmentsn" />
          </template>
        </el-table-column>
        <el-table-column label="设备类型" min-width="90" prop="productname">
        </el-table-column>
        <el-table-column label="手机号" min-width="110">
          <template #default="scope">
           {{scope.row.account?scope.row.account:'暂无'}}
          </template>
        </el-table-column>
        <el-table-column label="投资人" min-width="110">
          <template #default="scope">
           {{scope.row.investorname}}
          </template>
        </el-table-column>
        <el-table-column label="投资人昵称" min-width="140">
          <template #default="scope">
           {{scope.row.nickname?scope.row.nickname:'暂无'}}
          </template>
        </el-table-column>
        <el-table-column label="投资人id" min-width="140" prop="userid">
        </el-table-column>
        
       
      </el-table>
      <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, readonly, inject } from "vue";
  import routerChangeReData from "@/common/routerChangeReData";
  import {getM4sDevice,DeviceListRes,holdertype,} from "@/api/Share/Device";
  export default defineComponent({
    name: "Name",
    components: {
    },
    setup() {
      const loading = ref(false);
      const btnRemoveLoaidng = ref(false);
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const allHoldertype = holdertype;
      const list = ref<DeviceListRes[]>([]);
      const searchSelect = ref({
        search: "articleequipmentsn",
      });
      const mixin_select_search = ref({
        search: {
          articleequipmentsn: "",
          investorname: "",
          account:"",
          investoruserid:""
        },
      });
  
      const mixin_select_search_label = readonly({
        search: {
          articleequipmentsn: "设备SN",
          investorname: "投资人姓名",
          account: "手机号",
          investoruserid: "投资人ID"
        },
      });
  
      const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
  
      const mixin_router_search = ref({
        socketstatus: "",
        productname: "",
        privatized: "",
        lockcmd: "",
      });
      const reData = async (rq?: any) => {
        try {
          loading.value = true;
          let rqData: any = { current: page.value, size: limit.value };
          if (rq && JSON.stringify(rq) !== "{}") {
            rqData = { ...rqData, entity: rq };
          }
          const res = await getM4sDevice(rqData);
          total.value = res.total;
          list.value = res.records;
        } finally {
          loading.value = false;
        }
      };
      const {
        mixinReRouter,
        onPageChange,
        handleLimitChange,
        changSearch,
      } = routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      });
    
      const handelChange = () => {
        page.value = 1;
        mixinReRouter();
      };
     
  
  
      const exportFile = async(name:string) =>{
        // try {
        //   let searchData: any = {}
        //   let rqData: any = {fileName:'shareDevice'}
        //   let entity: any = {}
        //   searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
         
        //   console.log('导出参数=',searchData)
          
        //   Object.keys(searchData).forEach((key:any) => {
        //     if(searchData[key]){
        //       entity[key]=searchData[key]
        //     }
        //   })
        //   console.log('遍历之后=',entity)
          
        //   rqData = { ...rqData, entity: {...entity} }
  
        //   console.log('请求参数=',rqData)
         
  
        //   const res = await exportAnalyShareDeviceList(rqData)
        //   console.log('导表=',res.data)
        //   const blob = new Blob([res.data]);
        //   console.log('blob的值=',blob)
        //   saveAs(blob, name)
         
        // } finally {
         
        // }
      }
      return {
        mixin_select_search_label,
        mixin_select_search,
        mixin_router_search,
        searchSelect,
        list,
        loading,
        page,
        total,
        limit,
        handelChange,
        handleLimitChange,
        onPageChange,
        toPath_RouterSearchMixin,
        changSearch,
        allHoldertype,
        exportFile,
      };
    },
  });
  </script>
  <style lang="scss" scoped>
  .container {
    padding: 18px;
    .input-with-select {
      margin-right: 24px;
      width: 320px;
      /deep/.el-input {
        width: 120px;
      }
      ~ * {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
    .header-el-selector {
      width: 120px;
      margin-right: 12px;
       /deep/.el-input {
        width: 120px;
      }
    }
  
    .a-item{
      cursor: pointer;
      color:#409eff;
      padding-left: 4px;
      padding-right: 4px;
      height:32px;
      line-height:32px;
    }
  
    .el-table{
      /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
      }
    }
  }
  </style>