<template>
  <el-dialog :destroy-on-close="true" :title="`新增维修单`" @closed="handleClose" align="center" class="dialog"
    v-model="visible" width="750px" >
    <el-form :model="inputData" label-width="120px" ref="formRef" :rules="rules">
      <el-form-item label="设备SN" class="tip-text" prop="equipmentSn" >
        <el-input v-model="inputData.equipmentSn" style="width: 210px;">
        </el-input>
      </el-form-item>
      <el-form-item label="故障原因" class="tip-text" prop="reason" >
        <el-select  placeholder="请选择故障" v-model="inputData.reason">
          <el-option :key="item.identificationId" :label="item.title" :value="item.identificationId" v-for="item of list"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="撤回场地" class="tip-text" prop="revokePlace" >
        <el-input v-model="inputData.revokePlace" style="width: 320px;">
        </el-input>
      </el-form-item>
    </el-form>
  
    
    <!-- <el-form-item label="替换硬件">
    <el-select placeholder="请选择是否替换硬件" v-model="inputData.isRepalce">
        <el-option :value="true" label="未"></el-option>
        <el-option :value="false" label="直接投放"></el-option>
    </el-select>
    </el-form-item> -->
    <!-- <el-form-item label="上传图片">
      <el-upload action="uploadURL" :on-preview="handlePreview" :on-remove="handleRemove" :on-success="handleSuccess"
        list-type="picture" :headers="headerObj">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </el-form-item> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { createHttp } from '@/api/fault/repair'
import { faultModel, getFaultList } from '@/api/System/fault'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, readonly} from 'vue'
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
let overdueId = 0;
let startTime = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {

    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const list = ref<faultModel[]>([])
    const headerObj=ref<any>({})
    headerObj.value={ Authorization:userStore.info.token };

    const inputData = ref({
      equipmentSn: "",
      reason:'',
      revokePlace:'',
      isRepalce: false,
    })
    const rules = readonly({
      equipmentSn: [Rules.required()]
    });

    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async () => {
      await getFaultTemplate();
      visible.value = true
    }
    
    const getFaultTemplate = async () =>{
      
      let rqData: any = { current: 1, size: 20 }
      const res = await getFaultList(rqData)
      list.value = res.rows
       
    }

    const submit = async () => {
      try {
        btnSumitLoading.value = true

        const time = dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc = userStore.userInfo.account +"在"+time+ '新增维修单'
        const res = await createHttp({        
          equipmentSn: inputData.value.equipmentSn,
          faultDesc: desc,
          reason:inputData.value.reason,
          revokePlace:inputData.value.revokePlace
        })
        ElMessage.success(res)
        handleClose()
        
        emit('success')
        
      } finally {
        
        btnSumitLoading.value = false
      }
    }

    return {
      rules,
      headerObj,
      visible,
      handleClose,
      open,   
      list,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>