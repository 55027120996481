
import { defineComponent, nextTick, readonly, ref } from "vue";
import { userRoleList } from '@/api/User/role'
import { userList } from "@/api/User/index";
export default defineComponent({
  name: "v-dialog",
  emits:['success'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const inputData = ref({
      account: "", 
      name: "",
      userid: ""
    });
   
    const handleClose = async () => {  
      await nextTick();
      visible.value = false;

    };
    const open = async (row: any) => {
      inputData.value.userid=row.holderuserid;
      if(row.holdertype=='consumer'){
        await getNormalUser(row.holderuserid)
      }else {
        await getOtherUser(row.holderuserid,row.holdertype)
      }
      visible.value = true;
    };
    const getNormalUser = async (userid: string) => { 
      let rqData: any = { page: 1, limit: 10, userid: userid};
      const res = await userList(rqData);
      const list = res.result;
      const item=ref<any>();
      item.value=list[0];
      inputData.value.account=item.value.account;
      inputData.value.name=item.value.nickname;
    };
    const getOtherUser = async (userid: string,type:string) => {
      let rqData: any = { page: 1, limit: 10 }
      rqData[`${type}userid`] = userid
      const res = await userRoleList(rqData, type)
      const list = res.result;
      const item=ref<any>();
      item.value=list[0];
      inputData.value.account=item.value[`${type}account`]||item.value.account;
      inputData.value.name=item.value[`${type}name`]||item.value.name;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit
    };
  },
});
