import { hotModuleUnregisterModule } from "@/utils/helper/vuexHelper"
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { getLocalStorage, setLocalStorage } from '@/utils/localStore'
import store from "@/store"
import { emitter } from "@/utils/myMitt";
const NAME = 'app';
hotModuleUnregisterModule(NAME);
export interface sidebarType {
    opened: boolean
    withoutAnimation: boolean
}
@Module({ namespaced: true, name: NAME, dynamic: true, store })
class App extends VuexModule {
    public loading = false
    public sidebar: sidebarType = {
        opened: getLocalStorage('sidebarStatus') ? !!+getLocalStorage('sidebarStatus') : true, // 边栏是否展开
        withoutAnimation: false  // 是否显示动画
    }
    public device = 'desktop'  //  desktop 和 mobile
    public size = getLocalStorage('size') || 'medium'

    /**
     * 是否加载中
     */
    public get getLoading(): boolean {
        return this.loading
    }
    /**
     * 获取浏览设备类型，是桌面还是手机
     */
    public get getDevice(): string {
        return this.device
    }
    /**
     * 浏览的是否为手机
     */
    public get isMobile(): boolean {
        return this.device == 'mobile'
    }

    /**
     * 获取页面大小参数
     */
    public get getSize(): string {
        return this.size
    }

    /**
     * 获取边栏
     */
    public get getSidebar() {
        return this.sidebar
    }


    /**
     * 
     * @param is 切换加载
     */
    @Mutation
    toggleLoading(is?: boolean) {
        if (is == undefined) {
            this.loading = !this.loading
        } else {
            this.loading = is
        }
    }

    /**
     * 切换边栏
     */
    @Mutation
    TOGGLE_SIDEBAR() {
        this.sidebar = Object.assign({}, this.sidebar, {
            opened: !this.sidebar.opened,
            withoutAnimation: false
        })
        setLocalStorage('sidebarStatus', this.sidebar.opened ? 1 : 0)
        emitter.emit("resize")
    }

    /**
     * 
     * @param withoutAnimation 关闭边栏
     */
    @Mutation
    CLOSE_SIDEBAR(withoutAnimation: boolean) {
        setLocalStorage('sidebarStatus', 0)
        this.sidebar = Object.assign({}, this.sidebar, {
            opened: false,
            withoutAnimation
        })
    }

    /**
     * 
     * @param size 设置边栏大小
     */
    @Mutation
    SET_SIZE(size: string) {
        this.size = size
        setLocalStorage('size', size)
    }

    /**
     * 
     * @param device 切换浏览设备类型
     */
    @Mutation
    TOGGLE_DEVICE(device: string) {
        this.device = device
    }

    /**
     * 切换边栏方法
     */
    @Action
    toggleSideBar() {
        this.TOGGLE_SIDEBAR()
    }

    /**
     * 
     * @param param0 关闭边栏
     */
    @Action
    closeSideBar({ withoutAnimation }: { withoutAnimation: boolean }) {
        this.CLOSE_SIDEBAR(withoutAnimation)
    }

    /**
     * 
     * @param device 切换浏览设备方法
     */
    @Action
    toggleDevice(device: string) {
        this.TOGGLE_DEVICE(device)
    }

    /**
     * 
     * @param size 设置边栏大小方法
     */
    @Action
    setSize(size: string) {
        this.SET_SIZE(size)
    }
}

export const appStore = getModule<App>(App);