<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list" :default-sort="{prop: 'lastlogindate', order: 'descending'}" @sort-change="mixinReRouterTableSortChange">
      <el-table-column align="left" label="账号" min-width="40" >
         <template #default="scope">
          {{scope.row.account}}<my-copy :data="scope.row.account"></my-copy>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="ID" min-width="60">
        <template #default="scope">         
          {{scope.row.userid}}<my-copy :data="scope.row.userid"></my-copy>
        </template>
      </el-table-column>
      <el-table-column align="center" label="昵称" min-width="40" prop="nickname"></el-table-column>
      <el-table-column align="center" label="最后登录" min-width="50" prop="lastlogindate" ></el-table-column>
      <!-- <el-table-column align="center" label="最后登录" min-width="60" prop="lastlogindate" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column> -->
      <el-table-column align="center" label="操作" min-width="70" >
        <template #default="scope">
          <el-button @click="onRepalcePhoneClick(scope.row.userid,scope.row.account,scope.row.nickname)" size="mini" type="danger">更换账号</el-button>
          <el-button @click="onWalletHistoryClick(scope.row.userid,scope.row.account)" size="mini" type="info">钱包流水</el-button>
          <el-button @click="onWalletDetailClick(scope.row.userid,scope.row.account)" size="mini" type="primary">费用记录</el-button>
          <el-button @click="onWalletPointClick(scope.row.userid,scope.row.account)" size="mini" type="primary">积分记录</el-button>
          <el-button @click="toPath(`/user/userInfo?userid=${scope.row.userid}&vip=${scope.row.isvip}`)" size="mini" type="primary">个人详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    <walletHistoryDialog ref="walletHistoryDialogRef"></walletHistoryDialog>
    <detailsDialog  ref="walletDetailDialogRef"></detailsDialog>
    <pointDialog ref="pointDialogRef"></pointDialog>
    <replacePhoneDialog ref="replacePhoneDialogRef" @success="success"></replacePhoneDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import walletHistoryDialog from "@/components/wallet/walletHistoryDialog.vue";
import detailsDialog from "@/components/wallet/detailsDialog.vue";
import pointDialog from "@/components/wallet/pointDialog.vue";
import replacePhoneDialog from "./components/replacePhoneDialog.vue";
import { userList } from "@/api/User/index";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Name",
  components: {
    walletHistoryDialog,
    detailsDialog,
    pointDialog,
    replacePhoneDialog,
  },
  setup() {
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<any>([]);
    const walletHistoryDialogRef = ref<any>();
    const walletDetailDialogRef = ref<any>();
    const pointDialogRef = ref<any>();
    const replacePhoneDialogRef = ref<any>();
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      status: "",
      // order: "lastlogindate",
      // sort: "desc",
    });
    const router = useRouter();
    const mixin_select_search = ref({
      search: {
        nickname: "",
        account: "",
        userid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        nickname: "昵称",
        account: "账号",
        userid: "ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { page: page.value, limit: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, ...rq };
        }
        const res = await userList(rqData);
        total.value = res.totalCount;
        list.value = res.result;
      } finally {
        loading.value = false;
      }
    };
    /**
     * 钱包流水
     */
    const onWalletHistoryClick = (userid :string,account:string) => {
      walletHistoryDialogRef.value.open(userid,account)
    }
    /**
     * 钱包明细
     */
    const onWalletDetailClick = (userid :string,account:string) =>{
      walletDetailDialogRef.value.open(userid,account)
    }
    /**
     * 积分记录
     */
    const onWalletPointClick = (userid :string,account:string) => {
      pointDialogRef.value.open(userid,account)
    }
    /**
     * 更换手机号
     */
    const onRepalcePhoneClick = (userid :string,account:string,name:string) =>{
      replacePhoneDialogRef.value.open(userid,account,name)
    }

    const success = async() =>{
      handelChange();
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
      mixinReRouterTableSortChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };

    const toPath = (path: string) => {
      router.push(path);
    };

    const roleHiddenPermission = inject("roleHiddenPermission");
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      walletHistoryDialogRef,
      walletDetailDialogRef,
      pointDialogRef,
      handelChange,
      handleLimitChange,
      onPageChange,
      onWalletHistoryClick,
      onWalletDetailClick,
      roleHiddenPermission,
      toPath,
      changSearch,
      success,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      onWalletPointClick,
      onRepalcePhoneClick,
      replacePhoneDialogRef,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>