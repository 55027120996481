<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" placeholder="租赁数量是否测试" class="header-el-selector" v-model="mixin_router_search.fortest">
        <el-option :value="true" label="测试"></el-option>
        <el-option :value="false" label="正式"></el-option>
      </el-select>
      <el-select @change="handelChange" placeholder="在线离线状态" class="header-el-selector" v-model="mixin_router_search.online">
        <el-option label="在线" value="1"></el-option>
        <el-option label="离线" value="0"></el-option>
      </el-select>
      <el-select @change="handelChange" placeholder="场地开启状态" class="header-el-selector" v-model="mixin_router_search.enabled">
        <el-option :value="true" label="场地已开启"></el-option>
        <el-option :value="false" label="场地已关闭"></el-option>
      </el-select>
      <el-select @change="handelChange" placeholder="故障状态" class="header-el-selector" v-model="mixin_router_search.fault">
        <el-option :value="true" label="有故障"></el-option>
        <el-option :value="false" label="正常"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.selectProduct" value-key="productid">
        <el-option :key="item.productid" :label="item.name" :value="item" v-for="item of panelInfo"></el-option>
      </el-select>
      <!-- <my-select-city :maxLevel="4" @cityCodeChange="cityCodeChange"></my-select-city> -->
      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="归还日期"
        format="YYYY年M月D日 HH:mm:ss"
        range-separator="至"
        start-placeholder="归还日期"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.returnTime"
        value-format="YYYY-MM-DD HH:mm:ss"
      ></el-date-picker>
      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="租赁日期"
        format="YYYY年M月D日 HH:mm:ss"
        range-separator="至"
        start-placeholder="租赁日期"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.rentTime"
        value-format="YYYY-MM-DD HH:mm:ss"
      ></el-date-picker>
    </header>
    <!-- :default-sort="{prop:mixin_router_search.orderby , order: 'descending'}" -->
    <el-table :data="list" @sort-change="mixinReRouterTableSortChange" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="场所ID" v-if="scope.row.placeid">
              <my-click-data-to :data="scope.row.placeid" :params="[{search_placeid:scope.row.placeid}]" url="/share/place"></my-click-data-to>
              <my-copy :data="scope.row.placeid" />
            </el-form-item>
            <el-form-item label="详细地址">
              {{scope.row.address}}
              <my-copy :data="scope.row.address" />
            </el-form-item>
            <el-form-item label="deviceid" v-if="scope.row.deviceid">
              {{scope.row.deviceid}}
              <my-copy :data="scope.row.deviceid" />
            </el-form-item>
            <el-form-item label="在线时间" v-if="scope.row.online">{{scope.row.lastonlineDate}}</el-form-item>
            <el-form-item label="离线时间" v-else>{{scope.row.lastofflineDate}}</el-form-item>
            <el-form-item label="其它操作">
              <el-button :disabled="!scope.row.deviceid" @click="handleSync(scope.row.deviceid)" size="mini" type="primary">同步机柜信息</el-button>
              <el-button :disabled="!scope.row.deviceid" @click="handleFourthgmsg(scope.row.deviceid)" size="mini" type="primary">机柜上报消息</el-button>
              <el-button :disabled="!scope.row.deviceid" @click="handleFourthgcommand(scope.row.deviceid)" size="mini" type="primary">机柜下发消息</el-button>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="35">
        <template #default="scope">
          <my-copy :data="scope.row.panelequipmentsn" />
          {{scope.row.panelequipmentsn}}
          <hover-show-qr :key="scope.row.panelequipmentsn" :text="scope.row.panelequipmentsn"></hover-show-qr>
        </template>
      </el-table-column>
      <el-table-column label="MAC" min-width="35">
        <template #default="scope">
          {{scope.row.mac}}
          <my-copy :data="scope.row.mac" />
        </template>
      </el-table-column>
      <el-table-column label="设备类型" min-width="30">
        <template #default="scope">
          <span>{{scope.row.productname}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="小魔夹数量" min-width="40">
        <template #header>
          <!-- <el-tag type="success">有</el-tag> -->
          <el-tag @click="changeSort('normalnum')" type="success">
            <span class="flex_cc">
              <span>有</span>
              <span class="sort-icon flex_cl_c" v-if="mixin_router_search.orderby=='normalnum'">
                <i :class="{asc:mixin_router_search.sort=='asc'}" class="icon el-icon-caret-top"></i>
                <i :class="{desc:mixin_router_search.sort=='desc'}" class="icon el-icon-caret-bottom"></i>
              </span>
            </span>
          </el-tag>
          <el-tag @click="changeSort('faultnum')" type="danger">
            <span class="flex_cc">
              <span>故障</span>
              <span class="sort-icon flex_cl_c" v-if="mixin_router_search.orderby=='faultnum'">
                <i :class="{asc:mixin_router_search.sort=='asc'}" class="icon el-icon-caret-top"></i>
                <i :class="{desc:mixin_router_search.sort=='desc'}" class="icon el-icon-caret-bottom"></i>
              </span>
            </span>
          </el-tag>
          <el-tag @click="changeSort('emptynum')" type="info">
            <span class="flex_cc">
              <span>空</span>
              <span class="sort-icon flex_cl_c" v-if="mixin_router_search.orderby=='emptynum'">
                <i :class="{asc:mixin_router_search.sort=='asc'}" class="icon el-icon-caret-top"></i>
                <i :class="{desc:mixin_router_search.sort=='desc'}" class="icon el-icon-caret-bottom"></i>
              </span>
            </span>
          </el-tag>
        </template>
        <template #default="scope">
          <!-- <el-tag type="success">{{scope.row.num||0}}</el-tag> -->
          <el-tag type="success">{{scope.row.normalnum||0}}</el-tag>
          <el-tag type="danger">{{scope.row.faultnum||0}}</el-tag>
          <el-tag type="info">{{scope.row.emptynum||0}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="店名" min-width="40" prop="name"></el-table-column>
      <el-table-column label="联系人" min-width="20" prop="contactname"></el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="left" label="租赁" min-width="30" prop="borrownum" sortable="custom"></el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="left" label="归还" min-width="30" prop="returnnum" sortable="custom"></el-table-column>
      <el-table-column label="状态" min-width="50">
        <template #header>
          <span v-if="mixin_router_search.online==''">状态</span>
          <span @click="changeSort('offlineTime')" class="flex_cc" v-else>
            <span>状态</span>
            <span class="sort-icon flex_cl_c" v-if="mixin_router_search.orderby=='offlineTime'">
              <i :class="{asc:mixin_router_search.sort=='asc'}" class="icon el-icon-caret-top"></i>
              <i :class="{desc:mixin_router_search.sort=='desc'}" class="icon el-icon-caret-bottom"></i>
            </span>
          </span>
        </template>
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.online">{{scope.row.onlineLongText}} 在线</el-tag>
          <el-tag type="danger" v-else>{{scope.row.lastofflineText}} 离线</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="维修员ID" min-width="44" prop="local"></el-table-column> -->
      <el-table-column align="center" label="操作" min-width="40">
        <template #header>
          <el-button @click="handleExport(scope)" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button @click="handleShowDevice(scope.row.panelequipmentsn)" size="mini">机柜内设备</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
  <v-device-dialog ref="deviceDialogRef"></v-device-dialog>
  <v-components-dialog ref="componentsDialogRef"></v-components-dialog>
  <fourthgmsg-dialog ref="fourthgmsgDialogRef"></fourthgmsg-dialog>
  <fourthgcommand-dialog ref="fourthgcommandDialogRef"></fourthgcommand-dialog>
  <add-dialog ref="addDialogRef"></add-dialog>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import fourthgmsgDialog from '../components/fourthgmsgDialog.vue'
import fourthgcommandDialog from '../components/fourthgcommandDialog.vue'
import {
  getContainInfoList,
  GetContainInfoList,
  removePanel,
  getcrc,
} from '@/api/Share/Panel'
import deviceDialog from '../panel/components/deviceDialog.vue'
import componentsDialog from '../panel/components/componentsDialog.vue'
import addDialog from '../panel/components/addDialog.vue'
import { ElMessage } from 'element-plus'
import { getPanelProductInfo } from '@/api/util'
import myExport from '@/utils/exprotExcel'
import mySelectCity from '@/components/cityList/index.vue'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'Name',
  components: {
    'v-device-dialog': deviceDialog,
    'v-components-dialog': componentsDialog,
    fourthgmsgDialog,
    fourthgcommandDialog,
    addDialog,
    mySelectCity,
  },
  setup() {
    const loading = ref(false)
    const syncloading = ref(false)
    const areaCode = ref('')
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<GetContainInfoList[]>([])
    const addDialogRef = ref<any>()
    const fourthgmsgDialogRef = ref<any>()
    const deviceDialogRef = ref<any>()
    const componentsDialogRef = ref<any>()
    const fourthgcommandDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const panelInfo = getPanelProductInfo()
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '',
        mac: '',
        deviceid: '',
        name: '',
        contactname: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: 'SN',
        mac: 'MAC',
        name: '店名',
        deviceid: 'deviceid',
        contactname: '联系人',
      },
    })
    const mixin_router_search = ref({
      status: '',
      selectProduct: {},
      fortest: false,
      online: '',
      orderby: '',
      sort: 'desc',
      fault: '',
      returnTime: '',
      enabled: true,
      rentTime: '',
    })
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { currentPage: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.selectProduct) {
            rqData.productid = rq.selectProduct.productid
            delete rq.selectProduct
          }
          if (rq.order) {
            const { orderby, sort } = rq
            delete rq.orderby
            delete rq.sort
            rqData = { ...rqData, orderby, sort }
          }
          if (rq.returnTime) {
            rq.returnBeginTime = rq.returnTime[0]
            rq.returnEndTime = rq.returnTime[1]
            delete rq.returnTime
          }
          if (rq.rentTime) {
            rq.rentBeginTime = rq.rentTime[0]
            rq.rentEndTime = rq.rentTime[1]
            delete rq.rentTime
          }
          if (areaCode.value) {
            rq = { ...rq, ...switchCode(areaCode.value) }
          }
          rqData = { ...rqData, ...rq }
        }
        const res = await getContainInfoList(rqData)
        total.value = res.total
        list.value = res.list
      } finally {
        loading.value = false
      }
    }

    const switchCode = (code: string) => {
      const rq: any = {}
      switch (code.length) {
        case 2:
          rq.provinceareacode = code
          break
        case 4:
          rq.cityareacode = code
          break
        case 6:
          rq.districtareacode = code
          break
        case 9:
          rq.streetareacode = code
          break
      }
      return rq
    }

    const cityCodeChange = (code: string) => {
      areaCode.value = code
      page.value = 1
      mixinReRouter()
    }

    const handleExport = async () => {
      const excel = new myExport('小魔柜列表1')
      const _list = list.value.map((v) => {
        const _data = {
          机柜SN: v.panelequipmentsn,
          商户名: v.name,
          正常数: v.num,
          故障数: v.faultnum,
          空仓口: v.emptynum,
          租借次数: v.borrownum,
          当前位置: v.address,
          // 发布状态: v.ownership == 'public' ? '已发布' : '未发布',
          离线状态: v.online ? '在线' : '离线',
          机柜名称: v.productname,
          // 维修员: v.repairmanname,
          在线时间: '无',
          在线时长: '无',
          '在线时长（毫秒）': 0,
          离线时间: '无',
          离线时长: '无',
          '离线时长（毫秒）': 0,
        }
        if (v.online) {
          _data.在线时间 = v.lastonlineDate
          _data.在线时长 = v.onlineLongText
          _data['在线时长（毫秒）'] = v.onlineLongtime
        } else {
          _data.离线时间 = v.lastofflineDate
          _data.离线时长 = v.lastofflineText
          _data['离线时长（毫秒）'] = v.offlineLongtime
        }
        return _data
      })
      excel.addWorksheet({ headers: ['小魔柜列表'], list: _list })
      excel.export()
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
      mixinReRouterTableSortChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const addSuccess = () => {
      reData()
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removePanel({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    const handleShowDevice = (sn: string) => {
      deviceDialogRef.value.open(sn)
    }
    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }
    const handleSync = async (deviceid: string) => {
      try {
        syncloading.value = true
        await getcrc({ deviceid })
      } finally {
        syncloading.value = false
      }
      reData()
    }
    const changeSort = (type: string) => {
      if (type == mixin_router_search.value.orderby) {
        mixin_router_search.value.sort =
          mixin_router_search.value.sort == 'desc' ? 'asc' : 'desc'
      } else {
        mixin_router_search.value.orderby = type
        mixin_router_search.value.sort = 'asc'
      }
      mixinReRouter()
    }
    const handleFourthgmsg = (deviceid: string) => {
      fourthgmsgDialogRef.value.open(deviceid)
    }
    const handleFourthgcommand = (deviceid: string) => {
      fourthgcommandDialogRef.value.open(deviceid)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleRemove,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      handleShowDevice,
      deviceDialogRef,
      componentsDialogRef,
      handleSync,
      handleShowComponenet,
      changSearch,
      panelInfo,
      mixinReRouterTableSortChange,
      changeSort,
      handleExport,
      handleFourthgmsg,
      fourthgmsgDialogRef,
      fourthgcommandDialogRef,
      handleFourthgcommand,
      handleAdd,
      cityCodeChange
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .sort-icon {
    i {
      line-height: 6px;
      font-size: 14px;
    }
    .asc,
    .desc {
      color: #409eff;
    }
  }

 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>