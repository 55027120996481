
import { tweetAdd, tweetSet } from '@/api/System/tweet'
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      content: [Rules.required()],
    //   url: [Rules.required()],
      enabletime: [Rules.required()],
      expiretime: [Rules.required()],
    }
    const form = ref({
      content: '',
      url: '',
      enabletime: '',
      expiretime: '',
      tweetid: '',
    })
    const open = (row: any) => {
      console.log('row :>> ', row)
      visible.value = true
      if (row) form.value = row
    }

    const submit = async () => {
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res = form.value.tweetid
          ? await tweetSet(form)
          : await tweetAdd(form)
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
    }
  },
})
