<template>
  <el-dialog :destroy-on-close="true" :title="`编辑通知`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="form" :rules="rules" label-width="100px" ref="formRef">
      <el-form-item label="内容" prop="content">
        <el-input placeholder="请输入公告内容" v-model="form.content"></el-input>
      </el-form-item>
      <el-form-item label="链接">
        <el-input placeholder="跳转页面链接" v-model="form.url"></el-input>
      </el-form-item>
      <el-form-item label="启用时间" prop="enabletime">
        <el-date-picker :picker-options="pickerOptions" align="right" placeholder="选择日期" type="date" v-model="form.enabletime"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="expiretime">
        <el-date-picker :picker-options="pickerOptions" align="right" placeholder="选择日期" type="date" v-model="form.expiretime"></el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { tweetAdd, tweetSet } from '@/api/System/tweet'
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      content: [Rules.required()],
    //   url: [Rules.required()],
      enabletime: [Rules.required()],
      expiretime: [Rules.required()],
    }
    const form = ref({
      content: '',
      url: '',
      enabletime: '',
      expiretime: '',
      tweetid: '',
    })
    const open = (row: any) => {
      console.log('row :>> ', row)
      visible.value = true
      if (row) form.value = row
    }

    const submit = async () => {
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res = form.value.tweetid
          ? await tweetSet(form)
          : await tweetAdd(form)
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>