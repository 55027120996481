
import { defineComponent, ref, readonly, nextTick,inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { userRoleList,exportInvestUser } from '@/api/User/role'
import testWalletRechangeDialog from './components/testWalletRechangeDialog.vue'
import addInvestor from './components/addInvestorDialog.vue'
import couponVue from "@/components/coupon/index.vue";
import exportDialog from "@/components/export/exportDialog.vue";
import roleRelationDialog from "./components/roleRelationDialog.vue"
import { useRouter } from "vue-router";
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-investor-dialog': addInvestor,
    testWalletRechangeDialog,
    couponVue,
    exportDialog,
    roleRelationDialog,
  },
  setup() {
    const router = useRouter();
    const toPath = (path: string) => {
      router.push(path);
    };
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const addDialogRef = ref<any>()
    const date = ref("");
    const list = ref<any>([])
    const couponRef = ref<any>("");
    const testWalletRechangeDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const roleRelationDialogRef = ref<any>();
    const searchSelect = ref({ search: 'name' })
    const mixin_router_search = ref({ status: '', type: 'investor',  careateBN: '', investortype:'',allowlatter:''})
    const mixin_select_search = ref({
      search: {
        name: '',
        account: '',
        userid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
        account: '账号',
        userid: 'ID',
      },
    })
    const reData = async (rq?: any) => {
      await nextTick()
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.name) {
            rq[`${mixin_router_search.value.type}name`] = rq.name
            delete rq.name
          }
          if (rq.account) {
            rq[`${mixin_router_search.value.type}account`] = rq.account
            delete rq.account
          }
          if (rq.userid) {
            rq[`${mixin_router_search.value.type}userid`] = rq.userid
            delete rq.userid
          }
          if (rq.careateBN) {
            rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
            rq.endTime =  dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
            delete rq.careateBN
          }
          rqData = { ...rqData, ...rq }

        }
        const res = await userRoleList(rqData, mixin_router_search.value.type)
        total.value = res.totalCount
        list.value = res.result
      } finally {
        loading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleActivate = () => {
      addDialogRef.value.open(mixin_router_search.value.type)
    }
    const handleRechange = (row: any) => {
      testWalletRechangeDialogRef.value.open(row)
    }
    const handleCoupon = (userid:string,name:string) =>{
      couponRef.value.open(userid,name)
    }
    const handleRoleRelation = (userid:string,phone:string,name:string) =>{
      roleRelationDialogRef.value.open(userid,phone,name)
    }
    const handleExport = async() => {
      exportDialogRef.value.open('普通会员表')
    }

    const exportFile = async(name:string) =>{
      console.log("导出名称=",name);
      try {
        let searchData: any = {}
        let rqData: any = {type: 'investor',fileName:'investorUser'}

        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          rqData.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            if(key=='name'||key=='account'||key=='userid'){
              rqData[`${mixin_router_search.value.type}${key}`]=searchData[key]
            }else {
              rqData[key]=searchData[key]
            }
          }
        })
  
        console.log('请求参数=',rqData)
        
        const res = await exportInvestUser(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
        
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      date,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleCoupon,
      handleActivate,
      addDialogRef,
      roleRelationDialogRef,
      changSearch,
      handleRechange,
      toPath_RouterSearchMixin,
      testWalletRechangeDialogRef,
      toPath,
      couponRef,
      handleExport,
      exportDialogRef,
      exportFile,
      handleRoleRelation,
    }
  },
})
