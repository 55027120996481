<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-select" clearable placeholder="是否测试" v-model="mixin_router_search.fortest">
        <el-option :value="true" label="测试收益"></el-option>
        <el-option :value="false" label="正式收益"></el-option>
      </el-select> 
            
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="用户id">
              <span>{{ scope.row.userid }}</span>
              <my-copy :data="scope.row.userid" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="账号" align="center" min-width="55">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          {{scope.row.account}}
          <my-copy :data="scope.row.account"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="昵称" align="center" min-width="56">
        <template #default="scope">
          {{scope.row.nickname}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="总收益" min-width="44">
        <template #default="scope">
            <el-tag type="success">￥ {{scope.row.total}}</el-tag>
        </template>
      </el-table-column>
     <el-table-column label="净利润额" align="center" min-width="44">
        <template #default="scope">
            <el-tag type="info">￥ {{scope.row.totalnetprofitamount}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="总纳税额" align="center" min-width="44">
        <template #default="scope">
            <el-tag type="warning">￥ {{scope.row.totaltaxamount}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="收益角色" min-width="44">
        <template #default="scope" >
           <span v-if="scope.row.gainerrole=='investor'">投资人</span> 
           <span v-else-if="scope.row.gainerrole=='agent'">代理人</span> 
           <span v-else-if="scope.row.gainerrole=='superior'">一级收益</span> 
           <span v-else-if="scope.row.gainerrole=='presuper'">二级收益</span> 
           <span v-else-if="scope.row.gainerrole=='partner'">城市合伙人</span> 
           <span v-else-if="scope.row.gainerrole=='platform'">平台</span> 
           <span v-else-if="scope.row.gainerrole=='partner_expand'">城市合伙人</span> 
           <span v-else >其他</span> 
        </template>
      </el-table-column>
      <el-table-column label="收益类型" align="center" min-width="44">
        <template #default="scope">{{scope.row.gainertype=="individual"?"个人收益":"公司收益"}}</template>
      </el-table-column>
      <el-table-column label="收益比" align="center" min-width="44">
        <template #default="scope">{{scope.row.proportion}}%</template>
      </el-table-column>      
      <el-table-column align="center" label="操作" min-width="70">
        <template #default="scope">
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { totalProfitModel, totalProfitOfUserRoleHttp} from '@/api/profitStatistics/totalProfitUserRole'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getShareProductInfo, getUserRoleList} from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'in-out',
  components: {


  },
  setup() {
    const sheareProductInfo = getShareProductInfo()  
    const roleList = getUserRoleList();

    const mixin_router_search = ref({
      fortest: false, //是否测试
      gainerrole:'', //用户角色
    })

    const mixin_select_search = ref({
      search: {
        account:'',//用户账号    
        userid:'', //用户id
        
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',    
        userid:'用户id',  
      },
    })

   

    const searchSelect = ref({
      search: 'account',
    })

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<totalProfitModel[]>([])

    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    
    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { currentPage: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, ...rq }
        }
        const res = await totalProfitOfUserRoleHttp(rqData)
        console.log('返回数据',res)
        list.value = res.data.records
        total.value = res.data.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      changSearch,
      searchSelect,
      list,
      loading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      roleList,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>