
import NavMenu from './components/NavMenu/index.vue'  //侧边导航栏
import Breadcrumb from './components/Breadcrumb/index.vue' //导航路由显示
import TagsView from './components/TagsView/index.vue' //标签控件
import UserInfo from './components/header.vue'  //用户信息控件
import { defineComponent, computed, watch, ref, onMounted } from 'vue'
import { appStore } from '@/store/modules/app'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'v-layout',
  setup() {
    const isShow = ref(false)
    const getLoading = computed(() => appStore.getLoading) //获取加载 没有使用
    const device = computed(() => appStore.getDevice)   //获取浏览的设备
    const sidebar = computed(() => appStore.getSidebar)  //获取边栏
    const route = useRoute()
    const key = computed(() => route.path)  //获取导航对应的页面
    const showTagsView = computed(() => false)
    const checkPath = () => {
      if (route.path == '/home') {
        isShow.value = typeof route.name == 'string' ? ['adminIndex', 'platformIndex'].indexOf(route.name) > -1 : false
      } else {
        isShow.value = false
      }
    }
    watch(
      () => route.path,
      (v, oldV) => {
        if (v === oldV) return
        checkPath()
      }
    )
    onMounted(() => {
      checkPath()
    })
    const toggleMenu = () => {
      appStore.toggleSideBar()
    }
    const handleClickOutside = () => {
      appStore.toggleSideBar()
    }
    return {
      isShow,
      getLoading,
      device,
      sidebar,
      key,
      toggleMenu,
      handleClickOutside,
      showTagsView,
    }
  },
  components: {
    'v-nav-menu': NavMenu,
    'v-breadcrumb': Breadcrumb,
    'v-user-info': UserInfo,
    'tags-view': TagsView,
  },
})
