<template>
  <el-dialog :destroy-on-close="true" :title="`激活用户${type}角色`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">

    <div class="box" v-if="!activeType">
      <div class="lbox" @click="changeType({type:'platform',selectClass:'',label:''})">平台直签</div>
      <div class="cbox" @click="changeType({type:'agent',selectClass:'agentaccount',label:'代理人账号'})">代理人介绍</div>
      <div class="rbox" @click="changeType({type:'superior',selectClass:'superioraccount',label:'上线投资人账号'})">上线投资人介绍</div>
    </div>
    <el-form v-else :model="inputData" :rules="rules" label-width="100px" ref="inputRef">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="inputData.name"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input v-model="inputData.account"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, toRef } from "vue";
import { activateRole } from "@/api/User/role";
import Rules from "@/common/Rules";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  props: ["type"],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const type = toRef(props, "type");
    const activeType = ref("");
    const inputRef = ref<any>("");
    const inputData = ref({
      account: "",
      name: "",
    });
    const handleClose = () => {
      visible.value = false;
      inputRef.value.resetFields();
      inputRef.value.clearValidate();
    };
    const open = () => {
      visible.value = true;
    };
    const submit = async () => {
      try {
        try {
          await inputRef.value.validate();
        } catch (error) {
          console.log(error);
          throw Error("请检查输入");
        }
        btnSumitLoading.value = true;
        const _data: any = {};
        _data[`${type.value}account`] = inputData.value.account.trim();
        _data[`${type.value}name`] = inputData.value.name.trim();
        const result = await activateRole(_data, type.value);
        ElMessage.success(result);
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      inputRef,
      activeType,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>