<template>
  <div class="container">
    <el-alert
      title="1.正式和测试筛选，针对投资小魔夹，钱包余额，总收益，总奖励, 总消费有效"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>  
    <header style="margin-top: 12px;">
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="是否为正式" class="header-el-selector" v-model="mixin_router_search.fortest">
        <el-option label="正式" :value="false"></el-option>
        <el-option label="测试" :value="true"></el-option>
      </el-select>
     <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="结束时间"
        range-separator="至"
        start-placeholder="开始时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.timeBN"
      ></el-date-picker>
    </header>
    <el-table 
      :data="list"
      height="80vh"
      style="width: 100%" 
      v-if="list" 
      :row-style="{height: '72px'}">
      <el-table-column type="expand">
        <template #default="props">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="ID">
              {{props.row.userid}}
              <my-copy :data="props.row.userid"></my-copy>
              <!-- <el-button type="text" @click="toPath_RouterSearchMixin('/user',[{search_userid:props.row.userid}])">{{ props.row.userid }}</el-button> -->
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="账号" min-width="40">
        <template #default="props">
          <!-- <el-button type="text" @click="toPath_RouterSearchMixin('/user',[{search_account:props.row.account}])">{{ props.row.account }}</el-button> -->
          <my-copy :data="props.row.account"></my-copy>
          {{props.row.account}}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="40" prop="name"> </el-table-column>
      <el-table-column align="center" label="投资小魔夹" min-width="40" prop="xmjtotal" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="已下单购买小魔夹的数量" placement="top">
            <span>{{ props.row.xmjtotal ?props.row.xmjtotal:"0"}}</span>
          </el-tooltip>          
         </template>
      </el-table-column>
      <el-table-column align="center" label="共享小魔夹" min-width="40" prop="sharexmjtotal" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="已分配小魔夹数量" placement="top">
            <span>{{ props.row.sharexmjtotal ?props.row.sharexmjtotal:"0"}}</span>
          </el-tooltip>      
         </template>
      </el-table-column>
      <el-table-column align="center" label="钱包余额" min-width="40" prop="totalwallet" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="当前钱包余额" placement="top">
            <span>{{ props.row.totalwallet ?props.row.totalwallet:"0"}}</span>
          </el-tooltip> 
         </template>
      </el-table-column>
      <el-table-column align="center" label="总收益" min-width="40" prop="totalprofit" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="包含出租收益（投资50%）、会员推广提成（一级6%），超级会员推广提成（代理2.5%），城市合伙人(2.5%)" placement="top">
            <span @click="handlerShowDetails('1',props.row.userid,props.row.account,props.row.name)" class="span-click">￥ {{ props.row.totalprofit ?props.row.totalprofit:"0"}}</span>
          </el-tooltip> 
         </template>
      </el-table-column>
      <el-table-column align="center" label="总奖励" min-width="40" prop="rewardtotal" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="包含一次性推广奖励(100元),市场推广奖励(10元)" placement="top">
            <span @click="handlerShowDetails('2',props.row.userid,props.row.account,props.row.name)" class="span-click">￥ {{ props.row.rewardtotal ?props.row.rewardtotal:"0"}}</span>
          </el-tooltip> 
         </template>
      </el-table-column>
      <el-table-column align="center" label="总充值" min-width="40" prop="totalchange" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="包含普通平台充值，投资小魔夹时候的微信或者支付宝付款" placement="top">
            <span @click="handlerShowDetails('3',props.row.userid,props.row.account,props.row.name)" class="span-click">￥ {{ props.row.totalchange ?props.row.totalchange:"0"}}</span>
          </el-tooltip> 
         </template>
      </el-table-column>
      <el-table-column align="center" label="总提现" min-width="40" prop="totalwithdraw" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="包含微信和支付的提现" placement="top">
            <span @click="handlerShowDetails('4',props.row.userid,props.row.account,props.row.name)" class="span-click">￥ {{ props.row.totalwithdraw ?props.row.totalwithdraw:"0"}}</span>
          </el-tooltip> 
         </template>
      </el-table-column>
      <el-table-column align="center" label="总消费" min-width="40" prop="totalcost" >
         <template #default="props">
          <el-tooltip style="margin: 4px;" effect="light" content="包含租借订单扣款，超期订单扣款（199元），投资小魔夹扣款，租借订单续费" placement="top">
            <span @click="handlerShowDetails('5',props.row.userid,props.row.account,props.row.name)" class="span-click">￥ {{ props.row.totalcost ?props.row.totalcost:"0"}}</span>
          </el-tooltip> 
         </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="120">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button
            @click="handleCoupon(scope.row.userid,scope.row.name)"
            size="mini"
            type="primary"
          >优惠卷</el-button>
          <el-button
            @click="handleWalletHitory(scope.row.userid,scope.row.account)"
            size="mini"
            type="primary"
          >钱包流水</el-button>
          <el-button 
            @click="onWalletDetailClick(scope.row.userid,scope.row.name)" 
            size="mini" 
            type="primary">
            费用记录</el-button>
          <el-button 
            @click="onWalletPointClick(scope.row.userid,scope.row.name)" 
            size="mini" 
            type="primary">
            积分记录</el-button>  
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <couponVue ref="couponRef"></couponVue>
    <walletHistoryDialog ref="walletHistoryRef"></walletHistoryDialog>
    <detailsDialog  ref="walletDetailDialogRef"></detailsDialog>
    <pointDialog ref="pointDialogRef"></pointDialog>
    <profitDetailsDilaog ref="profitDetailsDialogRef"></profitDetailsDilaog>
    <rewardDetailsDialog ref="rewardDetailsDialogRef"></rewardDetailsDialog>
    <rechangeDetailsDialog ref="rechangeDetailsDialogRef"></rechangeDetailsDialog>
    <withdrawDetailsDialog ref="withdrawDetailsDialogRef"></withdrawDetailsDialog>
    <costDetailsDialog ref="costDetailsDialogRef"></costDetailsDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { investorModel,getInvestorStatistics,exportInvestorStatistics} from "@/api/Financial/investor";
import couponVue from "@/components/coupon/index.vue";
import walletHistoryDialog from "@/components/wallet/walletHistoryDialog.vue";
import detailsDialog from "@/components/wallet/detailsDialog.vue";
import pointDialog from "@/components/wallet/pointDialog.vue";
import profitDetailsDilaog from "@/components/financial/profitDetailsDialog.vue";
import rewardDetailsDialog from "@/components/financial/rewardDatailsDialog.vue";
import rechangeDetailsDialog from "@/components/financial/rechangeDetailsDialog.vue"
import withdrawDetailsDialog from "@/components/financial/withdrawDetailsDialog.vue"
import costDetailsDialog from "@/components/financial/costDetailsDialog.vue"
import dayjs from 'dayjs'
import console from "console";
import { saveAs } from 'file-saver'
export default defineComponent({
  name: "v-investor",
  components: {
    couponVue,
    walletHistoryDialog,
    detailsDialog,
    pointDialog,
    profitDetailsDilaog,
    rewardDetailsDialog,
    rechangeDetailsDialog,
    withdrawDetailsDialog,
    costDetailsDialog,
  },
  setup() {
    const loading = ref(false);
    const rmLoading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<investorModel[]>([]);
    const couponRef = ref<any>("");
    const walletHistoryRef = ref<any>("")
    const walletDetailDialogRef = ref<any>();
    const pointDialogRef = ref<any>();
    const profitDetailsDialogRef = ref<any>();
    const rewardDetailsDialogRef = ref<any>();
    const rechangeDetailsDialogRef = ref<any>();
    const withdrawDetailsDialogRef = ref<any>();
    const costDetailsDialogRef = ref<any>();
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      fortest: false,
      timeBN:''
    });
    const mixin_select_search = ref({
      search: {
        name: "",
        account: "",
        userid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        name: "昵称",
        account: "账号",
        userid: "用户ID",
      },
    });

    const handleCoupon = (userid:string,name:string) =>{
      couponRef.value.open(userid,name)
    }
    /**
     * 钱包流水
     */
    const handleWalletHitory = (userid:string,account:string) => {
      walletHistoryRef.value.open(userid,account)
    }
     /**
     * 钱包明细
     */
    const onWalletDetailClick = (userid :string,account:string) =>{
      walletDetailDialogRef.value.open(userid,account)
    }
    /**
     * 积分记录
     */
    const onWalletPointClick = (userid :string,account:string) => {
      pointDialogRef.value.open(userid,account)
    }
    /**
     * 下级详情
     */
    const handlerShowDetails = (type : string,userid : string, account : string,name:string) => {
      if(type=='1'){
        profitDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=='2'){
        rewardDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=='3'){
        rechangeDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=="4"){
        withdrawDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type == "5"){
        costDetailsDialogRef.value.open(userid,type,name,account,name);
      }
    }
    const reData = async (rq?: any) => {
      console.log("搜索参数",rq)
      try {
        loading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value, fortest:mixin_router_search.value.fortest };
        if (rq.timeBN) {
          rqData.startTime = dayjs(rq.timeBN[0]).format("YYYY-MM-DD HH:mm:ss").toString() 
          rqData.endTime =  dayjs(rq.timeBN[1]).format("YYYY-MM-DD HH:mm:ss").toString()  
          delete rq.timeBN
        }
        rqData={...rq,...rqData}
        const res = await getInvestorStatistics(rqData);
        console.log('查询到的数据',res)
        list.value = res.data.records;
        console.log('查询到的数据1',list.value)
        total.value = res.data.total;
      } finally {
        loading.value = false;
      }
    };
    const handleExport = async() =>{
      try {
        let searchData: any = {fileName:'investor'}
        let rqData: any = {fortest:mixin_router_search.value.fortest}
        
        searchData={...searchData,...mixin_select_search.value.search}

        console.log('导出参数=',searchData)
        if (mixin_router_search.value.timeBN) {
          rqData.startTime = dayjs(mixin_router_search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(mixin_router_search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            rqData[key]=searchData[key]
          }
        })
        console.log('遍历之后=',rqData)

        const res = await exportInvestorStatistics(rqData)
        console.log('导表=',res)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, '会员统计.xls')
      } finally {
       
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
  

    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      walletDetailDialogRef,
      pointDialogRef,
      profitDetailsDialogRef,
      rewardDetailsDialogRef,
      rechangeDetailsDialogRef,
      withdrawDetailsDialogRef,
      costDetailsDialogRef,
      onWalletDetailClick,
      handelChange,
      handleLimitChange,
      onPageChange,
      changSearch,
      handleCoupon,
      mixinReRouter,
      rmLoading,
      toPath_RouterSearchMixin,
      couponRef,
      handleWalletHitory,
      walletHistoryRef,
      onWalletPointClick,
      handlerShowDetails,
      handleExport,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }

  .span-click {
    color: #409eff;
    cursor:pointer;
  }
}
</style>