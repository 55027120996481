import { h, defineComponent, ref, toRef, onMounted, watch, nextTick, watchEffect } from 'vue';
import echarts from "./base"
import { unwarp } from '@/utils';
import {
    VisualMapComponent,
    GeoComponent,
} from 'echarts/components';
import {
    BarChart,
    PictorialBarChart,
    EffectScatterChart,
    MapChart
} from 'echarts/charts';
echarts.use([GeoComponent, BarChart, EffectScatterChart, VisualMapComponent, MapChart, PictorialBarChart])
export default defineComponent({
    name: "my-pie",
    props: ['option', 'mapData', 'total', 'height'],
    setup(props: any) {
        const option = toRef(props, 'option')
        const mapRef = ref<any>("")
        const myChart = ref<any>('')
        const myRender = async (_option?: any) => {
            await nextTick()
            myChart.value.setOption && unwarp(myChart.value).setOption(_option || option.value)
        }
        onMounted(async () => {
            const changsha = require('@/assets/json/430100_full.json')
            echarts.registerMap('changsha', changsha)
            await nextTick()
            console.log('echarts.getMap() :>> ', echarts.getMap('changsha'));
            myChart.value = echarts.init(mapRef.value);
            await nextTick()
            myChart.value.setOption({
                series: [{
                    type: 'map',
                    map: 'changsha'
                }]
            });
            watchEffect(async () => {
                myRender(option.value)
            })
        })
        const myResize = async () => {
            await nextTick()
            myChart.value.resize && myChart.value.resize()
        }
        return {
            mapRef,
            myRender,
            myResize
        }
    },
    render() {
        return h('div', {
            ref: 'mapRef',
        })
    }
})