
import { completeHttp, repairListModel } from '@/api/fault/repair'

import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, readonly } from 'vue'
import { userStore } from "@/store/modules/user";
import Rules from "@/common/Rules";
let overdueId = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {

    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<repairListModel>()
    const headerObj = ref<any>({})
    headerObj.value = { Authorization: userStore.info.token };

    const inputData = ref({
      equipmentSn: "",
      maintainDesc: "",
      
    })
    const rules = readonly({
      equipmentSn: [Rules.required()]
    });
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:repairListModel) => {
      visible.value = true
      overdueId=item.id;
      row.value=item;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true

        const time = dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc = userStore.userInfo.account +'在'+ time + '完结维修单'
        if(!inputData.value.maintainDesc){
          inputData.value.maintainDesc=desc;
        }
        const res = await completeHttp({
          id: overdueId,
          maintainDesc: inputData.value.maintainDesc,

        })

        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }


    return {
      rules,
      headerObj,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      row,
    }
  },
})
