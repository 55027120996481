import { defHttp } from "@/utils/http/axios";
import { ListRes, ListReq, RequestRes } from "../../types/requestBaseType"
import dayjs from "dayjs"


export const billtypeNames = {
    recharge: "充值",
    withdraw: "提现",
    deduction: "扣费",
    profit: "收益",
    renewal: "续费",
    refund:'退款'
}

export const rechargeOrProfitTypes = [
    {
        "id":0,
        "name":'收益'
    },
    {
        "id":1,
        "name":'充值'
    },
    {
        "id":2,
        "name":'出租收益'
    },
    {
        "id":3,
        "name":'跑腿红包'
    },
    {
        "id":4,
        "name":'分享红包'
    },
    {
        "id":5,
        "name":'商家红包'
    },
    {
        "id":6,
        "name":'超级会员推广提成'
    },
    {
        "id":7,
        "name":'会员推广提成'
    },
    {
        "id":8,
        "name":'超级会员推广提成'
    },
    {
        "id":9,
        "name":'平台收益'
    },
    {
        "id":10,
        "name":' 一次性推广奖励'
    },
    {
        "id":11,
        "name":'私有化赔付'
    },
    {
        "id":12,
        "name":'人传人红包收益'
    },
    {
        "id":13,
        "name":'退款'
    },
    {
        "id":14,
        "name":'会员推广提成'
    },
    {
        "id":15,
        "name":'市场推广奖励'
    }
]

export const deductionTypes = [
    {
        "id":0,
        "name":'消费'
    },
    {
        "id":1,
        "name":'订单消费'
    },
    {
        "id":2,
        "name":'跑腿红包'
    }
]

export function getRechargeOrProfitTypeList(){
    return rechargeOrProfitTypes;
}

export function getDeductionTypeList(){
    return deductionTypes;
}
    
export interface UserconsumewalletUnionbill {
    status: string,
    date: string,
    billtype: keyof typeof billtypeNames,
    billtypeName?: string
}
/**
 * 获取用户角色
 */
export async function userconsumewalletUnionbill(params: ListReq): Promise<ListRes<UserconsumewalletUnionbill>> {
    params.token = true
    const res = await defHttp.request<ListRes<UserconsumewalletUnionbill>>(
        {
            url: `/userconsumewallet/unionbill`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.date = dayjs(v.date).format("YYYY-MM-DD HH:mm:ss")
        v.billtypeName = billtypeNames[v.billtype]
        return v
    })
    return res
}