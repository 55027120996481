
  import { defineComponent, ref, readonly } from "vue";
  import routerChangeReData from "@/common/routerChangeReData";
  import { getPrivateDeviceList, DeviceModel,removePrivateDeivce } from "@/api/Share/Private";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { getAdInfoList,getProductInfoList,getProductIdList} from '@/api/util'
  import { useRouter } from "vue-router";
  import addDialog from "./components/addDialog.vue"
  export default defineComponent({
    name: "Name",
    components: {
      addDialog,
    },
    setup() {
      const loading = ref(false);
      const btnRemoveLoaidng = ref(false);
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const router = useRouter();
      // const list = ref<DeviceListRes[]>([]);
      const list =ref<DeviceModel[]>([]);

      const adInfoList = getAdInfoList()
      const productList = getProductInfoList()
      const productIdList = getProductIdList()
      const addDialogRef = ref<any>();
      const toPath = (path: string) => {
        router.push(path);
      };
      const searchSelect = ref({
        search: "equipmentSn",
      });
      const mixin_select_search = ref({
        search: {
          equipmentSn: "", //共享面板的设备序号，如：小魔柜SN；雨伞架SN
          userAccount: "",
          userId:""
        },
      });
  
      const mixin_select_search_label = readonly({
        search: {
          equipmentSn: "设备SN",
          userAccount: "手机号",
          userId:"用户id"
        },
      });
  
      const mixin_router_search = ref(
        { socketstatus: "",
          productid:"",
          order: "gmtCreate",
          sort: "desc" 
        });
      const reData = async (rq?: any) => {
        try {
          loading.value = true;
          let rqData: any = { current: page.value, size: limit.value };
          if (rq && JSON.stringify(rq) !== "{}") {
            rqData = { ...rqData, entity: rq };
           // rqData = { ...rqData, ...rq }
          }else{
            rqData = { ...rqData, entity: {} };
          }
          const res = await getPrivateDeviceList(rqData);
          console.log('返回数据=',res)
          
          total.value = res.total;
          list.value = res.rows;
        } finally {
          loading.value = false;
        }
      };
      const {
        mixinReRouter,
        onPageChange,
        handleLimitChange,
        changSearch,
      } = routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      });
      const handelChange = () => {
        page.value = 1;
        mixinReRouter();
      };
   
     
      const handleRemove = async(item:any) =>{
        await ElMessageBox.confirm(
          `是否解绑账号=${item.userAccount}的小魔夹=${item.equipmentSn}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await removePrivateDeivce(item.equipmentSn)
        page.value=1;
        reData();
        ElMessage.success(res.msg)
      }
     
      const handleAdd = async() =>{
        addDialogRef.value.open();
      }
      return {
        mixin_select_search_label,
        mixin_select_search,
        mixin_router_search,
        changSearch,
        searchSelect,
        list,
        loading,
        page,
        total,
        limit,
        adInfoList,
        productList,
        productIdList,
        addDialogRef,
        toPath,
        handelChange,
        handleLimitChange,
        onPageChange,
        handleRemove,
        handleAdd,
        btnRemoveLoaidng,
      };
    },
  });
  