<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.user[searchSelect.user]">
        <template #prepend>
          <el-select @change="changSearch('user')" placeholder="请选择" v-model="searchSelect.user">
            <el-option :key="item" :label="mixin_select_search_label.user[item]" :value="item" v-for="item of Object.keys(mixin_select_search.user)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="担保类型" class="header-el-selector" v-model="mixin_router_search.guaranteetype">
        <el-option value="BALANCE" label="余额担保"></el-option>
        <el-option value="ZHIMA" label="芝麻信用"></el-option>
        <el-option value="PAYSCORE" label="微信支付分"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="状态" class="header-el-selector" v-model="mixin_router_search.enabled">
        <el-option :value="true" label="已确认/已使用"></el-option>
        <el-option :value="false" label="未确认/已完成"></el-option>
      </el-select>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list" :default-sort="{prop: 'creationdate', order: 'descending'}" @sort-change="mixinReRouterTableSortChange">

      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="担保订单号">
              {{scope.row.guaranteeno}}
              <my-copy :data="scope.row.guaranteeno" />
            </el-form-item>
            <el-form-item label="用户ID">
              <my-click-data-to url="/user" :data="scope.row.userid" :params="[{search_userid:scope.row.userid}]"></my-click-data-to>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="用户账号" min-width="49">
        <template #default="scope">{{scope.row.account}}
          <my-copy :data="scope.row.account" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="订单号" min-width="45">
        <template #default="scope">
          <my-click-data-to url="/order/userorder" :data="scope.row.sharedorderid" :params="[{search_sharedorderid:scope.row.sharedorderid}]"></my-click-data-to>
        </template>
      </el-table-column>
      <el-table-column label="担保类型" min-width="30">
        <template #default="scope">
          <color-icon :type="`icon-${scope.row.guaranteetype}`" style="font-size:40px;color:#50b674"></color-icon>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否确认">
        <template #default="scope">
          <div v-if="scope.row.enabled">
            <el-tag v-if="scope.row.sharedorderid" type="waring">已使用</el-tag>
            <el-tag v-else type="primary"> 已确认</el-tag>
          </div>
          <div v-else>
            <el-tag v-if="scope.row.sharedorderid" type="success"> 已完成</el-tag>
            <el-tag v-else type="info"> 已创建</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="center" label="创建时间" min-width="48" prop="creationdate" sortable="custom"> </el-table-column>
      <el-table-column align="center" label="更新时间" min-width="48" prop="modificationdate"> </el-table-column>

      <el-table-column align="center" label="操作" min-width="50">
        <template #default="scope">
          <el-button @click="handleClose(scope.index,scope.row)" :disabled="!scope.row.enabled" size="mini" type="danger" :loading="btncloseLoading">强制关闭</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  userguaranteeorderList,
  UserguaranteeorderList,
  wechatpayscoreorderComplete,
  alipayfundauthorderComplete,
} from "@/api/Order/Guarantee";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
  name: "orderManage",
  setup() {
    const btncloseLoading = ref(false);
    const loading = ref(false);

    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<any>([]);
    const searchSelect = ref({
      search: "guaranteeno",
      user: "account",
    });
    const mixin_router_search = ref({
      guaranteetype: "",
      order: "creationdate",
      sort: "desc",
      enabled: "",
      sharedorderid: "",
    });
    const mixin_select_search = ref({
      search: {
        sharedorderid: "",
        guaranteeno: "",
      },
      user: {
        account: "",
        userid: "",
      },
    });
    const handleClose = async (index: number, item: UserguaranteeorderList) => {
      try {
        await ElMessageBox.confirm(`是否强制关闭此支付分订单？`, `请确认`, {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        btncloseLoading.value = true;
        if (item.guaranteetype == "ZHIMA") {
          await alipayfundauthorderComplete(item.guaranteeno);
        } else {
          await wechatpayscoreorderComplete(item.guaranteeno);
        }
        ElMessage.success("关闭担保订单成功");
      } finally {
        btncloseLoading.value = false;
      }
    };

    const mixin_select_search_label = readonly({
      search: {
        sharedorderid: "订单号",
        guaranteeno: "担保订单号",
      },
      user: {
        account: "用户账号",
        userid: "用户ID",
      },
    });
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          if (rq.order) {
            const { order, sort } = rq;
            delete rq.order;
            delete rq.sort;
            rqData = { ...rqData, order, sort };
          }
          rqData = { ...rqData, entity: rq };
        }
        const res = await userguaranteeorderList(rqData);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      handleClose,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>