<template>
  <el-dialog :destroy-on-close="true" :title="`提现审核`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form class="table-expand" inline label-position="left" label-width="120px">
      <el-form-item label="钱包余额">
        <span>{{ balance }}</span>
        <el-button :loading="reLoading" @click="reData" class="re-data-btn" size="mini" type="info">刷新</el-button>
      </el-form-item>
      <el-form-item label="提现金额">
        <span>{{ info.amount }}</span>
        <my-tip content="请仔细核对提现金额"></my-tip>
      </el-form-item>
      <el-form-item label="审核结果">
        <el-input type="textarea" :rows="2" placeholder="特殊情况输入，不必填" v-model="auditresult">
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="submit(false)">拒 绝</el-button>
        <el-button :loading="btnSumitLoading" @click="submit(true)" type="success">审核通过</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from "vue";
import {
  UserwithdraworderListRes,
  userwithdraworderAudit,
} from "@/api/Financial/transfer";
import { userconsumewalletAll, AllWallets } from "@/api/User/index";
import { toOptional } from "@/types/utils/index";
import { userStore } from "@/store/modules/user";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  emits: ["success"],
  setup(props, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const reLoading = ref(false);
    const auditresult = ref("");
    const wallets = ref<toOptional<AllWallets>>({});
    const info = ref<toOptional<UserwithdraworderListRes>>({});
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const balance = computed(() => {
      if (!info.value.articleproductmodel) return 0;
      if (!wallets.value[info.value.articleproductmodel]) return 0;
      return wallets.value[info.value.articleproductmodel]?.amount;
    });
    const reData = async () => {
      try {
        reLoading.value = true;
        wallets.value = (
          await userconsumewalletAll(info.value.userid as string)
        ).userAllWallets;
      } finally {
        reLoading.value = false;
      }
    };
    const open = (row: UserwithdraworderListRes) => {
      visible.value = true;
      info.value = row;
      reData();
    };
    const submit = async (approved: boolean) => {
      try {
        btnSumitLoading.value = true;
        if (!userStore.info.userid || !info.value.withdraworderid) return;
        const res = await userwithdraworderAudit({
          approved,
          auditoruserid: userStore.info.userid,
          auditresult: auditresult.value
            ? auditresult.value
            : approved
            ? "同意"
            : "不同意",
          withdraworderid: info.value.withdraworderid,
        });
        ElMessage.success(res);
        emit("success");
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      balance,
      reLoading,
      info,
      reData,
      submit,
      auditresult,
    };
  },
});
</script>
<style lang = "scss" scoped >
.re-data-btn {
  margin-left: 24px;
}
</style>