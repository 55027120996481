
import { defineComponent, ref, readonly, nextTick } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { userRoleList, removeOperator } from '@/api/User/role'
import testWalletRechangeDialog from './components/testWalletRechangeDialog.vue'
import activateDialog from './components/activateOperateDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import {useRoute} from "vue-router"
import checkRole from '@/common/checkRole' //检查用户是否有侧边导航权限
export default defineComponent({
  name: 'Name',
  components: {
    'v-activate-operate-dialog': activateDialog,
    testWalletRechangeDialog,
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const route = useRoute()
    const { roleShowPermission } = checkRole()
    const operationid = route.query.operationId;
    const activateDialogRef = ref<any>()
    const list = ref<any>([])
    const testWalletRechangeDialogRef = ref<any>()
    const searchSelect = ref({ search: 'name' })
    const mixin_router_search = ref({ status: '', type: 'operator',  operationId: operationid, })
    const mixin_select_search = ref({
      search: {
        name: '',
        account: '',
        userId: '',
       
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
        account: '账号',
        userId: 'ID',
      },
    })

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
         if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
            rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          // if (rq.name) {
          //   rq[`${mixin_router_search.value.type}name`] = rq.name
          //   delete rq.name
          // }
          // if (rq.account) {
          //   rq[`${mixin_router_search.value.type}account`] = rq.account
          //   delete rq.account
          // }
          // if (rq.userid) {
          //   rq[`${mixin_router_search.value.type}userid`] = rq.userid
          //   delete rq.userid
          // }
          rqData = { ...rqData, ...rq }
        }
        const res = await userRoleList(rqData, mixin_router_search.value.type)
        total.value = res.totalCount
        list.value = res.result
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleActivate = () => {
      activateDialogRef.value.open(true,null,operationid)
    }
    const handleRechange = (row: any) => {
      //activateDialogRef.value.open(false,row,operationid)
    }

    const handleRemove = async(row: any) => {
      await ElMessageBox.confirm(
        `是否删除运营员“`+row.name+'”',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        }
      )
     
      // const res = await removeRole(row,mixin_router_search.value.type)
      const res = await removeOperator({ account:row.account,operationid :row.operationId })
      mixinReRouter()
      ElMessage.success(res)
    }

    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleActivate,
      activateDialogRef,
      changSearch,
      handleRechange,
      handleRemove,
      roleShowPermission,
      testWalletRechangeDialogRef,
    }
  },
})
