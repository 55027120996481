
import { defineComponent, ref, onMounted,nextTick,computed,toRef} from "vue";
import cardBox from './components/cardBox.vue'
import liquidFill from "./components/liquidFill/index.vue";
export default defineComponent({
    name: "Name",
    components:{
        "v-card-box": cardBox,
        "v-liquid-fill": liquidFill
    },
    setup(props) {
        const getHeight = computed(() => 900 / 2 - 2 + "px");
        const pieHeight = ref("240px")
        const chartData = ref<Object>({name: '助学金',percent: [2325.36]})
        return {
            getHeight,
            pieHeight,
            chartData
        }
    }
})
