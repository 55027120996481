import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs";

export interface ProductList {
    creationTime: number
    mfrid: string
    model: string
    modificationTime: number
    name: string
    productid: string
    type: string
    firmwareDetail?: FirmwareLast
    open: boolean
    idx: number
}
export async function productList(params: ListReq): Promise<ListRes<ProductList>> {
    params.token = true
    const res = await defHttp.request<ListRes<ProductList>>(
        {
            url: `/product/list`,
            params
        }
    );
    res.result = res.result.map((v, i) => {
        v.open = false
        v.idx = i
        return v
    })
    return res
}
export interface FirmwareLast {
    "firmwareid": string,
    "productid": string,
    "version": string,
    "url": string,
    "description": string,
    "creationtime": number,
    "modificationtime": string,
    "productname": null | string,
    "producttype": null | string,
    "productmodel": null | string,
}
export async function firmwareLast(productid: string): Promise<FirmwareLast> {
    const res = await defHttp.request<RequestRes<FirmwareLast>>(
        {
            url: `/firmware/last`,
            params: {
                token: true,
                productid
            }
        }
    );
    if (!res?.record) throw new Error("获取固件信息失败");
    res.record.modificationtime = dayjs(res.record.modificationtime).format("YYYY-MM-DD HH:mm:ss")
    return res.record
}

/**
 * 
 * @param params 设备设备版本
 * @returns 
 */
 export async function updateXMJVersion(params: RequestReq) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/product/update`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData.message
}