
import { defineComponent, nextTick, readonly, ref } from "vue";
import { generateNewSn } from '@/api/Share/Device'
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import {getProductInfoList,getProductIdList} from '@/api/util'
export default defineComponent({
  name: "v-dialog",
  emits: ['export'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const inputData = ref({
      productid: "", 
      qrStr:"xmj",
      careateBN:"",
      name:"二维码数据",
      radio:"1",
    });
    const rules = readonly({
      productid: [Rules.required()],
      careateBN: [Rules.required()],
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
    
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
     
    };
    const open = () => {
      visible.value = true;
      
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        // console.log('生成参数=',inputData.value)
        // const res = await generateNewSn(inputData.value)
        // console.log('返回=',res)
        // handleClose();
        // ElMessage.success(res.message);
        let fileType="";
        if(inputData.value.radio=='1'){
          fileType="xls"
        }else if(inputData.value.radio=='2'){
          fileType="xlsx";
        }else if(inputData.value.radio=='3'){
          fileType="excel";
        }
        emit('export',{productid:inputData.value.productid,
                      type:inputData.value.qrStr,
                      time:inputData.value.careateBN,
                      name:`${inputData.value.name}.${fileType}`})
        handleClose();
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      productList,
      productIdList,
    };
  },
});
