

import { ref,computed, defineComponent, readonly ,reactive} from "vue";
import {
  faultDetailsModel,
  getFaultDetalsHttp,
} from '@/api/fault/index'
import {
 
  getOrderList,
  OrderList,

} from '@/api/Order'
import { toOptional } from "@/types/utils";
import { userStore } from "@/store/modules/user";
export default defineComponent({


    setup() {
        console.log('进入弹窗');
        const isVisible = ref(false);
        const title = ref("详情信息");
        const direction=ref("rtl");
        const status=ref(0)
        const sn=ref('')  //设备SN
        const formRef = ref<any>({})
        const sharedorderid=ref("")//订单id
        const faultId=ref('') //故障id
   
    
        const orderInfo = ref<toOptional<OrderList>>({});
        const list = ref<OrderList[]>([])
        const orderDetailsInfo=ref<toOptional<faultDetailsModel>>({});
        const handleClose=(done:any)=>{
          formRef.value.resetFields()
          formRef.value.clearValidate()
          done();
        };

      
        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用222');
            console.log(item.id)
            isVisible.value = true;
            sharedorderid.value=item.sharedOrderId;
            faultId.value=item.id
            title.value=item.equipmentSn+'故障详情'
            orderInfo.value={};
            orderDetailsInfo.value={};
            reData()
           
        };

        /**
         * 加载列表数据
         */
         const reData = async () => {
            try {
                 let rqData: any = { current: "1", size: "10" ,entity:{sharedorderid: sharedorderid.value}}
                console.log(rqData);
                const res = await getOrderList(rqData)
                list.value = res.records
                orderInfo.value=list.value[0];
               getFaultDetails();
            } finally{

            }
        }

        /**
         * 获取私有化进程
         */
        const getFaultDetails = async () => {
           
            const info = await getFaultDetalsHttp(faultId.value)
            orderDetailsInfo.value=info.data;
            console.log('故障详情222')
            console.log(orderDetailsInfo.value) 
        }


        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,
            orderDetailsInfo,
            orderInfo,
            sn,
            sharedorderid,
            formRef,
            status,
        };
    },
    components: {
      
    }
   
});
