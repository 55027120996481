/**
 * 普通用户和会员的统计详情
 */
import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes } from "../../types/requestBaseType"
import dayjs from "dayjs"

export interface profitTotalModel {
    userid: string,
    investorTotal: string,
    investor: string,
    superior?: string,
    superiorTotal?:string,
    agent?: string,
    agentTotal?: string,
    partner?: string,
    partnerTotal?:string,
}
export interface moneylModel {
    userId: string,
    wxPay: string,
    wxAllTotal: string,
    aliPay: string,
    aliAllTotal: string,
    yuePay?: string,
    yueAllTotal?: string,
    money?: string
}
export interface rewardModel {
    userId: string,
    oneTimeReward: string,
    oneTimeRewardTotal: string,
    marketReward: string,
    marketRewardTotal: string
}
export interface profitTimeModel {
    userid: string,
    investorTotal: string,
    investor: string,
    superior: string,
    superiorTotal:string,
    agent: string,
    agentTotal: string,
    partner: string,
    partnerTotal:string,
}
export interface moneyTimeModel {
    userId: string,
    wxPay: string,
    wxTotal: string,
    wxSumCount: string,
    aliPay: string,
    aliTotal: string,
    aliSumCount: string,
    yuePay?: string,
    yueTotal?: string,
    yueSumCount?: string
}
export interface rewardTimeModel {
    userId: string,
    oneTimeReward: string,
    oneTimeRewardTotal: string,
    marketReward: string,
    marketRewardTotal: string
}


export interface profitListModel {
    userid: string,
    name?: string,
    account?: string,
    totalwallet?: string,
    fundfrozen?: string,
    totalchange?: string,
    totalwithdraw?: string,
    totalcost?: string,
    rechargemode?: string,
    date?: string,
    rechargetype?: string,
    articleequipmentsn?: string,
    sharedorderid?: string,
    investorname?:String,
    transferred?:string,
    deductionType?:string,
    payeerealname?:string,
    payeeaccount?:string,
    applydate?:string,
}

export interface totalModel {
    total:number,
    alllist:profitTotalModel,
    list:profitTimeModel[],
}

export interface moneyTotalModel {
    total:number,
    alllist:moneylModel[],
    list:moneyTimeModel[],
    sectionlist?:moneylModel[],
}
export interface rewardTotalModel {
    total:number,
    alllist:rewardModel[],
    list:rewardTimeModel[],
}
/**
 * 获取收益详情总额
 */
 export async function getStatisticsTotalDetails(parmas: RequestReq): Promise<totalModel> {
    const res = await defHttp.request<RequestRes<totalModel>>(
        {
            url: `/finance/getMoneyStatistics`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res.data!
}

/**
 * 获取奖励详情总额
 */
 export async function getRewardStatisticsTotalDetails(parmas: RequestReq): Promise<rewardTotalModel> {
    const res = await defHttp.request<RequestRes<rewardTotalModel>>(
        {
            url: `/finance/getMoneyStatistics`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res.data!
}

/**
 * 获取充值,提现，消费详情总额
 */
 export async function getMoneyStatisticsTotalDetails(parmas: RequestReq): Promise<moneyTotalModel> {
    const res = await defHttp.request<RequestRes<moneyTotalModel>>(
        {
            url: `/finance/getMoneyStatistics`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res.data!
}

/**
 * 获取详情列表
 */
 export async function getStatisticsDetailsList(parmas: RequestReq): Promise<ListRes<profitListModel>> {
    const res = await defHttp.request<ListRes<profitListModel>>(
        {
            url: `/finance/getMoneyDetailed`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    // res.data.records = res.data.records.map(v => {
    //     v.date = dayjs(parseInt(v.date!)).format("YYYY-MM-DD HH:mm:ss")
    //     return v
    // })
    return res
}

/**
 * 获取详情列表
 */
 export async function exportStatisticsDetails(parmas: RequestReq): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/export/exportMoneyDetailed`,
            params: {
                token: true,
                ...parmas
            },
            responseType:'blob',
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    // res.data.records = res.data.records.map(v => {
    //     v.date = dayjs(parseInt(v.date!)).format("YYYY-MM-DD HH:mm:ss")
    //     return v
    // })
    return res
}