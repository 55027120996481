<template>
  <el-dialog :destroy-on-close="true" :title="`强制结算订单`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      :title="tipMsg"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef" style="margin-top: 20px;">
      <el-form-item label="归还机柜SN" prop="panelequipmentsn">
        <el-input style="width: 220px;" v-model="inputData.panelequipmentsn"></el-input>
      </el-form-item>
      <el-form-item label="归还机柜仓口" prop="socketsn">
        <el-select placeholder="归还机柜仓口" v-model="inputData.socketsn">
          <el-option :value="item" :label="`${item}号仓`" v-for="item of getNum" :key="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算时间" prop="time">
        <!-- <div class="block">
        <span class="demonstration">默认</span>
        <el-date-picker
          v-model="inputData.time"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker> -->
        <el-date-picker v-model="time" type="datetime" placeholder="选择日期时间" @change="handlerTimeChange"></el-date-picker>
      </el-form-item>
      <el-form-item label="订单费用" prop="rentalfee">
        <el-input-number v-model="inputData.rentalfee" :min="0" :step="3" label="订单费用"></el-input-number>
        <my-tip :content="`${inputData.rentalfee}元`"></my-tip>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from "vue";
import { sharedsocketMountarticle } from "@/api/Order/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { userList } from "@/api/User/index";
import { getRentalfee } from "@/api/util"
import dayjs from "dayjs";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "v-dialog",
  setup() {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const articleequipmentsn = ref("");
    const formRef = ref<any>("");
    const time = ref<any>('');
    const isVip = ref<boolean>(false);
    const isXmj = ref<boolean>(true);
    const productmodel = ref<string>("");
    const receivedate = ref<string>('');
    const userInfoList = ref<any>([]);
    const tipMsg = ref<string>("非会员用户，3小时内3元，超过3小时6元一天")
    const inputData = ref({
      panelequipmentsn: "",
      socketsn: "",
      rentalfee: 0
    });
    const geRules = computed(() => {
      const _rules: any = {};
      Object.keys(inputData.value).forEach(
        (v) => (_rules[v] = [Rules.required()])
      );
      return _rules;
    });
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };
    const open = async (o: {
      articleequipmentsn: string;
      receivedate: string;
      articleproductmodel: string;
      articleproducttype:string;
      renewalamount: number;
      receiveruserid:string,
      receiveraccount:string,
    }) => {
      //console.log('返回金额',getRentalfee('2023-05-22 09:03:22','2023-05-22 10:03:22'))
      await getUserInfo(o.receiveruserid,o.receiveraccount);
      visible.value = true;
      isXmj.value=o.articleproducttype=='phoneholder';
      articleequipmentsn.value = o.articleequipmentsn;
      receivedate.value=o.receivedate;
      const selectTime=dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      inputData.value.rentalfee=getRentalfee(receivedate.value,selectTime,isVip.value,isXmj.value);
      // inputData.value.rentalfee =
      //   Math.ceil(dayjs().diff(o.receivedate, "day", true).valueOf()) * 3 - (o.renewalamount || 0); //租赁费用减去续费的钱
      productmodel.value = o.articleproductmodel;
    };
    const handlerTimeChange = async(e:any)=>{
      //console.log('时间改变=',e)
      const selectTime=dayjs(e).format("YYYY-MM-DD HH:mm:ss");
      //console.log('时间改变1=',selectTime)
      inputData.value.rentalfee=getRentalfee(receivedate.value,selectTime,isVip.value,isXmj.value);
    }
    const getUserInfo = async (userid:string,phone:string) => {
      
      let rqData: any = { page: 1, limit: 10, userid:userid };
      const res = await userList(rqData);
      userInfoList.value = res.result;
      isVip.value=userInfoList.value[0].isvip==1;
      tipMsg.value="用户"+phone+"是"+(isVip?"会员用户，":"普通用户，")+(isVip?"计费是：小魔夹3元一天；雨伞3元一天 ":"计费是：小魔夹3小时内3元，超过3小时6元一天；雨伞3元一天")
      const selectTime=dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      inputData.value.rentalfee=getRentalfee(receivedate.value,selectTime,isVip.value,isXmj.value);
    };
    const getNum = computed(() => {
      let num = 12;
      if (productmodel.value == "M4S2") num = 10;
      if (productmodel.value == "UM01") num = 9;
      const list: string[] = [];
      for (let i = 1; i <= num; i++) {
        list.push(i < 10 ? `0${i}` : `${i}`);
      }
      return list;
    });
    const submit = async () => {
      try {
        await formRef.value.validate();
        await ElMessageBox.confirm(
          `请确认已强制结算设备${articleequipmentsn.value}订单，扣费${inputData.value.rentalfee}元？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            distinguishCancelAndClose: true,
          }
        );
        const res = await sharedsocketMountarticle({
          ...inputData.value,
          articleequipmentsn: articleequipmentsn.value,
        });
        ElMessage.success(res);
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      tipMsg,
      time,
      open,
      btnSumitLoading,
      inputData,
      formRef,
      submit,
      geRules,
      getNum,
      handlerTimeChange,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>