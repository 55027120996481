<template>
  <el-dialog :destroy-on-close="true" :title="`${title}故障模板`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      title="编码输入不能和已经录入的重复，故障原因一般的简称，比如：不充电"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="form" :rules="rules" label-width="100px" ref="formRef" style="margin-top: 16px;">
      <el-form-item label="故障原因" prop="content">
        <el-input style="width: 220px;" placeholder="请输入故障原因" v-model="form.title"></el-input>
      </el-form-item>
     
      <el-form-item label="编码">
        <el-input style="width: 220px;" placeholder="请输入故障编码" v-model="form.identificationId"></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input style="width: 420px;" placeholder="请输入故障描述" v-model="form.descriptiondetails" type="textarea" :rows="4"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import Rules from '@/common/Rules'
import {addFault,updataFault} from '@/api/System/fault'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
let _id='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const title = ref()
    const isAdd = ref(true);
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      title: [Rules.required()],
      descriptiondetails: [Rules.required()],
      identificationId: [Rules.required()],
    }
    const form = ref({
      title: '',
      descriptiondetails: '',
      identificationId: '',
    })
    const open = (row: any) => {
      visible.value = true
      if(row){
        isAdd.value=false;
        _id=row.id;
        title.value='修改'
        form.value.title=row.title;
        form.value.descriptiondetails=row.descriptiondetails;
        form.value.identificationId=row.identificationId;
      }else{
        isAdd.value=true;
        title.value='新增'
      }
    }

    const submit = async () => {
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res=  isAdd.value?await addFault({...form.value}):await updataFault({id:_id,...form.value})
        if(res.code==200){
          ElMessage.success("修改成功")
        }else{
          ElMessage.success(res)
        }
        
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      title,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>