
import { fourthgmsgList, FourthgcommandList } from '@/api/Share/Device'
import { defineComponent, ref } from 'vue'
let sort = 'datatimestamp'
let order = 'desc'

let deviceid = '',
  datastream: any[] = []
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const visible = ref(false)
    const list = ref<FourthgcommandList[]>([])
    const filters = [
      { text: 'PTS', value: 'PTS' },
      { text: 'STSIN', value: 'STSIN' },
      { text: 'STSOUT', value: 'STSOUT' },
      { text: 'GETCSQ', value: 'GETCSQ' },
      { text: 'MAC', value: 'MAC' },
    ]
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const date: any[] = []
    const handleClose = () => {
      visible.value = false
      deviceid = ''
      datastream = []
      //   selfDataRef.value.clearValidate();
      //   selfDataRef.value.resetFields();
    }
    const open = (_deviceid: string) => {
      visible.value = true
      deviceid = _deviceid
      reData()
    }
    const reData = async (re?: boolean) => {
      if (re) page.value = 1
      const req: any = {
        limit: limit.value,
        page: page.value,
        deviceid,
        sort,
        order,
        // phoneholdersn
      }
      if (date.length > 0) {
        req.datatimestampbegin = new Date(req.date[0]).getTime()
        req.datatimestampend = new Date(req.date[1]).getTime()
      }
      if (datastream.length > 0) req.datastream = datastream.join(',')
      const { totalCount, result } = await fourthgmsgList(req)
      total.value = totalCount
      list.value = result
    }
    const sortChange = (e: {
      order: 'ascending' | 'descending' | undefined
      prop: string
    }) => {
      switch (e.order) {
        case 'ascending':
          order = 'asc'
          break
        case 'descending':
          order = 'desc'
          break
      }
      reData(true)
    }

    const filterChange = (e: any) => {
      datastream = e.datastream
      reData()
    }
    return {
      visible,
      handleClose,
      open,
      list,
      filters,
      page,
      total,
      limit,
      filterChange,
      date,
      sort,
      order,
      reData,
      sortChange,
    }
  },
})
