import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'

export default _defineComponent({
  expose: [],
  setup(__props) {

const loading = ref(false)

return { loading, ref }
}

})