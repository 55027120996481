
import { defineComponent, ref, computed } from "vue";
import { sharedorderprivatizationprocessNegotiationNew } from "@/api/Order/Privatization";
import Rules from "@/common/Rules";
import { deepCopy } from "@/utils";
import { ElMessage } from "element-plus";
import { userStore } from "@/store/modules/user";
import dayjs from "dayjs"
let overdueId = "";
export default defineComponent({
  name: "v-dialog",
  emits: ["success"],
  setup(props, { emit }) {
    const inputData = ref({
      remark: "愿意归还",
      userReturnAttitude: true,
      returntype: "cabinet",
    

      // expressbrand: "",
      // expressnum: "",
    });

    /**
     * 封装沟通是数据
     */
    const getInputData = computed(() => {
      const _inputData = deepCopy(inputData.value);
      console.log("_inputData :>> ", _inputData);
     
      if (!inputData.value.userReturnAttitude) {
        // delete _inputData.returntype;
        // delete _inputData.expressbrand;
        // delete _inputData.expressnum;
        _inputData.userReturnAttitude=2;
      } else {
         _inputData.userReturnAttitude=1;
         if (inputData.value.returntype == "cabinet") {
           _inputData.remark=_inputData.remark+',归还到机柜';
         }else{
           _inputData.remark=_inputData.remark+',通过邮寄回来';
         }
        delete _inputData.returntype;
        // delete _inputData.paymentamount;
      }
      // if (inputData.value.returntype == "cabinet") {
      //   delete _inputData.expressbrand;
      //   delete _inputData.expressnum;
      // }
      return _inputData;
    });

    /**
     * 表单规则
     */
    const geRules = computed(() => {
      const _rules: any = {};
      Object.keys(getInputData.value).forEach(
        (v) => (_rules[v] = [Rules.required()])
      );
      return _rules;
    });

    const visible = ref(false);
    const formRef = ref<any>("");
    const btnSumitLoading = ref(false);
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };

    const open = (id: string) => {
      visible.value = true;
      overdueId = id;
      console.log('id数据')
      console.log(overdueId)
    };

    /**
     * 提交数据
     */
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        const res = await sharedorderprivatizationprocessNegotiationNew({
          ...getInputData.value,
          handleUserId: userStore.userInfo.userid,
          excuteInfo:'',
          remindTime:dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss"),
          overdueId,
        });
        
        ElMessage.success(res);
        emit("success");
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
    };
  },
});
