import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface UserRoleListRes {
    creationtime: string
    modificationtime: string
}
export async function userRoleList(params: ListReq, type: string): Promise<ListRes<UserRoleListRes>> {
    params.token = true
    delete params.type
    const res = await defHttp.request<ListRes<UserRoleListRes>>(
        {
            url: `/${type}/list`,
            params
        }
    );
    if (res.result && res.result.length > 0) {
        res.result = res.result.map(v => {
            v.creationtime = dayjs(v.creationtime).format('YYYY-MM-DD HH:mm')
            v.modificationtime = dayjs(v.modificationtime).format('YYYY-MM-DD HH:mm')
            return v
        })
    }
    return res
}

export async function operationDirectorList(params: ListReq): Promise<ListRes<UserRoleListRes>> {
    params.token = true
    delete params.type
    const res = await defHttp.request<ListRes<UserRoleListRes>>(
        {
            url: `/userrole/getOperateOrAfterSalesInfo`,
            params
        },{
            prefix: "/shared",
            headers: { 'Content-Type': 'application/json' },
        }
    );
    return res
}

//添加用户角色
export async function userRoleAdd(params: RequestReq): Promise<string | undefined> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/userrole/add`,
            params
        },{
            prefix: "/shared",
            headers: { 'Content-Type': 'application/json' },
        }
    );
    if (res.code != 200) throw Error(res.message || '添加角色失败')
    return res.message
}

// 激活角色
export async function activateRole(params: RequestReq, type: string): Promise<string | undefined> {
    params.token = true
    if(type!='investor'){
      delete params.type
    }
    const res = await defHttp.request<RequestRes>(
        {
            url: `/${type}/activate`,
            params
        }
    );
    if (res.code != 200) throw Error(res.message || '激活角色权限失败')
    return res.message
}
// 激活运营员
export async function activateOperator(params: RequestReq, type: string): Promise<string | undefined> {
    params.token = true
    delete params.type
    const res = await defHttp.request<RequestRes>(
        {
            url: `/operator/activate`,
            params
        },
        {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    if (res.code != 200) throw Error(res.message || '激活角色权限失败')
    return res.message
}

// 更新运营员
export async function upDateOperator(params: RequestReq): Promise<string | undefined> {
    params.token = true
    delete params.type
    const res = await defHttp.request<RequestRes>(
        {
            url: `/operator/update`,
            params
        },
        {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    if (res.code != 200) throw Error(res.message || '激活角色权限失败')
    return res.message
}

// 删除角色
export async function removeRole(params: RequestReq, type: string): Promise<string | undefined> {
    params.token = true
    delete params.type
    const res = await defHttp.request<RequestRes>(
        {
            url: `/${type}/remove`,
            params
        }
    );
    if (res.code != 200) throw Error(res.msg || '删除角色失败')
    return res.message
}

// 删除运营员角色
export async function removeOperator(params: RequestReq): Promise<string | undefined> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/operator/terminate`,
            params
        }
    );
    if (res.code != 200) throw Error(res.msg || '删除角色失败')
    return res.message
}
export async function userconsumewalletRecharge(params: { amount: number, userid: string }): Promise<string | undefined> {

    const res = await defHttp.request<RequestRes>(
        {
            url: `/userconsumewallet/recharge`,
            params: {
                ...params,
                token: true
            },

        },
        { prefix: "/shared",contentType:"form" }
    );
    if (res.code != 200) throw Error(res.message || '测试钱包充值失败')
    return res.message
}

// 删除测试员角色（取消授权）
export async function terminateTester(params: RequestReq): Promise<string | undefined> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/tester/terminate`,
            params
        },
        {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    if (res.code != 200) throw Error(res.msg || '删除角色失败')
    return res.msg
}

// 删除维修员
export async function removeRepair(params: ListReq): Promise<string | undefined> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/repairman/terminate`,
            params
        },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
    if (res.code != 200) throw Error(res.msg || '删除维修员失败')
    return res.msg
}

/**
 * 
 * @param params 导出投资订单excel表
 * @returns 
 */
 export async function exportInvestUser(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportInvestorInfo`,
            responseType:'blob',
            params
            
        },{
            prefix: "/analy",
            contentType: 'json',
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}