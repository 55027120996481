import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"


export interface DeviceModel {
    equipmentSn: string
    userId: string
    userName: string
    userAccount:string
    productId:string
    productName:string
    productType:string
    gmtCreate:string
}

export async function getPrivateDeviceList(params: ListReq): Promise<ListRes<DeviceModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<DeviceModel>>(
        {
            url: `/equserprivate/pagevo`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}

/**
 * 解绑设备
 */
export async function removePrivateDeivce(_sn:string): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/equserprivate/unbind`,
            params: {
                token: true,
                sn:_sn
            },
        }, {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}
/**
 * 解绑设备
 */
export async function bindPrivateDeivce(_sn:string): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/equserprivate/bind`,
            params: {
                token: true,
                sn:_sn
            },
        }, {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}