
import { defineComponent, ref, readonly, inject } from 'vue'
import { RelationModel, getUserRoleRelation } from '@/api/Relation/index'
import routerChangeReData from '@/common/routerChangeReData'
let userId:string;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const title = ref('')
    const list = ref<RelationModel[]>([])
    const searchSelect = ref({
      search: 'userid',
    })
    const mixin_router_search = ref({
      fortest: '', //是否测试
    })

    const mixin_select_search = ref({
      search: {
        account:'',//用户账号    
        userid:userId, //用户id
        
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',    
        userid:'用户id',  
      },
    })

    const open = async(_userid: string,_Phone:string,_nickname:string) => {
      userId=_userid;
      title.value=`${_nickname}的上下级关系`
      reData();
      visible.value = true
    }

    const handleClose = () => {
      visible.value = false

    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { currentPage: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if(!rq.userid){
              rq.userid=userId
          }
          rqData = { ...rqData, ...rq }
        }
        const res = await getUserRoleRelation(rqData)
        console.log('返回数据',res)
        list.value = res.data.records
        total.value = res.data.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }

    return {
        visible,
        handleClose,
        open,
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,   
        onPageChange,
        handelChange,
        handleLimitChange,
        mixinReRouter,
        changSearch,
        searchSelect,
        list,
        loading,
        page,
        limit,
        total,   
        title,
    }
  }
})
