import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
export interface faultModel {
    id: string
    title: string
    descriptiondetails: string
    identificationId: string
    updatetime: string
}
export async function getFaultList(params: ListReq): Promise<ListRes<faultModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<faultModel>>(
        {
            url: `/eqfaultreason/page`,
            params
        },
        {
            prefix: "/shared"
        }
    );
    return res
}

export async function addFault(params:any): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/eqfaultreason/saveReasonDictionaries`,
            params
        },
        {
            prefix: "/shared"
        }
    );
    return res
}
export async function updataFault(params:any): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/eqfaultreason/updateReasonDictionaries`,
            params
        },
        {
            prefix: "/shared"
        }
    );
    return res
}