import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"

export interface OrderModel {
    "returnData": [], 
    "xAxis": [], 
    "rentData": [] 
    
}

/**
 * 
 * @param params 查询投资人的收益账单
 * @returns 
 */
export async function getOrderStatisticsHttp(params: ListReq,_fortest:boolean): Promise<ListRes<OrderModel>> {
    const res = await defHttp.request<ListRes<OrderModel>>(
        {
            url: `/statistics/statisticsYearAndMonthData`,
            params: {
                token: true,
                ...params,
                fortest:_fortest
            }
        },  
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            
        }
    );
    return res
}
/**
 * 
 * @param params 查询租借归还数据
 * @returns 
 */
export async function getOrderHttp(params: ListReq,_fortest:boolean): Promise<ListRes<OrderModel>> {
    const res = await defHttp.request<ListRes<OrderModel>>(
        {
            url: `/finance/findOrderListCount`,
            params: {
                token: true,
                ...params,
                fortest:_fortest
            }
        },  
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            
        }
    );
    return res
}


/**
 * 
 * @param params 导出租借excel表
 * @returns 
 */
export async function exportOrder(params: ListReq,_fortest:boolean): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/export/orderListExport`,
            responseType:'blob',
            params: {
                token: true,
                ...params,
                fortest:_fortest
            }
            
        },{
            prefix: "/analy",
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}


/**
 * 
 * @param params 查询登录注册数据
 * @returns 
 */
export async function getLoginHttp(params: ListReq,_fortest:boolean): Promise<ListRes<OrderModel>> {
    const res = await defHttp.request<ListRes<OrderModel>>(
        {
            url: `/finance/findUserListCount`,
            params: {
                token: true,
                ...params,
                fortest:_fortest
            }
        },  
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            
        }
    );
    return res
}

/**
 * 
 * @param params 导出登录注册excel表
 * @returns 
 */
export async function exportLogin(params: ListReq,_fortest:boolean): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/export/UserListExport`,
            responseType:'blob',
            params: {
                token: true,
                ...params,
                fortest:_fortest
            }
            
        },{
            prefix: "/analy",
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}
