
import { defineComponent, ref, computed,readonly} from "vue";
import { CouponModel, getUserCouponList } from "@/api/Financial/coupon";
import listVue from "./list.vue";
export default defineComponent({
  name: "v-dialog",
  components: {
    listVue
  }, 
  setup() {
    const title=ref('优惠卷')
    const activeName = ref("0")
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const reLoading = ref(false);
    const userId = ref("");
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<CouponModel[]>([]);
    const handleClose = () => {
      visible.value = false;
    };
  
    const reData = async () => {
      console.log('页面改变='+page.value)
      try {
        reLoading.value = true;
        let rqData: any = { current: page.value, size: limit.value,order:'gmtCreate',sort:'desc',entity:{usedStatus: activeName.value,userId:userId.value}};
        const res = await getUserCouponList(rqData);

        list.value = res.rows;
        total.value = res.total;
      } finally {
        reLoading.value = false;
      }
    };

    const open = (userid: string,name:string) => {
      userId.value=userid;
      title.value=name+"的优惠卷",
      visible.value = true;
      reData();
     
    };
    
    const handleClick = async() => {
      page.value=1;
      reData();
    }
    const pageChange = (val:number) => {
      console.log("页面数据="+val)
      page.value=val;
      reData();
    }

    const handleLimitChange = (val:number) =>{
      console.log("分页改变",val)
      limit.value=val;
      reData();
    }

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      reLoading,
      reData,
      activeName,
      handleClick,
      title,
      limit,
      page,
      total,
      list,
      pageChange,
      handleLimitChange,
    };
  },
});
