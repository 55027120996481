import { hotModuleUnregisterModule } from "@/utils/helper/vuexHelper"
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { getLocalStorage, setLocalStorage, removeLocalStorage, setToken } from '@/utils/localStore'
import type { UserInfo, UserRole } from "@/types/user"
import type { toOptional } from "@/types/utils"
import store from "@/store"
import { routerStore } from "./router";
import { roleStore } from "./role";
const NAME = 'user';
hotModuleUnregisterModule(NAME);
@Module({ namespaced: true, name: NAME, dynamic: true, store })
class User extends VuexModule {
    public info: toOptional<UserInfo> = getLocalStorage('userInfo') || {}
    public currentRole: toOptional<UserRole> = getLocalStorage('currentRole') || {}


    public get userInfo(): toOptional<UserInfo> {
        const _info = this.info
        if (_info.nickname) _info.nickname = decodeURIComponent(_info.nickname)
        return _info
    }

    public get getCurrentRole(): toOptional<UserRole> {
        return this.currentRole
    }

    @Mutation
    clearStore() {
        this.info = {}
        this.currentRole = {}
        removeLocalStorage('userInfo')
        removeLocalStorage('currentRole')
    }

    

    @Mutation
    setUserInfo(info: UserInfo) {
        if (!info) return
        this.info = info
        if (info.token) {
            setToken(info.token)
        }
        if (info.roles) {
            const _currentRole = info.roles.find(v => v.concur == '1')
            if(_currentRole?.name=='ROLE_OPERATOR'){  //运营员，获取团队id
                const opertatorList=JSON.parse(_currentRole.extInfo);
                _currentRole.operationId=opertatorList.operationIds[0];             
            }
            if (_currentRole) {
                this.currentRole = _currentRole
                setLocalStorage('currentRole', _currentRole)
            }
        }
        setLocalStorage('userInfo', info)
    }

    @Mutation
    setCurrentRole(role: UserRole) {
        this.currentRole = role
        setLocalStorage('currentRole', role)
    }
    @Mutation
    upUserInfo(info: toOptional<UserInfo>) {
        this.info = Object.assign({}, this.info, info)
        setLocalStorage('userInfo', this.info)
    }

    @Action
    loginOut() {
        this.clearStore()
        routerStore.clearStore()
        roleStore.clearStore()
        localStorage.clear();
        location.reload()
    }
}
export const userStore = getModule<User>(User);