import { xmj_admin_Http, defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
const prefix = '/local/investorOrder'
export interface InvestorOrderListRes {
    creationdate: string;
    userid: string
    account: string
    name: string
    create_time: string
}

// export async function investmentorderList(params: ListReq): Promise<ListRes<InvestorOrderListRes>> {
export async function investorOrderList(params: ListReq): Promise<any> {
    params.token = true
    const res = await xmj_admin_Http(prefix).request<ListRes<InvestorOrderListRes>>(
        {
            url: `/list`,
            method: "POST",
            params
        }
    );
    if (res.data.list) {
        res.data.list = res.data.list.map(v => {
            v.create_time = dayjs(v.create_time).format("YYYY-MM-DD HH:mm:ss")
            return v
        })
    }
    return res.data
}

export async function addInvestorOrder(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/add`,
            params
        }
    );
    return reData
}
export async function removeInvestorOrder(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/remove`,
            params
        }
    );
    return reData
}

export interface GetInvestorOrderRes {
    status: string,
    creationdate: string
    creationtime: string
}
export async function getInvestorOrder(type: 'new' | 'old', params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<ListRes<GetInvestorOrderRes>>(
        {
            url: type == 'new' ? `/investmentorder/list` : "/investorder/list",
            params
        }, {
        prefix: type == 'new' ? "/shared" : "/default"
    }
    );
    if (res.result) {
        res.result = res.result.map(v => {
            if (v.creationtime) v.creationtime = dayjs(v.creationtime).format("YYYY-MM-DD HH:mm:ss")
            return v
        })
    }
    if (res.records) {
        res.result = res.records.map(v => {
            if (v.creationdate) v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
            return v
        })
        res.total = res.totalCount
    }
    return res
}