<template>
  <el-dialog :destroy-on-close="true" :title="`标题`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef">
      <el-form-item label="是否愿意归还" prop="willreturn">
        <el-switch active-text="愿意归还" inactive-text="不愿归还" active-color="#13ce66" inactive-color="#ff4949" v-model="inputData.willreturn"> </el-switch>
      </el-form-item>
      <el-form-item label="沟通结果" prop="description">
        <el-input v-model="inputData.description" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
      </el-form-item>
      <el-form-item label="订单扣费" prop="paymentamount" v-if="!inputData.willreturn">
        <el-input-number v-model="inputData.paymentamount" :precision="2" :step="1" ></el-input-number>
      </el-form-item>
      <template v-if="inputData.willreturn">
        <el-form-item label="归还类型" prop="returntype">
          <el-select placeholder="请选择归还类型" v-model="inputData.returntype">
            <el-option label="机柜" value="cabinet"></el-option>
            <el-option label="邮寄" value="express"></el-option>
          </el-select>
        </el-form-item>
        <template v-if="inputData.returntype=='express'">
          <el-form-item label="快递公司" prop="expressbrand">
            <el-input v-model="inputData.expressbrand"></el-input>
          </el-form-item>
          <el-form-item label="快递单号" prop="expressnum">
            <el-input v-model="inputData.expressnum"></el-input>
          </el-form-item>
        </template>
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from "vue";
import { sharedorderprivatizationprocessNegotiation } from "@/api/Order/Privatization";
import Rules from "@/common/Rules";
import { deepCopy } from "@/utils";
import { ElMessage } from "element-plus";
import { userStore } from "@/store/modules/user";
let sharedorderid = "";
export default defineComponent({
  name: "v-dialog",
  emits: ["success"],
  setup(props, { emit }) {
    const inputData = ref({
      description: "愿意归还",
      willreturn: true,
      paymentamount: 199,
      returntype: "cabinet",
      expressbrand: "",
      expressnum: "",
    });
    const getInputData = computed(() => {
      const _inputData = deepCopy(inputData.value);
      console.log("_inputData :>> ", _inputData);
      if (!inputData.value.willreturn) {
        delete _inputData.returntype;
        delete _inputData.expressbrand;
        delete _inputData.expressnum;
      } else {
        delete _inputData.paymentamount;
      }
      if (inputData.value.returntype == "cabinet") {
        delete _inputData.expressbrand;
        delete _inputData.expressnum;
      }
      return _inputData;
    });
    const geRules = computed(() => {
      const _rules: any = {};
      Object.keys(getInputData.value).forEach(
        (v) => (_rules[v] = [Rules.required()])
      );
      return _rules;
    });
    const visible = ref(false);
    const formRef = ref<any>("");
    const btnSumitLoading = ref(false);
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };
    const open = (id: string) => {
      visible.value = true;
      sharedorderid = id;
    };
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        const res = await sharedorderprivatizationprocessNegotiation({
          ...getInputData.value,
          negotiatoruserid: userStore.userInfo.userid,
          sharedorderid,
        });
        ElMessage.success(res);
        emit("success");
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>