import { sharedarticleInvertOrderCount } from "@/api/Index/platfrom";
import { ref } from "vue"

export default function () {
    const device = ref({
        data: {
            name: '投放比',
            percent: [0.1],
        },
        type: 1,
        title: '投放',
        box1: {
            label: '已投放',
            value: 1,
        },
        box2: {
            label: '已投资',
            value: 1,
        },
    })

    const reDevice = async ({ productid }: { productid?: string }) => {
        const res = await sharedarticleInvertOrderCount(productid);
        console.log('res :>> ', res);
        device.value.data = {
            name: '投放比',
            percent: [parseFloat(res.ratio || 0) / 100],
        }
        device.value.box1.value = res.num;
        device.value.box2.value = res.sum;
        console.log('reDevice :>> ', device.value);
    };
    return {
        device,
        reDevice
    }
}