
import * as echarts from 'echarts';
import { ElMessageBox } from "element-plus";
import { userRoleList } from '@/api/User/role'
import { defineComponent, ref,  onMounted, nextTick, readonly } from "vue"
type EChartsOption = echarts.EChartsOption;
var myChart: echarts.ECharts;
export default defineComponent({
  setup(props){
    const dataset = ref<any>([]);
    const treeRef = ref<any>("");
    const searchValue = ref<any>("");
    const page = ref(1)
    const total = ref(0)
    const userid = ref<any>();
    const name = ref<any>();
    const searchSelect = ref({ search: 'agentname' })
    const mixin_router_search = ref({ status: '', type: 'agent' })
    const mixin_select_search = ref({
      search: {
        agentname: '',
        agentaccount: '',
        agentuserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        agentname: '姓名',
        agentaccount: '账号',
        agentuserid: 'Id',
      },
    })
    const initChart = () =>{
      const option = ref<EChartsOption> ({
        //dataset:dataset.value,
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            data: dataset.value,
            top: '1%',
            left: '20%',
            bottom: '1%',
            right: '20%',
            symbolSize: 15,
            roam:true,
            initialTreeDepth:3,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 16
            },

            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },

            emphasis: {
              focus: 'descendant'
            },

            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      });
      myChart = echarts.init(treeRef.value);
      myChart.setOption(option.value)
      console.log('参数',option.value)
    }

    const handelChange = async() => {
      //console.log("数据",mixin_select_search.value.search)
      if(!searchValue.value){
        ElMessageBox.confirm(
          `请先输入搜索内容`,
          '搜索提示',
          {
            confirmButtonText: '确定',
            type: 'warning',
            distinguishCancelAndClose: true,
            showCancelButton:false
        })
        return
      }
      try{
        myChart.showLoading();
        var searchType=searchSelect.value.search;
        console.log('搜索哈哈',searchType)
        let rqData: any = { page: 1, limit: 10}
        rqData[searchType]=searchValue.value
        const res = await userRoleList(rqData, "agent")
        var item:any={};
        if(res.result.length>0){
          if(res.result.length==1){
            item = res.result[0];
            userid.value=item.agentuserid
            name.value=item.agentname
            await getRoleData();
            reView();
          }
        }else{
          ElMessageBox.confirm(
          `搜索的用户必须是超级会员，确认搜索的内容是否正确`,
          '搜索提示',
          {
            confirmButtonText: '确定',
            type: 'warning',
            distinguishCancelAndClose: true,
            showCancelButton:false
          })
        }
      } finally {
        myChart.hideLoading()
      }
     
    }
    const changSearch = () => {
      
    }
    const getRoleData = async () =>{
      try {
        
        let rqData: any = { page: page.value, limit: 100, agentuserid:userid.value, allowlatter: 1 }
        const res = await userRoleList(rqData, "investor")
       
        dataset.value=dataset.value.concat(res.result)
       
        if(res.result.length>=100){
          page.value+=1;
          getRoleData()
        }else{
          var list=tranListToTreeData(dataset.value,userid.value);
          dataset.value=[{name:name.value,children:list}]
          myChart.hideLoading()
        }
       
      } finally {
        myChart.hideLoading()
      }
    }
    const reView = async () => {
      //myChart.resize();
      console.log('重置关系树')
      nextTick(()=>{
        initChart();
      });
     
    };

    const  tranListToTreeData = (list:Array<any>, superioruserid:string) =>{
      var arr : Array<Object>=[]
      if(superioruserid==='bf1a3be4d4432eee6491d1cedfcaaa13'){
        const otherChildren = tranListToTreeData(list, "dd47dfe9056adcfd62decea025d10e51") //查询黄彩平的原来数据关系
        var otherItem : any={name: "", children: Object}
        if (otherChildren.length) {
          otherItem.children = otherChildren
          otherItem.name="黄彩平"
        }
        arr.push(otherItem)
      }else{
        list.forEach((item:any) => {
          if (item.superioruserid === superioruserid) {     
            console.log('超级id',superioruserid)
            // 找到之后 就要去找 item 下面有没有子节点
            const children = tranListToTreeData(list, item.investoruserid)
            if (children.length) {
              // 如果children的长度大于0 说明找到了子节点
              item.children = children
              item.name=item.investorname
            }    
            item.name=item.investorname       
            arr.push(item) // 将内容加入到数组中
          }
        })
      }
      return arr
    }

    onMounted(async()=>{
      initChart();
      console.log('关系ref',treeRef)
      console.log('关系Chart',myChart)
      //await getRoleData();
      reView();
    })
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,
      searchSelect,
      treeRef,
      searchValue,
      handelChange,
      changSearch,
    }
  }
})
