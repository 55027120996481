
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  userguaranteeorderList,
  UserguaranteeorderList,
  wechatpayscoreorderComplete,
  alipayfundauthorderComplete,
} from "@/api/Order/Guarantee";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
  name: "orderManage",
  setup() {
    const btncloseLoading = ref(false);
    const loading = ref(false);

    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<any>([]);
    const searchSelect = ref({
      search: "guaranteeno",
      user: "account",
    });
    const mixin_router_search = ref({
      guaranteetype: "",
      order: "creationdate",
      sort: "desc",
      enabled: "",
      sharedorderid: "",
    });
    const mixin_select_search = ref({
      search: {
        sharedorderid: "",
        guaranteeno: "",
      },
      user: {
        account: "",
        userid: "",
      },
    });
    const handleClose = async (index: number, item: UserguaranteeorderList) => {
      try {
        await ElMessageBox.confirm(`是否强制关闭此支付分订单？`, `请确认`, {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        btncloseLoading.value = true;
        if (item.guaranteetype == "ZHIMA") {
          await alipayfundauthorderComplete(item.guaranteeno);
        } else {
          await wechatpayscoreorderComplete(item.guaranteeno);
        }
        ElMessage.success("关闭担保订单成功");
      } finally {
        btncloseLoading.value = false;
      }
    };

    const mixin_select_search_label = readonly({
      search: {
        sharedorderid: "订单号",
        guaranteeno: "担保订单号",
      },
      user: {
        account: "用户账号",
        userid: "用户ID",
      },
    });
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          if (rq.order) {
            const { order, sort } = rq;
            delete rq.order;
            delete rq.sort;
            rqData = { ...rqData, order, sort };
          }
          rqData = { ...rqData, entity: rq };
        }
        const res = await userguaranteeorderList(rqData);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      handleClose,
    };
  },
});
