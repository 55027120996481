<!-- 填写快递信息 -->
<template>
  <el-dialog
    :append-to-body="true"
    :destroy-on-close="true"
    :title="`填写快递信息`"
    @closed="handleClose"
    v-model="visible"
    width="700px"
  >
    <el-form
      :model="mailData"
      :rules="rules"
      label-width="120px"
      ref="mailDataRef">
      <el-form-item label="快递公司" prop="mailingcontact">
        <el-input v-model="mailData.expressCompany"></el-input>
      </el-form-item>
      <el-form-item label="快递单号" prop="mailingnumber">
        <el-input v-model="mailData.expressNo"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>                     
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, nextTick, readonly, ref } from "vue";
import { confirmexpress } from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {

    const visible = ref(false);

    const btnSumitLoading = ref(false);
    const orderId = ref("");
    const mailDataRef = ref<any>({});

 
    const mailData = ref({
      expressCompany: "",
      expressNo: "",
      orderid:""
    });
    const rules = readonly({
      expressCompany: [Rules.required()],
      expressNo: [Rules.required()]
    });

    const open = (row: any) => {
      console.log('订单信息',row)
      visible.value = true;
      if (!row) return;
      const { orderid } = row;
     
      orderId.value = orderid;
    };
 

    const handleClose = async () => {

      mailDataRef.value.resetFields();
      mailDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
    };



   
    const submit = async () => {
      try {
      
        mailData.value.orderid=orderId.value;
        btnSumitLoading.value = true;
        const res = await confirmexpress({expressCompany: mailData.value.expressCompany, expressNo: mailData.value.expressNo,orderid:mailData.value.orderid})
        ElMessage.success(res);
        handleClose();
        emit("success");
      } finally {
        btnSumitLoading.value = false;
      }
    };
 
    return {
      rules,
      visible,
      btnSumitLoading,
      open,
      mailData,
      mailDataRef,
      submit,
      handleClose,

    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 0 0 24px 36px;
  .el-select {
    margin-right: 21px;
  }
}

.tip-text{
  font-size: 16px;
  font-weight: bold;
}

.span-border {
  border: 1px solid #DCDFE6; 
  border-radius: 5px;
  width: 205px; 
  height: 40px; 
  display: block; 
  padding-left: 14px;
}
</style>