
import { h, onMounted, ref, computed, defineComponent, nextTick } from 'vue';
import QRCode from "qrcode"
import { saveAs } from 'file-saver';
import { ElMessageBox } from 'element-plus';

function createHiDPICanvas(c: HTMLCanvasElement, w: number, h: number, ratio?: any): CanvasRenderingContext2D {
    const PIXEL_RATIO = (function () {
        const ctx: any = c.getContext("2d"),
            dpr = window.devicePixelRatio || 1,
            bsr = ctx['webkitBackingStorePixelRatio'] ||
                ctx['mozBackingStorePixelRatio'] ||
                ctx['msBackingStorePixelRatio'] ||
                ctx['oBackingStorePixelRatio'] ||
                ctx['backingStorePixelRatio'] || 1;

        return dpr / bsr;
    })();

    if (!ratio) { ratio = PIXEL_RATIO; }
    c.width = w * ratio;
    c.height = h * ratio;
    c.style.width = w + "px";
    c.style.height = h + "px";
    const can = c.getContext("2d") as CanvasRenderingContext2D
    can.setTransform(ratio, 0, 0, ratio, 0, 0);
    return can;
}

export default defineComponent({
    name: "qr-code",
    props: {
        text: {
            type: String,
            default: '',
            required: true
        },
        size: {
            type: Number,
            default: 150,
        },
        logo: {
            type: String,
            required: false
        },
        logoSize: {
            type: Number,
            default: 20,
        },
        margin: {
            type: Number,
            default: 10,
        },
        productid : {
            type: String,
            default: '',
            required: false
        }
    },
    setup(props) {
        const url = ref("")
        const canvasRef = ref(null as unknown as HTMLCanvasElement)
        const canvasCtx = ref(null as unknown as CanvasRenderingContext2D)
        const boxWidth = computed(() => props.size + props.margin * 2)
        const boxHeight = computed(() => props.size + props.margin * 2 + 20)
        const getlogoType = computed(() => {
            switch (props.logo) {
                case 'xmj':
                    return "xmj"
                case 'yg':
                case 'zg':
                    return "zg"
                case 'xg':
                    return "xg"
                case 'cv':
                    return "bmg"
                case 'uh':
                    return "uh"
                case 'um':
                case 'umbrella':
                    return "ys"
                case 'zm':
                case 'M4S2':
                case 'phoneholder':
                    return "new_xmj"
                default:
                    return "xmj"
            }
        })
        // const handleDownload = ()=>{

        // }
        const drawBk = () => {
            canvasCtx.value.translate(0.5, 0.5);
            const ctx: CanvasRenderingContext2D = canvasCtx.value
            const margin = props.margin
            ctx.fillStyle = '#efefef'
            ctx.fillRect(0, 0, boxWidth.value, boxHeight.value)
            const img = new Image()
            img.src = url.value
            img.onload = () => {
                ctx.drawImage(img, margin, margin, props.size, props.size)
                if (props.logo) {
                    ctx.fillStyle = '#fff'
                    ctx.fillRect((boxWidth.value - props.logoSize) / 2, (boxHeight.value - 20 - props.logoSize) / 2, props.logoSize, props.logoSize)
                    const logo = new Image()
                    logo.src = require(`../../assets/img/deviceIcon/${getlogoType.value}.png`)
                    logo.onload = () => {
                        ctx.drawImage(logo, (boxWidth.value - props.logoSize + 2) / 2, (boxHeight.value - 20 - props.logoSize + 2) / 2, props.logoSize - 2, props.logoSize - 2)
                    }
                }
            }
            ctx.beginPath();
            ctx.fillStyle = "#4a4d4c";
            ctx.font = 'bold 14px 微软雅黑';
            ctx.textBaseline = "top"
            ctx.textAlign = "center";
            ctx.fillText(props.text, props.size / 2 + margin, props.size + margin * 2);
        }
        onMounted(async () => {
            if(props.productid&&props.productid=='589de4ba1652454fa4b47e7458044328'){
                url.value = await QRCode.toDataURL(`https://xmj.smartline.com.cn/xmjprivate/${props.text}`, {
                    errorCorrectionLevel: 'H',
                })
            }else{
                url.value = await QRCode.toDataURL(`https://xmj.smartline.com.cn/xmj/${props.text}`, {
                    errorCorrectionLevel: 'H',
                })
            }
           
            canvasCtx.value = createHiDPICanvas(canvasRef.value, boxWidth.value, boxHeight.value, 2)
            await nextTick(() => {
                drawBk()
            })

        })

        const downLoad = async () => {
            try {
                await ElMessageBox.confirm(
                    `确定下载二维码吗？`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                );
                const base64 = canvasRef.value.toDataURL("image/png")
                saveAs(base64, `${props.text}.png`);
            } catch (error) {
                console.log('downLoad error :>> ', error);
            }
        }

        return {
            canvasRef,
            downLoad
        }
    },
    render() {
        return [
            h('canvas', {
                ref: 'canvasRef',
                style: {
                    cursor: 'pointer'
                },
                onClick: () => {
                    this.downLoad()
                }
            }, [
                h("p", null, '你的浏览器不支持Canvas')
            ])
        ]
    }
})