<template>
  <div class="container">
    <el-alert
      title="1.日期筛选建议最长不要超过一个月，用户钱包没有日期筛选效果 2.测试和正式对旧提现及旧充值没有筛选效果,"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
     <!-- <el-alert
      title="表格只统计用户充入平台的金钱总和和提现出去的金钱总和，共享收入属于用户租借小魔夹或者雨伞后支付到平台的金钱总和，不是分成后的钱"
      type="warning"
      show-icon
      :closable="false">
    </el-alert> -->
    <el-alert
      title="新旧充值和新旧提现，划分是以2021-05左右，新的充值包含了会员投资小魔夹下单的金钱"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <!-- <el-alert
      title="共享收入，用户租借小魔夹或者雨伞后支付到平台的金钱总和，不包括会员投资小魔夹扣款，余额的金钱属于充值到平台的钱，只做参考，不计入到共享收入总额"
      type="warning"
      show-icon
      :closable="false">
    </el-alert> -->
    <header style="margin-top: 12px;" >
   
      <el-select @change="handelChange" class="header-el-selector" placeholder="是否为正式" v-model="search.fortest">
        <el-option :value="true" label="测试"></el-option>
        <el-option :value="false" label="正式"></el-option>
      </el-select>
    
      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="结束时间"
        range-separator="至"
        start-placeholder="开始时间"
        type="datetimerange"
        unlink-panels
        v-model="search.timeBN"
      ></el-date-picker>
    
    </header>
    <div style="margin-top: 12px;">
      <!-- <el-table  border style="width: 100%" >
        <el-table-column label="类型" min-width="180" align="center">
          
            <template #default="scope">
              <span v-if="scope.row.name=='newcharge'">新充值</span>   
              <span v-else-if="scope.row.name=='oldcharge'">旧充值</span>   
              <span v-else-if="scope.row.name=='oldwithdraw'">旧提现</span>  
              <span v-else-if="scope.row.name=='newwithdraw'">新提现</span> 
              <span v-else-if="scope.row.name=='shareorder'">订单消费</span>  
              <span v-else-if="scope.row.name=='balance'">用户钱包</span> 
              <span v-else>用户钱包</span> 
            </template>
        </el-table-column>
        <el-table-column prop="type" label="支付方式" min-width="210" align="center">
           
            <template #default="scope">
              <span v-if="scope.row.type=='1'||scope.row.type=='ZHIMA'||scope.row.type=='alipay'">支付宝</span>   
              <span v-else-if="scope.row.type=='2'||scope.row.type=='PAYSCORE'||scope.row.type=='wechatpay'">微信</span>   
              <span v-else-if="scope.row.type=='BALANCE'">押金</span>  
              <span v-else-if="scope.row.type=='d965476559f0428483b03f08941cda39'">雨伞</span>  
              <span v-else>小魔夹</span>  
            </template>
        </el-table-column>
        <el-table-column prop="money" label="金额" min-width="320" align="center">
   
        </el-table-column>
      </el-table> -->
      <div class="table-box">
        <div class="table-row" style="border-bottom: 1px solid #DCDFE6;">
          <div class="title-1" style="font-weight: bold;">类型</div>
          <div class="title-2">
            <div class="title-2-1" style="border-left: 1px solid #DCDFE6; font-weight: bold;">支付方式</div>
            <div class="title-2-1" style="border-left: 1px solid #DCDFE6; font-weight: bold;">总金额</div>    
            <div class="title-2-2" style="font-weight: bold; border-left: 1px solid #DCDFE6;">操作</div>       
          </div>
           
        </div>
        <div class="table-row" style="border-bottom: 1px solid #DCDFE6;">
          <div class="table-row-1">旧充值</div>
          <div class="table-row-2">
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6;"> 
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的充值，普通的支付宝充值到平台" placement="top">
                <span class="table-row-2-2" >支付宝</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#01C446">￥ {{oldAlipayCharge}}</span>  
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('oldCharge','支付宝','alipay')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1">
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的充值，普通的微信充值到平台" placement="top">
                <span class="table-row-2-2" >微信</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#01C446">￥ {{oldWechatpayCharge}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('oldCharge','微信','wechatpay')">详情</el-button>
              </div>
            </div>          
          </div>
          
        </div>
        <div class="table-row" style="border-bottom: 1px solid #DCDFE6;">
          <div class="table-row-1">新充值</div>
          <div class="table-row-2">
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6;"> 
             <el-tooltip style="margin: 4px;" effect="dark" content="用户的充值，包括普通的支付宝充值（小魔夹，雨伞）含押金、投资小魔夹时候的支付宝充值" placement="top">
               <span class="table-row-2-2" >支付宝</span>
             </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#01C446">￥ {{newAlipayCharge}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('charge','支付宝','alipay')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1">
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的充值，包括普通的微信充值（小魔夹，雨伞）含押金、投资小魔夹时候的微信充值" placement="top">
                <span class="table-row-2-2" >微信</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#01C446">￥ {{newWechatpayCharge}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('charge','微信','wechatpay')">详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-row" style="border-bottom: 1px solid #DCDFE6;">
          <div class="table-row-1">旧提现</div>
          <div class="table-row-2">
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6;"> 
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的提现，通过支付宝从平台提现出去的金额" placement="top">
                <span class="table-row-2-2" >支付宝</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#c00000">￥ {{oldAlipayWithdraw}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('oldWithdraw','支付宝','alipay')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1">
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的提现，通过微信从平台提现出去的金额" placement="top">
                <span class="table-row-2-2" >微信</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#c00000">￥ {{oldWechatpayWithdraw}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini"  @click="handleClick('oldWithdraw','微信','wechatpay')">详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-row" style="border-bottom: 1px solid #DCDFE6;">
          <div class="table-row-1">新提现</div>
          <div class="table-row-2">
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6;"> 
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的提现，通过支付宝从平台提现出去的金额" placement="top">
                <span class="table-row-2-2" >支付宝</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#c00000">￥ {{newAlipayWithdraw}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('newWithdraw','支付宝','alipay')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1">
              <el-tooltip style="margin: 4px;" effect="dark" content="用户的提现，通过微信从平台提现出去的金额" placement="top">
                <span class="table-row-2-2" >微信</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#c00000">￥ {{newWechatpayWithdraw}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('newWithdraw','微信','wechatpay')">详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-row"  style="border-bottom: 1px solid #DCDFE6;">
          <div class="table-row-1" style="height: 240px; line-height: 240px;">共享收入</div>
          <div class="table-row-2" style="height: 240px;">
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6; " > 
              <el-tooltip style="margin: 4px;" effect="dark" content="租借共享设备（小魔夹）后从支付宝扣除的金额，共享设备不包括雨伞(雨伞是从余额扣除)" placement="top">
                <span class="table-row-2-2" >支付宝</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#01C446">￥ {{shareorderAlipay}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('shareOrder','支付宝','ZHIMA')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6;">
              <el-tooltip style="margin: 4px;" effect="dark" content="租借共享设备（小魔夹）后从微信扣除的金额，共享设备不包括雨伞(雨伞是从余额扣除)" placement="top">
                <span class="table-row-2-2" >微信</span>
              </el-tooltip>     
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#01C446">￥ {{shareorderWechatpay}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini" @click="handleClick('shareOrder','微信','PAYSCORE')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1" style="border-bottom: 1px solid #DCDFE6;">
              <el-tooltip style="margin: 4px;" effect="dark" content="余额的钱从充值进来，扣费包含租赁订单（小魔夹，雨伞）扣费，投资小魔夹扣费" placement="top">
                <span class="table-row-2-2" style="color:#cecece">余额</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#cecece">￥ {{shareorderBalance}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini"  @click="handleClick('shareOrder','余额','BALANCE')">详情</el-button>
              </div>
            </div>
            <div class="table-row-2-1" >
              <el-tooltip style="margin: 4px;" effect="dark" content="包含普通订单退款和超期扣199的退款" placement="top">
                <span class="table-row-2-2" style="color:#cecece">订单退款</span>
              </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6; color:#cecece">￥ {{returnMoney}}</span>
              <div class="table-row-2-3" style="border-left: 1px solid #DCDFE6; color:#01C446">
                <el-button type="primary" icon="el-icon-tickets" size="mini"  @click="handleClick('refund','退款','BALANCE')">详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-row" >
          <div class="table-row-1" style="height: 60px; line-height: 60px;">用户钱包</div>
          <div class="table-row-2" style="height: 60px;">
            <div class="table-row-2-1" style="height: 100%;"> 
             <el-tooltip style="margin: 4px;" effect="dark" content="用户当前钱包余额，主要来源充值，收益（租赁50%，一级6%，代理2.5%，城市合伙人3.5%），推广奖励（10元、100元），订单退款" placement="top">
              <span class="table-row-2-2" >总余额</span>
             </el-tooltip>
              <span class="table-row-2-2" style="border-left: 1px solid #DCDFE6;">￥ {{userBalance}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <oldChangeDetailsDialog ref="oldChangeDetailsDilaogRef"></oldChangeDetailsDialog>
    <newChangeDetailsDialog ref="newChangeDetailsDialogRef"></newChangeDetailsDialog>
    <oldWithdrawDetailsDialog ref="oldWithdrawDetailsDialogRef"></oldWithdrawDetailsDialog>
    <newWithdrawDetailsDialog ref="newWithdrawDetailsDialogRef"></newWithdrawDetailsDialog>
    <shareOrderDetailsDialog ref="shareOrderDetailsDialogRef"></shareOrderDetailsDialog>
    <shareOrderBalanceDetailsDialog ref="shareOrderBalanceDetailsDialogRef"></shareOrderBalanceDetailsDialog>
    <refundDetailsDialog ref="refundDetailsDialogRef"></refundDetailsDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted} from 'vue'
import { getInAndOutTotalMoney ,totalMoneyModel} from "@/api/Financial/inAndOutTotalMoney"
import dayjs from 'dayjs'
import oldChangeDetailsDialog from '@/components/financial/inAdnOut/oldChangeDetailsDialog.vue'
import newChangeDetailsDialog from '@/components/financial/inAdnOut/newChangeDetailsDialog.vue'
import oldWithdrawDetailsDialog from '@/components/financial/inAdnOut/oldWithdrawDetailsDialog.vue'
import newWithdrawDetailsDialog from '@/components/financial/inAdnOut/newWithdrawDetailsDialog.vue'
import shareOrderDetailsDialog from '@/components/financial/inAdnOut/shareOrderDetailsDialog.vue'
import shareOrderBalanceDetailsDialog from '@/components/financial/inAdnOut/shareOrderBalanceDetailsDialog.vue'
import refundDetailsDialog from '@/components/financial/inAdnOut/refundDetailsDialog.vue'
export default defineComponent({
  name: 'in-and-out-total-money',
  components: {
    oldChangeDetailsDialog,
    newChangeDetailsDialog,
    oldWithdrawDetailsDialog,
    newWithdrawDetailsDialog,
    shareOrderDetailsDialog,
    shareOrderBalanceDetailsDialog,
    refundDetailsDialog,
  },
  setup() {
    const search = ref({
      fortest: false, //是否用于测试，如：true 是 false 否
      timeBN: ''
    })
    const loading = ref(false)
    const list = ref<any[]>([])
    const oldAlipayCharge = ref("0");
    const oldWechatpayCharge = ref("0");
    const newAlipayCharge = ref("0");
    const newWechatpayCharge = ref("0");
    const oldAlipayWithdraw = ref("0");
    const oldWechatpayWithdraw = ref("0");
    const newAlipayWithdraw = ref("0");
    const newWechatpayWithdraw = ref("0");
    const shareorderAlipay = ref("0");
    const shareorderWechatpay = ref("0");
    const shareorderBalance = ref("0");
    const returnMoney = ref("0");
    const userBalance = ref("0");

    const oldChangeDetailsDilaogRef = ref<any>()
    const newChangeDetailsDialogRef = ref<any>()
    const oldWithdrawDetailsDialogRef = ref<any>()
    const newWithdrawDetailsDialogRef = ref<any>()
    const shareOrderDetailsDialogRef = ref<any>()
    const shareOrderBalanceDetailsDialogRef = ref<any>()
    const refundDetailsDialogRef = ref<any>()
    // const objectSpanMethod = ({ row , column , rowIndex , columnIndex  }) => {
    //     if (columnIndex === 0) {
    //         if(rowIndex<8){
    //             if( rowIndex % 2 === 0){
    //                 return {
    //                   rowspan: 2,
    //                   colspan: 1
    //                 }
    //             }else{
    //                 return {
    //                   rowspan: 0,
    //                   colspan: 0
    //                 }
    //             }
    //         }else{
    //             if(rowIndex==8){
    //                 return {
    //                   rowspan: 3,
    //                   colspan: 1
    //                 }
    //             }else{
    //                 return {
    //                   rowspan: 0,
    //                   colspan: 0
    //                 }
    //             }
    //         }
    //         // if(rowIndex > orderLength.value+1+9){
    //         //     return {
    //         //     rowspan: 0,
    //         //     colspan: 0
    //         //     };
    //         // } else if(rowIndex==9){
    //         //     return {
    //         //     rowspan: orderLength.value+1,
    //         //     colspan: 0
    //         //     };
    //         // }else if (rowIndex<9 && rowIndex % 2 === 0) {
    //         //     return {
    //         //     rowspan: 2,
    //         //     colspan: 1
    //         //     };
    //         // } else {
    //         //     return {
    //         //     rowspan: 0,
    //         //     colspan: 0
    //         //     };
    //         // }
    //     }
    // }

    const reData = async () => {
      oldAlipayCharge.value = "0";
      oldWechatpayCharge.value = "0";
      newAlipayCharge.value = "0";
      newWechatpayCharge.value = "0";
      oldAlipayWithdraw.value = "0";
      oldWechatpayWithdraw.value = "0";
      newAlipayWithdraw.value = "0";
      newWechatpayWithdraw.value = "0";
      shareorderAlipay.value = "0";
      shareorderWechatpay.value = "0";
      shareorderBalance.value = "0";
      userBalance.value = "0";
      try {
        let rqData : any = {fortest:search.value.fortest}
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        const res = await getInAndOutTotalMoney(rqData);
       // orderLength.value=res.data!.shareorder.length;
        res.data?.oldcharge.forEach(item =>{
          if(item.type=='1'){
            oldAlipayCharge.value=item.money
          }else if(item.type=='2'){
            oldWechatpayCharge.value=item.money
          }
        })
         res.data?.newcharge.forEach(item =>{
          if(item.type=='alipay'){
            newAlipayCharge.value=item.money
          }else if(item.type=='wechatpay'){
            newWechatpayCharge.value=item.money
          }
        })
        res.data?.oldwithdraw.forEach(item =>{
          if(item.type=='1'){
            oldAlipayWithdraw.value=item.money
          }else if(item.type=='2'){
            oldWechatpayWithdraw.value=item.money
          }
        })
        res.data?.newwithdraw.forEach(item =>{
          if(item.type=='alipay'){
            newAlipayWithdraw.value=item.money
          }else if(item.type=='wechatpay'){
            newWechatpayWithdraw.value=item.money
          }
        })
        res.data?.shareorder.forEach(item =>{
          if(item.type=='ZHIMA'){
            shareorderAlipay.value=item.money
          }else if(item.type=='PAYSCORE'){
            shareorderWechatpay.value=item.money
          }else if(item.type=='BALANCE'){
            shareorderBalance.value=item.money
          }
        })
        res.data?.balance.forEach(item =>{
          if(item.type=='56c62a11a6a14d6eb7ab29fbf9f2f8b4'){
            userBalance.value=item.money
          }
        })
        res.data?.return.forEach(item =>{
          returnMoney.value=item.money
        })
        // list.value.push({name:"newcharge",data:res.data['newcharge'][0]})
        // list.value.push({name:"newcharge",data:res.data['newcharge'][1]})
        // list.value.push({name:"oldcharge",data:res.data['oldcharge'][0]})
        // list.value.push({name:"oldcharge",data:res.data['oldcharge'][1]})
        // list.value.push({name:"newwithdraw",data:res.data['newwithdraw'][0]})
        // list.value.push({name:"oldwithdraw",data:res.data['oldwithdraw'][1]})
        
        // list.value.push({name:"shareorder",data:res.data['shareorder'][i]})
        
        // list.value.push({name:"balance",data:res.data['balance']})
      
      }catch(err){

      } finally {
        loading.value = false
      }
    }

    const handelChange = () => {
      reData();
    }
    onMounted(()=>{
      reData();
    }) 
    const handleClick =(type:string,name:string,rechargemode:string) =>{
      if(type=='oldCharge'){
        oldChangeDetailsDilaogRef.value.open(type,name,rechargemode)
      }else if(type == 'charge'){
        newChangeDetailsDialogRef.value.open(type,name,rechargemode)
      }else if(type=='oldWithdraw'){
        oldWithdrawDetailsDialogRef.value.open(type,name,rechargemode)
      }else if(type=='newWithdraw'){
        newWithdrawDetailsDialogRef.value.open(type,name,rechargemode)
      }else if(type=="shareOrder"){
        if(rechargemode=='BALANCE'){
          shareOrderBalanceDetailsDialogRef.value.open(type,name,rechargemode)
        }else{
          shareOrderDetailsDialogRef.value.open(type,name,rechargemode)
        }
      }else if(type=='refund'){
        refundDetailsDialogRef.value.open(type,name,rechargemode)
      }
      

    }
    return {
     loading,
     search,
     list,
    //  objectSpanMethod,
     oldAlipayCharge,
     oldWechatpayCharge,
     newAlipayCharge,
     newWechatpayCharge,
     oldAlipayWithdraw,
     oldWechatpayWithdraw,
     newAlipayWithdraw,
     newWechatpayWithdraw,
     shareorderAlipay,
     shareorderWechatpay,
     shareorderBalance,
     returnMoney,
     userBalance,
     handelChange,
     handleClick,
     oldChangeDetailsDilaogRef,
     newChangeDetailsDialogRef,
     oldWithdrawDetailsDialogRef,
     newWithdrawDetailsDialogRef,
     shareOrderDetailsDialogRef,
     shareOrderBalanceDetailsDialogRef,
     refundDetailsDialogRef,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
  .table-box{
    width: 80%;
    border: 1px solid #DCDFE6;
    
    .table-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .title-1{
        width: 20%;
        text-align: center;
        line-height: 48px;
        height: 48px;
      }
      .title-2{
        width: 80%;
        height: 48px;
        display: flex;
        flex-direction: row;
        .title-2-1{
          width: 40%;
          height:100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .title-2-2{
          width: 20%;
          height:100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .title-3{
        width: 10%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .table-row-1{
        width: 20%;
        text-align: center;
        line-height: 120px;
        height: 120px;
      }
      .table-row-2{
        width: 80%;
        height: 120px;
        display: flex;
        flex-direction: column;
        .table-row-2-1{
          width: 100%;
          height: 50%;
          display: flex;
          flex-direction: row;
          border-left: 1px solid #DCDFE6;
  
          .table-row-2-2{
            width: 40%;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .table-row-2-3{
            width: 20%;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
       
      }
     
    }
  }
}
</style>