import { h, defineComponent, ref, toRef, onMounted, watch, nextTick, watchEffect, onUnmounted } from 'vue';
import echarts from "./base"
import {
    PieChart
} from 'echarts/charts';
import { unwarp } from '@/utils';
echarts.use(
    [PieChart]
);
export default defineComponent({
    name: "my-pie",
    props: ["option", 'events'],
    setup(props) {
        const option = toRef(props, 'option')
        const events = toRef(props, 'events')
        const pieRef = ref<any>("")
        const myChart = ref<any>('')
        onMounted(async () => {
            myChart.value = echarts.init(pieRef.value);
            if (events.value) {
                Object.keys(events.value).map(v => {
                    myChart.value.on(v, events.value[v]);
                })
            }
            watchEffect(async () => {
                myRender(option.value)
            })
        })
        const myRender = async (_option?: any) => {
            await nextTick()
            myChart.value.setOption && unwarp(myChart.value).setOption(option.value)
        }
        const myResize = async () => {
            await nextTick()
            myChart.value.resize && myChart.value.resize()
        }
        return {
            pieRef,
            myRender,
            myResize
        }
    },
    render() {
        return h('div', {
            ref: 'pieRef'
        })
    }
})