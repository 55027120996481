
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import firmwareDialog from './components/firmwareDialog.vue'
import { firmwareLast, ProductList, productList,updateXMJVersion } from '@/api/System/product'
import { saveAs } from 'file-saver'
import { ProductInfo, productInfoList } from '@/api/util'
import editVersionDialog from './components/editVersionDialog.vue'
import { ElMessage } from "element-plus";
import axios from 'axios'
import { defHttp } from '@/utils/http/axios'
export default defineComponent({
  name: 'product',
  components: { firmwareDialog,editVersionDialog },
  setup() {
    const loading = ref(false)
    const downloadLoading = ref(false)
    const updateVersionLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<ProductList[]>([])
    const firmwareDialogRef = ref<any>()
    const editVersionDialogRef = ref<any>()
    const searchSelect = ref({})
    const myProductInfoList = productInfoList
    const mixin_router_search = ref({ productid: '' })
    const mixin_select_search = ref({ search: {} })
    const mixin_select_search_label = readonly({ search: {} })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, ...rq }
        }
        const { result, totalCount } = await productList(rqData)
        list.value = result
        total.value = totalCount
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleUpdateDialog = (row: ProductInfo) => {
      firmwareDialogRef.value.open(row)
    }
    const handleUpdateVersionDialog = (productId:string) => {
      editVersionDialogRef.value.open(productId);
    }
    const handleOpen = async (e: ProductList) => {
      console.log('e :>> ', e)
      list.value[e.idx].open = !list.value[e.idx].open // 开启关闭状态
      if (!list.value[e.idx].open) return
      const firmwareDetail = await firmwareLast(e.productid)
      list.value[e.idx].firmwareDetail = firmwareDetail
    }
    const handlerUpdateVersion = async(productId:string,version:string) => {
      try{
          console.log('产品id=',productId);
          console.log('版本号=',version);
          updateVersionLoading.value = true;
          const res =  await updateXMJVersion({ productid:productId,version:version });
          ElMessage.success(res);
        } finally {
          updateVersionLoading.value = false;
        }
    }

    const downLoadFirmware = async (index: number, url: string) => {
      try {
        downloadLoading.value = true
        const item = list.value[index]
        // const res = (await axios({
        //   url:url.replace("http://xmjoss.oss-cn-hangzhou.aliyuncs.com/",'aliyuncs/'),
        //   method: 'GET',
        //   responseType: 'blob',
        // })) as unknown as Blob
        // const blob = new Blob([res], { type: 'application/octet-stream' })
        // const res = (await axios.get(url, {
        //   responseType: 'blob',
        // })) as unknown as Blob
        // console.log('res :>> ', res)
        saveAs(url, `${item.name}_v_${item.firmwareDetail?.version}.bin`)
        // saveAs(blob, `${item.name}_v_${item.firmwareDetail?.version}.bin`)
        // URL.revokeObjectURL(url)
      } finally {
        downloadLoading.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      updateVersionLoading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      myProductInfoList,
      firmwareDialogRef,
      editVersionDialogRef,
      handleOpen,
      handleUpdateDialog,
      handleUpdateVersionDialog,
      downLoadFirmware,
      handlerUpdateVersion,
    }
  },
})
