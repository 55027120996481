import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes,ListRes,ListResLoad } from "../../types/requestBaseType"

export interface totalMoneyListModel {
    newcharge: totalMoneyModel[]
    oldcharge: totalMoneyModel[]  //1 支付宝充值 2 微信充值
    newwithdraw: totalMoneyModel[]  
    oldwithdraw: totalMoneyModel[]  //1 支付宝转账 2 微信转账
    shareorder: totalMoneyModel[]
    balance: totalMoneyModel[]
    return:totalMoneyModel[]
}

export interface totalMoneyModel {
    type?: string
    money: string
}

export interface detaildModel {
    CreationTime?: string
    money?: string
    nickname?: string
    creationdate?:string
    approved?:string
    transfertime?:string
    payeeaccount?:string
    payeerealname?:string
    creationtime?:string
    sharedorderid?:string
    articleproductname?:string
    deductionType?:string
    articleequipmentsn?:string
    deductiondate?:string
    amount?:string
    refundType?:string
    orderid?:string
    refunded?:string
    refunddate?:string
    date?:string
    account?:string

}

export interface totalModel {
    money?:string
}

/**
 * 获取总额
 */
 export async function getInAndOutTotalMoney(parmas: RequestReq): Promise<RequestRes<totalMoneyListModel>> {
    const res = await defHttp.request<RequestRes<totalMoneyListModel>>(
        {
            url: `/finance/getInAndOutTotalMoney`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}

/**
 * 获取区间总额
 */
export async function getInAndOutTimeTotalMoney(parmas: RequestReq): Promise<RequestRes<totalMoneyListModel>> {
    const res = await defHttp.request<RequestRes<totalMoneyListModel>>(
        {
            url: `/finance/findShareSumMoney`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}


/**
 * 获取统计列表数据
 */
 export async function getInAndOutDetails(parmas: RequestReq): Promise<ListRes<detaildModel>> {
    const res = await defHttp.request<ListRes<detaildModel>>(
        {
            url: `/finance/getTotalDetailed`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}

/**
 * 获取统计列表数据
 */
export async function getInAndOutDetailsList(parmas: RequestReq): Promise<ListResLoad<detaildModel>> {
    const res = await defHttp.request<ListResLoad<detaildModel>>(
        {
            url: `/finance/findShareList`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}

/**
 * 导数数据
 */
 export async function exportInAndOutDetails(parmas: RequestReq): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/export/exportTotalDetailed`,
            params: {
                token: true,
                ...parmas
            },
            responseType:'blob',
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
}