
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { CouponModel, getUserCouponList } from "@/api/Financial/coupon";
import { userList } from "@/api/User/index";
export default defineComponent({
  setup() {
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<CouponModel[]>([]);
    const userInfoList=ref<any>();
    const searchSelect = ref({
      search: "userId",
    });
    const mixin_router_search = ref({
      order: 'gmtCreate',
      sort: 'desc',
      usedStatus: "",
    });
    const mixin_select_search = ref({
      search: {
        account:"",
        userId: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',
        userId: "用户ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
            if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await getUserCouponList(rqData);
        console.log("res.records :>> ", res.records);
        list.value = res.rows;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
   
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      getRelData();
      //mixinReRouter();
    };
    const getRelData = () =>{
      if(mixin_select_search.value.search.account){
        getUserInfo(mixin_select_search.value.search.account);
      }else{
        mixinReRouter();
      }
    }

    const getUserInfo = async (account: string) => {
      try {
       
        let rqData: any = { page: "1", limit: "10" ,account:account};
       
        const res = await userList(rqData);
        
        console.log('用户信息')
        console.log(res)
        userInfoList.value = res.result;
        mixin_select_search.value.search.userId=userInfoList.value[0].userid;
        
      } finally {

        mixinReRouter();
      }
    };

    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
    };
  },
});
