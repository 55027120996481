
import { AllWallets, userconsumewalletAll, getUserInfo } from "@/api/User";
import AvatarDialog from "./components/avatarDialog.vue";
import dayjs from "dayjs";
import { defineComponent, inject, onMounted, ref, toRef } from "vue";
import { toOptional } from "@/types/utils";
import { UserInfo } from "@/types/user";
export default defineComponent({
  props: ["userid"],
  components: {
    "v-avatar-dialog": AvatarDialog,
  },
  setup(props) {
    const loading = ref(false);
    const applyLoading = ref(false);
    const isHover = ref(false);
    const userInfo = ref<toOptional<UserInfo>>({});
    const walletInfo = ref<toOptional<AllWallets>>({});
    const walletInfoTest = ref<toOptional<AllWallets>>({});
    const userid = toRef(props, "userid");
    const reData = async () => {
      try {
        loading.value = true;
        console.log("userid :>> ", userid);
        userInfo.value = await getUserInfo(userid.value);
        const res = await userconsumewalletAll(userid.value);
        console.log("res :>> ", res);
        walletInfo.value = res.userAllWallets;
        walletInfoTest.value = res.userAllWalletsTest;
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      reData();
    });
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      loading,
      applyLoading,
      isHover,
      userInfo,
      walletInfo,
      walletInfoTest,
      toPath_RouterSearchMixin,
    };
  },
});
