// axios配置  可自行根据项目进行更改，只需更改该文件即可，其他文件可以不动
// The axios configuration can be changed according to the project, just change the file, other files can be left unchanged

import type { AxiosResponse } from 'axios';
import type { CreateAxiosOptions, RequestOptions, Result } from './types';
import { ElMessage } from "element-plus"
import { VAxios } from './Axios';
import { getToken } from '@/utils/localStore';
import type { AxiosTransform } from './axiosTransform';
import { showErrorMessage } from "./requestResult"
const { baseURL } = require("@/config/setting")
import { isString } from '@/utils/is';
import { formatRequestDate } from '@/utils/dateUtil';
import { setObjToUrlParams, deepMerge } from '@/utils/index';
import { errorStore } from '@/store/modules/error';
import { requestError } from "./requestResult";
import JSONBig from 'json-bigint'

import Cookies from 'js-cookie'

import Qs from "qs"
import { userStore } from '@/store/modules/user';


function getHeader(ontentType?: "json" | "form") {
  switch (ontentType) {
    case 'json':
      return { 'Content-Type': 'application/json' }
    case 'form':
      return { 'Content-Type': 'application/x-www-form-urlencoded' }
    default:
      return { 'Content-Type': 'application/x-www-form-urlencoded' }
  }
}
/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
  /**
   * @description: 处理请求数据
   */
  transformRequestData: async (res: AxiosResponse<Result>, options: RequestOptions) => {
    // console.log('第一次')
    // console.log(res)
    const { isTransformRequestResult,isFile } = options;
    //console.log('返回配置=',options)
    if(isFile){
      return res
    }
    // 不进行任何处理，直接返回
    // 用于页面代码可能需要直接获取code，data，message这些信息时开启
    if (!isTransformRequestResult) {
      return res.data;
    }
    // 错误的时候返回
    try {
      //console.log(res.data);
      const _res = await requestError(res)
      console.log('request success :>> ', _res);
      return _res
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // 请求之前处理config
  beforeRequestHook: (config, options) => {

    const { apiUrl, joinParamsToUrl, formatDate, prefix, headers, contentType } = options;
    if (headers) {
      config.headers = headers
    } else if (prefix == '/shared') {
      if (config.headers) {
        config.headers['Content-Type'] = 'application/json'
      } else {
        config.headers = { 'Content-Type': 'application/json' }
      }
    }
    if (contentType) {
      config.headers = { ...config.headers, ...getHeader(contentType) }
      console.log('config.headers  :>> ', config.headers);
    }

    config.url = `${prefix}${config.url}`;

    // config.url = prefix ? `${prefix}${config.url}` : config.url;

    if (apiUrl && isString(apiUrl)) {
      config.url = `${apiUrl}${config.url}`;
    }

    if (config.method === "GET") {
      const now = new Date().getTime();
      const isAddToken = config.params?.token && typeof (config.params.token) == 'boolean'
      if (isAddToken) config.params.token = userStore.userInfo.token
      if (!isString(config.params)) {
        config.data = {
          // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
          params: Object.assign(config.params || {}, {
            _t: now,
          }),
        };
      } else {
        // 兼容restful风格
        config.url = config.url + config.params + `?_t=${now}`;
        config.params = undefined;
      }
    } else {
      if (!isString(config.params)) {
        formatDate && formatRequestDate(config.params);
        // config.data = config.params;
        // 添加token和userid
        const isAddToken = config.params.token && typeof (config.params.token) == 'boolean'
        const isAddUserId = config.params.userid && typeof (config.params.userid) == 'boolean'
        if (isAddUserId) config.params.userid = userStore.userInfo.userid
        if (config.headers && config.headers['Content-Type'] == 'application/json') {
          if (isAddToken) {
            config.url = config.url + `?token=${userStore.userInfo.token}`
            if(config.params.fileName){
              config.url = config.url+`&fileName=`+config.params.fileName
              delete config.params.fileName
            }
            delete config.params.token
          }
         
          config.data = config.params
        } else {
          if (isAddToken) {
            if (prefix == "/analy") {
              config.url = config.url + `?token=${userStore.userInfo.token}`
              // if(config.params.fileName){
              //   config.url = config.url+`&fileName=`+config.params.fileName
              //   delete config.params.fileName
              // }
              delete config.params.token
            } else {
              config.params.token = userStore.userInfo.token
            }
          }
          config.data = Qs.stringify(config.params);
        }
        if (joinParamsToUrl) {
          config.url = setObjToUrlParams(config.url as string, config.params);
        }
      } else {
        // 兼容restful风格
        config.url = config.url + config.params;
      }
      delete config.params;
    }
    return config;
  },

  /**
   * @description: 请求拦截器处理
   */
  requestInterceptors: (config) => {
    // 请求之前处理config
    const token = getToken();
    if (token) {
      // jwt token
      config.headers.Authorization = token;
    }
    return config;
  },

  /**
   * @description: 响应错误处理
   */
  responseInterceptorsCatch: (error: any) => {
    console.log('errorerrorerrorerrorerror :>> ', error);
    errorStore.setupErrorHandle(error);
    const { response, code, message } = error || {};
    const msg: string = response?.data?.error?.message || '';
    const err: string = error.toString();
    try {
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        ElMessage.error(err)
      }
      if (err && err.includes('Network Error')) {
        ElMessage.error(err)
      }
    } catch (error) {
      return Promise.reject(error);
    }
    showErrorMessage(error?.response?.data || error)
    return Promise.reject(error);
  },
};

function createAxios(opt?: Partial<CreateAxiosOptions>) {
  return new VAxios(
    deepMerge(
      {
        timeout: 40 * 1000,
        // 基础接口地址
        // baseURL: globSetting.apiUrl,
        // 接口可能会有通用的地址部分，可以统一抽取出来
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        // 数据处理方式
        transform,
        method: "POST",
        // 配置项，下面的选项都可以在独立的接口请求中覆盖
        requestOptions: {
          // 默认将prefix 添加到url
          joinPrefix: true,
          // 需要对返回数据进行处理
          isTransformRequestResult: true,
          // post请求的时候添加参数到url
          joinParamsToUrl: false,
          // 格式化提交参数时间
          formatDate: true,
          // 消息提示类型
          errorMessageMode: 'none',
          prefix: "/default",
        },
      },
      opt || {}
    )
  );
}


// export const defHttp = createAxios();
export const defHttp = createAxios();

export const xmj_admin_Http = (prefix: string) => {
  return createAxios({
    requestOptions: {
      headers: {
        'x-csrf-token': Cookies.get("csrfToken"), // 前后端不分离的情况加每次打开客户端，egg会直接在客户端的 Cookie 中写入密钥 ，密钥的 Key 就是 'scrfToken' 这个字段，所以直接获取就好了
        'Content-Type': "application/json",
      },
      prefix
    }
  });
}

// other api url
// export const otherHttp = createAxios({
//   requestOptions: {
//     apiUrl: 'xxx',
//   },
// });
