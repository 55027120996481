import { defHttp } from "@/utils/http/axios";
import { ListRes, ListReq, RequestRes } from "../../types/requestBaseType"
import dayjs from "dayjs"


export interface AlippayfundTransfer {
    outbizno: string,
    appid: string,
    method: string,
    format: string,
    charset: string,
    signtype: string,
    timestamp: string,
    version: string,
    appauthtoken: string,
    bizcontent: string,
    transamount: string,
    productcode: string,
    bizscene: string,
    ordertitle: string,
    originalorderid: string,
    payeeinfo: string,
    payeeinfoidentity: string,
    payeeinfoidentitytype: string,
    payeeinfoname: string,
    remark: string,
    businessparams: string,
    code: string,
    msg: string,
    subcode: string,
    submsg: string,
    sign: string,
    orderid: string,
    payfundorderid: string,
    status: string,
    transdate: string,
    creationdate: string,
    modificationdate: string,
}
/**
 * 获取用户角色
 */
export async function getAlippayfundTransfer(params: ListReq): Promise<ListRes<AlippayfundTransfer>> {
    params.token = true
    const res = await defHttp.request<ListRes<AlippayfundTransfer>>(
        {
            url: `/alipayfundtransfer/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.modificationdate = dayjs(v.modificationdate).format("YYYY-MM-DD HH:mm:ss")
        v.transdate= dayjs(v.transdate).format("YYYY-MM-DD HH:mm:ss")
        v.modificationdate = dayjs(v.modificationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}