
import { defineComponent, ref } from "vue";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
import exportDialog from "@/components/export/exportDialog.vue";
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog,
   },
   setup() {
    const title = ref('订单详情');
    const visible = ref(false);
    const activeName = ref("2")
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const exportDialogRef = ref<any>();
    const list = ref<any[]>([]);
    
    const open = () => {
      visible.value = true;
      for(var i=0;i<5;i++){
        list.value.push(i)
      }
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsList();
    };

    const getStatisticsList = async() => {
      console.log('开启调用')
      try {
        // //reLoading.value = true;
        // let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,
        //   userid:userId,type:valueType,rechargeType:activeName.value};
        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        // }
        // const res = await getStatisticsDetailsList(rqData);
        // total.value=res.data.total
        // list.value=res.data.records
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const handleExport = async() =>{
      //exportDialogRef.value.open(`${name}的收益`)
    }
    const exportFile = async(name:string) =>{
      try {

        // let rqData: any = {fortest:search.value.fortest,fileName:name+'的收益',userid:userId,type:valueType,rechargeType:activeName.value}

        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        // }

        // const res = await exportStatisticsDetails(rqData)
      
        // const blob = new Blob([res.data]);
      
        // saveAs(blob, name)
      } finally {

      }
    }
    return {
      title,
      visible,
      activeName,
      page,
      total,
      limit,
      list,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
