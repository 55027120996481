
  import { userRoleList,  removeRepair} from '@/api/User/role'
  import { defineComponent, ref, toRef } from "vue";
  import { ElMessage } from "element-plus";
  let Id=""
  export default defineComponent({
    name: "v-dialog",
    props: ["type"],
    data() {
      return {
        rules: {
          accectAccount: [{ required: true, message: '请输入接收人手机号', trigger: 'blur' }]      
        },
      };
    },
    setup(props: any) {
      const visible = ref(false);
      const btnSumitLoading = ref(false);
      const isAdd = ref(true);

      const userList = ref<any>([])

      const formRef =ref<any>("");
      const inputData = ref({
        accectAccount: "",
      });
      const handleClose = () => {
        visible.value = false;
        formRef.value.resetFields();
        formRef.value.clearValidate();
      };
      const open = (_id:string) => {
        visible.value = true;
        Id=_id;
        inputData.value.accectAccount=""

      };
      const newAccountChange = async () =>{
        if(inputData.value.accectAccount){
          let rqData: any = { page: 1, limit: 10,repairmanaccount: inputData.value.accectAccount}
          const res = await userRoleList(rqData, "repairman")
          userList.value=res.result
          const operationid=userList.value[0].operationId;
         
        }
      }
  
      const submit = async () => {
        try {
          try {
            await formRef.value.validate();
          } catch (error) {
            console.log(error);
            throw Error("请检查输入");
          }
          btnSumitLoading.value = true;
          
          const rqData = { newAccount:inputData.value.accectAccount}
          const result =  await removeRepair(rqData);
          ElMessage.success(result);
          handleClose();
        
        } finally {
          btnSumitLoading.value = false;
        }
      };
      return {
        isAdd,
       
        visible,
        handleClose,
        open,
        newAccountChange,
      
        btnSumitLoading,
        inputData,
        submit,
        formRef,
      };
    },
  });
  