<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.productid">
        <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of productList"></el-option>
        <!-- <el-option label="二代小魔夹" value="56c62a11a6a14d6eb7ab29fbf9f2f8b4"></el-option>
        <el-option label="三代小魔夹" value="811103983bcffe227dc6dddec51f468e"></el-option>
        <el-option label="二代机柜" value="9775f7dd993646eaa4977537b0735e8f"></el-option>
        <el-option label="三代机柜" value="6951e86506725baaf4dfcdf9aa015395"></el-option>
        <el-option label="雨伞" value="d965476559f0428483b03f08941cda39"></el-option>
        <el-option label="雨伞柜" value="a2ea3faa86274d3f966ff30aad68b208"></el-option> -->
      </el-select>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary">生成新二维码</el-button>
      <el-button @click="handleExportQR" icon="el-icon-plus" type="primary">导出打印二维码</el-button>
    </header>
    <div style="height: 80vh;">
      <el-table :data="list" max-height="80vh" border style="width: 100%" v-if="list" >
      <!-- <el-table :data="list" height="80vh" style="width: 100%" v-loading="true"> -->
        <el-table-column type="expand">
          <template #default="scope">
            <el-form class="table-expand" inline label-position="left" label-width="120px">
              <el-form-item label="创建时间">
                <span>{{ scope.row.creationdate }}</span>
              </el-form-item>
              <el-form-item label="其它操作" v-if="scope.row.deviceid">
                <el-button @click="toPath(`/oneNet/report?deviceid=${scope.row.deviceid}`)" size="mini" type="primary">上报消息</el-button>
                <el-button @click="toPath(`/oneNet/send?deviceid=${scope.row.deviceid}`)" size="mini" type="primary">下发消息</el-button>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="SN" width="140">
          <template #default="scope">
            <my-copy :data="scope.row.sn" />
            {{scope.row.sn}}
            <hover-show-qr :logo="`${scope.row.prefix}`" :text="scope.row.sn" :key="scope.row.sn" :productid="scope.row.productid"></hover-show-qr>
          </template>
        </el-table-column>
        <el-table-column label="设备类型" width="160" align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.productid">{{productList[productIdList.indexOf(scope.row.productid)].name}}</el-tag>
            <!-- <el-tag v-if="scope.row.productid=='811103983bcffe227dc6dddec51f468e'">三代小魔夹</el-tag>
            <el-tag v-if="scope.row.productid=='56c62a11a6a14d6eb7ab29fbf9f2f8b4'">二代小魔夹</el-tag>
            <el-tag v-if="scope.row.productid=='9775f7dd993646eaa4977537b0735e8f'">二代机柜</el-tag>
            <el-tag v-if="scope.row.productid=='a2ea3faa86274d3f966ff30aad68b208'">雨伞架</el-tag>
            <el-tag v-if="scope.row.productid=='0a9edc30d4924f989fd9207bf62e6d23'">便民柜</el-tag>
            <el-tag v-if="scope.row.productid=='d194bd2a29ba48d094648a38a4d5896f'">一代小魔夹</el-tag>
            <el-tag v-if="scope.row.productid=='6951e86506725baaf4dfcdf9aa015395'">三代机柜</el-tag>
            <el-tag v-if="scope.row.productid=='d965476559f0428483b03f08941cda39'">共享雨伞</el-tag>
            <el-tag v-if="scope.row.productid=='d4b8d2d2df5742ecbec9398d5409dba9'">消毒柜</el-tag> -->
            <!-- <el-tag v-else>设备</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column label="广告" width="140" align="center">
          <template #default="scope">
            <span v-if="scope.row.adType!=null">{{adInfoList[scope.row.adType].name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="出厂状态" width="120" align="center">
         <template #default="scope">
            <el-switch
              v-model=scope.row.stage
              active-color="#13ce66"
              inactive-color="#C0CCDA"
              :active-value=1
              :inactive-value=0
              disabled>
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="在线状态" width="120" align="center">
         <template #default="scope">
            <el-switch
              v-model=scope.row.online
              active-color="#13ce66"
              inactive-color="#C0CCDA"
              :active-value=1
              :inactive-value=0
              disabled>
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="固件版本" width="120" align="center">
         <template #default="scope">
            {{scope.row.version?scope.row.version:'0'}}
         </template>
        </el-table-column>
        <el-table-column label="设备状态"  width="680">
          <template #default="scope">           
            <el-steps :space="70" :active="scope.row.status+1" finish-status="success">
              <el-step title="待生产"></el-step>
              <el-step title="已入库"></el-step>
              <el-step title="已出库"></el-step>
              <el-step title="待投放"></el-step>
              <el-step title="已投放"></el-step>
              <el-step title="故障"></el-step>
              <el-step title="维修"></el-step>
              <el-step title="待报废"></el-step>
              <el-step title="已报废"></el-step>
            </el-steps>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template #default="scope">
            <!-- <el-button @click="handleEidt(scope.$index,scope.row)" size="mini">编辑</el-button> -->
            <el-button
              @click="handleShowComponenet(scope.row.sn)"
              size="mini"
              type="primary"
            >查看组件</el-button>
            <el-button
              @click="handleShowDetails(scope.row.sn,scope.row)"
              size="mini"
              type="info"
            >设备详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination 
    :current-page="page" 
    :page-size="limit" 
    :page-sizes="[10, 30, 50, 100]" 
    :total="total" @current-change="onPageChange" 
    @size-change="handleLimitChange" 
    layout="total, sizes, prev, pager, next, jumper">
  </el-pagination>
  </div>
  <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
  <v-components-dialog ref="componentsDialogRef"></v-components-dialog>
  <deviceDetailsDialog ref= "deviceDetailsDialogRef"></deviceDetailsDialog>
  <addNewSnDialog ref="addNewSnDialogRef"></addNewSnDialog>
  <exportQRSnDialog @export="exportSn" ref="exportQRSnDialogRef"></exportQRSnDialog>
</template>

<script lang="ts">
import { defineComponent, ref, readonly } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
// import { getDeviceList, DeviceListRes } from "@/api/Share/Device";
import { getAnalyDeviceList, analyDeviceModel,exportQRSn } from "@/api/Share/Device";
import addDialog from "./components/addDialog.vue";
import componentsDialog from './components/componentsDialog.vue'
import deviceDetailsDialog from "@/components/device/deviceDetailsDialog.vue";
import addNewSnDialog from "./components/addNewSnDialog.vue";
import exportQRSnDialog from "./components/exportQRSnDialog.vue"
import { ElMessage } from "element-plus";
import { getAdInfoList,getProductInfoList,getProductIdList} from '@/api/util'
import { useRouter } from "vue-router";
import { forEach } from "lodash";
import dayjs from 'dayjs'
export default defineComponent({
  name: "Name",
  components: {
    "v-add-dialog": addDialog,
    'v-components-dialog': componentsDialog,
    deviceDetailsDialog,
    addNewSnDialog,
    exportQRSnDialog,
  },
  setup() {
    const loading = ref(false);
    const btnRemoveLoaidng = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const router = useRouter();
    // const list = ref<DeviceListRes[]>([]);
    const list =ref<analyDeviceModel[]>([]);
    const addDialogRef = ref<any>();
    const componentsDialogRef = ref<any>();
    const deviceDetailsDialogRef = ref<any>();  
    const addNewSnDialogRef = ref<any>();
    const exportQRSnDialogRef = ref<any>();
    const adInfoList = getAdInfoList()
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const toPath = (path: string) => {
      router.push(path);
    };
    const searchSelect = ref({
      search: "sn",
    });
    const mixin_select_search = ref({
      search: {
        sn: "", //共享面板的设备序号，如：小魔柜SN；雨伞架SN
      },
    });

    const mixin_select_search_label = readonly({
      search: {
        sn: "设备SN",
      },
    });

    const mixin_router_search = ref({ productid:"",order: "creationdate",sort: "desc" });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          //rqData = { ...rqData, entity: rq };
          rqData = { ...rqData, ...rq }
        }
        const res = await getAnalyDeviceList(rqData);
        console.log('返回数据=',res)
        
        total.value = res.data.total;
        list.value = res.data.records;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleAdd = () => {
      //addDialogRef.value.open(-1, {});
      addNewSnDialogRef.value.open()
    };
    const handleExportQR = async() =>{
      exportQRSnDialogRef.value.open()
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row);
    };
    const addSuccess = (data: { index: number; data: analyDeviceModel }) => {
      if (typeof data.index == "number" && data.index > -1) {
        list.value[data.index] = data.data;
      } else {
        list.value.push(data.data);
      }
    };

    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }

    const handleShowDetails = (sn:string,item:any) => {
      deviceDetailsDialogRef.value.open(sn,item)
    }
    const exportSn = async(data: { productid:string, type:string, time:string, name:string}) =>{
        let rqData: any = {fileName:'QR',productid: data.productid,type: data.type,}
        
        if (data.time) {
          rqData.startDate = dayjs(data.time[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endDate =  dayjs(data.time[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        console.log('请求参数=',rqData)
       
        const res = await exportQRSn(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, data.name)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      adInfoList,
      productList,
      productIdList,
      toPath,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      addSuccess,
      handleExportQR,
      exportSn,
      addDialogRef,
      btnRemoveLoaidng,
      componentsDialogRef,
      deviceDetailsDialogRef,
      addNewSnDialogRef,
      exportQRSnDialogRef,
      handleShowComponenet,
      handleShowDetails,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }

  .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
  }
}
</style>