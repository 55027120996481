import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
export interface TweetList {
    content: string
    enable: string
    enabletime: number
    expiretime: number
    picurl: null | string
    tweetid: string
    url: null | string
}
export async function tweetList(params: ListReq): Promise<ListRes<TweetList>> {
    params.token = true
    const res = await defHttp.request<ListRes<TweetList>>(
        {
            url: `/tweet/list`,
            params
        }
    );
    if (res.totalCount == 0) throw Error('获取通知消息失败')
    return res
}
export async function tweetRemove(params: RequestReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/tweet/remove`,
            params
        }
    );
    if (!res) throw Error('删除失败')
    return res.message || "删除成功"
}
export async function tweetSet(params: RequestReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/tweet/set`,
            params
        }
    );
    if (!res) throw Error('更新失败')
    return res.message || "更新成功"
}
export async function tweetAdd(params: RequestReq): Promise<string> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/tweet/add`,
            params
        }
    );
    if (!res) throw Error('添加失败')
    return res.message || "添加成功"
}