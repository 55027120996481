import { readonly } from "vue"
import dayjs from "dayjs";
export const pickerOptions = readonly({
    shortcuts: [
        {
            text: "今天",
            onClick(picker: any) {
                const end = new Date();
                const start = new Date();
                start.setTime(dayjs().startOf("day").get("millisecond"));
                picker.$emit("pick", [start, end]);
            },
        },
        {
            text: "最近一天",
            onClick(picker: any) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24);
                picker.$emit("pick", [start, end]);
            },
        },
        {
            text: "最近三天",
            onClick(picker: any) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
                picker.$emit("pick", [start, end]);
            },
        },
        {
            text: "最近一周",
            onClick(picker: any) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
            },
        },
    ],
    disabledDate: (time: any) => time.getTime() > Date.now() - 8.64e6,
})