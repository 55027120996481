<template>
  <el-dialog :destroy-on-close="true" :title="`沟通处理`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef">
      <el-form-item label="是否愿意归还" prop="userReturnAttitude">
        <el-switch active-text="愿意归还" inactive-text="不愿归还" active-color="#13ce66" inactive-color="#ff4949" v-model="inputData.userReturnAttitude" 
            > 
        </el-switch>
      </el-form-item>
      <el-form-item label="沟通结果" prop="remark">
        <el-input v-model="inputData.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
      </el-form-item>
      <template v-if="inputData.userReturnAttitude">
        <el-form-item label="归还类型" prop="returntype">
          <el-select placeholder="请选择归还类型" v-model="inputData.returntype">
            <el-option label="机柜" value="cabinet"></el-option>
            <el-option label="邮寄" value="express"></el-option>
          </el-select>
        </el-form-item>
        <!-- <template v-if="inputData.returntype=='express'">
          <el-form-item label="快递公司" prop="expressbrand">
            <el-input v-model="inputData.expressbrand"></el-input>
          </el-form-item>
          <el-form-item label="快递单号" prop="expressnum">
            <el-input v-model="inputData.expressnum"></el-input>
          </el-form-item>
        </template> -->
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from "vue";
import { sharedorderprivatizationprocessNegotiationNew } from "@/api/Order/Privatization";
import Rules from "@/common/Rules";
import { deepCopy } from "@/utils";
import { ElMessage } from "element-plus";
import { userStore } from "@/store/modules/user";
import dayjs from "dayjs"
let overdueId = "";
export default defineComponent({
  name: "v-dialog",
  emits: ["success"],
  setup(props, { emit }) {
    const inputData = ref({
      remark: "愿意归还",
      userReturnAttitude: true,
      returntype: "cabinet",
    

      // expressbrand: "",
      // expressnum: "",
    });

    /**
     * 封装沟通是数据
     */
    const getInputData = computed(() => {
      const _inputData = deepCopy(inputData.value);
      console.log("_inputData :>> ", _inputData);
     
      if (!inputData.value.userReturnAttitude) {
        // delete _inputData.returntype;
        // delete _inputData.expressbrand;
        // delete _inputData.expressnum;
        _inputData.userReturnAttitude=2;
      } else {
         _inputData.userReturnAttitude=1;
         if (inputData.value.returntype == "cabinet") {
           _inputData.remark=_inputData.remark+',归还到机柜';
         }else{
           _inputData.remark=_inputData.remark+',通过邮寄回来';
         }
        delete _inputData.returntype;
        // delete _inputData.paymentamount;
      }
      // if (inputData.value.returntype == "cabinet") {
      //   delete _inputData.expressbrand;
      //   delete _inputData.expressnum;
      // }
      return _inputData;
    });

    /**
     * 表单规则
     */
    const geRules = computed(() => {
      const _rules: any = {};
      Object.keys(getInputData.value).forEach(
        (v) => (_rules[v] = [Rules.required()])
      );
      return _rules;
    });

    const visible = ref(false);
    const formRef = ref<any>("");
    const btnSumitLoading = ref(false);
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };

    const open = (id: string) => {
      visible.value = true;
      overdueId = id;
      console.log('id数据')
      console.log(overdueId)
    };

    /**
     * 提交数据
     */
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        const res = await sharedorderprivatizationprocessNegotiationNew({
          ...getInputData.value,
          handleUserId: userStore.userInfo.userid,
          excuteInfo:'',
          remindTime:dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss"),
          overdueId,
        });
        
        ElMessage.success(res);
        emit("success");
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>