import { createApp } from 'vue'
import App from './App.vue'  //App首页
import store from './store'  //存储
import { setupAppConfig } from "@/plugins/appConfig"
import { setupElement } from "@/plugins/element"
import { setupComponents } from "@/plugins/components"
import { setupProvide } from "@/plugins/provide"
import { setupDirectives } from "@/plugins/directives"
import { setupStore } from "@/store/index"
import router, { setupRouter } from '@/router';
// import './assets/json/china.js'
// import '/static/js/china'
import './assets/css/index.scss'
import 'dayjs/locale/zh-cn'
import 'element-plus/lib/theme-chalk/display.css';
import lang from 'element-plus/lib/locale/lang/zh-cn'
const app = createApp(App)
console.log("当前环境",process.env);
//添加App扩展功能
setupProvide(app)  //解密方式
setupAppConfig(app)  //App配置
setupStore(app)  //设置存储
setupRouter(app)  //设置路由
setupElement(app) 
setupComponents(app) //设置一些自定义组件，如复制，提示框，复制数据至路径
setupDirectives(app)  //设置指令
app.use(store).use(router).mount('#app')
