
import { defineComponent, ref, readonly, nextTick } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { userRoleList,terminateTester} from '@/api/User/role'
import testWalletRechangeDialog from './components/testWalletRechangeDialog.vue'
import activateDialog from './components/activateDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  name: 'Name',
  components: {
    'v-activate-dialog': activateDialog,
    testWalletRechangeDialog,
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const activateDialogRef = ref<any>()
    const list = ref<any>([])
    const testWalletRechangeDialogRef = ref<any>()
    const searchSelect = ref({ search: 'name' })
    const mixin_router_search = ref({ status: '', type: 'tester' })
    const mixin_select_search = ref({
      search: {
        name: '',
        account: '',
        userid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
        account: '账号',
        userid: 'ID',
      },
    })
    const reData = async (rq?: any) => {
      await nextTick()
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.name) {
            rq[`${mixin_router_search.value.type}name`] = rq.name
            delete rq.name
          }
          if (rq.account) {
            rq[`${mixin_router_search.value.type}account`] = rq.account
            delete rq.account
          }
          if (rq.userid) {
            rq[`${mixin_router_search.value.type}userid`] = rq.userid
            delete rq.userid
          }
          rqData = { ...rqData, ...rq }
        }
        const res = await userRoleList(rqData, mixin_router_search.value.type)
        total.value = res.totalCount
        list.value = res.result
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleActivate = () => {
      activateDialogRef.value.open(mixin_router_search.value.type)
    }
    const handleRechange = (row: any) => {
      testWalletRechangeDialogRef.value.open(row)
    }
    const handleRemove = async(row: any) => {
       await ElMessageBox.confirm(
          `是否删除测试员`+row[`${mixin_router_search.value.type}name`],
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        if(row.testeraccount){
          const res = await terminateTester({"account":row.testeraccount})
          mixinReRouter()
          ElMessage.success(res)
        }else{
          ElMessage.error("找不到改测试员手机号，请联系开发人员进行删除")
        }
    }

    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleActivate,
      activateDialogRef,
      changSearch,
      handleRechange,
      handleRemove,
      testWalletRechangeDialogRef,
    }
  },
})
