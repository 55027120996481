
import { defineComponent, ref } from "vue";
import { getComponentsList, ComponentsListRes } from "@/api/Component/Index";
import { getcrc } from "@/api/Share/Panel";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  setup() {
    const visible = ref(false);
    const loading = ref(false);
    const syncLoaidng = ref(false);
    const btnSumitLoading = ref(false);
    const current = ref(1);
    const size = ref(10);
    const totalCount = ref(0);
    const sn = ref("");
    const list = ref<ComponentsListRes[]>([]);
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const reData = async () => {
      try {
        loading.value = true;
        const { records, total } = await getComponentsList({
          current: current.value,
          size: size.value,
          entity: {
            equipmentsn: sn.value,
          },
        });
        list.value = records;
        totalCount.value = total;
      } finally {
        loading.value = false;
      }
    };
    const open = (_sn: string) => {
      visible.value = true;
      sn.value = _sn;
      reData();
    };
    const onPageChange = (e: any) => {
      current.value = e.value;
      reData();
    };
    const handleSync = async (deviceid: string) => {
      try {
        syncLoaidng.value = true;
        const res =  await getcrc({ deviceid });
        ElMessage.success(res);
      } finally {
        syncLoaidng.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      list,
      btnSumitLoading,
      current,
      totalCount,
      size,
      onPageChange,
      handleSync,
      syncLoaidng
    };
  },
});
