<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="状态" class="header-el-selector" v-model="mixin_router_search.usedStatus">
        <el-option label="待发放" value="0"></el-option>
        <el-option label="待领取" value="1"></el-option>
        <el-option label="待激活" value="2"></el-option>
        <el-option label="未使用" value="3"></el-option>
        <el-option label="已使用" value="4"></el-option>
        <el-option label="已失效" value="5"></el-option>
        <el-option label="已过期" value="6"></el-option>
      </el-select>
    
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <!-- <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item> -->
            <el-form-item label="用户id">
              <el-button type="text" @click="toPath_RouterSearchMixin('/user',[{search_userid:scope.row.userId}])">{{ scope.row.userId }}</el-button>
              <my-copy :data="scope.row.userid" />
            </el-form-item>
            <el-form-item label="优惠卷id">
              <span>{{ scope.row.id }}</span>
              <my-copy :data="scope.row.id" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="名称" min-width="40">
        <template #default="scope">
           {{scope.row.title}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="描述" min-width="60">
        <template #default="scope">
            {{scope.row.desc}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="金额" min-width="20">
        <template #default="scope">
          {{scope.row.usedAmount}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="30">
        <template #default="scope">
          <el-tag type="warning"  v-if="scope.row.usedStatus=='0'">待发放</el-tag>
          <el-tag type="warning" v-if="scope.row.usedStatus=='1'">待领取</el-tag>
          <el-tag type="info" v-if="scope.row.usedStatus=='2'">待激活</el-tag>
          <el-tag type="info" v-if="scope.row.usedStatus=='3'">未使用</el-tag>
          <el-tag type="success" v-if="scope.row.usedStatus=='4'">已使用</el-tag>
          <el-tag type="danger" v-if="scope.row.usedStatus=='5'">已失效</el-tag>
          <el-tag type="danger" v-if="scope.row.usedStatus=='6'">已过期</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="生效时间" min-width="40">
        <template #default="scope">
          {{scope.row.validStartTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="到期时间" min-width="40">
        <template #default="scope">
          {{scope.row.validEndTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="领取时间" min-width="40">
        <template #default="scope">
          {{scope.row.receiveTime?scope.row.receiveTime:"未领取"}}
        </template>
      </el-table-column>
      
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { CouponModel, getUserCouponList } from "@/api/Financial/coupon";
import { userList } from "@/api/User/index";
export default defineComponent({
  setup() {
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<CouponModel[]>([]);
    const userInfoList=ref<any>();
    const searchSelect = ref({
      search: "userId",
    });
    const mixin_router_search = ref({
      order: 'gmtCreate',
      sort: 'desc',
      usedStatus: "",
    });
    const mixin_select_search = ref({
      search: {
        account:"",
        userId: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',
        userId: "用户ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
            if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await getUserCouponList(rqData);
        console.log("res.records :>> ", res.records);
        list.value = res.rows;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
   
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      getRelData();
      //mixinReRouter();
    };
    const getRelData = () =>{
      if(mixin_select_search.value.search.account){
        getUserInfo(mixin_select_search.value.search.account);
      }else{
        mixinReRouter();
      }
    }

    const getUserInfo = async (account: string) => {
      try {
       
        let rqData: any = { page: "1", limit: "10" ,account:account};
       
        const res = await userList(rqData);
        
        console.log('用户信息')
        console.log(res)
        userInfoList.value = res.result;
        mixin_select_search.value.search.userId=userInfoList.value[0].userid;
        
      } finally {

        mixinReRouter();
      }
    };

    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 130px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>