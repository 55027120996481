
import { defineComponent, ref, toRef } from 'vue'
import { userconsumewalletRecharge } from '@/api/User/role'
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { getShareProductInfo } from '@/api/util'
let row: any = {}
export default defineComponent({
  name: 'v-testWalletRechangeDialog',
  props: ['type'],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
      },
    }
  },
  setup(props: any) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const type = toRef(props, 'type')
    const inputRef = ref<any>('')
    const walletList = getShareProductInfo()
    const inputData = ref({
      amount: 0
    })
    const handleClose = () => {
      visible.value = false
      row = {}
      inputRef.value.resetFields()
      inputRef.value.clearValidate()
    }
    const open = (_row: any) => {
      visible.value = true
      row = _row
      console.log('row :>> ', row);
    }
    const submit = async () => {
      try {
        try {
          await inputRef.value.validate()
        } catch (error) {
          console.log(error)
          throw Error('请检查输入')
        }
        btnSumitLoading.value = true
        const result = await userconsumewalletRecharge({
          ...inputData.value,
          userid:row.testeruserid
        })
        ElMessage.success(result)
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      inputRef,
      walletList
    }
  },
})
