 <template>
 <el-dialog :destroy-on-close="true" :title="name+`的出货单`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="70%">
    <div class="container">
     <header>
      <el-select
        @change="handelChange"
        clearable
        placeholder="平台投放开始状态"
        class="header-el-selector"
        v-model="platformlaunchbegin"
      >
        <el-option :value="true" label="已开始投放"></el-option>
        <el-option :value="false" label="未开始投放"></el-option>
      </el-select>
      <el-select
        @change="handelLaunchChange"
        clearable
        placeholder="平台投放结束状态"
        class="header-el-selector"
        style="margin-left: 12px;"
        v-model="platformlaunchend"
        v-show="platformlaunchbegin"
      >
        <el-option :value="true" label="平台已完成投放"></el-option>
        <el-option :value="false" label="平台未完成投放"></el-option>
      </el-select>
      <el-button @click="handleAddNewShipment" icon="el-icon-plus" type="primary" style="margin-left:24px;">新增出货单</el-button>
      <!-- <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">创建无投资出货单</el-button> -->
    </header>
     <el-tabs v-model="activeName"  @tab-click="handleClick" >
      <el-tab-pane label="全部" name="6"></el-tab-pane>
      <el-tab-pane label="未出仓" name="0"></el-tab-pane>
      <el-tab-pane label="已出仓" name="1"></el-tab-pane>
      <el-tab-pane label="平台投放已开启" name="2"></el-tab-pane>
      <el-tab-pane label="平台投放已结束" name="3"></el-tab-pane>
      <el-tab-pane label="快递已寄出" name="4"></el-tab-pane>
      <el-tab-pane label="快递已签收" name="5"></el-tab-pane>
    </el-tabs>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            
            <el-form-item label="出货单编码">
              <span>{{ scope.row.shipmentid }}</span>
              <my-copy :data="scope.row.shipmentid"></my-copy>
            </el-form-item>
            <el-form-item label="投资订单编码">
              <span>{{ scope.row.investmentorderid }}</span>
              <my-copy :data="scope.row.investmentorderid"></my-copy>
            </el-form-item>
            
            <el-form-item label="平台投放结束时间" v-if="scope.row.platformlaunchenddate">
              <span>{{ scope.row.platformlaunchenddate }}</span>
            </el-form-item>
            <div v-if="scope.row.packagedelivereddate">
              <el-form-item label="快递寄出时间">
                <span>{{ scope.row.packagedelivereddate }}</span>
              </el-form-item>
              <el-form-item label="快递公司名">
                <span>{{ scope.row.courierfirm }}</span>
              </el-form-item>
              <el-form-item label="快递单号">
                <span>{{ scope.row.trackingnumber }}</span>
              </el-form-item>
              <el-form-item label="快递单号">
                <span>{{ scope.row.trackingnumber }}</span>
              </el-form-item>
              <el-form-item label="快递签收时间" v-if="scope.row.packagesigneddate">
                <span>{{ scope.row.packagesigneddate }}</span>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" min-width="28">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          {{getStatusName[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column label="数量" min-width="28" prop="quantity" ></el-table-column>
      <el-table-column label="创建时间" min-width="28" prop="creationdate" label-position="center"></el-table-column>
      <el-table-column label="出仓时间" min-width="28" prop="outwarehousedate" label-position="center">
        <template #default="scope">
          {{scope.row.outwarehousedate?scope.row.outwarehousedate:"暂无"}}
        </template>       
      </el-table-column>

      <el-table-column label="操作" min-width="28">
        <template #default="scope">
          <el-button @click="handleShowAssgin(scope.row)" size="mini" type="info">查看已分配</el-button>
          <el-button
            @click="handleAssgin(scope.row)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='0'"
          >分配</el-button>
          <el-button
            @click="handleOut(scope.$index,scope.row.shipmentid)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='0'"
          >出仓</el-button>
          <el-button
            @click="handleStartLaunch(scope.$index,scope.row.shipmentid)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='1'&&scope.row.investmentorderid"
          >开始投放</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-assign-dialog ref="assignDialogRef"></v-assign-dialog>
    <v-assign-new-dialog ref="assignNewDialogRef"></v-assign-new-dialog>
   </div>
 </el-dialog>
</template> 
<script lang="ts">
import { defineComponent, ref, computed,readonly} from "vue";
import assignDialog from "./assignDialog.vue";
import assignNewDialog from "./assignNewDialog.vue";
import {
  investmentordershipmentList,
  InvestmentordershipmentListRes,
  outwarehouse,
  startlaunch,
  addNoInvestorOrderShipmentHttp,
} from "@/api/Investment/shipment";
import {investmentorderCreateshipment} from '@/api/Investment/Order'
import { ElMessage, ElMessageBox } from "element-plus";
import { getStatusName } from "./editData";
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: "v-dialog",
  components: {
    "v-assign-dialog": assignDialog,
    "v-assign-new-dialog":assignNewDialog,
  },
  setup() {
    const name = ref("");  
    const visible = ref(false);
    const activeName = ref("");
    const loading = ref(false);
    const articlenum = ref<number>(0); //下单小魔夹数量
    const btnOutLoading = ref(false);
    const investmentorderid = ref("");
    const platformlaunchbegin = ref();
    const platformlaunchend = ref();
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<InvestmentordershipmentListRes | any>([]);
    const assignDialogRef = ref<any>();
    const assignNewDialogRef = ref<any>();
    const open = (_name:string,_investmentorderid:string,_articlenum:number) =>{
        visible.value = true;
        name.value=_name;
        articlenum.value=_articlenum;
        investmentorderid.value=_investmentorderid;
        reData();
    }

    const reData = async() => {
      try {
        loading.value = true;
        let rqData: any = {
          current: page.value,
          size: limit.value,
          order:"creationdate",
          sort:'desc',
          entity:{
            investmentorderid:investmentorderid.value,
            status:activeName.value,
            platformlaunchbegin:platformlaunchbegin.value,
            platformlaunchend:platformlaunchend.value
          }
        };
        console.log('筛选值='+activeName.value)
        if(activeName.value===null||activeName.value===""||activeName.value.endsWith("6")){
            delete rqData.entity.status
        }
        if(platformlaunchbegin.value===null||platformlaunchbegin.value===""){
            delete rqData.entity.platformlaunchbegin
        }
        if(platformlaunchend.value===null||platformlaunchend.value===""){
            delete rqData.entity.platformlaunchend
        }
        const res = await investmentordershipmentList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    }

    const handleClose = () =>{
      visible.value = false;
    }

    const handelChange = (val:any) =>{
      platformlaunchbegin.value=val
      page.value=1;
      reData();
    }
    const handelLaunchChange = (val:any) => {
      platformlaunchend.value= val
      page.value=1;
      reData();
    }
    const handleClick = () => {
      if(activeName.value==="6"){
        activeName.value=""
      }
      page.value=1;
      reData();
    }
    const handleAddNewShipment = async() => {
      try {
        const input = await ElMessageBox.prompt('请输入出货数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: `请输入 1-`,
          inputType: 'number',
        })
        console.log('input :>> ', input)
        const _value = parseInt(input.value)
        if (_value > articlenum.value || _value < 1)
          return ElMessage.error(`请输入`)
        if (!userStore.info.userid) return userStore.loginOut()
        const res = await investmentorderCreateshipment({
          orderid:investmentorderid.value,
          quantity: _value,
          shipperuserid: userStore.info.userid,
        })
        console.log('res :>> ', res)
        ElMessage.success(res)
        reData();
      } catch (error) {
        if (error == 'cancel') return
        if (error == 'colse') return
        ElMessage.error(error)
      }
    }
    const handleShowAssgin = (item:any) =>{
      assignNewDialogRef.value.open(item);
    }
    const handleAssgin = (item:any) => {
      assignNewDialogRef.value.open(item);
    }
    const handleOut = async(index:number,shipmentid: string) =>{
      try {
        btnOutLoading.value = true;
        const res = await outwarehouse({
          shipmentid,
        });
        ElMessage.success(res.msg);
        reData();
      } finally {
        btnOutLoading.value = false;
      }
    }
    const handleStartLaunch = async(index: number, shipmentid: string) => {
      try {
        btnOutLoading.value = true;
        const res = await startlaunch({
          shipmentid,
        });
        ElMessage.success(res.msg);
        reData();
      } finally {
        btnOutLoading.value = false;
      }
    }
    const onPageChange = (_page:number) =>{
        page.value=_page
        reData();
    }
    const handleLimitChange = (_size:number) =>{
        limit.value=_size
        reData();
    }
    return {
      name,
      visible,
      btnOutLoading,
      activeName,
      platformlaunchbegin,
      platformlaunchend,
      articlenum,
      limit,
      page,
      total,
      list,
      loading,
      getStatusName,
      assignDialogRef,
      assignNewDialogRef,
      handelLaunchChange,
      open,
      reData,
      handleShowAssgin,
      handleAddNewShipment,
      handleClose,
      handelChange,
      handleClick,
      handleAssgin,
      handleOut,
      handleStartLaunch,
      onPageChange,
      handleLimitChange,
    }
  }
})    

</script>
<style>
</style>