<!--支付宝转账结果-->
<template>
  <div class="container">
  <header>
    <el-input
      @keyup.enter="handelChange"
      class="input-with-select"
      placeholder="请输入搜索内容"
      v-model="searchValue"
    >
    <template #prepend>
        <el-select @change="changSearch()" placeholder="请选择" v-model="searchSelect.search">
          <el-option
            :key="item"
            :label="mixin_select_search_label.search[item]"
            :value="item"
            v-for="item of Object.keys(mixin_select_search.search)"
          ></el-option>
        </el-select>
      </template>
      <template #append>
        <el-button @click="handelChange" icon="el-icon-search"></el-button>
      </template>
    </el-input>
  </header>
    <el-tabs v-model="activeName" style="margin-top: 16px;" @tab-click="handleClick" >
      <el-tab-pane  label="支付宝" name="alipay">
        <AlipayfundTransfer ref="alipayRef"></AlipayfundTransfer>
      </el-tab-pane>
      <el-tab-pane label="微信" name="wechat">
        <WechatMerchantTransfer ref="wechatRef"></WechatMerchantTransfer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject, onMounted } from 'vue'
import AlipayfundTransfer from './components/alipayfundTransfer.vue'
import WechatMerchantTransfer from './components/wechatMerchantTransfer.vue'
export default defineComponent({
  components: {
    AlipayfundTransfer,
    WechatMerchantTransfer
  },
  setup() {
    const activeName = ref("alipay")
    const searchValue = ref<any>("");
    const searchSelect = ref({ search: 'name' })
    const alipayRef = ref<any>("")
    const wechatRef = ref<any>("")
    const mixin_select_search = ref({
      search: {
        name: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
      },
    })

    const handleClick = () => {
      if(activeName.value=='alipay'){
        alipayRef.value.reData(searchValue.value)
      }else{
        wechatRef.value.reData(searchValue.value)
      }
    }

    const handelChange = async() => {
      if(activeName.value=='alipay'){
        alipayRef.value.reData(searchValue.value)
      }else{
        wechatRef.value.reData(searchValue.value)
      }
    }

    const changSearch = () => {
    
    }
    onMounted(() => {
      if(activeName.value=='alipay'){
        alipayRef.value.reData(searchValue.value)
      }else{
        wechatRef.value.reData(searchValue.value)
      }
    })
    return {
      searchSelect,
      mixin_select_search,
      mixin_select_search_label,
      onMounted,
      activeName,
      searchValue,
      alipayRef,
      wechatRef,
      handleClick,
      handelChange,
      changSearch
    }
  }
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

   .el-select{
      width: 130px;
    }
}
</style>