<!-- 卡片数据 -->
<template>
  <div class="container">
    <el-row :gutter="2">
      <el-col :lg="8" :sm="8" :xs="12">
        <v-card-box :cardData="userGrowth" :height="getHeight">
          <template #center>
            <div class="ball">
              <v-pie :chartData="userGrowth.data.rows" :pieHeight="pieHeight" key="userGrowth" ref="userGrowthRef"></v-pie>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="8" :sm="8" :xs="12">
        <v-card-box :cardData="costs" :height="getHeight" @box1Click="handleThisMothCosts" @box2Click="handleLastMonthCosts" @selectTitleChange="reCocts" :selectItem="coctsSelectItem">
          <template #center>
            <v-pie :chartData="costs.data.rows" :pieHeight="pieHeight" key="costs" ref="costsRef"></v-pie>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="8" :sm="8" :xs="12">
        <v-card-box :cardData="earningsRatio" :height="getHeight" @selectTitleChange="reEarningsRatioSelectItemChange" :selectItem="reEarningsRatioSelectItem">
          <template #center>
            <v-muilt-pie :chartData="earningsRatio.chartData" :chartData2="earningsRatioChartData2" :pieHeight="pieHeight" :selectRow="earningsRatio.select" @changePieSelect="changeEarningsRatioData" key="profitMuiltPie" ref="profitMuiltPieRef"></v-muilt-pie>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="8" :sm="8" :xs="12">
        <v-card-box :cardData="cabinet" :height="getHeight" @selectTitleChange="reCabinet" :selectItem="cabinetSelectItem" @box1Click="toPath_RouterSearchMixin('/device/manage/xmj',[{holdertype:'cabinet'}])" @box2Click="toPath_RouterSearchMixin('/device/manage/xmg',[{ownership:'public'}])">
          <template #center>
            <div class="ball">
              <v-liquid-fill :chartData="cabinet.data" :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="8" :sm="8" :xs="12">
        <v-card-box :cardData="device" :height="getHeight" @selectTitleChange="reDevice" :selectItem="deviceSelectItem" @box2Click="toPath_RouterSearchMixin('/device/manage/xmj',[{investorderfortest:'0'},{investorderstatus:'3'}])">
          <template #center>
            <div class="ball">
              <v-liquid-fill :chartData="device.data" :pieHeight="pieHeight" key="device" ref="deviceRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="8" :sm="8" :xs="12">
        <v-card-box :cardData="invertOrder" :height="getHeight" @selectTitleChange="reInvertOrderData" :selectItem="invertOrderSelectItem" @box2Click="toPath_RouterSearchMixin('/device/manage/xmj',[{investorderfortest:'0'},{investorderstatus:'3'}])">
          <template #center>
            <div class="ball">
              <investment-pie :chartData="invertOrder.data" :pieHeight="pieHeight" ref="invertOrderRef" key="invertOrder"></investment-pie>
            </div>
          </template>
        </v-card-box>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
// interface Props {
//   tboxHeight: string;
// }
import { defineComponent, computed, toRef, ref, onMounted } from "vue";
import cardBox from "./cardBox.vue";
import muiltPie from "./profitMuiltPie/index.vue";
import getProfitMuiltPie from "./profitMuiltPie/getData";
import dayjs from "dayjs";
import liquidFill from "./liquidFill/index.vue";
import getCabinetData from "./liquidFill/getCabinetData";
import getDeviceData from "./liquidFill/getDeviceData";
import custemPie from "./custemPie/index.vue";
import areaPie from "./areaPie/index.vue";
import invertOrderData from "./areaPie/getInvertOrderData";
import pie from "./pie/index.vue";
// import listCarousel from "./components/listCarousel";
import getUserGrowth from "./pie/getUserGrowthData";
import getCocts from "./pie/getCoctsData";
import { deepCopy, unwarp } from "@/utils";
import { getShareProductInfo, ProductInfo } from "@/api/util";
export default defineComponent({
  components: {
    "v-card-box": cardBox,
    "v-muilt-pie": muiltPie,
    investmentPie: areaPie,
    "v-liquid-fill": liquidFill,
    "v-pie": pie,
    // "v-list-carousel": listCarousel
  },
  props: {
    pieHeight: {
      type: String,
      default: () => "180px",
    },
    tboxHeight: {
      type: String,
      default: () => "350px",
    },
  },
  data() {
    return {
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      timer: null,
      timeLimit: {
        min: {
          year: "2019",
          month: "01",
          day: "1",
        },
        max: {
          year: dayjs().format("YYYY"),
          month: dayjs().format("MM"),
          day: dayjs().format("DD"),
        },
      },
      platformRevenue: {
        data: {
          columns: ["name", "percent"],
          rows: [
            {
              name: "增长",
              percent: 0.1,
            },
          ],
        },
        type: 1,
        title: "平台收益",
        box1: {
          label: "增长",
          value: "1320",
        },
        box2: {
          label: "同比增长",
          value: "xx",
        },
      },
      proportionOfInvestorsIncome: {
        type: 1,
        title: "投资人收益占比",
        box1: {
          label: "投资人收益",
          value: "1320万",
        },
        box2: {
          label: "同比增长",
          value: "+110%",
        },
      },
      urbanRevenueTop10: {
        type: 2,
        data: [
          { name: "深圳", value: 200 },
          { name: "广州", value: 179 },
          { name: "长沙", value: 156 },
          { name: "宁乡", value: 128 },
          { name: "北京", value: 110 },
          { name: "天津", value: 90 },
          { name: "上海", value: 60 },
          { name: "大理", value: 55 },
          { name: "丽江", value: 44 },
          { name: "常德", value: 22 },
        ],
        title: "城市收益Top10",
        header: "收益最多",
        label: "北京",
        value: "500万",
      },
      cityPartnerPerformanceTop10: {
        type: 2,
        title: "合伙人业绩Top10",
        header: "业绩第一",
        label: "孙悟空",
        value: "100万",
      },
      provincialActivityTop10: {
        type: 3,
        title: "省活跃Top10",
        label: "活跃用户最多的省份",
        value: "湖南省",
      },
    };
  },
  setup(props) {
    const tboxHeight = toRef(props, "tboxHeight");
    const getHeight = computed(() => parseInt(tboxHeight.value) / 2 - 2 + "px");
    const userGrowthRef = ref<any>();
    const costsRef = ref<any>();
    const profitMuiltPieRef = ref<any>();
    const cabinetRef = ref<any>();
    const deviceRef = ref<any>();
    const invertOrderRef = ref<any>();
    const { reUserGrowth, userGrowth } = getUserGrowth();
    const { costs, reCocts } = getCocts();
    const {
      reEarningsRatio,
      earningsRatio,
      earningsRatioChartData2,
      changeEarningsRatioData,
    } = getProfitMuiltPie();
    const { cabinet, reCabinet } = getCabinetData();
    const { device, reDevice } = getDeviceData();
    const { invertOrder, reInvertOrderData } = invertOrderData();

    const coctsSelectItem = ref<ProductInfo>(
      deepCopy(getShareProductInfo()[0])
    );
    const reEarningsRatioSelectItem = ref<ProductInfo>(
      deepCopy(getShareProductInfo()[0])
    );
    const cabinetSelectItem = ref<ProductInfo>(
      deepCopy(getShareProductInfo()[0])
    );
    const deviceSelectItem = ref<ProductInfo>(
      deepCopy(getShareProductInfo()[0])
    );
    const invertOrderSelectItem = ref<ProductInfo>(
      deepCopy(getShareProductInfo()[0])
    );
    const reData = async () => {
      reUserGrowth();
      reCocts(coctsSelectItem.value);
      reEarningsRatio(reEarningsRatioSelectItem.value);
      reCabinet(cabinetSelectItem.value);
      reDevice(deviceSelectItem.value);
      reInvertOrderData(invertOrderSelectItem.value);
    };
    const reEarningsRatioSelectItemChange = (i: ProductInfo) => {
      reEarningsRatio(i);
    };
    onMounted(() => {
      reData();
    });
    return {
      getHeight,
      userGrowth,
      userGrowthRef,
      costs,
      reCocts,
      costsRef,
      profitMuiltPieRef,
      reEarningsRatio,
      earningsRatio,
      changeEarningsRatioData,
      earningsRatioChartData2,
      cabinetRef,
      deviceRef,
      cabinet,
      device,
      invertOrder,
      invertOrderRef,
      coctsSelectItem,
      reEarningsRatioSelectItem,
      reEarningsRatioSelectItemChange,
      cabinetSelectItem,
      deviceSelectItem,
      invertOrderSelectItem,
      reDevice,
      reCabinet,
      reInvertOrderData,
    };
  },
});
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  .el-row {
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0;
    }
    .el-col {
      border-radius: 4px;
    }
  }
  .home-scroll {
    overflow-x: scroll;
    padding: 0.15rem 0 !important;
    &::-webkit-scrollbar {
      width: 0.026rem;
      height: 0.026rem;
      background-color: rgb(53, 73, 126);
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 0.013rem;
      background-color: #00c9c9;
      cursor: pointer;
      &:hover {
        background-color: #00f4f4;
      }
    }
  }
  // .ball {
  //   height: 1.953rem;
  // }
  .urban-revenue,
  .city-partner-performance,
  .provincial-activity {
    display: flex;
    width: 1rem;
    padding: 0.2rem 0;
    margin: 0 auto;
    .item {
      flex: 1;
      font-size: 12px;
      display: flex;
      .left {
        width: 1em;
        color: #699dff;
        margin-right: 0.023rem;
      }
    }

    .item {
      margin-right: 0.072rem;
    }
  }
}
</style>