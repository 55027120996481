
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { userModel,getUserStatistics} from "@/api/Financial/user";
import { ElMessage, ElMessageBox } from "element-plus";
import walletHistoryDialog from "@/components/wallet/walletHistoryDialog.vue";
import detailsDialog from "@/components/wallet/detailsDialog.vue";
import pointDialog from "@/components/wallet/pointDialog.vue";
import frozenDialog from "@/components/wallet/frozenDialog.vue";
import rechangeDetailsDialog from "@/components/financial/rechangeDetailsDialog.vue"
import withdrawDetailsDialog from "@/components/financial/withdrawDetailsDialog.vue"
import costDetailsDialog from "@/components/financial/costDetailsDialog.vue"
import dayjs from 'dayjs'
import console from "console";
export default defineComponent({
  name: "v-investor",
  components: {
    walletHistoryDialog,
    detailsDialog,
    pointDialog,
    frozenDialog,
    rechangeDetailsDialog,
    withdrawDetailsDialog,
    costDetailsDialog,
  },
  setup() {
    const loading = ref(false);
    const rmLoading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const walletHistoryRef = ref<any>("")
    const walletDetailDialogRef = ref<any>();
    const pointDialogRef = ref<any>();
    const frozenDialogRef = ref<any>();
    const rechangeDetailsDialogRef = ref<any>();
    const withdrawDetailsDialogRef = ref<any>();
    const costDetailsDialogRef = ref<any>();
    const list = ref<userModel[]>([]);
    
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      fortest: false,
      timeBN:''
    });
    const mixin_select_search = ref({
      search: {
        account: "",
        userid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        account: "账号",
        userid: "用户ID",
      },
    });
    const reData = async (rq?: any) => {
      console.log("搜索参数",rq)
      try {
        loading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value, fortest:mixin_router_search.value.fortest };
        if (rq.timeBN) {
          rqData.startTime = dayjs(rq.timeBN[0]).format("YYYY-MM-DD HH:mm:ss").toString() 
          rqData.endTime =  dayjs(rq.timeBN[1]).format("YYYY-MM-DD HH:mm:ss").toString()  
          delete rq.timeBN
        }
        rqData={...rq,...rqData}
        const res = await getUserStatistics(rqData);
        console.log('查询到的数据',res)
        list.value = res.data.records;
        console.log('查询到的数据1',list.value)
        total.value = res.data.total;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    /**
     * 钱包流水
     */
    const handleWalletHitory = (userid:string,account:string) => {
      walletHistoryRef.value.open(userid,account)
    } 
     /**
     * 钱包明细
     */
    const onWalletDetailClick = (userid :string,account:string) =>{
      walletDetailDialogRef.value.open(userid,account)
    }
    /**
     * 积分记录
     */
    const onWalletPointClick = (userid :string,account:string) => {
      pointDialogRef.value.open(userid,account)
    }
    /**
     * 冻结查询
     */
    const onFrozenClick = (userid :string,account:string) => {
      frozenDialogRef.value.open(userid,account);
    }
    /**
     * 下级详情
     */
    const handlerShowDetails = (type : string,userid : string, account : string,name:string) => {
      if(type=='3'){
        rechangeDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=="4"){
        withdrawDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type == "5"){
        costDetailsDialogRef.value.open(userid,type,name,account,name);
      }
    }
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      walletDetailDialogRef,
      pointDialogRef,
      frozenDialogRef,
      rechangeDetailsDialogRef,
      withdrawDetailsDialogRef,
      costDetailsDialogRef,
      handelChange,
      handleLimitChange,
      onPageChange,
      changSearch,
      walletHistoryRef,
      handleWalletHitory,
      onWalletDetailClick,
      mixinReRouter,
      rmLoading,
      toPath_RouterSearchMixin,
      onWalletPointClick,
      onFrozenClick,
      handlerShowDetails,
    };
  },
});
