
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentorderOfflineinvest,
  investmentorderUpdate,
} from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
let index: any; // 编辑的index
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const orderId = ref("");
    const investortype = ref("user");
    const selfDataRef = ref<any>({});
    const selectDataRef = ref<any>({});
    const platformDataRef = ref<any>({});
    const selectData = ref({
      articleproductid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
      launchmode: "PLATFORM",
      investortype: "user",
      fortest: false,
      freegift: false,
      investoruserid: "",
    });
    const selfData = ref({
      mailingcontact: "",
      mailingnumber: "",
      mailingaddress: "",
      articlenum: 10,
      singleamount: 204.0,
      investorproportion: 50,
    });
    const platformData = ref({
      articlenum: 10,
      singleamount: 204.0,
      investorproportion: 50,
    });
    const rules = readonly({
      mailingcontact: [Rules.required()],
      mailingnumber: [Rules.required()],
      mailingaddress: [Rules.required()],
      articlenum: [Rules.required()],
    });

    const open = (row: any, _index: number) => {
      visible.value = true;
      if (!row) return;
      const {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,

        mailingcontact,
        mailingnumber,
        mailingaddress,
        investoruserid,
        articlenum,
        singleamount,
        investorproportion,
        orderid,
      } = row;
      selectData.value = {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,
        investoruserid,
      };
      selfData.value = {
        mailingcontact,
        mailingnumber,
        mailingaddress,
        articlenum,
        singleamount,
        investorproportion,
      };
      platformData.value = {
        articlenum,
        singleamount,
        investorproportion,
      };
      orderId.value = orderid;
      index = _index;
    };
    const handleClose = async () => {
      selfDataRef.value.resetFields();
      selfDataRef.value.clearValidate();
      platformDataRef.value.resetFields();
      platformDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
      index = "";
    };
    const submit = async () => {
      try {
        const rqData = {
          ...(selectData.value.launchmode == "SELF"
            ? selfData.value
            : platformData.value),
          ...selectData.value,
          investmode: "offline",
          salesmanuserid: userStore.info.userid,
          rechargemode:"platform"
        };
        if (investortype.value == "platform") rqData.investoruserid = "980e6462ee785a6e9842d1e553f67755";
        if (selectData.value.launchmode == "SELF") {
          await selfDataRef.value.validate();
        } else if (selectData.value.launchmode == "PLATFORM") {
          await platformDataRef.value.validate();
        }
        btnSumitLoading.value = true;
        const res = orderId.value
          ? await investmentorderUpdate({ ...rqData, orderid: orderId.value })
          : await investmentorderOfflineinvest(rqData);
        ElMessage.success(res.message);
        handleClose();
        emit("success", {
          isEdit: !!orderId.value,
          data: res.record,
          index,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeArticletype = (e: string) => {
      if (e == "umbrella") {
        selfData.value.singleamount = 49;
        platformData.value.singleamount = 49;
      }
      if (e == "phoneholder") {
        selfData.value.singleamount = 204;
        platformData.value.singleamount = 204;
      }
    };
    const changeInvestortype = (e: string) => {
      if (e == "platform") {
        selectData.value.freegift = false;
        selectData.value.investoruserid = '';
      }
    };
    return {
      rules,
      selectData,
      platformData,
      selfData,
      visible,
      btnSumitLoading,
      open,
      selectDataRef,
      selfDataRef,
      platformDataRef,
      submit,
      handleClose,
      changeArticletype,
      changeInvestortype,
      investortype,
    };
  },
});
