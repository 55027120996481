<template>
  <el-dialog :destroy-on-close="true" :title="`上传固件`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <!-- <template v-if="row"> -->
    <el-form :model="form" :rules="rules" label-width="100px" ref="formRef">
      <el-form-item label="设备名称 :">
        <div>{{row.name}}</div>
      </el-form-item>
      <el-form-item label="固件版本号" prop="version">
        <el-input placeholder="请固件版本号如：1.1" v-model="form.version"></el-input>
      </el-form-item>
      <el-form-item label="发布描述" prop="description">
        <el-input :autosize="{ minRows: 3}" placeholder="请输入版本描述内容" type="textarea" v-model="form.description"></el-input>
      </el-form-item>
    </el-form>
    <div class="update">
      <v-file-upload
        :acceptType="'.bin'"
        :data="form"
        :productid="row.productid"
        @success="upLoadSuccess"
        ref="firmwareUploadRef"
        url="/v1/file/firmware/upload"
      ></v-file-upload>
    </div>
    <!-- </template> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { ProductList } from '@/api/System/product'
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, readonly, ref } from 'vue'
import firmwareUpload from './firmwareUpload.vue'
export default defineComponent({
  name: 'v-dialog',
  components: {
    'v-file-upload': firmwareUpload,
  },
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const form = reactive({
      version: '',
      description: '',
    })
    const rules = {
      version: Rules.firmwareVersion,
      description: [Rules.required()]
    }
    const row = ref<ProductList>()
    const btnSumitLoading = ref(false)
    const firmwareUploadRef = ref<any>()
    const formRef = ref<any>()
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: ProductList) => {
      console.log('_row :>> ', _row)
      row.value = _row
      visible.value = true
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        await formRef.value.validate()
        firmwareUploadRef.value.submit()
      } finally {
        btnSumitLoading.value = false
      }
    }
    const upLoadSuccess = (e: string) => {
      emit('success')
      ElMessage.success(e || '上更新固件成功')
    }
    return {
      visible,
      handleClose,
      rules,
      row,
      open,
      btnSumitLoading,
      firmwareUploadRef,
      form,
      formRef,
      submit,
      upLoadSuccess,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>