<template>
  <el-dialog :destroy-on-close="true" :title="`预计费用记录`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      :title="tipMsg"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" label-width="120px" ref="formRef" style="margin-top: 20px;">
      <el-form-item label="开始时间" prop="startTime">
        <span>{{inputData.startTime}}</span>
      </el-form-item>
      <el-form-item label="结算时间" prop="endTime">
        <el-date-picker v-model="inputData.endTime" type="datetime" placeholder="选择日期时间" @change="handlerTimeChange"></el-date-picker>
      </el-form-item>
      <el-form-item label="已扣费用" prop="dueConst">
        <span>￥ {{inputData.dueConst}}</span>
      </el-form-item>
      <el-form-item label="退款费用" prop="durRefund">
        <span>￥ {{inputData.durRefund}}</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button  @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, readonly, inject } from "vue";
import { getRentalfee } from "@/api/util"
import dayjs from "dayjs";
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props,{ emit }) {
    const visible = ref(false)
    const tipMsg = ref<string>("非会员用户，3小时内3元，超过3小时6元一天")
    const time = ref<any>('');
    const isVip=ref(false);
    const isXmj=ref(true);
    const inputData = ref({
      startTime:'',
      endTime:'',
      dueConst: 0,
      durRefund: 0,
    })
    const handleClose = () => {
      visible.value = false
    }
    const open = (_startTime:string,_isVip:boolean,_isXmj:boolean,phone:string,_dueConst?:string,isPrvate?:boolean) => {
      visible.value = true
      inputData.value.startTime=_startTime;
      isVip.value=_isVip;
      isXmj.value=_isXmj;
      tipMsg.value="用户"+phone+"是"+(isVip.value?"会员用户，":"普通用户，")+(isVip.value?"计费是：小魔夹3元一天；雨伞3元一天 ":"计费是：小魔夹3小时内3元，超过3小时6元一天；雨伞3元一天")
      const selectTime=dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if(isPrvate){
        inputData.value.dueConst=parseInt(_dueConst!);
      }else{
        inputData.value.dueConst=parseInt(_dueConst!);
        //inputData.value.dueConst=getRentalfee(inputData.value.startTime,selectTime,isVip.value,isXmj.value); 
      }
      
    }
  
    const submit = async () => {
      handleClose()
      emit('success',{data:inputData.value.durRefund})
    }

    const handlerTimeChange = async(e:any)=>{
      const selectTime=dayjs(e).format("YYYY-MM-DD HH:mm:ss");
      const due=getRentalfee(inputData.value.startTime,selectTime,isVip.value,isXmj.value);
      inputData.value.durRefund=due;
    }

    return{
      visible,
      handleClose,
      open,
      submit,
      inputData,
      tipMsg,
      handlerTimeChange,
      time,
    }
  }
})    
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>