import { defineComponent as _defineComponent } from 'vue'
import {
  AdministrativedivisionList,
  administrativedivisionList,
  administrativedivisionRemove,
} from '@/api/System/city'
import selectCity from '@/components/cityList/index.vue'
import areaDialog from './components/areaDialog.vue'
import { ElMessage } from 'element-plus'
import { getCurrentInstance, onMounted, ref } from 'vue'

export default _defineComponent({
  expose: [],
  setup(__props) {

let code = ''
const loading = ref(false)
const areaDialogRef = ref<any>(null)
const btnLoading = ref(false)
const limit = ref(10)
const total = ref(0)
const page = ref(1)
const list = ref<AdministrativedivisionList[]>([])
const reData = async (re?: boolean) => {
  try {
    if (re) page.value = 1
    loading.value = false
    let { result, totalCount } = await administrativedivisionList({
      limit: limit.value,
      page: page.value,
      superiorcode: code ? code : '0',
    })
    total.value = totalCount
    list.value = result
  } finally {
    loading.value = true
  }
}
onMounted(() => {
  reData()
})
const cityCodeChange = (_code: string) => {
  code = _code
  reData()
}
const onPageChange = (current: number) => {
  page.value = current
  reData()
}
const handelAdd = () => {
  console.log('_ref :>> ', areaDialogRef.value)
  areaDialogRef.value.open({
    isAdd: true,
    ...list.value[0]
  })
}
const handelRemove = async (index: number, row: any) => {
  try {
    loading.value = true
    const res = await administrativedivisionRemove(row.areacode)
    ElMessage.success(res)
    list.value.splice(index, 1)
  } finally {
    loading.value = false
  }
}
const handleEdit = (index: number, row: AdministrativedivisionList) => {
  areaDialogRef.value.open({
    isAdd: false,
    ...row,
    index,
  })
}

return { code, loading, areaDialogRef, btnLoading, limit, total, page, list, reData, cityCodeChange, onPageChange, handelAdd, handelRemove, handleEdit, AdministrativedivisionList, administrativedivisionList, administrativedivisionRemove, selectCity, areaDialog, ElMessage, getCurrentInstance, onMounted, ref }
}

})