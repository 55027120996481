
import { computed, defineComponent, ref, toRef } from "vue";
import pie from "@/components/echart/pie";
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => "180px",
    },
    chartData: {
      type: Array,
    },
  },
  components: {
    "v-pie": pie,
  },
  setup(props) {
    const chartData = toRef(props, "chartData");
    const option = computed(() => {
      return {
        tooltip: {
          trigger: "item",
        },
        color: ["#00F4F4", "#005674"],
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            // hoverOffset: 10,
            radius: ["70%", "80%"],
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            data: chartData.value,
          },
        ],
      };
    });
    const pieRef = ref<any>(null);
    const reView = () => {
      pieRef.value.myRender();
    };
    return {
      option,
      pieRef,
      reView,
    };
  },
});
