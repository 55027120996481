
import { inBoxHttp, inXMJHttp } from '@/api/WarehouseManagement/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { getAdInfoList } from '@/api/util'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const adInfoList = getAdInfoList()

    const whOpTypeList = ref([
      {
        "whOpType":'0',
        "name":"生产"
      },
      {
        "whOpType":'1',
        "name":"维修"
      }
    ])

    const inputData = ref({
      sn: '',
      boxsn:'', 
      type:'0',  //0小魔夹 1包装箱
      adType:'0',//广告类型
      whOpType:'0',  //0生产  1维修
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = () => {
     
      visible.value = true
      console.log('新的确认')
      // inputData.value.day = Math.ceil(
      //   dayjs().diff(_row.receivedate, 'day', true)
      // )
    }
    const submit = async () => {
      try {
         btnSumitLoading.value = true
         const res =inputData.value.type=='0'? 
         await inXMJHttp({adType:inputData.value.adType,sn:inputData.value.sn})
         :await inBoxHttp(inputData.value.boxsn)
      
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      adInfoList,
      whOpTypeList,
    }
  },
})
