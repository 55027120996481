<template>
  <el-dialog :destroy-on-close="true" :title="`退款申请`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
     <el-alert
      :title="tipMsg"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef" style="margin-top: 20px;">
      <el-form-item label="退款原因" prop="reason">
        <el-input :autosize="{ minRows: 2, maxRows: 4}" type="textarea" v-model="inputData.reason"></el-input>
      </el-form-item>
      <el-form-item label="查看费用" >
        <el-button style="margin-left: 16px;" type="primary" @click="handleChack">查询</el-button>
      </el-form-item>
      <el-form-item label="退款金额" prop="amount">
        <!-- <el-input-number :step="3" v-model="inputData.amount"></el-input-number> -->
        <el-input-number :min="0" :max="costs" :precision="2" :step="3" v-model="inputData.amount"></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <dueCoustDialog ref="dueCoustDialogRef" @success="handlerDueCoust"></dueCoustDialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from 'vue'
import { sharedordermemoApplyrefund } from '@/api/Order/index'
import { userList } from "@/api/User/index";
import Rules from '@/common/Rules'
import { ElMessage, ElMessageBox } from 'element-plus'
import dueCoustDialog from '@/components/order/dueCoustDialog.vue';
let sharedorderid = ''
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  components: {
    dueCoustDialog
  },
  setup(props, { emit }) {
    const inputData = ref({
      reason: '',
      amount: 0,
    })
    const geRules = computed(() => {
      return {
        reason: [Rules.required()],
        amount: Rules.number(0, costs.value),
      }
    })
    const visible = ref(false)
    const costs = ref(0)
    const formRef = ref<any>('')
    const dueCoustDialogRef = ref<any>();
    const btnSumitLoading = ref(false)
    const userInfoList = ref<any>([]);
    const startTime = ref<string>('');
    const isVip = ref<boolean>(false);
    const isXmj = ref<boolean>(true);
    const phone = ref<string>('');
    const tipMsg = ref<string>("非会员用户，3小时内3元，超过3小时6元一天")
    const handleClose = () => {
      visible.value = false
      sharedorderid = ''
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async (id: string, userid:string, _costs: number,_startTime:string,_deviceType:string) => {
      await getUserInfo(userid);
      visible.value = true
      inputData.value.amount = _costs
      costs.value = _costs
      sharedorderid = id
      startTime.value=_startTime;
      isXmj.value=_deviceType=='phoneholder';
    }

    const handleChack = async() => {
      console.log('计算费用',costs.value)
      dueCoustDialogRef.value.open(startTime.value,isVip.value,isXmj.value,phone.value,''+costs.value)
    }

    const handlerDueCoust = async(e:any) =>{
      console.log('返回数据',e.data)
      inputData.value.amount=parseInt(e.data)
    }

    const getUserInfo = async (userid:string) => {     
      let rqData: any = { page: 1, limit: 10, userid:userid };
      const res = await userList(rqData);
      userInfoList.value = res.result;
      isVip.value=userInfoList.value[0].isvip==1;
      phone.value=userInfoList.value[0].account
      tipMsg.value="用户"+phone+"是"+(isVip?"会员用户，":"普通用户，")+(isVip?"计费是：小魔夹3元一天；雨伞3元一天 ":"计费是：小魔夹3小时内3元，超过3小时6元一天；雨伞3元一天")
    };
    const submit = async () => {
      try {
        await formRef.value.validate()
        await ElMessageBox.confirm(
          `请确认退款${inputData.value.amount}元？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        btnSumitLoading.value = true
        const res = await sharedordermemoApplyrefund({
          ...inputData.value,
          sharedorderid,
        })
        ElMessage.success(res)
        emit('success')
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }

    return {
      visible,
      handleClose,
      open,
      tipMsg,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
      costs,
      handleChack,
      dueCoustDialogRef,
      handlerDueCoust,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>