
import { defineComponent, ref, readonly } from 'vue'
import { investmentorderList, UserListRes, removeUser } from '@/api/ERP/user'
import addDialog from './components/addDialog.vue'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'investor',
  components: {
    'v-add-dialog': addDialog,
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const btnSumitLoading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const total = ref(0)
    const list = ref<UserListRes[]>([])
    const addDialogRef = ref<any>()
    const mixin_select_search = ref({
      search: {
        userid: '', // 用户编号
        name: '', // 姓名,
        account: '', // 账号
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        userid: '用户编号', // 投资人用户编号
        name: '姓名', // 投资人用户编号
        account: '账号', //投资订单编码
      },
    })
    const searchSelect = ref({})

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = {
          page: page.value,
          limit: limit.value,
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, data: rq }
        }
        const res = await investmentorderList(rqData)
        total.value = res.total
        list.value = res.list
      } finally {
        loading.value = false
      }
    }

    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
    } = routerChangeReData({
      mixin_select_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open()
    }
    const dialogSuccess = (data: any) => {
      list.value.push(data)
    }
    const handleRemove = async (index: number, id: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removeUser({
          id,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      addDialogRef,
      handleAdd,
      btnSumitLoading,
      dialogSuccess,
      handleRemove,
    }
  },
})
