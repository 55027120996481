export default {
  required(message = '此项为必填项') {
    return { required: true, message, trigger: 'blur' }
  },
  phone() {
    return [
      { required: true, message: "请输入手机号码", trigger: "blur" },
      { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
      {
        pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
        message: "请输入正确的手机号码"
      }
    ]
  },
  number(min: number, max: number) {
    return [
      { required: true, message: "此项为必填项", trigger: "blur" },
      {
        type: 'number', message: `请输入${min} - ${max}的数`,
        validator(rule: any, value: number) {
          return value >= min && value <= max
        },
      },
    ]
  },
  firmwareVersion: [
    { required: true, message: "此项为必填项", trigger: "blur" },
    {
      required: true,
      message: `请输入正确的版本号,如1.1`,
      pattern: /\d+\.\d+/,
    },
  ],
}