
  import Rules from '@/common/Rules'
  import { defineComponent, ref } from 'vue'
  import { getProductInfoList } from '@/api/util'
  import { ElMessage } from 'element-plus'
  import { userStore } from '@/store/modules/user'
  import { addNoInvestorOrderShipmentHttp} from "@/api/Investment/shipment";
  export default defineComponent({
    name: 'assign-new-dialog',
    emits: ['success'],
    setup(props, { emit }) {
      const visible = ref(false)
      const btnSumitLoading = ref(false)
      const btnCancelLoading = ref(false)
      const productList = getProductInfoList()
      const formRef =ref<any>("");
      const rules = {
        quantity: [Rules.required()],
      }
      const inputData = ref({
        productid: "",
        quantity:'',
      });
      const handleClose = () => {
        btnSumitLoading.value = false
        visible.value = false
        formRef.value.resetFields();
        formRef.value.clearValidate();
      }
      const open = (data: any, _isShow = false) => {
      
        visible.value = true
    
      }
     
      const submit = async() => {
        try {
          try {
            await formRef.value.validate();
          } catch (error) {
            console.log(error);
            throw Error("请检查输入");
          }
          btnSumitLoading.value = true;
          const res = await addNoInvestorOrderShipmentHttp({
            quantity: inputData.value.quantity,
            productId:inputData.value.productid,
            shipperUserId: userStore.info.userid,
          })
          ElMessage.success(res);
          handleClose();
          emit('success',res)
        }finally {
          btnSumitLoading.value = false;
        }
      }
      return {
        visible,
        handleClose,
        open,
        rules,
        productList,
        inputData,
        btnSumitLoading,
        btnCancelLoading,
        submit,
        formRef,
      }
    },
  })
  