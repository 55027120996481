import { xmj_admin_Http } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
const prefix = '/local/warehouseManagement'
export interface WarehouseManagementListRes {
    creationdate: string;
    userid: string
    account: string
    name: string
    create_time: string
    loading: boolean;
}

// export async function investmentorderList(params: ListReq): Promise<ListRes<WarehouseManagementListRes>> {
export async function investmentorderList(params: ListReq): Promise<any> {
    params.token = true
    const res = await xmj_admin_Http(prefix).request<ListRes<WarehouseManagementListRes>>(
        {
            url: `/list`,
            method: "POST",
            params
        }
    );
    if (res.data.list) {
        res.data.list = res.data.list.map(v => {
            v.create_time = dayjs(v.create_time).format("YYYY-MM-DD HH:mm:ss")
            v.loading = false
            return v
        })
    }
    return res.data
}

export async function add(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/add`,
            params
        }
    );
    return reData
}
interface WarehouseManagementInoutReq {
    status: number
    sn: string
    type: 'old' | 'new',
    token: string
}
export async function warehouseManagementInout(params: WarehouseManagementInoutReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/inout`,
            params
        }
    );
    return reData
}



export async function warehouseManagementUpdate(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/update`,
            params
        }
    );
    return reData
}
export async function removeWarehouseManagement(params: RequestReq) {
    const reData = await xmj_admin_Http(prefix).request<RequestRes>(
        {
            url: `/remove`,
            params
        }
    );
    return reData
}