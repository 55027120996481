
import { AppRouteRecordRawChildren } from "@/router/types";
import { TagsViewStore, TagsViewType } from "@/store/modules/tagsView";
import { computed, defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "TagsView",
  setup() {
    const initTag = () => {
      if (route.path == "/home") return TagsViewStore.initTages();
      TagsViewStore.initTages({
        path: route.path,
        meta: route.meta as any,
        name: route.name as string,
      });
    };
    const tagsView = computed(() => {
      return TagsViewStore.getTagsView;
    });
    const handleClose = (index: number) => {
      if (route.path == tagsView.value[index].path) {
        router.push(tagsView.value[index - 1].path);
      }
      TagsViewStore.delTage(index);
    };
    const handleClick = (tag: TagsViewType) => {
      if (tag.path == route.path) return;
      router.push(tag.path);
    };
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      initTag();
    });
    return {
      tagsView,
      route,
      handleClose,
      handleClick,
    };
  },
});
