
import { AllWallets, userconsumewalletAll, WalletType } from "@/api/User";
import { userModel,getUserStatistics} from "@/api/Financial/user";
import { investorModel,getInvestorStatistics} from "@/api/Financial/investor";
import { defineComponent, ref,onMounted, toRef} from "vue"
import { toOptional } from "@/types/utils";
export default defineComponent({
  props: ["userid","vip"],
  setup(props){
    const walletInfo = ref<toOptional<WalletType>>({});
    const walletInfoTest = ref<toOptional<WalletType>>({});
    const normal = ref<toOptional<userModel>>({});
    const investor = ref<toOptional<investorModel>>({});
    const userid = toRef(props, "userid");
    const vip = toRef(props, "vip");
    const reData = async () => {
      try {
        console.log("userid :>> ", userid);
       
        const res = await userconsumewalletAll(userid.value);
        console.log("钱包res :>> ", res);
        walletInfo.value = res.userAllWallets['M4S2'];
        walletInfoTest.value = res.userAllWalletsTest['M4S2'];
        if(vip.value!=null){
          console.log('是否会员11=',parseInt(vip.value))
          if(parseInt(vip.value)>0){  //是会员
            reInvestorUser();
          }else{
            reNormalUser();
          }
        }
      } finally {
        //loading.value = false;
      }
    };

    const reNormalUser = async () => {
     
      try {
        let rqData: any = { currentPage: "1", size: "10", fortest:false,userid:userid.value };
        const res = await getUserStatistics(rqData);
        normal.value = res.data.records[0];     
      } finally {
      }
    };
    const reInvestorUser = async () => {  
      try {
        let rqData: any = { currentPage: "1", size: "10", fortest:false, userid:userid.value};
        const res = await getInvestorStatistics(rqData);    
        investor.value = res.data.records[0];   
      } finally {
       
      }
    };
    onMounted(() => {
      reData();
    });
    // const route = useRoute()
    // return{
    //   userid:route.query.userid
    // }
    return {
      walletInfo,
      walletInfoTest,
      vip,
      normal,
      investor,
    }
  }
})
