<template>
  <div style="height: 380px; margin-left: 24px; margin-top: 12px;">
    <el-steps direction="vertical" :active="active">
        <el-step title="归还" :description="syncInfo.description"></el-step>
        <el-step title="接收设备" :description='syncInfo.returndate+"收到设备"'></el-step>
        <el-step title="发起扣费"></el-step>
        <el-step title="支付成功" :description='"订单扣款"+syncInfo.paymentamount+"元"'></el-step>
        <el-step title="投放设备" :description='"订单扣款"+syncInfo.paymentamount+"元"'></el-step>
        <el-step title="订单完结" ></el-step>
    </el-steps>
  </div>
</template>
<script  lang='ts'>
import { ref,reactive,defineComponent,toRef,onBeforeMount,onMounted,onUnmounted,onBeforeUpdate} from 'vue'
import { SharedorderprivatizationdeductionListNew, 
        sharedorderprivatizationnegotiationLoadNew,
        SharedorderprivatizationnegotiationLoadNew,} from "@/api/Order/Privatization";
import { userStore } from "@/store/modules/user";        
export default defineComponent({
   props: ["info"],
   setup(props) {

    const returnDes=ref(""); 
    const info: SharedorderprivatizationdeductionListNew = toRef(props, "info");
    console.log('愿意归还')

    const orderDetailsInfo=ref<SharedorderprivatizationnegotiationLoadNew[]>([]);
    
    //const status=toRef(props,"statu");
    //getOrderProgress(info);
    console.log('开始处理');
    const active=ref(0);
    

    /**
     * 获取私有化进程详情
     */
    const getOrderProgress = async (item:any) => {
        console.log('私有化处理加载')
        console.log(item.value.status)
        const status = item.value.status;
        // const overdueId=item.id.toString(); //1476384977803993090
        const overdueId="1476384977803993090"; //1476384977803993090
        const userToken=userStore.userInfo.token!;
      
        if (status > 0) {
            const deInfo = await sharedorderprivatizationnegotiationLoadNew({ token: userToken,overdueId:overdueId})
            console.log('进程加载')
            console.log(deInfo)
            orderDetailsInfo.value=deInfo.data;
            console.log(orderDetailsInfo.value)
        }
    }

    const setActiveValue = async(item:any) =>{
       if(item.value.status==0){
        active.value=0;
      }else if(item.value.status==1){
        active.value=1;
      }else if(item.value.status==3){
        active.value=2;
        if(item.value.deductStatus==1){  //开始扣款
          active.value=3;
        }else if(item.value.deductStatus==99){ //扣款完成
          active.value=4;
        }
      }else if(item.value.status==5){
        active.value=5;
      }else if(item.value.status==99){
        active.value=6;
      }
    }

    // onBeforeMount(()=>{
    //   console.log('函数执行')
    //   console.log(info)
    //     getOrderProgress(info)
    // })

    // onMounted(()=>{
    //   console.log('挂在111')
    //   console.log(info)
    // })

    onBeforeUpdate(()=>{
      console.log('数据更新之前111')
      console.log(info)
      getOrderProgress(info)
      setActiveValue(info)
    })

    // onUnmounted(()=>{
    //   console.log('销毁111')
    //   console.log(info)
    // })
    return {
      syncInfo: info,
      active,
      returnDes,
    };
  },
})
</script>
<style scoped lang='scss'>
</style>