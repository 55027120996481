import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface totalProfitModel {
    "account": string, //手机号
    "nickname": string, //昵称
    "userid": string, //用户id
    "total": number, //总收益
    "gainerrole": string, //用户角色  investor 投资人；agent 代理人；superior 投资人的上线；presuper 投资人的上上线；partner 城市合伙人；platform 平台
    "gainertype": string, //收益类型 如：user 用户；platform 平台
    "proportion": number,  //分成比例
    "gaineruserid": string,//用户角色id
    "fortest": number,    //是否测试
    "totalnetprofitamount": number, //净利润额
    "totaltaxamount": number,  //纳税额
    
}

/**
 * 
 * @param params 查询投资人的收益账单
 * @returns 
 */
export async function totalProfitOfUserRoleHttp(parmas: ListReq): Promise<ListRes<totalProfitModel>> {
    const res = await defHttp.request<ListRes<totalProfitModel>>(
        {
            url: `/profit/getTotalProfitOfUserRole`,
            params: {
                token: true,
                ...parmas
            },
        },
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
   
    return res
}
