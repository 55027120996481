<!-- 添加投资订单 -->
<template>
  <el-dialog
    :append-to-body="true"
    :destroy-on-close="true"
    :title="`创建投资订单`"
    @closed="handleClose"
    v-model="visible"
    width="700px"
  >
    <el-form :model="selectData" label-width="120px" ref="selectDataRef">
      <el-form-item label="投资类型">
        <el-select
          @change="changeArticletype"
          placeholder="请选择投资类型"
          v-model="selectData.articleproductid"
        >
          <el-option label="小魔夹" value="56c62a11a6a14d6eb7ab29fbf9f2f8b4"></el-option>
          <el-option label="雨伞" value="d965476559f0428483b03f08941cda39"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投资者类型">
        <el-select @change="changeInvestortype" placeholder="请选择投资者类型" v-model="investortype">
          <el-option label="用户" value="user"></el-option>
          <el-option label="平台 " value="platform"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select placeholder="请选择订单类型" v-model="selectData.fortest">
          <el-option :value="false" label="正常订单"></el-option>
          <el-option :value="true" label="测试订单"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放类型">
        <el-select placeholder="请选择投放类型" v-model="selectData.launchmode">
          <el-option label="自投放" value="SELF"></el-option>
          <el-option label="平台投放" value="PLATFORM"></el-option>
        </el-select>
      </el-form-item>
      <div v-show="investortype=='user'">
        <el-form-item label="是否为免费赠送">
          <el-select placeholder="请选择是否免费" v-model="selectData.freegift">
            <el-option :value="true " label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
          <my-tip content="请慎重选择"></my-tip>
        </el-form-item>
        <el-form-item label="投资人用户ID">
          <el-input v-model="selectData.investoruserid"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-form
      :model="selfData"
      :rules="rules"
      label-width="120px"
      ref="selfDataRef"
      v-show="selectData.launchmode=='SELF'"
    >
      <el-form-item label="寄件联系人" prop="mailingcontact">
        <el-input v-model="selfData.mailingcontact"></el-input>
      </el-form-item>
      <el-form-item label="寄件联系电话" prop="mailingnumber">
        <el-input v-model="selfData.mailingnumber"></el-input>
      </el-form-item>
      <el-form-item label="寄件地址" prop="mailingaddress">
        <el-input v-model="selfData.mailingaddress"></el-input>
      </el-form-item>
      <el-form-item label="小魔夹数量" prop="articlenum">
        <el-input-number
          :min="1"
          :precision="0"
          :step="1"
          controls-position="right"
          v-model="selfData.articlenum"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="单价" prop="singleamount">
        <el-input-number
          :min="0"
          :precision="2"
          :step="1"
          controls-position="right"
          v-model="selfData.singleamount"
        ></el-input-number>
        <!-- <span>&ensp;总额{{selfData.singleamount*selfData.articlenum}}</span> -->
      </el-form-item>
      <el-form-item label="投资人分成比例" prop="investorproportion">
        <el-input-number
          :max="100"
          :min="0"
          :precision="2"
          :step="0.1"
          v-model="selfData.investorproportion"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <el-form
      :model="platformData"
      :rules="rules"
      label-width="120px"
      ref="platformDataRef"
      v-show="selectData.launchmode=='PLATFORM'"
    >
      <el-form-item label="小魔夹数量" prop="articlenum">
        <el-input-number
          :min="1"
          :precision="0"
          :step="1"
          controls-position="right"
          v-model="platformData.articlenum"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="单价" prop="singleamount">
        <el-input-number
          :min="1"
          :precision="2"
          :step="1"
          controls-position="right"
          v-model="platformData.singleamount"
        ></el-input-number>
        <!-- <span>&ensp;总额{{platformData.singleamount*platformData.articlenum}}</span> -->
      </el-form-item>
      <el-form-item label="投资人分成比例" prop="investorproportion">
        <el-input-number
          :max="100"
          :min="0"
          :precision="1"
          :step="0.1"
          controls-position="right"
          v-model="platformData.investorproportion"
        ></el-input-number>&ensp;%
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentorderOfflineinvest,
  investmentorderUpdate,
} from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
let index: any; // 编辑的index
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const orderId = ref("");
    const investortype = ref("user");
    const selfDataRef = ref<any>({});
    const selectDataRef = ref<any>({});
    const platformDataRef = ref<any>({});
    const selectData = ref({
      articleproductid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
      launchmode: "PLATFORM",
      investortype: "user",
      fortest: false,
      freegift: false,
      investoruserid: "",
    });
    const selfData = ref({
      mailingcontact: "",
      mailingnumber: "",
      mailingaddress: "",
      articlenum: 10,
      singleamount: 204.0,
      investorproportion: 50,
    });
    const platformData = ref({
      articlenum: 10,
      singleamount: 204.0,
      investorproportion: 50,
    });
    const rules = readonly({
      mailingcontact: [Rules.required()],
      mailingnumber: [Rules.required()],
      mailingaddress: [Rules.required()],
      articlenum: [Rules.required()],
    });

    const open = (row: any, _index: number) => {
      visible.value = true;
      if (!row) return;
      const {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,

        mailingcontact,
        mailingnumber,
        mailingaddress,
        investoruserid,
        articlenum,
        singleamount,
        investorproportion,
        orderid,
      } = row;
      selectData.value = {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,
        investoruserid,
      };
      selfData.value = {
        mailingcontact,
        mailingnumber,
        mailingaddress,
        articlenum,
        singleamount,
        investorproportion,
      };
      platformData.value = {
        articlenum,
        singleamount,
        investorproportion,
      };
      orderId.value = orderid;
      index = _index;
    };
    const handleClose = async () => {
      selfDataRef.value.resetFields();
      selfDataRef.value.clearValidate();
      platformDataRef.value.resetFields();
      platformDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
      index = "";
    };
    const submit = async () => {
      try {
        const rqData = {
          ...(selectData.value.launchmode == "SELF"
            ? selfData.value
            : platformData.value),
          ...selectData.value,
          investmode: "offline",
          salesmanuserid: userStore.info.userid,
        };
        if (investortype.value == "platform") rqData.investoruserid = "980e6462ee785a6e9842d1e553f67755";
        if (selectData.value.launchmode == "SELF") {
          await selfDataRef.value.validate();
        } else if (selectData.value.launchmode == "PLATFORM") {
          await platformDataRef.value.validate();
        }
        btnSumitLoading.value = true;
        const res = orderId.value
          ? await investmentorderUpdate({ ...rqData, orderid: orderId.value })
          : await investmentorderOfflineinvest(rqData);
        ElMessage.success(res.message);
        handleClose();
        emit("success", {
          isEdit: !!orderId.value,
          data: res.record,
          index,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeArticletype = (e: string) => {
      if (e == "umbrella") {
        selfData.value.singleamount = 49;
        platformData.value.singleamount = 49;
      }
      if (e == "phoneholder") {
        selfData.value.singleamount = 204;
        platformData.value.singleamount = 204;
      }
    };
    const changeInvestortype = (e: string) => {
      if (e == "platform") {
        selectData.value.freegift = false;
        selectData.value.investoruserid = '';
      }
    };
    return {
      rules,
      selectData,
      platformData,
      selfData,
      visible,
      btnSumitLoading,
      open,
      selectDataRef,
      selfDataRef,
      platformDataRef,
      submit,
      handleClose,
      changeArticletype,
      changeInvestortype,
      investortype,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 0 0 24px 36px;
  .el-select {
    margin-right: 21px;
  }
}
</style>