import type { App, Prop } from 'vue';
import { h } from 'vue';
import { ElMessage } from "element-plus"
import { toPath_RouterSearchMixin } from "@/common/toPath"
import { hoverShowQr } from "@/components/qrCode/hoverShowQr"
// import 'echarts/index.common'
// import 'echarts'
//复制提示框
function copyToClipboardAsync(text: string): Promise<any> {
    const textArea = document.createElement('textarea')
    textArea.style.position = 'fixed'
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.width = '2em'
    textArea.style.height = '2em'
    textArea.style.padding = '0'
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'
    textArea.style.background = 'transparent'
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    return new Promise((res, rej) => {
        try {
            const successful = document.execCommand('copy')
            if (!successful) throw Error('该浏览器不支持点击复制到剪贴板')
            res(1)
        } catch (err) {
            rej(err)
        } finally {
            document.body.removeChild(textArea)
        }
    })
}

//我的复制组件
const myCopy = {
    name: "my-copy",
    props: {
        data: {
            type: String
        }
    },
    setup(props: any) {
        const onClick = async () => {
            await copyToClipboardAsync(props.data);
            ElMessage.success(`${props.data} 已复制到粘贴板`)
        }
        return () => h('span', {
            class: ['el-icon-document-copy'],
            style: {
                cursor: 'pointer'
            },
            onClick,
        })
    }
}

//颜色图标
const colorIcon = {
    name: 'color-icon',
    props: {
        type: String
    },
    setup(props: any) {
        return () => h('svg', {
            class: ['el-icon-document-copy'],
            'aria-hidden': true,
            style: {
                width: '18px',
                height: '18px',
                'vertical-align': '-0.15em',
                fill: 'currentColor',
                overflow: 'hidden'
            }
        }, [
            h('use', {
                'xlink:href': '#' + props.type
            }),
        ])
    }
}

//我的提示
const myTip = {
    name: "my-tip",
    props: {
        content: String,
        size: String
    },
    setup(props: any) {
        return () => h('span', {
            style: {
                fontSize: props.size ? props.size : "12px",
                padding: "5px 10px"
            }
        }, [
            h('span', {
                style: {
                    color: "red",
                    marginRight: "6px"
                }
            }, "*"),
            h('span', {
                style: {
                    color: "#ddd"
                }
            }, props.content),
        ])
    }
}

//点击数据至路由路径
const clickDataToRouterPath = {
    name: "my-click-data-to",
    props: ["data", "url", "params"],
    setup(props: any) {
        const onClick = async () => {
            toPath_RouterSearchMixin(props.url, props.params)
        }
        return () => h('span', {
            style: {
                cursor: 'pointer',
                color: "#409eff"
            },
            onClick,
        }, props.data)
    }
}
import { resolveComponent } from 'vue'

//点击按钮传送数据到路径
const clickButtonToRouterPath = {
    name: "my-click-btn-to",
    props: ["data", "url", "params", 'size', 'type'],
    setup(props: any) {
        // setup(props: { data: string, url: string, size: string, type: string, params: string[] }) {
        const onClick = async () => {
            toPath_RouterSearchMixin(props.url, props.params)
        }
        return () => h(resolveComponent('el-button'), {
            type: props.type || "primary",
            size: props.size || "mini",
            onClick,
        }, props.data)
    }
}

export function setupComponents(app: App<Element>) {
    app.component(myCopy.name, myCopy)
    app.component(colorIcon.name, colorIcon)
    app.component(myTip.name, myTip)
    app.component(clickDataToRouterPath.name, clickDataToRouterPath)
    app.component(clickButtonToRouterPath.name, clickButtonToRouterPath)
    app.component(hoverShowQr.name, hoverShowQr)
}