
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { investorModel,getInvestorStatistics,exportInvestorStatistics} from "@/api/Financial/investor";
import couponVue from "@/components/coupon/index.vue";
import walletHistoryDialog from "@/components/wallet/walletHistoryDialog.vue";
import detailsDialog from "@/components/wallet/detailsDialog.vue";
import pointDialog from "@/components/wallet/pointDialog.vue";
import profitDetailsDilaog from "@/components/financial/profitDetailsDialog.vue";
import rewardDetailsDialog from "@/components/financial/rewardDatailsDialog.vue";
import rechangeDetailsDialog from "@/components/financial/rechangeDetailsDialog.vue"
import withdrawDetailsDialog from "@/components/financial/withdrawDetailsDialog.vue"
import costDetailsDialog from "@/components/financial/costDetailsDialog.vue"
import dayjs from 'dayjs'
import console from "console";
import { saveAs } from 'file-saver'
export default defineComponent({
  name: "v-investor",
  components: {
    couponVue,
    walletHistoryDialog,
    detailsDialog,
    pointDialog,
    profitDetailsDilaog,
    rewardDetailsDialog,
    rechangeDetailsDialog,
    withdrawDetailsDialog,
    costDetailsDialog,
  },
  setup() {
    const loading = ref(false);
    const rmLoading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<investorModel[]>([]);
    const couponRef = ref<any>("");
    const walletHistoryRef = ref<any>("")
    const walletDetailDialogRef = ref<any>();
    const pointDialogRef = ref<any>();
    const profitDetailsDialogRef = ref<any>();
    const rewardDetailsDialogRef = ref<any>();
    const rechangeDetailsDialogRef = ref<any>();
    const withdrawDetailsDialogRef = ref<any>();
    const costDetailsDialogRef = ref<any>();
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      fortest: false,
      timeBN:''
    });
    const mixin_select_search = ref({
      search: {
        name: "",
        account: "",
        userid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        name: "昵称",
        account: "账号",
        userid: "用户ID",
      },
    });

    const handleCoupon = (userid:string,name:string) =>{
      couponRef.value.open(userid,name)
    }
    /**
     * 钱包流水
     */
    const handleWalletHitory = (userid:string,account:string) => {
      walletHistoryRef.value.open(userid,account)
    }
     /**
     * 钱包明细
     */
    const onWalletDetailClick = (userid :string,account:string) =>{
      walletDetailDialogRef.value.open(userid,account)
    }
    /**
     * 积分记录
     */
    const onWalletPointClick = (userid :string,account:string) => {
      pointDialogRef.value.open(userid,account)
    }
    /**
     * 下级详情
     */
    const handlerShowDetails = (type : string,userid : string, account : string,name:string) => {
      if(type=='1'){
        profitDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=='2'){
        rewardDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=='3'){
        rechangeDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type=="4"){
        withdrawDetailsDialogRef.value.open(userid,type,name,account,name);
      }else if(type == "5"){
        costDetailsDialogRef.value.open(userid,type,name,account,name);
      }
    }
    const reData = async (rq?: any) => {
      console.log("搜索参数",rq)
      try {
        loading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value, fortest:mixin_router_search.value.fortest };
        if (rq.timeBN) {
          rqData.startTime = dayjs(rq.timeBN[0]).format("YYYY-MM-DD HH:mm:ss").toString() 
          rqData.endTime =  dayjs(rq.timeBN[1]).format("YYYY-MM-DD HH:mm:ss").toString()  
          delete rq.timeBN
        }
        rqData={...rq,...rqData}
        const res = await getInvestorStatistics(rqData);
        console.log('查询到的数据',res)
        list.value = res.data.records;
        console.log('查询到的数据1',list.value)
        total.value = res.data.total;
      } finally {
        loading.value = false;
      }
    };
    const handleExport = async() =>{
      try {
        let searchData: any = {fileName:'investor'}
        let rqData: any = {fortest:mixin_router_search.value.fortest}
        
        searchData={...searchData,...mixin_select_search.value.search}

        console.log('导出参数=',searchData)
        if (mixin_router_search.value.timeBN) {
          rqData.startTime = dayjs(mixin_router_search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(mixin_router_search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            rqData[key]=searchData[key]
          }
        })
        console.log('遍历之后=',rqData)

        const res = await exportInvestorStatistics(rqData)
        console.log('导表=',res)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, '会员统计.xls')
      } finally {
       
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
  

    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      walletDetailDialogRef,
      pointDialogRef,
      profitDetailsDialogRef,
      rewardDetailsDialogRef,
      rechangeDetailsDialogRef,
      withdrawDetailsDialogRef,
      costDetailsDialogRef,
      onWalletDetailClick,
      handelChange,
      handleLimitChange,
      onPageChange,
      changSearch,
      handleCoupon,
      mixinReRouter,
      rmLoading,
      toPath_RouterSearchMixin,
      couponRef,
      handleWalletHitory,
      walletHistoryRef,
      onWalletPointClick,
      handlerShowDetails,
      handleExport,
    };
  },
});
