
import { defineComponent, ref, readonly, inject ,onMounted} from 'vue'
import { fourthgcommandList } from '@/api/Share/Device'
import { ElMessage, ElMessageBox } from 'element-plus'
import {useRoute} from "vue-router"
import dayjs from 'dayjs'
export default defineComponent({
  name: 'send',
  components: {
   
  },
  setup() {

    const route = useRoute()
    const deviceId = ref<any>();
    deviceId.value=route.query.deviceid;
   
  
    
   
    const deviceSn=ref<any>();   
    const list = ref<any[]>([])
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
  
    const btnRemoveLoading = ref(false)
   

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { page: page.value, limit: limit.value,deviceid:deviceId.value,sort: 'creationtime',
      order: 'desc' }
         if (rq ) {
        
          rqData = { ...rqData, ...rq }
        }

        let { totalCount, result } = await fourthgcommandList(rqData)
        total.value = totalCount
        list.value = result
      } finally {
        loading.value = false
      }
    }
    

    const handelSearch = () => {

    }

    const handelChange = () => {
      page.value = 1
     
    }

    const onPageChange = async(item:any) => {
        console.log('大小改变')
        console.log(item)
        page.value=item;
        reData(false)
    }

    const handleLimitChange = async(item:any) => {
      console.log('大小改变2')
      console.log(item)
      page.value=item;
      reData(false)
    }
    
    const roleShowPermission = inject('roleShowPermission')

     onMounted(() => {
    
      reData();
    });

    return {
         
      onPageChange,
      handelSearch,
      handelChange,
      handleLimitChange,
      list,
      loading,
      deviceSn,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      
     
    }
  },
})
