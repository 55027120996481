<template>
  <div class="container">
    <header>
      <el-select @change="mixinReRouter" placeholder="设备类型" v-model="mixin_router_search.fortest">
        <el-option :value="false" label="正式钱包"></el-option>
        <el-option :value="true" label="测试钱包"></el-option>
      </el-select>
      <el-select @change="mixinReRouter" placeholder="设备类型" v-model="mixin_router_search.articleproductid">
        <el-option label="小魔夹" value="56c62a11a6a14d6eb7ab29fbf9f2f8b4"></el-option>
        <el-option label="雨伞" value="d965476559f0428483b03f08941cda39"></el-option>
        <!-- <el-option :key="i.productid" :label="i.name" :value="i.productid" v-for="i of walletsProductInfo"></el-option> -->
      </el-select>
      <el-button @click="mixinReRouter()" type="primary" icon="el-icon-refresh">刷新</el-button>
    </header>
    <div>
      <el-card :loading="myLoading">
        <el-row :gutter="8">
          <div class="name"></div>
          <el-col :span="10" style="text-align:center">
            <div class="item incom">
              <div class="title">
                <i class="el-icon-wallet"></i>
                收益钱包
              </div>
              <div class="content">
                <span class="label">¥</span>
                <span class="value">{{incom}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="4" class="flex_cc cbox">
            <el-button :loading="btnChangeLoading" @click="handleChange" type="text">>>> 转到支出 >>></el-button>
          </el-col>
          <el-col :span="10" style="text-align:center">
            <div class="item">
              <div class="title">
                <i class="el-icon-wallet"></i>
                支出钱包
              </div>
              <div class="content">
                <span class="label">¥</span>
                <span class="value">{{expenditure}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { getShareProductInfo } from '@/api/util'
import {
  platformexpensewalletLoad,
  platformprofitwalletLoad,
  platformprofitwalletTransfer,
} from '@/api/Financial/PartfromWallet'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getSessionStorage } from '@/utils/localStore'
export default defineComponent({
  setup() {
    const myLoading = ref(false)
    const expenditure = ref(0)
    const incom = ref(0)
    const btnChangeLoading = ref(false)
    const walletsProductInfo = readonly(getShareProductInfo())
    const mixin_router_search = ref({
      articleproductid: walletsProductInfo[0].productid,
      fortest: false,
    })
    const reData = async (rq?: any) => {
      try {
        if (typeof rq.fortest == 'string') {
          // 暂时解决router传参boolean 类型变字符串问题
          mixin_router_search.value.fortest = JSON.parse(rq.fortest)
        }
        myLoading.value = true
        expenditure.value = (await platformexpensewalletLoad(rq)).amount
        incom.value = (await platformprofitwalletLoad(rq)).amount
      } finally {
        myLoading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        loading: myLoading,
        reData,
      })
    const handleChange = async () => {
      const res = await ElMessageBox.prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\w{5,17}$/,
        inputErrorMessage: '密码错误',
        inputType: 'password',
      })
      console.log('res :>> ', res)
      const user = getSessionStorage('user')
      if (res.value != user.password) return ElMessage.error('密码错误')
      const { value } = await ElMessageBox.prompt('请输入转账金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: `请输入大于0小于等于${incom.value}数字`,
        inputType: 'number',
        inputValidator: (value): boolean => {
          const _value = parseFloat(value)
          if (isNaN(_value)) return false
          return _value > 0 && _value <= incom.value
        },
      })
      await ElMessageBox.confirm(`请确认是否转账${value}元到支出钱包`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        distinguishCancelAndClose: true,
      })
      const amount = parseFloat(value)
      try {
        btnChangeLoading.value = true
        const res = await platformprofitwalletTransfer({
          ...mixin_router_search.value,
          amount,
        })
        ElMessage.success(res)
        mixinReRouter()
      } finally {
        btnChangeLoading.value = false
      }
    }
    return {
      mixin_router_search,
      changSearch,
      myLoading,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      walletsProductInfo,
      mixinReRouter,
      expenditure,
      incom,
      handleChange,
      btnChangeLoading,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    margin-bottom: 24px;
    .el-select {
      width: 150px;
      margin-right: 24px;
    }
  }
  .item {
    .title {
      // border-bottom: 4px dotted #adcadc;
    }
    .el-icon-wallet {
      color: $--warning;
      font-weight: bold;
      font-size: 24px;
    }
    .content {
      line-height: 100px;
      .value {
        font-size: 52px;
        color: $--warning;
        margin-left: 12px;
      }
    }
  }
  .incom {
    .el-icon-wallet {
      color: #67c23a;
    }
    .content {
      .value {
        color: #67c23a;
      }
    }
  }
}
</style>