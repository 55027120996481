import { sharedarticleInvertOrderCountByStatus, SharedarticleInvertOrderCountByStatus } from "@/api/Index/platfrom";
import { ref } from "vue"

export default function () {
    const invertOrder = ref({
        data: {
            dimensions: ['name', 'num'],
            source: [] as SharedarticleInvertOrderCountByStatus['numAndRatio']
        },
        type: 1,
        title: '投资订单',
        box1: {
            label: '订单数',
            value: 1,
        },
        box2: {
            label: '小魔夹数',
            value: 1,
        },
    })

    const reInvertOrderData = async ({ productid }: { productid?: string }) => {
        const res = await sharedarticleInvertOrderCountByStatus(productid);
        console.log('res :>> ', res);
        invertOrder.value.data = {
            dimensions: ['name', 'num'],
            source: res.numAndRatio
        }
        invertOrder.value.box1.value = res.countNum.ordernum;
        invertOrder.value.box2.value = res.countNum.num;
        console.log('reInvertOrderData :>> ', invertOrder.value);
    };
    return {
        invertOrder,
        reInvertOrderData
    }
}