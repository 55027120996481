import dayjs from "dayjs"

export const productInfoList = [
    {
        productmodel: "MS4",
        productid: "589de4ba1652454fa4b47e7458044328",
        name: "小魔夹MS4",
    },
    {
        productmodel: "MS3",
        productid: "811103983bcffe227dc6dddec51f468e",
        name: "小魔夹MS3",
    },
    {
        productmodel: "M4S2",
        productid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
        name: "小魔夹MS2",
    },
    {
        productmodel: "M4S",
        productid: "d194bd2a29ba48d094648a38a4d5896f",
        name: "小魔夹M4S",
    },
    {
        productmodel: "MGS4G3",
        productid: "6951e86506725baaf4dfcdf9aa015395",
        name: "三代小魔柜",
    },
    {
        productmodel: "MGS4G2",
        productid: "9775f7dd993646eaa4977537b0735e8f",
        name: "二代小魔柜",
    },
    {
        productmodel: "MGS4G",
        productid: "bb1effce377d4ef1893d7e3656eddcc6",
        name: "一代小魔柜",
    },
    {
        productmodel: "UMHD",
        productid: "a2ea3faa86274d3f966ff30aad68b208",
        name: "雨伞架",
    },
    {
        productmodel: "UM01",
        productid: "d965476559f0428483b03f08941cda39",
        name: "共享雨伞",
    },
    {
        productmodel: "MXS",
        productid: "d4b8d2d2df5742ecbec9398d5409dba9",
        name: "消毒柜MXS",
    },
    {
        productmodel: "mzg",
        productid: "fd76cb9e939a4e6f92b4a867f0c19d2f",
        name: "立式箱柜",
    },
    {
        productmodel: "CV01",
        productid: "0a9edc30d4924f989fd9207bf62e6d23",
        name: "便民柜",
    },
    
    {
        productmodel: "MGS",
        productid: "2ff50a205d7f4ade895b6595cdc038c7",
        name: "NB小魔柜",
    },
    {
        productmodel: "MRS",
        productid: "91a9efbc08af485f9be6ff774d24f983",
        name: "小魔夹MRS",
    }
]

// export const productIdList = [
//     "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
//     "811103983bcffe227dc6dddec51f468e",
//     "d194bd2a29ba48d094648a38a4d5896f",
//     "6951e86506725baaf4dfcdf9aa015395",
//     "9775f7dd993646eaa4977537b0735e8f",
//     "bb1effce377d4ef1893d7e3656eddcc6",
//     "a2ea3faa86274d3f966ff30aad68b208",
//     "d965476559f0428483b03f08941cda39",
//     "d4b8d2d2df5742ecbec9398d5409dba9",
//     "fd76cb9e939a4e6f92b4a867f0c19d2f",
//     "0a9edc30d4924f989fd9207bf62e6d23",
//     "589de4ba1652454fa4b47e7458044328"
// ]

export const userRoleList = [
    {
        gainerrole: "investor",
        name: "会员",
    },
    {
        gainerrole: "agent",
        name: "超级会员",
    },
    {
        gainerrole: "superior",
        name: "会员推广提成",
    },
    {
        gainerrole: "presuper",
        name: "会员推广提成",
    },
    {
        gainerrole: "partner",
        name: "城市合伙人",
    },
    {
        gainerrole: "platform",
        name: "平台",
    },
    {
        gainerrole: "partner_expand",
        name: "城市合伙人",
    },
    
]

export const cityInfoList = [
    {
        cityareacode: "4301",
        districtareacode: "430121",
        name: "长沙市",
    },
    {
        cityareacode: "4302",
        districtareacode: "430121",
        name: "株洲市",
    }
    
]
export const areacodeInfoList = [
    {
        careacode: "4301",
        districtareacode: "430121",
        name: "长沙市",
    },
    {
        areacode: "4302",
        districtareacode: "430121",
        name: "株洲市",
    }
    
]
/**
 * 广告信息
 */
export const adInfoList = [
    {
        adType: "0",
        name: "招商加盟",
    },
    {
        adType: "1",
        name: "云契美食",
    },
    {
        adType: "2",
        name: "联通4G",
    },
    {
        adType: "3",
        name: "未知待定",
    },
]

export type ProductModel =  "MS3" | "MGS4G3" | 'mzg' | "mxs" | "UM01" | "UMHD" | "MGS4G2" | "M4S2" | "CV01"
export interface ProductInfo {
    productmodel: ProductModel,
    productid: string,
    name: string,
}
export function getShareProductInfo(models?: ProductModel[]): ProductInfo[] {
    return productInfoList.filter(v => (models || ["MS3",'M4S2',  "UM01"]).findIndex(i => v.productmodel == i) > -1) as ProductInfo[]
}
export function getPanelProductInfo(models?: ProductModel[]): ProductInfo[] {
    return productInfoList.filter(v => (models || ["MGS4G3","MGS4G2","UMHD", "mxs"]).findIndex(i => v.productmodel == i) > -1) as ProductInfo[]
}

export function getCabinetProductInfo(models?: ProductModel[]): ProductInfo[] {
    return productInfoList.filter(v => (models || ["MGS4G3","MGS4G2"]).findIndex(i => v.productmodel == i) > -1) as ProductInfo[]
}

export function getProductInfoList() {
    return productInfoList;
}

export function getProductIdList() {
    var productIdList = new Array();
    productInfoList.forEach(item=>{
        productIdList.push(item.productid)
    })
    return productIdList;
}

export function getCityInfoList(){
    return cityInfoList;
}

export function getAdInfoList(){
    return adInfoList;
}

export function getUserRoleList(){
    return userRoleList;
}
interface getTimeArrParams {
    start?: string
    end?: string
    str: string
}
interface getTimeArrParams {
    month?: string
    str: string
}
interface GetTimeArr {
    [key: string]: string
}
export function getTimeArr({ start, end, month, str }: getTimeArrParams): GetTimeArr[] { //str 时间的key
    const a: GetTimeArr[] = []
    if (month) { // 只传月份的
        const days = dayjs(month).daysInMonth()
        for (let i = 1; i <= days; i++) {
            a.push({ [str]: month + `-${i < 10 ? '0' + i : i}` })  // 1 变01
        }
    } else { // 起止时间的
        let _start = dayjs(start).valueOf()
        const _end = dayjs(end).valueOf()
        while (_start >= _end) {
            a.push({ [str]: dayjs(_start).format("YYYY-MM-DD") })
            _start = dayjs(_start).add(1, 'day').valueOf()
        }
    }
    return a
}
export function getRentalfee(startTime:any,endTimeany:any,isVip:boolean,isXmj:boolean){
    let money: number = 0;
    let start = new Date(startTime);
    let end = new Date(endTimeany);
    let time=parseInt(end.getTime()-start.getTime());
    let day = parseInt(time/(24*3600*1000))
    if(day>0){
        if(isVip||!isXmj){
            money+=day*3;
        }else{
            money+=day*6;
        }    
        if(parseInt(time%(24*3600*1000)/(6*3600*1000))>0){
            if(isVip||!isXmj){
                money+=3;
            }else{
                money+=6;
            }      
        }else{
            money+=3;
        }
        
    }else{
        if(parseInt(time/(6*3600*1000))>0){
            if(isVip||!isXmj){
                money+=3;
            }else{
                money+=6;
            }           
        }else{
            money+=3;
        }
    }
    return money;
}