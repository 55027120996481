import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface PointModel {
    "gmtCreate": string,
    "gmtModified": string,
    "oldValue": number,
    "newValue": number,
    "changeValue": number,
    "walletId": number,
    "billType": number,
    "remark": string,
    "outOrderId": string,
    "extendInfo": string
}

export async function getUserPointList(params: ListReq): Promise<ListRes<PointModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<PointModel>>(
        {
            url: `/userpointwalletbill/page`,
            params
        }, {
        prefix: "/shared"
    });
    res.rows = res.rows.map(v => {
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss")
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}