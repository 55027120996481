
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  onUnmounted,
  nextTick,
  readonly,
} from 'vue'
import topList from './components/topList.vue'
import map from './components/map/index.vue'
import cardData from './components/cardData.vue'
import { appStore } from '@/store/modules/app'
import selectTime from './components/selectTime.vue'
import vLine from './components/orderLine/index.vue'
import vActiveLine from './components/activeLine.vue'
import selectItem from './components/selectItem.vue'
import getActiveLineData from './getData/active'
import { emitter } from '@/utils/myMitt'
import {
  articleDistributeOfLocationAndType,
  ArticleDistributeOfLocationAndType,
} from '@/api/Index/platfrom'
import { deepCopy } from '@/utils'
import { getShareProductInfo, ProductInfo } from '@/api/util'
import { useRoute } from 'vue-router'
// import { phoneholderDistribution } from '@/api/Analy'
let reViewTimer: any = null
const selectType = [
  {
    name: '小魔夹M4S',
    value: 'd194bd2a29ba48d094648a38a4d5896f',
  },
  {
    name: '小魔夹MS2',
    value: '56c62a11a6a14d6eb7ab29fbf9f2f8b4',
  },
  {
    name: '雨伞',
    value: 'd965476559f0428483b03f08941cda39',
  },
]
export default defineComponent({
  name: 'Name',
  components: {
    'v-top-list': topList,
    'v-map': map,
    'v-card-data': cardData,
    selectTime,
    selectItem,
    vLine,
    vActiveLine,
  },
  // inject: ["reload"],
  setup() {
    
    const sidebar = computed(() => appStore.sidebar)
    const mapSelectItem = ref<ProductInfo>(deepCopy(getShareProductInfo()[1]))
    const orderSelectItem = ref<ProductInfo>(deepCopy(getShareProductInfo()[0]))
    const tboxHeight = ref('600px')
    const pieHeight = ref('150px')
    // const getMapHeight = computed(() =>
    //   appStore.isMobile ? "400px" : tboxHeight.value
    // );
    const activeLineRef = ref<any>('')
    const orderLineRef = ref<any>('')
    const deviceMapRef = ref<any>('')
    const route = useRoute()
    const { lineData } = getActiveLineData()
    const activeListTimeChange = (time: string) => {
      activeLineRef.value.reData(time)
    }
    const orderListTimeChange = (date?: string) => {
      orderLineRef.value.reData({
        date,
        productid: orderSelectItem.value.productid,
      })
    }

    const listData = ref({
      total: 0,
      list: [] as ArticleDistributeOfLocationAndType['list'],
    })

    const reMapData = async () => {
      const res = await articleDistributeOfLocationAndType({
        productid: mapSelectItem.value.productid,
      })
      listData.value = res
      deviceMapRef.value.reView()
    }
    const reView = async () => {
      clearTimeout(reViewTimer)
      reViewTimer = setTimeout(() => {
        if (route.path !== '/home') return
        deviceMapRef.value.reView()
        activeLineRef.value.reView()
        orderLineRef.value.reView()
      }, 1000)
    }
    const mapSelectChange = (e: any) => {
      mapSelectItem.value = e
      reMapData()
    }
    const orderSelectChange = (e: any) => {
      orderSelectItem.value = e
      orderListTimeChange()
    }
    onMounted(() => {
      emitter.on('resize', reView)
      orderListTimeChange()
      reMapData()
    })
    onUnmounted(() => {
      emitter.off('resize', reView)
    })
    return {
      sidebar,
      tboxHeight,
      pieHeight,
      lineData,
      activeListTimeChange,
      activeLineRef,
      orderListTimeChange,
      orderLineRef,
      deviceMapRef,
      listData,
      mapSelectItem,
      mapSelectChange,
      orderSelectItem,
      orderSelectChange,
    }
  },
})
