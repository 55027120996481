<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`扫码${type=='IN'?'入库':'出口'}`"
    @closed="handleClose"
    align="center"
    v-model="visible"
    width="750px"
  >
    <div class="dialog-warp">
      <header>
        <el-input
          :disabled="upLoading"
          @input="handleInput"
          class="input"
          placeholder="请选中，输入框后扫码"
          v-model="inputData"
        ></el-input>
        <el-input class="input" placeholder="请输入sn" v-model="searchData">
          <template #append>
            <el-button icon="el-icon-search"></el-button>
          </template>
        </el-input>
      </header>
      <el-table :data="showList" height="400px" style="width: 80%">
        <el-table-column label="SN" min-width="30">
          <template #default="scope">{{scope.row}}</template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="30">
          <template #default="scope">
            <el-button
              :disabled="parseInt(scope.row.status)<2"
              @click="handleRemove(scope.$index)"
              size="mini"
              type="primary"
            >取消</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script lang = "ts" >
import { ElMessage } from "element-plus";
import { computed, defineComponent, ref } from "vue";
import {
  warehouseManagementInout,
  warehouseManagementUpdate,
} from "@/api/ERP/warehouseManagement";
import { userStore } from "@/store/modules/user";
export default defineComponent({
  name: "v-dialog",
  setup(props, { emit }) {
    const visible = ref(false);
    const list = ref<string[]>([]);
    const type = ref<"IN" | "OUT">("IN");
    const upLoading = ref(false);
    const inputData = ref("");
    const searchData = ref("");
    let orderType: "old" | "new" = "old";
    let timer: any;
    const handleClose = () => {
      visible.value = false;
      emit("success");
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const showList = computed(() => {
      return list.value.filter((v) => v.indexOf(searchData.value) > -1);
    });
    const open = async (_type: "IN" | "OUT", _orderType: "old" | "new") => {
      visible.value = true;
      type.value = _type;
      orderType = _orderType;
    };
    const handleRemove = async (index: number) => {
      try {
        upLoading.value = true;
        const res = await warehouseManagementUpdate({
          status: type.value == "IN" ? 0 : 1, // 撤销操作
          sn: list.value[index],
        });
        ElMessage.success(res.message);
        list.value.splice(index, 1);
      } finally {
        upLoading.value = false;
      }
    };

    const upData = async (sn: string) => {
      try {
        upLoading.value = true;
        if (!userStore.userInfo.token) return;
        const res = await warehouseManagementInout({
          status: type.value == "IN" ? 1 : 2,
          sn,
          token: userStore.userInfo.token,
          type: orderType,
        });
        ElMessage.success(res.message);
        list.value.push(sn);
      } finally {
        upLoading.value = false;
      }
    };

    // https://xmj.smartline.com.cn/xmj/AAAI966
    const handleInput = async (e: any) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(async () => {
        if (e && e.length == 40) {
          const sn = e.split("/xmj/")[1];
          if (sn) {
            inputData.value = "";
            if (
              list.value.findIndex((v) => v.toUpperCase() == sn.toUpperCase()) >
              -1
            ) {
              return ElMessage.error("请勿重复添加");
            } else {
              await upData(sn);
            }
          }
        }
      }, 200);
    };
    return {
      visible,
      handleClose,
      open,
      upLoading,
      type,
      list,
      handleRemove,
      inputData,
      showList,
      searchData,
      handleInput,
    };
  },
});
</script>
<style lang = "scss" scoped >
.dialog-warp {
  header {
    .el-input {
      width: 250px;
      margin-right: 24px;
    }
  }
}
</style>