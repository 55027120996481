import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes } from "../../types/requestBaseType"



export interface userModel {
    userid: string,
    name: string,
    account: string,
    totalwallet: string,
    fundfrozen:string,
    totalchange: string,
    totalwithdraw: string,
    totalcost: string,
}

/**
 * 获取用户角色统计
 */
 export async function getUserStatistics(parmas: RequestReq): Promise<ListRes<userModel>> {
    const res = await defHttp.request<ListRes<userModel>>(
        {
            url: `/finance/getUserStatistics`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}