<template>
  <div class="container">
    <!-- <header> -->
      <!-- <el-button icon="el-icon-plus" @click="handleAdd" type="primary" v-if="roleShowPermission(['ROLE_SALESMAN'])">创建投资订单</el-button> -->
     <!-- <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input> -->
      <!-- <el-select @change="handelChange" class="header-select" clearable placeholder="投资订单状态" v-model="mixin_router_search.status">
        <el-option label="已下单" value="0"></el-option>
        <el-option label="已付款" value="1"></el-option>
        <el-option label="已出仓" value="2"></el-option>
        <el-option label="已开始投放" value="3"></el-option>
        <el-option label="已寄出" value="4"></el-option>
      </el-select> -->
      <!-- <el-select @change="handelChange" clearable placeholder="设备类型" v-model="mixin_router_search.articleproductid">
        <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of sheareProductInfo"></el-option>
      </el-select> -->
      <!-- <el-select @change="handelChange" class="header-el-selector" clearable placeholder="投放类型" v-model="mixin_router_search.launchmode">
        <el-option label="自投放" value="SELF"></el-option>
        <el-option label="平台投放" value="PLATFORM"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否为测试订单" v-model="mixin_router_search.fortest">
        <el-option :value="true" label="测试订单"></el-option>
        <el-option :value="false" label="正式订单"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="投资者类型" v-model="mixin_router_search.investortype">
        <el-option label="用户" value="user"></el-option>
        <el-option label="平台" value="platform"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="投资方式" v-model="mixin_router_search.investmode">
        <el-option label="线上投资" value="online"></el-option>
        <el-option label="线下投资" value="offline"></el-option>
      </el-select> -->
  

      <!-- <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="创建时间"
        range-separator="至"
        start-placeholder="创建时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">新增投资订单</el-button>
    </header> -->

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <!-- <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item> -->
            <!-- <el-form-item label="投资人ID" v-if="scope.row.investoruserid">
              <my-click-data-to
                :data="scope.row.investoruserid"
                :params="[{search_userid:scope.row.investoruserid},{type:'investor'}]"
                url="/user/role"
              ></my-click-data-to>
              <my-copy :data="scope.row.investoruserid" />
            </el-form-item> -->
            <el-form-item label="投资订单号">
              <span>{{ scope.row.orderid }}</span>
              <my-copy :data="scope.row.orderid"></my-copy>
            </el-form-item>
            <div v-if="scope.row.launchmode=='SELF'">
              <el-form-item label="联系人">
                <span>{{ scope.row.mailingnumber }}</span>
              </el-form-item>
              <el-form-item label="联系人电话">
                <span>{{ scope.row.mailingnumber }}</span>
              </el-form-item>
              <el-form-item label="收件地址">
                <span>{{ scope.row.mailingaddress }}</span>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="投资人" min-width="40">
        <template #default="scope">
           <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          {{scope.row.investorname}}{{scope.row.account}}</template>
      </el-table-column>
      <el-table-column label="订单状态" min-width="24">
        <template #default="scope">
         
          {{getStatusName[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column label="物品类型" min-width="28">
        <template #default="scope">{{scope.row.articleproductname}}</template>
      </el-table-column>
      <el-table-column label="投资者" min-width="24">
        <template #default="scope">{{scope.row.investortype=="platform"?"平台":"用户"}}</template>
      </el-table-column>
      <el-table-column label="投资方式" min-width="24">
        <template #default="scope">{{scope.row.investmode=="online"?"线上投资":"线下投资"}}</template>
      </el-table-column>
      <el-table-column label="数量" min-width="20">
        <template #default="scope">{{scope.row.articlenum}}</template>
      </el-table-column>
      <el-table-column align="left" label="单价" min-width="20">
        <template #default="scope">¥ {{scope.row.singleamount}}</template>
      </el-table-column>
      <el-table-column align="left" label="订单总额" min-width="24">
        <template #default="scope">¥ {{scope.row.singleamount*scope.row.articlenum}}</template>
      </el-table-column>
      <el-table-column label="下单时间" min-width="32" prop="creationdate"></el-table-column>
      <!-- <el-table-column align="left" label="操作" min-width="90">
        <template #header>
          <el-button @click="handleExport(scope)" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button @click="handleEdit(scope.$index,scope.row)" size="mini" v-if="scope.row.status=='0'">编辑</el-button>
          <el-button @click="handleRemove(scope.$index,scope.row.orderid)" size="mini" type="danger" v-show="scope.row.status=='0'">删除</el-button>
          <el-button
            @click="handleConfirm(scope.$index,scope.row)"
            size="mini"
            type="success"
            v-if="roleShowPermission(['ROLE_FINANCE','ROLE_ADMIN_DVE'])"
            v-show="scope.row.investmode=='offline'&&scope.row.status=='0'"
          >确认收款</el-button>
          <el-button
            @click="handleCreateBillSales(scope.$index,scope.row)"
            size="mini"
            type="primary"
            v-show="scope.row.status=='1'||scope.row.status=='2'"
          >创建出货单</el-button>
          <el-button
            @click="toPath_RouterSearchMixin('/investment/shipment',[{search_investmentorderid:scope.row.orderid}])"
            size="mini"
            v-show="scope.row.status!='0'"
          >查看出货单</el-button>
          <el-button
            @click="handleConfirmdelivery(scope.$index,scope.row.orderid)"
            size="mini"
            type="success"
            v-if="scope.row.status=='2'"
          >确认交付</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-add-order-dialog-new @success="orderDialogSueccess" ref="addOrderDialogRefNew"></v-add-order-dialog-new>
    <v-add-order-dialog @success="orderDialogSueccess" ref="addOrderDialogRef"></v-add-order-dialog>
    <v-order-confrim-dialog @success="orderConfirmDialogSueccess" ref="orderConfrimDialogRef"></v-order-confrim-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import {
  investmentorderList,
  InvestmentorderListRes,
  investmentorderRemove,
  investmentorderCreateshipment,
  confirmdelivery,
} from '@/api/Investment/Order'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { pickerOptions } from './elementConfig'
import { getStatusName, getArticletypeName } from './editData'
import orderDialog from './components/orderDialog.vue'
import orderDialogNew from './components/orderDialogNew.vue'
import orderConfrimDialog from './components/orderConfrimDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import myExport from '@/utils/exprotExcel'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'investment-order',
  components: {
    'v-add-order-dialog': orderDialog,
    'v-add-order-dialog-new': orderDialogNew,
    'v-order-confrim-dialog': orderConfrimDialog,
  },
  setup() {
    const mixin_router_search = ref({
      fortest: false, //是否用于测试，如：true 是 false 否 
      articleproductid: '56c62a11a6a14d6eb7ab29fbf9f2f8b4', //设备类型   
      investoruserid: userStore.userInfo.userid, // 投资人用户编号  
    })
    const mixin_select_search = ref({
      search: {       
        //investoruserid: userStore.userInfo.userid, // 投资人用户编号       
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
       // investoruserid: '投资人ID', // 投资人用户编号
      },
    })
    const searchSelect = ref({})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<InvestmentorderListRes[]>([])
    const btnRemoveLoading = ref(false)
    const btnConfirmdeliveryLoading = ref(false)
    const addOrderDialogRefNew = ref<any>()
    const addOrderDialogRef = ref<any>()
    const orderConfrimDialogRef = ref<any>()
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }
    type SearchData = typeof searchData

    type BaseReData = {
      size: number
      current: number
      entity?: toOptional<SearchData>
    }
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: BaseReData = {
          current: page.value,
          size: limit.value,
        }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.careateBN) {
            rq.creationdateBegin = rq.careateBN[0]
            rq.creationdateEnd = rq.careateBN[1]
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await investmentorderList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addOrderDialogRefNew.value.open()
    }
    const handleEdit = (index: number, row: any) => {
      addOrderDialogRef.value.open(row, index)
    }
    const orderDialogSueccess = (data: any) => {
      if (data.isEdit) {
        list.value.splice(data.index, 1, data.data)
      } else {
        //list.value.push(data.data)
        page.value = 1
        mixinReRouter()
      }
    }
    const handleRemove = async (index: number, orderid: string) => {
      try {
        btnRemoveLoading.value = true
        await investmentorderRemove({
          orderid,
        })
        list.value.splice(index, 1)
      } finally {
        btnRemoveLoading.value = false
      }
    }

    const handleConfirmdelivery = async (index: number, orderid: string) => {
      try {
        btnConfirmdeliveryLoading.value = true
        const res = await confirmdelivery({
          orderid,
          salesmanuserid: userStore.info.userid,
        })
        list.value[index].status = '3'
        ElMessage.success(res)
      } finally {
        btnConfirmdeliveryLoading.value = false
      }
    }

    const handleConfirm = async (index: number, row: any) => {
      row.index = index
      orderConfrimDialogRef.value.open(row)
    }
    const orderConfirmDialogSueccess = (index: number) => {
      list.value[index].status = '1'
    }
    const handleCreateBillSales = async (
      index: number,
      { articlenum, orderid }: any
    ) => {
      try {
        const input = await ElMessageBox.prompt('请输入出货数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: `请输入 1-${articlenum} `,
          inputType: 'number',
        })
        console.log('input :>> ', input)
        const _value = parseInt(input.value)
        if (input.value > articlenum || _value < 1)
          return ElMessage.error(`请输入 1-${articlenum}`)
        if (!userStore.info.userid) return userStore.loginOut()
        const res = await investmentorderCreateshipment({
          orderid,
          quantity: _value,
          shipperuserid: userStore.info.userid,
        })
        console.log('res :>> ', res)
        ElMessage.success(res)
      } catch (error) {
        if (error == 'cancel') return
        if (error == 'colse') return
        ElMessage.error(error)
      }
    }
    const handleExport = () => {
      const excel = new myExport(`小魔夹投资订单列表`)
      const _list = list.value.map((v) => {
        const _data = {
          // 姓名: v.investorname,
          投资人ID: v.investoruserid,
          时间: v.creationdate,
          数量: v.articlenum,
          // 分成比: v.investorproportion + '%',
          单价: v.singleamount,
          总计: v.articlenum * v.singleamount,
          状态: getStatusName[v.status],
          销售员: v.salesmanuserid,
        }
        return _data
      })
      excel.addWorksheet({ headers: ['小魔夹投资订单'], list: _list })
      excel.export()
    }
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,
      pickerOptions,
      onPageChange,
      handelChange,
      handleLimitChange,
      searchSelect,
      list,
      loading,
      getStatusName,
      getArticletypeName,
      handleAdd,
      addOrderDialogRefNew,
      addOrderDialogRef,
      orderConfrimDialogRef,
      handleEdit,
      orderDialogSueccess,
      handleRemove,
      btnRemoveLoading,
      handleConfirm,
      roleShowPermission,
      orderConfirmDialogSueccess,
      page,
      limit,
      total,
      handleCreateBillSales,
      toPath_RouterSearchMixin,
      handleConfirmdelivery,
      sheareProductInfo,
      handleExport,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>