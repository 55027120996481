
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import addDialog from './components/addDialog.vue'
import showPlacePicDialog from '@/components/device/showPlacePicDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";

import {
  getPlaceList,
  selectPlaceList,
  upDatePlace,
  PlaceListRes,
  removePlace,
  exportPlaceDeviceList,
} from '@/api/Share/Place'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import mySelectCity from '@/components/cityList/index.vue'
import { reduce } from 'lodash'
export default defineComponent({
  name: 'v-place',
  components: {
    'v-add-dialog': addDialog,
    mySelectCity,
    showPlacePicDialog,
    exportDialog,
    
  },
  setup() {
    const loading = ref(false)
    const btnEnabledLoaidng = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const addDialogRef = ref<any>()
    const showPlacePicDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    
    const list = ref<PlaceListRes[] | any>([])
    const mixin_router_search = ref({
      enabled: '',
      operationId:'',
      cityareacode:'',
      districtareacode:'',
      placeType:''
    })
    const searchSelect = ref({
      search: 'name',
    })
    const mixin_select_search = ref({
      search: {
        name: '',
        placeid: '',
        manageruserName:'',
        operatorName:''
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '名称',
        placeid: '场地ID',
        manageruserName:'维修员姓名',
        operatorName:'运营员姓名'
      },
    })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        // const res = await getPlaceList(rqData)
        const res = await selectPlaceList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const cityCodeChange = (code: string) => {
      if(code&&code.length==4){
        mixin_router_search.value.cityareacode=code;
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }else if(code&&code.length==6){
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=code
        page.value = 1
        mixinReRouter()
      }else{
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }
      
    }
    const handleEnabled = async (index: number, row: any) => {
      try {
        btnEnabledLoaidng.value = true
        const res = await upDatePlace({
          enabled: !row.enabled,
          placeid: row.placeid,
        })
        // list.value[index] = res.record
        // console.log('list.value[index] :>> ', list.value[index])
        ElMessage.success(res.message)
      } finally {
        btnEnabledLoaidng.value = false
      }
      page.value = 1
      mixinReRouter()
    }
    const handleRemove = async (index: number, placeid: string) => {
      try {
        await ElMessageBox({
          type:"warning",
          title:"请确认",
        })
        btnRemoveLoaidng.value = true
        const res = await removePlace({
          placeid,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    const showPlacePic = (item:any) => {
      showPlacePicDialogRef.value.open(item);
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }
    const addSuccess = (data: { index: number; data: PlaceListRes }) => {
      if (typeof data.index == 'number' && data.index > -1) {
        list.value[data.index] = data.data
      } else {
        list.value.push(data.data)
      }
    }
    const editSuccess = (msg:string) =>{
      console.log('修改成功')
      mixinReRouter();
    }
    const getCellClass =(obj:any) =>{
      console.log('出现列=',obj)
      if(obj.columnIndex==1){
      //   console.log('返回设定')
        return "padding:6px 0 0 0;"
        
        }else{
          return ""
        }
    }
    const handleExport = () =>{
      exportDialogRef.value.open('场所表')
    }

    const exportFile = async(name:string) =>{
      try {
        // let rqData: any = { current: page.value, size: limit.value }
        
        // if (rq && JSON.stringify(rq) !== '{}') {
        //   rqData = { ...rqData, entity: rq }
        // }

        let searchData: any = {}
        let rqData: any = {current: page.value, size: limit.value,fileName:'place'}
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          rqData.operationId=userStore.getCurrentRole.operationId
        }
        let entity: any = {enabled:mixin_router_search.value.enabled}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        delete searchData.enabled
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportPlaceDeviceList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      cityCodeChange,
      handleEnabled,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
      handleRemove,
      addDialogRef,
      showPlacePicDialogRef,
      exportDialogRef,
      handleAdd,
      addSuccess,
      handleEidt,
      changSearch,
      showPlacePic,
      editSuccess,
      getCellClass,
      handleExport,
      exportFile,
    }
  },
})
