<template>
  <div :style="{backgroundPosition:!sidebar.open?'-310px -71px':'0 -71px'}" class="amdinContainer">
    <el-row :gutter="8" class="top">
      <el-col :lg="3" :sm="6" :xs="24">
        <div class="box lbox">
          <div class="list">
            <div class="title">
              <div>
                <img alt src="../../../assets/img/index/ltitle.png" />
              </div>
              <!-- <div>小魔夹</div> -->
              <select-item :selectItem="mapSelectItem" @change="mapSelectChange" key="mapTypeSelect"></select-item>
              <div>
                <img alt src="../../../assets/img/index/rtitle.png" />
              </div>
            </div>
            <v-top-list :listData="listData"></v-top-list>
          </div>
        </div>
      </el-col>
      <el-col :lg="11" :sm="18" :xs="24">
        <div :style="{height:tboxHeight}" class="box cbox">
          <!-- <div class="map-title">长沙小魔夹分布</div> -->
          <v-map
            :height="tboxHeight"
            :mapData="listData.list"
            :name="mapSelectItem.name"
            :total="listData.total"
            class="map"
            ref="deviceMapRef"
          ></v-map>
        </div>
      </el-col>
      <el-col :lg="10" :sm="24" :xs="24">
        <div class="box rbox">
          <v-card-data :pieHeight="pieHeight" :tboxHeight="tboxHeight" class="card"></v-card-data>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="8" class="bottom">
      <el-col :lg="14" :sm="24" :xs="24">
        <div class="box blbox">
          <div class="title">
            <img alt src="../../../assets/img/index/ltitle.png" />
            <select-item @change="orderSelectChange" isBig="isBig" key="orderTypeSelect" type="订单和收益"></select-item>
            <!-- &ensp;订单和收益&ensp; -->
            <img alt src="../../../assets/img/index/rtitle.png" />
          </div>
          <div class="select-time-warp">
            <selectTime @timeChange="orderListTimeChange"></selectTime>
          </div>
          <v-line ref="orderLineRef"></v-line>
        </div>
      </el-col>
      <el-col :lg="10" :sm="24" :xs="24">
        <div class="box brbox">
          <div class="title">
            <img alt src="../../../assets/img/index/ltitle.png" />&ensp;用户注册和活跃&ensp;
            <img alt src="../../../assets/img/index/rtitle.png" />
          </div>
          <div class="select-time-warp">
            <selectTime @timeChange="activeListTimeChange"></selectTime>
          </div>
          <v-active-line ref="activeLineRef"></v-active-line>
        </div>
      </el-col>
    </el-row>
    <!-- <footer>
      <div class="imgWarp">
        <img src="../../../assets/img/index/footer.png" alt />
      </div>
    </footer>-->
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  onUnmounted,
  nextTick,
  readonly,
} from 'vue'
import topList from './components/topList.vue'
import map from './components/map/index.vue'
import cardData from './components/cardData.vue'
import { appStore } from '@/store/modules/app'
import selectTime from './components/selectTime.vue'
import vLine from './components/orderLine/index.vue'
import vActiveLine from './components/activeLine.vue'
import selectItem from './components/selectItem.vue'
import getActiveLineData from './getData/active'
import { emitter } from '@/utils/myMitt'
import {
  articleDistributeOfLocationAndType,
  ArticleDistributeOfLocationAndType,
} from '@/api/Index/platfrom'
import { deepCopy } from '@/utils'
import { getShareProductInfo, ProductInfo } from '@/api/util'
import { useRoute } from 'vue-router'
// import { phoneholderDistribution } from '@/api/Analy'
let reViewTimer: any = null
const selectType = [
  {
    name: '小魔夹M4S',
    value: 'd194bd2a29ba48d094648a38a4d5896f',
  },
  {
    name: '小魔夹MS2',
    value: '56c62a11a6a14d6eb7ab29fbf9f2f8b4',
  },
  {
    name: '雨伞',
    value: 'd965476559f0428483b03f08941cda39',
  },
]
export default defineComponent({
  name: 'Name',
  components: {
    'v-top-list': topList,
    'v-map': map,
    'v-card-data': cardData,
    selectTime,
    selectItem,
    vLine,
    vActiveLine,
  },
  // inject: ["reload"],
  setup() {
    
    const sidebar = computed(() => appStore.sidebar)
    const mapSelectItem = ref<ProductInfo>(deepCopy(getShareProductInfo()[1]))
    const orderSelectItem = ref<ProductInfo>(deepCopy(getShareProductInfo()[0]))
    const tboxHeight = ref('600px')
    const pieHeight = ref('150px')
    // const getMapHeight = computed(() =>
    //   appStore.isMobile ? "400px" : tboxHeight.value
    // );
    const activeLineRef = ref<any>('')
    const orderLineRef = ref<any>('')
    const deviceMapRef = ref<any>('')
    const route = useRoute()
    const { lineData } = getActiveLineData()
    const activeListTimeChange = (time: string) => {
      activeLineRef.value.reData(time)
    }
    const orderListTimeChange = (date?: string) => {
      orderLineRef.value.reData({
        date,
        productid: orderSelectItem.value.productid,
      })
    }

    const listData = ref({
      total: 0,
      list: [] as ArticleDistributeOfLocationAndType['list'],
    })

    const reMapData = async () => {
      const res = await articleDistributeOfLocationAndType({
        productid: mapSelectItem.value.productid,
      })
      listData.value = res
      deviceMapRef.value.reView()
    }
    const reView = async () => {
      clearTimeout(reViewTimer)
      reViewTimer = setTimeout(() => {
        if (route.path !== '/home') return
        deviceMapRef.value.reView()
        activeLineRef.value.reView()
        orderLineRef.value.reView()
      }, 1000)
    }
    const mapSelectChange = (e: any) => {
      mapSelectItem.value = e
      reMapData()
    }
    const orderSelectChange = (e: any) => {
      orderSelectItem.value = e
      orderListTimeChange()
    }
    onMounted(() => {
      emitter.on('resize', reView)
      orderListTimeChange()
      reMapData()
    })
    onUnmounted(() => {
      emitter.off('resize', reView)
    })
    return {
      sidebar,
      tboxHeight,
      pieHeight,
      lineData,
      activeListTimeChange,
      activeLineRef,
      orderListTimeChange,
      orderLineRef,
      deviceMapRef,
      listData,
      mapSelectItem,
      mapSelectChange,
      orderSelectItem,
      orderSelectChange,
    }
  },
})
</script>
<style lang="scss" scoped>
.amdinContainer {
  // padding: 129px 0 41px;
  // background: #0a163a;
  // font-size: 14px;
  background: url('../../../assets/img/index/homebg.png');
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   width: 0.026rem;
  //   height: 0.026rem;
  //   background-color: rgb(53, 73, 126);
  // }
  // &::-webkit-scrollbar-thumb {
  //   /*滚动条里面小方块*/
  //   border-radius: 0.013rem;
  //   background-color: #008080;
  //   // background-color: #002634;
  //   cursor: pointer;
  //   &:hover {
  //     background-color: #00f4f4;
  //   }
  // }
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  footer {
    position: fixed;
    bottom: 3px;
    .imgWarp {
      width: 100%;
      height: 0;
      padding-bottom: 2%;
      img {
        width: 100%;
      }
    }
  }
  .top {
    padding: 8px;
    .box {
    }
    .lbox {
      height: 100%;
      height: 600px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */

      background: url('../../../assets/img/index/lboxbg.png');
      background-size: 100% 100%;
      .list {
        padding: 24px 12px;
        box-sizing: border-box;
        height: 100%;
        .title {
          color: #00f4f4;
          font-size: 15px;
          height: 36px;
          line-height: 36px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 18px;
        }
      }
    }
    .cbox {
      background: url('../../../assets/img/index/cboxbg.png');
      background-size: 100% 100%;
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      .map {
        box-sizing: content-box;
        width: 100%;
      }
      .map-title {
        z-index: 2;
        text-align: center;
        position: absolute;
        top: 20px;
        left: 20px;
        color: #00e6e6;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .bottom {
    // width: 100%;
    padding: 4px;
    .box {
      height: 260px;
      width: 100%;
    }
    .blbox,
    .brbox {
      background: url('../../../assets/img/index/blboxbg.png');
      background-size: 100% 100%;
      // width: 100%;
      box-sizing: content-box;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding-bottom: 18px;
      box-sizing: border-box;
      margin-bottom: 8px;
      .title {
        position: absolute;
        top: 36px;
        color: #00f4f4;
        z-index: 2;
      }
      .select-time-warp {
        position: absolute;
        top: 15px;
        right: 20px;
        color: #00f4f4;
        z-index: 1;
      }
    }
    .brbox {
      background: url('../../../assets/img/index/brboxbg.png');
      background-size: 100% 100%;
      .warp {
        padding: 8px;
      }
      padding-bottom: 18px;
    }
  }
}
</style>