<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select
            @change="changSearch('search')"
            placeholder="请选择"
            v-model="searchSelect.search"
          >
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select
        @change="handelChange"
        clearable
        placeholder="是否开启"
        v-model="mixin_router_search.enabled"
        class="header-el-selector"
      >
        <el-option :value="true" label="已开启"></el-option>
        <el-option :value="false" label="已关闭"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="场所类型"
        v-model="mixin_router_search.placeType"
        class="header-el-selector"
      >
        <el-option :value="1" label="固定店铺"></el-option>
        <el-option :value="2" label="移动车辆"></el-option>
      </el-select>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary">添加</el-button>
      <my-select-city :maxLevel="3" @cityCodeChange="cityCodeChange"></my-select-city>  
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <!-- <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item> -->
            <el-form-item label="营业时间">
              <span>{{ scope.row.businesshours }}</span>
            </el-form-item>
            <el-form-item label="描述">
              <span>{{ scope.row.description }}</span>
            </el-form-item>
            <el-form-item label="ID">
              <span>{{ scope.row.placeid }}</span>
              <my-copy :data="scope.row.placeid" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="center" label="图片" width="120" >
        <template #default="scope">
         
            <el-tooltip effect="light" content="点击可以进行场所图片更新" placement="top">
              <div @click="showPlacePic(scope.row)">
                 <el-image :src="scope.row.placeImage" v-if="scope.row.isHasPic" style="width: 80px;" fit="scale-down"> </el-image>
              <!-- <i class="el-icon-picture" style="font-size: 56px;" v-else></i> -->
                 <el-image :src="require('@/assets/img/shop.png')" style="width: 80px;" fit="scale-down" v-else></el-image>
              </div>  
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="名称" width="190" prop="name"></el-table-column>
      <el-table-column align="center" label="商家" width="90">
        <template #default="scope">
          {{scope.row.contactname}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="商家电话" width="140">
        <template #default="scope">
          {{scope.row.contactmobile}}
          <my-copy :data="scope.row.contactmobile" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="地址" width="280">
        <template #default="scope">
          {{scope.row.address}}
          <my-copy :data="scope.row.address" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" width="120">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.enabled">已开启</el-tag>
          <el-tag type="error" v-else>关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="绑定设备" width="90">
        <template #default="scope">
          {{scope.row.countsh}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="租借次数" width="90">
        <template #default="scope">
          {{scope.row.borrowSecond}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="归还次数" width="90">
        <template #default="scope">
          {{scope.row.returnSecond}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="运营团队" width="120">
        <template #default="scope">
          {{scope.row.operationName}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="维修员" width="160">
        <template #default="scope">
          {{scope.row.manageruserName?scope.row.manageruserName:'暂无'}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="运营专员" width="160">
        <template #default="scope">
         {{scope.row.operatorName?scope.row.operatorName:'暂无'}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="场所类型" width="120">
        <template #default="scope">
          <span v-if="scope.row.placeType=='1'">固定店铺</span>
          <span v-if="scope.row.placeType=='2'">移动车辆</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" width="160">
        <template #default="scope">
          {{ scope.row.creationdate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="260" fixed="right">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button
            :loading="btnEnabledLoaidng"
            :type="scope.row.enabled?'warning':'primary'"
            @click="handleEnabled(scope.$index,scope.row)"
            size="mini"
          >{{scope.row.enabled?"关闭":"开启"}}</el-button>
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.$index,scope.row.placeid)"
            size="mini"
            type="danger"
          >删除</el-button>
          <el-button @click="handleEidt(scope.$index,scope.row)" size="mini">编辑</el-button>
          <!-- <my-click-btn-to
            :params="[{search_placeid:scope.row.placeid}]"
            data="场地设备"
            url="/share/panel"
          ></my-click-btn-to> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
    <showPlacePicDialog  @success="editSuccess" ref="showPlacePicDialogRef"></showPlacePicDialog>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
    
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import addDialog from './components/addDialog.vue'
import showPlacePicDialog from '@/components/device/showPlacePicDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";

import {
  getPlaceList,
  selectPlaceList,
  upDatePlace,
  PlaceListRes,
  removePlace,
  exportPlaceDeviceList,
} from '@/api/Share/Place'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import mySelectCity from '@/components/cityList/index.vue'
import { reduce } from 'lodash'
export default defineComponent({
  name: 'v-place',
  components: {
    'v-add-dialog': addDialog,
    mySelectCity,
    showPlacePicDialog,
    exportDialog,
    
  },
  setup() {
    const loading = ref(false)
    const btnEnabledLoaidng = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const addDialogRef = ref<any>()
    const showPlacePicDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    
    const list = ref<PlaceListRes[] | any>([])
    const mixin_router_search = ref({
      enabled: '',
      operationId:'',
      cityareacode:'',
      districtareacode:'',
      placeType:''
    })
    const searchSelect = ref({
      search: 'name',
    })
    const mixin_select_search = ref({
      search: {
        name: '',
        placeid: '',
        manageruserName:'',
        operatorName:''
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '名称',
        placeid: '场地ID',
        manageruserName:'维修员姓名',
        operatorName:'运营员姓名'
      },
    })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        // const res = await getPlaceList(rqData)
        const res = await selectPlaceList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const cityCodeChange = (code: string) => {
      if(code&&code.length==4){
        mixin_router_search.value.cityareacode=code;
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }else if(code&&code.length==6){
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=code
        page.value = 1
        mixinReRouter()
      }else{
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }
      
    }
    const handleEnabled = async (index: number, row: any) => {
      try {
        btnEnabledLoaidng.value = true
        const res = await upDatePlace({
          enabled: !row.enabled,
          placeid: row.placeid,
        })
        // list.value[index] = res.record
        // console.log('list.value[index] :>> ', list.value[index])
        ElMessage.success(res.message)
      } finally {
        btnEnabledLoaidng.value = false
      }
      page.value = 1
      mixinReRouter()
    }
    const handleRemove = async (index: number, placeid: string) => {
      try {
        await ElMessageBox({
          type:"warning",
          title:"请确认",
        })
        btnRemoveLoaidng.value = true
        const res = await removePlace({
          placeid,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    const showPlacePic = (item:any) => {
      showPlacePicDialogRef.value.open(item);
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }
    const addSuccess = (data: { index: number; data: PlaceListRes }) => {
      if (typeof data.index == 'number' && data.index > -1) {
        list.value[data.index] = data.data
      } else {
        list.value.push(data.data)
      }
    }
    const editSuccess = (msg:string) =>{
      console.log('修改成功')
      mixinReRouter();
    }
    const getCellClass =(obj:any) =>{
      console.log('出现列=',obj)
      if(obj.columnIndex==1){
      //   console.log('返回设定')
        return "padding:6px 0 0 0;"
        
        }else{
          return ""
        }
    }
    const handleExport = () =>{
      exportDialogRef.value.open('场所表')
    }

    const exportFile = async(name:string) =>{
      try {
        // let rqData: any = { current: page.value, size: limit.value }
        
        // if (rq && JSON.stringify(rq) !== '{}') {
        //   rqData = { ...rqData, entity: rq }
        // }

        let searchData: any = {}
        let rqData: any = {current: page.value, size: limit.value,fileName:'place'}
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          rqData.operationId=userStore.getCurrentRole.operationId
        }
        let entity: any = {enabled:mixin_router_search.value.enabled}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        delete searchData.enabled
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportPlaceDeviceList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      cityCodeChange,
      handleEnabled,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
      handleRemove,
      addDialogRef,
      showPlacePicDialogRef,
      exportDialogRef,
      handleAdd,
      addSuccess,
      handleEidt,
      changSearch,
      showPlacePic,
      editSuccess,
      getCellClass,
      handleExport,
      exportFile,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
  .span-click {
    color: #409eff;
    cursor:pointer;
  }

  .has-pic{
    color: #67C23A
  }

  .no-pic{
    color: darkgrey;
  }

  /deep/.pic_cell{
    margin-top: -24px; 
    margin-bottom:-24px; 
    padding-top: -24px;
    padding-bottom: -24px;
  }
  .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
  }
}
</style>
