<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="账号类型" v-model="mixin_router_search.investortype">
        <el-option label="个人" value="individual"></el-option>
        <el-option label="公司" value="company"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="激活状态" v-model="mixin_router_search.allowlatter">
        <el-option label="已激活" value="1"></el-option>
        <el-option label="未激活" value="0"></el-option>
      </el-select>
      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="激活时间"
        range-separator="至"
        start-placeholder="激活时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
      <el-button @click="handleActivate" icon="el-icon-plus" type="primary" style="margin-left: 16px;">添加</el-button>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column label="姓名" min-width="30">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest=='1'" />
          <color-icon type="icon-zhengshiban" v-else />
          {{scope.row[`${mixin_router_search.type}name`]||scope.row.name}}
          <my-copy :data="scope.row[`${mixin_router_search.type}name`]||scope.row.name"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="账号" min-width="40">
        <template #default="scope">
          <my-copy :data="scope.row[`${mixin_router_search.type}account`]||scope.row.account"></my-copy>
          {{scope.row[`${mixin_router_search.type}account`]||scope.row.account}}
        </template>
      </el-table-column>
      <el-table-column label="ID" min-width="60">
        <template #default="scope">
          <my-copy :data="scope.row[`${mixin_router_search.type}userid`]||scope.row.userid"></my-copy>
          {{scope.row[`${mixin_router_search.type}userid`]||scope.row.userid}}
        </template>
      </el-table-column>
      <el-table-column label="激活状态" align="center" min-width="28">
        <template #default="scope">
          <el-switch
            v-model="scope.row.allowlatter"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
            active-value="1"
            inactive-value="0"
            disabled>
          </el-switch>
        </template>
      </el-table-column>
       <el-table-column label="账号类型" align="center" min-width="28">
        <template #default="scope">
         {{ scope.row.investortype=='individual'?'个人':'公司'}}
        </template>
      </el-table-column>
      <el-table-column label="激活时间" align="center" min-width="50" prop="creationtime"></el-table-column>
      <el-table-column label="更新时间" align="center" min-width="50" prop="modificationtime"></el-table-column>
      <el-table-column align="center" label="操作" min-width="60">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button  @click="handleRoleRelation(scope.row.investoruserid,scope.row[`${mixin_router_search.type}account`]||scope.row.account,scope.row[`${mixin_router_search.type}name`]||scope.row.name)" size="mini" type="info">上下级关系</el-button>
           <el-button @click="handleCoupon(scope.row.investoruserid,scope.row[`${mixin_router_search.type}name`]||scope.row.name)" size="mini" type="primary">优惠卷</el-button>
          <el-button  @click="toPath(`/investment/device?investoruserid=${scope.row.investoruserid}`)" size="mini" type="primary">小魔夹</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-add-investor-dialog :type="mixin_router_search.type"  ref="addDialogRef"></v-add-investor-dialog>
    <test-wallet-rechange-dialog ref="testWalletRechangeDialogRef"></test-wallet-rechange-dialog>
    <couponVue ref="couponRef"></couponVue>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
    <roleRelationDialog ref="roleRelationDialogRef"></roleRelationDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, nextTick,inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { userRoleList,exportInvestUser } from '@/api/User/role'
import testWalletRechangeDialog from './components/testWalletRechangeDialog.vue'
import addInvestor from './components/addInvestorDialog.vue'
import couponVue from "@/components/coupon/index.vue";
import exportDialog from "@/components/export/exportDialog.vue";
import roleRelationDialog from "./components/roleRelationDialog.vue"
import { useRouter } from "vue-router";
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-investor-dialog': addInvestor,
    testWalletRechangeDialog,
    couponVue,
    exportDialog,
    roleRelationDialog,
  },
  setup() {
    const router = useRouter();
    const toPath = (path: string) => {
      router.push(path);
    };
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const addDialogRef = ref<any>()
    const date = ref("");
    const list = ref<any>([])
    const couponRef = ref<any>("");
    const testWalletRechangeDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const roleRelationDialogRef = ref<any>();
    const searchSelect = ref({ search: 'name' })
    const mixin_router_search = ref({ status: '', type: 'investor',  careateBN: '', investortype:'',allowlatter:''})
    const mixin_select_search = ref({
      search: {
        name: '',
        account: '',
        userid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
        account: '账号',
        userid: 'ID',
      },
    })
    const reData = async (rq?: any) => {
      await nextTick()
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.name) {
            rq[`${mixin_router_search.value.type}name`] = rq.name
            delete rq.name
          }
          if (rq.account) {
            rq[`${mixin_router_search.value.type}account`] = rq.account
            delete rq.account
          }
          if (rq.userid) {
            rq[`${mixin_router_search.value.type}userid`] = rq.userid
            delete rq.userid
          }
          if (rq.careateBN) {
            rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
            rq.endTime =  dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
            delete rq.careateBN
          }
          rqData = { ...rqData, ...rq }

        }
        const res = await userRoleList(rqData, mixin_router_search.value.type)
        total.value = res.totalCount
        list.value = res.result
      } finally {
        loading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleActivate = () => {
      addDialogRef.value.open(mixin_router_search.value.type)
    }
    const handleRechange = (row: any) => {
      testWalletRechangeDialogRef.value.open(row)
    }
    const handleCoupon = (userid:string,name:string) =>{
      couponRef.value.open(userid,name)
    }
    const handleRoleRelation = (userid:string,phone:string,name:string) =>{
      roleRelationDialogRef.value.open(userid,phone,name)
    }
    const handleExport = async() => {
      exportDialogRef.value.open('普通会员表')
    }

    const exportFile = async(name:string) =>{
      console.log("导出名称=",name);
      try {
        let searchData: any = {}
        let rqData: any = {type: 'investor',fileName:'investorUser'}

        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          rqData.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            if(key=='name'||key=='account'||key=='userid'){
              rqData[`${mixin_router_search.value.type}${key}`]=searchData[key]
            }else {
              rqData[key]=searchData[key]
            }
          }
        })
  
        console.log('请求参数=',rqData)
        
        const res = await exportInvestUser(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
        
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      date,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleCoupon,
      handleActivate,
      addDialogRef,
      roleRelationDialogRef,
      changSearch,
      handleRechange,
      toPath_RouterSearchMixin,
      testWalletRechangeDialogRef,
      toPath,
      couponRef,
      handleExport,
      exportDialogRef,
      exportFile,
      handleRoleRelation,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;

  .header-select {
    width: 130px;
    margin: 0 24px 24px 0;
    ~ * {
      margin: 0 24px 24px 0;
    }
    /deep/.el-input {
      width: 130px;
      
    }
      .el-select {
      width: 130px;
      /deep/.el-input{
        width: 130px;
      }
    }
  }
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>