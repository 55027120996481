<template>
    <div class="container">
      <header>
        <el-input
          @keyup.enter="handelChange"
          class="input-with-select"
          placeholder="请输入搜索内容"
          v-model="mixin_select_search.search[searchSelect.search]"
        >
          <template #prepend>
            <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
              <el-option
                :key="item"
                :label="mixin_select_search_label.search[item]"
                :value="item"
                v-for="item of Object.keys(mixin_select_search.search)"
              ></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="handelChange" icon="el-icon-search"></el-button>
          </template>
        </el-input>
    
        <el-button @click="handleActivate" icon="el-icon-plus" type="primary" style="margin-left: 16px;">添加</el-button>
      </header>
      <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
        <el-table-column label="姓名" min-width="30">
          <template #default="scope">  
            {{scope.row.name}}
            <my-copy :data="scope.row.name"></my-copy>
          </template>
        </el-table-column>
        <el-table-column label="账号" min-width="40">
          <template #default="scope">
            {{scope.row.account}}
            <my-copy :data="scope.row.account"></my-copy>          
          </template>
        </el-table-column>
        <el-table-column label="ID" min-width="60">
          <template #default="scope">
            {{scope.row.userid}}
            <my-copy :data="scope.row.userid"></my-copy>      
          </template>
        </el-table-column>
        <el-table-column label="角色类型" min-width="60">
          <template #default="scope">
            {{scope.row.roleid=='28'?"运营总监":'售后主管'}}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" min-width="50" prop="createTime"></el-table-column>
        <el-table-column align="center" label="操作" min-width="60">        
          <template #default="scope"> 
            <el-button   size="mini" type="primary">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :page-sizes="[10, 30, 50, 100]"
        :total="total"
        @current-change="onPageChange"
        @size-change="handleLimitChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <v-add-operation-director-dialog  ref="addDialogRef"></v-add-operation-director-dialog>
    
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, readonly, nextTick,inject } from 'vue'
  import routerChangeReData from '@/common/routerChangeReData'
  import { operationDirectorList } from '@/api/User/role'
  import addOperationDirectorDialog from './components/addOperationDirectorDialog.vue'
  import { useRouter } from "vue-router";
  import dayjs from 'dayjs'
  import { saveAs } from 'file-saver'
  export default defineComponent({
    name: 'Name',
    components: {
      'v-add-operation-director-dialog': addOperationDirectorDialog,
      
    },
    setup() {
      const router = useRouter();
      const toPath = (path: string) => {
        router.push(path);
      };
      const loading = ref(false)
      const limit = ref(10)
      const page = ref(1)
      const total = ref(0)
      const addDialogRef = ref<any>()
      const date = ref("");
      const list = ref<any>([])
      const couponRef = ref<any>("");
      const testWalletRechangeDialogRef = ref<any>()
      const exportDialogRef = ref<any>();
      const searchSelect = ref({ search: 'name' })
      const mixin_router_search = ref({ status: '',  careateBN: '', investortype:'',allowlatter:''})
      const mixin_select_search = ref({
        search: {
          name: '',
          account: '',
          userid: '',
        },
      })
      const mixin_select_search_label = readonly({
        search: {
          name: '姓名',
          account: '账号',
          userid: 'ID',
        },
      })
      const reData = async (rq?: any) => {
        await nextTick()
        try {
          loading.value = true
          let rqData: any = { current: page.value, size: limit.value }
          if (rq && JSON.stringify(rq) !== '{}') {
            rqData = { ...rqData, entity:{...rq }}
          }
          const res = await operationDirectorList(rqData)
          total.value = res.total
          list.value = res.records
        } finally {
          loading.value = false
        }
      }
      const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
      const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
        routerChangeReData({
          mixin_select_search,
          mixin_router_search,
          loading,
          limit,
          page,
          reData,
          searchSelect,
        })
      const handelChange = () => {
        page.value = 1
        mixinReRouter()
      }
      const handleActivate = () => {
        addDialogRef.value.open()
      }
      const handleRechange = (row: any) => {
        testWalletRechangeDialogRef.value.open(row)
      }
   
     
      return {
        mixin_select_search_label,
        mixin_select_search,
        mixin_router_search,
        searchSelect,
        list,
        loading,
        page,
        total,
        limit,
        date,
        handelChange,
        handleLimitChange,
        onPageChange,
        handleActivate,
        addDialogRef,
        changSearch,
        handleRechange,
        toPath_RouterSearchMixin,
        testWalletRechangeDialogRef,
        toPath,
        couponRef,
        exportDialogRef,
      }
    },
  })
  </script>
  <style lang="scss" scoped>
  .container {
    padding: 18px;
  
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
      /deep/.el-input {
        width: 130px;
        
      }
        .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .input-with-select {
      margin-right: 24px;
      width: 320px;
      /deep/.el-input {
        width: 120px;
      }
      ~ * {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
   .header-el-selector {
      width: 120px;
      margin-right: 12px;
       /deep/.el-input {
        width: 120px;
      }
    }
  }
  </style>