import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface investorProfitDeviceModel {
    
    "articleequipmentsn": string, //小魔夹SN  
    "amount": string, //收益
    "leftoverday": string, //剩余折现天数
    
}

/**
 * 
 * @param params 查询投资人的收益账单
 * @returns 
 */
export async function investorProfitDeviceListHttp(params: ListReq): Promise<{
    records: investorProfitDeviceModel[];
    total: number;
}> {
    params.token = true
    const res = await defHttp.request<ListRes<investorProfitDeviceModel>>(
        {
            url: `/Investment/getProfitOfEveryXmjByPage`,
            params
        }, 
        {
            prefix: "/analy",
            contentType: 'json'
        }
    );
   
    // res.rows = res.rows.map(v => {    
    //     v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
    //     v.gmtIn =v.gmtIn?dayjs(v.gmtIn).format("YYYY-MM-DD HH:mm:ss"):"暂无";
    //     v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
    //     v.gmtOut = v.gmtOut?dayjs(v.gmtOut).format("YYYY-MM-DD HH:mm:ss"):"暂无";
    //     return v
    // })
    return res.data
}
