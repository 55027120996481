
import { defineComponent, ref, readonly, inject } from 'vue'
import {
  investmentorderList,
  InvestmentorderListRes,
  investmentorderRemove,
  investmentorderCreateshipment,
  confirmdelivery,
  exportInvestmentorder,
} from '@/api/Investment/Order'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { pickerOptions } from './elementConfig'
import { getStatusName, getArticletypeName } from './editData'
import orderDialog from './components/orderDialog.vue'
import orderDialogNew from './components/orderDialogNew.vue'
import orderConfrimDialog from './components/orderConfrimDialog.vue'
import orderMailDialog from './components/orderMailDialog.vue'
import shipmentDialog from '../components/shipmentDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import purchaseListDialog from '@/components/investorment/purchaseListDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import { saveAs } from 'file-saver'
import axios from 'axios'
import myExport from '@/utils/exprotExcel'
import dayjs from 'dayjs'
import { any } from 'vue-types'
export default defineComponent({
  name: 'investment-order',
  components: {
    'v-add-order-dialog': orderDialog,
    'v-add-order-dialog-new': orderDialogNew,
    'v-order-confrim-dialog': orderConfrimDialog,
    'v-order-mail-dialog': orderMailDialog,
    'v-shipment-dialog':shipmentDialog,
    exportDialog,
    purchaseListDialog,
  },
  setup() {
    const mixin_router_search = ref({
      status: '', //投资订单状态，0 已下单；1 已付款；2 已出仓；3 已开始投放；4 已寄出；
      launchmode: '', //投放方式， SELF 自投放； PLATFORM 平台投放；
      expressed:'', //是否邮寄
      fortest: false, //是否用于测试，如：true 是 false 否
      investortype: '', //投资者类型，如：user 用户；platform 平台；
      investmode: '', //投资方式， online 线上投资 offline 线下投资
      freegift: '', //是否为免费赠送，如：true 是 false 否
      articleproductid: '56c62a11a6a14d6eb7ab29fbf9f2f8b4', //设备类型
      careateBN: '',
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        account:'', //投资人手机号
        investorname:'',//投资人姓名
        investoruserid: '', // 投资人用户编号
        orderid: '', //投资订单编码
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        investoruserid: '投资人ID', // 投资人用户编号
        orderid: '订单ID', //投资订单编码
        account:'手机号', 
        investorname:'姓名',
      },
    })
    const searchSelect = ref({search: 'account'})
    const url = require ("@/config/url.ts")
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0) 
    const list = ref<InvestmentorderListRes[]>([])
    const btnRemoveLoading = ref(false)
    const btnConfirmdeliveryLoading = ref(false)
    const addOrderDialogRefNew = ref<any>()
    const addOrderDialogRef = ref<any>()
    const orderConfrimDialogRef = ref<any>()
    const orderMailDialogRef = ref<any>()
    const shipmentDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const purchaseListDialogRef = ref<any>();
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }
    type SearchData = typeof searchData

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = {
          current: page.value,
          size: limit.value,
        }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          console.log("筛选时间",rq.careateBN);
          if (rq.careateBN) {
            rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
            rq.endTime =  dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
            delete rq.careateBN
          }
         rqData = { ...rqData, entity: rq }
        }
        const res = await investmentorderList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }

    const { mixinReRouter, onPageChange, handleLimitChange, changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addOrderDialogRefNew.value.open()
    }
    const handleEdit = (index: number, row: any) => {
      addOrderDialogRef.value.open(row, index)
    }
    const orderDialogSueccess = (data: any) => {
      if (data.isEdit) {
        list.value.splice(data.index, 1, data.data)
      } else {
        //list.value.push(data.data)
        page.value = 1
        mixinReRouter()
      }
    }
    const orderMailDialogSueccess = () =>{
      page.value = 1
      mixinReRouter()
    }
    const handleRemove = async (index: number, orderid: string) => {
      try {
        btnRemoveLoading.value = true
        await investmentorderRemove({
          orderid,
        })
        list.value.splice(index, 1)
      } finally {
        btnRemoveLoading.value = false
      }
    }

    const handleConfirmdelivery = async (index: number, orderid: string) => {
      try {
        btnConfirmdeliveryLoading.value = true
        const res = await confirmdelivery({
          orderid,
          salesmanuserid: userStore.info.userid,
        })
        list.value[index].status = '3'
        ElMessage.success(res)
      } finally {
        btnConfirmdeliveryLoading.value = false
      }
    }

    const handleConfirm = async (index: number, row: any) => {
      row.index = index
      orderConfrimDialogRef.value.open(row)
    }
    const orderConfirmDialogSueccess = (index: number) => {
      list.value[index].status = '1'
    }
    const handleMail = (row: any) => {
      orderMailDialogRef.value.open(row)
    }
    const handleCreateBillSales = async (
      index: number,
      { articlenum, orderid }: any
    ) => {
      try {
        const input = await ElMessageBox.prompt('请输入出货数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: `请输入 1-${articlenum} `,
          inputType: 'number',
        })
        console.log('input :>> ', input)
        const _value = parseInt(input.value)
        if (input.value > articlenum || _value < 1)
          return ElMessage.error(`请输入 1-${articlenum}`)
        if (!userStore.info.userid) return userStore.loginOut()
        const res = await investmentorderCreateshipment({
          orderid,
          quantity: _value,
          shipperuserid: userStore.info.userid,
        })
        console.log('res :>> ', res)
        ElMessage.success(res)
      } catch (error) {
        if (error == 'cancel') return
        if (error == 'colse') return
        ElMessage.error(error)
      }
    }
    const handlerShipment = (item:any) => {
      //shipmentDialogRef.value.open(item.investorname||item.account,item.orderid,item.articlenum);
      purchaseListDialogRef.value.open(item.investorname||item.account,item.orderid,item.articlenum)
    }
    const handleExport = async() => {
      exportDialogRef.value.open('小魔夹投资订单表')
      
      // const excel = new myExport(`小魔夹投资订单列表`)
      // const _list = list.value.map((v) => {
      //   const _data = {
      //     姓名: v.investorname,
      //     手机号: v.account,
      //     时间: v.creationdate,
      //     投资方式: v.investmode=="online"?"线上投资":"线下投资",
      //     数量: v.articlenum,
      //     // 分成比: v.investorproportion + '%',
      //     单价: v.singleamount,
      //     总计: v.articlenum * v.singleamount,
      //     状态: getStatusName[v.status],
      //     销售员: v.salesmanuserid,
      //   }
      //   return _data
      // })
      // excel.addWorksheet({ headers: ['小魔夹投资订单'], list: _list })
      // excel.export()
    }
    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'investorOrder',fortest:mixin_router_search.value.fortest}
        
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        
        console.log('导出参数=',searchData)
        if (searchData.careateBN) {
          rqData.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            rqData[key]=searchData[key]
          }
        })
        //let _url=url.baseUrl+'/v1/analy/client/export/exportInvestmentorderData?token='+userStore.userInfo.token+'&fileName=investor'
        // axios.post(_url, {
        //     ...rqData,
        //     headers: { "Access-Control-Allow-Origin": "*",}
        //   },
          
        //   {responseType: 'blob',}
        //   ).then((res) => {
        //     console.log('导表数据=',res);
        //     let blob = new Blob([JSON.stringify(res)]);
        //     let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
        //     let a = document.createElement('a');
        //     a.href = url;
        //     a.download = '投资订单.xlsx';
        //     a.click();
        //     window.URL.revokeObjectURL(url); // 释放该 url
        // }).catch((err) => {
        //     console.log(err);
        // });

        // await axios.request({
        //   url: _url,
        //   method: 'post',
        //   data: rqData,
        //   headers: {
        //     'Content-Type': 'application/json;charset=UTF-8',
        //     'Access-Control-Allow-Origin':' *',
        //     'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        //     'Access-Control-Allow-Headers':'x-requested-with,content-type'
        //   }
        // }).then((res:any) => {
        //   const blob = new Blob([JSON.stringify(res)],{ type: 'application/octet-stream' });
        //   saveAs(blob, `投资订单表.xls`)
        //     //callBack(res);
        // }).catch((e:any) => {
        //     console.log('错误=',e)
        //     //alert(e);
            
        // })

        const res = await exportInvestmentorder(rqData)
       
        const blob = new Blob([res.data]);
     
        saveAs(blob, name)
        // let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
        // let a = document.createElement('a');
        // a.href = url;
        // a.download = '投资订单.xlsx';
        // a.click();
        // window.URL.revokeObjectURL(url); // 释放该 url
        // let blob = new Blob([res], {
        //   type: res.data.type,
        // });
      } finally {
       
      }
    }
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,
      pickerOptions,
      onPageChange,
      handelChange,
      handleLimitChange,
      searchSelect,
      changSearch,
      list,
      loading,
      getStatusName,
      getArticletypeName,
      handleAdd,
      addOrderDialogRefNew,
      addOrderDialogRef,
      orderConfrimDialogRef,
      orderMailDialogRef,
      handleEdit,
      orderDialogSueccess,
      orderMailDialogSueccess,
      handleRemove,
      btnRemoveLoading,
      handleConfirm,
      roleShowPermission,
      orderConfirmDialogSueccess,
      page,
      limit,
      total,
      handleCreateBillSales,
      toPath_RouterSearchMixin,
      handleConfirmdelivery,
      sheareProductInfo,
      handleExport,
      handleMail,
      shipmentDialogRef,
      handlerShipment,
      exportDialogRef,
      exportFile,
      purchaseListDialogRef,
    }
  },
})
