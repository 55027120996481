
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import fourthgmsgDialog from '../components/fourthgmsgDialog.vue'
import fourthgcommandDialog from '../components/fourthgcommandDialog.vue'
import {
  getContainInfoList,
  GetContainInfoList,
  removePanel,
  getcrc,
} from '@/api/Share/Panel'
import deviceDialog from '../panel/components/deviceDialog.vue'
import componentsDialog from '../panel/components/componentsDialog.vue'
import addDialog from '../panel/components/addDialog.vue'
import { ElMessage } from 'element-plus'
import { getPanelProductInfo } from '@/api/util'
import myExport from '@/utils/exprotExcel'
import mySelectCity from '@/components/cityList/index.vue'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'Name',
  components: {
    'v-device-dialog': deviceDialog,
    'v-components-dialog': componentsDialog,
    fourthgmsgDialog,
    fourthgcommandDialog,
    addDialog,
    mySelectCity,
  },
  setup() {
    const loading = ref(false)
    const syncloading = ref(false)
    const areaCode = ref('')
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<GetContainInfoList[]>([])
    const addDialogRef = ref<any>()
    const fourthgmsgDialogRef = ref<any>()
    const deviceDialogRef = ref<any>()
    const componentsDialogRef = ref<any>()
    const fourthgcommandDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const panelInfo = getPanelProductInfo()
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '',
        mac: '',
        deviceid: '',
        name: '',
        contactname: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: 'SN',
        mac: 'MAC',
        name: '店名',
        deviceid: 'deviceid',
        contactname: '联系人',
      },
    })
    const mixin_router_search = ref({
      status: '',
      selectProduct: {},
      fortest: false,
      online: '',
      orderby: '',
      sort: 'desc',
      fault: '',
      returnTime: '',
      enabled: true,
      rentTime: '',
    })
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { currentPage: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.selectProduct) {
            rqData.productid = rq.selectProduct.productid
            delete rq.selectProduct
          }
          if (rq.order) {
            const { orderby, sort } = rq
            delete rq.orderby
            delete rq.sort
            rqData = { ...rqData, orderby, sort }
          }
          if (rq.returnTime) {
            rq.returnBeginTime = rq.returnTime[0]
            rq.returnEndTime = rq.returnTime[1]
            delete rq.returnTime
          }
          if (rq.rentTime) {
            rq.rentBeginTime = rq.rentTime[0]
            rq.rentEndTime = rq.rentTime[1]
            delete rq.rentTime
          }
          if (areaCode.value) {
            rq = { ...rq, ...switchCode(areaCode.value) }
          }
          rqData = { ...rqData, ...rq }
        }
        const res = await getContainInfoList(rqData)
        total.value = res.total
        list.value = res.list
      } finally {
        loading.value = false
      }
    }

    const switchCode = (code: string) => {
      const rq: any = {}
      switch (code.length) {
        case 2:
          rq.provinceareacode = code
          break
        case 4:
          rq.cityareacode = code
          break
        case 6:
          rq.districtareacode = code
          break
        case 9:
          rq.streetareacode = code
          break
      }
      return rq
    }

    const cityCodeChange = (code: string) => {
      areaCode.value = code
      page.value = 1
      mixinReRouter()
    }

    const handleExport = async () => {
      const excel = new myExport('小魔柜列表1')
      const _list = list.value.map((v) => {
        const _data = {
          机柜SN: v.panelequipmentsn,
          商户名: v.name,
          正常数: v.num,
          故障数: v.faultnum,
          空仓口: v.emptynum,
          租借次数: v.borrownum,
          当前位置: v.address,
          // 发布状态: v.ownership == 'public' ? '已发布' : '未发布',
          离线状态: v.online ? '在线' : '离线',
          机柜名称: v.productname,
          // 维修员: v.repairmanname,
          在线时间: '无',
          在线时长: '无',
          '在线时长（毫秒）': 0,
          离线时间: '无',
          离线时长: '无',
          '离线时长（毫秒）': 0,
        }
        if (v.online) {
          _data.在线时间 = v.lastonlineDate
          _data.在线时长 = v.onlineLongText
          _data['在线时长（毫秒）'] = v.onlineLongtime
        } else {
          _data.离线时间 = v.lastofflineDate
          _data.离线时长 = v.lastofflineText
          _data['离线时长（毫秒）'] = v.offlineLongtime
        }
        return _data
      })
      excel.addWorksheet({ headers: ['小魔柜列表'], list: _list })
      excel.export()
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
      mixinReRouterTableSortChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const addSuccess = () => {
      reData()
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removePanel({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    const handleShowDevice = (sn: string) => {
      deviceDialogRef.value.open(sn)
    }
    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }
    const handleSync = async (deviceid: string) => {
      try {
        syncloading.value = true
        await getcrc({ deviceid })
      } finally {
        syncloading.value = false
      }
      reData()
    }
    const changeSort = (type: string) => {
      if (type == mixin_router_search.value.orderby) {
        mixin_router_search.value.sort =
          mixin_router_search.value.sort == 'desc' ? 'asc' : 'desc'
      } else {
        mixin_router_search.value.orderby = type
        mixin_router_search.value.sort = 'asc'
      }
      mixinReRouter()
    }
    const handleFourthgmsg = (deviceid: string) => {
      fourthgmsgDialogRef.value.open(deviceid)
    }
    const handleFourthgcommand = (deviceid: string) => {
      fourthgcommandDialogRef.value.open(deviceid)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleRemove,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      handleShowDevice,
      deviceDialogRef,
      componentsDialogRef,
      handleSync,
      handleShowComponenet,
      changSearch,
      panelInfo,
      mixinReRouterTableSortChange,
      changeSort,
      handleExport,
      handleFourthgmsg,
      fourthgmsgDialogRef,
      fourthgcommandDialogRef,
      handleFourthgcommand,
      handleAdd,
      cityCodeChange
    }
  },
})
