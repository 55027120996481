
import { defineComponent, ref, readonly, inject,onMounted} from 'vue'
import { investorProfitOrderModel, investorProfitOrderListHttp } from '@/api/Profit/investor'
import { userStore } from '@/store/modules/user'
import { getOrderList, OrderList} from '@/api/Order'
import { toOptional } from "@/types/utils";
import dayjs from 'dayjs'
import {useRoute} from "vue-router"
export default defineComponent({
  name: 'profit-order-details',
  components: {

  },
  
  setup() {
    const route = useRoute()
    const list = ref<OrderList[]>([])
    const profitList = ref<investorProfitOrderModel[]>([])
    const nickname =ref<any>();
    const proportion =ref<any>();
    const amount =ref<any>();
    const item = ref<string>();
    const profitInfo = ref<any>({})
    const shareorderid= ref<any>()
    shareorderid.value=route.query.shareorderid;
    proportion.value=route.query.proportion;
    amount.value=route.query.profit;
    nickname.value=route.query.name;

   // profitInfo.value=JSON.parse(JSON.stringify(route.query.shareorderid));
    // let obj: investorProfitOrderModel = JSON.parse(JSON.stringify(route.query.shareorderid));
    //nickname.value=profitInfo.value?.nickname;
    //proportion.value=profitInfo.value.proportion;
    //amount.value=profitInfo.value.amount;
    //console.log('数据')
    // console.log(obj.nickname)
    
   // const sharedorderid=profitInfo.sharedorderid;
    const orderInfo = ref<any>({});
  
    const reData = async (rq?: any) => {
      try {
        
        let rqData: any = { current: 1, size: 10 }
        
        rqData = { ...rqData, entity: rq }

        const res = await getOrderList(rqData)
        console.log("详情信息")
        console.log(res);
        list.value = res.records
        orderInfo.value=list.value[0];
        // total.value = res.total
        // getProfit({
        //         sharedorderid:sharedorderid,
        //         fortest: 'false', 
        //         userid:userStore.userInfo.userid,
        //         productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4'
        //     })
      } finally {
         
      }
    };

     const getProfit = async (rq?: any) => {
      try {
       
         let rqData: any = { currentPage: 1, size: 10  }

         rqData = { ...rqData,  ...rq }
        
        const res = await investorProfitOrderListHttp(rqData)
        profitList.value = res.records
        profitInfo.value=profitList.value[0];
        // total.value = res.total
      } finally {
        
      }
    }
    onMounted(() => {
    
      reData({
          sharedorderid:shareorderid.value 
      });
    });
    return {
     orderInfo,
     profitInfo,
     nickname,
     proportion,
     amount,
    }
  },
  
})
