
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  getPanelList,
  GetContainInfoList,
  PanelListRes,
  removePanel,
  getcrc,
  getContainInfoList,
  getContainList,
  exportContainInfoList,
} from '@/api/Share/Panel'
import addDialog from './components/addDialog.vue'
import deviceDialog from './components/deviceDialog.vue'
import componentsDialog from './components/componentsDialog.vue'
import showDeviceDrawerVue from './components/showDeviceDrawer.vue'
import fourthgmsgDialog from '../components/fourthgmsgDialog.vue'
import fourthgcommandDialog from '../components/fourthgcommandDialog.vue'
import cabinetDatailsDialog from '@/components/device/cabinetDatailsDialog.vue'
import oneNetReportDialog from '@/components/device/oneNetReportDialog.vue'
import oneNetSendDialog from '@/components/device/oneNetSendDialog.vue'
import placeDetailsDialog from '@/components/place/placeDetailsDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import { ElMessage } from 'element-plus'
import myExport from '@/utils/exprotExcel'
import { getPanelProductInfo ,getCityInfoList } from '@/api/util'
import { userStore } from '@/store/modules/user'
import { useRouter } from "vue-router";
import mySelectCity from '@/components/cityList/index.vue'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-dialog': addDialog,
    'v-device-dialog': deviceDialog,
    'v-components-dialog': componentsDialog,
    showDeviceDrawerVue,
    fourthgmsgDialog,
    fourthgcommandDialog,
    mySelectCity,
    cabinetDatailsDialog,
    oneNetReportDialog,
    oneNetSendDialog,
    placeDetailsDialog,
    exportDialog,
  },

  setup() {
    const loading = ref(false)
    const syncloading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const router = useRouter();
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<GetContainInfoList[]>([])
    const addDialogRef = ref<any>()
    const deviceDialogRef = ref<any>()
    const componentsDialogRef = ref<any>()
    const showDeviceDrawerRef = ref<any>()
    const fourthgmsgDialogRef = ref<any>()
    const fourthgcommandDialogRef = ref<any>()
    const cabinetDetailsDialogRef = ref<any>()  
    const oneNetReportDialogRef = ref<any>()
    const oneNetSendDialogRef = ref<any>()
    const placeDetailsDialogRef = ref<any>();
    const exportDialogRef = ref<any>();
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const sheareProductInfo = getPanelProductInfo()  //共享设备类型
    const cityInfoList = getCityInfoList();       //城市集合
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '',
        name:'',
        contactname: '',
        contactmobile: '',
        repairmanname:'',
        operationname:'',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: '设备SN',
        name:'场所名称',    
        contactname: '联系人',
        contactmobile: '联系人帐号',
        repairmanname: '维修员',
        operationname:'运营团队'
      },
    })
   const toPath = (path: string) => {
      router.push(path);
    };
    const mixin_router_search = ref({ status: '', productid: '',cityareacode:'',districtareacode:'',online:'',orderby:"",careateBN:""})
    const reData = async (rq?: any) => {
      console.log("开始搜索")
      console.log(rq)
      try {
        loading.value = true
        let rqData: any = { currentPage: page.value, size: limit.value ,fortest: false}
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          if(rq.orderby){
            rq.sort='desc'
          }
          if(rq.online&&rq.online=="0"){
            rq.sort='desc';
            rq.orderby='offlineTime'
          }
          if (rq.careateBN) {
            rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endTime = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData={...rqData,...rq}
        }
        // rqData={...rqData,...mixin_router_search.value}
        // Object.keys(rqData).map((key)=>{
        //   if(rqData[key]==''|| rqData[key]==null ||rqData[key] == undefined){
        //       delete rqData[key]
        //   }
        // }) 
        //const res = await getContainInfoList(rqData)
        const res = await getContainList(rqData)
        total.value = res.total
        list.value = res.list
      } finally {
        loading.value = false
      }
    }
    const handleExport = async () => {
      exportDialogRef.value.open('机柜统计表')
      // const excel = new myExport('小魔柜列表')
      // const _list = list.value.map((v) => {
      //   const _data = {
      //     机柜SN: v.panelequipmentsn,
      //     机柜mac:v.mac,
      //     商户名: v.name,
      //     联系人:v.contactname,
      //     联系电话:v.contactmobile,
      //     正常数: v.normalnum,
      //     故障数: v.faultnum||0,
      //     私有数: v.num||0,
      //     空仓数: v.emptynum,
      //     当前位置: v.address,
      //     在线离线: v.online ? '在线' : '离线',
      //     机柜名称: v.productname,
      //     城市编号: v.cityareacode,
      //     县区编号: v.districtareacode,
      //     在线时间: '无',
      //     在线时长: '无',
      //     离线时间: '无',
      //     离线时长: '无',
      //   }
      //   if (v.online) {
      //     _data.在线时间 = v.lastonlineDate
      //     _data.在线时长 = v.onlineLongText
      //   } else {
      //     _data.离线时间 = v.lastofflineDate
      //     _data.离线时长 = v.lastofflineText
      //   }
      //   return _data
      // })
      // excel.addWorksheet({ headers: ['小魔柜列表'], list: _list })
      // excel.export()
    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {currentPage: page.value, size: limit.value ,fortest: false}
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          searchData.operationId=userStore.getCurrentRole.operationId
        }
        let rqData: any = {fileName:'cabientList'}
        
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
      
        if (searchData.careateBN) {
          rqData.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        if(searchData.orderby){
          searchData.sort='desc'
        }
         if(searchData.online&&searchData.online=="0"){
          searchData.sort='desc';
          searchData.orderby='offlineTime'
        }
        delete searchData.careateBN
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            rqData[key]=searchData[key]
          }
        })
        

        const res = await exportContainInfoList(rqData)
       
        const blob = new Blob([res.data]);
     
        saveAs(blob, name)
       
      } finally {
       
      }
    }

    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {
        placeid: "",
      })
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }
    const addSuccess = () => {
      reData()
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removePanel({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    // const handleShowDevice = (item:any) => {
    //   showDeviceDrawerRef.value.open(item)
    // }

    const handleShowDevice = (sn:string,prefix: string) => {
      deviceDialogRef.value.open(sn,prefix)
    }
    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }
    const handleSync = async (deviceid: string,sn:string) => {
      try {
        syncloading.value = true
        const res=await getcrc({ deviceid })
        ElMessage.success(sn+" "+res);
      } finally {
        syncloading.value = false
      }
      //reData()
    }
    const cityCodeChange = (code: string) => {
      if(code&&code.length==4){
        mixin_router_search.value.cityareacode=code;
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }else if(code&&code.length==6){
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=code
        page.value = 1
        mixinReRouter()
      }else{
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }
      
    }
    const handleFourthgmsg = (deviceid: string) => {
      fourthgmsgDialogRef.value.open(deviceid)
    }
    const handleFourthgcommand = (deviceid: string) => {
      fourthgcommandDialogRef.value.open(deviceid)
    }
    const handleDetails = (sn:string,item:any) => {
  
      cabinetDetailsDialogRef.value.open(sn,item)
    }
    const getCellClass =(obj:any) =>{
      if(obj.columnIndex==1){
        return "padding:6px 0 0 0;"
        }else{
          return ""
        }
    }

    /**
     * 机柜上报消息 
     */
    const handle4GUpdate = (deviceId:string,sn:string) =>{
      oneNetReportDialogRef.value.open(deviceId,sn)
    }    
    /**
     * 机柜下发消息
     */
    const handle4GSend = (deviceId:string,sn:string) =>{
      oneNetSendDialogRef.value.open(deviceId,sn)
    }

    const handlePlaceDetails = (placeId:string,name:String) =>{
      placeDetailsDialogRef.value.open(placeId,name)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleRemove,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      handleShowDevice,
      deviceDialogRef,
      componentsDialogRef,
      showDeviceDrawerRef,
      cabinetDetailsDialogRef,
      oneNetReportDialogRef,
      oneNetSendDialogRef,
      placeDetailsDialogRef,
      handleSync,
      handleDetails,
      handleShowComponenet,
      changSearch,
      sheareProductInfo,
      cityInfoList,
      syncloading,
      handleFourthgmsg,
      fourthgmsgDialogRef,
      fourthgcommandDialogRef,
      handleFourthgcommand,
      toPath,
      handleExport,
      cityCodeChange,
      getCellClass,
      handle4GUpdate,
      handle4GSend,
      handlePlaceDetails,
      exportDialogRef,
      exportFile,
    }
  },
})
