<template>
  <div class="container">
    <header>
     <!-- <el-input      
        class="input-with-select"
        placeholder="请输入设备SN"
        v-model="deviceSn">      
        <template #prepend>
          <el-select placeholder="请选择"  class="input-select-tip">
            <el-option
              label="设备SN"   
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelSearch" icon="el-icon-search"></el-button>
        </template>
      </el-input> -->
      <el-select @change="handelChange" class="header-select" clearable placeholder="消息类型" v-model="mixin_router_search.datastream">
        <el-option label="同步" value="PTS"></el-option>
        <el-option label="归还" value="STSIN"></el-option>
        <el-option label="弹出" value="STSOUT"></el-option>
        <el-option label="心跳" value="GETCSQ"></el-option>
        <el-option label="在线" value="MAC"></el-option>
      </el-select>     

      <!-- <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="创建时间"
        range-separator="至"
        start-placeholder="创建时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.careateBN"
      ></el-date-picker> -->
     
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column label="设备SN" align="center" min-width="44">
        <template #default="scope">{{scope.row.cabinetsn}}</template>
      </el-table-column>
      <el-table-column label="消息类型" min-width="20">
        <template #default="scope">        
           <div v-if="scope.row.datastream=='PTS'">同步</div>
           <div v-else-if="scope.row.datastream=='STSIN'">归还</div>
           <div v-else-if="scope.row.datastream=='STSOUT'">弹出</div>
           <div v-else-if="scope.row.datastream=='GETCSQ'">心跳</div>
           <div v-else-if="scope.row.datastream=='SOS'">SOS</div>
           <div v-else-if="scope.row.datastream=='TIME'">同步时间</div>
           <div v-else-if="scope.row.datastream=='bat'">电量</div>
           <div v-else-if="scope.row.datastream=='appversion'">版本号</div>
           <div v-else-if="scope.row.datastream=='Expired'">租借时间</div>
           <div v-else-if="scope.row.datastream=='LBS'">定位</div>
           <div v-else-if="scope.row.datastream=='factory'">工厂模式</div>
           <div v-else-if="scope.row.datastream=='MAC'">在线</div>
           <div v-else>其他</div>
        </template>
      </el-table-column>
      <el-table-column label="消息内容" align="center" min-width="120">
        <template #default="scope">
          {{scope.row.body}}
        </template>
      </el-table-column>
      
      <el-table-column label="上报时间" align="center" min-width="50">
        <template #default="scope">{{scope.row.datatimestamp}}</template>
      </el-table-column>
      <el-table-column label="到达时间" align="center" min-width="50">
        <template #default="scope">{{scope.row.creationtime}}</template>
      </el-table-column>
     
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[100, 300, 500, 1000]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
   
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject ,onMounted} from 'vue'
import { fourthgmsgList, FourthgcommandList } from '@/api/Share/Device'
import routerChangeReData from '@/common/routerChangeReData'

import { ElMessage, ElMessageBox } from 'element-plus'
import { getShareProductInfo } from '@/api/util'
import {useRoute} from "vue-router"
import dayjs from 'dayjs'
export default defineComponent({
  name: 'report',
  components: {
   
  },
  setup() {

    const route = useRoute()
    const deviceId = ref<any>();
    deviceId.value=route.query.deviceid;
    const mixin_router_search = ref({
      datastream: '' //消息类型
      
    })
    const mixin_select_search = ref({
      search: {
        
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        deviceid: '设备id', 
      },
    })
    const searchSelect = ref({})
    const deviceSn=ref<any>();   
    const list = ref<FourthgcommandList[]>([])
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
  
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { page: page.value, limit: limit.value,deviceid:deviceId.value,sort: 'datatimestamp',
      order: 'desc' }
         if (rq ) {
        //   if (rq.order) {
        //     const { order, sort } = rq
        //     delete rq.order
        //     delete rq.sort
        //     rqData = { ...rqData, order, sort }
        //   }
          rqData = { ...rqData, ...rq }
        }

        const { totalCount, result } = await fourthgmsgList(rqData)
        total.value = totalCount
        list.value = result
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })

    const handelSearch = () => {

    }

    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')

    //  onMounted(() => {
    
    //   reData();
    // });

    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelSearch,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      list,
      loading,
      deviceSn,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
     
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>