
import { ElMessage } from "element-plus";
import { computed, defineComponent, ref } from "vue";
import {
  warehouseManagementInout,
  warehouseManagementUpdate,
} from "@/api/ERP/warehouseManagement";
import { userStore } from "@/store/modules/user";
export default defineComponent({
  name: "v-dialog",
  setup(props, { emit }) {
    const visible = ref(false);
    const list = ref<string[]>([]);
    const type = ref<"IN" | "OUT">("IN");
    const upLoading = ref(false);
    const inputData = ref("");
    const searchData = ref("");
    let orderType: "old" | "new" = "old";
    let timer: any;
    const handleClose = () => {
      visible.value = false;
      emit("success");
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const showList = computed(() => {
      return list.value.filter((v) => v.indexOf(searchData.value) > -1);
    });
    const open = async (_type: "IN" | "OUT", _orderType: "old" | "new") => {
      visible.value = true;
      type.value = _type;
      orderType = _orderType;
    };
    const handleRemove = async (index: number) => {
      try {
        upLoading.value = true;
        const res = await warehouseManagementUpdate({
          status: type.value == "IN" ? 0 : 1, // 撤销操作
          sn: list.value[index],
        });
        ElMessage.success(res.message);
        list.value.splice(index, 1);
      } finally {
        upLoading.value = false;
      }
    };

    const upData = async (sn: string) => {
      try {
        upLoading.value = true;
        if (!userStore.userInfo.token) return;
        const res = await warehouseManagementInout({
          status: type.value == "IN" ? 1 : 2,
          sn,
          token: userStore.userInfo.token,
          type: orderType,
        });
        ElMessage.success(res.message);
        list.value.push(sn);
      } finally {
        upLoading.value = false;
      }
    };

    // https://xmj.smartline.com.cn/xmj/AAAI966
    const handleInput = async (e: any) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(async () => {
        if (e && e.length == 40) {
          const sn = e.split("/xmj/")[1];
          if (sn) {
            inputData.value = "";
            if (
              list.value.findIndex((v) => v.toUpperCase() == sn.toUpperCase()) >
              -1
            ) {
              return ElMessage.error("请勿重复添加");
            } else {
              await upData(sn);
            }
          }
        }
      }, 200);
    };
    return {
      visible,
      handleClose,
      open,
      upLoading,
      type,
      list,
      handleRemove,
      inputData,
      showList,
      searchData,
      handleInput,
    };
  },
});
