<template>
  <el-dialog
    :destroy-on-close="true"
    :title="name"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="760px"
  >
    <el-carousel height="320px" :interval="5000" arrow="always" v-if="images">
      <el-carousel-item v-for="item in images" :key="item" >
        <img  :src="item" style="height: 100%; width: 100%;" :lazy="true"/>
      </el-carousel-item>
    </el-carousel>
    <el-image :src="require('@/assets/img/shop.png')" style="width: 100%;height:320px;" fit="scale-down" v-else></el-image>
    <el-form  v-if="deviceInfo">
      <el-row class="inline-info">
        
        <el-form-item label="地址:" class="from-item-label" >
          <span v-if="deviceInfo.address">{{ deviceInfo.address }}</span>
        </el-form-item>
          
      </el-row>  
      <el-row class="inline-info">
        <el-col :span="12">
          <el-form-item label="联系人:"  class="from-item-label">
              <span  v-if="deviceInfo.contactname">{{ deviceInfo.contactname }}</span>        
          </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="联系方式:"  class="from-item-label">
          <span v-if="deviceInfo.contactmobile">{{ deviceInfo.contactmobile }}</span>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <el-table :data="deviceList" height="280px" style="width: 100%;margin-top: 16px;" v-if="deviceList" >
    <el-table-column label="设备SN" width="240" >
      <template #default="scope">
        {{scope.row.sharedpanel.panelequipmentsn}}
      </template>
    </el-table-column>
    <el-table-column label="位置" width="240" >
      <template #default="scope">
        {{scope.row.sharedpanel.location}}
      </template>
    </el-table-column>
    <el-table-column label="设备类型" width="240" >
      <template #default="scope">
        <span v-if='scope.row.sharedpanel.productmodel=="mgs4g"'>一代机柜</span>
        <span v-if='scope.row.sharedpanel.productmodel=="MGS4G2"'>二代机柜</span>
        <span v-if='scope.row.sharedpanel.productmodel=="MGS4G3"'>二代机柜</span>
        <span v-if='scope.row.sharedpanel.productmodel=="UMHD"'>伞架</span>
        <span v-if='scope.row.sharedpanel.productmodel=="CV01"'>便民柜</span>
      </template>
    </el-table-column>
  </el-table>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref } from "vue";
import { getPlaceDetails,getPlaceDevices } from "@/api/Share/Place"
let plactId="";
export default defineComponent({
  name: "v-device-dialog",
  setup() {
    const visible = ref(false);
    const name = ref("");
    const images = ref<string[]>([]);
    const deviceInfo = ref<any>();
    const deviceList = ref<any[]>([]);
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const open = (_placeid:string,_name:string) => {
      visible.value = true;
      name.value=_name;
      plactId=_placeid;
      images.value=[];
      deviceInfo.value="";
      deviceList.value=[];
      reData();
      queryPlaceDevices();
    };
    const reData = async () => {
      let reData : any={placeid:plactId}
      const res= await getPlaceDetails(reData);
      deviceInfo.value=res.record;
      images.value=res.record.signboardUrls;
      console.log('图片地址=',images.value)
    };
   
    const queryPlaceDevices = async() =>{
      let reData : any={current:1,size:10,entity:{placeid:plactId}}
      const res = getPlaceDevices(reData);
      deviceList.value=(await res).records;
    }

    return {
      visible,
      handleClose,
      open,
      name,
      images,
      deviceInfo,
      deviceList,
    };
  },
});
</script>
<style lang = "scss" scoped >
 .from-item-label{
    font-weight: bold;
  }
.inline-info{
  width: 100%;
  height: 42px; 
}
</style>