
import {
  computed,
  defineComponent,
  toRef,
  ref,
  onMounted,
  nextTick,
} from "vue";
import echarts from "@/components/echart/base";
import line from "@/components/echart/line";
import { getUserLRCount } from "@/api/Index/platfrom";
import dayjs from "dayjs";
export default defineComponent({
  components: {
    "v-line": line,
  },
  props: {
    height: {
      type: String,
      default: () => "180px",
    },
    width: {
      type: String,
      default: () => "360px",
    },
    chartData: {
      type: Array,
    },
  },
  setup(props) {
    // const chartData = toRef(props, "chartData");
    const chartData = ref<any[]>([]);
    const lineRef = ref<any>("");
    const option = computed(() => {
      return {
        dataset: {
          source: chartData.value,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0",
          top: "30%",
          containLabel: true,
        },
        xAxis: { type: "category", boundaryGap: false },
        yAxis: {
          // type: "value",
          // z: 10,
        },
        series: [
          {
            name: "登陆",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 15,
          },
          {
            name: "注册",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              barBorderRadius: 5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#14c8d4" },
                { offset: 1, color: "#43eec6" },
              ]),
            },
          },
          {
            name: "差值",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(20,200,212,0.5)" },
                { offset: 0.2, color: "rgba(20,200,212,0.2)" },
                { offset: 1, color: "rgba(20,200,212,0)" },
              ]),
            },
            z: -12,
            tooltip: {
              show: false,
              // formatter: (e: any) => {
              //   console.log("e :>> ", e);
              // },
            },
          },
          {
            name: "dotted",
            type: "pictorialBar",
            symbol: "rect",
            itemStyle: {
              color: "#0f375f",
            },
            symbolRepeat: true,
            symbolSize: [12, 4],
            symbolMargin: 1,
            z: -10,
            tooltip: {
              show: false,
            },
          },
        ],
      };
    });
    const reView = async () => {
      await nextTick();
      lineRef.value.myResize();
    };
    const reData = async (time?: string) => {
      time = time ? time : dayjs().format("YYYY-MM");
      const res = await getUserLRCount({
        date: time,
        type: 3 - time.split("-").length,
      });
      chartData.value = res;
      reView();
      console.log("res :>> ", res);
    };
    const changeTime = (time: string) => {
      reData(time);
    };
    onMounted(() => {
      reData();
    });
    return {
      option,
      lineRef,
      changeTime,
      reData,
      reView,
    };
  },
});
