import { defHttp } from "@/utils/http/axios";
import { ListRes, RequestRes } from "../../types/requestBaseType"
import dayjs from "dayjs"
import { getTimeArr } from "../util";

export interface IncreaseLastAndCurrentOfDayAndMonth {
    "yesterday": number,
    "today": number,
    "lastMonth": number,
    "thisMonth": number
}
/**
 * 获取用户角色
 */
export async function increaseLastAndCurrentOfDayAndMonth(): Promise<IncreaseLastAndCurrentOfDayAndMonth> {
    const res = await defHttp.request<RequestRes<IncreaseLastAndCurrentOfDayAndMonth>>(
        {
            url: `/user/increaseLastAndCurrentOfDayAndMonth`,
            params: {
                token: true
            }
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
/**
 * 用户登陆统计
 */

interface UserRegCount {
    "dateStr": string,
    "cnt": number
}
interface UserRegCountRes {
    list: UserRegCount[]
}

interface UserRegCountReq {
    type: number
    date: string
}
export async function userRegCount(parmas: UserRegCountReq): Promise<UserRegCountRes> {
    const res = await defHttp.request<RequestRes<UserRegCountRes>>(
        {
            url: `/user/regCount`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
export async function userLoginCount(parmas: UserRegCountReq): Promise<UserRegCountRes> {
    const res = await defHttp.request<RequestRes<UserRegCountRes>>(
        {
            url: `/login/loginCount`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
interface SharedorderprofitIncreaseLastAndCurrentOfDayAndMonth {
    "yesterday": number,
    "today": number,
    "lastMonth": number,
    "thisMonth": number
}
export async function sharedorderprofitIncreaseLastAndCurrentOfDayAndMonth(productid?: string): Promise<SharedorderprofitIncreaseLastAndCurrentOfDayAndMonth> {
    const res = await defHttp.request<RequestRes<SharedorderprofitIncreaseLastAndCurrentOfDayAndMonth>>(
        {
            url: `/sharedorderprofit/increaseLastAndCurrentOfDayAndMonth`,
            params: {
                token: true,
                fortest: false,
                status: 1,
                productid: productid || "d194bd2a29ba48d094648a38a4d5896f"
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
interface SharedarticleArticleNumAndRatio {
    "containerNum": number,
    "articleSum": number,
    "ratio": number
}
export async function sharedarticleArticleNumAndRatio(model?: string): Promise<SharedarticleArticleNumAndRatio> {
    const res = await defHttp.request<RequestRes<SharedarticleArticleNumAndRatio>>(
        {
            url: `/sharedarticle/articleNumAndRatio`,
            params: {
                token: true,
                fortest: false,
                model
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
interface SharedarticleInvertOrderCount {
    "num": number,
    "sum": number,
    "ratio": number
}
export async function sharedarticleInvertOrderCount(productid?: string): Promise<SharedarticleInvertOrderCount> {
    const res = await defHttp.request<RequestRes<SharedarticleInvertOrderCount>>(
        {
            url: `/sharedarticle/invertOrderCount`,
            params: {
                token: true,
                fortest: false,
                productid: productid || "56c62a11a6a14d6eb7ab29fbf9f2f8b4"
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
export interface SharedarticleInvertOrderCountByStatus {
    numAndRatio: {
        status: '0' | '1' | '2' | "3",
        num: number,
        name: string,
        value: number,
        ratio: string,
    }[],
    countNum: {
        num: number
        ordernum: number
    }
}
const statusName = {
    '0': "已下单",
    '1': "已付款",
    '2': "已受理",
    '3': "已交付",
}
export async function sharedarticleInvertOrderCountByStatus(productid?: string): Promise<SharedarticleInvertOrderCountByStatus> {
    const res = await defHttp.request<RequestRes<SharedarticleInvertOrderCountByStatus>>(
        {
            url: `/sharedarticle/invertOrderCountByStatus`,
            params: {
                token: true,
                fortest: false,
                productid: productid || "56c62a11a6a14d6eb7ab29fbf9f2f8b4"
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    res.data.numAndRatio = res.data.numAndRatio.map(v => {
        v.name = statusName[v.status]
        // v.value = v.num
        return v
    })
    return res.data
}

export async function getUserLRCount(parmas: UserRegCountReq): Promise<any[][]> {
    const registList = await userRegCount(parmas)
    const loginList = await userLoginCount(parmas)
    const data: any = [["时间", "登陆", "注册"]]
    loginList.list.map(v => {
        const item = registList.list.find(_v => _v.dateStr == v.dateStr)
        data.push([v.dateStr, v.cnt, item?.cnt])
    })
    return data
}

interface SharedorderprofitGetProfitAndRatioGroupByRoleRes {
    list: SharedorderprofitGetProfitAndRatioGroupByRole[]
}

const role: any = {
    agent: '代理人',
    investor: '投资人',
    partner: '合伙人',
    platform: '平台',
    presuper: '上线',
    superior: '上上线',
}
interface SharedorderprofitGetProfitAndRatioGroupByRole {
    "gainerrole": typeof role,
    "num": number,
    "ratio": string
}


export async function sharedorderprofitGetProfitAndRatioGroupByRole({ productid, date }: { productid?: string, date: string }): Promise<SharedorderprofitGetProfitAndRatioGroupByRoleRes> {
    const res = await defHttp.request<RequestRes<SharedorderprofitGetProfitAndRatioGroupByRoleRes>>(
        {
            url: `/sharedorderprofit/getProfitAndRatioGroupByRole`,
            params: {
                token: true,
                fortest: false,
                status: 1,
                productid: productid || "d194bd2a29ba48d094648a38a4d5896f",
                date,
                type: 2
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res.data
}
interface ArticleDistributeOfLocationAndTypeRes {
    [key: string]: number
}
export interface ArticleDistributeOfLocationAndType {
    total: number
    list: {
        name: string
        value: number
    }[]
}


export async function articleDistributeOfLocationAndType({ productid, cityareacode }: { productid?: string, cityareacode?: string }): Promise<ArticleDistributeOfLocationAndType> {
    const res = await defHttp.request<RequestRes<ArticleDistributeOfLocationAndTypeRes>>(
        {
            url: `/sharedarticle/articleDistributeOfLocationAndType`,
            params: {
                token: true,
                productid: productid || "d194bd2a29ba48d094648a38a4d5896f",
                // productid: productid || "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
                cityareacode: cityareacode || "4301",
            },
        }, {
        prefix: "/analy"
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    const _data: any = res.data
    let total = 0
    const list: ArticleDistributeOfLocationAndType['list'] = []
    Object.keys(_data).map(v => {
        total += _data[v] as number
        list.push({ name: v, value: _data[v] })
    })
    return {
        total,
        list
    }
}
export async function getSharedorderprofitGetProfitAndRatioGroupByRole({ productid, date }: { productid?: string, date: string }): Promise<any[]> {
    const res1 = await sharedorderprofitGetProfitAndRatioGroupByRole({ productid, date })
    const res2 = await sharedorderprofitGetProfitAndRatioGroupByRole({ productid, date: dayjs(date).subtract(1).format("YYYY-MM") })
    const list: any[] = []
    res1.list.map((v, index) => {
        const lastM = res2.list[index].num
        list.push([role[v.gainerrole], v.num, lastM, (((v.num - lastM) / lastM) * 100).toFixed(2) + "%", index === 0])
    })
    return list
}

interface GetProfitGroupByRoleAndDateDayOfMonth {
    dimensions: string[]
    source: { [key: string]: number | string }[]
}
interface GetProfitGroupByRoleAndDateDayOfMonthRes {
    roleDateList: {
        gainerrole: string,
        profit: number,
        datestr: string,
        ordernum: number, // 订单的数据，组合在一起
        finishnum: number// 订单的数据，组合在一起
    }[]
}
interface GetNewOrderAndFinishOrderDayOfMonth {
    finishOrderList: {
        cnt: number
        dateStr: string
    }[]
    newOrderList: {
        cnt: number
        dateStr: string
    }[]
}
export async function getProfitGroupByRoleAndDateDayOfMonth({ date, productid }: { date: string, productid?: string }): Promise<GetProfitGroupByRoleAndDateDayOfMonth> {
    const res = await defHttp.request<RequestRes<GetProfitGroupByRoleAndDateDayOfMonthRes>>(
        {
            url: `/sharedorderprofit/getProfitGroupByRoleAndDateDayOfMonth`,
            params: {
                token: true,
                fortest: false,
                type: 3 - date.split("-").length,
                date,
                productid: productid || "56c62a11a6a14d6eb7ab29fbf9f2f8b4"
            },
        }, {
        prefix: "/analy"
    }
    );
    const orderRes = await defHttp.request<RequestRes<GetNewOrderAndFinishOrderDayOfMonth>>(
        {
            url: `/sharedordermemo/getNewOrderAndFinishOrderDayOfMonth`,
            params: {
                token: true,
                fortest: false,
                type: 3 - date.split("-").length,
                date,
                productid: productid || "56c62a11a6a14d6eb7ab29fbf9f2f8b4"
            },
        }, {
        prefix: "/analy"
    }
    );
    console.log('orderRes :>> ', orderRes);
    if (!res?.data || !orderRes?.data) throw Error("获取信息失败")
    let dimensions: string[] = []
    const source: { [key: string]: number | string }[] = []
    const str = 'time'
    getTimeArr({ str, month: date }).map(v => {
        const _a: any = {
            ...v,
            租赁订单: orderRes.data?.newOrderList.find(i => i.dateStr == v[str])?.cnt || 0,
            完结订单: orderRes.data?.finishOrderList.find(i => i.dateStr == v[str])?.cnt || 0,
        }
        Object.keys(role).map(v => {
            _a[role[v]] = 0
            dimensions = [role[v], ...dimensions]
        })
        res.data?.roleDateList.map(i => {
            if (i.datestr == v[str]) {
                _a[role[i.gainerrole]] = i.profit || 0
            }
        })
        source.push(_a)
    })
    dimensions = ["租赁订单", "完结订单", ...dimensions]
    source.sort((a, b) => dayjs(a.time).unix() - dayjs(b.time).unix())
    console.log('object :>> ', source, dimensions);
    return { source, dimensions: ['time', ...dimensions] }
    // return { source, dimensions: ['name'] }
}