<template>
  <el-dialog :destroy-on-close="true" :title="`入库操作`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData"   label-width="120px" ref="formRef">
     <el-form-item label="设备类型">
       <el-select placeholder="请选择设备类型" v-model="inputData.type " class="tip-text">
          <el-option label="单个设备" value='0'></el-option>
          <el-option label="包装箱" value='1'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告类型" v-if="inputData.type=='0'">
        <el-select  placeholder="请选择广告" v-model="inputData.adType">
          <el-option :key="item.adType" :label="item.name" :value="item.adType" v-for="item of adInfoList"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="来源" v-if="inputData.type=='0'">
        <el-select  placeholder="请选择来源" v-model="inputData.whOpType">
          <el-option :key="item.whOpType" :label="item.name" :value="item.whOpType" v-for="item of whOpTypeList"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="设备SN" class="tip-text" prop="sn" v-if="inputData.type=='0'">
        <el-input v-model="inputData.sn" style="width: 220px;" ></el-input>
      </el-form-item>
      <el-form-item label="包装箱SN" class="tip-text" prop="boxsn" v-if="inputData.type=='1'">
        <el-input v-model="inputData.boxsn" style="width: 220px;" ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { inBoxHttp, inXMJHttp } from '@/api/WarehouseManagement/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { getAdInfoList } from '@/api/util'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const adInfoList = getAdInfoList()

    const whOpTypeList = ref([
      {
        "whOpType":'0',
        "name":"生产"
      },
      {
        "whOpType":'1',
        "name":"维修"
      }
    ])

    const inputData = ref({
      sn: '',
      boxsn:'', 
      type:'0',  //0小魔夹 1包装箱
      adType:'0',//广告类型
      whOpType:'0',  //0生产  1维修
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = () => {
     
      visible.value = true
      console.log('新的确认')
      // inputData.value.day = Math.ceil(
      //   dayjs().diff(_row.receivedate, 'day', true)
      // )
    }
    const submit = async () => {
      try {
         btnSumitLoading.value = true
         const res =inputData.value.type=='0'? 
         await inXMJHttp({adType:inputData.value.adType,sn:inputData.value.sn})
         :await inBoxHttp(inputData.value.boxsn)
      
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      adInfoList,
      whOpTypeList,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>