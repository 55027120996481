import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router' //创建路由，创建页面历史记录，路由路线记录
import { scrollBehavior } from './scrollBehaviour' //页面滑动管理
import type { App } from 'vue';
import basicRoutes from "./modules/base"  //启动页面，默认是登录页面
import { routerStore } from "@/store/modules/router"  //路由控制控件
import Permission from "@/router/permission"  //路由权限，先检查是否登录，登录获取导航，跳转到主页，没有跳转到登录页面
// app router

//创建路由
const   router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: basicRoutes as RouteRecordRaw[],
  strict: true,
  scrollBehavior: scrollBehavior,
});

//清空路由
export function clearRouter() {
  router.getRoutes().forEach((route) => {
    const { name } = route;
    if (name) {
      router.hasRoute(name) && router.removeRoute(name);
    }
  });
}

//重置路由
export function resetRouter() {
  clearRouter()
  const routers = routerStore.getRouters
  console.log('routers 111:>> ', routers);
  routers.forEach((route) => {
    router.addRoute(route as RouteRecordRaw);
  });
}

//设置路由
export function setupRouter(app: App<Element>) {
  Permission(router)
  app.use(router);
}
export default router
