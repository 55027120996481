import router from "@/router"  //路由导航
import ED from "@/utils/base64"
export function toPath_RouterSearchMixin(url: string, params: any[]) { // 跳转到路由搜索页面，格式有两种[{type：Seach，name:张三},{name:张三}]
    const agm: any = {}
    params.map(v => {
        const type = Object.keys(v)[0].split('_')
        if (type[1]) {
            agm[`${type[0]}_${type[1]}`] = Object.values(v)[0]
        } else {
            agm[`${Object.keys(v)[0]}`] = Object.values(v)[0]
        }
    })
    router.push({
        path: url,
        query: agm
    })
}
export function toPath_queryEncode(url: string, params: any) {
    const agm: any = {}
    Object.keys(params).map(key => {
        agm[key] = ED.encode(params[key])
    })
    router.push({
        path: url,
        query: agm
    })
}