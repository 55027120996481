
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import exportDialog from "@/components/export/exportDialog.vue";
import { SharedorderdeductionList,sharedorderdeductionList,exportSharedOrderDeductionList} from '@/api/Order/duction'
import { getShareProductInfo } from '@/api/util'
export default defineComponent({
  name: 'shareorderdection',
  components: {
    exportDialog
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const exportDialogRef = ref<any>();
    const list = ref<SharedorderdeductionList[]>([])
    const deviceList = getShareProductInfo()
    const searchSelect = ref({
      search: 'articleequipmentsn',
    })
    const mixin_router_search = ref({
      fortest: false,
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {        
        articleequipmentsn: '',
        sharedorderid: '',
        userid: '',
        outorderno:'',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        sharedorderid: '订单号',
        userid: '用户ID',
        outorderno:'担保单号',
      },
    })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderdeductionList(rqData)
        list.value = res.records
        total.value = res.total
        // sharedorderrenewalList
        // sharedorderrene
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch,} =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleExport = async() => {
      exportDialogRef.value.open('扣款记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'duction'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        
        delete searchData.order
        delete searchData.sort
        delete searchData.fortest
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        
        rqData = { ...rqData, entity: {...entity,fortest:mixin_router_search.value.fortest} }
        console.log('导出参数=',rqData)
        const res = await exportSharedOrderDeductionList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      changSearch,
      onPageChange,
      deviceList,
      handleExport,
      exportDialogRef,
      exportFile,
    }
  },
})
