<template>
  <el-dialog :destroy-on-close="true" :title="`财务提现审核`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form class="table-expand" inline label-position="left" label-width="120px">
      <el-form-item label="支出钱包余额">
        <span>{{ expenditure }}</span>
        <el-button
          :class="reExpenditureLoading?'el-icon-loading':'el-icon-refresh-left'"
          @click="reExpenditureData"
          size="mini"
          type="text"
        ></el-button>
        <template v-if="row.amount>expenditure">
          <my-tip content="余额不足前往充值"></my-tip>
          <my-click-btn-to
            :params="[{fortest:row.fortest},{articleproductid:row.articleproductid}]"
            data="支出钱包充值"
            url="/financial/partfromWallet"
          ></my-click-btn-to>
        </template>
      </el-form-item>
      <el-form-item label="退款金额">
        <span>{{ row.amount}}元</span>
      </el-form-item>
      <el-form-item label="审核结果">
        <el-input :rows="2" placeholder="特殊情况输入，不必填" type="textarea" v-model="auditresult"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button :loading="btnSumitLoading" @click="submit(false)" type="danger">拒 绝</el-button>
        <el-button :loading="btnSumitLoading" @click="submit(true)" type="success">审核通过</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, inject } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  sharedorderrefundAudit,
  SharedorderrefundList,
} from '@/api/Order/Refund'
import { platformexpensewalletLoad } from '@/api/Financial/PartfromWallet'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const reExpenditureLoading = ref(false)
    const expenditure = ref(0)
    const auditresult = ref('')
    const row = ref<SharedorderrefundList>()
    const handleClose = () => {
      visible.value = false
    }
    const reExpenditureData = async () => {
      try {
        reExpenditureLoading.value = true
        expenditure.value = (
          await platformexpensewalletLoad({
            fortest: row.value?.fortest as boolean,
            articleproductid: row.value?.articleproductid as string,
          })
        ).amount
      } finally {
        reExpenditureLoading.value = false
      }
    }
    const open = (_row: SharedorderrefundList) => {
      visible.value = true
      row.value = _row
      reExpenditureData()
    }
    const toPath_RouterSearchMixin: any = inject('toPath_RouterSearchMixin')
    const submit = async (approved: boolean) => {
      if (row.value && approved && expenditure.value < row.value.amount) {
        await ElMessageBox.confirm(`支出钱包余额不足是否前往充值？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        })
        toPath_RouterSearchMixin("/financial/partfromWallet", [
          { fortest: row.value.fortest },
          { articleproductid: row.value.articleproductid },
        ])
        return
      }
      try {
        btnSumitLoading.value = true
        const res = await sharedorderrefundAudit({
          approved,
          auditresult: auditresult.value
            ? auditresult.value
            : approved
            ? '同意'
            : '不同意',
          refundid: row.value?.refundid as string,
        })
        ElMessage.success(res)
        emit('success')
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      submit,
      auditresult,
      reExpenditureLoading,
      reExpenditureData,
      expenditure,
      row,
    }
  },
})
</script>
<style lang = "scss" scoped >
.re-data-btn {
  margin-left: 24px;
}
</style>