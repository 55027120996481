
  import { defineComponent, ref, readonly, inject, onMounted } from 'vue'

  export default defineComponent({
    components: {

    },
    setup() {
      const activeName = ref("wechat")
      const searchValue = ref<any>("");
      const searchSelect = ref({ search: 'name' })
      const alipayRef = ref<any>("")
      const wechatRef = ref<any>("")
      const mixin_router_search = ref({
        timeBN:''
      });
      const mixin_select_search = ref({
        search: {   
        },
      })
      const mixin_select_search_label = readonly({
        search: {
        },
      })
  
      const handleClick = () => {
        // if(activeName.value=='alipay'){
        //   alipayRef.value.reData(searchValue.value)
        // }else{
        //   wechatRef.value.reData(searchValue.value)
        // }
      }
  
      const handelChange = async() => {
        // if(activeName.value=='alipay'){
        //   alipayRef.value.reData(searchValue.value)
        // }else{
        //   wechatRef.value.reData(searchValue.value)
        // }
      }
  
      const changSearch = () => {
      
      }
      onMounted(() => {
        // if(activeName.value=='alipay'){
        //   alipayRef.value.reData(searchValue.value)
        // }else{
        //   wechatRef.value.reData(searchValue.value)
        // }
      })
      return {
        searchSelect,
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,
        onMounted,
        activeName,
        searchValue,
        alipayRef,
        wechatRef,
        handleClick,
        handelChange,
        changSearch
      }
    }
  })
  