import { defHttp } from "@/utils/http/axios";
import { ListRes, ListReq, RequestRes } from "../../types/requestBaseType"
import dayjs from "dayjs"

export interface userconsumewalletwalletbillModel {
    id: string,
    gmtCreate: string,
    gmtModified: string,
    oldAmount: string,
    newAmount: string,
    amount: string,
    walletId: number,
    billType: number,
    remark: string,
    outOrderId: string,
    extendInfo: string
}

/**
 * 获取用户角色
 */
 export async function userconsumewalletwalletbill(params: ListReq): Promise<ListRes<userconsumewalletwalletbillModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<userconsumewalletwalletbillModel>>(
        {
            url: `/userconsumewalletbill/page`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss")
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}