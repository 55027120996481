
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  toRef,
} from 'vue'
import pie from '@/components/echart/pie'
import { deepCopy } from '@/utils'
let timer: any
let selectIndex = 0
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => '180px',
    },
    chartData: {
      type: Array,
      required: true,
    },
    chartData2: {
      type: Array,
      required: true,
    },
  },
  components: {
    'v-pie': pie,
  },
  emits: ['change-pie-select'],
  setup(props, { emit }) {
    const chartData = toRef(props, 'chartData')
    const chartData2 = toRef(props, 'chartData2')
    const option = computed(() => {
      return {
        dataset: {
          source: chartData.value,
        },
        tooltip: {
          trigger: 'item',
        },
        color: [
          'rgb(36,106,173)',
          '#1976D2',
          'rgb(17,46,98)',
          '#00F4F4',
          '#005674',
        ],
        series: [
          {
            type: 'pie',
            selectedMode: 'single',
            radius: '57%',
            selectedOffset: 4,
            center: ['50%', '50%'],
            labelLine: {
              show: false,
            },
            label: {
              show: true,
              fontSize: 10,
              position: 'inside',
            },
          },
          {
            type: 'pie',
            radius: ['72%', '85%'],
            animationDurationUpdate: 2000,
            label: {
              show: false,
            },
            color: ['#005772', '#00F4F4'],
            data: chartData2.value,
          },
        ],
      }
    })
    const muiltPieRef = ref<any>(null)
    const reView = async () => {
      await nextTick()
      console.log('muiltPsieRef :>> ', muiltPieRef.value)
      muiltPieRef.value.myRender()
    }

    const selectHandler = async (e: any, autoindex?: number) => {
      let _chartData: any[] = chartData.value
      const _index = e ? e.fromActionPayload.dataIndexInside + 1 : autoindex
      if (e?.fromActionPayload.seriesIndex === 0 || autoindex !== undefined) {
        if (_chartData[_index] && _chartData[_index][4] !== true) {
          _chartData = _chartData.map((v: any, index) => {
            // if (index > 0) v[4] = index === _index;
            if (index > 0) v[4] = index === _index
            if (index === _index) selectIndex = index
            return v
          })
          emit('change-pie-select', _chartData)
        }
      }
    }
    const events = {
      selectchanged: selectHandler,
    }
    const chartMouseout = () => {
      autoSelect()
    }
    const chartMouseover = () => {
      clearInterval(timer)
    }

    const autoSelect = () => {
      timer = setInterval(() => {
        if (selectIndex > chartData.value.length - 1) selectIndex = 1
        selectHandler(null, selectIndex)
        selectIndex++
      }, 3000)
    }
    const resetAutoSelect = () => {
      selectIndex = 1
      clearInterval(timer)
    }
    onMounted(() => {
      autoSelect()
    })
    onUnmounted(() => {
      resetAutoSelect()
    })

    return {
      option,
      muiltPieRef,
      reView,
      selectHandler,
      events,
      chartMouseover,
      chartMouseout,
    }
  },
})
