/**
 * 导航路由管理
 */
import { hotModuleUnregisterModule } from "@/utils/helper/vuexHelper"
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import type { AppRouteRecordRaw, AppRouteRecordRawChildren, AppRouteModule, netRotuer } from '@/router/types';
import { getLocalStorage, setLocalStorage } from '@/utils/localStore'
import commonMiddleRouterView from '@/layout/components/commonMiddleRouterView/index.vue' //中间路由的view
import Layout from '@/layout/index.vue'  //页面容器
import baseRouter from "@/router/modules/base"  //默认的路由，登录和默认
import endRouter from "@/router/modules/end"   //404找不到页面
import { deepCopy } from "@/utils"  //深度复制，递归复制工具
import store from "@/store"  //存储，用于创建存储
import { roletreeList } from '@/api/System'  //管理菜单树集合
import { userStore } from "./user";  //用户信息管理
import _addrouters from "@/router/modules/addrouters" //出入库和投资人管理
// const _router = _addrouters
const NAME = 'router';
let getgroupTimes = 1
hotModuleUnregisterModule(NAME);

// 菜单分组
function grouping(data: netRotuer[]): Promise<netRotuer[]> {
    const groupMenu: any = []
    console.log(data)
    let _data = deepCopy(data); // 深拷贝， 可以拷贝函数 
    return new Promise(resolve => {
        _data = _data.filter((v: netRotuer) => {
            if (v.parenttreeid == "0") {
                v.children = [];
                groupMenu.push(v);
            }
            return v.parenttreeid != "0";
        });
        getgroup(_data, groupMenu)
        resolve(groupMenu)
    })
}

/**
 * 获取群组里面的每个item
 * @param items 
 * @param groupMenu 
 */
function getgroup(items: netRotuer[], groupMenu: netRotuer[]) {
    groupOther(items, groupMenu)
    // console.log(`执行完${a}次items :>> `, items);
    if (getgroupTimes >= 10) {
        console.log('items, groupMenu :>> ', items, groupMenu);
        throw Error("路由没有遍历完全，给管理员哥哥说下，这里是个坑")
    }
    if (items.length > 0 && getgroupTimes < 8) {
        getgroupTimes++
        getgroup(items, groupMenu)
    }
}

/**
 * 
 * @param items 获取群组每个字item
 * @param groupMenu 
 */
function groupOther(items: netRotuer[], groupMenu: netRotuer[]) {
    for (const menuItem of groupMenu) {
        if (menuItem.children && menuItem.children.length > 0) {
            for (let i = 0; i < items.length; i++) {
                if (menuItem.treeid == items[i].parenttreeid) {
                    if (menuItem.children) {
                        menuItem.children.push(items[i])
                    } else {
                        menuItem.children = [items[i]]
                    }
                    items.splice(i, 1)
                }
            }
            groupOther(items, menuItem.children)
        } else {
            for (let i = 0; i < items.length; i++) {
                if (menuItem.treeid == items[i].parenttreeid) {
                    if (menuItem.children) {
                        menuItem.children.push(items[i])
                    } else {
                        menuItem.children = [items[i]]
                    }
                    items.splice(i, 1)
                }
            }
        }
    }
}

function getBaseRouterTemplate(item: netRotuer, o = {}) {
    const _item: AppRouteModule = { // 初始化
        path: item.treeurl,
        name: item.treecode,
        meta: {
            auth: true,
            title: item.treename
        },
        hidden: item.treehidden == "true" ? true : false,
        children: [],
        component: () => import(`@/views${item.treepath}.vue`)
    }
    if (item.treeicon && item.treeicon != "none") { // 存在才设置icon
        _item.meta.icon = item.treeicon
    }
    if (Object.keys(o).length > 0) {
        return Object.assign({}, _item, o)
    }
    return _item
}

function getParentBaseRouterTemplate(v: netRotuer & { affix?: boolean | undefined }) {
    return {
        path: v.treeurl,
        name: v.treecode,
        meta: {
            auth: true,
            icon: v.treeicon,
            title: v.treename,
            affix: v.affix
        },
        component: Layout,
        children: [],
    }
}

function getRouterTree(_group_menu: netRotuer[], _routers: AppRouteModule[]) {
    _group_menu.map((v, index) => {
        // 第一级菜单，没有子项的时候固定格式
        if (!v.children) return _routers.push(getBaseRouterTemplate(v)) // 最后一层
        if (v.parenttreeid == '0') {
            // 第一级菜单，没有只有一个子项的时候固定格式
            const _data = getParentBaseRouterTemplate(v)
            _data.meta.affix = true
            _routers.push(_data)
        }
        v.children.map((item, _index) => { // 遍历孙子项
            if (item.children && item.children.length > 0) { //有孙子的
                if (item.children.length == 1) { //只有一个孙子
                    getRouterTree(item.children, _routers[index].children)
                } else { //多个孙子的
                    _routers[index].meta.affix
                    _routers[index].children.push(getBaseRouterTemplate(item, {
                        component: commonMiddleRouterView,
                        children: [],
                    }))
                    getRouterTree(item.children, _routers[index].children[_index].children)
                }
            } else { // 没有孙子的
                _routers[index].children.push(getBaseRouterTemplate(item))
            }
        });
    });
}


function eidtRouterIcon(_router: AppRouteModule[], icon = '') {
    for (let i = 0; i < _router.length; i++) {
        if (_router[i].children && _router[i].children.length == 1) {
            eidtRouterIcon(_router[i].children, _router[i].meta.icon)
        } else {
            if (icon && (!_router[i].children || _router[i].children.length == 0)) {
                _router[i].meta.icon = icon
                if (!_router[i].name) _router[i].name = _router[i].path
            }
        }
    }
}

@Module({ namespaced: true, name: NAME, dynamic: true, store })
class Router extends VuexModule {
    public routers: AppRouteRecordRaw[] = baseRouter
    public addRouters: AppRouteRecordRaw[] = []


    /**
     * 获取默认路由，有默认页和登录
     */
    public get getRouters(): AppRouteRecordRaw[] {
        return this.routers
    }

    /**
     * 获取空的导航路由
     */
    public get getAddRouters(): AppRouteRecordRaw[] {
        return this.addRouters
    }


    /**
     * 清空路由，数组是默认的，添加的只是一个数组
     */
    @Mutation
    clearStore() {
        this.routers = baseRouter
        this.addRouters = []
    }


    /**
     * 
     * @param _routers 设置路由实现
     */
    @Mutation
    SET_ROUTERS(_routers: AppRouteRecordRaw[]) {
        this.addRouters = _routers;
        this.routers = [...baseRouter, ..._routers];
        setLocalStorage('routers', this.routers)
    }

    /**
     * 
     * @returns 同步获取路由
     */
    @Action
    async syncRouters(): Promise<AppRouteRecordRaw[]> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                if (!userStore.getCurrentRole.roleid) return reject("must be roleid")
                const roleMenu = await roletreeList({ roleid: userStore.getCurrentRole.roleid, appid: 2 })
                //const roleMenu = await roletreeList({ roleid: "10", appid: 2 })
                const _groupMenu = await grouping(roleMenu)
                console.log('_groupMenu :>> ', _groupMenu);
                const _routers: AppRouteModule[] = []
                getRouterTree(_groupMenu, _routers) // 整理好的路由整理为路由树
                eidtRouterIcon(_routers as AppRouteModule[]) // 只有一项，子项是需要继承上级的icon
                // console.log('_routers :>> ', _routers);
                // 获取路由
                this.SET_ROUTERS([..._routers, ...endRouter])
                // this.SET_ROUTERS([..._addrouters, ...endRouter])
                resolve(_routers)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export const routerStore = getModule<Router>(Router);