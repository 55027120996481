<template>
  <el-dialog :destroy-on-close="true" :title="`4G下发消息`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="80vw">
    <header>
      <el-input @keyup.enter="reData(true)" class="input-with-select" placeholder="请输入搜索内容" v-model="deviceSearch">
        <template #prepend>
          <el-select placeholder="请选择" v-model="deviceSelect">
            <el-option label="MAC" value="phoneholdermac"></el-option>
            <el-option label="SN" value="phoneholdersn"></el-option>
            <el-option label="errno" value="errno"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="reData(true)" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-date-picker
        @change="reData(true)"
        align="right"
        end-placeholder="结束日期"
        range-separator="至"
        start-placeholder="开始日期"
        type="datetimerange"
        v-model="date"
      ></el-date-picker>
    </header>
    <el-table
      :data="list"
      :default-sort="{prop: 'datatimestamp', order: 'descending'}"
      @filter-change="filterChange"
      @sort-change="sortChange"
      height="560px"
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template #default="props">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="commandid">{{props.row.commandid}}</el-form-item>
            <el-form-item label="cmduuid">{{props.row.cmduuid}}</el-form-item>
            <el-form-item label="cmdresp">{{props.row.cmdresp}}</el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="center" label="deviceid" min-width="20" prop="deviceid"></el-table-column>
      <el-table-column align="center" label="发送包" min-width="60" prop="body"></el-table-column>
      <el-table-column align="center" label="errno - error" min-width="20">
        <template #default="scope">{{scope.row.errno}} - {{scope.row.error}}</template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" label="发起时间" min-width="20" prop="creationtime" sortable="custom"></el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="reData()"
      @size-change="reData(true)"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref } from 'vue'
import { fourthgcommandList } from '@/api/Share/Device'
let datastream: any[] = [],
  order = 'desc',
  sort = 'creationtime',
  deviceid = ''
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const deviceSelect = ref('phoneholdersn')
    const deviceSearch = ref('')
    const date = ref('')
    const list = ref<any[]>([])
    const visible = ref(false)
    const page = ref(1)
    const total = ref(0)
    const limit = ref(10)
    const loading = ref(false)
    const btnSumitLoading = ref(false)
    const handleClose = () => {
      visible.value = false
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    }

    const reData = async (re?: boolean) => {
      try {
        if (re) page.value = 1
        loading.value = true
        const rqData: any = {
          page: page.value,
          limit: limit.value,
          deviceid,
          sort,
          order,
        }
        if (datastream.length > 0) rqData.datastream = datastream.join(',')
        if (deviceSelect.value && deviceSearch.value)
          rqData[deviceSelect.value] = deviceSearch.value
        if (date.value.length > 0) {
          rqData.datatimestampbegin = new Date(rqData.date[0]).getTime()
          rqData.datatimestampend = new Date(rqData.date[1]).getTime()
        }
        let { totalCount, result } = await fourthgcommandList(rqData)
        total.value = totalCount
        list.value = result
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    const open = (_deviceid: string) => {
      visible.value = true
      deviceid = _deviceid
      reData()
    }
    const filterChange = (e: any) => {
      datastream = e.datastream
      reData(true)
    }
    const sortChange = (e: {
      order: 'ascending' | 'descending' | undefined
      prop: string
    }) => {
      switch (e.order) {
        case 'ascending':
          order = 'asc'
          break
        case 'descending':
          order = 'desc'
          break
      }
      reData(true)
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      deviceSelect,
      deviceSearch,
      date,
      list,
      reData,
      filterChange,
      sortChange,
    }
  },
})
</script>
<style lang = "scss" scoped >
header {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  > * {
    margin-right: 16px;
  }
  .el-input {
    width: 320px;
  }
  .el-select {
    width: 130px;
  }
}
</style>