<template>
  <div class="tags-warp" v-if="route.path!='/home'">
    <el-tag v-for="(tag,index) of tagsView" :key="tag.path" :type="route.path==tag.path?'primary':'info'" @click="handleClick(tag)" :closable="route.path==tag.path&&tag.path!='/home'" @close="handleClose(index)">
      {{tag.meta.title}}
    </el-tag>
  </div>
</template>
<script lang="ts">
import { AppRouteRecordRawChildren } from "@/router/types";
import { TagsViewStore, TagsViewType } from "@/store/modules/tagsView";
import { computed, defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "TagsView",
  setup() {
    const initTag = () => {
      if (route.path == "/home") return TagsViewStore.initTages();
      TagsViewStore.initTages({
        path: route.path,
        meta: route.meta as any,
        name: route.name as string,
      });
    };
    const tagsView = computed(() => {
      return TagsViewStore.getTagsView;
    });
    const handleClose = (index: number) => {
      if (route.path == tagsView.value[index].path) {
        router.push(tagsView.value[index - 1].path);
      }
      TagsViewStore.delTage(index);
    };
    const handleClick = (tag: TagsViewType) => {
      if (tag.path == route.path) return;
      router.push(tag.path);
    };
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      initTag();
    });
    return {
      tagsView,
      route,
      handleClose,
      handleClick,
    };
  },
});
</script>
<style lang="scss" scoped>
.tags-warp {
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  min-height: 50px;
  padding: 5px 12px;
  border: 1px solid #efefef;
  // border-radius: 4px;
  overflow-x: scroll;
  white-space:nowrap;
  // overflow: visible;
  &::-webkit-scrollbar {
    color: #efefef;
  }
  .el-tag {
    margin-right: 12px;
  }
}
</style>