
import { defineComponent, ref, readonly, inject, onMounted } from 'vue'
import AlipayfundTransfer from './components/alipayfundTransfer.vue'
import WechatMerchantTransfer from './components/wechatMerchantTransfer.vue'
export default defineComponent({
  components: {
    AlipayfundTransfer,
    WechatMerchantTransfer
  },
  setup() {
    const activeName = ref("alipay")
    const searchValue = ref<any>("");
    const searchSelect = ref({ search: 'name' })
    const alipayRef = ref<any>("")
    const wechatRef = ref<any>("")
    const mixin_select_search = ref({
      search: {
        name: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
      },
    })

    const handleClick = () => {
      if(activeName.value=='alipay'){
        alipayRef.value.reData(searchValue.value)
      }else{
        wechatRef.value.reData(searchValue.value)
      }
    }

    const handelChange = async() => {
      if(activeName.value=='alipay'){
        alipayRef.value.reData(searchValue.value)
      }else{
        wechatRef.value.reData(searchValue.value)
      }
    }

    const changSearch = () => {
    
    }
    onMounted(() => {
      if(activeName.value=='alipay'){
        alipayRef.value.reData(searchValue.value)
      }else{
        wechatRef.value.reData(searchValue.value)
      }
    })
    return {
      searchSelect,
      mixin_select_search,
      mixin_select_search_label,
      onMounted,
      activeName,
      searchValue,
      alipayRef,
      wechatRef,
      handleClick,
      handelChange,
      changSearch
    }
  }
})
