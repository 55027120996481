import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
import { userStore } from "@/store/modules/user";
export interface SharedorderrefundList {
    "amount": number, //申请退款的金额
    "approved": boolean, //财务是否批准通过，true 通过 false 不通过
    "articleequipmentsn": string, //退款的小魔夹SN
    "articleproductid": string, //产品编号
    "articleproductmodel": string, //产品型号
    "articleproductname": string,//产品名称
    "articleproducttype": string, //产品类型
    "audited": boolean,  //财务是否审核过，true 已审核过 false 未审核过
    "auditoraccount": string, //财务审核员用户账号，如账务经理用户账号
    "auditoruserid": string, //财务审核员用户编码，如账务经理用户编码
    "auditresult": string, //财务审核结果描述
    closed: boolean,  //退款申请是否关闭，如：true 是 false 否
    "creationdate": string, //创建时间
    "fortest": boolean, //是否用于测试，true 是 false 否
    "modificationdate": string, //更新时间
    "reason": string,//申请退款的理由
    "receiveraccount": string,//接收人用户账号
    "receiveruserid": string,//接收人用户编码
    "refunddate": string, //退款类型 0:共享订单退款 1:押金退款
    "refunded": boolean, //是否已退款，如：true 是 false 否
    "refundid": string, //退款编码
    "sharedorderid": string, //订单编码
    "submitteraccount": string, //退款申请的提交人用户账号，如订单租赁用户账号，或平台售后用户账号
    "submitteruserid": string, //退款申请的提交人用户编码，如订单租赁用户编码，或平台售后用户编码
    "refundType":number, //退款类型 0:共享订单退款 1:押金退款
    masterAuditresult: string, //masterAuditresult
    masterAudited: boolean, //主管是否审核过，true 已审核过 false 未审核过
    masterUserId: string, //审核主管用户ID
    masterApproved: boolean //主管 是否批准通过，true 通过 false 不通过
    auditedStep:number //审核步奏
}

export async function sharedorderrefundList(params: ListReq): Promise<ListRes<SharedorderrefundList>> {
    params.token = true
    const res = await defHttp.request<ListRes<SharedorderrefundList>>(
        {
            url: `/sharedorderrefund/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.refunddate = dayjs(v.refunddate).format("YYYY-MM-DD HH:mm:ss")
        if(v.masterAudited){
            if(v.audited){
                v.auditedStep=3
            }else{
                v.auditedStep=1
            }
        }else{
            if(v.closed){
                v.auditedStep=3
            }else{
                v.auditedStep=0
            }
        }
        return v
    })
    return res
}
interface SharedorderrefundAuditReq {
    approved: boolean
    auditresult: string
    refundid: string
}
export async function sharedorderrefundAudit(params: SharedorderrefundAuditReq): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderrefund/audit`,
            params: {
                token: true,
                auditoruserid: userStore.userInfo.userid,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}
export async function sharedorderMasterRefundAudit(params: any): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderrefund/masteraudit`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return res.msg
}
export async function sharedorderrefundRefund(refundid: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedorderrefund/refund`,
            params: {
                token: true,
                refundid
            }
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message || "操作成功"
}