
import { defineComponent, ref, readonly, inject } from 'vue'
import { WhboxModel, whboxList ,takeBoxInHttp} from '@/api/WarehouseManagement/whbox'
import routerChangeReData from '@/common/routerChangeReData'
import addBoxDialog from './components/addBoxDialog.vue'
import boxDetailsDrawer from './components/boxDetailsDrawer.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'investment-order',
  components: {
   addBoxDialog,
   boxDetailsDrawer,
  },
  setup() {
    const addDialogRef = ref<any>()
    const boxDetailsDrewerRef = ref<any>()

    const mixin_router_search = ref({
      status: '', //状态 0:待入库 1：已入库 2：已出库
      careateBN:'',
      order: 'gmt_create',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        sn:'', //投资人手机号
        in_user_account:'',//投资人姓名       
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        sn: '编号', // 投资人用户编号
        in_user_account: '出库账号', //投资订单编码
      },
    })
    const searchSelect = ref({search:"sn"})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<WhboxModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
           if (rq.careateBN) {
            rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await whboxList(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleAdd = () => {
      addDialogRef.value.open()
    }

    const handleTakeBoxDetail = (item:any) =>{
      boxDetailsDrewerRef.value.open(item)
    }
    
    const handleRemove = async(idx:number,boxSn:string) =>{

    }

    const handleTakeBoxIn = async(item:any,boxSn:string) =>{
          //   reDeductionLoading.value = true
          if(item.curCount==0){
             ElMessage.error("空包装箱，不能入库！！！")
            return;
          }
        await ElMessageBox.confirm(
          `是否将包装箱`+boxSn+'归入仓库,当前装箱设备'+item.curCount+',最大装箱设备为'+item.maxCount,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await takeBoxInHttp(boxSn)
        mixinReRouter()
        ElMessage.success(res)
    }
   
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      changSearch,
      list,
      loading,
      addDialogRef,
      boxDetailsDrewerRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      handleAdd,
      handleRemove,
      handleTakeBoxIn,
      handleTakeBoxDetail,
    }
  },
})
