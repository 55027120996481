
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  userwithdraworderList,
  userwithdraworderRemove,
} from "@/api/Financial/transfer";
import catchwithDialog from "./components/catchwithDialog.vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
  name: "v-transfer",
  components: {
    "v-catchwith-dialog": catchwithDialog,
  },
  setup() {
    const loading = ref(false);
    const rmLoading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const catchwithDialogRef = ref<any>("");
    const list = ref<any[]>([]);
    const walletType = readonly({
      YS: "雨伞",
      MJ: "小魔夹",
    });
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      transfertype: "",
      audited: "",
      approved: "",
      articleproductmodel: "",
      finished: false,
    });
    const mixin_select_search = ref({
      search: {
        payeerealname: "",
        account: "",
        userid: "",
        withdraworderid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        payeerealname: "昵称",
        account: "账号",
        userid: "用户ID",
        withdraworderid: "提现ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, entity: rq };
        }
        const res = await userwithdraworderList(rqData);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleCatchwith = (row: any) => {
      catchwithDialogRef.value.open(row);
    };
    const handleRemove = async (index: number) => {
      try {
        const item = list.value[index];
        await ElMessageBox.confirm(
          `确定删除${item.payeerealname}的提现申请吗？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        rmLoading.value = true;
        const res = await userwithdraworderRemove(item.withdraworderid);
        list.value.splice(index, 1);
        ElMessage.success(res || "移除成功");
      } finally {
        rmLoading.value = false;
      }
    };

    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      walletType,
      changSearch,
      handleCatchwith,
      catchwithDialogRef,
      mixinReRouter,
      handleRemove,
      rmLoading,
      toPath_RouterSearchMixin,
    };
  },
});
