
/**
 * @description 导出默认通用配置
 */
const setting = {
  // 开发以及部署时的URL
  publicPath: '',
  // 生产环境构建文件的目录名
  outputDir: 'dist',
  // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
  assetsDir: 'static',
  // 开发环境每次保存时是否输出为eslint编译警告
  lintOnSave: true,
  // 进行编译的依赖
  transpileDependencies: ['vue-echarts', 'resize-detector'],
  //标题 （包括初次加载雪花屏的标题 页面的标题 浏览器的标题）
  title: 'XMJ_Admin',
  //简写
  abbreviation: 'Admin',
  //开发环境端口号
  devPort: '8881',
  //是否是开发模式
  isDev: () => process.env.NODE_ENV === 'development',
  //版本号
  version: process.env.VUE_APP_VERSION,
  //是否显示顶部进度条
  progressBar: true,
  //缓存路由的最大数量
  keepAliveMaxNum: 99,
  // 路由模式，可选值为 history 或 hash
  routerMode: 'history',
  //不经过token校验的路由
  routesWhiteList: ['/userlogin', '/register', '/404', '/401'],
  //加载时显示文字
  loadingText: '正在加载中...',
  //token名称
  tokenName: 'accessToken',
  //token在localStorage、sessionStorage存储的key的名称
  tokenTableName: 'xmj_admin',
  //token存储位置localStorage sessionStorage
  storage: 'localStorage',
  //token失效回退到登录页时是否记录本次的路由
  recordRoute: true,
  //是否显示logo，不显示时设置false，显示时请填写remixIcon图标名称，暂时只支持设置remixIcon
  logo: 'icon_xmj',
  //vertical布局时是否只保持一个子菜单的展开
  uniqueOpened: true,
  //vertical布局时默认展开的菜单path，使用逗号隔开建议只展开一个
  defaultOopeneds: ['/index'],
  //需要加loading层的请求，防止重复提交
  debounce: ['doEdit'],
  //需要自动注入并加载的模块
  providePlugin: {
    maptalks: 'maptalks',
    'window.maptalks': 'maptalks'
  },
  //npm run build时是否自动生成7z压缩包
  build7z: true,

  changeMenuPassward:'admin123'
}

module.exports = setting