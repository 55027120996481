
import { defineComponent, nextTick, readonly, ref } from "vue";
import { generateNewSn } from '@/api/Share/Device'
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import {getProductInfoList,getProductIdList} from '@/api/util'
export default defineComponent({
  name: "v-dialog",
  emits:['success'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const inputData = ref({
      productid: "", 
      number: "", 
      initble: "", 
      init4g: "", 
    });
    const rules = readonly({
      productid: [Rules.required()],
      quantity: [Rules.required()],
      initble: [Rules.required()],
      init4g: [Rules.required()],
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
    
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
     
    };
    const open = () => {
      visible.value = true;
      
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        console.log('生成参数=',inputData.value)
        const res = await generateNewSn(inputData.value)
        console.log('返回=',res)
        handleClose();
        ElMessage.success(res.message);
        
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      productList,
      productIdList,
    };
  },
});
