<template>
  <el-dialog :destroy-on-close="true" :title="isAdd ? '添加' : '修改'+`运营员角色`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData" :rules="rules" label-width="100px" ref="formRef">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="inputData.name"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input v-model="inputData.account"></el-input>
      </el-form-item>
      <el-form-item label="团队" prop="operationid">
        <el-select v-model="inputData.operationid" placeholder="请选择运营团队">
          <el-option
            v-for="item in operateList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>    
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, toRef } from "vue";
import { activateOperator,upDateOperator } from "@/api/User/role";
import { operateModel, getOperateListHttp } from "@/api/Operate/index"
import Rules from "@/common/Rules";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  props: ["type"],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
        operationid: [Rules.required()],
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const isAdd = ref(true);
    const operateList = ref<operateModel[]>([])
    const type = toRef(props, "type");
    const formRef =ref<any>("");
    const inputData = ref({
      account: "",
      name: "",
      operationid :"",
    });
    const handleClose = () => {
      console.log('点击关闭')
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };
    const open = (add:boolean,item:any,operationid:any) => {
     // console.log('类型',typeof(item.operationId))
      visible.value = true;
      isAdd.value=add;
      inputData.value.account=""
      inputData.value.name=""
      inputData.value.operationid=""
     
      reData(add,item,operationid);
    };
    const reData = async (add:boolean,item:any,operationid:any) => {
      const res = await getOperateListHttp()     
      operateList.value = res.data     
      // if(operationid){
      //   inputData.value.operationid=operationid
      // }
      // operateList.value.filter(items=>{
      //   if(items.id.toString().includes(operationid)){
      //     inputData.value.operationid=item.id.toString()
      //     return true;
      //   }
      // })
      // operateList.value.forEach(item=>{  //遍历数组,只要包含关健字的,就把这个对象存起来到新一个数组
      //   if(item.id.indexOf(operationid) !=-1){
          
      //   }
      // })
      // operateList.value.some((item,i)=>{
      //   if(item.id == parseInt(operationid)){
      //     inputData.value.operationid=item.id.toString()
      //     return true;  //返回true会停止掉本次的循环
      //   }
      // })
      if(!add){
        inputData.value.account=item.account
        inputData.value.name=item.name
        inputData.value.operationid=item.operationId      
      }
    }

    const submit = async () => {
      try {
        try {
          await formRef.value.validate();
        } catch (error) {
          console.log(error);
          throw Error("请检查输入");
        }
        btnSumitLoading.value = true;
        const result = isAdd.value ? await activateOperator(inputData.value, type.value) : await upDateOperator(inputData.value);
        ElMessage.success(result);
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      isAdd,
      operateList,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      formRef,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>