<template>
  <el-dialog :destroy-on-close="true" :title="`替换设备二维码`" @closed="handleClose" align="center" class="dialog"
    v-model="visible" width="750px">
      <el-alert
        title="更换硬件选择操作：选择不更换，硬件寿命不重置，共享天数不重置；选择更换，硬件寿命重置，共享天数不重置"
        type="warning"
        :closable="false"
        show-icon>
      </el-alert>
      <el-alert
        title="新的设备SN，必须是没有入库的才能更新"
        type="warning"
        :closable="false"
        show-icon>
      </el-alert>
    <el-form :model="inputData" label-width="120px" ref="formRef" :rules="rules">
      <el-form-item label="旧设备SN" class="tip-text" prop="inEquipmentSn">
        {{row.inEquipmentSn}}
      </el-form-item>
      <el-form-item label="新设备SN" class="tip-text" prop="newSn" >
        <el-input v-model="inputData.newSn" style="width: 220px;">
        </el-input>
      </el-form-item>
      <el-form-item label="替换原因" class="tip-text" prop="replaceReason" >
        <el-select placeholder="请选择替换的原因" v-model="inputData.replaceReason">
          <el-option value="1" label="维修"></el-option>
          <el-option value="2" label="丢失"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="更换硬件" class="tip-text" prop="newEquipment" >
        <el-select placeholder="请选择是否更换硬件" v-model="inputData.newEquipment">
          <el-option :value="false" label="未更换"></el-option>
          <el-option :value="true" label="要更换"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { replaceSnHttp, repairListModel } from '@/api/fault/repair'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, readonly } from 'vue'
import { userStore } from "@/store/modules/user";
import Rules from "@/common/Rules";
let overdueId = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {

    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<repairListModel>()
    const headerObj = ref<any>({})
    headerObj.value = { Authorization: userStore.info.token };

    const inputData = ref({
      newSn: "",
      newEquipment:false,
      replaceReason:"1"
    })
    const rules = readonly({
      newSn: [Rules.required()]
    });
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:repairListModel) => {
      visible.value = true
      overdueId=item.id;
      row.value=item;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        const res = await replaceSnHttp({
          id: overdueId,
          newEquipment: inputData.value.newEquipment,
          newSn:inputData.value.newSn,
          replaceReason:inputData.value.replaceReason
        })

        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }


    return {
      rules,
      headerObj,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      row,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>