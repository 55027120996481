
import { defineComponent, toRef } from "vue";
import { SharedorderprivatizationdeductionList } from "@/api/Order/Privatization";
export default defineComponent({
  name: "negotiation-info",
  props: ["info"],
  setup(props) {
    const info: SharedorderprivatizationdeductionList = toRef(props, "info");
    return {
      syncInfo: info,
    };
  },
});
