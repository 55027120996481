import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface UserwithdraworderListRes {
    creationdate: string
    finishdate: string
    applydate: string
    transferdate: string
    articleproductid: string
    articleproductmodel: "M4S2" | 'UM01'
    articleproductname: string
    amount: number
    userid: string
    withdraworderid: string
}
export async function userwithdraworderList(params: ListReq): Promise<ListRes<UserwithdraworderListRes>> {
    console.log('params :>> ', params);
    params.token = true
    const res = await defHttp.request<ListRes<UserwithdraworderListRes>>(
        {
            url: `/userwithdraworder/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.finishdate = dayjs(v.finishdate).format("YYYY-MM-DD HH:mm:ss")
        v.applydate = dayjs(v.applydate).format("YYYY-MM-DD HH:mm:ss")
        v.transferdate = dayjs(v.transferdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}
// export interface UserconsumewalletRes {

// }

interface UserwithdraworderAuditReq {
    approved: boolean
    auditoruserid: string
    auditresult: string
    withdraworderid: string
    token?: any
}
export async function userwithdraworderAudit(params: UserwithdraworderAuditReq): Promise<string | undefined> {
    params.token = true
    const res = await defHttp.request<RequestRes>(
        {
            url: `/userwithdraworder/audit`,
            params
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    if (res.code != 200) throw Error(res.message || '审核请求发送失败')
    return res.message
}

export async function userwithdraworderRemove(withdraworderid: string): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/userwithdraworder/remove`,
            params: {
                token: true,
                withdraworderid
            }
        }, {
        prefix: "/shared",
    }
    );
    if (res.code != 200) throw Error(res.message || '审核请求发送失败')
    return res.message
}


