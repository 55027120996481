<template>
  <div class="container">
   
    <el-card>
      <!-- <el-tabs >
         <el-tab-pane label="个人信息">
           <component :is="UserDetailsComponent" :userid="userid" />
         </el-tab-pane>
         <el-tab-pane label="钱包信息">
           <component :is="UserWalletComponent" :userid="userid" />
         </el-tab-pane>
      </el-tabs> -->
      <UserDetails :userid="userid"></UserDetails>
      <div style="width: 80%; border-top:2px solid #DCDFE6;margin-top: -50px; margin-left: 48px; margin-bottom: 48px;" ></div>
      <UserWallet :userid="userid" :vip="vip"></UserWallet>
    </el-card>       
   
  </div>
</template>

<script>
import UserInfo from "@/components/userInfoCard/index.vue"
import UserDetails from "./components/userDetails.vue"
import UserWallet from "./components/userWallet.vue"
import { defineComponent } from "vue"
import {useRoute} from "vue-router"
export default defineComponent({
  components: {
    UserInfo,
    UserDetails,
    UserWallet
  },
  data() {
    return {
      UserDetailsComponent: 'user-details',
      UserWalletComponent: 'user-wallet'
    }
  },
  setup(){
    const route = useRoute()
    console.log('顺带信息=',route.query)
    console.log('')
    return{
      userid:route.query.userid,
      vip:route.query.vip
    }
  }
})
// <v-user-info :userid="userid"></v-user-info>
</script>

<style lang="scss" scoped>
.container {
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
}
</style>