import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface investorProfitOrderModel {
    "sharedorderid": string, //订单id   
    "articleequipmentsn": string, //小魔夹SN
    "areaname": string, //租借城市
    "nickname": string, //租借人昵称
    "receivedate": string, //租借时间
    "deliverdate": string, //归还时间
    "amount": string, //收益
    "receiveaddress": string, //租借地址
    "proportion": string, //收益比
    
}

/**
 * 
 * @param params 查询投资人的收益账单
 * @returns 
 */
export async function investorProfitOrderListHttp(params: ListReq): Promise<{
    records: investorProfitOrderModel[];
    total: number;
}> {
    params.token = true
    const res = await defHttp.request<ListRes<investorProfitOrderModel>>(
        {
            url: `/Investment/getAllProfitOrderOfInvestmentor`,
            params
        }, 
        {
            prefix: "/analy",
            contentType: 'json'
        }
    );

    // res.rows = res.rows.map(v => {    
    //     v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
    //     v.gmtIn =v.gmtIn?dayjs(v.gmtIn).format("YYYY-MM-DD HH:mm:ss"):"暂无";
    //     v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
    //     v.gmtOut = v.gmtOut?dayjs(v.gmtOut).format("YYYY-MM-DD HH:mm:ss"):"暂无";
    //     return v
    // })
    return res.data
}
