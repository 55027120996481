<!-- 排行类表 -->
<template>
  <div class="container" v-if="listData.total">
    <!-- <div class="item" v-for="(item,index) of Array.prototype.reverse.call(list) " :key="item.name"> -->
    <div class="list-warp">
      <div :key="item.name" class="item" v-for="(item,index) of  listData.list">
        <el-tooltip :content="`● ${item.name}: ${item.value}`" placement="right-end">
          <div class="text">
            <div class="left">NO.{{index+1}}</div>
            <div class="center">{{item.name}}</div>
            <div class="imgwarp">
              <img alt src="../../../../assets/img/index/first.png" v-if="index==0" />
              <img alt src="../../../../assets/img/index/second.png" v-if="index==1" />
              <img alt src="../../../../assets/img/index/third.png" v-if="index==2" />
            </div>
          </div>
        </el-tooltip>
        <el-progress
          :color="customColors"
          :percentage="parseInt((item.value/listData.total).toFixed(2)*100)"
          :show-text="false"
          :stroke-width="6"
        ></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["listData"],
  data() {
    return {
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  }
};
</script>
<style lang='scss' scoped>
.container {
  // width: 160px;
  font-size: 12px;
  color: #1397ff;
  max-width: 200px;
  margin: 0 auto;
  height: calc(100% - 50px);
  overflow: hidden;
  .list-warp {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .text {
      margin-top: 20px;
      // font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      .left {
        width: 30px;
      }
      .center {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        text-align: center;
      }
      .imgwarp {
        width: 40px;
        height: 0;
        padding-bottom: 12%;
        img {
          width: 100%;
        }
      }
    }
    .el-progress {
      margin-top: 10px;
    }
  }
}
</style>