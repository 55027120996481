<template>
    <el-dialog :destroy-on-close="true" :title="`${name}的采购单`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="70%">
      <el-alert
        title="新增采购单，下单数量建议下10，20，50整数，不建议一次性下太大数量，可以下多个采购单"
        type="warning"
        show-icon
        style="margin-bottom: 16px;"
        :closable="false">
      </el-alert>
       <div class="container">
        <header>
        <el-select @change="handelChange" class="header-select" clearable placeholder="采购单状态" v-model="status">
          <el-option label="待受理" value="0"></el-option>
          <el-option label="已受理" value="1"></el-option>
          <el-option label="待接收" value="2"></el-option>
          <el-option label="已完结" value="3"></el-option>
        </el-select>
         <el-button @click="handleAddNewPurchase" icon="el-icon-plus" type="primary" style="margin-left:24px;">新增采购单</el-button>

         <el-button  @click="handlerOldShipment" icon="el-icon-info" type="primary" style="margin-left:24px;">旧出货单</el-button>
         <!-- <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">创建无投资出货单</el-button> -->
       </header>
    
       <el-table :data="list" :loading="loading" height="55vh" style="width: 100%" v-if="list">
         <el-table-column type="expand">
           <template #default="scope">
             <el-form class="table-expand" inline label-position="left" label-width="120px">
               
               <el-form-item label="投资订单id">
                 <span>{{ scope.row.investmentOrderId }}</span>
                 <my-copy :data="scope.row.investmentOrderId"></my-copy>
               </el-form-item>
             </el-form>
           </template>
         </el-table-column>
         <el-table-column label="Id" width="90" align="center">
           <template #default="scope">
             <!-- <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
             <color-icon type="icon-zhengshiban" v-else /> -->
             <!-- {{getStatusName[scope.row.status]}} -->
             {{scope.row.id}}
             <hover-show-qr :logo="`${scope.row.productId}`" :text="scope.row.id" :key="scope.row.id"></hover-show-qr>
           </template>
         </el-table-column>
         <el-table-column label="产量类型" width="120" prop="productId"  align="center">
          <template #default="scope">
            <!-- <el-tag v-if="scope.row.productId=='811103983bcffe227dc6dddec51f468e'">三代小魔夹</el-tag>
            <el-tag v-if="scope.row.productId=='56c62a11a6a14d6eb7ab29fbf9f2f8b4'">二代小魔夹</el-tag>
            <el-tag v-if="scope.row.productId=='a2ea3faa86274d3f966ff30aad68b208'">雨伞架</el-tag>
            <el-tag v-if="scope.row.productId=='d965476559f0428483b03f08941cda39'">共享雨伞</el-tag>
            <el-tag v-if="scope.row.productId=='0a9edc30d4924f989fd9207bf62e6d23'">便民柜</el-tag>
            <el-tag v-if="scope.row.productId=='6951e86506725baaf4dfcdf9aa015395'">三代机柜</el-tag>
            <el-tag v-if="scope.row.productId=='9775f7dd993646eaa4977537b0735e8f'">二代机柜</el-tag>
            <el-tag v-if="scope.row.productId=='a2ea3faa86274d3f966ff30aad68b208'">雨伞架</el-tag>
            <el-tag v-if="scope.row.productId=='d4b8d2d2df5742ecbec9398d5409dba9'">便民柜</el-tag>
            <el-tag v-if="scope.row.productId=='fd76cb9e939a4e6f92b4a867f0c19d2f'">便民柜</el-tag> -->
            <el-tag>{{productList[productIdList.indexOf(scope.row.productId)].name}}</el-tag>
          </template>
         </el-table-column>
         <el-table-column label="数量" width="70" prop="quantity"  align="center"></el-table-column>
         <el-table-column label="状态" width="90" prop="status"  align="center">
          <template #default="scope">
            <el-tag type="" v-if="scope.row.status=='0'">待受理</el-tag>
            <el-tag type="info" v-if="scope.row.status=='1'">已受理</el-tag>
            <el-tag type="warning" v-if="scope.row.status=='2'">待接收</el-tag>
            <el-tag type="success" v-if="scope.row.status=='3'">已完结</el-tag>
          </template>
         </el-table-column>
         <el-table-column label="已出货" width="70" prop="shipedQuantity"  align="center"></el-table-column>
         <el-table-column label="已完结" width="70" prop="finishQuantity"  align="center"></el-table-column>
         <el-table-column label="创建人" width="140" prop="createUser"  align="center"></el-table-column>
         <el-table-column label="接收人" width="140" prop="finishUser"  align="center"></el-table-column>
         <el-table-column label="创建时间" width="180" prop="gmtCreate" align="center"></el-table-column>
         <el-table-column label="受理时间" width="180" prop="gmtAccept"  align="center"></el-table-column>
         <el-table-column label="完结时间" width="180" prop="gmtFinish"  align="center"></el-table-column>
         <el-table-column label="操作" width="180" fixed="right" align="center">
           <template #default="scope">
             <el-button
               @click="handlerShipment(scope.row)"
               size="mini"
               type="primary"
             >出货单</el-button>
             <el-button
               @click="handlerAccept(scope.row)"
               size="mini"
               type="primary"
               v-if="scope.row.status=='2'"
             >接收</el-button>
             <el-button
               @click="handlerDelete(scope.row)"
               size="mini"
               type="danger"
               v-if="scope.row.status=='0'"
             >删除</el-button>
            
           </template>
         </el-table-column>
       </el-table>
       <el-pagination
         :current-page="page"
         :page-size="limit"
         :page-sizes="[10, 30, 50, 100]"
         :total="total"
         @current-change="onPageChange"
         @size-change="handleLimitChange"
         layout="total, sizes, prev, pager, next, jumper"
       ></el-pagination>
       <addPurchaseDialog @success="handleSuccess" ref="addPurchaseDialogRef"></addPurchaseDialog>
       <v-shipment-dialog  ref="shipmentDialogRef"></v-shipment-dialog>
       <accectPurchaseDialog ref="accectPurchaseDialogRef"></accectPurchaseDialog>
       <shipmentOldDialog ref="shipmentOldDialogRef"></shipmentOldDialog>
      </div>
    </el-dialog>
   </template> 
   <script lang="ts">
   import { defineComponent, ref} from "vue";
   import { getShareProductInfo, getProductInfoList,getProductIdList } from '@/api/util'
   import{purchaseModel, purchaseList, removePurchase, accextPurchase} from "@/api/Investment/purchase"
   import addPurchaseDialog from "./addPurchaseDialog.vue"
   import shipmentDialog from './shipmentDialog.vue'
   import shipmentOldDialog from '@/views/investment/components/shipmentDialog.vue'
   import accectPurchaseDialog from "./accectPurchaseDialog.vue"
   import { ElMessage, ElMessageBox } from "element-plus";
   import { userStore } from '@/store/modules/user'
   let investorOrderId="";
   let articlenum =0;
   export default defineComponent({
     name: "v-dialog",
     components: {
      addPurchaseDialog,
      'v-shipment-dialog':shipmentDialog,
      accectPurchaseDialog,
      shipmentOldDialog,
     },
     setup() {
      const name = ref("");  
      const visible = ref(false);
      const activeName = ref("");
      const loading = ref(false);
      const status = ref("");
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const list = ref<purchaseModel[]>([]);
      const sheareProductInfo = getShareProductInfo()
      const productList = getProductInfoList()
      const productIdList = getProductIdList()
      const addPurchaseDialogRef = ref<any>();
      const shipmentDialogRef = ref<any>()
      const shipmentOldDialogRef = ref<any>()
      const accectPurchaseDialogRef = ref<any>();
      const open = (_name:string,_investmentorderid:string,_articlenum:number) =>{
        visible.value = true;
        name.value=_name;
        investorOrderId=_investmentorderid;
        articlenum=_articlenum;
        reData();
       }
   
       const reData = async() => {
         try {
           loading.value = true;
           let resData: any = {current: page.value,size: limit.value,order:"gmtCreate",sort:"desc"}
           let entity: any = {investmentOrderId:investorOrderId}
           if(status.value){
            entity.status=status.value
           }
           resData={...resData,entity:{...entity}}
           const res = await purchaseList(resData);
           console.log('请求列表='+res)
           list.value = res.rows
           total.value = res.total
         } finally {
           loading.value = false;
         }
       }
   
       const handleClose = () =>{
         visible.value = false;
       }
   
       const handelChange = (val:any) =>{
        page.value=1;
        reData();
       }
       
       
       const handleAddNewPurchase = async() => {
         try {
          addPurchaseDialogRef.value.open(investorOrderId);
         } catch (error) {
           if (error == 'cancel') return
           if (error == 'colse') return
        //    ElMessage.error(error)
         }
       }
      
       const onPageChange = (_page:number) =>{
           page.value=_page
           reData();
       }
       const handleLimitChange = (_size:number) =>{
           limit.value=_size
           reData();
       }
       const handleSuccess = async (msg:string) =>{
         ElMessage.success(msg)
         page.value=1;
         reData();
       }
       const handlerShipment = (item:any) =>{
        shipmentDialogRef.value.open(name.value,investorOrderId,item.id,item.quantity);
       }
       const handlerAccept = async (item:any) =>{
        await ElMessageBox.confirm(
          `是否接收采购单id=${item.id},接收账号是${userStore.info.account}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await accextPurchase({ id :item.id })
        page.value=1;
        reData();
        ElMessage.success(res.msg)
       }
       const handlerOldShipment = () =>{
        shipmentOldDialogRef.value.open(name.value,investorOrderId,articlenum);
       }
       const handlerDelete = async(item:any) =>{
        
        await ElMessageBox.confirm(
          `是否删除采购单id=${item.id}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )

        const res = await removePurchase({ id :item.id })
        page.value=1;
        reData();
        ElMessage.success(res.msg)
       }
       return {
         name,
         visible,
         status,
         activeName,
        
         limit,
         page,
         total,
         list,
         loading,
         addPurchaseDialogRef,
         sheareProductInfo,
         productList,
         productIdList,
         shipmentDialogRef,
         shipmentOldDialogRef,
         accectPurchaseDialogRef,
         open,
         reData,
         handleAddNewPurchase,
         handleClose,
         handelChange,
         onPageChange,
         handleLimitChange,
         handleSuccess,
         handlerShipment,
         handlerOldShipment,
         handlerDelete,
         handlerAccept,
       }
     }
   })    
   
   </script>
   <style lang="scss" scoped> 
   .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
  }  
   </style>