
import { defineComponent, ref, readonly, inject ,onMounted} from 'vue'
import { fourthgcommandList } from '@/api/Share/Device'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
let deviceId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const deviceSn=ref<any>();   
    const list = ref<any[]>([])
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const search = ref({
      careateBN:"",
      datastream:"",
    }) 
   
    const handleClose = () => {
      visible.value = false
     
    }
    const open = async (_deviceid:string,_sn:string) => {
      visible.value = true
      deviceId=_deviceid;
      deviceSn.value=_sn;
      reData();
    }
    const reData = async () => {
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value,deviceid:deviceId,sort: 'creationtime', order: 'desc' }
       
        if (search.value.careateBN) {
          rqData.datatimestampbegin = new Date(search.value.careateBN[0]).getTime()   //dayjs(search.value.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.datatimestampend = new Date(search.value.careateBN[1]).getTime()   //dayjs(search.value.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")  
        }
        if(search.value.datastream){
          rqData.datastream=search.value.datastream
        }
        let { totalCount, result } = await fourthgcommandList(rqData)
        total.value = totalCount
        list.value = result
      } finally {
        loading.value = false
      }
    }
    

    const handelSearch = () => {

    }

    const handelChange = () => {
      page.value = 1
      reData()
    }

    const onPageChange = async(item:any) => {
      page.value=item;
      reData()
    }

    const handleLimitChange = async(item:any) => {
      page.value=item;
      reData()
    }
   
    return {
      visible,
      page,
      limit,
      total,
      list,
      search,
      loading,
      deviceSn,
      handleClose,
      open,
      reData,
      handelSearch,
      handelChange,
      onPageChange,
      handleLimitChange,
    }
  },
})
