
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { ElMessage, ElMessageBox } from 'element-plus'
import { userfundfrozenList,removefundfrozen } from "@/api/Financial/walletFrozen";
let userId = ''
export default defineComponent({
  setup() {
    const loading = ref(false);
    const btnEnabledLoaidng = ref(false);
    const btnRemoveLoaidng = ref(false);
    const visible = ref(false)
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const name = ref('');
    const list = ref<any[]>([]);
    const handleClose = () => {
      visible.value = false
    }
    const open = (_userId: string,_name: string) => {
      visible.value = true
      userId = _userId
      name.value=_name;
      reData({userid:userId,articleproductname:"共享雨伞",fortest:false})
    }
    const searchSelect = ref({
      search: "userid",
    });
    const mixin_router_search = ref({
      order: 'creationdate',
      sort: 'desc',
      articleproductname: "共享雨伞",
      fortest: false,
    });
    const mixin_select_search = ref({
      search: {
        account:"",
        userid: userId,
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',
        userid: "用户ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
            if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await userfundfrozenList(rqData);
        console.log("res.records :>> ", res.records);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    const handleRemove = async(item:any) =>{
        const phone=item.account;
        const orderid=item.frozenid;
        await ElMessageBox.confirm(
        `是否删除`+phone+'的冻结金额，删除后将可以正常提现',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        })
        const res = await removefundfrozen(orderid)
        mixinReRouter()
        ElMessage.success(res)
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      visible,
      name,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
      handleRemove,
      toPath_RouterSearchMixin,
      open,
      handleClose,
    };
  },
});
