import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface purchaseModel {
    id:string,
    gmtCreate: string, //创建时间
    gmtModified: string, //更新时间
    investmentOrderId: string, //投资订单ID
    productId:  string, //产品ID
    quantity:  number, //数量
    status: string //0:待受理 1:已受理 2:已完结
    gmtAccept: string, //受理时间
    gmtFinish: string, //完结时间
    acceptUser: string, //受理人
    finishUser: string, //完结人
    shipedQuantity: number, //已出货数量
    finishQuantity: number, //已投放数量
    createUser: string, //创建人
}
export interface purchaseListModel {
    id: string,
    gmtCreate: string,				//创建时间
    gmtModified: string,			//
    nickname: string,								//名字
    account: string,						//电话
    productname: string,						//产品类型
    quantity: number,									//数量
    status: number,									//状态
    shipedQuantity: number,							//已出货数量
    finishQuantity: number,							//已投放数量
    acceptUser: string,							//受理人
    finishUser: string,							//完结人
    gmtAccept: string,								//受理时间
    gmtFinish: string,								//完结时间
    createUser: string,								//创建人
    isDeleted: string,								//是否删除
    investmentOrderId: string,	//订单号
    productId: string	//类型ID

}
export async function purchaseList(params: ListReq): Promise<ListRes<purchaseModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<purchaseModel>>(
        {
            url: `/whpurchase/page`,
            params
        }, {
        prefix: "/shared"
    }
    );
    // res.records = res.records.map(v => {
    //     v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
    //     return v
    // })
    return res
}

export async function getPurchaseList(params: ListReq): Promise<ListRes<purchaseListModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<purchaseListModel>>(
        {
            url: `/whpurchase/relationPage`,
            params
        }, {
        prefix: "/shared"
    }
    );
    // res.records = res.records.map(v => {
    //     v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
    //     return v
    // })
    return res
}

export async function ceatePurchase<T>(params: T) {
    
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whpurchase/create`,
            params: {
                token: true,
                ...params
            }
        }, {
            prefix: "/shared",
        }
    );
    // res.records = res.records.map(v => {
    //     v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
    //     return v
    // })
    return res
}

export async function removePurchase<T>(params: T) {
    
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whpurchase/remove`,
            params: {
                token: true,
                ...params
            }
        }, {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res
}

export async function accextPurchase<T>(params: T) {
    
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whpurchase/finish`,
            params: {
                token: true,
                ...params
            }
        }, {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res
}

export async function receiveCabinet<T>(params: T) {
    
    const res = await defHttp.request<RequestRes>(
        {
            url: `/equipment/receive`,
            params: {
                token: true,
                ...params
            }
        }, {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res
}

export async function exportPurchase(params: ListReq): Promise<any> {

    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportWhpurchaseInfo`,
            responseType:'blob',
            params
        }, {
            prefix: "/analy",
            contentType:'json',
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
    
}

//创建没有投资订单的采购单
export async function addNoInvestorOrderShipmentHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whpurchase/createnoorder`,
            params: {
                token: true,
                ...parmas 
            },
        }, 
        {
            prefix: "/shared",
        }
    );
    return res.msg || "添加成功"
}