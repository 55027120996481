

import { ref,computed, defineComponent, readonly ,reactive } from "vue";
import { boxDeviceModel, getBoxDeviceOnSnHttp, removeDeviceToBoxHttp} from '@/api/WarehouseManagement/whbox';
import addDeviceDialog from "./addDeviceDialog.vue";
import { getShareProductInfo,getAdInfoList} from '@/api/util'
import { ElMessage, ElMessageBox } from 'element-plus'
let boxSn = "";
let boxId = "";
export default defineComponent({
    components: {
        addDeviceDialog
    },

    setup() {
        console.log('进入弹窗');
        const direction=ref("rtl");
        const isVisible = ref(false);
        const title = ref("包装箱详情");
        const loading = ref(false);
        const page = ref(1);
        const limit = ref(15);
        const list = ref<boxDeviceModel[]>([]);
        const total = ref(0);
        const isShowAdd = ref(true)
        const add = ref<any>('')
        const addType = ref<any>('equipmentId')

        const addDialogRef=ref<any>();
        const sheareProductInfo = getShareProductInfo()  
        const adInfoList = getAdInfoList()
       

        const handleClose=(done:any)=>{
            done();
        };

        
        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            isShowAdd.value=item.status=='0';
            boxSn=item.sn;
            boxId=item.id;
            title.value=boxSn+"包装箱详情"
            reData();
        };

        const handelChange = () => {
            page.value = 1
            reData()
        }
   
        const reData = async () => {
            try {
                loading.value = true;
                const res = await getBoxDeviceOnSnHttp(boxSn);
                console.log('包装箱 :>> ', res);
                total.value = res.total;
                list.value = res.data;
                console.log(list.value)
            } finally {
                loading.value = false;
            }
        };
      
        const onPageChange = (current: any) =>{
            page.value = current.value;
            reData();
        }

        const handleSearch = () =>{

        }

        const addClick = () =>{
            addDialogRef.value.open(boxId);
        }

        const handleRemove = async(idx:number,item:any) =>{
             await ElMessageBox.confirm(
            `是否将小魔夹`+item.equipmentSn+'移出包装箱？！',
            '提示',
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                distinguishCancelAndClose: true,
            }
            )
            const res = await removeDeviceToBoxHttp({adType:item.adType,boxId:boxId,equipmentSn:item.equipmentSn})
            reData()
            ElMessage.success(res)
        }

        return{
            title,
            isVisible,
            isShowAdd,
            addDialogRef,
            adInfoList,
            sheareProductInfo,
            direction,
            addType,
            handleClose,
            onPageChange,    
            handelChange,
            handleRemove,
            handleSearch,
            addClick,        
            open,
            page,
            limit,
            total,
            list,
            add,
        
        };
    },
   
});
