<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.productname">
        <el-option label="共享雨伞" value="共享雨伞"></el-option>
        <el-option label="小魔夹MS3" value="小魔夹MS3"></el-option>
        <el-option label="小魔夹MS2" value="小魔夹MS2"></el-option>       
      </el-select>
      <el-select @change="handelChange" clearable placeholder="私有化状态" class="header-el-selector" v-model="mixin_router_search.privatized">
        <el-option label="已私有化" :value="true"></el-option>
        <el-option label="未私有化" :value="false"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="锁定状态" class="header-el-selector" v-model="mixin_router_search.lockcmd">
        <!-- <el-option label="初始" :value="null"></el-option> -->
        <el-option label="已锁定" value="LOCK"></el-option>
        <el-option label="解锁" value="UNLOCK"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="持有类型" class="header-el-selector" v-model="mixin_router_search.holdertype">
        <el-option :label="allHoldertype[item]" :value="item" v-for="item of Object.keys(allHoldertype)" :key="item"></el-option>
      </el-select>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <!-- <el-table :data="list" height="80vh" style="width: 100%" v-loading="true"> -->
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item>
            <el-form-item label="折旧时间">
              <span>{{ scope.row.depreciationstartdate }}</span>
            </el-form-item>
            <el-form-item label="投资订单号">
              <el-button type="text" @click="toPath_RouterSearchMixin('/investment/order',[{search_orderid:scope.row.investmentorderid}])">{{ scope.row.investmentorderid }}</el-button>
              <my-copy :data="scope.row.investmentorderid"></my-copy>
            </el-form-item>
            <el-form-item label="投资人ID" v-if="scope.row.investoruserid">
              {{scope.row.investoruserid}}
              <my-copy :data="scope.row.investoruserid"></my-copy>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="SN" width="140">
        <template #default="scope">
          <my-copy :data="scope.row.articleequipmentsn" />
          {{scope.row.articleequipmentsn}}
          <hover-show-qr :logo="`${scope.row.producttype}`" :text="scope.row.articleequipmentsn" :key="scope.row.articleequipmentsn"></hover-show-qr>
        </template>
      </el-table-column>
      <el-table-column label="设备类型" min-width="140" prop="productname">
      </el-table-column>
      <el-table-column label="投资人" width="220">
        <template #default="scope">
         {{scope.row.investorname}}({{scope.row.account?scope.row.account:'未知'}})
        </template>
      </el-table-column>
      <el-table-column label="投资人昵称" width="140">
        <template #default="scope">
         {{scope.row.nickname?scope.row.nickname:'无昵称'}}
        </template>
      </el-table-column>
      <el-table-column label="手机号" width="140">
        <template #default="scope">
         {{scope.row.phonenumber?scope.row.phonenumber:'未知'}}
        </template>
      </el-table-column>
      <el-table-column label="持有" width="160">
        <template #default="scope">
          <div v-if="scope.row.holdertype=='socket'">
            <span class="iconfont icon-panel-zg"></span>
            <el-button type="text" @click="toPath_RouterSearchMixin('/share/panel',[{search_panelequipmentsn:scope.row.holderpanelequipmentsn}])">{{scope.row.holderpanelequipmentsn}}</el-button>
            - {{scope.row.holdersocketsn}}
            <my-copy :data="scope.row.holderpanelequipmentsn" />
          </div>
          <div v-else-if="scope.row.holdertype=='ownerless'">
            无主设备
          </div>
          <div v-else>
            <span class="el-icon-user"></span>
            <span v-if="scope.row.holdertype=='consumer'" class="a-item" @click="checkUser(scope.row)">租赁用户</span>
            <span v-else-if="scope.row.holdertype=='dispatcher'" class="a-item" @click="checkUser(scope.row)">调度员</span>
            <span v-else-if="scope.row.holdertype=='factoryrepairman'" class="a-item" @click="checkUser(scope.row)">工厂维修员</span>
            <span v-else-if="scope.row.holdertype=='factoryrepairman'" class="a-item" @click="checkUser(scope.row)">现场维修员</span>
            <span v-else-if="scope.row.holdertype=='fieldrepairman'" class="a-item" @click="checkUser(scope.row)">工厂维修员</span>
            <span v-else-if="scope.row.holdertype=='tester'" class="a-item" @click="checkUser(scope.row)">测试员</span>
            <span v-else-if="scope.row.holdertype=='investor'" class="a-item" @click="checkUser(scope.row)">投资人</span>
            <!-- <my-click-data-to url="'/user'" :data="allHoldertype[scope.row.holdertype]" :params="[{search_userid:scope.row.holderuserid}]"></my-click-data-to> -->
            <my-copy :data="scope.row.holderuserid" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="共享状态" width="140">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.shared">共享中</el-tag>
          <el-tag type="danger" v-else="!scope.row.shared">未共享</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="私有化状态" width="140">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.privatized">已私有</el-tag>
          <el-tag type="success" v-else="!scope.row.privatized">未私有</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="锁定状态" width="140">
        <template #default="scope">
          <el-tag type="danger" v-if='scope.row.lockcmd=="LOCK"'>已锁定</el-tag>
          <el-tag type="success" v-else='scope.row.lockcmd=="UNLOCK"'>未锁定</el-tag>
        </template>
      </el-table-column>
     
      <el-table-column label="替换SN" width="140">
        <template #default="scope">
          待开发
        </template>
      </el-table-column>
      <el-table-column label="替换原因" width="140">
        <template #default="scope">
          待开发
        </template>
      </el-table-column>
      <el-table-column label="投资寿命" width="140">
        <template #default="scope">
          待开发
        </template>
      </el-table-column>
      <el-table-column label="硬件寿命" width="140">
        <template #default="scope">
          待开发
        </template>
      </el-table-column>
      <el-table-column label="已补偿" width="140">
        <template #default="scope">
          待开发
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" min-width="200"  fixed="right">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button @click="handleShowComponenet(scope.row.articleequipmentsn)" size="mini" type="primary">查看组件</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
  </div>
  <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
  <v-components-dialog ref="componentsDialogRef"></v-components-dialog>
  <v-show-user-info-dialog ref="showUserInfoDialigRef"></v-show-user-info-dialog>
  <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
</template>

<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  getShareDeviceList,
  getAnalyShareDeviceList,
  DeviceListRes,
  holdertype,
  exportAnalyShareDeviceList,
} from "@/api/Share/Device";
import addDialog from "./components/addDialog.vue";
import componentsDialog from "./components/componentsDialog.vue";
import showUserInfoDialog from "./components/showUserInfoDialog.vue"
import exportDialog from "@/components/export/exportDialog.vue";
export default defineComponent({
  name: "Name",
  components: {
    "v-add-dialog": addDialog,
    "v-components-dialog": componentsDialog,
    "v-show-user-info-dialog":showUserInfoDialog,
    exportDialog,
  },
  setup() {
    const loading = ref(false);
    const btnRemoveLoaidng = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const allHoldertype = holdertype;
    const list = ref<DeviceListRes[]>([]);
    const exportDialogRef = ref<any>();
    const addDialogRef = ref<any>();
    const showUserInfoDialigRef = ref<any>();
    const componentsDialogRef = ref<any>();
    const searchSelect = ref({
      search: "articleequipmentsn",
    });
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: "",
        holderpanelequipmentsn: "",
        investmentorderid: "",
        holderuserid: "",
        investoruserid: "",
      },
    });

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: "设备SN",
        holderpanelequipmentsn: "机柜SN",
        investmentorderid: "订单号",
        holderuserid: "用户ID",
        investoruserid: "投资人ID",
      },
    });

    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");

    const mixin_router_search = ref({
      socketstatus: "",
      productname: "",
      privatized: "",
      lockcmd: "",
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, entity: rq };
        }
        const res = await getAnalyShareDeviceList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const checkUser = (row:any)=>{
      showUserInfoDialigRef.value.open(row)
    };
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row);
    };
    const addSuccess = (data: { index: number; data: DeviceListRes }) => {
      if (typeof data.index == "number" && data.index > -1) {
        list.value[data.index] = data.data;
      } else {
        list.value.push(data.data);
      }
    };

    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn);
    };

    const handleExport = async() => {
      exportDialogRef.value.open('共享设备表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'shareDevice'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
       
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportAnalyShareDeviceList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      checkUser,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleEidt,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      componentsDialogRef,
      showUserInfoDialigRef,
      handleShowComponenet,
      toPath_RouterSearchMixin,
      changSearch,
      allHoldertype,
      handleExport,
      exportDialogRef,
      exportFile,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }

  .a-item{
    cursor: pointer;
    color:#409eff;
    padding-left: 4px;
    padding-right: 4px;
    height:32px;
    line-height:32px;
  }

  .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
  }
}
</style>