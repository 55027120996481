<template>
  <el-dialog
      :destroy-on-close="true"
      :title="title"
      @closed="handleClose"
      class="dialog"
      v-model="visible"
      width="960px"
    >
    <div class="container">
     <header>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否为正式" v-model="search.fortest">
          <el-option :value="true" label="测试"></el-option>
          <el-option :value="false" label="正式"></el-option>
        </el-select>
        <el-date-picker
          @change="handelChange"
          align="right"
          class="header-select"
          end-placeholder="结束时间"
          range-separator="至"
          start-placeholder="开始时间"
          type="datetimerange"
          unlink-panels
          v-model="search.timeBN"
        ></el-date-picker>
        <el-button style="margin-left: 16px;" @click="handleExport()" icon="el-icon-tickets" type="primary">导出表数据</el-button>
      </header>
      <div class="total-parent">
        <div class="total-item">
          <div class="item-top">
            <color-icon type="icon-BALANCE" style="color:#50b674; width: 46px; height: 46px;"></color-icon>
          </div>
          <div class="item-buttom">
            <span>总充值: ￥ <span style="color: #1AAC1B;">{{totalMoney}}</span></span>
            <span style="margin-top: 4px;">区间充值: ￥ {{selTotalMoney}}</span>
          </div>
        </div>
      </div>
      <el-table :data="list" height="45vh" style="width: 100%" v-if="list">
        <el-table-column align="center" label="充值人" min-width="40">
          <template #default="scope">
            {{scope.row.nickname}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额" min-width="40">
          <template #default="scope">
            ￥ {{scope.row.money}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间" min-width="60">
          <template #default="scope">
          {{scope.row.CreationTime}}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination 
        :current-page="page" 
        :page-size="limit" 
        :page-sizes="[10, 30, 50, 100]" 
        :total="total" 
        @current-change="pageChange" 
        @size-change="handleLimitChange" 
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </el-dialog>
  <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
</template>

<script lang = "ts" >
import { defineComponent, ref } from "vue";
import { detaildModel,getInAndOutDetails,exportInAndOutDetails} from "@/api/Financial/inAndOutTotalMoney";
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
let valueType='';
let name='';
let rechargemode='';
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog
   },
   setup() {
    const title = ref('旧充值详情');
    const visible = ref(false);
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const totalMoney = ref(0);
    const selTotalMoney = ref(0);
    const exportDialogRef = ref<any>();
    const list = ref<detaildModel[]>([]);
    const search = ref({
      timeBN:'',
      fortest:false
    });
    const open = (_type:string,_name:string,_rechargemode:string) => {
      console.log('开启弹窗')
      visible.value = true;
      valueType=_type;
      rechargemode=_rechargemode;
      totalMoney.value=0;
      selTotalMoney.value=0;
      list.value=[];
      if(_name!=null){
        name=_name
        title.value=_name+"旧充值详情"
      }
      search.value.timeBN='';
      search.value.fortest=false;
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsTotal();
      getStatisticsList();
    };

    const getStatisticsTotal = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        // let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,rechargemode:rechargemode,type:valueType};
        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        // }
        // const res = await getInAndOutDetails(rqData);
        // totalInfo.value = res;
        // profitTotalInfo.value=res.alllist[0];
        // profitTimeInfo.value=res.list[0];
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const getStatisticsList = async() => {
      
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,rechargemode:rechargemode,type:valueType};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getInAndOutDetails(rqData);
        console.log('详情信息=',res)
       
        total.value=res.data.total
        list.value=res.data.records
        list.value.map(v => {
          v.CreationTime = dayjs(parseInt(v.CreationTime!)).format("YYYY-MM-DD HH:mm:ss")
          return v
        })
        totalMoney.value =res.data.alllist[0]?res.data.alllist[0].money:0;
        selTotalMoney.value = res.data.sectionlist[0]?res.data.sectionlist[0].money:0;
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }

    const handleExport = async() =>{
      exportDialogRef.value.open(title.value)
    }
    const exportFile = async(name:string) =>{
      try {
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,rechargemode:rechargemode,type:valueType,fileName:title.value};
       
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        const res = await exportInAndOutDetails(rqData)

        const blob = new Blob([res.data]);
        saveAs(blob, name)
      } finally {

      }
    }

    return {
      title,
      visible,
      page,
      total,
      limit,
      list,
      search,
      totalMoney,
      selTotalMoney,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
</script>
<style lang = "scss" scoped >
.container{
  margin-top: -16px;
  .total-parent{
    display: flex;
    flex-direction: row;
    height: 96px;
    width: 100%;
    align-items: center;
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    .total-item{
      width: 25%;
      display: flex;
      flex-direction: row;
      background: #f2f2f2;
      align-items: center;
      padding-top: 18px;
      padding-bottom: 18px;
      .item-top{
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-buttom{
        display: flex;
        margin-top: 8px;
        flex-direction: column;
      }
    }
  } 
  
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>