<template>
  <el-dialog :destroy-on-close="true" :title="name+`钱包明细`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="55%">
   <div class="container">
     <header>
      <el-select @change="handelChange" clearable placeholder="钱包类型" class="header-el-selector" v-model="isTest">
        <el-option :value="false" label="正式钱包"></el-option>
        <el-option :value="true" label="测试钱包"></el-option>
      </el-select>
     </header>
     <el-tabs v-model="activeName"  @tab-click="handleClick" >
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane label="充值" name="recharge"></el-tab-pane>
      <el-tab-pane label="提现" name="withdraw"></el-tab-pane>
      <el-tab-pane label="扣费" name="deduction"></el-tab-pane>
      <el-tab-pane label="收益" name="profit"></el-tab-pane>
      <el-tab-pane label="续费" name="renewal"></el-tab-pane>
      <el-tab-pane label="退款" name="refund"></el-tab-pane>
     </el-tabs>
   
     <el-table
        :data="list"
        :default-sort="{prop: 'date', order: 'descending'}"
        height="55vh"
        style="width: 100%"
        v-if="list">
        <el-table-column align="center" label="钱包类型" min-width="55">
          <template #default="scope">
            <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
            <color-icon type="icon-zhengshiban" v-else />
            <span>{{scope.row.articleproductname}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="费用类型" min-width="55">
          <template #default="scope">
            <div v-if="scope.row.billtype=='recharge'||scope.row.billtype=='profit' ||scope.row.billtype=='refund'">  
              <color-icon type="icon-weixin" style="font-size:40px;color:#50b674" v-if="scope.row.chargetype&&scope.row.chargetype==0"></color-icon> 
              <color-icon type="icon-zhifubao" style="font-size:40px;color:#50b674" v-if="scope.row.chargetype&&scope.row.chargetype==1"></color-icon> 
              {{ rechargeOrProfitTypeList[scope.row.rechargeType].name }}
            </div>
            <div v-if="scope.row.billtype=='withdraw'">          
              <color-icon type="icon-weixin" style="font-size:40px;color:#50b674" v-if="scope.row.transfertype&&scope.row.transfertype==0" />
              <color-icon type="icon-zhifubao" style="font-size:40px;color:#50b674" v-if="scope.row.transfertype&&scope.row.transfertype==1" />
              提现
            </div>
            <div v-if="scope.row.billtype=='deduction'">
              {{ deductionTypeList[scope.row.rechargeType].name }}
            </div>
            <div v-if="scope.row.billtype=='renewal'">
              订单续费
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" label="金额" min-width="44">
          <template #default="scope">
            {{scope.row.billtype=='recharge'||scope.row.billtype=='profit'||scope.row.billtype=='refund'?'+':'-'}} ¥ {{scope.row.amount}}
          </template>
        </el-table-column>
        <el-table-column  align="center" label="创建时间" min-width="40" prop="date" sortable="custom"></el-table-column>
      </el-table>
        <el-pagination
        :current-page="page"
        :page-size="limit"
        :page-sizes="[10, 30, 50, 100]"
        :total="total"
        @current-change="onPageChange"
        @size-change="handleLimitChange"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
   </div>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, ref, computed,readonly} from "vue";
import {
  billtypeNames,
  userconsumewalletUnionbill,
  getRechargeOrProfitTypeList,
  getDeductionTypeList,
} from '@/api/Financial/wallet'
export default defineComponent({
  name: "v-dialog",
  components: {
  },
  setup() {
    const name = ref("");
    const visible = ref(false);
    const isTest = ref(false);
    const activeName = ref("");
    const userId = ref("");
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
    const rechargeOrProfitTypeList=getRechargeOrProfitTypeList();
    const deductionTypeList=getDeductionTypeList();
    const open = (_userid:string,_name:string) => {
      userId.value=_userid;
      name.value=_name;
      visible.value=true;
      reData();
    }
    const reData = async () => {
      try {
        let rqData: any = { current: page.value, size: limit.value, order:"date",sort:"desc",
            entity:{fortest:isTest.value,billtype:activeName.value,userid:userId.value} 
        }
        const res = await userconsumewalletUnionbill(rqData)
        console.log('res.records :>> ', res.records)
        list.value = res.records
        total.value = res.total
      } finally {
       
      }
    }
    const handleClose = () => {
      visible.value = false;
    }
    const handelChange = () => {
      page.value=1;
      reData();
    }
    const handleClick = () => {
      if(activeName.value==="0"){
        activeName.value=""
      }
      page.value=1;
      reData();
    }
    const onPageChange = (_page:number) =>{
        page.value=_page
        reData();
    }
    const handleLimitChange = (_size:number) =>{
        limit.value=_size
        reData();
    }
    return {
      visible,
      name,
      isTest,
      activeName,
      limit,
      page,
      total,
      list,
      open,
      handleClose,
      handelChange,
      handleClick,
      onPageChange,
      handleLimitChange,
      deductionTypeList,
      rechargeOrProfitTypeList,
    }
  }
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>