import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface CouponModel {
    id: string,
    gmtCreate: string,
    gmtModified: string,
    userId: string,
    receiveTime: string,
    validStartTime: string,
    validEndTime: string,
    sharedOrderId: string,
    receiveStartTime: string,
    receiveEndTime: string,
    title: string,
    desc: string,
    type: number,
    withAmount: string,
    usedAmount: number,
    usedStatus: number  //0 待领取-未到领取时间,1 待领取-已经到领取时间了,2 未到使用时间,3 待使用,4 已使用,5 未领取已过期,6 已领取已过期
}

export async function getUserCouponList(params: ListReq): Promise<ListRes<CouponModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<CouponModel>>(
        {
            url: `/usercoupon/pagevo`,
            params
        }, {
        prefix: "/shared"
    });
    res.rows = res.rows.map(v => {
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss")
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss")
        v.receiveEndTime = dayjs(v.receiveEndTime).format("YYYY-MM-DD HH:mm:ss")
        v.receiveStartTime = dayjs(v.receiveStartTime).format("YYYY-MM-DD HH:mm:ss")
        v.receiveTime = dayjs(v.receiveTime).format("YYYY-MM-DD HH:mm:ss")
        v.validEndTime = dayjs(v.validEndTime).format("YYYY-MM-DD HH:mm:ss")
        v.validStartTime = dayjs(v.validStartTime).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}
