<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="钱包类型" class="header-el-selector" v-model="mixin_router_search.articleproductmodel">
        <el-option label="雨伞" value="UM01"></el-option>
        <el-option label="小魔夹" value="M4S2"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="是否已完成" class="header-el-selector" v-model="mixin_router_search.finished">
        <el-option :value="true" label="已审核"></el-option>
        <el-option :value="false" label="未审核"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="是否审核通过" class="header-el-selector" v-model="mixin_router_search.approved">
        <el-option :value="true" label="通过"></el-option>
        <el-option :value="false" label="拒绝"></el-option>
      </el-select>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="props">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="ID">
              <my-copy :data="props.row.userid"></my-copy>
              <el-button type="text" @click="toPath_RouterSearchMixin('/user',[{search_userid:props.row.userid}])">{{ props.row.userid }}</el-button>
            </el-form-item>
            <el-form-item label="提现账号">
              <my-copy :data="props.row.payeeaccount"></my-copy>
              <span>{{ props.row.payeeaccount }}</span>
            </el-form-item>
            <el-form-item label="提现ID">
              <my-copy :data="props.row.withdraworderid"></my-copy>
              <span>{{ props.row.withdraworderid }}</span>
            </el-form-item>
            <el-form-item label="转账前余额" v-show="props.row.balancebeforetransfer">
              <span>¥ {{ props.row.balancebeforetransfer }}</span>
            </el-form-item>
            <el-form-item label="转账后余额" v-show="props.row.balanceaftertransfer">
              <span>¥ {{ props.row.balanceaftertransfer }}</span>
            </el-form-item>
            <el-form-item label="发起应用">
              <span>{{ props.row.payeetype=="XCX"?"小程序":'APP' }}</span>
            </el-form-item>
            <el-form-item label="审核结果" v-if="props.row.auditresult ">
              <span>{{ props.row.auditresult }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="账号" min-width="40">
        <template #default="props">
          <el-button type="text" @click="toPath_RouterSearchMixin('/user',[{search_account:props.row.account}])">{{ props.row.account }}</el-button>
          <my-copy :data="props.row.account"></my-copy>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="钱包类型" min-width="40">
        <template #default="props">
          <el-tag type="primary">{{props.row.articleproductname}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="金额" min-width="44" prop="amount" >
        <template #default="props">
         <span style="width: 80px; display: inline-block; text-align: right; margin-right: 6px;">{{props.row.amount}}</span>
            
          <color-icon style="font-size:36px" type="icon-zhifubao" v-if="props.row.withdrawmode=='alipay'"></color-icon>
          <color-icon style="font-size:36px;color:#50b674" type="icon-weixin" v-else-if="props.row.withdrawmode=='wechatpay'"></color-icon>
        </template>
      </el-table-column>
      <el-table-column align="center" label="提现姓名" min-width="40" prop="payeerealname"></el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="center" label="申请时间" min-width="40" prop="creationdate" sortable="custom"></el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="center" label="转账时间" min-width="40" prop="transferdate" sortable="custom"></el-table-column>
      <!-- <el-table-column label="提现金额" min-width="40">
        <template #default="scope">
          <el-tooltip class="item" effect="dark" content="点击查看余额" placement="top">
            <div style="cursor:pointer" @click="showBalance(scope.row.payeerealname,scope.row.userid)">
              <color-icon type="icon-zhifubao" v-if="scope.row.transfertype=='1'" style="font-size:36px"></color-icon>
              <color-icon type="icon-weixin" v-else-if="scope.row.transfertype=='2'" style="font-size:36px;color:#50b674"></color-icon>
              ¥ {{scope.row.amount}}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>-->
      <el-table-column align="right" label="审核结果" min-width="40">
        <template #default="scope">
          <div v-if="scope.row.autopay">
            <el-tag type="success">自动转账</el-tag>
          </div>
          <div v-else-if="scope.row.approved">
            <el-tag type="success" v-if="scope.row.audited">同意</el-tag>
            <el-tag type="danger" v-else>不同意</el-tag>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="操作" min-width="50" v-if="roleHiddenPermission(['ROLE_SALESMAN','ROLE_CALLCENTER','ROLE_POSTSALE'])"> -->
      <el-table-column align="center" label="操作" min-width="50">
        <template #default="scope">
          <el-button :disabled="scope.row.audited" @click="handleCatchwith(scope.row)" size="mini" type="primary" v-if="!scope.row.finished&&!scope.row.autopay">审核提现</el-button>
          <el-button @click="handleRemove(scope.$index)" size="mini" type="danger" v-if="(!scope.row.transferred&&scope.row.approved)||(scope.row.autopay&&!scope.row.approved)" :loading="rmLoading">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    <v-catchwith-dialog @success="mixinReRouter" ref="catchwithDialogRef"></v-catchwith-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  userwithdraworderList,
  userwithdraworderRemove,
} from "@/api/Financial/transfer";
import catchwithDialog from "./components/catchwithDialog.vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
  name: "v-transfer",
  components: {
    "v-catchwith-dialog": catchwithDialog,
  },
  setup() {
    const loading = ref(false);
    const rmLoading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const catchwithDialogRef = ref<any>("");
    const list = ref<any[]>([]);
    const walletType = readonly({
      YS: "雨伞",
      MJ: "小魔夹",
    });
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      transfertype: "",
      audited: "",
      approved: "",
      articleproductmodel: "",
      finished: false,
    });
    const mixin_select_search = ref({
      search: {
        payeerealname: "",
        account: "",
        userid: "",
        withdraworderid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        payeerealname: "昵称",
        account: "账号",
        userid: "用户ID",
        withdraworderid: "提现ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, entity: rq };
        }
        const res = await userwithdraworderList(rqData);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleCatchwith = (row: any) => {
      catchwithDialogRef.value.open(row);
    };
    const handleRemove = async (index: number) => {
      try {
        const item = list.value[index];
        await ElMessageBox.confirm(
          `确定删除${item.payeerealname}的提现申请吗？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        rmLoading.value = true;
        const res = await userwithdraworderRemove(item.withdraworderid);
        list.value.splice(index, 1);
        ElMessage.success(res || "移除成功");
      } finally {
        rmLoading.value = false;
      }
    };

    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      walletType,
      changSearch,
      handleCatchwith,
      catchwithDialogRef,
      mixinReRouter,
      handleRemove,
      rmLoading,
      toPath_RouterSearchMixin,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>