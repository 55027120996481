
import { userconsumewalletwalletbillModel, userconsumewalletwalletbill } from '@/api/Financial/walletBill'
import { defineComponent, ref } from 'vue'
import dayjs from 'dayjs'
let sort = 'desc'
let order = 'gmt_create'
let userId = ''
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const visible = ref(false)
    const list = ref<userconsumewalletwalletbillModel[]>([])
    const account = ref("");
    const current = ref(1)
    const size = ref(10)
    const totalCount = ref(0)
    const search = ref({
      fortest: false, //是否用于测试，如：true 是 false 否
      timeBN: ''
    })
    const handleClose = () => {
      visible.value = false
    }
    const open = (_userId: string,_account: string) => {
      visible.value = true
      userId = _userId
      account.value=_account;
      reData()
    }
    const reData = async () => {
      let rqData : any = {current:current.value,size:size.value,order:order,sort:sort}
      let entity : any = {userId:userId,fortest:search.value.fortest};
      if (search.value.timeBN) {
        entity.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        entity.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
      }
      rqData={...rqData,entity:{...entity}}
      console.log('请求参数',rqData)
      const { total, rows } = await userconsumewalletwalletbill(rqData)
      totalCount.value = total
      list.value = rows
    }
    

    const handelChange = () => {
      current.value=1;
      reData();
    }

    const pageChange = (val:number) => {
      current.value=val;
      reData();
    }

    const handleLimitChange = (val:number) =>{
      size.value=val;
      reData();
    }

   
    return {
      visible,
      account,
      handleClose,
      open,
      list,
      current,
      totalCount,
      size,
      search,
      handelChange,
      sort,
      order,
      reData,
      pageChange,
      handleLimitChange,
    }
  },
})
