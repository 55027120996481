
import { defineComponent, ref, toRef } from "vue";
import { userRoleAdd } from "@/api/User/role";
import { userList } from "@/api/User/index";
import Rules from "@/common/Rules";
import { userRoleList } from '@/api/User/role'
import { ElMessage } from "element-plus";
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: "v-dialog",
  props: ["type"],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
        superioraccount:[Rules.required()]
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const userId=ref("");
    const list = ref<any>([])
    const inputRef = ref<any>("");
    const inputData = ref({
      account: "",
      name: "",
      roleid:"",
     
    });
    const handleClose = () => {
      visible.value = false;
      inputRef.value.resetFields();
      inputRef.value.clearValidate();
    };
    const open = () => {
      visible.value = true;
     
    };
    const getUserInfo = async () => {
      try {
        let rqData: any = { page: 1, limit: 10,account:inputData.value.account};
        const res = await userList(rqData);
        list.value = res.result;
        if(list.value.length>0){
          userId.value=list.value[0].userid
          addUserRole();
        }else{
          ElMessage.success(`未找到${inputData.value.account}用户，请确认账号存在`);
        }   
      } finally {
        btnSumitLoading.value = false
      }
    }
    const addUserRole = async () =>{
      try {
        let submitData: any={appcur: "0",concur: "1",roleid: inputData.value.roleid,userid: userId.value};
        const res =await userRoleAdd(submitData);
        ElMessage.success(res);
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    }

    const submit = async () => {
      try {
        try {
          await inputRef.value.validate();
        } catch (error) {
          console.log(error);
          throw Error("请检查输入");
        }    
        btnSumitLoading.value = true;
        getUserInfo();
     
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      inputRef,
      checkInvestorLoading,
    };
  },
});
