import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface RelationModel {
    "userid": string,  //用户id
    "account": string, //手机号
    "nickname": string, //昵称
    "name": string,     //姓名
    "type": string,    //类型
    "roletype": string, //角色
    "fortest": string,  //是否测试
    
}

/**
 * 
 * @param params 查询投资人的收益账单
 * @returns 
 */
export async function getUserRoleRelation(parmas: ListReq): Promise<ListRes<RelationModel>> {
    const res = await defHttp.request<ListRes<RelationModel>>(
        {
            url: `/role/getUserRoleRelation`,
            params: {
                token: true,
                ...parmas
            },
        },
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
   
    return res
}
