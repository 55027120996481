// 里有保存数据模块 
import { watch, Ref, nextTick } from 'vue';
import { useRouter, useRoute } from "vue-router"
import { ElMessage } from "element-plus"
import EDCode from "@/utils/base64"
import { isArray } from "@/utils/is"

function haveNotSerch(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] != undefined || obj[key] != null || obj[key] != '') {
            return true
        }
    })
    return false
}

function isObject(val: any) {
    return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

function objExistsAndIsNotEmpty(obj: any) {
    return obj != undefined && obj != null && JSON.stringify(obj) != "{}"
}

interface routerChangeReDataRes {
    // 页面搜索
    mixinReRouter: () => void
    mixinReRouterTableSortChange: (e: SortChange) => void
    // 页面对象改变
    onPageChange: (current: Current) => void
    //单页数切换
    handleLimitChange: (size: number) => void
    //切换是清除
    changSearch: (type: string) => void
}
export interface Mixin_router_search {
    [key: string]: string | number | boolean | null | any
}
export interface Mixin_select_search {
    [key: string]: Mixin_router_search
}

interface SortChange {
    order: 'ascending' | 'descending' | undefined
    prop: string
}

interface RouterChangeReData {
    mixin_router_search?: Ref<Mixin_router_search>;
    mixin_select_search?: Ref<Mixin_select_search>;
    reData: (data?: any) => void;
    loading: Ref<boolean>;
    page?: Ref<number>,
    limit?: Ref<number>
    searchSelect?: Ref<any>
}

type Current = Ref<number>
type Size = Ref<number>

export default function routerChangeReData({
    mixin_router_search,
    mixin_select_search,
    reData,
    loading,
    page,
    limit,
    searchSelect
}: RouterChangeReData): routerChangeReDataRes {
    let _mixinRouterSearchChange: unknown = ''
    const router = useRouter()
    const route = useRoute()
    _mixinRouterSearchChange = EDCode.encode(route.query)
    //监听路由变化， 变了就开始请求数据
    watch([() => route.query, () => route.path], ([query, path], [oldQuery, oldPath]) => {
        if (path == oldPath) {
            setTimeout(() => {
                mixinRouterQueryChange();
            }, 200)
        }
    })

    /**
     * 搜索的时候路由格式化
     */
    const mixinRouterSearchChange = () => { // 获取路由
        const obj: any = {}
        if (mixin_router_search && objExistsAndIsNotEmpty(mixin_router_search.value)) Object.keys(mixin_router_search.value).forEach(key => { // 搜索
            if (mixin_router_search.value[key] !== null && mixin_router_search.value[key] !== undefined && mixin_router_search.value[key] !== '') {
                obj[key] = mixin_router_search.value[key]
            }
        })
        if (mixin_select_search && objExistsAndIsNotEmpty(mixin_select_search.value)) Object.keys(mixin_select_search.value).forEach(key => { // 选择搜索
            if (mixin_select_search.value[key] != null && mixin_select_search.value[key] != undefined) {
                for (const _key of Object.keys(mixin_select_search.value[key])) { // 选着搜索清空
                    if (mixin_select_search.value[key][_key] || mixin_select_search.value[key][_key] === false) obj[key + '_' + _key] = mixin_select_search.value[key][_key] // 格式化 select_name : data
                    // if (mixin_select_search.value[key][_key] !== null && mixin_select_search.value[key][_key] !== undefined) obj[key + '_' + _key] = mixin_select_search.value[key][_key] // 格式化 select_name : data
                }
            }
        })
        return obj
    }

    /**
     * 解析路由
     * 因为带有路由搜索的特殊性质
     * 使用规定 ： 1.固定类型搜索 key：value
     *            2.不定类型搜索 [key]:value 此时只有路由是 [[type]+'_'+[name]]:data 的形式才可以解析
     *            3.默认值搜索，[`mixinRouterSearchDefault_${key}`]:value , value 不能为空 
     */
    const mixinRouterQueryChange = (re?: boolean) => {
        const query = route.query.base ? JSON.parse(EDCode.decode(route.query.base)) : route.query // query base 加密和 不加密的
        console.log('query :>> ', query);
        if (JSON.stringify(query) == "{}") { // 路由是里面没有参数的时候
            if (re) { // 有默认搜索的时候 此时路由是空的
                const obj: any = mixinRouterSearchChange()
                reData && reData(obj); // 是有无搜索reData
            } else {
                if (mixin_router_search && objExistsAndIsNotEmpty(mixin_router_search.value)) Object.keys(mixin_router_search.value).forEach(key => { // 如果有serch 选项的全部设置为空
                    mixin_router_search.value[key] = ""
                })
                if (mixin_select_search && objExistsAndIsNotEmpty(mixin_select_search.value)) Object.keys(mixin_select_search.value).forEach(key => { // 如果有select_serch 选项的全部设置为空
                    for (const _key of Object.keys(mixin_select_search.value[key])) { // 选着搜索清空
                        mixin_select_search.value[key][_key] = ""
                    }
                })
                reData && reData(); // 是有无搜索reData
            }
        } else { // 路由有参数 解析
            const obj: any = {}
            Object.keys(query).forEach(function (key) {
                const serch = key.split('_')
                console.log({
                    query
                })
                console.log({
                    serch
                })
                if (serch[0] == undefined || serch[0] == null) { // 屏蔽 _a 的情况导致解析不出来
                    router.go(-1)
                    return
                }
                if (serch[1] != undefined && serch[1] != null) {
                    if (mixin_select_search && objExistsAndIsNotEmpty(mixin_select_search.value)) {
                        const data = typeof query[key] === 'string' ? query[key].trim() : query[key]
                        mixin_select_search.value[serch[0]][serch[1]] = data
                        if (searchSelect?.value) searchSelect.value[serch[0]] = serch[1]
                        console.log('serch[0],serch[1] ', serch[0], serch[1]);
                        if (['all', ''].indexOf(data) == -1) obj[serch[1]] = data // a
                    }
                    console.log('mixin_select_search.value :>> ', mixin_select_search);
                } else { // 搜索固定类型
                    if (mixin_router_search && objExistsAndIsNotEmpty(mixin_router_search.value)) {
                        const data = typeof query[key] === 'string' ? query[key].trim() : query[key]
                        console.log('key data :>> ', key, data);
                        mixin_router_search.value[key] = data
                        if (['all', ''].indexOf(data) == -1) obj[key] = data
                    }
                }
            });
            reData && reData(obj); // 是有无搜索reData
        }
    }
    const mixinReRouter = () => { // 页面搜索
        let query = mixinRouterSearchChange()
        console.log("路由内容")
        console.log(query)
        query = EDCode.encode(query) // 加密一下哈
        if (_mixinRouterSearchChange && EDCode.decode(query) == EDCode.decode(_mixinRouterSearchChange)) {
            console.log("重复的")
            mixinRouterQueryChange()
        } else { //搜索直接触发路由改变
            console.log('路由改变搜索')
            _mixinRouterSearchChange = query
            router.push({
                query: {
                    base: query
                }
            });
        }
    }
    const mixinReRouterTableSortChange = (e: SortChange) => {
        console.log('e :>> ', e);
        let order = 'descending'
        switch (e.order) {
            case "ascending":
                order = "asc"
                break;
            case "descending":
                order = "desc"
                break;
        }
        if (mixin_router_search?.value) {
            if (mixin_router_search.value.order != undefined) {
                mixin_router_search.value.order = e.prop
            } else if (mixin_router_search.value.orderby != undefined) {
                mixin_router_search.value.orderby = e.prop
            } else {
                throw new Error("排序order类型不存在");
            }
            mixin_router_search.value.sort = order
            mixinReRouter();
        }
    }
    const onPageChange = (current: any) => {
        if (loading.value) return;
        if (page?.value) page.value = current;
        mixinReRouter();
    }
    const handleLimitChange = (size: number) => {
        if (limit?.value!=undefined) limit.value = size
        mixinReRouter();
    }


    const changSearch = (searchType: string) => {
        // 切换搜索
        if (mixin_select_search && mixin_select_search.value) {
            console.log('JSON.stringify(mixin_select_search.value[searchType]) :>> ', JSON.stringify(mixin_select_search.value[searchType]));
            const keys = Object.keys(mixin_select_search.value[searchType])
            let _data: any = ""
            for (const v of keys) {
                if (mixin_select_search.value[searchType][v]) {
                    _data = mixin_select_search.value[searchType][v]
                    mixin_select_search.value[searchType][v] = ''
                    if (searchSelect?.value) mixin_select_search.value[searchType][searchSelect.value[searchType]] = _data
                    break;
                }
            }
        }
    }

    setTimeout(() => {
        mixinRouterQueryChange(true) // 首次加载
    }, 200)
    return {
        mixinReRouter,
        mixinReRouterTableSortChange,
        onPageChange,
        handleLimitChange,
        changSearch
    }
}