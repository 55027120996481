<template>
  <el-drawer
        :title="title"
        v-model="isVisible"
        size='40%'
        :direction="direction"
        :before-close="handleClose">
        
        <el-table :data="list"  style="margin-left: 16px; margin-right: 16px; height: 100%; " >
            <el-table-column label="仓口号" width="80" prop="socketsn" align='center'></el-table-column>
            <el-table-column label="SN" width="110" align='center'>
                <template #default="scope">
                <div v-if="scope.row.articleequipmentsn == '空'">{{scope.row.articleequipmentsn}}</div>
                <div v-else>
                    {{scope.row.articleequipmentsn}}
                    <hover-show-qr :logo="`icon-${prefix=='uh'?'umbrella':'phoneholder'}`" :text="scope.row.articleequipmentsn"></hover-show-qr>
                </div>
                </template>
            </el-table-column>          
            <el-table-column label="电量" width="80" align='center'>
                <template  #default="scope">
                    {{scope.row.batterypower}}%
                </template>
            </el-table-column>
            <el-table-column label="休眠时间" width="80" align='center' prop="sleeptime"></el-table-column>
            <el-table-column label="状态"   min-width="60"   prop="sleeptime">
                <template #default="scope">
                <el-tag type="info" v-if="scope.row.socketstatus=='0'">正常无设备</el-tag>
                <el-tag type="success" v-if="scope.row.socketstatus=='1'">正常有设备</el-tag>
                <el-tag type="warning" v-if="scope.row.socketstatus=='2'">可通信,充电不正常</el-tag>
                <el-tag type="danger" v-if="scope.row.socketstatus=='3'">识别机仓内有东西，但是无法通讯和充电</el-tag>
                <el-tag type="danger" v-if="scope.row.socketstatus=='4'">收到指令成功解锁设备但是驱动电机不能使小魔夹退出</el-tag>
                <el-tag type="danger" v-if="scope.row.socketstatus=='5'">发出解锁数据后连续查询设备5次不能解锁设备</el-tag>
                </template>
            </el-table-column>
           
        </el-table>

        
    <!-- <el-pagination
      :current-page="page"
      :page-size="limit"
      :total="total"
      @current-change="onPageChange"
      layout="prev, pager, next"
    ></el-pagination> -->
   </el-drawer>     
</template>
<script lang='ts'>
import { ref,computed, defineComponent, onMounted ,reactive} from "vue";
import { getSocketList, SocketListRes } from "@/api/Share/Socket";
export default defineComponent({
    setup() {
        const isVisible = ref(false);
        const title = ref("仓位口设备");
        const direction=ref("rtl");
        const page = ref(1);
        const limit = ref(15);
        const loading = ref(false);
        const list = ref<SocketListRes[]>([]);
        const total = ref(0);
        const panelequipmentsn = ref("");
        const prefix = ref("");
        /**
         * 关闭页面
         */
        const handleClose=(done:any)=>{
            done();
        };

         /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            panelequipmentsn.value=item.panelequipmentsn;
            title.value=item.panelequipmentsn+"仓位口设备"
             reData()
        };

        const reData = async () => {
            try {
                loading.value = true;
                const res = await getSocketList({
                current: page.value,
                size: limit.value,
                entity: { panelequipmentsn: panelequipmentsn.value },
                });
                console.log('res :>> ', res);
                total.value = res.total;
                list.value = res.records;
            } finally {
                loading.value = false;
            }
        };

        const onPageChange = () =>{

        }

        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,           
            panelequipmentsn,    
            onPageChange,       
            prefix,
            page,
            limit,
            total,
            list,
        }
    }
})
</script>
<style scoped lang='scss'>   

</style>