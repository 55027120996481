
import { defineComponent, ref, readonly, inject } from 'vue'
import {
  investmentorderList,
  InvestmentorderListRes,
  investmentorderRemove,
  investmentorderCreateshipment,
  confirmdelivery,
} from '@/api/Investment/Order'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { pickerOptions } from './elementConfig'
import { getStatusName, getArticletypeName } from './editData'
import orderDialog from './components/orderDialog.vue'
import orderDialogNew from './components/orderDialogNew.vue'
import orderConfrimDialog from './components/orderConfrimDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import myExport from '@/utils/exprotExcel'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'investment-order',
  components: {
    'v-add-order-dialog': orderDialog,
    'v-add-order-dialog-new': orderDialogNew,
    'v-order-confrim-dialog': orderConfrimDialog,
  },
  setup() {
    const mixin_router_search = ref({
      status: '', //投资订单状态，0 已下单；1 已付款；2 已出仓；3 已开始投放；4 已寄出；
      launchmode: '', //投放方式， SELF 自投放； PLATFORM 平台投放；
      fortest: false, //是否用于测试，如：true 是 false 否
      investortype: '', //投资者类型，如：user 用户；platform 平台；
      investmode: '', //投资方式， online 线上投资 offline 线下投资
      freegift: '', //是否为免费赠送，如：true 是 false 否
      articleproductid: '56c62a11a6a14d6eb7ab29fbf9f2f8b4', //设备类型
      careateBN: '',
    })
    const mixin_select_search = ref({
      search: {
        account:'', //投资人手机号
        investorname:'',//投资人姓名
        investoruserid: '', // 投资人用户编号
        orderid: '', //投资订单编码
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        investoruserid: '投资人ID', // 投资人用户编号
        orderid: '订单ID', //投资订单编码
         account:'手机号', 
        investorname:'姓名',
      },
    })
    const searchSelect = ref({search:"investorname"})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<InvestmentorderListRes[]>([])
    const btnRemoveLoading = ref(false)
    const btnConfirmdeliveryLoading = ref(false)
    const addOrderDialogRefNew = ref<any>()
    const addOrderDialogRef = ref<any>()
    const orderConfrimDialogRef = ref<any>()
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }
    type SearchData = typeof searchData

    type BaseReData = {
      size: number
      current: number
      entity?: toOptional<SearchData>
    }
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: BaseReData = {
          current: page.value,
          size: limit.value,
        }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.careateBN) {
            rq.creationdateBegin = rq.careateBN[0]
            rq.creationdateEnd = rq.careateBN[1]
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await investmentorderList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addOrderDialogRefNew.value.open()
    }
    const handleEdit = (index: number, row: any) => {
      addOrderDialogRef.value.open(row, index)
    }
    const orderDialogSueccess = (data: any) => {
      if (data.isEdit) {
        list.value.splice(data.index, 1, data.data)
      } else {
        //list.value.push(data.data)
        page.value = 1
        mixinReRouter()
      }
    }
    const handleRemove = async (index: number, orderid: string) => {
      try {
        btnRemoveLoading.value = true
        await investmentorderRemove({
          orderid,
        })
        list.value.splice(index, 1)
      } finally {
        btnRemoveLoading.value = false
      }
    }

    const handleConfirmdelivery = async (index: number, orderid: string) => {
      try {
        btnConfirmdeliveryLoading.value = true
        const res = await confirmdelivery({
          orderid,
          salesmanuserid: userStore.info.userid,
        })
        list.value[index].status = '3'
        ElMessage.success(res)
      } finally {
        btnConfirmdeliveryLoading.value = false
      }
    }

    const handleConfirm = async (index: number, row: any) => {
      row.index = index
      orderConfrimDialogRef.value.open(row)
    }
    const orderConfirmDialogSueccess = (index: number) => {
      list.value[index].status = '1'
    }
    const handleCreateBillSales = async (
      index: number,
      { articlenum, orderid }: any
    ) => {
      try {
        const input = await ElMessageBox.prompt('请输入出货数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: `请输入 1-${articlenum} `,
          inputType: 'number',
        })
        console.log('input :>> ', input)
        const _value = parseInt(input.value)
        if (input.value > articlenum || _value < 1)
          return ElMessage.error(`请输入 1-${articlenum}`)
        if (!userStore.info.userid) return userStore.loginOut()
        const res = await investmentorderCreateshipment({
          orderid,
          quantity: _value,
          shipperuserid: userStore.info.userid,
        })
        console.log('res :>> ', res)
        ElMessage.success(res)
      } catch (error) {
        if (error == 'cancel') return
        if (error == 'colse') return
        ElMessage.error(error)
      }
    }
    const handleExport = () => {
      const excel = new myExport(`小魔夹投资订单列表`)
      const _list = list.value.map((v) => {
        const _data = {
          // 姓名: v.investorname,
          投资人ID: v.investoruserid,
          时间: v.creationdate,
          数量: v.articlenum,
          // 分成比: v.investorproportion + '%',
          单价: v.singleamount,
          总计: v.articlenum * v.singleamount,
          状态: getStatusName[v.status],
          销售员: v.salesmanuserid,
        }
        return _data
      })
      excel.addWorksheet({ headers: ['小魔夹投资订单'], list: _list })
      excel.export()
    }
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,
      pickerOptions,
      onPageChange,
      handelChange,
      handleLimitChange,
      searchSelect,
      list,
      loading,
      getStatusName,
      getArticletypeName,
      handleAdd,
      addOrderDialogRefNew,
      addOrderDialogRef,
      orderConfrimDialogRef,
      handleEdit,
      orderDialogSueccess,
      handleRemove,
      btnRemoveLoading,
      handleConfirm,
      roleShowPermission,
      orderConfirmDialogSueccess,
      page,
      limit,
      total,
      handleCreateBillSales,
      toPath_RouterSearchMixin,
      handleConfirmdelivery,
      sheareProductInfo,
      handleExport,
    }
  },
})
