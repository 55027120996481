<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" v-model="mixin_router_search.articleproductmodel">
        <el-option :key="item.productid" :label="item.name" :value="item.productmodel" v-for="item of deviceList"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="是否测试" v-model="mixin_router_search.fortest">
        <el-option :value="false" label="正式"></el-option>
        <el-option :value="true" label="测试"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="续费状态" v-model="mixin_router_search.succeed">
        <el-option :value="false" label="失败"></el-option>
        <el-option :value="true" label="成功"></el-option>
      </el-select>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="auto">
            <el-form-item label="账号">
              <my-click-data-to :data="scope.row.account" :params="[{search_account:scope.row.account}]" url="/user"></my-click-data-to>
              <my-copy :data="scope.row.account" />
            </el-form-item>
            <el-form-item label="订单号">
              <my-click-data-to
                :data="scope.row.sharedorderid"
                :params="[{search_sharedorderid:scope.row.sharedorderid}]"
                url="/order/userorder"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedorderid" />
            </el-form-item>
            <el-form-item label="续费ID">
              {{scope.row.renewalid}}
              <my-copy :data="scope.row.renewalid" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="44" prop="articleequipmentsn"></el-table-column>
      <el-table-column label="型号" min-width="44" prop="articleproductname"></el-table-column>
      <el-table-column label="续费天数" min-width="44" prop="days"></el-table-column>
      <el-table-column label="扣费" min-width="44" prop="amount"></el-table-column>
      <el-table-column label="续费时间" min-width="44" prop="creationdate"></el-table-column>
      <el-table-column align="right" label="续费状态" min-width="44">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.succeed">成功</el-tag>
          <el-tag type="danger" v-else>失败</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { sharedorderrenewalList } from '@/api/Order/Renewal'
import { getShareProductInfo } from '@/api/util'
export default defineComponent({
  name: 'renewal',
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
    const deviceList = getShareProductInfo()
    const searchSelect = ref({
      search: 'account',
    })
    const mixin_router_search = ref({
      fortest: false,
    })
    const mixin_select_search = ref({
      search: {
        account: '',
        articleequipmentsn: '',
        sharedorderid: '',
        renewalid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        account: '账号',
        articleequipmentsn: '设备',
        sharedorderid: '订单号',
        renewalid: '续费ID',
      },
    })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderrenewalList(rqData)
        list.value = res.records
        total.value = res.total
        // sharedorderrenewalList
        // sharedorderrene
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      deviceList,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-left: 24px;
    }
  }

  .el-select{
    width: 130px;
    /deep/.el-input{
      width: 130px;
    }
  }
}
</style>