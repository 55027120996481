
import { isObject } from '@/utils/is';
import type { App } from 'vue';
export function deepCopy(obj: any): any {
  if (!obj) return
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    // if (obj.hasOwnProperty(key)) {
    if (obj[key] != null && obj[key] != undefined) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        result[key] = deepCopy(obj[key]); //递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
}

/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: any): string {
  console.log('object :>> ', baseUrl, obj);
  let parameters = '';
  let url = '';
  for (const key in obj) {
    parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
  }
  parameters = parameters.replace(/&$/, '');
  if (/\?$/.test(baseUrl)) {
    url = baseUrl + parameters;
  } else {
    url = baseUrl.replace(/\/?$/, '?') + parameters;
  }
  return url;
}


export function deepMerge<T = any>(src: any, target: any): T {
  let key: string;
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
  }
  return src;
}

export function withInstall<T>(component: T) {
  const comp = component as any;
  comp.install = (app: App) => {
    app.component(comp.displayName || comp.name, comp);
  };
  return comp as T & Plugin;
}
// export const unwarp:<T> = (obj:T) => obj && (obj.__v_raw || obj.valueOf() || obj);
// export function unwarp<T>(obj: T): T {
//   return obj && (obj.__v_raw || obj.valueOf() || obj)
// }
export function unwarp(obj: any): any {
  return obj && (obj.__v_raw || obj.valueOf() || obj)
}