
import { computed, defineComponent, ref, toRef } from "vue";
import { upLoadfile } from "@/api/System";
import { userStore } from "@/store/modules/user";
import { ElMessage } from "element-plus";
export default defineComponent({
  props: ["data", "url"],
  emits: ["upAvatarSuccess"],
  setup(props, { emit }) {
    const visible = ref(false);
    const loading = ref(false);
    const result = ref<any>();
    const newUrl = ref<any>("");
    const imgSubmit = ref<any>();
    const data = toRef(props, "data");
    const url = toRef(props, "url");
    const UploadUrl = computed(() => {
      return url.value;
    });
    const Upload = async (e: any) => {
      let formData = new FormData();
      formData.append("file", e.file);
      formData.append("id", data.value.id);
      result.value = await upLoadfile(url.value, formData);
    };

    const isSuccess = (e: any) => {
      emit("upAvatarSuccess");
      ElMessage.success(result.value.data.message || "操作成功");
    };
    const isError = (e: any) => {
      console.log({ error: e });
    };
    const submit = () => {
      imgSubmit.value.submit();
    };
    return {
      visible,
      loading,
      isSuccess,
      Upload,
      UploadUrl,
      isError,
      imgSubmit,
      submit,
      newUrl,
    };
  },
});
