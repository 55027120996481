<template>
  <el-dialog :destroy-on-close="true" :title=title @closed="handleClose" align="center" class="dialog" v-model="visible" width="70%">
    <el-tabs v-model="activeName"  @tab-click="handleClick" >
      <el-tab-pane  label="待发放" name="0"></el-tab-pane>
      <el-tab-pane label="待领取" name="1"></el-tab-pane>
      <el-tab-pane label="待激活" name="2"></el-tab-pane>
      <el-tab-pane label="未使用" name="3"></el-tab-pane>
      <el-tab-pane label="已使用" name="4"></el-tab-pane>
      <el-tab-pane label="已失效" name="5"></el-tab-pane>
      <el-tab-pane label="已过期" name="6"></el-tab-pane>
    </el-tabs>
    <!-- <listVue ref="listRef"></listVue> -->
    <div class="container">
      <el-table :data="list" height="60vh" style="width: 100%" v-if="list">
        <el-table-column type="expand">
          <template #default="scope">
            <el-form class="table-expand" inline label-position="left" label-width="120px">
              <el-form-item label="优惠卷id">
                <span>{{ scope.row.id }}</span>
                <my-copy :data="scope.row.id" />
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="left" label="名称" min-width="40">
          <template #default="scope">
            {{scope.row.title}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="描述" min-width="60">
          <template #default="scope">
              {{scope.row.desc}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额" min-width="20">
          <template #default="scope">
            {{scope.row.usedAmount}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" min-width="30">
          <template #default="scope">
            <el-tag type="warning"  v-if="scope.row.usedStatus=='0'">待发放</el-tag>
            <el-tag type="warning" v-if="scope.row.usedStatus=='1'">待领取</el-tag>
            <el-tag type="info" v-if="scope.row.usedStatus=='2'">待激活</el-tag>
            <el-tag type="info" v-if="scope.row.usedStatus=='3'">未使用</el-tag>
            <el-tag type="success" v-if="scope.row.usedStatus=='4'">已使用</el-tag>
            <el-tag type="danger" v-if="scope.row.usedStatus=='5'">已失效</el-tag>
            <el-tag type="danger" v-if="scope.row.usedStatus=='6'">已过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="生效时间" min-width="40">
          <template #default="scope">
            {{scope.row.validStartTime}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="到期时间" min-width="40">
          <template #default="scope">
            {{scope.row.validEndTime}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="领取时间" min-width="40">
          <template #default="scope">
            {{scope.row.receiveTime?scope.row.receiveTime:"未领取"}}
          </template>
        </el-table-column>
        
      </el-table>
      <el-pagination 
        :current-page="page" 
        :page-size="limit" 
        :page-sizes="[10, 30, 50, 100]" 
        :total="total" 
        @current-change="pageChange" 
        @size-change="handleLimitChange" 
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed,readonly} from "vue";
import { CouponModel, getUserCouponList } from "@/api/Financial/coupon";
import listVue from "./list.vue";
export default defineComponent({
  name: "v-dialog",
  components: {
    listVue
  }, 
  setup() {
    const title=ref('优惠卷')
    const activeName = ref("0")
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const reLoading = ref(false);
    const userId = ref("");
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<CouponModel[]>([]);
    const handleClose = () => {
      visible.value = false;
    };
  
    const reData = async () => {
      console.log('页面改变='+page.value)
      try {
        reLoading.value = true;
        let rqData: any = { current: page.value, size: limit.value,order:'gmtCreate',sort:'desc',entity:{usedStatus: activeName.value,userId:userId.value}};
        const res = await getUserCouponList(rqData);

        list.value = res.rows;
        total.value = res.total;
      } finally {
        reLoading.value = false;
      }
    };

    const open = (userid: string,name:string) => {
      userId.value=userid;
      title.value=name+"的优惠卷",
      visible.value = true;
      reData();
     
    };
    
    const handleClick = async() => {
      page.value=1;
      reData();
    }
    const pageChange = (val:number) => {
      console.log("页面数据="+val)
      page.value=val;
      reData();
    }

    const handleLimitChange = (val:number) =>{
      console.log("分页改变",val)
      limit.value=val;
      reData();
    }

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      reLoading,
      reData,
      activeName,
      handleClick,
      title,
      limit,
      page,
      total,
      list,
      pageChange,
      handleLimitChange,
    };
  },
});
</script>
<style lang = "scss" scoped >
.re-data-btn {
  margin-left: 24px;
}
</style>