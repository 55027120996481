
import Rules from '@/common/Rules'
import {replaceUserPhone} from '@/api/User/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
let userId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const nickname = ref('')
    const oldPhone = ref('')
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      newAccount: [Rules.required()],
    }
    const form = ref({
      newAccount: '',
    })
    const open = (_userid: string,_oldPhone:string,_nickname:string) => {
      visible.value = true
      userId=_userid;
      nickname.value=_nickname;
      oldPhone.value=_oldPhone;
    }

    const submit = async () => {
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res= await replaceUserPhone(userId,form.value.newAccount)
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      nickname,
      oldPhone,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
    }
  },
})
