<template>
  <div class="user-info">
      <!-- 个人信息 -->
    <h3 style="margin-left: 48px;">个人信息</h3>
    <el-form label-width="220px">
       <el-row style="height:104px;">
        <el-col :span="12">
          <el-form-item label="头像"  class="from-item-label">
           <div class="avatar" @mouseenter="isHover=true" @mouseleave="isHover=false" @click="changeAvatar">
            <el-avatar v-if="userInfo.avatarurl" :size="88" shape="square">
              <span v-if="isHover" class="el-icon-edit">修改</span>
              <img v-else :src="userInfo.avatarurl" alt />
            </el-avatar>
            <span v-else class="el-dropdown-link">
              <div class="avatar-font" v-if="isHover" style="backgroundColor:#efefef;color:#3ea243;font-size:16px;">上传头像</div>
              <div class="avatar-font" v-else :style="{backgroundColor: '#efefef',color:'#3ea243'}">{{decodeURIComponent(userInfo.nickname).substring(0,1)}}</div>
            </span>
          </div>
          </el-form-item>
        </el-col>
      </el-row>  
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="用户名"  class="from-item-label">
            <span class="item-span">{{ userInfo.account }}</span>
            <!-- <el-input v-model="userInfo.account"  disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="注册时间"  class="from-item-label">
            <span class="item-span">{{ userInfo.regdate }}</span>
            <!-- <el-input v-model="userInfo.regdate" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="昵称"  class="from-item-label">
            <span class="item-span">{{ userInfo.nickname }}</span>
            <!-- <el-input v-model="userInfo.nickname" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="最后登录"  class="from-item-label">
            <span class="item-span">{{ userInfo.lastlogindate }}</span>
            <!-- <el-input v-model="userInfo.lastlogindate" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="性别"  class="from-item-label">
            <span class="item-span">{{ userInfo.sex }}</span>
            <!-- <el-input v-model="userInfo.sex" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否会员"  class="from-item-label">
            <span class="item-span">{{ userInfo.isvip }}</span>
            <!-- <el-input v-model="userInfo.isvip" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="用户角色"  class="from-item-label" style="margin-top: 16px;">
        <el-tag
            :key="tag"
            v-for="tag in userInfo.roleArray"
            :closable="false"
            :disable-transitions="false"
            style="margin-right: 16px;height:32px; line-height:32px;"
            >
            {{tag.rolename}}
        </el-tag>
      </el-form-item>
      
    </el-form>  
  </div>
</template>
<script lang="ts">
import { UserInfo } from "@/types/user";
import { AllWallets, userconsumewalletAll, getUserInfo } from "@/api/User";
import { defineComponent, ref,onMounted, toRef} from "vue"
import { toOptional } from "@/types/utils";
export default defineComponent({
  props: ["userid"],
  setup(props){
    const userInfo = ref<toOptional<UserInfo>>({});
    const isHover = ref(false);
    const userid = toRef(props, "userid");
    const reData = async () => {
      try {
       
        console.log("userid :>> ", userid);
        userInfo.value = await getUserInfo(userid.value);
        // const res = await userconsumewalletAll(userid.value);
        // console.log("res :>> ", res);
        // walletInfo.value = res.userAllWallets;
        // walletInfoTest.value = res.userAllWalletsTest;
      } finally {
        //loading.value = false;
      }
    };
    onMounted(() => {
      reData();
    });
    // const route = useRoute()
    // return{
    //   userid:route.query.userid
    // }
    return {
      userInfo,
      isHover,
    }
  }
})
</script>
<style  lang='scss' scoped>
  .user-info {
    margin-top: 16px;
    .textR {
      width: 150px;
    }
    .from-item-label{
       font-weight: bold;
    }
    padding-bottom: 100px;
  }
  .inputW {
    width: 300px;
  }
  .item-span{
    display: block;
    height: 42px;
    width: 290px;
    padding-left: 8px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    font-weight: 500;
    color: #808080;
  }
  .inline-info{
    height: 60px; 
  }

  .avatar-font {
    width: 88px;
    height: 88px;
    border-radius: 5px;
    box-shadow: #888888 1px 1px 2px;
    margin-right: 16px;
    display: flex;
    font-size: 40px;
    // font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }
  
</style>