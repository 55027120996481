
  import { defineComponent, ref, readonly, inject } from "vue";
  import routerChangeReData from "@/common/routerChangeReData";
  import {getM4sDevice,DeviceListRes,holdertype,} from "@/api/Share/Device";
  export default defineComponent({
    name: "Name",
    components: {
    },
    setup() {
      const loading = ref(false);
      const btnRemoveLoaidng = ref(false);
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const allHoldertype = holdertype;
      const list = ref<DeviceListRes[]>([]);
      const searchSelect = ref({
        search: "articleequipmentsn",
      });
      const mixin_select_search = ref({
        search: {
          articleequipmentsn: "",
          investorname: "",
          account:"",
          investoruserid:""
        },
      });
  
      const mixin_select_search_label = readonly({
        search: {
          articleequipmentsn: "设备SN",
          investorname: "投资人姓名",
          account: "手机号",
          investoruserid: "投资人ID"
        },
      });
  
      const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
  
      const mixin_router_search = ref({
        socketstatus: "",
        productname: "",
        privatized: "",
        lockcmd: "",
      });
      const reData = async (rq?: any) => {
        try {
          loading.value = true;
          let rqData: any = { current: page.value, size: limit.value };
          if (rq && JSON.stringify(rq) !== "{}") {
            rqData = { ...rqData, entity: rq };
          }
          const res = await getM4sDevice(rqData);
          total.value = res.total;
          list.value = res.records;
        } finally {
          loading.value = false;
        }
      };
      const {
        mixinReRouter,
        onPageChange,
        handleLimitChange,
        changSearch,
      } = routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      });
    
      const handelChange = () => {
        page.value = 1;
        mixinReRouter();
      };
     
  
  
      const exportFile = async(name:string) =>{
        // try {
        //   let searchData: any = {}
        //   let rqData: any = {fileName:'shareDevice'}
        //   let entity: any = {}
        //   searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
         
        //   console.log('导出参数=',searchData)
          
        //   Object.keys(searchData).forEach((key:any) => {
        //     if(searchData[key]){
        //       entity[key]=searchData[key]
        //     }
        //   })
        //   console.log('遍历之后=',entity)
          
        //   rqData = { ...rqData, entity: {...entity} }
  
        //   console.log('请求参数=',rqData)
         
  
        //   const res = await exportAnalyShareDeviceList(rqData)
        //   console.log('导表=',res.data)
        //   const blob = new Blob([res.data]);
        //   console.log('blob的值=',blob)
        //   saveAs(blob, name)
         
        // } finally {
         
        // }
      }
      return {
        mixin_select_search_label,
        mixin_select_search,
        mixin_router_search,
        searchSelect,
        list,
        loading,
        page,
        total,
        limit,
        handelChange,
        handleLimitChange,
        onPageChange,
        toPath_RouterSearchMixin,
        changSearch,
        allHoldertype,
        exportFile,
      };
    },
  });
  