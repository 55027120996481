
  import { defineComponent, ref, readonly, inject } from 'vue'
  import { listModel, cabientListHttp } from '@/api/fault/cabient'
  import routerChangeReData from '@/common/routerChangeReData'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { userStore } from '@/store/modules/user'
  import { getShareProductInfo } from '@/api/util'
  import cabinetDatailsDialog from '@/components/device/cabinetDatailsDialog.vue'
  import dayjs from 'dayjs'
  export default defineComponent({
    name: 'fault',
    components: {
        cabinetDatailsDialog
    },
    setup() {
      const cabinetDetailsDialogRef = ref<any>();
      const mixin_router_search = ref({
        careateBN:'',
        order: 'gmtCreate',
        sort: 'desc',
      })
      const mixin_select_search = ref({
        search: {
          panelSn:'', //设备sn
        },
      })
      const sheareProductInfo = getShareProductInfo()
      const mixin_select_search_label = readonly({
        search: {
          panelSn: '机柜SN', 
        },
      })
      const searchSelect = ref({search: 'panelSn'})
  
      const loading = ref(false)
      const limit = ref(10)
      const page = ref(1)
      const total = ref(0)
      const list = ref<listModel[]>([])
      const btnRemoveLoading = ref(false)
      const searchData = {
        ...mixin_router_search.value,
        ...mixin_select_search.value,
      }
  
      const reData = async (rq?: any) => {
        try {
          loading.value = true
           let rqData: any = { current: page.value, size: limit.value }
  
          if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
            }
            if (rq.careateBN) {
              rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
              rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
              delete rq.careateBN
            }
            rqData = { ...rqData, entity: rq }
          }
          const res = await cabientListHttp(rqData)
          list.value = res.rows
          total.value = res.total
        } finally {
          loading.value = false
        }
      }
      const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
        routerChangeReData({
          mixin_router_search,
          mixin_select_search,
          loading,
          limit,
          page,
          reData,
          searchSelect,
        })
      const handelChange = () => {
        page.value = 1
        mixinReRouter()
      }
     
      const showCabintDivece =(sn:string) =>{
        cabinetDetailsDialogRef.value.open(sn,null)
      }
      
      return {
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,   
        onPageChange,
        handelChange,
        handleLimitChange,
        mixinReRouter,
        searchSelect,
        changSearch,
        list,
        loading,
        btnRemoveLoading,
        page,
        limit,
        total,   
        sheareProductInfo,
        cabinetDetailsDialogRef,
        showCabintDivece,
      }
    },
  })
  