<template>
    <el-dialog :destroy-on-close="true" title="新增采购单" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
     
      <el-form :model="form" :rules="rules" label-width="100px" ref="formRef" style="margin-top: 16px;">
        <el-form-item label="采购数量" prop="quantity">
          <el-input style="width: 220px;" placeholder="请输入采购数量" v-model="form.quantity" text="number"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  <script lang = "ts" >
  import Rules from '@/common/Rules'
  import { ElMessage } from 'element-plus'
  import { defineComponent, ref } from 'vue'
  import { ceatePurchase } from "@/api/Investment/purchase"
  let investorOrderId='';
  export default defineComponent({
    name: 'v-dialog',
    emits: ['success'],
    setup(props, { emit }) {
      const visible = ref(false)
      const btnSumitLoading = ref(false)
      const formRef = ref()
      const handleClose = () => {
        visible.value = false
        formRef.value.resetFields()
        formRef.value.clearValidate()
      }
      const rules = {
        quantity: [Rules.required()],
        descriptiondetails: [Rules.required()],
        identificationId: [Rules.required()],
      }
      const form = ref({
        quantity: '',
      })
      const open = (_id: string) => {
        visible.value = true
        investorOrderId=_id
      }
  
      const submit = async () => {
       
        try {  
          const res= await ceatePurchase ({investmentOrderId:investorOrderId,quantity:form.value.quantity}) 
          console.log('新采购单='+res)
          handleClose()
          emit('success',res.msg)
        } finally {
          btnSumitLoading.value = false
        }
      }
      return {
        visible,
        handleClose,
        open,
        btnSumitLoading,
        form,
        rules,
        formRef,
        submit,
      }
    },
  })
  </script>
  <style lang = "scss" scoped >
  </style>