<template>
  <div class="user-info">
      <!-- 钱包信息 -->
    <h3 style="margin-left: 48px;">钱包信息</h3>
    <el-form label-width="220px">
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="正式钱包"  class="from-item-label">
            <span v-if="walletInfo&&walletInfo.amount" class="item-span">{{ walletInfo.amount }}</span>
            <span v-else class="item-span">无</span>
            <!-- <el-input v-model="walletInfo.amount"  disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="正式冻结金额"  class="from-item-label">
            <span v-if="walletInfo&&walletInfo.frozenamount" class="item-span">{{ walletInfo.frozenamount }}</span>
            <span v-else class="item-span">无</span>
            <!-- <el-input v-model="walletInfo.frozenamount" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="测试钱包"  class="from-item-label">

            <span v-if="walletInfoTest&&walletInfoTest.amount" class="item-span">{{ walletInfoTest.amount }}</span>
            <span v-else class="item-span">无</span>
            <!-- <el-input v-model="walletInfoTest.amount" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="测试冻结金额"  class="from-item-label">
            <span v-if="walletInfoTest&&walletInfoTest.frozenamount" class="item-span">{{ walletInfoTest.frozenamount }}</span>
            <span v-else class="item-span">无</span>
            <!-- <el-input v-model="walletInfoTest.frozenamount" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="商城积分"  class="from-item-label">
            <span class="item-span">暂无</span>
            <!-- <el-input v-model="walletInfo.profit" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="普通积分"  class="from-item-label">
            <span class="item-span">0</span>
            <!-- <el-input v-model="walletInfo.points" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="收益"  class="from-item-label">
            <span class="item-span">{{parseInt(vip)>0 ?investor.totalprofit?investor.totalprofit:0:"0"}}</span>
            <!-- <el-input v-model="walletInfo.profit" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="提现"  class="from-item-label">
            <span class="item-span">{{parseInt(vip)>0 ?investor.totalwithdraw?investor.totalwithdraw:0:normal.totalwithdraw?normal.totalwithdraw:0}}</span>
            <!-- <el-input v-model="walletInfo.points" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="inline-info">
        <el-col :span="8">
          <el-form-item label="充值"  class="from-item-label">
            <span class="item-span">{{parseInt(vip)>0 ?investor.totalchange?investor.totalchange:0:normal.totalchange?normal.totalchange:0}}</span>
            <!-- <el-input v-model="walletInfo.profit" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="消费"  class="from-item-label">
            <span class="item-span">{{parseInt(vip)>0 ?investor.totalcost?investor.totalcost:0:normal.totalcost?normal.totalcost:0}}</span>
            <!-- <el-input v-model="walletInfo.points" disabled class="inputW" /> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>  
  </div>
</template>
<script lang="ts">
import { AllWallets, userconsumewalletAll, WalletType } from "@/api/User";
import { userModel,getUserStatistics} from "@/api/Financial/user";
import { investorModel,getInvestorStatistics} from "@/api/Financial/investor";
import { defineComponent, ref,onMounted, toRef} from "vue"
import { toOptional } from "@/types/utils";
export default defineComponent({
  props: ["userid","vip"],
  setup(props){
    const walletInfo = ref<toOptional<WalletType>>({});
    const walletInfoTest = ref<toOptional<WalletType>>({});
    const normal = ref<toOptional<userModel>>({});
    const investor = ref<toOptional<investorModel>>({});
    const userid = toRef(props, "userid");
    const vip = toRef(props, "vip");
    const reData = async () => {
      try {
        console.log("userid :>> ", userid);
       
        const res = await userconsumewalletAll(userid.value);
        console.log("钱包res :>> ", res);
        walletInfo.value = res.userAllWallets['M4S2'];
        walletInfoTest.value = res.userAllWalletsTest['M4S2'];
        if(vip.value!=null){
          console.log('是否会员11=',parseInt(vip.value))
          if(parseInt(vip.value)>0){  //是会员
            reInvestorUser();
          }else{
            reNormalUser();
          }
        }
      } finally {
        //loading.value = false;
      }
    };

    const reNormalUser = async () => {
     
      try {
        let rqData: any = { currentPage: "1", size: "10", fortest:false,userid:userid.value };
        const res = await getUserStatistics(rqData);
        normal.value = res.data.records[0];     
      } finally {
      }
    };
    const reInvestorUser = async () => {  
      try {
        let rqData: any = { currentPage: "1", size: "10", fortest:false, userid:userid.value};
        const res = await getInvestorStatistics(rqData);    
        investor.value = res.data.records[0];   
      } finally {
       
      }
    };
    onMounted(() => {
      reData();
    });
    // const route = useRoute()
    // return{
    //   userid:route.query.userid
    // }
    return {
      walletInfo,
      walletInfoTest,
      vip,
      normal,
      investor,
    }
  }
})
</script>
<style  lang='scss' scoped>
  .user-info {
    margin-top: 16px;
    .textR {
      width: 150px;
    }
    .from-item-label{
       font-weight: bold;
    }
    padding-bottom: 100px;
  }
  .inputW {
    width: 300px;
  }
 .item-span{
    display: block;
    height: 42px;
    width: 290px;
    padding-left: 8px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    font-weight: 500;
    color: #808080;
  }
  .inline-info{
    height: 60px; 
  }
  
</style>