
  import Rules from '@/common/Rules'
  import { ElMessage } from 'element-plus'
  import { defineComponent, ref } from 'vue'
  import { ceatePurchase } from "@/api/Investment/purchase"
  let investorOrderId='';
  export default defineComponent({
    name: 'v-dialog',
    emits: ['success'],
    setup(props, { emit }) {
      const visible = ref(false)
      const btnSumitLoading = ref(false)
      const formRef = ref()
      const handleClose = () => {
        visible.value = false
        formRef.value.resetFields()
        formRef.value.clearValidate()
      }
      const rules = {
        quantity: [Rules.required()],
        descriptiondetails: [Rules.required()],
        identificationId: [Rules.required()],
      }
      const form = ref({
        quantity: '',
      })
      const open = (_id: string) => {
        visible.value = true
        investorOrderId=_id
      }
  
      const submit = async () => {
       
        try {  
          const res= await ceatePurchase ({investmentOrderId:investorOrderId,quantity:form.value.quantity}) 
          console.log('新采购单='+res)
          handleClose()
          emit('success',res.msg)
        } finally {
          btnSumitLoading.value = false
        }
      }
      return {
        visible,
        handleClose,
        open,
        btnSumitLoading,
        form,
        rules,
        formRef,
        submit,
      }
    },
  })
  