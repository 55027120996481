
import { defineComponent, ref } from "vue";
import { getPlaceDetails,getPlaceDevices } from "@/api/Share/Place"
let plactId="";
export default defineComponent({
  name: "v-device-dialog",
  setup() {
    const visible = ref(false);
    const name = ref("");
    const images = ref<string[]>([]);
    const deviceInfo = ref<any>();
    const deviceList = ref<any[]>([]);
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const open = (_placeid:string,_name:string) => {
      visible.value = true;
      name.value=_name;
      plactId=_placeid;
      images.value=[];
      deviceInfo.value="";
      deviceList.value=[];
      reData();
      queryPlaceDevices();
    };
    const reData = async () => {
      let reData : any={placeid:plactId}
      const res= await getPlaceDetails(reData);
      deviceInfo.value=res.record;
      images.value=res.record.signboardUrls;
      console.log('图片地址=',images.value)
    };
   
    const queryPlaceDevices = async() =>{
      let reData : any={current:1,size:10,entity:{placeid:plactId}}
      const res = getPlaceDevices(reData);
      deviceList.value=(await res).records;
    }

    return {
      visible,
      handleClose,
      open,
      name,
      images,
      deviceInfo,
      deviceList,
    };
  },
});
