
import { getToken } from '@/utils/localStore'
import { computed, defineComponent, ref, toRef } from 'vue'
export default defineComponent({
  name: 'firmwareUpload',
  props: ['url', 'data', 'productid', 'acceptType'],
  emits: ['success'],
  setup(props, { emit }) {
    const row = toRef(props, 'data')
    const fileSubmitRef = ref<any>()
    const getUrl = computed(() => {
      const url =
        process.env.NODE_ENV === 'production'
          ? '/v1/file/firmware/upload'
          : '/xmjgateway/v1/file/firmware/upload'
      return `${url}?token=${getToken()}`
    })
    const rqData = computed(() => {
      return {
        productid: props.productid,
        version: row.value.version,
        description: row.value.description,
      }
    })

    // const upload = async (e: any) => {
    //   let formData = new FormData()
    //   formData.append('file', e.file)
    //   formData.append('productid', props.productid)
    //   formData.append('version', row.value.version)
    //   formData.append('description', row.value.description)
    //   try {
    //     this.result = await axios.postFromData(
    //       this.url + `?token=${Util.auth.getToken()}`,
    //       formData
    //     )
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }

    const isSuccess = () => {
      emit('success')
    }
    const isError = (e: any) => {
      console.log({ error: e })
    }
    const submit = () => {
      fileSubmitRef.value.submit()
    }
    return { getUrl, rqData, isError, isSuccess, submit, fileSubmitRef }
  },
})
