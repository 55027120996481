<template>
  <el-dialog
    :title="`查看小魔夹持有人`"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-form :model="inputData"  label-width="120px" ref="fromRef">
      <el-form-item label="账号" prop="account">
        <span class="item-span">{{inputData.account}}</span>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <span class="item-span">{{inputData.name}}</span>
      </el-form-item>
      <el-form-item label="用户id" prop="userid">
        <span class="item-span">{{inputData.userid}} <my-copy :data="inputData.userid"></my-copy></span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, nextTick, readonly, ref } from "vue";
import { userRoleList } from '@/api/User/role'
import { userList } from "@/api/User/index";
export default defineComponent({
  name: "v-dialog",
  emits:['success'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const inputData = ref({
      account: "", 
      name: "",
      userid: ""
    });
   
    const handleClose = async () => {  
      await nextTick();
      visible.value = false;

    };
    const open = async (row: any) => {
      inputData.value.userid=row.holderuserid;
      if(row.holdertype=='consumer'){
        await getNormalUser(row.holderuserid)
      }else {
        await getOtherUser(row.holderuserid,row.holdertype)
      }
      visible.value = true;
    };
    const getNormalUser = async (userid: string) => { 
      let rqData: any = { page: 1, limit: 10, userid: userid};
      const res = await userList(rqData);
      const list = res.result;
      const item=ref<any>();
      item.value=list[0];
      inputData.value.account=item.value.account;
      inputData.value.name=item.value.nickname;
    };
    const getOtherUser = async (userid: string,type:string) => {
      let rqData: any = { page: 1, limit: 10 }
      rqData[`${type}userid`] = userid
      const res = await userRoleList(rqData, type)
      const list = res.result;
      const item=ref<any>();
      item.value=list[0];
      inputData.value.account=item.value[`${type}account`]||item.value.account;
      inputData.value.name=item.value[`${type}name`]||item.value.name;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit
    };
  },
});
</script>
<style lang = "scss" scoped >
 .item-span{
    display: block;
    height: 42px;
    width: 290px;
    padding-left: 8px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    font-weight: 500;
    color: #808080;
  }
</style>