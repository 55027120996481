
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { DeviceReleaseModel, getDeviceReleaseList } from '@/api/Release/Device'
// import addDialog from './components/addDialog.vue'
// import upDataDialog from './components/upDataDialog.vue'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'Name',
  components: {
    // 'v-add-dialog': addDialog,
    // 'v-up-data-dialog':upDataDialog,
  },
  setup() {
    const loading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<DeviceReleaseModel[]>([])
    const addDialogRef = ref<any>()
    const upDataDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'articleequipmentsn',
    })
    const mixin_select_search = ref({
      search: {     
        articleequipmentsn: '', //共享物品的设备序号
        supplieraccount: '', //插口序号，如：小魔柜 01~12 雨伞架 01~09
        supplieruserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        supplieraccount: '发布人手机号',
        supplieruserid: '发布人id',
      },
    })

    const mixin_router_search = ref({ 
      order: 'creationdate',
      sort: 'desc',
      fortest:'',
      withredenvelope:'',
      locked:'',
      releasing:'',
    })

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
          }
        }
        const res = await getDeviceReleaseList(rqData)
        total.value = res.total
        list.value = res.records
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }

    const handleUpdata = (index: number,row:any) =>{
      upDataDialogRef.value.open(index, row)
    }

    const addSuccess = (data: { index: number; data: DeviceReleaseModel }) => {
      if (typeof data.index == 'number' && data.index > -1) {
        list.value[data.index] = data.data
      } else {
        list.value.push(data.data)
      }
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      // try {
      //   btnRemoveLoaidng.value = true
      //   const res = await removeSocket({
      //     panelequipmentsn,
      //   })
      //   list.value.splice(index, 1)
      //   ElMessage.success(res.message)
      // } finally {
      //   btnRemoveLoaidng.value = false
      // }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleUpdata,
      handleRemove,
      addSuccess,
      addDialogRef,
      upDataDialogRef,
      btnRemoveLoaidng,
    }
  },
})
