import type { App } from 'vue';
import 'element-plus/lib/theme-chalk/index.css';
// import lang from 'element-plus/lib/locale/lang/zh-cn'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import {
    ElAlert,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElButton,
    ElPopconfirm,
    ElNotification,
    ElIcon,
    ElImage,
    ElInput,
    ElInputNumber,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElTable,
    ElTableColumn,
    ElTabs,
    ElTabPane,
    ElTag,
    ElTimePicker,
    ElTimeSelect,
    ElInfiniteScroll,
    ElForm,
    ElFormItem,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElAvatar,
    ElDropdown,
    ElDropdownItem,
    ElSubmenu,
    ElDropdownMenu,
    ElRadioGroup,
    ElRadioButton,
    ElSwitch,
    ElDialog,
    ElSelect,
    ElTree,
    ElOption,
    ElDatePicker,
    ElPagination,
    ElAutocomplete,
    ElPopover,
    ElCol,
    ElRow,
    ElCascader,
    locale,
    ElTooltip,
    ElProgress,
    ElCard,
    ElScrollbar,
    ElUpload,
    ElDrawer,
    ElSteps,
    ElStep,
    ElDescriptions,
    ElDescriptionsItem,
    ElResult,
    ElCarousel,
    ElCarouselItem,
    ElImageViewer,
    ElRadio,
    ElCheckbox,
} from 'element-plus';

export function setupElement(app: App<Element>): void {
    locale(zhCn)
    app.component(ElAlert.name, ElAlert);
    app.component(ElMessage.name, ElMessage);
    app.component(ElMessageBox.name, ElMessageBox);
    app.component(ElButton.name, ElButton);
    app.component(ElPopconfirm.name, ElPopconfirm);
    app.component(ElMessageBox.name, ElMessageBox);
    app.component(ElImage.name, ElImage);
    app.component(ElIcon.name, ElIcon);
    app.component(ElInput.name, ElInput);
    app.component(ElInputNumber.name, ElInputNumber);
    app.component(ElMenu.name, ElMenu);
    app.component(ElMenuItem.name, ElMenuItem);
    app.component(ElMenuItemGroup.name, ElMenuItemGroup);
    app.component(ElTable.name, ElTable);
    app.component(ElTableColumn.name, ElTableColumn);
    app.component(ElTabs.name, ElTabs);
    app.component(ElTabPane.name, ElTabPane);
    app.component(ElTag.name, ElTag);
    app.component(ElTimePicker.name, ElTimePicker);
    app.component(ElTimeSelect.name, ElTimeSelect);
    app.component(ElForm.name, ElForm);
    app.component(ElFormItem.name, ElFormItem);
    app.component(ElBreadcrumb.name, ElBreadcrumb);
    app.component(ElBreadcrumbItem.name, ElBreadcrumbItem);
    app.component(ElAvatar.name, ElAvatar);
    app.component(ElDropdown.name, ElDropdown);
    app.component(ElDropdownItem.name, ElDropdownItem);
    app.component(ElSubmenu.name, ElSubmenu);
    app.component(ElDropdownMenu.name, ElDropdownMenu);
    app.component(ElRadioGroup.name, ElRadioGroup);
    app.component(ElRadioButton.name, ElRadioButton);
    app.component(ElSwitch.name, ElSwitch);
    app.component(ElDialog.name, ElDialog);
    app.component(ElSelect.name, ElSelect);
    app.component(ElTree.name, ElTree);
    app.component(ElOption.name, ElOption);
    app.component(ElDatePicker.name, ElDatePicker);
    app.component(ElPagination.name, ElPagination);
    app.component(ElAutocomplete.name, ElAutocomplete);
    app.component(ElPopover.name, ElPopover);
    app.component(ElCol.name, ElCol);
    app.component(ElRow.name, ElRow);
    app.component(ElCascader.name, ElCascader);
    app.component(ElTooltip.name, ElTooltip);
    app.component(ElProgress.name, ElProgress);
    app.component(ElCard.name, ElCard);
    app.component(ElScrollbar.name, ElCard);
    app.component(ElUpload.name, ElUpload);
    app.component(ElDrawer.name,ElDrawer);
    app.component(ElSteps.name,ElSteps);
    app.component(ElStep.name,ElStep);
    app.component(ElDescriptions.name,ElDescriptions);
    app.component(ElCarousel.name,ElCarousel);
    app.component(ElCarouselItem.name,ElCarouselItem);
    app.component(ElDescriptionsItem.name,ElDescriptionsItem);
    app.component(ElResult.name,ElResult);
    app.component(ElImageViewer.name,ElImageViewer);
    app.component(ElRadio.name,ElRadio);
    app.component(ElCheckbox.name,ElCheckbox);
    
    app.config.globalProperties.$loading = ElLoading;
    app.config.globalProperties.$msgbox = ElMessageBox;
    app.config.globalProperties.$alert = ElAlert;
    app.config.globalProperties.$confirm = ElPopconfirm;
    app.config.globalProperties.$notify = ElNotification;
    app.config.globalProperties.$message = ElMessage;

}
