import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"

export interface UserguaranteeorderList {
    "account": string,
    "creationdate": string
    "enabled": boolean,
    "guaranteeno": string,
    "guaranteetype": string,
    "modificationdate": string,
    "sharedorderid": string,
    "userid": string
}

export async function userguaranteeorderList(params: ListReq): Promise<ListRes<UserguaranteeorderList>> {
    params.token = true
    const res = await defHttp.request<ListRes<UserguaranteeorderList>>(
        {
            url: `/userguaranteeorder/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.modificationdate = dayjs(v.modificationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}
export async function wechatpayscoreorderComplete(outorderno: string): Promise<ListRes<RequestRes>> {
    const res = await defHttp.request<ListRes<RequestRes>>(
        {
            url: `/wechatpayscoreorder/complete`,
            params: {
                token: true,
                outorderno
            }
        }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        prefix: "/shared"
    }
    );
    return res
}
export async function alipayfundauthorderComplete(outorderno: string): Promise<ListRes<RequestRes>> {
    const res = await defHttp.request<ListRes<RequestRes>>(
        {
            url: `/alipayfundauthorder/complete`,
            params: {
                token: true,
                outorderno
            }
        }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        prefix: "/shared"
    }
    );
    return res
}