<template>
 <div class="container">
    <div>
        订单租借统计
        <orderEcharts></orderEcharts>
    </div>
    <div>
        登录注册统计
        <loginEcharts></loginEcharts>
    </div>
 </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted,nextTick} from "vue";
import orderEcharts from './components/orderEcharts.vue'
import loginEcharts from './components/loginEcharts.vue'
export default defineComponent({
    name: "Name",
    components: {
        orderEcharts,
        loginEcharts
    },
    setup() {
        return {
           
        }
    }
})
</script>
<style lang="scss" scoped>
.container {
    padding: 18px;

    
  }
</style>