<template>
  <div class="container">
    <header>
      <el-select @change="handelChange" clearable placeholder="设备类型" v-model="mixin_router_search.productid" value-key="productid">
        <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of myProductInfoList"></el-option>
      </el-select>
    </header>
    <el-table :data="list" @expand-change="handleOpen" height="80vh" row-key="productid" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="auto" v-if="scope?.row.firmwareDetail">
            固件信息
            <el-form-item label="版本">
              <el-tag type="info">V {{scope.row.firmwareDetail.version}}</el-tag>
            </el-form-item>
            <el-form-item label="更新">{{scope.row.firmwareDetail.modificationtime}}</el-form-item>
            <el-form-item label="描述">
              <el-input :autosize="{ minRows: 2}" disabled type="textarea" v-model="scope.row.firmwareDetail.description"></el-input>
            </el-form-item>
            <el-form-item label="操作">
              <!-- <a :href="scope.row.firmwareDetail.url" v-if="!scope.row.firmwareDetail.message">
                <el-button size="mini" type="primary">下载固件</el-button>
              </a> -->
              <el-button
                @click="downLoadFirmware(scope.$index,scope.row.firmwareDetail.url)"
                class="up"
                size="mini"
                type="primary"
                v-if="scope.row.firmwareDetail?.url"
              >下载固件</el-button>
            </el-form-item>
          </el-form>
          <div v-else>暂无固件信息</div>
        </template>
      </el-table-column>
      <el-table-column label="产品名称" min-width="44" prop="name"></el-table-column>
      <el-table-column label="type" min-width="44" prop="type"></el-table-column>
      <el-table-column label="型号" min-width="44" prop="model"></el-table-column>
      <el-table-column label="ID" min-width="44">
        <template #default="scope">
          {{scope.row.productid}}
          <my-copy :data="scope.row.productid" />
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="44">
        <template #default="scope">
          <el-button @click="handleUpdateVersionDialog(scope.row.productid)" class="up" size="mini" type="primary" :loading="updateVersionLoading" 
           v-if="scope.row.productid=='811103983bcffe227dc6dddec51f468e'">设置版本</el-button>
          <el-button @click="handleUpdateDialog(scope.row)" class="up" size="mini" type="primary" v-else>上传固件</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <firmware-dialog @success="mixinReRouter()" ref="firmwareDialogRef"></firmware-dialog>
    <editVersionDialog @success="handlerUpdateVersion" ref="editVersionDialogRef"></editVersionDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import firmwareDialog from './components/firmwareDialog.vue'
import { firmwareLast, ProductList, productList,updateXMJVersion } from '@/api/System/product'
import { saveAs } from 'file-saver'
import { ProductInfo, productInfoList } from '@/api/util'
import editVersionDialog from './components/editVersionDialog.vue'
import { ElMessage } from "element-plus";
import axios from 'axios'
import { defHttp } from '@/utils/http/axios'
export default defineComponent({
  name: 'product',
  components: { firmwareDialog,editVersionDialog },
  setup() {
    const loading = ref(false)
    const downloadLoading = ref(false)
    const updateVersionLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<ProductList[]>([])
    const firmwareDialogRef = ref<any>()
    const editVersionDialogRef = ref<any>()
    const searchSelect = ref({})
    const myProductInfoList = productInfoList
    const mixin_router_search = ref({ productid: '' })
    const mixin_select_search = ref({ search: {} })
    const mixin_select_search_label = readonly({ search: {} })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, ...rq }
        }
        const { result, totalCount } = await productList(rqData)
        list.value = result
        total.value = totalCount
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleUpdateDialog = (row: ProductInfo) => {
      firmwareDialogRef.value.open(row)
    }
    const handleUpdateVersionDialog = (productId:string) => {
      editVersionDialogRef.value.open(productId);
    }
    const handleOpen = async (e: ProductList) => {
      console.log('e :>> ', e)
      list.value[e.idx].open = !list.value[e.idx].open // 开启关闭状态
      if (!list.value[e.idx].open) return
      const firmwareDetail = await firmwareLast(e.productid)
      list.value[e.idx].firmwareDetail = firmwareDetail
    }
    const handlerUpdateVersion = async(productId:string,version:string) => {
      try{
          console.log('产品id=',productId);
          console.log('版本号=',version);
          updateVersionLoading.value = true;
          const res =  await updateXMJVersion({ productid:productId,version:version });
          ElMessage.success(res);
        } finally {
          updateVersionLoading.value = false;
        }
    }

    const downLoadFirmware = async (index: number, url: string) => {
      try {
        downloadLoading.value = true
        const item = list.value[index]
        // const res = (await axios({
        //   url:url.replace("http://xmjoss.oss-cn-hangzhou.aliyuncs.com/",'aliyuncs/'),
        //   method: 'GET',
        //   responseType: 'blob',
        // })) as unknown as Blob
        // const blob = new Blob([res], { type: 'application/octet-stream' })
        // const res = (await axios.get(url, {
        //   responseType: 'blob',
        // })) as unknown as Blob
        // console.log('res :>> ', res)
        saveAs(url, `${item.name}_v_${item.firmwareDetail?.version}.bin`)
        // saveAs(blob, `${item.name}_v_${item.firmwareDetail?.version}.bin`)
        // URL.revokeObjectURL(url)
      } finally {
        downloadLoading.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      updateVersionLoading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      myProductInfoList,
      firmwareDialogRef,
      editVersionDialogRef,
      handleOpen,
      handleUpdateDialog,
      handleUpdateVersionDialog,
      downLoadFirmware,
      handlerUpdateVersion,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    width: 320px;
    .el-select {
      width: 150px;
    }
    ~ * {
      margin-left: 24px;
    }
  }
  .table-expand {
    width: 700px;
    .download {
      text-decoration: none;
      color: #fff;
    }
  }
}
</style>