<template>
  <el-dialog :destroy-on-close="true" title="导出表格" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
   
    <el-form :model="form" :rules="rules" label-width="100px" ref="formRef" style="margin-top: 16px;">
      <el-form-item label="表格名称" prop="content">
        <el-input style="width: 220px;" placeholder="请输入表格名称" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="表格类型" >
        <el-radio v-model="radio" label="1">XLS</el-radio>
        <el-radio v-model="radio" label="2">XLSX</el-radio>
        <!-- <el-radio v-model="radio" label="3">EXCEL</el-radio> -->
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['export'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const radio = ref('1')
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      name: [Rules.required()],
      descriptiondetails: [Rules.required()],
      identificationId: [Rules.required()],
    }
    const form = ref({
      name: '',
    })
    const open = (name: string) => {
      visible.value = true
      form.value.name=name
    }

    const submit = async () => {
      let fileType="";
      if(radio.value=='1'){
        fileType="xls"
      }else if(radio.value=='2'){
        fileType="xlsx";
      }else if(radio.value=='3'){
        fileType="excel";
      }
      try {
        handleClose()
        emit('export',`${form.value.name}.${fileType}`)
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
      radio,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>