const url = {
    baseUrl: 'https://xmjgateway.yunqijingdian.com',
    login: 'https://xmjlogin.yunqijingdian.com/',
    console:'http://xmjconsole2.yunqijingdian.com',
    default: 'https://xmjlogin.yunqijingdian.com/v2/rbac/',
    tran: 'https://xmjgateway.yunqijingdian.com/v1/tran/',
    shared: 'https://xmjgateway.yunqijingdian.com/v1/shared/',
    // baseUrl: 'https://uat.xmjgateway.smartline.com.cn',
    // login: 'https://uat.xmjlogin.smartline.com.cn/',
    // console:'https://uat.xmjconsole2.smartline.com.cn',
    // baseUrl: 'https://xmbdata.smartline.com.cn:8441',
    // login: 'https://xmbdata.smartline.com.cn:8341/',
    // console:'http://xmbdata.smartline.com.cn:8442',
}
module.exports = url