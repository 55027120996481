<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-select" clearable placeholder="维修状态" v-model="mixin_router_search.status">
        <el-option label="待接收" value="0"></el-option>
        <el-option label="维修中" value="1"></el-option>
        <el-option label="已完成" value="2"></el-option>
      </el-select>     
     <el-select @change="handelChange" class="header-select" clearable placeholder="维修结果" v-model="mixin_router_search.result">
        <el-option label="已修好" value="0"></el-option>
        <el-option label="修不好" value="1"></el-option>
      </el-select>   

       <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="上报时间"
        range-separator="至"
        start-placeholder="上报时间"
        type="datetimerange"
        unlink-panels        
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">新增维修单</el-button>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">                      
            <el-form-item label="录入用户id" v-if="scope.row.inUserId">
              <span>{{ scope.row.inUserId }}</span>
              <my-copy :data="scope.row.inUserId"></my-copy>
            </el-form-item>
            <el-form-item label="完结用户id" v-if="scope.row.outUserId">
              <span>{{ scope.row.outUserId }}</span>
              <my-copy :data="scope.row.outUserId"></my-copy>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="序号" width="90">
        <template #default="scope">        
          {{scope.row.id}}
          <my-copy :data="scope.row.id"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="录入SN" align="center" width="210">
        <template #default="scope">
          <el-tag v-if="scope.row.productid">{{productList[productIdList.indexOf(scope.row.productid)].name}}</el-tag>
          <!-- <el-tag v-if="scope.row.productId=='811103983bcffe227dc6dddec51f468e'">三代小魔夹</el-tag>
          <el-tag v-if="scope.row.productId=='56c62a11a6a14d6eb7ab29fbf9f2f8b4'">二代小魔夹</el-tag>
          <el-tag v-if="scope.row.productId=='a2ea3faa86274d3f966ff30aad68b208'">雨伞架</el-tag>
          <el-tag v-if="scope.row.productId=='d965476559f0428483b03f08941cda39'">共享雨伞</el-tag>
          <el-tag v-if="scope.row.productId=='0a9edc30d4924f989fd9207bf62e6d23'">便民柜</el-tag>
          <el-tag v-if="scope.row.productId=='6951e86506725baaf4dfcdf9aa015395'">三代机柜</el-tag>
          <el-tag v-if="scope.row.productId=='9775f7dd993646eaa4977537b0735e8f'">二代机柜</el-tag>
          <el-tag v-if="scope.row.productId=='a2ea3faa86274d3f966ff30aad68b208'">雨伞架</el-tag>
          <el-tag v-if="scope.row.productId=='d4b8d2d2df5742ecbec9398d5409dba9'">便民柜</el-tag>
          <el-tag v-if="scope.row.productId=='fd76cb9e939a4e6f92b4a867f0c19d2f'">便民柜</el-tag> -->
          -{{scope.row.inEquipmentSn}}
          <my-copy :data="scope.row.inEquipmentSn"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="故障原因" align="center" width="120">
        <template #default="scope">
          {{scope.row.reason?scope.row.reason:'未知'}}
        </template>
      </el-table-column>
      <el-table-column label="替换SN" align="center" width="120">
        <template #default="scope" >
         <el-tag effect="dark" type='info' v-if="scope.row.outEquipmentSn">
            {{scope.row.outEquipmentSn}}
            <my-copy :data="scope.row.outEquipmentSn" ></my-copy>
          </el-tag>       
        </template>
      </el-table-column>
      <el-table-column label="返回场地" align="center" width="240">
        <template #default="scope">
          {{scope.row.revokePlace?scope.row.revokePlace:'未知'}}
        </template>
      </el-table-column>
      <el-table-column label="录入账号" align="center" width="160">
        <template #default="scope">{{scope.row.inUserAccount}}</template>
      </el-table-column>
      <el-table-column label="完结账号" align="center" width="160">
        <template #default="scope">{{scope.row.outUserAccount}}</template>
      </el-table-column>
      <el-table-column align="center" label="维修状态" width="120">
        <template #default="scope">
          <el-tag  type="info" v-if="scope.row.status==0">待接收</el-tag>    
          <el-tag  type="waring" v-if="scope.row.status==1">维修中</el-tag>    
          <el-tag  type="success"   v-if="scope.row.status==2">已完成</el-tag>
        </template>
      </el-table-column>
       <el-table-column align="center" label="维修结果" width="90">
         <!-- <i class="el-icon-success el-icon"></i> -->
         <!-- <el-result icon="success" class="ic-result"></el-result> -->
        <template #default="scope">
          <i class="el-icon-success el-icon" v-if="scope.row.result==0"></i>
          <i class="el-icon-error el-icon" style="color: #F56C6C;" v-if="scope.row.result==1"></i>
          <!-- <el-tag  type="success" v-if="scope.row.result==0">已修好</el-tag>    
          <el-tag  type="waring" v-if="scope.row.result==1">修不好</el-tag>     -->
        </template>
      </el-table-column>
      
      <el-table-column label="完结时间" align="center" width="180">
        <template #default="scope">{{scope.row.gmtComplete}}</template>
      </el-table-column>
      <el-table-column label="录入时间" align="center" width="180">
        <template #default="scope">{{scope.row.gmtCreate}}</template>
      </el-table-column>
     
      <el-table-column align="left" label="操作" fixed="right" width="290">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button
            @click="handleComfirm(scope.$index,scope.row)"
            size="mini"
            type="primary"
            style="margin-right: 6px;"
            v-if="scope.row.status=='0'"
          >确认接收</el-button>
          <el-button
            @click="handleComplete(scope.$index,scope.row)"
            size="mini"
            type="success"
            style="margin-right: 6px;"
            v-if="scope.row.status=='1'"
          >维修完成</el-button>
          <el-button
            @click="handleReplace(scope.$index,scope.row)"
            size="mini"
            type="danger"
            style="margin-right: 6px;"
            v-if="scope.row.status=='1'&&!scope.row.outEquipmentSn"
          >替换SN</el-button>
          <el-button
            @click="handleDetails(scope.$index,scope.row)"
            size="mini"           
          >查看详情</el-button>
         
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-repair-dialog @success="handelChange()" ref="addRepairDialogRef"></add-repair-dialog>
    <complete-repair-dialog @success="handelChange()" ref="completeDialogRef"></complete-repair-dialog>
    <replace-sn-dialog @success="handelChange()" ref="replaceDialogRef"></replace-sn-dialog>
    <repair-details-drawer ref="detailsDrawerRef"></repair-details-drawer>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { repairListModel, repairListHttp, confirmHttp,exportEqmaintainList } from '@/api/fault/repair'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'

import addRepairDialog from './components/addRepairDialog.vue'
import completeRepairDialog from './components/completeRepairDialog.vue'
import replaceSnDialog from './components/replaceSnDialog.vue'
import repairDetailsDrawer from './components/repairDetailsDrawer.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import {getProductInfoList,getProductIdList} from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'fault-order',
  components: {
   addRepairDialog,
   completeRepairDialog,
   replaceSnDialog,
   repairDetailsDrawer,
   exportDialog
  },
  setup() {
    const addRepairDialogRef = ref<any>()
    const detailsDrawerRef = ref<any>()
    const replaceDialogRef = ref<any>()
    const completeDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const mixin_router_search = ref({
      status: '', //状态 0:待入库 1：已入库 2：已出库
      result:'',//维修结果
      careateBN:'',
      order: 'gmtCreate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        inEquipmentSn:'', //设备sn
        inUserAccount:'',//上报手机号    
        outEquipmentSn:'',//上报昵称   
        outUserAccount:'',
      },
    })
 
    const mixin_select_search_label = readonly({
      search: {
        inEquipmentSn: '录入SN', 
        inUserAccount: '录入账号', 
        outEquipmentSn:'完结SN',
        outUserAccount:'完结账号'
      },
    })
    const searchSelect = ref({search: 'inEquipmentSn'})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<repairListModel[]>([])
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.careateBN) {
            rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await repairListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })

    const handelChange = () => {
      console.log('改变回调')
      page.value = 1
      mixinReRouter()
    }

    const handleAdd = async() => {
      addRepairDialogRef.value.open();
    }

    const handleComfirm = async(idx:number,item:any) =>{
      await ElMessageBox.confirm(
        `是否确认接收设备`+item.inEquipmentSn+'的维修单，接收后，设备状态为维修中',
        '接收提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        }
      )
      const res = await confirmHttp(item.id)
      handelChange()
      ElMessage.success(res)
    }

    const handleComplete = async(idx:number,item:any) =>{
       completeDialogRef.value.open(item);     
    }

    const handleReplace = async(idx:number,item:any) =>{
      replaceDialogRef.value.open(item);
       
    }
  
    
    const handleDetails = async(idx:number,item:any) =>{
      detailsDrawerRef.value.open(item)
    }
   
    const handleExport = async() => {
      exportDialogRef.value.open('维修单记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'eqmaintain',order: 'gmtCreate',sort: 'desc'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          searchData.startGmtCreate = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          searchData.endGmtCreate =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportEqmaintainList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }

    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      changSearch,
      list,
      loading,
       page,
      limit,
      total, 
      addRepairDialogRef,
      detailsDrawerRef,
      replaceDialogRef,
      completeDialogRef,      
      exportDialogRef,
      productList,
      productIdList,
      handleAdd,
      handleComfirm,
      handleComplete,
      handleReplace,
      handleDetails,
      handleExport,    
      exportFile 
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }

  .el-icon{
    font-size: 30px;
    line-height: 30px;
    color: #67C23A;
  }
  // .ic-result{
  //   width: 15px;
  //   height: 15px;
  // }
  .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
  }
}
</style>