import { defHttp } from "@/utils/http/axios";  //网络请求
import { netRotuer } from "@/router/types"
import { RequestRes, ListRes } from "../types/requestBaseType"
import type { UserRole } from "@/types/user"
export interface TreeSaveParams {
    name: string;
    url: string;
    path: string;
    icon: string;
    hidden: string;
    state: string;
    treeorder: number | string;
    parenttreeid: string;
    code: string;
}

interface TreeCtlPramas {
    appid: string | number
    treeid?: string
}
export async function treeSave(params: TreeSaveParams & TreeCtlPramas): Promise<string | undefined> {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/tree/save`,
            params: {
                token: true,
                ...params
            },
        }
    );
    if (!reData.result) throw Error(reData.msg || "删除菜单失败")
    return reData.msg
}

export async function roletreeList({ roleid, appid }: { roleid: string, appid: string | number }, limit = 40): Promise<netRotuer[]> {
    const res = await defHttp.request<ListRes<netRotuer>>(
        {
            url: `/roletree/list`,
            params: {
                roleid,
                consoleids: appid,
                page: 1,
                limit: limit,
                token: true,
            },
        }
    );
    if (res.totalCount > limit) {
        return await roletreeList({ roleid, appid }, res.totalCount)
    }
    return res.result
}


interface roleMenu {
    result: UserRole[],
    totalCount: number
}

export async function roleList(limit = 40): Promise<UserRole[]> {
    const res = await defHttp.request<roleMenu>(
        {
            url: `/role/list`,
            params: {
                page: 1,
                limit: limit,
                token: true
            },
        }
    );
    if (res.totalCount && res.totalCount > limit) {
        return await roleList(res.totalCount)
    }
    return res.result
}

export interface MenuListResData extends netRotuer {
    indexs: number[],
    child: MenuListResData[]
}

export async function treeLoadTree({ appid }: TreeCtlPramas): Promise<MenuListResData[]> {
    const reData = await defHttp.request<RequestRes<MenuListResData[]>>(
        {
            url: `/tree/loadTree`,
            params: {
                appid,
                token: true
            },
        }
    );
    if (!reData.data) throw Error(reData.message || "获取菜单数据失败")
    reData.data = reData.data.filter((v) => v.parenttreeid == 0);
    reData.data = addMenuListIndex(reData.data)
    return reData.data
}

function addMenuListIndex(list: MenuListResData[], indexs: number[] = [0]) {
    list = list.map((v, index) => {
        v.indexs = [...indexs, index]
        if (v.child) addMenuListIndex(v.child, v.indexs)
        return v
    })
    return list
}


export async function treeUpdate(params: MenuListResData & TreeCtlPramas): Promise<string | undefined> {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/tree/update`,
            params: {
                token: true,
                consoleids: params.appid,
                ...params
            },
        }
    );
    if (!reData.result) throw Error(reData.msg || "更新菜单失败")
    return reData.msg
}

export async function treeRemove({ treeid, appid }: TreeCtlPramas): Promise<string | undefined> {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/tree/remove`,
            params: {
                appid,
                treeid,
                token: true
            },
        }
    );
    if (!reData.result) throw Error(reData.msg || "删除菜单失败")
    return reData.msg
}
interface UpdateRoleMenuListParams {
    roleid?: string;
    name?: string,
    rolename?: string,
    treeArray?: string,
    consoleids?: string | number,
}
export async function updateRoleMenuList(params: UpdateRoleMenuListParams): Promise<string | undefined> {
    console.log('params :>> ', params);
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/role/update`,
            params: {
                ...params,
                token: true
            },
        }
    );
    if (!reData.result) throw Error(reData.msg || "更新菜单失败")
    return reData.msg
}

export async function upLoadfile(url: string, params: any): Promise<void> {
    console.log('params :>> ', params);
    const reData = await defHttp.uploadFile<RequestRes>(
        {
            url,
        },
        params
    );
    // if (!reData.result) throw Error(reData.msg || "更新菜单失败")
    // return reData.msg
}