<template>
    <el-dialog
      :destroy-on-close="true"
      :title="title"
      @closed="handleClose"
      class="dialog"
      v-model="visible"
      width="800px"
    >
    <div class="container">
      <div class="name"><span class="name-text">{{deviceInfo.name}}</span></div>
      <div class="cabinet-out">
        <div class="cabinet-top"></div>
        <div class="cabinet-bottom">
            <div class="bottom-left"></div>
            <div class="bottom-right">
                <div class="deive-online">
                    <div v-for="(item,index) in scoketList" class="item" @click="onItemClick(item)">
                      <div class="device-bg" :class="item.isEmpty?
                                                  item.fault?'empty-fault':'empty-normal'
                                                :item.isNormal
                                                  ?item.articleequipmentsn=='无'||item.faultcode=='privatization'?'has-private'
                                                      :item.faultcode=='breakdown'||item.fault?'has-fault'
                                                        :item.faultcode=='unshared'||item.faultcode=='invalid'?'has-umnode':'has-normal'
                                                  :'has-fault'">
                        <p class="sn-text" v-if="item.socketstatus!='0'">{{item.articleequipmentsn=='无'?item.equipmentsn:item.articleequipmentsn}}</p>
                        <p class="sn-text" >{{item.batterypower}}</p>
                        <el-tooltip v-if="item.socketstatus=='0'" effect="light" content="正常无设备" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='1'" effect="light" content="正常有设备" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='2'" effect="light" content="充电不正常" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='3'" effect="light" content="识别机仓内有东西，但是无法通讯和充电" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='4'" effect="light" content="收到指令成功解锁设备但是驱动电机不能使小魔夹退出" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='5'" effect="light" content="发出解锁数据后连续查询设备5次不能解锁设备" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='6'" effect="light" content="仓位电流过大" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='7'" effect="light" content="仓位前面读取正常后面不正常" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else-if="item.socketstatus=='8'" effect="light" content="订单未结算，需要及时处理" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                        <el-tooltip v-else effect="light" content="未知故障" placement="top">
                          <div class="fault-popup"></div>
                        </el-tooltip>
                      </div>
                      <span class="item-number">{{item.socketsn}}</span>
                    </div>
                    <!-- <div class="item"><div class="device-bg empty-normal"></div><span class="item-number">1</span></div>
                    <div class="item"><div class="device-bg empty-fault"></div><span class="item-number">2</span></div>
                    <div class="item"><div class="device-bg has-normal"></div><span class="item-number">3</span></div>
                    <div class="item"><div class="device-bg has-fault"></div><span class="item-number">4</span></div>
                    <div class="item"><div class="device-bg has-private"></div><span class="item-number">5</span></div>
               
                    <div class="item"><div class="device-bg has-umnode"></div><span class="item-number">6</span></div>
                    <div class="item"><div class="device-bg has-normal"></div><span class="item-number">7</span></div>
                    <div class="item"><div class="device-bg has-normal"></div><span class="item-number">8</span></div>
                    <div class="item"><div class="device-bg has-normal"></div><span class="item-number">9</span></div>
                    <div class="item"><div class="device-bg has-normal"></div><span class="item-number">10</span></div> -->
                </div>        
            </div>
        </div>
      </div>  
      <div class="color-desc">
        <el-button 
          @click="handleRefresh()" 
          type="primary" 
          size="small" 
          :loading="loading"
          icon="el-icon-refresh">刷新机柜</el-button>
        <el-button 
          @click="handleListDevice()" 
          type="primary" 
          size="small" 
          icon="el-icon-tickets">列表展示</el-button>
        <el-button 
          @click="handle4GUpdate()" 
          size="mini" type="primary">4G上报消息
        </el-button>
        <el-button  
          @click="handle4GSend()" 
          size="mini" 
          type="primary">4G下发消息
        </el-button>
      </div>
      <div class="color-desc">
        <div class="desc-item"><dev class="desc-bg empty-normal"></dev><span>正常空仓</span></div>
        <div class="desc-item"><dev class="desc-bg empty-fault"></dev><span>故障空仓</span></div>
        <div class="desc-item"><dev class="desc-bg has-normal"></dev><sapn>正常设备</sapn></div>
        <div class="desc-item"><dev class="desc-bg has-fault"></dev><span>故障设备</span></div>
        <div class="desc-item"><dev class="desc-bg has-private"></dev><span>私有设备</span></div>
        <div class="desc-item"><dev class="desc-bg has-umnode"></dev><span>未知设备</span></div>
      </div>
      <el-form label-width="120px">
       
        <el-row class="inline-info">
            <el-col :span="16">
            <el-form-item label="地址:" class="from-item-label" >
                <span >{{ deviceInfo.address }}</span>
            </el-form-item>
            </el-col>
        </el-row>
        <el-row class="inline-info">
            <el-col :span="12">
            <el-form-item label="设备类型:"  class="from-item-label">
                <span v-if="deviceInfo.productid=='9775f7dd993646eaa4977537b0735e8f'" >二代机柜</span>
                <span v-if="deviceInfo.productid=='0a9edc30d4924f989fd9207bf62e6d23'" >便民柜</span>
                <span v-if="deviceInfo.productid=='6951e86506725baaf4dfcdf9aa015395'" >三代机柜</span>
                <span v-if="deviceInfo.productid=='d4b8d2d2df5742ecbec9398d5409dba9'" >消毒柜</span>
                <span v-if="deviceInfo.productid=='a2ea3faa86274d3f966ff30aad68b208'" >雨伞柜</span>
                <!-- <span  class="item-span">{{ deviceInfo.sn }}</span>         -->
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="设备MAC:"   class="from-item-label ">
                <span >{{ mac.toUpperCase() }}<my-copy :data="mac" /></span>
                
            </el-form-item>
            </el-col>
        </el-row>
       
        <el-row class="inline-info">
            <el-col :span="12">
            <el-form-item label="在线状态:"  class="from-item-label">
                <span  >{{ deviceInfo.online?"在线":"离线" }}</span>        
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="4G ID:"  class="from-item-label">
                <span >{{ deviceInfo.deviceid?deviceInfo.deviceid:"无" }}</span>
            </el-form-item>
            </el-col>
        </el-row>
        <el-row class="inline-info">
            <el-col :span="12">
            <el-form-item label="联系人:"  class="from-item-label">
                <span  >{{ deviceInfo.contactname }}</span>        
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="联系方式:"  class="from-item-label">
                <span >{{ deviceInfo.contactmobile }}</span>
            </el-form-item>
            </el-col>
        </el-row>
        <el-row class="inline-info">
            <el-col :span="12">
            <el-form-item  class="from-item-label">
              <el-button  @click="handleSync(deviceInfo.deviceid)" :loading="syncLoaidng" size="mini" type="primary">同步设备</el-button>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item   class="from-item-label item_right">
              <el-button  @click="handleEditMsg()" size="mini" type="primary" :disabled="deviceInfo.deviceid==null" :loading="sendMsgLoading">发送自定义消息</el-button>
            </el-form-item>
            </el-col>
        </el-row>
      </el-form> 
      <editMsgDialog ref="editDialogRef" @success="sendOneNetMsg"></editMsgDialog>
      <v-device-dialog ref="deviceDialogRef"></v-device-dialog>
      <oneNetReportDialog ref="oneNetReportDialogRef"></oneNetReportDialog>
      <oneNetSendDialog ref="oneNetSendDialogRef"></oneNetSendDialog>
    </div>
    </el-dialog>
  </template>
  <script lang = "ts" >
  import { defineComponent, ref } from "vue";
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { getComponentsList, ComponentsListRes } from "@/api/Component/Index";
  import {getContainInfoList,GetContainInfoList,removePanel} from '@/api/Share/Panel'
  import { getcrc, sendOnenNetMsg} from "@/api/Share/Panel";
  import { getSocketList, SocketListRes, removeSocket } from '@/api/Share/Socket'
  import { toOptional } from "@/types/utils";
  import editMsgDialog from "./editMsgDialog.vue";
  import deviceDialog from "@/components/device/cabinetDeviceDialog.vue" 
  import oneNetReportDialog from '@/components/device/oneNetReportDialog.vue'
  import oneNetSendDialog from '@/components/device/oneNetSendDialog.vue'
  import { userStore } from '@/store/modules/user'
  import dayjs from 'dayjs'
  let deviceId = "";
  let productId="";
  export default defineComponent({
    name: "v-dialog",
    components: {
      editMsgDialog,
      'v-device-dialog': deviceDialog,
      oneNetReportDialog,
      oneNetSendDialog,
    },
    setup() {
      const visible = ref(false);
      const loading = ref(false);
      const syncLoaidng = ref(false);
      const sendMsgLoading = ref(false);
      const btnSumitLoading = ref(false);
      const title = ref('机柜详情');
      const current = ref(1);
      const size = ref(10);
      const sn = ref("");
      const mac = ref('');
      const scoketTotal = ref(0)
      const scoketList = ref<SocketListRes[]>([])
      const list = ref<ComponentsListRes[]>([]);
      const maclist = ref<ComponentsListRes[]>([])
      const deviceInfo = ref<toOptional<GetContainInfoList>>({});
      const editDialogRef = ref<any>();
      const deviceDialogRef = ref<any>()
      const oneNetReportDialogRef = ref<any>()
      const oneNetSendDialogRef = ref<any>()
      const handleClose = () => {
        visible.value = false;
        //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
      };
      const reData = async () => {
        try {
          loading.value = true;
          const { records } = await getComponentsList({
            current: current.value,
            size: size.value,
            entity: {
              equipmentsn: sn.value,
            },
          });
          list.value = records;
          list.value.forEach(item => {
            if(item.type==='BLE'){
              mac.value=item.sign
            }
          });
        } finally {
          loading.value = false;
        }
      };
      const open = (_sn: string,item:any) => {
        console.log("出现弹窗=")
        visible.value = true;
        title.value=_sn+"机柜详情";
        sn.value = _sn;
        if(item !=null){
          deviceInfo.value=item;
          console.log('设备信息=',deviceInfo.value)
          deviceId=item.deviceid;
          productId=item.productid;
          console.log('产品id=',productId);
          console.log('设备id=',deviceId)
        }else{
          getDeviceSn();
        }
        getDeviceScoket();
        reData();
      };
      const onPageChange = (e: any) => {
        current.value = e.value;
        reData();
      };
      const handleSync = async (deviceid?: string) => {
        try {
          syncLoaidng.value = true;
          const res =  await getcrc({ deviceid });
          ElMessage.success(res);
        } finally {
          syncLoaidng.value = false;
        }
      };

      const getDeviceSn = async () => {
        try {
            
            let rqData: any = { currentPage: 1, size: 10,fortest:false,panelequipmentsn:sn.value }
            const res = await getContainInfoList(rqData)
            deviceInfo.value = res.list[0]
        } finally {
           
        }
      };

    const getDeviceScoket = async () => {
      try {
        loading.value = true
        let rqData: any = { current: 1, size: 20}
        let _entity:any = { panelequipmentsn:sn.value };
        if(userStore.getCurrentRole.rolename=='ROLE_OPERATOR'){
          _entity.operationId=userStore.getCurrentRole.operationId
        }
        rqData = {...rqData,entity:_entity}
        const res = await getSocketList(rqData)
        scoketTotal.value = res.total
        scoketList.value = res.records.map(v => {
          v.isSnEqual = v.socketstatus? v.articleequipmentsn === v.equipmentsn :false
          v.isEmpty = v.socketstatus==='0'
          v.isNormal = v.socketstatus==='1'
          return v
        })
      } finally {
        loading.value = false
      }
    }
      
      const handleEditMsg = async() => {
        editDialogRef.value.open();
      }
      
      const sendOneNetMsg = async(args:string ) =>{
        try{
          sendMsgLoading.value = true;
          const res =  await sendOnenNetMsg({ deviceid:deviceId,productId:productId,sn:sn.value,args:args });
          ElMessage.success(res);
        } finally {
          sendMsgLoading.value = false;
        }
      }

      const handleListDevice = async() =>{
        deviceDialogRef.value.open(sn.value,deviceInfo.value.productmodel)
      }

      const handleRefresh = async() => {
        getDeviceScoket()
      }
      /**
       * 机柜上报消息 
       */
      const handle4GUpdate = () =>{
        oneNetReportDialogRef.value.open(deviceId,sn.value)
      }    
      /**
       * 机柜下发消息
       */
      const handle4GSend = () =>{
        oneNetSendDialogRef.value.open(deviceId,sn.value)
      }
      /**
       * 弹出机柜仓位设备
       */
      const onItemClick = async (item:any) =>{
        console.log('点击单个弹窗',item)
        await ElMessageBox.confirm(
            `是否强制弹窗当前仓位设备，弹出请确保设备不丢失`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              distinguishCancelAndClose: true,
            }
          )
          let sendSn=null;
        
          if(item.articleequipmentsn =='无'){
            if(item.equipmentsn != '无'){
              sendSn=item.equipmentsn
            }
          }else{
            sendSn=item.articleequipmentsn
          }
         
          getDeviceMac(sendSn,item.socketsn)
      }
      const getDeviceMac = async (sendSn:string,socketsn:string) => {
        console.log('查询之前',sendSn)
        let sendmac="null";
        if(sendSn){
          try {
            let rqData: any = { current: 1, size: 10 ,entity: {equipmentsn: sendSn}}
            const res = await getComponentsList(rqData)
            maclist.value=res.records
            maclist.value.forEach(item => {
            if(item.type==='BLE'){
              sendmac=item.sign.toUpperCase()
            }
          });
          } finally {
            
          }
        }
        const time = dayjs(Date.now()).format('YYMMDDHHmmss')
        const args=`REMOTEOUT:${socketsn}|${sendmac}|d5|${time}|1 `;
        console.log('发送信息',args)

        const res =  await sendOnenNetMsg({ deviceid:deviceId,productId:productId,sn:sn.value,args:args });
        ElMessage.success(res);
    }
      return {
        visible,
        handleClose,
        open,
        list,
        scoketTotal,
        scoketList,
        btnSumitLoading,
        loading,
        current,
        size,
        onPageChange,
        handleSync,
        syncLoaidng,
        sendMsgLoading,
        title,
        mac,
        deviceInfo,
        editDialogRef,
        deviceDialogRef,
        oneNetReportDialogRef,
        oneNetSendDialogRef,
        handleEditMsg,
        sendOneNetMsg,
        handleListDevice,
        handleRefresh,
        handle4GUpdate,
        handle4GSend,
        onItemClick,
      };
    },
  });
  </script>
  <style lang = "scss" scoped >
   .from-item-label{
       font-weight: bold;
    }
  
    .item_right{
        margin-left: 48px;
    }
  .item-span{
      display: block;
      height: 42px;
      width: 180px;
      padding-left: 8px;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      font-weight: 500;
      color: #808080;
    }
    .inline-info{
      width: 100%;
      height: 42px; 
    }
    .name{
      width: 100%;
      height: 40px;
      padding-left: 10%;
      display: flex;
      align-items: center;
      .name-text{
        font-size: 19px;
        font-weight: bold;
        color: black;
      }
    }
  .cabinet-out{
    margin-left: 10%;
    border: 5px solid #666666;
    border-radius: 16px;
    width: 70%;
    height: 380px;
    display: flex;
    flex-direction: column;
    .cabinet-top{
        width: 100%;
        height: 20%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: pink;
    }
    .cabinet-bottom{
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: row;
        .bottom-left{
          width: 20%;
          height: 100%;
          background: #808080;
          border-bottom-left-radius: 10px;
        }
        .bottom-right{
          width: 80%;
          height: 100%;
          border-bottom-right-radius: 10px;
          .deive-online{
            width: 100%;
            height: 50%;
            display: flex;
            padding-right: 1%;
            flex-wrap: wrap;    //父元素加一个换行
            .item{
              width: 19%;
              height: 90%;
              margin-left: 1%;
              margin-top: 2%;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              .item-number{
                width: 18px;
                height: 18px;
                background: #3299CC;
                border-radius: 9px;
                color: #FFF;
                text-align: center;
                line-height: 18px;
                font-size: 10px;
                font-weight: bold;
                margin-top: 4px;
              }
              .device-bg{
                border-radius: 5px;
                width: 62%;
                height: 80%;
                position: relative;
                .fault-popup{
                  width: 100%;
                  height: 100%;
                  top: 0;
                  position: absolute;
                }
              }        
            }
          }
        }
    }
  }
  .empty-normal{
      border: 2px solid #C0C0C0;
    }
    .empty-fault{
      border: 2px solid #FF0000;
    }
    .has-normal{
      border: 2px solid #06c05f;
      background: #06c05f;
    }
    .has-fault{
      border: 2px solid red;
      background: red;
    }
    .has-private{
      border: 2px solid #409EFF;
      background: #409EFF;
    }  
    .has-umnode{
      border: 2px solid #871F78;
      background: #871F78;
    }           
    .sn-text{
      color: white;
      font-size: 13px;
      text-align: center;
    }
    .color-desc{
      width: 100%;
      padding-left: 10%;
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      .desc-item{
        margin-right: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .desc-bg{
        width: 16px;
        height: 16px;
      }
    }
  </style>