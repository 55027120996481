
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  distributeprofit,
  getOrderList,
  OrderList,
  sharedordermemoSettleup,
} from '@/api/Order'
import closedOrderDialog from './components/closedOrderDialog.vue'
import refundDialog from './components/refundDialog.vue'
import privatizationDrewer from './components/privatizationdrawer.vue'
import componentsDialog from '@/components/device/componentsDialog.vue'
import guaranteeOrderDialog from '@/components/order/guaranteeOrderDialog.vue'
import cabinetDatailsDialog from '@/components/device/cabinetDatailsDialog.vue'
import { holdertype } from '@/api/Share/Device'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
import { sharedorderPrivatizationprocess,sharedorderPrivatizationprocessNew } from '@/api/Order/Privatization'
import myExport from '@/utils/exprotExcel'
import mySelectCity from '@/components/cityList/index.vue'
import { userStore } from "@/store/modules/user";

export default defineComponent({
  name: 'orderManage',
  data() {
    return {
      privateShortcuts: [
        {
          text: '最近一天',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(end.getTime() - 3600 * 1000 * 24)
            return [start, end]
          })(),
        },
        {
          text: '明天',
          value: (() => {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs(end).add(1, 'day')
            return [start, end]
          })(),
        },
      ],
      shortcuts: [
        {
          text: '最近一天',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            return [start, end]
          })(),
        },
        {
          text: '昨天',
          value: (() => {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs(end).subtract(1, 'day')
            return [start, end]
          })(),
        },
        {
          text: '最近一周',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          })(),
        },
        {
          text: '最近一个月',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          })(),
        },
      ],
      isShowDrawer:false,
    }
  },
  setup() {
    const loading = ref(false)
    const reDeductionLoading = ref(false)
    const prvatiLoading = ref(false)
    const splitLoading = ref(false)
    const token = userStore.userInfo.token;
    const areaCode = ref('')
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<OrderList[]>([])
    const closedOrderDialogRef = ref<any>()
    const refundDialogRef = ref<any>()
    const privatizationDrewerRef= ref<any>()
    const componentsDialogRef = ref<any>();
    const guaranteeOrderRef = ref<any>();
    const cabinetDetailsDialogRef = ref<any>();
    const searchSelect = ref({
      search: 'articleequipmentsn',
      former: 'formerholderpanelequipmentsn',
      latter: 'latterholderpanelequipmentsn',
    })
    const allHoldertype = holdertype
    const mixin_router_search = ref({
      fortest: false,
      order: 'receivedate',
      sort: 'desc',
      firstorder: '',
      privatizationwarned: '',
      privatized: '',
      articleproductmodel: '',
      guaranteetype: '',
      closed: '',
      latterholdertype: '',
      formerholdertype: '',
      receiveBN: '',
      privateBN: '',
      deliverdateBN: '',
    })
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: '',
        receiveraccount: '',
        sharedorderid: '',
        receiveruserid: '',
      },
      former: {
        formerholderpanelequipmentsn: '',
        formerholderuserid: '',
        formerholderaccount: '',
      },
      latter: {
        latterholderpanelequipmentsn: '',
        latterholderaccount: '',
        latterholderuserid: '',
      },
    })

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        receiveraccount: '租赁手机号',
        sharedorderid: '订单号',
        receiveruserid: '用户ID',
      },
      former: {
        formerholderpanelequipmentsn: '租赁机柜SN',
        formerholderaccount: '上任账号',
        formerholderuserid: '上任ID',
      },
      latter: {
        latterholderpanelequipmentsn: '归还机柜SN',
        latterholderaccount: '下任账号',
        latterholderuserid: '下任ID',
      },
    })
    const cityCodeChange = (code: string) => {
      areaCode.value = code
      page.value = 1
      mixinReRouter()
    }

    const handleClose =(done:any)=>{
      done();
    }

    const handleExport = () => {
      const excel = new myExport('用户订单列表')
      const _list = list.value.map((v) => {
        const _data = {
          订单号: v.sharedorderid,
          小魔夹SN: v.articleequipmentsn,
          '出租方（机柜/用户）': allHoldertype[v.formerholdertype],
          '租赁机柜SN/用户账号':
            v.formerholderaccount ||
            v.formerholderpanelequipmentsn + '-' + v.formerholdersocketsn,
          '归还机柜SN/用户账号': v.latterholderpanelequipmentsn
            ? v.latterholderpanelequipmentsn + '-' + v.latterholdersocketsn
            : v.latterholderaccount || '无',
          租赁人: v.receiveraccount,
          租赁时间: v.receivedate,
          转出时间: v.deliverdate,
          金额: v.costs,
          订单状态: v.closed ? '已完成' : '进行中',
          担保订单号: v.outorderno,
          担保类型: { PAYSCORE: '微信', ZHIMA: '支付宝', BALANCE: '余额' }[
            v.guaranteetype
          ],
          // 市: v.receivec,
          // 区: v.recvdistrictname,
          租赁地址: v.receiveaddress,
        }
        return _data
      })
      excel.addWorksheet({ headers: ['用户订单列表'], list: _list })
      excel.export()
    }
    const switchCode = (code: string) => {
      const rq: any = {}
      switch (code.length) {
        case 2:
          rq.receiveprovinceareacode = code
          break
        case 4:
          rq.receivecityareacode = code
          break
        case 6:
          rq.receivedistrictareacode = code
          break
        case 9:
          rq.receivestreetareacode = code
          break
      }
      return rq
    }
    const reData = async (rq?: any) => {
      console.log('转换时间戳',new Date('2023-05-22 08:52:36'))
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.receiveBN) {
            rq.receivedateBegin = dayjs(rq.receiveBN[0]).format("YYYY-MM-DD HH:mm:ss") 
            rq.receivedateEnd =dayjs(rq.receiveBN[1]).format("YYYY-MM-DD HH:mm:ss")  
            delete rq.receiveBN
          }
          if (rq.privateBN) {
            rq.privatizationexpirationdateBegin = dayjs(rq.privateBN[0]).format("YYYY-MM-DD HH:mm:ss")  
            rq.privatizationexpirationdateEnd = dayjs(rq.privateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.privateBN
          }
          if (rq.deliverdateBN) {
            rq.deliverdateBegin =dayjs( rq.deliverdateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.deliverdateEnd = dayjs( rq.deliverdateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.deliverdateBN
          }
          if (areaCode.value) {
            rq = { ...rq, ...switchCode(areaCode.value) }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await getOrderList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleCloseOrder = (item: any) => {
      closedOrderDialogRef.value.open(item)
    }
    const handleRefund = (id: string, userid:string, costs: number,startTime:string,deviceType:string) => {
      refundDialogRef.value.open(id, userid, costs,startTime,deviceType)
    }
    const onShowDrawer = (item:any) => {
      console.log('点击弹出');
      console.log(privatizationDrewerRef)
      privatizationDrewerRef.value.open(item);
    }
    const handlerShowSn = (sn:string) => {
      console.log('点击SN');
       componentsDialogRef.value.open(sn)
    }
    /**
     * 担保订单
     */
    const handlerGuaranteeOrder = (item:any) =>{
      guaranteeOrderRef.value.open(item.receiveruserid,item.receiveraccount,item.sharedorderid,item.outorderno)
    }
    const handleReDeduction = async (
      item: OrderList,
      Manual?: boolean
    ): Promise<any> => {
      let rentalfee = item.costs as number
      if (!Manual) {
        try {
          await ElMessageBox.confirm(
            `请确认是否发起订单扣费，金额${rentalfee}元？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '手动输入',
              type: 'warning',
              distinguishCancelAndClose: true,
            }
          )
        } catch (error) {
          if (error == 'cancel') return await handleReDeduction(item, true)
          return
        }
      }
      try {
        if (Manual) {
          const { value } = await ElMessageBox.prompt(
            '请输入订单扣款金额',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputErrorMessage: `请输入大于等于0小于${rentalfee}数字`,
              inputType: 'number',
              inputValidator: (value): boolean => {
                const _value = parseFloat(value)
                if (isNaN(_value)) return false
                return _value >= 0 && _value <= (item.costs || 0)
              },
            }
          )
          rentalfee = parseFloat(value)
          await ElMessageBox.confirm(
            `请确认是否发起订单扣费，金额${rentalfee}元？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              distinguishCancelAndClose: true,
            }
          )
        }
        reDeductionLoading.value = true
        const res = await sharedordermemoSettleup({
          sharedorderid: item.sharedorderid,
          rentalfee,
        })
        ElMessage.success(res)
        mixinReRouter()
      } finally {
        reDeductionLoading.value = false
      }
    }
    const handlePrvati = async (sharedorderId: string) => {
      try {
        prvatiLoading.value = true
        //const res = await sharedorderPrivatizationprocess(sharedorderId)
        const res = await sharedorderPrivatizationprocessNew(sharedorderId)
        ElMessage.success(res)
      } finally {
        prvatiLoading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const roleShowPermission = inject('roleShowPermission')
    const handleSplit = async (sharedorderid: string) => {
      try {
        splitLoading.value = true
        const res = await distributeprofit(sharedorderid)
        ElMessage.success(res)
      } finally {
        splitLoading.value = false
      }
    }
    const showCabintDivece = async(sn:string) =>{
      cabinetDetailsDialogRef.value.open(sn,null)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      token,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      mixinReRouter,
      closedOrderDialogRef,
      componentsDialogRef,
      guaranteeOrderRef,
      handleCloseOrder,
      handleRefund,
      refundDialogRef,
      allHoldertype,
      handleReDeduction,
      reDeductionLoading,
      handlePrvati,
      prvatiLoading,
      roleShowPermission,
      handleExport,
      cityCodeChange,
      handleSplit,
      handleClose,
      privatizationDrewerRef,
      onShowDrawer,
      handlerShowSn,
      handlerGuaranteeOrder,
      showCabintDivece,
      cabinetDetailsDialogRef,
    }
  },
  components: {
    closedOrderDialog,
    refundDialog,
    mySelectCity,
    privatizationDrewer,
    'v-components-dialog': componentsDialog,
    guaranteeOrderDialog,
    cabinetDatailsDialog,
  },
})
