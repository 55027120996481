
import { defineComponent, ref } from 'vue'
import {
  investmentorderarticleList,
  investmentorderarticleRemove,
} from '@/api/Investment/shipment'
import { singleassign } from '@/api/Investment/shipment'
import { ElMessage } from 'element-plus'
let equipmentsnMap: any
let equipmentboxMap: any
let investmentorderid: any
let shipmentid: any
export default defineComponent({
  name: 'assign-dialog',
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const btnCancelLoading = ref(false)
    const loading = ref(false)
    const status = ref<any>('')
    const isShow = ref(false)
    const addType = ref<any>('equipmentsn')
    const add = ref<any>('')
    const quantity = ref(0)
    const equipmentbox = ref<any>([])
    const equipmentsn = ref<any>([])
    const list = ref<any[]>([])
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const reDistributionXmjList = async () => {
      try {
        loading.value = true
        const res = await investmentorderarticleList({
          entity: {
            shipmentid,
            investmentorderid,
          },
          current: page.value,
          size: limit.value,
        })
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const handleClose = () => {
      btnSumitLoading.value = false
      visible.value = false
      addType.value = 'equipmentsn'
      add.value = ''
      equipmentbox.value = []
      equipmentsn.value = []
      list.value = []
      equipmentsnMap = null
      equipmentboxMap = null
      emit('assgin-success')
    }
    const open = (data: any, _isShow = false) => {
      console.log('data :>> ', data)
      equipmentsnMap = new Map()
      equipmentboxMap = new Map()
      shipmentid = data.shipmentid
      investmentorderid = data.investmentorderid
      visible.value = true
      quantity.value = data.quantity
      status.value = data.status
      reDistributionXmjList()
      isShow.value = _isShow
    }
    const addClick = async () => {
      if (!add.value || !add.value.trim()) return ElMessage.warning('请输入SN')
      add.value = add.value.trim().toUpperCase() // 转换为大写
      const _map =
        addType.value == 'equipmentsn'
          ? equipmentsnMap
          : addType.value == 'equipmentbox'
          ? equipmentboxMap
          : ''
      if (_map.has(add.value)) {
        _map.set(add.value, true)
        return ElMessage.error('这个设备或箱子已添加')
      } else {
        try {
          btnSumitLoading.value = true
          let res = null
          if (addType.value == 'equipmentsn') {
            res = await singleassign({
              shipmentid,
              equipmentsn: add.value,
            })
            equipmentsn.value.push(add.value)
            // list.value.push(res.record);
          } else {
            return ElMessage.error('暂时不支持分配装箱')
            // res = await batchassign({
            //   shipmentid,
            //   boxid: this.add,
            // });
            // equipmentbox.value.push(add.value);
            // reDistributionXmjList();
          }
          _map.set(add.value, false)
          ElMessage.success(res.message)
          quantity.value =
            addType.value == 'equipmentbox'
              ? quantity.value - 60
              : quantity.value - 1
          add.value = ''
          reDistributionXmjList()
        } finally {
          btnSumitLoading.value = false
        }
      }
    }
    const removeItem = async (index: number, articleequipmentsn: string) => {
      try {
        btnCancelLoading.value = true
        const res = await investmentorderarticleRemove({
          articleequipmentsn: articleequipmentsn,
        })
        ElMessage.success(res)
        list.value.splice(index, 1)
      } finally {
        btnCancelLoading.value = false
      }
    }
    const handleExport = (e: any) => {
      ElMessage.warning('暂时不支持')
      console.log('e :>> ', e)
    }
    const cancelAssgin = (e: any) => {
      ElMessage.warning('暂时不支持')
      console.log('e :>> ', e)
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      loading,
      list,
      quantity,
      add,
      addClick,
      addType,
      total,
      page,
      limit,
      equipmentsn,
      equipmentbox,
      status,
      handleExport,
      btnCancelLoading,
      cancelAssgin,
      isShow,
      removeItem,
    }
  },
})
