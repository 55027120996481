<template>
  <div class="container">
    <header >
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.former[searchSelect.former]"
      >
        <template #prepend>
          <el-select @change="changSearch('former')" placeholder="请选择" v-model="searchSelect.former">
            <el-option
              :key="item"
              :label="mixin_select_search_label.former[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.former)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.latter[searchSelect.latter]"
      >
        <template #prepend>
          <el-select @change="changSearch('latter')" placeholder="请选择" v-model="searchSelect.latter">
            <el-option
              :key="item"
              :label="mixin_select_search_label.latter[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.latter)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="平台投放开始状态" v-model="mixin_router_search.fortest">
        <el-option :value="true" label="测试订单"></el-option>
        <el-option :value="false" label="正式订单"></el-option>
      </el-select>
      <!-- <a href="https://www.baidu.com">百度</a> -->
      <!-- <a :href="'http://xmbdata.smartline.com.cn:8442/mall/login?token='+token" >商城订单</a> -->
      <!-- <a :href="'http://xmjconsole2.smartline.com.cn/mall/login?token='+token" >商城订单</a> -->
      <div class="header-el-template">        
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="订单状态" v-model="mixin_router_search.closed">
          <el-option :value="true" label="已完成"></el-option>
          <el-option :value="false" label="进行中"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="扣费状态" v-model="mixin_router_search.paid">
          <el-option :value="true" label="已结清"></el-option>
          <el-option :value="false" label="未扣费"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="担保类型" v-model="mixin_router_search.guaranteetype">
          <el-option label="余额" value="BALANCE"></el-option>
          <el-option label="微信" value="PAYSCORE"></el-option>
          <el-option label="芝麻" value="ZHIMA"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否首单" v-model="mixin_router_search.firstorder">
          <el-option :value="true" label="是首单"></el-option>
          <el-option :value="false" label="不是首单"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="设备类型" v-model="mixin_router_search.articleproductmodel">
          <el-option label="小魔夹MS3" value="MS3"></el-option>
          <el-option label="小魔夹MS2" value="M4S2"></el-option>
          <el-option label="小魔夹M4S" value="M4S"></el-option>
          <el-option label="雨伞" value="UM01"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="超期预警" v-model="mixin_router_search.privatizationwarned">
          <el-option :value="true" label="已预警"></el-option>
          <el-option :value="false" label="未预警"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否超期" v-model="mixin_router_search.privatized">          
          <el-option :value="0" label="未超期"></el-option>
          <el-option :value="1" label="已私有"></el-option>
          <el-option :value="2" label="已超期"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="上任类型" v-model="mixin_router_search.formerholdertype">
          <el-option :key="item" :label="allHoldertype[item]" :value="item" v-for="item of Object.keys(allHoldertype)"></el-option>
        </el-select>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="继任类型" v-model="mixin_router_search.latterholdertype">
          <el-option :key="item" :label="allHoldertype[item]" :value="item" v-for="item of Object.keys(allHoldertype)"></el-option>
        </el-select>
      </div>
      <!-- <my-select-city :maxLevel="3" @cityCodeChange="cityCodeChange"></my-select-city> -->
      <el-date-picker
        :shortcuts="shortcuts"
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="租赁日期"
        range-separator="至"
        start-placeholder="租赁日期"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.receiveBN"
      ></el-date-picker>
      <el-date-picker
        :shortcuts="shortcuts"
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="归还日期"
        range-separator="至"
        start-placeholder="归还日期"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.deliverdateBN"
      ></el-date-picker>
      <el-date-picker
        :shortcuts="privateShortcuts"
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="私有化日期"
        range-separator="至"
        start-placeholder="私有化日期"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.privateBN"
      ></el-date-picker>
    </header>
    <el-table
      :data="list"
      :default-sort="{prop: 'receivedate', order: 'descending'}"
      @sort-change="mixinReRouterTableSortChange"
      height="80vh"
      style="width: 100%"
      v-if="list"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="100px">
            <el-form-item label="订单号">
              {{scope.row.sharedorderid}}
              <my-copy :data="scope.row.sharedorderid" />
            </el-form-item>
            <el-form-item label="担保订单号" v-if="scope.row.outorderno">
              <!-- <my-click-data-to
                :data="scope.row.outorderno"
                :params="[{search_guaranteeno:scope.row.outorderno}]"
                url="/order/guaranteeOrder"
              ></my-click-data-to> -->
              <span @click="handlerGuaranteeOrder(scope.row)" style="color: #409eff;cursor: pointer">{{scope.row.outorderno}}</span>
              <my-copy :data="scope.row.outorderno" />
            </el-form-item>
            <!-- <el-form-item label="租赁时间">{{scope.row.receivedate}}</el-form-item>
            <el-form-item label="归还时间">{{scope.row.deliverdate}}</el-form-item>-->
            <el-form-item label="租赁地址">{{scope.row.receiveaddress}}</el-form-item>
            <el-form-item label="其他信息">
              <my-click-btn-to
                :params="[{search_sharedorderid:scope.row.sharedorderid}]"
                data="续费记录"
                url="/order/renewal"
                v-if="scope.row.renewed"
              ></my-click-btn-to>
              <my-click-btn-to
                :params="[{search_sharedorderid:scope.row.sharedorderid}]"
                data="退款记录"
                url="/order/refund"
                v-if="scope.row.closed"
              ></my-click-btn-to>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="租赁者" min-width="40">
        <template #default="scope">
          {{scope.row.receiveraccount}}
          <my-copy :data="scope.row.receiveraccount" />
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="40">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          <span @click="handlerShowSn(scope.row.articleequipmentsn)" style="color: #409eff;cursor: pointer">{{scope.row.articleequipmentsn}}</span>
          <!-- <my-click-data-to :data="scope.row.articleequipmentsn" :params="[{search_sn:scope.row.articleequipmentsn}]" url="/share/device"></my-click-data-to> -->
          <my-copy :data="scope.row.articleequipmentsn" />
        </template>
      </el-table-column>
      <el-table-column label="产品" min-width="40" align='center' prop="articleproductname"></el-table-column>

      <el-table-column
        :sort-orders="['ascending', 'descending']"
        align="center"
        label="时间"
        min-width="65"
        prop="receivedate"
        sortable="true"
      >
        <template #default="scope">
          <el-tooltip class="item" content="开始时间" effect="dark" placement="right">
            <div>
              <span class="el-icon-time g-color-info"></span>
              {{scope.row.receivedate}}
            </div>
          </el-tooltip>
          <el-tooltip class="item" content="结束时间" effect="dark" placement="right" v-if="scope.row.deliverdate">
            <div>
              <span class="el-icon-time g-color-success"></span>
              {{scope.row.deliverdate}}
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="私有化到期时间"
            effect="dark"
            placement="right"
            v-if="!scope.row.closed&&scope.row.privatizationexpirationdate"
          >
            <div>
              <span class="el-icon-time g-color-danger"></span>
              {{scope.row.privatizationexpirationdate}}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="left" label="金额" min-width="30">
        <template #default="scope">
          <div v-if="scope.row.costs!==null">{{scope.row.firstorder?"首单 ":' '}}¥ {{scope.row.costs}}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型|私有化|状态" min-width="50">
        <template #default="scope">
          <div class="flex_ca">
            <color-icon :type="`icon-${scope.row.guaranteetype}`" style="font-size:40px;color:#50b674"></color-icon>
            <el-tag type="danger" v-if="scope.row.privatized||scope.row.privatized==2">已超期</el-tag>
            <el-tag type="primary" v-else>未超期</el-tag>
            <el-tag type="success" v-if="scope.row.closed">已完成</el-tag>
            <el-tag type="success" v-else>租赁中</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="90">
        <template #header>
          <el-tag type="primary">租</el-tag>
          <el-tag type="success">还</el-tag>
        </template>
        <template #default="scope">
          <!-- <el-tag
            @click="toPath_RouterSearchMixin('/share/panel',[{search_panelequipmentsn:scope.row.formerholderpanelequipmentsn}])"
            type="primary"
            v-if="scope.row.formerholdertype=='socket'"
          > -->
          <el-tag
            @click="showCabintDivece(scope.row.formerholderpanelequipmentsn)"
            type="primary"
            v-if="scope.row.formerholdertype=='socket'"
          >
            机柜 {{scope.row.formerholderpanelequipmentsn}} - {{scope.row.formerholdersocketsn}}
            <my-copy :data="scope.row.formerholderpanelequipmentsn" />
          </el-tag>
          <el-tag type="primary" v-else>用户</el-tag>
          <span v-if="scope.row.latterholdertype">
            <!-- <el-tag
              @click="toPath_RouterSearchMixin('/share/panel',[{search_panelequipmentsn:scope.row.latterholderpanelequipmentsn}])"
              type="success"
              v-if="scope.row.latterholdertype=='socket'"
            > -->
            <el-tag
            @click="showCabintDivece(scope.row.latterholderpanelequipmentsn)"
            type="success"
            v-if="scope.row.formerholdertype=='socket'"
            >
              机柜 {{scope.row.latterholderpanelequipmentsn}} - {{scope.row.latterholdersocketsn}}
              <my-copy :data="scope.row.latterholderpanelequipmentsn" />
            </el-tag>
            <el-tag type="success" v-else>用户</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="50">
        <template #header>
          <el-button @click="handleExport(scope)" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button
            :loading="prvatiLoading"
            @click="handlePrvati(scope.row.sharedorderid)"
            size="mini"
            type="danger"
            v-if="roleShowPermission(['ROLE_ADMIN_DVE'])&&!scope.row.closed&&scope.row.fortest&&(!scope.row.privatized||scope.row.privatized!=2)"
          >强制私有化</el-button>
          <my-click-btn-to
            :params="[{search_sharedorderid:scope.row.sharedorderid}]"
            data="私有化处理"
            url="/order/privatization"
            v-if="scope.row.privatized&&scope.row.privatized!=2"
          ></my-click-btn-to>
          <my-click-btn-to
            :params="[{search_sharedOrderId:scope.row.sharedorderid}]"
            data="私有化处理"
            url="/order/privatizationNew"
            v-if="scope.row.privatized==2"
          ></my-click-btn-to>
          <!-- <el-button v-if="scope.row.privatized" @click="onShowDrawer(scope.row)" type="primary" 
          style="margin-left: 16px;" size='mini'>私有化处理</el-button> -->
          <el-button
            :disabled="!scope.row.costs"
            @click="handleRefund(scope.row.sharedorderid,scope.row.receiveruserid,scope.row.costs,scope.row.receivedate,scope.row.articleproducttype)"
            size="mini"
            type="warning"
            v-if="scope.row.closed&&scope.row.paid"
          >发起退款</el-button>
          <el-button
            :disabled="scope.row.costs==0"
            :loading="reDeductionLoading"
            @click="handleReDeduction(scope.row)"
            size="mini"
            type="primary"
            v-else-if="scope.row.latterholdertype"
          >重新扣款</el-button>
          <el-button 
            @click="handleCloseOrder(scope.row)" 
            size="mini" 
            type="danger" v-else 
            :disabled="scope.row.privatized">强制结算
          </el-button>
          <el-button
            :disabled="scope.row.profitedamount"
            @click="handleSplit(scope.row.sharedorderid)"
            size="mini"
            type="success"
            v-if="scope.row.closed&&scope.row.costs>0"
          >分成</el-button>
        </template>
      </el-table-column>    
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <closedOrderDialog ref="closedOrderDialogRef"></closedOrderDialog>
    <refund-dialog @success="mixinReRouter()" ref="refundDialogRef"></refund-dialog>
    <privatizationDrewer ref="privatizationDrewerRef"></privatizationDrewer>
    <v-components-dialog ref="componentsDialogRef"></v-components-dialog>
    <guaranteeOrderDialog ref="guaranteeOrderRef"></guaranteeOrderDialog>
    <cabinetDatailsDialog ref ="cabinetDetailsDialogRef"></cabinetDatailsDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  distributeprofit,
  getOrderList,
  OrderList,
  sharedordermemoSettleup,
} from '@/api/Order'
import closedOrderDialog from './components/closedOrderDialog.vue'
import refundDialog from './components/refundDialog.vue'
import privatizationDrewer from './components/privatizationdrawer.vue'
import componentsDialog from '@/components/device/componentsDialog.vue'
import guaranteeOrderDialog from '@/components/order/guaranteeOrderDialog.vue'
import cabinetDatailsDialog from '@/components/device/cabinetDatailsDialog.vue'
import { holdertype } from '@/api/Share/Device'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
import { sharedorderPrivatizationprocess,sharedorderPrivatizationprocessNew } from '@/api/Order/Privatization'
import myExport from '@/utils/exprotExcel'
import mySelectCity from '@/components/cityList/index.vue'
import { userStore } from "@/store/modules/user";

export default defineComponent({
  name: 'orderManage',
  data() {
    return {
      privateShortcuts: [
        {
          text: '最近一天',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(end.getTime() - 3600 * 1000 * 24)
            return [start, end]
          })(),
        },
        {
          text: '明天',
          value: (() => {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs(end).add(1, 'day')
            return [start, end]
          })(),
        },
      ],
      shortcuts: [
        {
          text: '最近一天',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            return [start, end]
          })(),
        },
        {
          text: '昨天',
          value: (() => {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs(end).subtract(1, 'day')
            return [start, end]
          })(),
        },
        {
          text: '最近一周',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          })(),
        },
        {
          text: '最近一个月',
          value: (() => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          })(),
        },
      ],
      isShowDrawer:false,
    }
  },
  setup() {
    const loading = ref(false)
    const reDeductionLoading = ref(false)
    const prvatiLoading = ref(false)
    const splitLoading = ref(false)
    const token = userStore.userInfo.token;
    const areaCode = ref('')
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<OrderList[]>([])
    const closedOrderDialogRef = ref<any>()
    const refundDialogRef = ref<any>()
    const privatizationDrewerRef= ref<any>()
    const componentsDialogRef = ref<any>();
    const guaranteeOrderRef = ref<any>();
    const cabinetDetailsDialogRef = ref<any>();
    const searchSelect = ref({
      search: 'articleequipmentsn',
      former: 'formerholderpanelequipmentsn',
      latter: 'latterholderpanelequipmentsn',
    })
    const allHoldertype = holdertype
    const mixin_router_search = ref({
      fortest: false,
      order: 'receivedate',
      sort: 'desc',
      firstorder: '',
      privatizationwarned: '',
      privatized: '',
      articleproductmodel: '',
      guaranteetype: '',
      closed: '',
      latterholdertype: '',
      formerholdertype: '',
      receiveBN: '',
      privateBN: '',
      deliverdateBN: '',
    })
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: '',
        receiveraccount: '',
        sharedorderid: '',
        receiveruserid: '',
      },
      former: {
        formerholderpanelequipmentsn: '',
        formerholderuserid: '',
        formerholderaccount: '',
      },
      latter: {
        latterholderpanelequipmentsn: '',
        latterholderaccount: '',
        latterholderuserid: '',
      },
    })

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        receiveraccount: '租赁手机号',
        sharedorderid: '订单号',
        receiveruserid: '用户ID',
      },
      former: {
        formerholderpanelequipmentsn: '租赁机柜SN',
        formerholderaccount: '上任账号',
        formerholderuserid: '上任ID',
      },
      latter: {
        latterholderpanelequipmentsn: '归还机柜SN',
        latterholderaccount: '下任账号',
        latterholderuserid: '下任ID',
      },
    })
    const cityCodeChange = (code: string) => {
      areaCode.value = code
      page.value = 1
      mixinReRouter()
    }

    const handleClose =(done:any)=>{
      done();
    }

    const handleExport = () => {
      const excel = new myExport('用户订单列表')
      const _list = list.value.map((v) => {
        const _data = {
          订单号: v.sharedorderid,
          小魔夹SN: v.articleequipmentsn,
          '出租方（机柜/用户）': allHoldertype[v.formerholdertype],
          '租赁机柜SN/用户账号':
            v.formerholderaccount ||
            v.formerholderpanelequipmentsn + '-' + v.formerholdersocketsn,
          '归还机柜SN/用户账号': v.latterholderpanelequipmentsn
            ? v.latterholderpanelequipmentsn + '-' + v.latterholdersocketsn
            : v.latterholderaccount || '无',
          租赁人: v.receiveraccount,
          租赁时间: v.receivedate,
          转出时间: v.deliverdate,
          金额: v.costs,
          订单状态: v.closed ? '已完成' : '进行中',
          担保订单号: v.outorderno,
          担保类型: { PAYSCORE: '微信', ZHIMA: '支付宝', BALANCE: '余额' }[
            v.guaranteetype
          ],
          // 市: v.receivec,
          // 区: v.recvdistrictname,
          租赁地址: v.receiveaddress,
        }
        return _data
      })
      excel.addWorksheet({ headers: ['用户订单列表'], list: _list })
      excel.export()
    }
    const switchCode = (code: string) => {
      const rq: any = {}
      switch (code.length) {
        case 2:
          rq.receiveprovinceareacode = code
          break
        case 4:
          rq.receivecityareacode = code
          break
        case 6:
          rq.receivedistrictareacode = code
          break
        case 9:
          rq.receivestreetareacode = code
          break
      }
      return rq
    }
    const reData = async (rq?: any) => {
      console.log('转换时间戳',new Date('2023-05-22 08:52:36'))
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.receiveBN) {
            rq.receivedateBegin = dayjs(rq.receiveBN[0]).format("YYYY-MM-DD HH:mm:ss") 
            rq.receivedateEnd =dayjs(rq.receiveBN[1]).format("YYYY-MM-DD HH:mm:ss")  
            delete rq.receiveBN
          }
          if (rq.privateBN) {
            rq.privatizationexpirationdateBegin = dayjs(rq.privateBN[0]).format("YYYY-MM-DD HH:mm:ss")  
            rq.privatizationexpirationdateEnd = dayjs(rq.privateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.privateBN
          }
          if (rq.deliverdateBN) {
            rq.deliverdateBegin =dayjs( rq.deliverdateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.deliverdateEnd = dayjs( rq.deliverdateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.deliverdateBN
          }
          if (areaCode.value) {
            rq = { ...rq, ...switchCode(areaCode.value) }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await getOrderList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleCloseOrder = (item: any) => {
      closedOrderDialogRef.value.open(item)
    }
    const handleRefund = (id: string, userid:string, costs: number,startTime:string,deviceType:string) => {
      refundDialogRef.value.open(id, userid, costs,startTime,deviceType)
    }
    const onShowDrawer = (item:any) => {
      console.log('点击弹出');
      console.log(privatizationDrewerRef)
      privatizationDrewerRef.value.open(item);
    }
    const handlerShowSn = (sn:string) => {
      console.log('点击SN');
       componentsDialogRef.value.open(sn)
    }
    /**
     * 担保订单
     */
    const handlerGuaranteeOrder = (item:any) =>{
      guaranteeOrderRef.value.open(item.receiveruserid,item.receiveraccount,item.sharedorderid,item.outorderno)
    }
    const handleReDeduction = async (
      item: OrderList,
      Manual?: boolean
    ): Promise<any> => {
      let rentalfee = item.costs as number
      if (!Manual) {
        try {
          await ElMessageBox.confirm(
            `请确认是否发起订单扣费，金额${rentalfee}元？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '手动输入',
              type: 'warning',
              distinguishCancelAndClose: true,
            }
          )
        } catch (error) {
          if (error == 'cancel') return await handleReDeduction(item, true)
          return
        }
      }
      try {
        if (Manual) {
          const { value } = await ElMessageBox.prompt(
            '请输入订单扣款金额',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputErrorMessage: `请输入大于等于0小于${rentalfee}数字`,
              inputType: 'number',
              inputValidator: (value): boolean => {
                const _value = parseFloat(value)
                if (isNaN(_value)) return false
                return _value >= 0 && _value <= (item.costs || 0)
              },
            }
          )
          rentalfee = parseFloat(value)
          await ElMessageBox.confirm(
            `请确认是否发起订单扣费，金额${rentalfee}元？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              distinguishCancelAndClose: true,
            }
          )
        }
        reDeductionLoading.value = true
        const res = await sharedordermemoSettleup({
          sharedorderid: item.sharedorderid,
          rentalfee,
        })
        ElMessage.success(res)
        mixinReRouter()
      } finally {
        reDeductionLoading.value = false
      }
    }
    const handlePrvati = async (sharedorderId: string) => {
      try {
        prvatiLoading.value = true
        //const res = await sharedorderPrivatizationprocess(sharedorderId)
        const res = await sharedorderPrivatizationprocessNew(sharedorderId)
        ElMessage.success(res)
      } finally {
        prvatiLoading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const roleShowPermission = inject('roleShowPermission')
    const handleSplit = async (sharedorderid: string) => {
      try {
        splitLoading.value = true
        const res = await distributeprofit(sharedorderid)
        ElMessage.success(res)
      } finally {
        splitLoading.value = false
      }
    }
    const showCabintDivece = async(sn:string) =>{
      cabinetDetailsDialogRef.value.open(sn,null)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      token,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      mixinReRouter,
      closedOrderDialogRef,
      componentsDialogRef,
      guaranteeOrderRef,
      handleCloseOrder,
      handleRefund,
      refundDialogRef,
      allHoldertype,
      handleReDeduction,
      reDeductionLoading,
      handlePrvati,
      prvatiLoading,
      roleShowPermission,
      handleExport,
      cityCodeChange,
      handleSplit,
      handleClose,
      privatizationDrewerRef,
      onShowDrawer,
      handlerShowSn,
      handlerGuaranteeOrder,
      showCabintDivece,
      cabinetDetailsDialogRef,
    }
  },
  components: {
    closedOrderDialog,
    refundDialog,
    mySelectCity,
    privatizationDrewer,
    'v-components-dialog': componentsDialog,
    guaranteeOrderDialog,
    cabinetDatailsDialog,
  },
})
</script>
<style lang="scss" scoped>
.container {
  // padding: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/ .el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
  header {
    .el-date-editor {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-template{
    width: 100%;
     .header-el-selector {
      width:120px ;
      margin-right: 12px;

      /deep/.el-input{
        width: 120px;
      }
    }
  }
 

  .header-select {
    margin-right: 24px;
    margin-bottom: 24px;
  }
}
</style>