<template>
  <div class="container">
    <el-descriptions title="收益账单详情"  :column="1" border>
        <el-descriptions-item label="编号">{{orderInfo.articleequipmentsn}}</el-descriptions-item>
        <el-descriptions-item label="租赁人" >{{nickname}}</el-descriptions-item>
        <el-descriptions-item label="租借场所">{{orderInfo.formerholderplacename}}</el-descriptions-item>
        <el-descriptions-item label="租借地址">{{orderInfo.receiveaddress}}</el-descriptions-item>
        <el-descriptions-item label="开始时间" >{{orderInfo.receivedate}}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{orderInfo.deliverdate}}</el-descriptions-item>
        <!-- <el-descriptions-item label="租赁时长">{{orderInfo.receiveaddress}}</el-descriptions-item> -->
        <el-descriptions-item label="收益比">{{ proportion }}</el-descriptions-item>
        <el-descriptions-item label="收益">￥{{ amount }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject,onMounted} from 'vue'
import { investorProfitOrderModel, investorProfitOrderListHttp } from '@/api/Profit/investor'
import { userStore } from '@/store/modules/user'
import { getOrderList, OrderList} from '@/api/Order'
import { toOptional } from "@/types/utils";
import dayjs from 'dayjs'
import {useRoute} from "vue-router"
export default defineComponent({
  name: 'profit-order-details',
  components: {

  },
  
  setup() {
    const route = useRoute()
    const list = ref<OrderList[]>([])
    const profitList = ref<investorProfitOrderModel[]>([])
    const nickname =ref<any>();
    const proportion =ref<any>();
    const amount =ref<any>();
    const item = ref<string>();
    const profitInfo = ref<any>({})
    const shareorderid= ref<any>()
    shareorderid.value=route.query.shareorderid;
    proportion.value=route.query.proportion;
    amount.value=route.query.profit;
    nickname.value=route.query.name;

   // profitInfo.value=JSON.parse(JSON.stringify(route.query.shareorderid));
    // let obj: investorProfitOrderModel = JSON.parse(JSON.stringify(route.query.shareorderid));
    //nickname.value=profitInfo.value?.nickname;
    //proportion.value=profitInfo.value.proportion;
    //amount.value=profitInfo.value.amount;
    //console.log('数据')
    // console.log(obj.nickname)
    
   // const sharedorderid=profitInfo.sharedorderid;
    const orderInfo = ref<any>({});
  
    const reData = async (rq?: any) => {
      try {
        
        let rqData: any = { current: 1, size: 10 }
        
        rqData = { ...rqData, entity: rq }

        const res = await getOrderList(rqData)
        console.log("详情信息")
        console.log(res);
        list.value = res.records
        orderInfo.value=list.value[0];
        // total.value = res.total
        // getProfit({
        //         sharedorderid:sharedorderid,
        //         fortest: 'false', 
        //         userid:userStore.userInfo.userid,
        //         productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4'
        //     })
      } finally {
         
      }
    };

     const getProfit = async (rq?: any) => {
      try {
       
         let rqData: any = { currentPage: 1, size: 10  }

         rqData = { ...rqData,  ...rq }
        
        const res = await investorProfitOrderListHttp(rqData)
        profitList.value = res.records
        profitInfo.value=profitList.value[0];
        // total.value = res.total
      } finally {
        
      }
    }
    onMounted(() => {
    
      reData({
          sharedorderid:shareorderid.value 
      });
    });
    return {
     orderInfo,
     profitInfo,
     nickname,
     proportion,
     amount,
    }
  },
  
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>