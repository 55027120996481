
import { defineComponent, ref, readonly, inject ,onMounted} from 'vue'
import { fourthgmsgList, FourthgcommandList } from '@/api/Share/Device'
import routerChangeReData from '@/common/routerChangeReData'

import { ElMessage, ElMessageBox } from 'element-plus'
import { getShareProductInfo } from '@/api/util'
import {useRoute} from "vue-router"
import dayjs from 'dayjs'
export default defineComponent({
  name: 'report',
  components: {
   
  },
  setup() {

    const route = useRoute()
    const deviceId = ref<any>();
    deviceId.value=route.query.deviceid;
    const mixin_router_search = ref({
      datastream: '' //消息类型
      
    })
    const mixin_select_search = ref({
      search: {
        
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        deviceid: '设备id', 
      },
    })
    const searchSelect = ref({})
    const deviceSn=ref<any>();   
    const list = ref<FourthgcommandList[]>([])
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
  
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { page: page.value, limit: limit.value,deviceid:deviceId.value,sort: 'datatimestamp',
      order: 'desc' }
         if (rq ) {
        //   if (rq.order) {
        //     const { order, sort } = rq
        //     delete rq.order
        //     delete rq.sort
        //     rqData = { ...rqData, order, sort }
        //   }
          rqData = { ...rqData, ...rq }
        }

        const { totalCount, result } = await fourthgmsgList(rqData)
        total.value = totalCount
        list.value = result
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })

    const handelSearch = () => {

    }

    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')

    //  onMounted(() => {
    
    //   reData();
    // });

    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelSearch,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      list,
      loading,
      deviceSn,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
     
    }
  },
})
