import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface SharedorderdeductionList {
    "account": string,
    "amount": number,
    "articleequipmentsn": string,
    "articleproductid": string,
    "articleproductmodel": string,
    "articleproductname": string,
    "articleproducttype": string,
    "creationdate": string,
    "deducted": boolean,
    "deductionType": string,
    "deductiondate": string,
    "deductionid": string,
    "fortest": boolean,
    "model": string,
    "modificationdate": string,
    "outorderno": string,
    "sharedorderid": string,
    "userid": string
}

export async function sharedorderdeductionList(params: ListReq): Promise<ListRes<SharedorderdeductionList>> {
    params.token = true
    const res = await defHttp.request<ListRes<SharedorderdeductionList>>(
        {
            url: `/sharedorderdeduction/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.deductiondate=dayjs(v.deductiondate).format("YYYY-MM-DD HH:mm:ss");
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss");
        return v
    })
    return res
}

export async function exportSharedOrderDeductionList(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/sharedorderdeductionExport`,
            responseType:'blob',
            params
        }, {
            prefix: "/analy",
            contentType:'json',
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
}