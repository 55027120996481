<template>
    <el-drawer
        :title="title"
        v-model="isVisible"
        size="40%"
        :direction="direction"
        :before-close="handleClose">
       
          <el-form  class="table-expand" ref="formRef" label-width="120px">
              <el-form-item label="设备SN">
                {{orderDetailsInfo.inEquipmentSn}}              
              </el-form-item>
              
              <el-form-item label="故障描述">
                {{orderDetailsInfo.faultDesc}}              
              </el-form-item>
              <el-form-item label="故障图片">
                <a  v-for="(item,index) of orderDetailsInfo.faultPicList" :key="index" target="_blank">
                    <el-image :src="item" lazy :preview-src-list="orderDetailsInfo.faultPicList">
                    <!-- <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div> -->
                    </el-image>
                </a>
            </el-form-item>
               <el-form-item label="维修描述">
                {{orderDetailsInfo.maintainDesc}}              
              </el-form-item>
              <el-form-item label="维修图片">
                <a  v-for="(item,index) of orderDetailsInfo.maintainPicList" :key="index" target="_blank">
                    <el-image :src="item" lazy :preview-src-list="orderDetailsInfo.maintainPicList">
                    <!-- <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div> -->
                    </el-image>
                </a>
            </el-form-item>
          </el-form>
        
    </el-drawer>
</template>
<script lang="ts">

import { ref,computed, defineComponent, readonly ,reactive} from "vue";
import { repairDetailsModel, getDetailsHttp } from '@/api/fault/repair'
import { toOptional } from "@/types/utils";
import { userStore } from "@/store/modules/user";
export default defineComponent({


    setup() {
        const isVisible = ref(false);
        const title = ref("详情信息");
        const direction=ref("rtl");
        const status=ref(0)
        const sn=ref('')  //设备SN
        const formRef = ref<any>({})
        const faultId=ref('') //故障id

        const orderDetailsInfo=ref<toOptional<repairDetailsModel>>({});
        const handleClose=(done:any)=>{
          formRef.value.resetFields()
          formRef.value.clearValidate()
          done();
        };

      
        /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用222');
            console.log(item.id)
            isVisible.value = true;
           
            faultId.value=item.id
            title.value=item.inEquipmentSn+'故障详情'
        
            orderDetailsInfo.value={};
            getFaultDetails()
           
        };

      
        /**
         * 获取详情信息
         */
        const getFaultDetails = async () => {          
          const info = await getDetailsHttp(faultId.value)
          orderDetailsInfo.value=info.data;
        }


        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,
            orderDetailsInfo,         
            sn,          
            formRef,
            status,
        };
    },
    components: {
      
    }
   
});
</script>
<style scoped lang='scss'>

.el-drawer__body{ 
    overflow: auto; 
}

</style>

<style rel="stylesheet/scss" lang="scss">
    .el-drawer.rtl{

    overflow: scroll
    }

</style>