<template>
  <div class="container">
    <header>
      <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="钱包类型" class="header-el-selector" v-model="mixin_router_search.fortest">
        <el-option label="正式钱包" :value="false"></el-option>
        <el-option label="测试钱包" :value="true"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.articleproductname">
        <el-option label="小魔夹" value="小魔夹M4S2"></el-option>
        <el-option label="雨伞" value="共享雨伞"></el-option>
      </el-select>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <!-- <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item> -->
            <el-form-item label="用户id">
              <el-button type="text" @click="toPath_RouterSearchMixin('/user',[{search_userid:scope.row.userid}])">{{ scope.row.userid }}</el-button>
              <my-copy :data="scope.row.userid" />
            </el-form-item>
            <el-form-item label="冻结单号">
              <span>{{ scope.row.frozenid }}</span>
              <my-copy :data="scope.row.frozenid" />
            </el-form-item>
            <el-form-item label="sign">
              <span>{{ scope.row.sign }}</span>
              <my-copy :data="scope.row.sign" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="用户账号" min-width="40">
        <template #default="scope">
           {{scope.row.account}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="冻结原因" min-width="40">
        <template #default="scope">
          <div v-if="scope.row.reason=='sharedorder'">订单押金</div>
          <div v-if="scope.row.reason=='demandredenvelope'">需求红包</div>
          <div v-if="scope.row.reason=='demandlock'">需求锁定</div>
          <div v-if="scope.row.reason=='supplyredenvelope'">发布红包</div>
          <div v-if="scope.row.reason=='supplylock'">发布锁定</div>
          <div v-if="scope.row.reason=='withdraw'">提现冻结</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="钱包类型" min-width="40">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          <span>{{scope.row.articleproductname}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="冻结金额" min-width="40">
        <template #default="scope">
          {{scope.row.amount}}
        </template>
      </el-table-column>
     <el-table-column align="center" label="创建时间" min-width="40">
        <template #default="scope">
          {{scope.row.creationdate}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <!-- <el-button
              :loading="btnRemoveLoaidng"
              @click="handleRemove(scope.row)"
              size="mini"
              type="primary"
            >解冻</el-button> -->
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.row)"
            size="mini"
            type="danger"
          >解冻</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="page" :page-size="limit" :page-sizes="[10, 30, 50, 100]" :total="total" @current-change="onPageChange" @size-change="handleLimitChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import { ElMessage, ElMessageBox } from 'element-plus'
import { userfundfrozenList,removefundfrozen } from "@/api/Financial/walletFrozen";
export default defineComponent({
  setup() {
    const loading = ref(false);
    const btnEnabledLoaidng = ref(false);
    const btnRemoveLoaidng = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<any[]>([]);
    const searchSelect = ref({
      search: "userid",
    });
    const mixin_router_search = ref({
      order: 'creationdate',
      sort: 'desc',
      articleproductname: "共享雨伞",
      fortest: false,
    });
    const mixin_select_search = ref({
      search: {
        account:"",
        userid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',
        userid: "用户ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
            if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await userfundfrozenList(rqData);
        console.log("res.records :>> ", res.records);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    const handleRemove = async(item:any) =>{
        const phone=item.account;
        const orderid=item.frozenid;
        await ElMessageBox.confirm(
        `是否删除`+phone+'的冻结金额，删除后将可以正常提现',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        })
        const res = await removefundfrozen(orderid)
        mixinReRouter()
        ElMessage.success(res)
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
      handleRemove,
      toPath_RouterSearchMixin,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 130px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>