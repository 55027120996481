<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select
        @change="handelChange"
        class="header-el-selector"
        clearable
        placeholder="投资订单出货状态"
        v-model="mixin_router_search.status"
      >
        <el-option
          :key="getStatusName[item]"
          :label="getStatusName[item]"
          :value="item"
          v-for="item of Object.keys(getStatusName)"
        ></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="平台投放开始状态"
        class="header-el-selector"
        v-model="mixin_router_search.platformlaunchbegin"
      >
        <el-option :value="true" label="已开始平台投放"></el-option>
        <el-option :value="false" label="未开始平台投放"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="平台投放结束状态"
        class="header-el-selector"
        v-model="mixin_router_search.platformlaunchend"
        v-show="mixin_router_search.platformlaunchbegin"
      >
        <el-option :value="true" label="已完成平台投放"></el-option>
        <el-option :value="false" label="未完成平台投放"></el-option>
      </el-select>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">创建无投资出货单</el-button>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            
            <el-form-item label="出货单编码">
              <span>{{ scope.row.shipmentid }}</span>
              <my-copy :data="scope.row.shipmentid"></my-copy>
            </el-form-item>
            <el-form-item label="投资订单编码">
              <span>{{ scope.row.investmentorderid }}</span>
              <my-copy :data="scope.row.investmentorderid"></my-copy>
            </el-form-item>
            
            <el-form-item label="平台投放结束时间" v-if="scope.row.platformlaunchenddate">
              <span>{{ scope.row.platformlaunchenddate }}</span>
            </el-form-item>
            <div v-if="scope.row.packagedelivereddate">
              <el-form-item label="快递寄出时间">
                <span>{{ scope.row.packagedelivereddate }}</span>
              </el-form-item>
              <el-form-item label="快递公司名">
                <span>{{ scope.row.courierfirm }}</span>
              </el-form-item>
              <el-form-item label="快递单号">
                <span>{{ scope.row.trackingnumber }}</span>
              </el-form-item>
              <el-form-item label="快递单号">
                <span>{{ scope.row.trackingnumber }}</span>
              </el-form-item>
              <el-form-item label="快递签收时间" v-if="scope.row.packagesigneddate">
                <span>{{ scope.row.packagesigneddate }}</span>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" min-width="28">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          {{getStatusName[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column label="设备类型" min-width="36"  >
        <template #default="scope">
          <el-tag v-if="scope.row.productId">{{productList[productIdList.indexOf(scope.row.productId)].name}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="数量" min-width="28" prop="quantity" ></el-table-column>
      <el-table-column label="创建时间" min-width="28" prop="creationdate" label-position="center"></el-table-column>
      <el-table-column label="出仓时间" min-width="28" prop="outwarehousedate" label-position="center">
        <template #default="scope">
          {{scope.row.outwarehousedate?scope.row.outwarehousedate:"暂无"}}
        </template>       
      </el-table-column>

      <el-table-column label="操作" min-width="28">
        <template #default="scope">
          <el-button @click="handleShowAssgin(scope.row)" size="mini" type="info">查看已分配</el-button>
          <el-button
            @click="handleAssgin(scope.row)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='0'"
          >分配</el-button>
          <el-button
            @click="handleOut(scope.$index,scope.row.shipmentid)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='0'"
          >出仓</el-button>
          <el-button
            @click="handleStartLaunch(scope.$index,scope.row.shipmentid)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='1'"
          >开始投放</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-assign-dialog ref="assignDialogRef"></v-assign-dialog>
    <v-assign-new-dialog ref="assignNewDialogRef"></v-assign-new-dialog>
    <addNoOrderShipmentDialog @success="handleSuccess" ref="addNoOrderShipmentRef"> </addNoOrderShipmentDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from "vue";
import { getStatusName } from "./editData";
import routerChangeReData from "@/common/routerChangeReData";
import assignDialog from "./components/assignDialog.vue";
import assignNewDialog from "./components/assignNewDialog.vue";
import addNoOrderShipmentDialog from "./components/addNoOrderShipmentDialog.vue"
import {getProductInfoList,getProductIdList} from '@/api/util'
import { userStore } from '@/store/modules/user'
import {
  investmentordershipmentList,
  InvestmentordershipmentListRes,
  outwarehouse,
  startlaunch,
  addNoInvestorOrderShipmentHttp,
} from "@/api/Investment/shipment";
import { ElMessage, ElMessageBox } from "element-plus";
import { number } from "echarts/core";
export default defineComponent({
  name: "Name",
  components: {
    "v-assign-dialog": assignDialog,
    "v-assign-new-dialog":assignNewDialog,
    addNoOrderShipmentDialog,
  },
  setup() {
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const mixin_router_search = ref({
      status: "", //投资订单出货状态，0 未出仓；1 已出仓；2 平台投放已开启；3 平台投放已结束；4 快递已寄出；5 快递已签收
      platformlaunchbegin: "", //平台投放是否开启，如：true 是 false 否
      platformlaunchend: "", //平台投放是否结束，如：true 是 false 否
      outwarehouse: "", //平台投放是否开启，如：true 是 false 否
      packagedelivered: "", //快递是否寄出，如：true 是 false 否
      packagesigned: "", //	快递是否被签收，如：true 是 false 否
      order: "creationdate",
      sort: "desc",
    });
    const mixin_select_search = ref({
      search: {
        investoruserid: "", // 投资人用户编号
        investmentorderid: "", //投资订单编码
        trackingnumber: "", // 快递单号
        shipperuserid: "", //投资订单编码       
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        investoruserid: "投资人用户编号", // 投资人用户编号
        investmentorderid: "投资订单编码", //投资订单编码
        trackingnumber: "快递单号", //投资订单编码
        shipperuserid: "投资订单编码", //投资订单编码
      },
    });
    const btnOutLoading = ref(false);
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<InvestmentordershipmentListRes | any>([]);
    const searchSelect = ref({search:"investoruserid"});
    const assignDialogRef = ref<any>();
    const assignNewDialogRef = ref<any>();
    const addNoOrderShipmentRef = ref<any>();
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = {
          current: page.value,
          size: limit.value,
        };
        // if (rq && JSON.stringify(rq) !== "{}") {
        //   rqData = { ...rqData, entity: rq };
        // }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await investmentordershipmentList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
    } = routerChangeReData({
      mixin_router_search,
      mixin_select_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleSuccess =() =>{
      page.value = 1;
      mixinReRouter();
    }
    const handleAssgin = (row: any) => {
      // assignDialogRef.value.open(row);
      assignNewDialogRef.value.open(row);
    };
    const handleShowAssgin = (row: any) => {
      // assignDialogRef.value.open(row, true);
      assignNewDialogRef.value.open(row,true);
    };
    const handleOut = async (index: number, shipmentid: string) => {
      try {
        btnOutLoading.value = true;
        const res = await outwarehouse({
          shipmentid,
        });
        ElMessage.success(res.msg);
        mixinReRouter();
        // list.value[index] = res.record;
      } finally {
        btnOutLoading.value = false;
      }
    };
    const handleStartLaunch = async (index: number, shipmentid: string) => {
      try {
        btnOutLoading.value = true;
        const res = await startlaunch({
          shipmentid,
        });
        ElMessage.success(res.msg);
        mixinReRouter();
        //list.value[index] = res.record;
      } finally {
        btnOutLoading.value = false;
      }
    };

    const handleAdd = async() => {
      addNoOrderShipmentRef.value.open()
      // try {
      //   const input = await ElMessageBox.prompt('请输入出货数', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     inputPattern: /^[0-9]*$/,
      //     inputErrorMessage: `请输入 1-60 `,
      //     inputType: 'number',
      //   })
      //   console.log('input :>> ', input)
      //   const _value = parseInt(input.value)
      //   if (_value > 60 || _value < 1)
      //     return ElMessage.error(`请输入 1-60`)
      //   if (!userStore.info.userid) return userStore.loginOut()
      //     const res = await addNoInvestorOrderShipmentHttp({
      //       quantity: _value,
      //       shipperUserId: userStore.info.userid,
      //     })
      //   console.log('res :>> ', res)
      //   ElMessage.success(res)
      //   mixinReRouter();
      // } catch (error) {
      //   if (error == 'cancel') return
      //   if (error == 'colse') return
      //   ElMessage.error(error)
      // }
        
    }

    return {
      mixin_select_search,
      mixin_select_search_label,
      searchSelect,
      mixin_router_search,
      getStatusName,
      loading,
      limit,
      page,
      total,
      list,
      handelChange,
      onPageChange,
      handleLimitChange,
      assignDialogRef,
      assignNewDialogRef,
      addNoOrderShipmentRef,
      productList,
      productIdList,
      handleAssgin,
      handleOut,
      handleAdd,
      handleStartLaunch,
      handleShowAssgin,
      handleSuccess,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
      /deep/ .el-input {
        width: 320px;
        .el-select {
          width: 130px;
        }
      }
      /deep/ .el-select {
        width: 130px;
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }

    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 160px;
    margin-right: 12px;
     /deep/.el-input {
      width: 140px;
    }
  }
}
</style>