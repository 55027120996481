<template>
    <el-dialog :destroy-on-close="true" title="接收采购单" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
     <el-alert
        title="采购单接收之后，采购单为完结状态"
        type="warning"
        show-icon
        :closable="false">
      </el-alert>
      <el-form :model="inputData" :rules="rules" label-width="100px" ref="formRef" style="margin-top: 20px;" >
        <el-form-item label="接收员" prop="accectAccount">
          <el-input v-model="inputData.accectAccount" placeholder="请输入接收人手机号" style="width: 60%;" @blur="newAccountChange"></el-input>
        </el-form-item> 
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  <script lang = "ts" >
  import { userRoleList,  removeRepair} from '@/api/User/role'
  import { defineComponent, ref, toRef } from "vue";
  import { ElMessage } from "element-plus";
  let Id=""
  export default defineComponent({
    name: "v-dialog",
    props: ["type"],
    data() {
      return {
        rules: {
          accectAccount: [{ required: true, message: '请输入接收人手机号', trigger: 'blur' }]      
        },
      };
    },
    setup(props: any) {
      const visible = ref(false);
      const btnSumitLoading = ref(false);
      const isAdd = ref(true);

      const userList = ref<any>([])

      const formRef =ref<any>("");
      const inputData = ref({
        accectAccount: "",
      });
      const handleClose = () => {
        visible.value = false;
        formRef.value.resetFields();
        formRef.value.clearValidate();
      };
      const open = (_id:string) => {
        visible.value = true;
        Id=_id;
        inputData.value.accectAccount=""

      };
      const newAccountChange = async () =>{
        if(inputData.value.accectAccount){
          let rqData: any = { page: 1, limit: 10,repairmanaccount: inputData.value.accectAccount}
          const res = await userRoleList(rqData, "repairman")
          userList.value=res.result
          const operationid=userList.value[0].operationId;
         
        }
      }
  
      const submit = async () => {
        try {
          try {
            await formRef.value.validate();
          } catch (error) {
            console.log(error);
            throw Error("请检查输入");
          }
          btnSumitLoading.value = true;
          
          const rqData = { newAccount:inputData.value.accectAccount}
          const result =  await removeRepair(rqData);
          ElMessage.success(result);
          handleClose();
        
        } finally {
          btnSumitLoading.value = false;
        }
      };
      return {
        isAdd,
       
        visible,
        handleClose,
        open,
        newAccountChange,
      
        btnSumitLoading,
        inputData,
        submit,
        formRef,
      };
    },
  });
  </script>
  <style lang = "scss" scoped >
  </style>