
import { ProductList } from '@/api/System/product'
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, readonly, ref } from 'vue'
import firmwareUpload from './firmwareUpload.vue'
export default defineComponent({
  name: 'v-dialog',
  components: {
    'v-file-upload': firmwareUpload,
  },
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const form = reactive({
      version: '',
      description: '',
    })
    const rules = {
      version: Rules.firmwareVersion,
      description: [Rules.required()]
    }
    const row = ref<ProductList>()
    const btnSumitLoading = ref(false)
    const firmwareUploadRef = ref<any>()
    const formRef = ref<any>()
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: ProductList) => {
      console.log('_row :>> ', _row)
      row.value = _row
      visible.value = true
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        await formRef.value.validate()
        firmwareUploadRef.value.submit()
      } finally {
        btnSumitLoading.value = false
      }
    }
    const upLoadSuccess = (e: string) => {
      emit('success')
      ElMessage.success(e || '上更新固件成功')
    }
    return {
      visible,
      handleClose,
      rules,
      row,
      open,
      btnSumitLoading,
      firmwareUploadRef,
      form,
      formRef,
      submit,
      upLoadSuccess,
    }
  },
})
