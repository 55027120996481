
import type { App } from 'vue';
import { isArray } from '@/utils/is'
import { ElMessage } from "element-plus"
import { getErrMessage } from "@/utils/http/axios/requestResult"
import { defHttp, xmj_admin_Http } from "@/utils/http/axios"
import EAB from "@/utils/base64"
import _ from "lodash"
export function setupAppConfig(app: App<Element>) {

    app.config.errorHandler = (err: any, vm, info) => {
        // 处理错误
        // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
        console.log('errorHandler_err :>> ', err);
        console.log('errorHandler_vm :>> ', vm);
        console.log('errorHandler_info :>> ', info);
        let message = ''
        if (err) {
            if (err == 'close') return
            if (err == 'cancel') return
            if (err.message) {
                message = err.message
            } else {
                for (const key of Object.keys(err)) {
                    if (isArray(err[key])) {
                        console.log('err[key] :>> ', err[key]);
                        // err.map(v => {
                        //     if (v.message) message = v.message
                        // })
                        const _err = _.find(err[key], 'message')
                        console.log('_err :>> ', _err);
                        if (_err) {
                            message = _err.message
                            break
                        }
                    }
                }
            }
            message = message ? message : getErrMessage(err)
        }
        ElMessage.error(message || "未知错误")
    }
    // app.config.warnHandler = function (msg, vm, trace) {
    //     // `trace` 是组件的继承关系追踪        
    //     console.log('warnHandler_msg :>> ', msg);
    //     console.log('warnHandler_vm :>> ', vm);
    //     console.log('warnHandler_trace :>> ', trace);
    // }

    // app.config.isCustomElement = tag => tag.startsWith('ion-')
    app.config.globalProperties = Object.assign({}, app.config.globalProperties, {
        $EAB: EAB,
        $defHttp: defHttp,
        $xmj_admin_Http: xmj_admin_Http
    })
    // app.config.globalProperties.$EAB = EAB
    // app.config.globalProperties.$defHttp = defHttp
    // app.config.globalProperties.$xmj_admin_Http = xmj_admin_Http
}
