import { defHttp } from "@/utils/http/axios";
import { ListRes, RequestRes,ListResProft} from "../../types/requestBaseType"
import dayjs from "dayjs"
import { getTimeArr } from "../util";

export interface totalProfitModel {
    "gainerrole": string,
    "amount": number,
}

/**
 * 
 * @param parmas 查询一个角色的总收益
 * @returns 
 */
export async function getInvsetorTotalProfit(parmas?: any): Promise<ListResProft<totalProfitModel>> {
    const res = await defHttp.request<ListResProft<totalProfitModel>>(
        {
            url: `/Investment/getSumProfitByGainerrole`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/analy",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }  
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res
}

/**
 * 按时间查询总收益
 */
 export async function getProfitOnDay(parmas?: any): Promise<ListResProft<totalProfitModel>> {
    const res = await defHttp.request<ListResProft<totalProfitModel>>(
        {
            url: `/sharedorderprofit/getSumProfitOfInvestorAndDateAndModel`,
            params: {
                token: true,
                ...parmas
            },
        }, {
        prefix: "/analy",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }  
    }
    );
    if (!res?.data) throw Error("获取信息失败")
    return res
}