<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`添加投资人`"
    @closed="handleClose"
    v-if="visible"
    v-model="visible"
    width="750px"
  >
    <el-autocomplete
      :fetch-suggestions="querySearchAsync"
      @select="onSearch"
      class="input-with-select"
      placeholder="请输入搜索内容"
      v-model="searchData"
    >
      <!-- v-el-loadmore="loadmore" -->
      <template #prepend>
        <el-select placeholder="请选择" v-model="sharchType">
          <el-option label="姓名" value="investorname"></el-option>
          <el-option label="账号" value="investoraccount"></el-option>
          <el-option label="用户ID" value="investoruserid"></el-option>
        </el-select>
      </template>

      <template #append>
        <el-button @click="handleSearch" icon="el-icon-search"></el-button>
      </template>
    </el-autocomplete>
    <el-form :model="selectData" class="form" label-width="120px" ref v-if="selectData.name">
      <el-form-item label="投资人姓名：">
        <span class="value">{{selectData.name}}</span>
      </el-form-item>
      <el-form-item label="ID：">
        <span class="value">{{selectData.userid}}</span>
      </el-form-item>
      <el-form-item label="电话号码：">
        <span class="value">{{selectData.account}}</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">添 加</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- <div v-el-loadmore="loadmore"></div> -->
</template>
<script lang="ts">
import { defineComponent, ref, nextTick } from "vue";
import { investorList, InvestorListListRes } from "@/api/User/investor";
import { add } from "@/api/ERP/user";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const visible = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const nomore = ref(false);
    const sharchType = ref("investorname");
    const searchData = ref("");
    const limit = ref(10);
    const page = ref(1);
    const searchList = ref<InvestorListListRes[]>([]);
    const selectData = ref({
      name: "",
      account: "",
      userid: "",
    });
    let mCb: any;
    const handleClose = () => {
      visible.value = false;
      mCb = null;
    };
    const onSearch = (e: any) => {
      selectData.value = {
        name: e.investorname,
        account: e.investoraccount,
        userid: e.investoruserid,
      };
    };

    const loadData = async (re = false, queryString?: any) => {
      //   if (!sharchType.value) return ElMessage.error("请选择搜索类型");
      //   if (!searchData.value) return ElMessage.error("请输入搜索内容");
      if (re) {
        page.value = 1;
        searchList.value = [];
      }
      try {
        loading.value = true;
        const reData: any = {
          limit: limit.value,
          page: page.value,
        };
        reData[sharchType.value] = searchData.value;
        const { total, result } = await investorList(reData);
        const _result = result.map((v) => {
          v.value = v.investorname;
          return v;
        });
        searchList.value.push(..._result);
        if (page.value * limit.value >= total) nomore.value = true;
        await nextTick();
        if (!result.length) return;
        mCb && mCb(searchList.value);
      } finally {
        loading.value = false;
      }
    };
    const handleSearch = async () => {
      await loadData(true);
    };

    const loadmore = async () => {
      if (nomore.value) return;
      page.value++;
      await loadData();
    };

    const querySearchAsync = async (queryString: any, cb: any) => {
      if (!mCb) mCb = cb;
      await loadData(true, queryString);
    };
    const open = async () => {
      visible.value = true;
      await loadData(true);
    };

    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        const res = await add(selectData.value || {});
        emit("success", res.data);
        ElMessage.success(res.message);
        handleClose();
        console.log("res :>> ", res);
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      open,
      handleClose,
      sharchType,
      searchData,
      querySearchAsync,
      handleSearch,
      loadmore,
      onSearch,
      selectData,
      submit,
    };
  },
});
</script>
<style lang="scss" scoped>
.input-with-select {
  width: 320px;
  .el-select {
    width: 150px;
  }
  ~ * {
    margin-left: 24px;
    .form {
      margin: 24px auto;
      width: 80%;
    }
  }
}
</style>