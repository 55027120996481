
import { ref,computed, defineComponent, onMounted ,reactive} from "vue";
import { getSocketList, SocketListRes } from "@/api/Share/Socket";
export default defineComponent({
    setup() {
        const isVisible = ref(false);
        const title = ref("仓位口设备");
        const direction=ref("rtl");
        const page = ref(1);
        const limit = ref(15);
        const loading = ref(false);
        const list = ref<SocketListRes[]>([]);
        const total = ref(0);
        const panelequipmentsn = ref("");
        const prefix = ref("");
        /**
         * 关闭页面
         */
        const handleClose=(done:any)=>{
            done();
        };

         /**
         * 父组件调用方法
         */
        const open = (item:any) => {
            console.log('弹窗方法调用');
            isVisible.value = true;
            panelequipmentsn.value=item.panelequipmentsn;
            title.value=item.panelequipmentsn+"仓位口设备"
             reData()
        };

        const reData = async () => {
            try {
                loading.value = true;
                const res = await getSocketList({
                current: page.value,
                size: limit.value,
                entity: { panelequipmentsn: panelequipmentsn.value },
                });
                console.log('res :>> ', res);
                total.value = res.total;
                list.value = res.records;
            } finally {
                loading.value = false;
            }
        };

        const onPageChange = () =>{

        }

        return{
            title,
            isVisible,
            direction,
            handleClose,
            open,           
            panelequipmentsn,    
            onPageChange,       
            prefix,
            page,
            limit,
            total,
            list,
        }
    }
})
