import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export const holdertype = {
    ownerless: '无主',
    tester: '测试者',
    investor: '投资人',
    socket: '机柜',
    consumer: '用户',
    dispatcher: '调度员',
    factoryrepairman: '现场维修员',
    fieldrepairman: '工厂维修员 ',
    operatingCenter:'运营中心',
}
export interface DeviceListRes {
    panelequipmentsn: string
    articleequipmentsn: string
    creationdate: string
    enabled: boolean
    holdertype: typeof holdertype
}
export async function getShareDeviceList(params: ListReq): Promise<ListRes<DeviceListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<DeviceListRes>>(
        {
            url: `/sharedarticle/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        if (!v.articleequipmentsn) v.articleequipmentsn = '空'
        return v
    })
    return res
}

export async function getAnalyShareDeviceList(params: ListReq): Promise<ListRes<DeviceListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<DeviceListRes>>(
        {
            url: `/shareDevice/infolist`,
            params
        }, {
        prefix: "/analy",
        contentType:'json'
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        if (!v.articleequipmentsn) v.articleequipmentsn = '空'
        return v
    })
    return res
}
export async function exportAnalyShareDeviceList(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportSharedarticleInfo`,
            responseType:'blob',
            params
        }, {
            prefix: "/analy",
            contentType:'json',
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
}

export async function getDeviceList(params: ListReq): Promise<ListRes<DeviceListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<DeviceListRes>>(
        {
            url: `/equipment/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        if (!v.articleequipmentsn) v.articleequipmentsn = '空'
        return v
    })
    return res
}


export interface analyDeviceModel {
    sn: string,
    prefix: string,
    productid: string,
    stage: number,
    creationdate: string,
    modificationdate: string,
    status: number,
    adType: number,
    equipmentsn: string,
    sign: string,
    deviceid: string,
    mac: string,
    version:string,
    versionSetting: string,
    electricquantity: string,
    latitude: string,
    longitude: string,
    online: number
}


/**
 * 获取设备列表
 */
 export async function getAnalyDeviceList(parmas: RequestReq): Promise<ListRes<analyDeviceModel>> {
    const res = await defHttp.request<ListRes<analyDeviceModel>>(
        {
            url: `/equipment/getEquipmentList`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    res.data.records=res.data.records.map(v=>{
        if(v.status==3){
            v.status=4;
        }else if(v.status==4){
            v.status=5;
        }else if(v.status==5){
            v.status=6;
        }else if(v.status==6){
            v.status=3;
        }
        return v;
    })
    return res
}

export interface LoadOldCabinetRes {
    address: string
    nickname: string
    latitude: number | string
    longitude: number | string
    repairmanaccount: string
    repairmanname: string
    repairmanuserid: string
    streetareacode: string
    businesshours: string
}
export async function loadOldCabinet(sn: string): Promise<LoadOldCabinetRes> {
    const res = await defHttp.request<ListRes<LoadOldCabinetRes>>(
        {
            url: `/bluetooth/cabinet/list`,
            params: {
                sn,
                token: true,
                limit: 10,
                page: 1
            }
        }
    );
    return res.result[0]
}
export interface FourthgcommandList {
    creationtime: string
    datatimestamp: string
}

export async function fourthgmsgList(params: ListReq): Promise<ListRes<FourthgcommandList>> {
    params.token = true
    const res = await defHttp.request<ListRes<FourthgcommandList>>(
        {
            url: `/fourthgmsg/list`,
            params
        }
    );
    res.result = res.result.map(v => {
        v.creationtime = dayjs(v.creationtime).format('YYYY-MM-DD HH:mm:ss')
        v.datatimestamp = dayjs(v.datatimestamp).format('YYYY-MM-DD HH:mm:ss')
        return v
    })
    console.log('res :>> ', res);
    return res
}
export interface FourthgcommandList {
    creationtime: string
    datatimestamp: string
}

export async function fourthgcommandList(params: ListReq): Promise<ListRes<FourthgcommandList>> {
    params.token = true
    const res = await defHttp.request<ListRes<FourthgcommandList>>(
        {
            url: `/fourthgcommand/list`,
            params
        }
    );
  
    res.result = res.result.map(v => {
        v.creationtime = dayjs(v.creationtime).format('YYYY-MM-DD HH:mm:ss')
        v.datatimestamp = dayjs(v.datatimestamp).format('YYYY-MM-DD HH:mm:ss')
        return v
    })
    console.log('res :>> ', res);
    return res
}


export interface DeviceTypeTotalModel {
    total: number|string
    productname: string
    producttype: string
    productmodel: string
}

/**
 * 
 * @param params 查询各类机柜的投放总数量
 * @returns 
 */
 export async function getCabinetTypeTotal(parmas: ListReq): Promise<ListRes<DeviceTypeTotalModel>> {
    const res = await defHttp.request<ListRes<DeviceTypeTotalModel>>(
        {
            url: `/operateController/getCabinetTypeTotal`,
            params: {
                token: true,
                ...parmas
            },
        },
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
   
    return res
}


/**
 * 
 * @param params 查询各类机柜的故障总数量
 * @returns 
 */
 export async function getCabinetFaultTotal(parmas: ListReq): Promise<ListRes<DeviceTypeTotalModel>> {
    const res = await defHttp.request<ListRes<DeviceTypeTotalModel>>(
        {
            url: `/operateController/getCabinetFaultTotal`,
            params: {
                token: true,
                ...parmas
            },
        },
        {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
   
    return res
}

/**
 * 生成新二维码
 */

export async function generateNewSn(parmas: ListReq): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/equipment/generate`,
            params: {
                token: true,
                ...parmas
            },
        },
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
   
    return res
}

export async function exportQRSn(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportQRcode`,
            responseType:'blob',
            params
        }, {
            prefix: "/analy",
            contentType:'json',
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
}

/**
 * 
 * @param params 查询m4s设备
 * @returns 
 */
export async function getM4sDevice(params: ListReq): Promise<ListRes<DeviceListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<DeviceListRes>>(
        {
            url: `/shareDevice/generationList`,
            params
        }, {
            prefix: "/analy",
            contentType:'json'
        }
    );
   
    return res
}
