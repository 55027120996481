import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
import { formatAgo,formatTimeLong,formatTimeLongToStr } from "@/utils/dateUtil";
export interface PanelListRes {
    creationdate: string
    enabled: boolean
}
export async function getPanelList(params: ListReq): Promise<ListRes<PanelListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<PanelListRes>>(
        {
            url: `/sharedpanel/infolist`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}
export async function upDatePanel(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<PanelListRes>>(
        {
            url: `/sharedpanel/update`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function removePanel(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<PanelListRes>>(
        {
            url: `/sharedpanel/remove`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData
}
export async function placeBindpanel(params: RequestReq) {
    const reData = await defHttp.request<RequestRes<PanelListRes>>(
        {
            url: `/place/bindpanel`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return reData
}

export async function getcrc(params: RequestReq) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/fourthgdevice/getcrc`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return reData.message
}

/**
 * 
 * @param params 发送自定义消息
 * @returns 
 */
export async function sendOnenNetMsg(params: RequestReq) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/fourthgcommand/sendmsg`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData.msg
}

/**
 * 
 * @param params 设备设备版本
 * @returns 
 */
 export async function updateXMJVersion(params: RequestReq) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/product/update`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
    }
    );
    return reData.msg
}

export interface GetContainInfoList {
    "panelequipmentsn": string,
    "placeid": string,
    "address": string,
    "deviceid": string,
    "mac": string,
    "lastofflinetime": number,
    "lastonlinetime": number,
    "millisecond": number,
    "borrownum": number,
    "returnnum": number,
    "normalnum" : number,
    "emptynum": number,
    "faultnum": number,
    borrowSecond:string,
    returnSecond:string,
    createTime:string,
    repairmanName:string,
    operatorName:string,
    onlineTotal:string,
    offlineTotal:string,
    contactname:string,
    contactmobile:string,
    cityareacode:string,
    districtareacode:string,
    streetareacode:string,
    allnum: number
    num: number
    name: string
    online: 0 | 1
    productid: string
    productmodel: string
    productname: string
    producttype: string
    lastofflineDate: string
    lastonlineDate: string
    offlineLongtime: number
    offlineTimeLongText:string
    onlineLongtime: number
    lastofflineText: string
    onlineLongText: string
    onlineTimeLongText:string
    signboardUrls:any
    isHasPic:boolean
    placeImage:string
    operationname:string
}
export async function getContainInfoList(params: ListReq): Promise<{
    list: GetContainInfoList[];
    total: number;
}> {
    params.token = true
    const res = await defHttp.request<ListRes<GetContainInfoList>>(
        {
            url: `/operateController/getContainInfo`,
            params
        }, {
        prefix: "/analy",
        contentType: 'json',
    });
    if (!res.data) throw Error("获取机柜信息失败")
    res.data.list = res.data.list.map(v => {
        v.num = v.allnum - v.faultnum - v.emptynum - v.normalnum
        v.isHasPic = v.signboardUrls!=null
        if(v.isHasPic){
            v.placeImage=v.signboardUrls[0]
        }
        v.offlineTimeLongText = formatTimeLongToStr(v.offlineTotal)
        v.onlineTimeLongText = formatTimeLongToStr(v.onlineTotal)
        if (v.online) {
            v.lastonlineDate = dayjs(v.lastonlinetime).format("YYYY-MM-DD HH:mm:ss")
            v.onlineLongtime = dayjs().diff(v.lastonlinetime).valueOf()
            //v.onlineLongText =formatAgo(v.lastonlinetime)
            v.onlineLongText = formatTimeLong(v.lastonlinetime)
        } else {
            v.lastofflineDate = dayjs(v.lastofflinetime).format("YYYY-MM-DD HH:mm:ss")
            v.offlineLongtime = dayjs().diff(v.lastofflinetime).valueOf()
            //v.lastofflineText =formatAgo(v.lastofflinetime)
            v.lastofflineText =formatTimeLong(v.lastofflinetime)
        }

        return v
    })
    return res.data
}

export async function getContainList(params: ListReq): Promise<{
    list: GetContainInfoList[];
    total: number;
}> {
    params.token = true
    const res = await defHttp.request<ListRes<GetContainInfoList>>(
        {
            url: `/operateController/getContainCabInfo`,
            params
        }, {
        prefix: "/analy",
        contentType: 'json',
    });
    if (!res.data) throw Error("获取机柜信息失败")
    res.data.list = res.data.list.map(v => {
        v.num = v.allnum - v.faultnum - v.emptynum - v.normalnum
        v.isHasPic = v.signboardUrls!=null
        if(v.isHasPic){
            v.placeImage=v.signboardUrls[0]
        }
        v.offlineTimeLongText = formatTimeLongToStr(v.offlineTotal)
        v.onlineTimeLongText = formatTimeLongToStr(v.onlineTotal)
        if (v.online) {
            v.lastonlineDate = dayjs(v.lastonlinetime).format("YYYY-MM-DD HH:mm:ss")
            v.onlineLongtime = dayjs().diff(v.lastonlinetime).valueOf()
            //v.onlineLongText =formatAgo(v.lastonlinetime)
            v.onlineLongText = formatTimeLong(v.lastonlinetime)
        } else {
            v.lastofflineDate = dayjs(v.lastofflinetime).format("YYYY-MM-DD HH:mm:ss")
            v.offlineLongtime = dayjs().diff(v.lastofflinetime).valueOf()
            //v.lastofflineText =formatAgo(v.lastofflinetime)
            v.lastofflineText =formatTimeLong(v.lastofflinetime)
        }

        return v
    })
    return res.data
}

export async function exportContainInfoList(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportContainInfo`,
            responseType:'blob',
            params
            
        },{
            prefix: "/analy",
            contentType: 'json',
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}