
/**
 * 检查用户是否有导航权限
 */
import { userStore } from '@/store/modules/user';
import { computed } from 'vue';
import { showMessage } from "@/utils/message"
import type { ComputedRef } from "vue"
interface CheckRole {
    roleShowPermission: ComputedRef<(roles: string) => boolean | void>
    roleHiddenPermission: ComputedRef<(roles: string) => boolean | void>
}
export default function checkRole(): CheckRole {
    const roleShowPermission = computed(() => {
        return function (roles: string) {
            if (!Array.isArray(roles)) return showMessage("请传入角色name数组")
            return roles.indexOf(userStore.getCurrentRole.name) > -1
        }
    })
    const roleHiddenPermission = computed(() => {
        return function (roles: string) {
            if (!Array.isArray(roles)) return showMessage("请传入角色name数组")
            return roles.indexOf(userStore.getCurrentRole.name) == -1
        }
    })
    return {
        roleShowPermission,
        roleHiddenPermission
    }
}

export function hasCurrentRole(roles: string[]){
    if (!Array.isArray(roles)) return showMessage("请传入角色name数组")
    return roles.indexOf(userStore.getCurrentRole.name!) > -1
}