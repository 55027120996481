
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  WechatMerchantTransfer,
  getWechatMerchantTransfer,
} from '@/api/Financial/wechatmerchanttransfer'

import { deepCopy } from '@/utils'
export default defineComponent({
  setup() {
    const loading = ref(false)
    const btnEnabledLoaidng = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
  
    const searchSelect = ref({
      search: 'reusername',
    })
    const mixin_router_search = ref({
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        reusername: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        reusername: '用户姓名',
      },
    })
   
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await getWechatMerchantTransfer(rqData)
        console.log('res.records :>> ', res.records)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
      mixinReRouterTableSortChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
    }
  },
})
