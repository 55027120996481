import { h, defineComponent, ref, toRef, onMounted, watch, nextTick, watchEffect } from 'vue';
import echarts from "./base"
import {
    LineChart
} from 'echarts/charts';
import { unwarp } from '@/utils';
echarts.use([LineChart])
export default defineComponent({
    name: "my-line",
    props: ["option"],
    setup(props: any) {
        const option = toRef(props, 'option')
        const lineRef = ref<any>("")
        const myChart = ref<any>('')
        const myRender = async (_option?: any) => {
            await nextTick()
            myChart.value.setOption && unwarp(myChart.value).setOption(_option || option.value)
        }
        onMounted(async () => {
            await nextTick()
            myChart.value = echarts.init(lineRef.value);
            watchEffect(async () => {
                myRender(option.value)
            })
        })

        const myResize = async () => {
            await nextTick()
            myChart.value.resize && myChart.value.resize()
        }
        return {
            lineRef,
            myRender,
            myResize
        }
    },
    render() {
        return h('div', {
            ref: 'lineRef',
            style: {
                width: "100%",
                height: "100%"
            }
        })
    }
})