<template>
  <el-dialog :destroy-on-close="true" :title="`完结维修单`" @closed="handleClose" align="center" class="dialog"
    v-model="visible" width="750px">
      <el-alert
        title="维修单完结后，小魔夹自动入库，需要出库在走仓库管理->出入库管理->维修出库"
        type="warning"
        show-icon>
      </el-alert>
    <el-form :model="inputData" label-width="120px" ref="formRef" :rules="rules">
      <el-form-item label="小魔夹SN" class="tip-text" prop="inEquipmentSn">
        {{row.inEquipmentSn}}
      </el-form-item>
      <el-form-item label="替换后SN" class="tip-text"  prop="outEquipmentSn">
        {{row.outEquipmentSn?row.outEquipmentSn:'未替换'}}
      </el-form-item>
      <el-form-item label="描述信息" prop="maintainDesc">
          <el-input type="textarea" placeholder="请输入内容" v-model="inputData.maintainDesc" :autosize="{ minRows: 3, maxRows: 5}">
      </el-input>
    </el-form-item>
    </el-form>
  
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { completeHttp, repairListModel } from '@/api/fault/repair'

import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, readonly } from 'vue'
import { userStore } from "@/store/modules/user";
import Rules from "@/common/Rules";
let overdueId = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {

    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<repairListModel>()
    const headerObj = ref<any>({})
    headerObj.value = { Authorization: userStore.info.token };

    const inputData = ref({
      equipmentSn: "",
      maintainDesc: "",
      
    })
    const rules = readonly({
      equipmentSn: [Rules.required()]
    });
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:repairListModel) => {
      visible.value = true
      overdueId=item.id;
      row.value=item;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true

        const time = dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const desc = userStore.userInfo.account +'在'+ time + '完结维修单'
        if(!inputData.value.maintainDesc){
          inputData.value.maintainDesc=desc;
        }
        const res = await completeHttp({
          id: overdueId,
          maintainDesc: inputData.value.maintainDesc,

        })

        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }


    return {
      rules,
      headerObj,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      row,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>