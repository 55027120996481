<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="`添加场地`"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-form :model="inputData" :rules="rules" label-width="120px" ref="fromRef">
      <el-form-item label="同步小魔柜信息">
        <el-input @keyup.enter="handelSearch" v-model="sn">
          <template #append>
            <el-button @click="handelSearch" icon="el-icon-search"></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="inputData.name"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input v-model="inputData.description"></el-input>
      </el-form-item>
      <el-form-item label="营业时间" prop="businesshours">
        <el-input v-model="inputData.businesshours"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="inputData.address"></el-input>
      </el-form-item>
      <el-form-item label="是否开启" prop="enabled">
        <el-switch
          active-color="#13ce66"
          inactive-color="#ff4949"
          v-model="inputData.enabled"
        ></el-switch>
      </el-form-item>
      <el-form-item label="经度" prop="longitude">
        <el-input v-model="inputData.longitude"></el-input>
      </el-form-item>
      <el-form-item label="纬度" prop="latitude">
        <el-input v-model="inputData.latitude"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contactname">
        <el-input v-model="inputData.contactname"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactmobile">
        <el-input v-model="inputData.contactmobile"></el-input>
      </el-form-item>
      <el-form-item label="区域码" prop="streetareacode">
        <el-input v-model="inputData.streetareacode"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, nextTick, readonly, ref } from "vue";
import { addPlace, upDatePlace } from "@/api/Share/Place";
import { loadOldCabinet } from "@/api/Share/Device";
import { ElMessage } from "element-plus";
import { userStore } from "@/store/modules/user";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "v-dialog",
  emits:['success'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const searchLoading = ref(false);
    const isEidt = ref(false);
    const sn = ref("");
    let index = -1;
    let placeid = "";
    const inputData = ref({
      name: "", // 名称
      description: "", // 描述
      businesshours: "00:00 ~ 24:00", // 店铺名
      address: "", // 地址
      enabled: true, // 是否开启
      latitude: "", // 纬度
      longitude: "", // 精度
      contactname: "", // 联系人
      contactmobile: "", // 联系电话
      //   provinceareacode: "", // 省
      //   cityareacode: "", // 城市码
      //   districtareacode: "", // 区
      streetareacode: "", // 街道
      editoruserid: userStore.info.userid, // 描述
      manageruserid:userStore.info.userid,//维修员id
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
      console.log("fromRef.value :>> ", fromRef.value);
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      isEidt.value = false;
      placeid = "";
    };
    const open = (_index: number, row: any) => {
      visible.value = true;
      if (_index > -1) {
        isEidt.value = true;
        index = _index;
      }
      const _inputData: any = {};
      Object.keys(inputData.value).map((v) => {
        if (v != "editoruserid") _inputData[v] = row[v];
      });
      placeid = row.placeid;
      inputData.value = Object.assign({}, inputData.value, _inputData);
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        const streetareacode = inputData.value.streetareacode;
        const rqData = {
          ...inputData.value,
          provinceareacode: streetareacode.substring(0, 2),
          cityareacode: streetareacode.substring(0, 4),
          districtareacode: streetareacode.substring(0, 6),
        };
        btnSumitLoading.value = true;
        let res: any;
        if (isEidt.value) {
          res = await upDatePlace({ ...rqData, placeid });
        } else {
          res = await addPlace(rqData);
        }
        handleClose();
        await nextTick();
        ElMessage.success(res.message);
        emit("success", {
          index,
          data: res.record,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const handelSearch = async () => {
      try {
        if(!sn.value) throw Error("请输入sn再搜索")
        searchLoading.value = true;
        const { address, nickname, latitude, longitude, streetareacode } =
          (await loadOldCabinet(sn.value)) || {};
        inputData.value = Object.assign({}, inputData.value, {
          address,
          name: nickname,
          latitude,
          longitude,
          streetareacode,
          contactname: "何双南", // 联系人
          contactmobile: "18182115772", // 联系电话
        });
      } finally {
        searchLoading.value = false;
      }
    };
    const rules = readonly({
      name: [Rules.required()], // 名称
      description: [Rules.required()], // 描述
      businesshours: [Rules.required()], // 店铺名
      address: [Rules.required()], // 地址
      latitude: [Rules.required()], // 纬度
      longitude: [Rules.required()], // 精度
      contactname: [Rules.required()], // 联系人
      contactmobile: [Rules.required()], // 联系电话
      streetareacode: [Rules.required()], // 街道
    });
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      sn,
      handelSearch,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>