
  import { defineComponent, ref, readonly, nextTick,inject } from 'vue'
  import routerChangeReData from '@/common/routerChangeReData'
  import { operationDirectorList } from '@/api/User/role'
  import addOperationDirectorDialog from './components/addOperationDirectorDialog.vue'
  import { useRouter } from "vue-router";
  import dayjs from 'dayjs'
  import { saveAs } from 'file-saver'
  export default defineComponent({
    name: 'Name',
    components: {
      'v-add-operation-director-dialog': addOperationDirectorDialog,
      
    },
    setup() {
      const router = useRouter();
      const toPath = (path: string) => {
        router.push(path);
      };
      const loading = ref(false)
      const limit = ref(10)
      const page = ref(1)
      const total = ref(0)
      const addDialogRef = ref<any>()
      const date = ref("");
      const list = ref<any>([])
      const couponRef = ref<any>("");
      const testWalletRechangeDialogRef = ref<any>()
      const exportDialogRef = ref<any>();
      const searchSelect = ref({ search: 'name' })
      const mixin_router_search = ref({ status: '',  careateBN: '', investortype:'',allowlatter:''})
      const mixin_select_search = ref({
        search: {
          name: '',
          account: '',
          userid: '',
        },
      })
      const mixin_select_search_label = readonly({
        search: {
          name: '姓名',
          account: '账号',
          userid: 'ID',
        },
      })
      const reData = async (rq?: any) => {
        await nextTick()
        try {
          loading.value = true
          let rqData: any = { current: page.value, size: limit.value }
          if (rq && JSON.stringify(rq) !== '{}') {
            rqData = { ...rqData, entity:{...rq }}
          }
          const res = await operationDirectorList(rqData)
          total.value = res.total
          list.value = res.records
        } finally {
          loading.value = false
        }
      }
      const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
      const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
        routerChangeReData({
          mixin_select_search,
          mixin_router_search,
          loading,
          limit,
          page,
          reData,
          searchSelect,
        })
      const handelChange = () => {
        page.value = 1
        mixinReRouter()
      }
      const handleActivate = () => {
        addDialogRef.value.open()
      }
      const handleRechange = (row: any) => {
        testWalletRechangeDialogRef.value.open(row)
      }
   
     
      return {
        mixin_select_search_label,
        mixin_select_search,
        mixin_router_search,
        searchSelect,
        list,
        loading,
        page,
        total,
        limit,
        date,
        handelChange,
        handleLimitChange,
        onPageChange,
        handleActivate,
        addDialogRef,
        changSearch,
        handleRechange,
        toPath_RouterSearchMixin,
        testWalletRechangeDialogRef,
        toPath,
        couponRef,
        exportDialogRef,
      }
    },
  })
  