<template>
  <el-dialog :destroy-on-close="true" :title="`设置押金扣款时间`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef" v-if="row">
      <!-- <el-form-item label="续期" prop="day">{{row.renewaltotaldays}}天，扣款{{row.renewalamount}}元</el-form-item> -->
      <!-- <el-form-item label="超期扣除金额" prop="day">
         <el-input placeholder="请输入扣款金额"  size="large" type="number" v-model="inputData.day"></el-input>
      </el-form-item> -->
      <el-form-item>
       <el-date-picker
        v-model="inputData.deductionTime"
        type="datetime"
        placeholder="选择日期时间"
        :default-time="deductionTime"
        >
      </el-date-picker>
      </el-form-item>  
      <el-form-item>
        <span style="color: red;">注意：设置押金扣除时间,会在基础上延时5分钟</span>
      </el-form-item>
     
    </el-form>
   
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import {
  SharedorderprivatizationdeductionListNew,
  sharedorderprivatizationreturnConfirmNew,
  sharedorderprivatizationprocessDeductionNew,
} from '@/api/Order/Privatization'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { userStore } from "@/store/modules/user";
let overdueId = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const deductionTime=ref("");
    const row = ref<SharedorderprivatizationdeductionListNew>()
    const inputData = ref({
      day: 0,
      deductionTime:'',
    })
    const handleClose = () => {
      visible.value = false
      inputData.value.deductionTime='';
      deductionTime.value='';
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: SharedorderprivatizationdeductionListNew) => {
      row.value=_row;
      overdueId=_row.id;
      visible.value = true
      deductionTime.value=_row.deductStartTime
     
      console.log('新的确认')
      console.log(deductionTime.value)
      // inputData.value.day = Math.ceil(
      //   dayjs().diff(_row.receivedate, 'day', true)
      // )
    }
    const submit = async () => {
      if (!row.value) return
      if(inputData.value.deductionTime==''||inputData.value.deductionTime==null){
          inputData.value.deductionTime=deductionTime.value;
        }
      try {
        // btnSumitLoading.value = true
        // const rentdays = inputData.value.day;
        // const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        // const desc=time+'确认收到小魔夹'
        // const res = await sharedorderprivatizationreturnConfirmNew({
        //   overdueId: overdueId,
        //   handleUserId:userStore.userInfo.userid,
        //   amount: rentdays * 3,
        //   remark:desc,
        //   excuteInfo:'',
        //   returnTime:time,
        //   returnType:'1'
        // })
        // ElMessage.success(res)
        // handleClose()
        // emit('success') 
       
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const deductionTime=dayjs(new Date().getTime()+5*60*1000).format("YYYY-MM-DD HH:mm:ss");
        inputData.value.deductionTime=dayjs(new Date(inputData.value.deductionTime).getTime()+5*60*1000).format("YYYY-MM-DD HH:mm:ss")
        const desc=time+'设置押金扣款时间为'+inputData.value.deductionTime
        console.log(inputData.value.deductionTime)
        const res = await sharedorderprivatizationprocessDeductionNew({
            overdueId:overdueId,
            handleUserId:userStore.userInfo.userid,
            remark:desc,
            excuteInfo:'',
            deductTime:inputData.value.deductionTime
          })
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      row,
      submit,
      inputData,
      deductionTime,
    }
  },
})
</script>
<style lang = "scss" scoped >
  .el-input{
    width: 160px;
  }
</style>