import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"

export interface DeviceReleaseModel {
    "supplyid":string, //发布id
    "articleequipmentsn":string,  //共享物品的设备序号，如：小魔夹SN;雨伞SN
    "productmodel":string, //产品型号  
    "fortest":boolean,     //是否测试
    "supplieruserid":string,   //供应者用户编号
    "supplieraccount":string,            //供应者用户账号
    "description":string,                       //描述
    "address":string,           //供应设备的详细地址
    "longitude":number, //设备位置坐标的经度，如：116.444082
    "latitude":number, //设备位置坐标的纬度，如：39.860513
    "withredenvelope":boolean, //是否包含红包，如：true 是 false 否
    "redenvelopeamount":number, //红包金额
    "releasing":boolean, //供应是否为发布状态，如：true 是 false 否
    "releasedate":string, //发布时间
    "canceled":boolean, //供应者是否进行过撤消操作，如：true 是 false 否
    "canceldate":string, //撤消时间，撤消后移除实时表数据，同时发布状态变成false
    "locked":boolean,  //供应是否被锁定，如：true 是 false 否
    "lockid":string, //供应锁定编码
    "lockeruserid":string,  //供应锁定者用户编号
    "lockeraccount":string, //供应锁定者用户账号
    "lockdate":string, //锁定时间
    "utilized":boolean, //供应是否被使用过，如：true 是 false 否
    "utilizedate":string, //使用时间，使用后移除实时表数据，同时发布状态变成false
    "utilizeruserid":string, //供应使用者用户编号
    "utilizeraccount":string, //供应使用者用户账号
    "sharedorderid":string, //使用该供应的共享订单编码
    "creationdate":string, //创建时间
    "modificationdate":string //更新时间
}

export async function getDeviceReleaseList(params: ListReq): Promise<ListRes<DeviceReleaseModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<DeviceReleaseModel>>(
        {
            url: `/sharedarticlesupplymemo/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.releasedate=dayjs(v.releasedate).format("YYYY-MM-DD HH:mm:ss");
        v.canceldate=dayjs(v.canceldate).format("YYYY-MM-DD HH:mm:ss");
        v.lockdate=dayjs(v.lockdate).format("YYYY-MM-DD HH:mm:ss");
        v.utilizedate=dayjs(v.utilizedate).format("YYYY-MM-DD HH:mm:ss");
        v.modificationdate=dayjs(v.modificationdate).format("YYYY-MM-DD HH:mm:ss");
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss");
        return v
    })
    return res
}