<template>
 <div class="container">
    <el-row :gutter="32">
      <el-col :lg="6" :sm="6" :xs="8">
        <v-card-box  :height="getHeight">
          <template #center>
            <div class="ball">
                <v-liquid-fill :chartData=chartData :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="6" :sm="6" :xs="8">
        <v-card-box  :height="getHeight">
          <template #center>
            <div class="ball">
                <v-liquid-fill :chartData=chartData :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="6" :sm="6" :xs="8">
        <v-card-box  :height="getHeight">
          <template #center>
            <div class="ball">
                <v-liquid-fill :chartData=chartData :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="6" :sm="6" :xs="8">
        <v-card-box  :height="getHeight">
          <template #center>
            <div class="ball">
                <v-liquid-fill :chartData=chartData :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="6" :sm="6" :xs="8">
        <v-card-box  :height="getHeight">
          <template #center>
            <div class="ball">
                <v-liquid-fill :chartData=chartData :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
      <el-col :lg="6" :sm="6" :xs="8">
        <v-card-box  :height="getHeight">
          <template #center>
            <div class="ball">
                <v-liquid-fill :chartData=chartData :pieHeight="pieHeight" key="cabinet" ref="cabinetRef"></v-liquid-fill>
            </div>
          </template>
        </v-card-box>
      </el-col>
    </el-row>
 </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted,nextTick,computed,toRef} from "vue";
import cardBox from './components/cardBox.vue'
import liquidFill from "./components/liquidFill/index.vue";
export default defineComponent({
    name: "Name",
    components:{
        "v-card-box": cardBox,
        "v-liquid-fill": liquidFill
    },
    setup(props) {
        const getHeight = computed(() => 900 / 2 - 2 + "px");
        const pieHeight = ref("240px")
        const chartData = ref<Object>({name: '助学金',percent: [2325.36]})
        return {
            getHeight,
            pieHeight,
            chartData
        }
    }
})
</script>
<style lang="scss" scoped>
.container {
    padding: 18px;
    width: 100%;
  .el-row {
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0;
    }
    .el-col {
      border-radius: 4px;
    }
  }
  .home-scroll {
    overflow-x: scroll;
    padding: 0.15rem 0 !important;
    &::-webkit-scrollbar {
      width: 0.026rem;
      height: 0.026rem;
      background-color: rgb(53, 73, 126);
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 0.013rem;
      background-color: #00c9c9;
      cursor: pointer;
      &:hover {
        background-color: #00f4f4;
      }
    }
  }
//   .ball {
//     height: 1.953rem;
//   }
  .urban-revenue,
  .city-partner-performance,
  .provincial-activity {
    display: flex;
    width: 1rem;
    padding: 0.2rem 0;
    margin: 0 auto;
    .item {
      flex: 1;
      font-size: 12px;
      display: flex;
      .left {
        width: 1em;
        color: #699dff;
        margin-right: 0.023rem;
      }
    }

    .item {
      margin-right: 0.072rem;
    }
  }
    
  }
</style>