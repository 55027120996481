
import { defineComponent, ref, computed } from 'vue'
import { onPrivateOrderReplaceDevice } from '@/api/Order/Privatization'
import Rules from '@/common/Rules'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from "@/store/modules/user";
import dayjs from 'dayjs'
let overdueId = '';
let oldSn='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const inputData = ref({
      remark: '超期补偿新小魔夹给投资人',
      newSN: '',
      excuteInfo:'',
      handleUserId:'',
      overdueId:'',
      replaceStatus:'99'
    })
    const geRules = computed(() => {
      return {
        remark: [Rules.required()],
        newSN: [Rules.required()],
      }
    })
    const visible = ref(false)

    const formRef = ref<any>('')
    const btnSumitLoading = ref(false)
    const title=ref('更换小魔夹')
    const handleClose = () => {
      visible.value = false
      overdueId = ''
      oldSn=''
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:any) => {
      visible.value = true
      oldSn=item.equipmentSN;
      title.value=item.equipmentSN+'更换新设备'
      overdueId = item.id;
      
    }
    const submit = async () => {
      try {
        await formRef.value.validate()
        await ElMessageBox.confirm(
          `请确认更换${oldSn}小魔夹为${inputData.value.newSN.trim().toUpperCase()}？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        btnSumitLoading.value = true
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        inputData.value.remark=time+inputData.value.remark;
        inputData.value.handleUserId=userStore.userInfo.userid!;
        inputData.value.overdueId=overdueId;
        inputData.value.newSN=inputData.value.newSN.trim().toUpperCase()
        const res = await onPrivateOrderReplaceDevice({
          ...inputData.value    
        })
        ElMessage.success(res)
        emit('success')
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
      title,
 
    }
  },
})
