<template>
  <el-dialog :title="`标题`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="form" :rules="rules" label-width="100px" ref="formRef">
      <el-form-item label="名称" prop="rolename">
        <el-input v-model="form.rolename"></el-input>
      </el-form-item>
      <el-form-item label="NAME" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, ref } from 'vue'
import { roleAdd } from '@/api/System/role'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const isEdit = ref(false)
    const formRef = ref()
    const btnSumitLoading = ref(false)
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const form = reactive({
      name: '',
      rolename: '',
    })
    const rules = {
      name: [Rules.required()],
      rolename: [Rules.required()],
    }
    const open = (row: any) => {
      visible.value = true
      if (row) {
        isEdit.value = true
        const { name, rolename } = row
        form.name = name
        form.rolename = rolename
      } else {
        form.name = ''
        form.rolename = ''
        isEdit.value = false
      }
    }

    const submit = async () => {
      try {
        await formRef.value.validate()
        btnSumitLoading.value = true
        if (isEdit.value) return ElMessage.error('不能改')
        const result = await roleAdd(form)
        ElMessage.success(result)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      rules,
      formRef,
      form,
      submit,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>