import { h, resolveComponent, ref } from 'vue';
import qr from "./index"
export const hoverShowQr = {
    name: "hover-show-qr",
    props: {
        text: {
            type: String,
            default: '',
            required: true
        },
        size: {
            type: Number,
            default: 150,
        },
        logo: {
            type: String,
            default: '',
            required: false
        },
        logoSize: {
            type: Number,
            default: 20,
        },
        margin: {
            type: Number,
            default: 10,
        },
        productid : {
            type: String,
            default: '',
            required: false
        }
    },
    setup(props: any) {
        const qrRef = ref<any>()
        return () => h(resolveComponent('el-popover') as string, {
            placement: "right",
            trigger: "hover",
            key: props.text,
            // width: props.size + props.margin * 2 + 20 + 'px',
            width: 'auto',
            ref: 'qrRef',
            hideAfter: 1000,
        }, {
            default: () => [h(qr, props)],
            // default: () => [h(resolveComponent('qr-code'), props)],
            reference: () => [h('span', {
                class: ['iconfont icon-qrcode'],
                onClick: (e: any) => {
                    console.log('e :>> ', e);
                    console.log('qrRef :>> ', qrRef);
                }
            })]
        })
    }
}