import { sharedarticleArticleNumAndRatio } from "@/api/Index/platfrom";
import { ref } from "vue"

export default function () {
    const cabinet = ref({
        data: {
            name: '空置率',
            percent: [0.1],
        },
        type: 1,
        title: '机柜',
        box1: {
            label: '小魔夹',
            value: 1,
        },
        box2: {
            label: '小魔柜',
            value: 1,
        },
    })

    const reCabinet = async ({ productmodel }: { productmodel?: string }) => {
        const res = await sharedarticleArticleNumAndRatio(productmodel);
        console.log('res :>> ', res);
        cabinet.value.data = {
            name: '空置率',
            percent: [parseFloat(res.ratio || 0) / 100],
        }
        cabinet.value.box1.value = res.articleSum;
        cabinet.value.box2.value = res.containerNum;
        console.log('cabinet :>> ', cabinet.value);
    };
    return {
        cabinet,
        reCabinet
    }
}