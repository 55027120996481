<template>
  <div class="container">
    <header>
      <!--搜索框-->
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select
            @change="changSearch('search')"
            placeholder="请选择"
            v-model="searchSelect.search"
          >
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select
        @change="handelChange"
        clearable
        placeholder="订单类型"
        v-model="mixin_router_search.fortest"
        class="header-el-selector"
      >
        <el-option :value="true" label="测试订单"></el-option>
        <el-option :value="false" label="正式订单"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="设备类型"
        v-model="mixin_router_search.articleproductmodel"
        class="header-el-selector"
      >
        <el-option label="小魔夹MS2" value="MS2"></el-option>
        <el-option label="小魔夹M4S" value="M4S"></el-option>
        <el-option label="雨伞" value="UM01"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="担保类型"
        v-model="mixin_router_search.model"
        class="header-el-selector"
      >
        <el-option label="余额" value="BALANCE"></el-option>
        <el-option label="微信" value="PAYSCORE"></el-option>
        <el-option label="芝麻" value="ZHIMA"></el-option>
      </el-select>
      <el-select
        @change="handelChange"
        clearable
        placeholder="私有化状态"
        v-model="mixin_router_search.status"
        class="header-el-selector"
      >
        <el-option
          :key="item"
          :label="allStatus[item]"
          :value="item"
          v-for="item of Object.keys(allStatus)"
        ></el-option>
      </el-select>
    </header>
    <el-table
      :data="list"
      :default-sort="{prop: 'receivedate', order: 'descending'}"
      @expand-change="handleOpen"
      @sort-change="mixinReRouterTableSortChange"
      height="80vh"
      row-key="sharedorderid"
      style="width: 100%"
      v-if="list"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="auto">
            <el-form-item label="订单号">
              <my-click-data-to
                :data="scope.row.sharedorderid"
                :params="[{search_sharedorderid:scope.row.sharedorderid}]"
                url="/order/userorder"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedorderid" />
            </el-form-item>
            <el-form-item label="沟通进程" v-if="scope.row.negotiationInfo">
              <negotiation-info :info="scope.row.negotiationInfo" :key="scope.row.sharedorderid"></negotiation-info>
            </el-form-item>
            <el-form-item
              label="归还记录"
              v-if="scope.row.returnInfo&&scope.row.negotiationInfo?.willreturn">
              <retrun-info :info="scope.row.returnInfo" :key="scope.row.sharedorderid"></retrun-info>
            </el-form-item>
            <el-form-item label="准备投放" v-if="scope.row.relaunched">{{scope.row.relaunchdate}}</el-form-item>
            <el-form-item
              label="开始投放"
              v-if="scope.row.launchcompleted"
            >{{scope.row.launchcompletedate}}</el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="left" label="租赁者" min-width="49">
        <template #default="scope">
          {{scope.row.account}}
          <my-copy :data="scope.row.account" />
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="45">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          <el-button
            @click="toPath_RouterSearchMixin('/share/shareDevice',[{search_articleequipmentsn:scope.row.articleequipmentsn}])"
            size="mini"
            type="text"
          >{{scope.row.articleequipmentsn}}</el-button>
          <my-copy :data="scope.row.articleequipmentsn" />
        </template>
      </el-table-column>

      <el-table-column
        :sort-orders="['ascending', 'descending']"
        align="center"
        label="租赁开始时间"
        min-width="60"
        prop="receivedate"
        sortable="custom"
      >
        <template #default="scope">
          <span class="el-icon-time g-color-info"></span>
          {{scope.row.receivedate}}
        </template>
      </el-table-column>
      <el-table-column align="left" label="计费天数" min-width="30">
        <template #default="scope">{{scope.row.rentdays||0}} 天</template>
      </el-table-column>
      <el-table-column align="left" label="已扣金额" min-width="30">
        <template #default="scope">¥ {{scope.row.deductionamount||0}}</template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="40">
        <template #default="scope">
          <el-tag :type="allStatusColor[scope.row.status]">{{allStatus[scope.row.status]}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="50">
        <template #default="scope">
          <el-button
            @click="handleNegotiation(scope.row.sharedorderid)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='00'"
          >开始沟通</el-button>
          <el-button
            @click="handleRetrun(scope.row)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='01'"
          >确认归还</el-button>
          <el-button
            :loading="reDeductionLoading"
            @click="handleReDeduction(scope.row.sharedorderid)"
            size="mini"
            type="warning"
            v-if="scope.row.status=='11'"
          >重新发起扣款</el-button>
          <el-button
            @click="handleRelaunch(scope.row.sharedorderid)"
            size="mini"
            type="primary"
            v-if="scope.row.status=='12'"
          >准备重新投放</el-button>
          <el-button
            @click="handleRelaunchSuccess(scope.row.sharedorderid)"
            size="mini"
            type="success"
            v-if="scope.row.status=='21'"
          >投放完成</el-button>
          <!-- <el-button v-if="scope.row.status!='99'" @click="onShowDrawer(scope.row)" type="primary" 
          style="margin-left: 16px;" size='mini'>私有化处理</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <negotiation-dialog @success="mixinReRouter" ref="negotiationDialogRef"></negotiation-dialog>
    <confrim-retrun-dialog @success="mixinReRouter" ref="confrimRetrunDialogRef"></confrim-retrun-dialog>
    <privatizationDrewer ref="privatizationDrewerRef"></privatizationDrewer>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import privatizationDrewer from './components/privatizationdrawer.vue'
import routerChangeReData from '@/common/routerChangeReData'
import negotiationInfo from './components/negotiationInfo.vue'
import retrunInfo from './components/retrunInfo.vue'
import confrimRetrunDialog from "./components/confrimRetrunDialog.vue"
import {
  sharedorderprivatizationdeductionList,
  SharedorderprivatizationdeductionList,
  sharedorderprivatizationnegotiationLoad,
  sharedorderprivatizationreturnConfirm,
  sharedorderprivatizationreturnLoad,
  sharedorderprivatizationprocessRelaunchStart,
  sharedorderprivatizationprocessLaunchComplete,
  sharedorderprivatizationprocessDeduction,
} from '@/api/Order/Privatization'
import negotiationDialog from './components/negotiationDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'orderManage',
  components: {
    negotiationDialog,
    negotiationInfo,
    retrunInfo,
    confrimRetrunDialog,
    privatizationDrewer,
  },
  setup() {
    const loading = ref(false)
    const reDeductionLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SharedorderprivatizationdeductionList[]>([])
    const negotiationDialogRef = ref<any>({})
    const confrimRetrunDialogRef = ref<any>({})
    const privatizationDrewerRef= ref<any>()
    const searchSelect = ref({ search: 'articleequipmentsn' })
    const allStatus = readonly({
      '00': '已启动',
      '01': '开始谈判',
      '02': '谈判完成',
      '11': '开始扣款',
      '12': '扣款完成',
      '21': '重新开始投放',
      '22': '投放完成',
      '31': '开始赔偿投资人',
      '32': '赔偿投资人完成',
      '99': '已完结',
    })
    const allStatusColor = readonly({
      '00': 'info',
      '01': 'info',
      '02': 'info',
      '11': 'danger',
      '12': 'waring',
      '21': 'primary',
      '22': 'success',
      '31': 'waring',
      '32': 'primary',
      '99': 'success',
    })
    const mixin_router_search = ref({
      //   fortest: false,
      order: 'receivedate',
      sort: 'desc',
      model: '',
      articleproductmodel: '',
      status: '',
    })
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: '',
        account: '',
        sharedorderid: '',
        userid: '',
      },
    })

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        account: '用户账号',
        sharedorderid: '订单号',
        userid: '用户ID',
      },
    })
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderprivatizationdeductionList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
     const onShowDrawer = (item:any) => {
      console.log('点击弹出');
      console.log(privatizationDrewerRef)
      privatizationDrewerRef.value.open(item);
    }

    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })

    /**
     * 参数筛选改变
     */
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }

    /**
     * 开启沟通按钮
     */
    const handleNegotiation = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }
    const handleNegotiationResult = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }

    /**
     * 是否展开获取订单详情
     * 加载私有化订单
     */
    const handleOpen = async (e: SharedorderprivatizationdeductionList) => {
      list.value[e.idx].open = !list.value[e.idx].open // 开启关闭状态
      if (!list.value[e.idx].open) return
      const status = parseInt(e.status)
      if (status > 0) {
        const _negotiationInfo = await sharedorderprivatizationnegotiationLoad(
          e.negotiationid
        )
        list.value[e.idx].negotiationInfo = _negotiationInfo
      }
      // if (status > 0) {
      //   const _returnInfo = await sharedorderprivatizationreturnLoad(
      //     e.negotiationid
      //   );
      //   list.value[e.idx].returnInfo = _returnInfo;
      // }
    }

    /**
     * 确认归还
     */
    const handleRetrun = async (row: SharedorderprivatizationdeductionList) => {
      confrimRetrunDialogRef.value.open(row)
    }

    /**
     * 开始投放
     */
    const handleRelaunch = async (sharedorderid: string) => {
      const res = await sharedorderprivatizationprocessRelaunchStart(
        sharedorderid
      )
      ElMessage.success(res)
      mixinReRouter()
    }

    /**
     * 投放完成
     */
    const handleRelaunchSuccess = async (sharedorderid: string) => {
      const res = await sharedorderprivatizationprocessLaunchComplete(
        sharedorderid
      )
      mixinReRouter()
      ElMessage.success(res)
    }

    /**
     * 开始扣款
     */
    const handleReDeduction = async (sharedorderid: string) => {
      try {
        reDeductionLoading.value = true
        const res = await sharedorderprivatizationprocessDeduction(
          sharedorderid
        )
        mixinReRouter()
        ElMessage.success(res)
      } finally {
        reDeductionLoading.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      allStatus,
      allStatusColor,
      handleNegotiation,
      negotiationDialogRef,
      mixinReRouter,
      handleNegotiationResult,
      handleOpen,
      handleRetrun,
      handleRelaunch,
      handleRelaunchSuccess,
      handleReDeduction,
      confrimRetrunDialogRef,
      privatizationDrewerRef,
      onShowDrawer,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width:120px ;
    margin-right: 12px;
    /deep/ .el-input{
      width: 120px;
    }
  }

}
</style>