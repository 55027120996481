
  import { defineComponent, ref } from "vue";
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { getComponentsList, ComponentsListRes } from "@/api/Component/Index";
  import {getContainInfoList,GetContainInfoList,removePanel} from '@/api/Share/Panel'
  import { getcrc, sendOnenNetMsg} from "@/api/Share/Panel";
  import { getSocketList, SocketListRes, removeSocket } from '@/api/Share/Socket'
  import { toOptional } from "@/types/utils";
  import editMsgDialog from "./editMsgDialog.vue";
  import deviceDialog from "@/components/device/cabinetDeviceDialog.vue" 
  import oneNetReportDialog from '@/components/device/oneNetReportDialog.vue'
  import oneNetSendDialog from '@/components/device/oneNetSendDialog.vue'
  import { userStore } from '@/store/modules/user'
  import dayjs from 'dayjs'
  let deviceId = "";
  let productId="";
  export default defineComponent({
    name: "v-dialog",
    components: {
      editMsgDialog,
      'v-device-dialog': deviceDialog,
      oneNetReportDialog,
      oneNetSendDialog,
    },
    setup() {
      const visible = ref(false);
      const loading = ref(false);
      const syncLoaidng = ref(false);
      const sendMsgLoading = ref(false);
      const btnSumitLoading = ref(false);
      const title = ref('机柜详情');
      const current = ref(1);
      const size = ref(10);
      const sn = ref("");
      const mac = ref('');
      const scoketTotal = ref(0)
      const scoketList = ref<SocketListRes[]>([])
      const list = ref<ComponentsListRes[]>([]);
      const maclist = ref<ComponentsListRes[]>([])
      const deviceInfo = ref<toOptional<GetContainInfoList>>({});
      const editDialogRef = ref<any>();
      const deviceDialogRef = ref<any>()
      const oneNetReportDialogRef = ref<any>()
      const oneNetSendDialogRef = ref<any>()
      const handleClose = () => {
        visible.value = false;
        //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
      };
      const reData = async () => {
        try {
          loading.value = true;
          const { records } = await getComponentsList({
            current: current.value,
            size: size.value,
            entity: {
              equipmentsn: sn.value,
            },
          });
          list.value = records;
          list.value.forEach(item => {
            if(item.type==='BLE'){
              mac.value=item.sign
            }
          });
        } finally {
          loading.value = false;
        }
      };
      const open = (_sn: string,item:any) => {
        console.log("出现弹窗=")
        visible.value = true;
        title.value=_sn+"机柜详情";
        sn.value = _sn;
        if(item !=null){
          deviceInfo.value=item;
          console.log('设备信息=',deviceInfo.value)
          deviceId=item.deviceid;
          productId=item.productid;
          console.log('产品id=',productId);
          console.log('设备id=',deviceId)
        }else{
          getDeviceSn();
        }
        getDeviceScoket();
        reData();
      };
      const onPageChange = (e: any) => {
        current.value = e.value;
        reData();
      };
      const handleSync = async (deviceid?: string) => {
        try {
          syncLoaidng.value = true;
          const res =  await getcrc({ deviceid });
          ElMessage.success(res);
        } finally {
          syncLoaidng.value = false;
        }
      };

      const getDeviceSn = async () => {
        try {
            
            let rqData: any = { currentPage: 1, size: 10,fortest:false,panelequipmentsn:sn.value }
            const res = await getContainInfoList(rqData)
            deviceInfo.value = res.list[0]
        } finally {
           
        }
      };

    const getDeviceScoket = async () => {
      try {
        loading.value = true
        let rqData: any = { current: 1, size: 20}
        let _entity:any = { panelequipmentsn:sn.value };
        if(userStore.getCurrentRole.rolename=='ROLE_OPERATOR'){
          _entity.operationId=userStore.getCurrentRole.operationId
        }
        rqData = {...rqData,entity:_entity}
        const res = await getSocketList(rqData)
        scoketTotal.value = res.total
        scoketList.value = res.records.map(v => {
          v.isSnEqual = v.socketstatus? v.articleequipmentsn === v.equipmentsn :false
          v.isEmpty = v.socketstatus==='0'
          v.isNormal = v.socketstatus==='1'
          return v
        })
      } finally {
        loading.value = false
      }
    }
      
      const handleEditMsg = async() => {
        editDialogRef.value.open();
      }
      
      const sendOneNetMsg = async(args:string ) =>{
        try{
          sendMsgLoading.value = true;
          const res =  await sendOnenNetMsg({ deviceid:deviceId,productId:productId,sn:sn.value,args:args });
          ElMessage.success(res);
        } finally {
          sendMsgLoading.value = false;
        }
      }

      const handleListDevice = async() =>{
        deviceDialogRef.value.open(sn.value,deviceInfo.value.productmodel)
      }

      const handleRefresh = async() => {
        getDeviceScoket()
      }
      /**
       * 机柜上报消息 
       */
      const handle4GUpdate = () =>{
        oneNetReportDialogRef.value.open(deviceId,sn.value)
      }    
      /**
       * 机柜下发消息
       */
      const handle4GSend = () =>{
        oneNetSendDialogRef.value.open(deviceId,sn.value)
      }
      /**
       * 弹出机柜仓位设备
       */
      const onItemClick = async (item:any) =>{
        console.log('点击单个弹窗',item)
        await ElMessageBox.confirm(
            `是否强制弹窗当前仓位设备，弹出请确保设备不丢失`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              distinguishCancelAndClose: true,
            }
          )
          let sendSn=null;
        
          if(item.articleequipmentsn =='无'){
            if(item.equipmentsn != '无'){
              sendSn=item.equipmentsn
            }
          }else{
            sendSn=item.articleequipmentsn
          }
         
          getDeviceMac(sendSn,item.socketsn)
      }
      const getDeviceMac = async (sendSn:string,socketsn:string) => {
        console.log('查询之前',sendSn)
        let sendmac="null";
        if(sendSn){
          try {
            let rqData: any = { current: 1, size: 10 ,entity: {equipmentsn: sendSn}}
            const res = await getComponentsList(rqData)
            maclist.value=res.records
            maclist.value.forEach(item => {
            if(item.type==='BLE'){
              sendmac=item.sign.toUpperCase()
            }
          });
          } finally {
            
          }
        }
        const time = dayjs(Date.now()).format('YYMMDDHHmmss')
        const args=`REMOTEOUT:${socketsn}|${sendmac}|d5|${time}|1 `;
        console.log('发送信息',args)

        const res =  await sendOnenNetMsg({ deviceid:deviceId,productId:productId,sn:sn.value,args:args });
        ElMessage.success(res);
    }
      return {
        visible,
        handleClose,
        open,
        list,
        scoketTotal,
        scoketList,
        btnSumitLoading,
        loading,
        current,
        size,
        onPageChange,
        handleSync,
        syncLoaidng,
        sendMsgLoading,
        title,
        mac,
        deviceInfo,
        editDialogRef,
        deviceDialogRef,
        oneNetReportDialogRef,
        oneNetSendDialogRef,
        handleEditMsg,
        sendOneNetMsg,
        handleListDevice,
        handleRefresh,
        handle4GUpdate,
        handle4GSend,
        onItemClick,
      };
    },
  });
  