<template>
  <div class="userInfo" v-if="userInfo">
    <div style="margin-right: 4px;" class="icon-item" v-if="roleShowPermission(['ROLE_FINANCE','ROLE_ADMIN_DVE','ROLE_CALLCENTER','ROLE_POSTSALE','ROLE_OPERATOR','ROLE_SALESMAN'])">
      <el-tooltip style="margin: 4px;" effect="light" content="积分商城" placement="bottom">
        <a :href="url.console+'/mall/login?token='+userInfo.token" > 
          <i class="el-icon-s-shop" style="font-size: 24px;" :class="isShow?'home-shop-color':'other-shop-color'" ></i>
        </a>
      </el-tooltip>
    </div>
    <div style="margin-right: 12px;" class="icon-item">
      <i class="el-icon-message" style="font-size: 20px;" :class="isShow?'home-msg-color':'other-msg-color'" ></i>
    </div>
    <el-dropdown @command="menuClick">
      <!-- <el-avatar size="small" :src="userInfo.avatarurl"></el-avatar> -->
      <el-avatar
        :loading="avaterIsLoaded"
        :src="userInfo.avatarurl"
        @error="avaterLoadingError()"
        class="home-avatar-el"
        size="small"
        v-if="userInfo.avatarurl"
      ></el-avatar>
      <span class="el-dropdown-link" v-else>
        <div @click="toPath('/home')" class="home-avatar" v-if="isShow">{{getFrist(userInfo.nickname)}}</div>
        <div @click="toPath('/home')" class="avatar" v-else>{{getFrist(userInfo.nickname)}}</div>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item disabled>{{userInfo.username}}</el-dropdown-item>
          <el-dropdown-item command="my">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown @command="RoleClick">
      <div class="role" v-if="isShow">{{userRole.rolename}}</div>
      <!-- <el-tag v-else>{{userRole.rolename}}</el-tag> -->
      <span class="el-dropdown-link" v-else>
        {{userRole.rolename}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item :command="item" :key="index" v-for="(item,index) of userRoles">{{item.rolename}}</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang="ts">
import { appStore } from '@/store/modules/app'
import { userStore } from '@/store/modules/user'
import {
  defineComponent,
  ref,  
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  toRef,
} from 'vue'
import { useRouter } from 'vue-router'
import { getUserInfo, changeRole } from '@/api/User/index'
import { roleStore } from '@/store/modules/role'
import type { UserRole } from '@/types/user'
import type { toOptional } from '@/types/utils'
import { routerStore } from '@/store/modules/router'
import { ElMessage } from 'element-plus'
import checkRole from '@/common/checkRole' //检查用户是否有侧边导航权限

export default defineComponent({
  name: 'v-layout-header',
  props: {
    isShow: Boolean,
    default:()=>false
  },
  setup(props) {
    const url = require ("@/config/url.ts")
    const avaterIsLoaded = ref(false)
    const router = useRouter()
    const isShow = toRef(props, 'isShow')
    let select: toOptional<UserRole> = reactive({})
    // const background = ref("#efefef");
    // const color = ref("#3ea243");
    const userInfo = computed(() => userStore.userInfo)
    const userRoles = computed(() => userStore.userInfo.roles)
    const userRole = computed(() => userStore.getCurrentRole)
    const getLoading = computed(() => appStore.getLoading)
    const { roleShowPermission } = checkRole()
    let fStr = ''
    const getFrist = computed({
      get: () => fStr.substr(0, 1).toUpperCase(),
      set: (val) => {
        fStr = val
      },
    })
    const internalInstance = getCurrentInstance()
    console.log('internalInstance :>> ', internalInstance)
    const getAvaterBg = computed(() => {
      return isShow.value
        ? {
            background: `url(${'../../assets/img/avaterbg.png'})`,
            color: '#ffffff',
          }
        : { background: '#efefef', color: '#3ea243' }
    })
    onMounted(() => {
      avaterIsLoaded.value = false
    })

    const menuClick = (com: string) => {
      switch (com) {
        case 'logout':
          userStore.loginOut()
          break
        case 'my':
          router.push('/my')
          break
      }
    }

    const avaterLoadingError = async (e: unknown) => {
      console.log('avaterLoadingError', e)
      if (avaterIsLoaded.value) return
      try {
        const { avatarurl } = await getUserInfo()
        userStore.upUserInfo({ avatarurl })
        avaterIsLoaded.value = true
      } catch (error) {
        console.log({ error })
        avaterIsLoaded.value = false
      }
    }
    const RoleClick = async (item: UserRole) => {
      console.log('item :>> ', item, select)
      if (select && select.roleid == item.roleid) return
      if (getLoading.value) return
      select = item
      try {
        appStore.toggleLoading(true)
        router.replace(`/home`)
        await changeRole(item.userroleid)
        roleStore.setUserRole(item)
        userStore.setCurrentRole(item)
        await routerStore.syncRouters()
        location.reload()
      } finally {
        appStore.toggleLoading(false)
      }
    }

    const logout = () => {
      userStore.loginOut()
    }
    const toPath = (url: string) => {
      router.push(url)
    }
    return {
      select,
      // background,
      // color,
      url,
      userInfo,
      userRole,
      userRoles,
      getLoading,
      getAvaterBg,
      avaterLoadingError,
      RoleClick,
      logout,
      toPath,
      menuClick,
      getFrist,
      avaterIsLoaded,
      fStr,
      roleShowPermission,
    }
  },
})
</script>

<style lang="scss" scoped>
.userInfo {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  .icon-item{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-avatar {
    width: 30px;
    height: 30px;
    background: url('../../assets/img/avaterbg.png');
    background-size: 100%;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #efefef;
    margin-right: 12px;
    cursor: pointer;
  }
  .home-avatar-el {
    margin-right: 12px;
    width: 30px;
    height: 30px;
  }
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(236, 245, 255);
    border: 1px solid rgb(217, 236, 255);
    color: rgb(64, 168, 255);
    cursor: pointer;
  }
  .role {
    color: #efefef;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    padding: 3px 6px;
    box-sizing: border-box;
    // font-weight: bold;
    background: url('../../assets/img/rolebg.png') no-repeat;
    background-size: 100% 100%;
  }
  .home-shop-color{
    color: whitesmoke;
  }
  .other-shop-color{
    color: #909399;
  }
  .home-msg-color{
    color: whitesmoke;
  }
  .other-msg-color{
    color: #909399;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 13px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>