
import Rules from '@/common/Rules'
import {addFault,updataFault} from '@/api/System/fault'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
let _id='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const title = ref()
    const isAdd = ref(true);
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      title: [Rules.required()],
      descriptiondetails: [Rules.required()],
      identificationId: [Rules.required()],
    }
    const form = ref({
      title: '',
      descriptiondetails: '',
      identificationId: '',
    })
    const open = (row: any) => {
      visible.value = true
      if(row){
        isAdd.value=false;
        _id=row.id;
        title.value='修改'
        form.value.title=row.title;
        form.value.descriptiondetails=row.descriptiondetails;
        form.value.identificationId=row.identificationId;
      }else{
        isAdd.value=true;
        title.value='新增'
      }
    }

    const submit = async () => {
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res=  isAdd.value?await addFault({...form.value}):await updataFault({id:_id,...form.value})
        if(res.code==200){
          ElMessage.success("修改成功")
        }else{
          ElMessage.success(res)
        }
        
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      title,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
    }
  },
})
