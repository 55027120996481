<!--  -->
<template>
    <v-pie :style="{width:'600px',height:'600px'}" :option="option" :events="events" ref="muiltPieRef" key="muiltPie"></v-pie>
</template>
<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, toRef } from "vue";
import pie from "@/components/echart/pie";
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => "180px",
    },
    chartData: {
      type: Array,
    },
  },
  components: {
    "v-pie": pie,
  },
  emits: ["change-pie-select"],
  setup(props, { emit }) {
    const chartData = toRef(props, "chartData");
    const option = computed(() => {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          data: [
            "直达",
            "营销广告",
            "搜索引擎",
            "邮件营销",
            "联盟广告",
            "视频广告",
            "百度",
            "谷歌",
            "必应",
            "其他",
          ],
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            selectedMode: "single",
            radius: [0, "30%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1548, name: "搜索引擎", selected: true },
              { value: 775, name: "直达" },
              { value: 679, name: "营销广告" },
            ],
          },
          {
            name: "访问来源",
            type: "pie",
            radius: ["45%", "60%"],
            labelLine: {
              length: 30,
            },
            label: {
              formatter: "{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,

              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [3, 4],
                  borderRadius: 4,
                },
              },
            },
            data: [
              { value: 1048, name: "百度" },
              { value: 335, name: "直达" },
              { value: 310, name: "邮件营销" },
              { value: 251, name: "谷歌" },
              { value: 234, name: "联盟广告" },
              { value: 147, name: "必应" },
              { value: 135, name: "视频广告" },
              { value: 102, name: "其他", selected: true },
            ],
          },
        ],
      };
    });
    const muiltPieRef = ref<any>(null);
    const reView = async () => {
      await nextTick();
      console.log("muiltPsieRef :>> ", muiltPieRef.value);
      muiltPieRef.value.myRender();
    };
    onMounted(()=>{
      reView()
    })

    const selectHandler = async (e: any) => {
      console.log("e :>> ", e);
    };
    const events = {
      selectchanged: selectHandler,
    };
    return {
      option,
      muiltPieRef,
      reView,
      selectHandler,
      events,
    };
  },
});
</script>
<style lang='scss' scoped>
.piewarp {
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 86, 116, 0.1);
  border-radius: 50%;
  position: relative;
}
</style>