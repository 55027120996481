
import { defineComponent, ref, readonly, inject } from 'vue'
import { totalProfitModel, totalProfitOfUserRoleHttp} from '@/api/profitStatistics/totalProfitUserRole'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getShareProductInfo, getUserRoleList} from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'in-out',
  components: {


  },
  setup() {
    const sheareProductInfo = getShareProductInfo()  
    const roleList = getUserRoleList();

    const mixin_router_search = ref({
      fortest: false, //是否测试
      gainerrole:'', //用户角色
    })

    const mixin_select_search = ref({
      search: {
        account:'',//用户账号    
        userid:'', //用户id
        
      },
    })
    
    const mixin_select_search_label = readonly({
      search: {
        account:'用户账号',    
        userid:'用户id',  
      },
    })

   

    const searchSelect = ref({
      search: 'account',
    })

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<totalProfitModel[]>([])

    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    
    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { currentPage: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, ...rq }
        }
        const res = await totalProfitOfUserRoleHttp(rqData)
        console.log('返回数据',res)
        list.value = res.data.records
        total.value = res.data.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange ,changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      changSearch,
      searchSelect,
      list,
      loading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      roleList,
    }
  },
})
