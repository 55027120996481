
import { defineComponent, nextTick, ref, toRef } from "vue";
import type { toOptional } from "@/types/utils";
import {
  updateRoleMenuList,
  treeLoadTree,
  roletreeList,
  roleList,
} from "@/api/System";
import type { UserRole } from "@/types/user";
import { netRotuer } from "@/router/types";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "Name",
  props: ["appid"],
  data() {
    return {
      defaultProps: {
        children: "child",
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const selectRole = ref<toOptional<UserRole>>({});
    const treeRef = ref<any>();
    const roles = ref<UserRole[]>([]);
    const roleMenuList = ref<netRotuer[]>([]);
    const allMenus = ref([]);
    const initAllMenus = ref([]);
    const appid = toRef(props, "appid");

    const getAllMenu = async () => {
      const data = await treeLoadTree({ appid: appid.value });
      if (data.length <= 0) return;
      allMenus.value = JSON.parse(JSON.stringify(data));
      // this.allMenus = this.editData(data);
      // console.log(this.allMenus);
      initAllMenus.value = JSON.parse(JSON.stringify(data));
      console.log("initAllMenus :>> ", initAllMenus);
    };
    const getAllRoles = async () => {
      const data = await roleList();
      if (data.length <= 0) return;
      roles.value = data;
    };
    const getRoleMenuList = async () => {
      if (!selectRole.value.roleid)
        return console.log("selectRole.value.roleid 不存在");
      let data = await roletreeList({
        roleid: selectRole.value.roleid,
        appid: appid.value,
      });
      if (data.length <= 0) return;
      data = data.filter((v) => {
        return v.parenttreeid != "0";
      });
      roleMenuList.value = data;
      console.log("treeRef.value :>> ", data);
      await nextTick();
      treeRef.value.setCheckedNodes(data);
    };
    const reData = async () => {
      try {
        loading.value = true;
        //获取所有菜单
        await getAllMenu();
        //请求角色列表
        await getAllRoles();
        //设置普通用户为默认
        selectRole.value = roles.value[4];
        // 获取指定全新的菜单列表
        await getRoleMenuList();
      } finally {
        loading.value = false;
      }
    };
    const open = async () => {
      visible.value = true;
      reData();
    };
    const close = () => {
      visible.value = false;
    };

    const editData = (data: any) => {
      return data.filter((v: any) => {
        if (v.child.length > 0) {
          editData(v.child);
        }
        return v.handleChecked === true;
      });
    };
    const submit = async () => {
      const rqList = [];
      btnSumitLoading.value = true;
      try {
        rqList.push(...treeRef.value.getHalfCheckedNodes());
        rqList.push(...treeRef.value.getCheckedNodes());
        const _rqList: any = [];
        rqList.map((v) => {
          if (_rqList.findIndex((i: any) => i == v.treeid) == -1) {
            // 去重
            _rqList.push(v.treeid);
          }
        });
        console.log('appid.value :>> ', appid.value);
        const result = await updateRoleMenuList({
          roleid: selectRole.value.roleid,
          name: selectRole.value.name,
          rolename: selectRole.value.rolename,
          treeArray: _rqList.join(","),
          consoleids: appid.value,
        });
        console.log("result :>> 3", result);
        ElMessage.success(result);
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeRole = async () => {
      // roleMenuList.value = {};
      allMenus.value = JSON.parse(JSON.stringify(initAllMenus.value));
      try {
        loading.value = true;
        await getRoleMenuList();
      } finally {
        loading.value = false;
      }
    };
    return {
      open,
      editData,
      visible,
      close,
      treeRef,
      submit,
      loading,
      btnSumitLoading,
      allMenus,
      changeRole,
      selectRole,
      roles,
    };
  },
});
