import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface whRecordModel {
     
    "gmtCreate": string, //创建时间
    "gmtModified": string, //更新时间
    "gmtOut": string, //出库时间
    "id": number, //
    "inUserAccount": string, //入库用户账号
    "inUserId": string, //入库用户ID
    "equipmentId":string, //设备id
    "equipmentSn": string, //设备sn
    "boxId": string,  //包装箱id
    "boxSn": string,    //包装箱sn
    "whOpType": number, //操作类型 0:生产 1：维修
    "adType": number,   //广告类型
    "outUserId": null,   //出库用户id
    "outUserAccount": string,  //出库用户账号
}

export async function whRecordListHttp(params: ListReq): Promise<ListRes<whRecordModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<whRecordModel>>(
        {
            url: `/whrecord/page`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {    
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        v.gmtOut = v.gmtOut?dayjs(v.gmtOut).format("YYYY-MM-DD HH:mm:ss"):"暂无";
        return v
    })
    return res
}


//包装箱入库
export async function inBoxHttp(boxsn : string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whrecord/takeboxin`,
            params: {
                token: true,
                boxsn
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res.msg || "包装箱入库成功"
}

//小魔夹入库
export async function inXMJHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whrecord/takein`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "小魔夹入库成功"
}

//维修设备出库
export async function outRepairXMJHttp(sn : string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whrecord/takeout`,
            params: {
                token: true,
                sn
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res.msg || "出库成功"
}

//更新广告类型
export async function changeXMJAdHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whrecord/updateadtype`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared"
        }
    );
    return res.msg || "更新成功"
}

export async function exportWhrecordInfo(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportWhrecordInfo`,
            responseType:'blob',
            params
            
        },{
            prefix: "/analy",
            contentType: 'json',
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}