<template>
    <div class="container">
    <header>
        <el-button @click="handelAdd()" icon="el-icon-plus" size="mini" type="primary">新增模板</el-button>
    </header>
  
      <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
        <el-table-column label="id" min-width="44" align="center">
          <template #default="scope">      
          {{scope.row.id}}           
          </template>
        </el-table-column>
        <el-table-column label="故障码" align="center" min-width="32">
          <template #default="scope">      
           {{scope.row.identificationId}}  
          </template>
        </el-table-column>
        <el-table-column label="故障原因" align="center" min-width="32">
          <template #default="scope">{{scope.row.title}}</template>
        </el-table-column>
        <el-table-column align="center" label="故障描述" min-width="90">
            <template #default="scope">{{scope.row.descriptiondetails}}</template>
        </el-table-column>
        <el-table-column align="center" label="更新时间" min-width="64">
            <template #default="scope">{{scope.row.updatetime}}</template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="52">
            <template #default="scope">
            <el-button
                @click="handelAdd(scope.row)"
                size="mini"
                type="primary"
                style="margin-left: 12px;"
            >修改</el-button>
            </template>       
        </el-table-column>
      </el-table>
      <addDialog ref="addDialogRef" @success="handelChange"></addDialog>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, readonly, onMounted } from 'vue'
  import { faultModel, getFaultList } from '@/api/System/fault'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import dayjs from 'dayjs'
  import addDialog from './components/addDialog.vue'
  export default defineComponent({
    name: 'fault',
    components: {
        addDialog
    },
    setup() {
      const loading = ref(false)
      const limit = ref(20)
      const page = ref(1)
      const total = ref(0)
      const list = ref<faultModel[]>([])
      const addDialogRef = ref<any>();
      const btnRemoveLoading = ref(false)
      onMounted(() => {
        reData()
      })
      const reData = async (rq?: any) => {
        try {
          loading.value = true
           let rqData: any = { current: page.value, size: limit.value }
  
          if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
            }
            
            rqData = { ...rqData, entity: rq }
          }
          const res = await getFaultList(rqData)
          console.log('查询的数据=',res)
          list.value = res.rows
          total.value = res.total
        } finally {
          loading.value = false
        }
      }
   
      const handelChange = () => {
        reData();
      }
      
      const handelAdd = (item?:any) => {
        addDialogRef.value.open(item)
      }

      
      return {
        handelChange,
        list,
        loading,
        btnRemoveLoading,
        page,
        limit,
        total,   
        handelAdd,
        addDialogRef,
      }
    },
  })
  </script>
  <style lang="scss" scoped>
  .container {
    padding: 18px;
    header {
      display: flex;
      flex-wrap: wrap;
      .header-select {
        width: 130px;
        margin: 0 24px 24px 0;
        ~ * {
          margin: 0 24px 24px 0;
        }
       /deep/.el-input {
          width: 130px;
         
        }
         .el-select {
          width: 130px;
          /deep/.el-input{
            width: 130px;
          }
        }
      }
      .content {
        padding: 0;
      }
      .input-with-select{
        width: 320px;
        margin-right: 16px;
        
        /deep/.el-input{
          width: 120px;
        }
      }
      .input-select-tip{
          width: 130px;
      }
    }
    .no-more {
      color: #aaa;
    }
    .el-table {
      .el-button {
        margin: 0.04rem;
      }
    }
  
    .header-el-selector {
      width: 120px;
      margin-right: 12px;
       /deep/.el-input {
        width: 120px;
      }
    }
    .span-click {
        color: #409eff;
        cursor:pointer;
    }
  }
  </style>