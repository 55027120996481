import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface WhboxModel {
    "curCount": number, //设备数量   
    "gmtCreate": string, //创建时间
    "gmtIn": string, //入库时间
    "gmtModified": string, //更新时间
    "gmtOut": string, //出库时间
    "id": number, //
    "inUserAccount": string, //入库用户账号
    "inUserId": string, //入库用户ID
    "maxCount": number, //箱子最大承载
    "productId": string, //产品类型ID
    "shipmentId": string, //出货单ID
    "sn": string,  //编码
    "status":number, //状态 0:待入库 1：已入库 2：已出库
}

export interface boxDeviceModel {
    "adType": number,
    "equipmentId": string,
    "equipmentSn": string,
    "gmtCreate": string,
    "prefix": string,
    "productId": string,
}

export async function whboxList(params: ListReq): Promise<ListRes<WhboxModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<WhboxModel>>(
        {
            url: `/whbox/pagevo`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {    
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        v.gmtIn =v.gmtIn?dayjs(v.gmtIn).format("YYYY-MM-DD HH:mm:ss"):"暂无";
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        v.gmtOut = v.gmtOut?dayjs(v.gmtOut).format("YYYY-MM-DD HH:mm:ss"):"暂无";
        return v
    })
    return res
}

//新增包装箱
export async function addBoxHttp(count: number): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whbox/create`,
            params: {
                token: true,
                count
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res.msg || "添加成功"
}

//包装箱入库
export async function takeBoxInHttp(boxsn : string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whrecord/takeboxin`,
            params: {
                token: true,
                boxsn 
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res.msg || "添加成功"
}

/**
 * 查询包装箱内的设备(根据SN)
 */
export async function getBoxDeviceOnSnHttp(sn: string): Promise<ListResLoad<boxDeviceModel>> {
    const res = await defHttp.request<ListResLoad<boxDeviceModel>>(
        {
            url: `/whbox/getequipmentbysn`,
            method: "GET",
            params: {
                token: true,
                sn 
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );

    res.data = res.data.map(v => {    
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        return v
    })
    return res ;
}

//小魔夹装入包装箱
export async function addDeviceToBoxHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whbox/adddevice`,
            params: {
                token: true,
                ...parmas 
            },
        }, 
        {
            prefix: "/shared",
        }
    );
    return res.msg || "添加成功"
}

//小魔夹移除包装箱
export async function removeDeviceToBoxHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/whbox/removedevice`,
            params: {
                token: true,
                ...parmas 
            },
        }, 
        {
            prefix: "/shared",
        }
    );
    return res.msg || "移除成功"
}

