
import { defineComponent, ref, toRef } from "vue";
import { activateRole } from "@/api/User/role";
import Rules from "@/common/Rules";
import { ElMessage } from "element-plus";
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: "v-dialog",
  props: ["type"],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const type = toRef(props, "type");
    const inputRef = ref<any>("");
    const inputData = ref({
      account: "",
      name: "",
    });
    const handleClose = () => {
      visible.value = false;
      inputRef.value.resetFields();
      inputRef.value.clearValidate();
    };
    const open = () => {
      visible.value = true;
    };
    const submit = async () => {
      try {
        try {
          await inputRef.value.validate();
        } catch (error) {
          console.log(error);
          throw Error("请检查输入");
        }
        btnSumitLoading.value = true;
        const _data: any = {};
        _data[`${type.value}account`] = inputData.value.account.trim();
        _data[`${type.value}name`] = inputData.value.name.trim();
        if(type.value=='repairman'){
           _data.operationid = userStore.getCurrentRole.operationId
        }
        const result = await activateRole(_data, type.value);
        ElMessage.success(result);
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      inputRef,
    };
  },
});
