<template>
  <el-dialog
    :title="title"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-form :model="inputData" :rules="rules" label-width="120px" ref="fromRef">
      <el-form-item label="设备SN" prop="articleequipmentsn" >
        <el-input v-model="inputData.articleequipmentsn"></el-input>
      </el-form-item>    
      <el-form-item label="是否有故障" prop="fault">
        <el-select placeholder="请选择" v-model="inputData.fault">
          <el-option label="有故障" value=true></el-option>
          <el-option label="无故障" value=false></el-option>
        </el-select>
      </el-form-item>     
      <el-form-item label="是否有共享设备" prop="article">
        <el-select placeholder="请选择" v-model="inputData.article">
          <el-option label="有共享设备" value=true></el-option>
          <el-option label="无共享设备" value=false></el-option>
        </el-select>
      </el-form-item>     
      <el-form-item label="仓位口状态" prop="socketstatus">
        <el-select placeholder="请选择" v-model="inputData.socketstatus">
          <el-option label="正常有设备" value="1"></el-option>
          <el-option label="正常无设备" value="0"></el-option>
        </el-select>
      </el-form-item>       
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, nextTick, readonly, ref } from "vue";
import { placeBindpanel, upDatePanel } from "@/api/Share/Panel";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "v-dialog",
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const isEidt = ref(false);
    const title=ref('更新仓位口')
    let index = -1;
    const inputData = ref({
      panelequipmentsn: "", // 机柜SN
      socketsn:"", //仓位口号
      article:'true', //是否有共享物品
      articleequipmentsn:"",//仓位设备SN
      fault:'false', //是否有故障
      equipmentsn:"",//设备序号
      socketstatus:'1'//仓位口状态
    });
    const rules = readonly({
      panelequipmentsn: [Rules.required()]
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
      console.log("fromRef.value :>> ", fromRef.value);
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      isEidt.value = false;
    };
    const open = (_index: number, row?: any) => {
      visible.value = true;
      title.value='更新机柜'+row.panelequipmentsn+"的"+row.socketsn+"口信息"
      inputData.value.socketsn=row.socketsn;
      inputData.value.panelequipmentsn=row.panelequipmentsn;
      if (_index > -1) {
        index = _index;
      }

      // const _inputData: any = {};
      // Object.keys(inputData.value).map((v) => {
      //   _inputData[v] = row[v];
      // });
      // inputData.value = Object.assign({}, inputData.value, _inputData);
    };
    const submit = async () => {
      try {
        inputData.value.equipmentsn=inputData.value.articleequipmentsn;
        console.log(inputData.value)
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        let res: any;
        //if (isEidt.value) {
          res = await upDatePanel(inputData.value);
        // } else {
        //   res = await placeBindpanel(inputData.value);
        // }
        handleClose();
        await nextTick();
        ElMessage.success(res.message);
        emit("success", {
          index,
          data: res.record,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      title,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      isEidt,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>