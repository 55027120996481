import { defHttp } from "@/utils/http/axios";
import { ListRes, ListReq,RequestRes} from "../../types/requestBaseType"
import dayjs from "dayjs"

export interface UsuserfundfrozenList {
    "frozenid": string,
    "userid": string,
    "wallettype": "MJ_TEST" | "MJ" | "YS" | "YS_TEST",
    "amount": number,
    "reason": "sharedorder" | string,
    "sign": string,
    "frozendate": string,
    "creationdate": string
}
/**
 * 获取冻结列表
 */
export async function userfundfrozenList(params: ListReq): Promise<ListRes<UsuserfundfrozenList>> {
    params.token = true
    const res = await defHttp.request<ListRes<UsuserfundfrozenList>>(
        {
            url: `/userfundfrozen/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}

/**
 * 删除冻结订单
 */
 export async function removefundfrozen(frozenid: string): Promise<string | undefined> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/userfundfrozen/remove`,
            params: {
                token: true,
                frozenid
            },
        }, {
        prefix: "/shared",
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message
}