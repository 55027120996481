
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  getShareDeviceList,
  getAnalyShareDeviceList,
  DeviceListRes,
  holdertype,
  exportAnalyShareDeviceList,
} from "@/api/Share/Device";
import addDialog from "./components/addDialog.vue";
import componentsDialog from "./components/componentsDialog.vue";
import showUserInfoDialog from "./components/showUserInfoDialog.vue"
import exportDialog from "@/components/export/exportDialog.vue";
export default defineComponent({
  name: "Name",
  components: {
    "v-add-dialog": addDialog,
    "v-components-dialog": componentsDialog,
    "v-show-user-info-dialog":showUserInfoDialog,
    exportDialog,
  },
  setup() {
    const loading = ref(false);
    const btnRemoveLoaidng = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const allHoldertype = holdertype;
    const list = ref<DeviceListRes[]>([]);
    const exportDialogRef = ref<any>();
    const addDialogRef = ref<any>();
    const showUserInfoDialigRef = ref<any>();
    const componentsDialogRef = ref<any>();
    const searchSelect = ref({
      search: "articleequipmentsn",
    });
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: "",
        holderpanelequipmentsn: "",
        investmentorderid: "",
        holderuserid: "",
        investoruserid: "",
      },
    });

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: "设备SN",
        holderpanelequipmentsn: "机柜SN",
        investmentorderid: "订单号",
        holderuserid: "用户ID",
        investoruserid: "投资人ID",
      },
    });

    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");

    const mixin_router_search = ref({
      socketstatus: "",
      productname: "",
      privatized: "",
      lockcmd: "",
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, entity: rq };
        }
        const res = await getAnalyShareDeviceList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const checkUser = (row:any)=>{
      showUserInfoDialigRef.value.open(row)
    };
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row);
    };
    const addSuccess = (data: { index: number; data: DeviceListRes }) => {
      if (typeof data.index == "number" && data.index > -1) {
        list.value[data.index] = data.data;
      } else {
        list.value.push(data.data);
      }
    };

    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn);
    };

    const handleExport = async() => {
      exportDialogRef.value.open('共享设备表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'shareDevice'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
       
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportAnalyShareDeviceList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      checkUser,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleEidt,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      componentsDialogRef,
      showUserInfoDialigRef,
      handleShowComponenet,
      toPath_RouterSearchMixin,
      changSearch,
      allHoldertype,
      handleExport,
      exportDialogRef,
      exportFile,
    };
  },
});
