
import { replaceSnHttp, repairListModel } from '@/api/fault/repair'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, readonly } from 'vue'
import { userStore } from "@/store/modules/user";
import Rules from "@/common/Rules";
let overdueId = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {

    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<repairListModel>()
    const headerObj = ref<any>({})
    headerObj.value = { Authorization: userStore.info.token };

    const inputData = ref({
      newSn: "",
      newEquipment:false,
      replaceReason:"1"
    })
    const rules = readonly({
      newSn: [Rules.required()]
    });
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (item:repairListModel) => {
      visible.value = true
      overdueId=item.id;
      row.value=item;
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        const res = await replaceSnHttp({
          id: overdueId,
          newEquipment: inputData.value.newEquipment,
          newSn:inputData.value.newSn,
          replaceReason:inputData.value.replaceReason
        })

        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }


    return {
      rules,
      headerObj,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      row,
    }
  },
})
