<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select
        @change="handelChange"
        socketholder="请选择"
        class="header-el-selector"
        v-model="mixin_router_search.socketstatus"
      >
        <el-option label="正常无设备" value="0"></el-option>
        <el-option label="正常有设备" value="1"></el-option>
      </el-select>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left: 24px;">添加</el-button>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="机柜SN" min-width="35" prop="panelequipmentsn"></el-table-column>
      <el-table-column label="设备SN" min-width="35" prop="articleequipmentsn"></el-table-column>
      <el-table-column label="仓位设备SN" min-width="35" prop="equipmentsn"></el-table-column>
      <el-table-column label="仓口号" min-width="25" prop="socketsn"></el-table-column>
      <el-table-column label="电量" min-width="30"   align="center">
       <template  #default="scope">
          {{scope.row.batterypower}}%
       </template>
     </el-table-column>
      <el-table-column label="休眠时长" min-width="35" prop="sleeptime" align="center"></el-table-column>
      <el-table-column label="有共享设备" min-width="35"   align="center">
       <template  #default="scope">
          <el-tag type="success"  v-if="scope.row.article">有</el-tag>
          <el-tag type="waring"  v-else>无</el-tag>
       </template>
     </el-table-column>
    <el-table-column label="设备故障" min-width="35"   align="center">
       <template  #default="scope">
          <el-tag type="danger"  v-if="scope.row.fault">故障</el-tag>
          <el-tag type="success"  v-else>无故障</el-tag>
       </template>
     </el-table-column>
     <el-table-column label="设备状态" min-width="35"   align="center">
       <template  #default="scope">
          <el-tag type="danger"  v-if="scope.row.faultcode=='privatization'">私有化</el-tag>
          <el-tag type="waring"  v-if="scope.row.faultcode=='unshared'">非共享</el-tag>
          <el-tag type="waring"  v-if="scope.row.faultcode=='invalid'">无效设备</el-tag>
          <el-tag type="info"   effect="dark" v-if="scope.row.faultcode=='breakdown'">故障设备</el-tag>
          <el-tag type="waring"  v-else>{{scope.row.article?'正常':'无设备'}}</el-tag>
       </template>
     </el-table-column>
      <el-table-column label="仓口状态" min-width="60" prop="sleeptime" align="left">
        <template #default="scope">
          <el-tag type="info" v-if="scope.row.socketstatus=='0'">正常无设备</el-tag>
          <div v-else-if="scope.row.socketstatus=='1'">
              <el-tag type="info"  effect="dark"  v-if="scope.row.articleequipmentsn == '空'">私有化设备</el-tag>
              <el-tag type="info"  effect="dark"  v-if="scope.row.fault">小魔夹故障</el-tag>
              <el-tag type="success"  v-else>正常有设备</el-tag>
          </div>
          <!-- <el-tag type="success" v-else-if="scope.row.socketstatus=='1'">正常有设备</el-tag> -->
          <el-tooltip v-else-if="scope.row.socketstatus=='2'" effect="light" content="可通信,充电不正常" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='3'" effect="light" content="识别机仓内有东西，但是无法通讯和充电" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='4'" effect="light" content="读取小魔夹信息超时错误" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='5'" effect="light" content="收到指令成功解锁设备但是驱动电机不能使小魔夹退出" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='6'" effect="light" content="发出解锁数据后连续查询设备5次不能解锁设备" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
           <el-tooltip v-else-if="scope.row.socketstatus=='7'" effect="light" content="读取MCU忙" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
           <el-tooltip v-else effect="light" content="未知故障" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.$index,scope.row.panelequipmentsn)"
            size="mini"
            type="danger"
          >删除</el-button>
          <el-button @click="handleEidt(scope.$index,scope.row)" size="mini">编辑</el-button>
          <el-button @click="handleUpdata(scope.$index,scope.row)" size="mini" type="primary">更新</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
  <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
  <v-up-data-dialog @success="addSuccess" ref="upDataDialogRef"></v-up-data-dialog>
</template>


<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { getSocketList, SocketListRes, removeSocket } from '@/api/Share/Socket'
import addDialog from './components/addDialog.vue'
import upDataDialog from './components/upDataDialog.vue'
import { ElMessage } from 'element-plus'
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-dialog': addDialog,
    'v-up-data-dialog':upDataDialog,
  },
  setup() {
    const loading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SocketListRes[]>([])
    const addDialogRef = ref<any>()
    const upDataDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '', //共享面板的设备序号，如：小魔柜SN；雨伞架SN
        articleequipmentsn: '', //共享物品的设备序号
        socketsn: '', //插口序号，如：小魔柜 01~12 雨伞架 01~09
        manageruserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: '机柜SN',
        articleequipmentsn: '设备SN',
        socketsn: '仓位口号',
        manageruserid: '维修员ID',
      },
    })

    const mixin_router_search = ref({ socketstatus: '' })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if(userStore.getCurrentRole.rolename=='ROLE_OPERATOR'){
          rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await getSocketList(rqData)
        total.value = res.total
        list.value = res.records
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }

    const handleUpdata = (index: number,row:any) =>{
      upDataDialogRef.value.open(index, row)
    }

    const addSuccess = (data: { index: number; data: SocketListRes }) => {
      if (typeof data.index == 'number' && data.index > -1) {
        list.value[data.index] = data.data
      } else {
        list.value.push(data.data)
      }
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removeSocket({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleUpdata,
      handleRemove,
      addSuccess,
      changSearch,
      addDialogRef,
      upDataDialogRef,
      btnRemoveLoaidng,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
    width:120px ;
    margin-right: 12px;
    /deep/.el-input{
      width: 120px;
    }
  }
}
</style>