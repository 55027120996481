import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq, ListResLoad} from "@/types/requestBaseType"
import dayjs from "dayjs"

export interface markModel {
    
    "address": string, //小魔夹SN  
    "longitude": number, //收益
    "latitude": number, //剩余折现天数
    "number": number,
    "name":string
    
}

/**
 * 
 * @param params 查询投资人的收益账单
 * @returns 
 */
export async function getMarkListHttp(params: ListReq): Promise<ListRes<markModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<markModel>>(
        {
            url: `/operateController/getCoordinateInfo`,
            params
        }, 
        {
            prefix: "/analy",
            contentType:'json'
        }
    );
    return res
}
