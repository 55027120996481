import { hotModuleUnregisterModule } from "@/utils/helper/vuexHelper"
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { RouteLocationMatched } from 'vue-router'
import type { toOptional } from "@/types/utils"
import store from "@/store"
import { AppRouteRecordRawChildren, RouteMeta } from "@/router/types";
import { routerStore } from "./router";
const NAME = 'TagsView';
hotModuleUnregisterModule(NAME);
export type TagsViewType = {
  path: string,
  meta: RouteMeta,
  name?: string,
}
@Module({ namespaced: true, name: NAME, dynamic: true, store })
class TagsView extends VuexModule {
  tagsView: TagsViewType[] = []

  
  public get getTagsView() {
    return this.tagsView
  }
  

  @Mutation
  addTages(view: TagsViewType) {
    const index = TagsViewStore.tagsView.findIndex((v) => v.path == view.path);
    if (index > -1) return;
    this.tagsView.push(view)
  }
  @Mutation
  initTages(view?: TagsViewType) {
    const { name, path, meta } = routerStore.getRouters.find(v => v.path == '/home') as AppRouteRecordRawChildren
    this.tagsView = [{
      name, path, meta
    }]
    if (view) this.tagsView.push(view)
  }
  @Mutation
  upTages(view: TagsViewType) {
    const index = this.tagsView.findIndex(v => v.path == view.path)
    if (index > -1) {
      this.tagsView.splice(index, 1, view)
    }
  }
  @Mutation
  delTage(index: number) {
    this.tagsView.splice(index, 1)
  }

}
export const TagsViewStore = getModule<TagsView>(TagsView);