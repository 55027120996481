
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { getSocketList, SocketListRes, removeSocket } from '@/api/Share/Socket'
import addDialog from './components/addDialog.vue'
import upDataDialog from './components/upDataDialog.vue'
import { ElMessage } from 'element-plus'
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-dialog': addDialog,
    'v-up-data-dialog':upDataDialog,
  },
  setup() {
    const loading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SocketListRes[]>([])
    const addDialogRef = ref<any>()
    const upDataDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '', //共享面板的设备序号，如：小魔柜SN；雨伞架SN
        articleequipmentsn: '', //共享物品的设备序号
        socketsn: '', //插口序号，如：小魔柜 01~12 雨伞架 01~09
        manageruserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: '机柜SN',
        articleequipmentsn: '设备SN',
        socketsn: '仓位口号',
        manageruserid: '维修员ID',
      },
    })

    const mixin_router_search = ref({ socketstatus: '' })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if(userStore.getCurrentRole.rolename=='ROLE_OPERATOR'){
          rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await getSocketList(rqData)
        total.value = res.total
        list.value = res.records
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {})
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }

    const handleUpdata = (index: number,row:any) =>{
      upDataDialogRef.value.open(index, row)
    }

    const addSuccess = (data: { index: number; data: SocketListRes }) => {
      if (typeof data.index == 'number' && data.index > -1) {
        list.value[data.index] = data.data
      } else {
        list.value.push(data.data)
      }
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removeSocket({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleUpdata,
      handleRemove,
      addSuccess,
      changSearch,
      addDialogRef,
      upDataDialogRef,
      btnRemoveLoaidng,
    }
  },
})
