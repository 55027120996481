
import { defineComponent, ref, computed } from "vue";
import { sharedorderprivatizationprocessNegotiation } from "@/api/Order/Privatization";
import Rules from "@/common/Rules";
import { deepCopy } from "@/utils";
import { ElMessage } from "element-plus";
import { userStore } from "@/store/modules/user";
let sharedorderid = "";
export default defineComponent({
  name: "v-dialog",
  emits: ["success"],
  setup(props, { emit }) {
    const inputData = ref({
      description: "愿意归还",
      willreturn: true,
      paymentamount: 199,
      returntype: "cabinet",
      expressbrand: "",
      expressnum: "",
    });
    const getInputData = computed(() => {
      const _inputData = deepCopy(inputData.value);
      console.log("_inputData :>> ", _inputData);
      if (!inputData.value.willreturn) {
        delete _inputData.returntype;
        delete _inputData.expressbrand;
        delete _inputData.expressnum;
      } else {
        delete _inputData.paymentamount;
      }
      if (inputData.value.returntype == "cabinet") {
        delete _inputData.expressbrand;
        delete _inputData.expressnum;
      }
      return _inputData;
    });
    const geRules = computed(() => {
      const _rules: any = {};
      Object.keys(getInputData.value).forEach(
        (v) => (_rules[v] = [Rules.required()])
      );
      return _rules;
    });
    const visible = ref(false);
    const formRef = ref<any>("");
    const btnSumitLoading = ref(false);
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };
    const open = (id: string) => {
      visible.value = true;
      sharedorderid = id;
    };
    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        const res = await sharedorderprivatizationprocessNegotiation({
          ...getInputData.value,
          negotiatoruserid: userStore.userInfo.userid,
          sharedorderid,
        });
        ElMessage.success(res);
        emit("success");
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
    };
  },
});
