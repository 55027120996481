<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" v-model="mixin_router_search.productmodel">
        <el-option label="4G-2小魔柜" value="MGS4G2"></el-option>
        <el-option label="4G小魔柜" value="MGS4G"></el-option>
        <el-option label="雨伞架" value="UMHD"></el-option>
        <el-option label="便民柜" value="CV01"></el-option>
      </el-select>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary">添加</el-button>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="场所ID" v-if="scope.row.sharedpanel.placeid">
              <my-click-data-to
                :data="scope.row.sharedpanel.placeid"
                :params="[{search_placeid:scope.row.sharedpanel.placeid}]"
                url="/share/place"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedpanel.placeid" />
            </el-form-item>
            <el-form-item label="产品ID" v-if="scope.row.product">
              <my-click-data-to
                :data="scope.row.product.productid"
                :params="[{search_productid:scope.row.product.productid}]"
                url="/share/product"
              ></my-click-data-to>
              <my-copy :data="scope.row.product.productid" />
            </el-form-item>
            <el-form-item label="mfrid" v-if="scope.row.product">
              {{scope.row.product.mfrid}}
              <my-copy :data="scope.row.product.mfrid" />
            </el-form-item>
            <el-form-item label="场所ID" v-if="scope.row.productid">
              <my-click-data-to
                :data="scope.row.sharedpanel.placeid"
                :params="[{search_placeid:scope.row.sharedpanel.placeid}]"
                url="/share/place"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedpanel.placeid" />
            </el-form-item>
            <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="34">
        <template #default="scope">
          {{scope.row.panelequipment.sn}}
          <hover-show-qr
            :key="scope.row.panelequipment.sn"
            :logo="`${scope.row.panelequipment.prefix}`"
            :text="scope.row.panelequipment.sn"
          ></hover-show-qr>
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="30">
        <template #default="scope">
          <span
            :class="'icon-panel-'+scope.row.panelequipment.prefix"
            class="iconfont"
            v-my-color="scope.row.articlenum>0?'primary':'info'"
          ></span>&ensp;
          <span>{{scope.row.product.name}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="小魔夹数量" min-width="50">
        <template #header>
          <el-tag type="success">有</el-tag>
          <!-- <el-tag type="info">空</el-tag> -->
          <el-tag type="danger">故障</el-tag>
          <el-tag type="warning">私有</el-tag>
        </template>
        <template #default="scope">
          <el-tag type="success">{{scope.row.articlenum}}</el-tag>
          <!-- <el-tag type="info">{{12-scope.row.supplynum-scope.row.faultnum-scope.row.privatizednum}}</el-tag> -->
          <el-tag type="danger">{{scope.row.faultnum||0}}</el-tag>
          <el-tag type="warning">{{scope.row.privatizednum||0}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="地址" min-width="50" prop="place.address"></el-table-column>
      <el-table-column label="维修员ID" min-width="44" prop="sharedpanel.manageruserid"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button @click="handleShowComponenet(scope.row.panelequipment.sn)" size="mini" type="primary">查看组件</el-button>
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.$index,scope.row.panelequipment.sn)"
            size="mini"
            type="danger"
          >删除</el-button>
          <el-button @click="handleEidt(scope.$index,scope.row)" size="mini" type="primary">编辑</el-button>
          <el-button @click="handleShowDevice(scope.row.panelequipment.sn,scope.row.panelequipment.prefix)" size="mini">机柜内设备</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
  <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
  <v-device-dialog ref="deviceDialogRef"></v-device-dialog>
  <v-components-dialog ref="componentsDialogRef"></v-components-dialog>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  getPanelList,
  PanelListRes,
  removePanel,
  getcrc,
} from '@/api/Share/Panel'
import addDialog from './components/addDialog.vue'
import deviceDialog from './components/deviceDialog.vue'
import componentsDialog from './components/componentsDialog.vue'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-dialog': addDialog,
    'v-device-dialog': deviceDialog,
    'v-components-dialog': componentsDialog,
  },
  setup() {
    const loading = ref(false)
    const syncloading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<PanelListRes[]>([])
    const addDialogRef = ref<any>()
    const deviceDialogRef = ref<any>()
    const componentsDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '',
        placeid: '',
        location: '',
        manageruserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: 'SN',
        placeid: '场所ID',
        location: '场所位置',
        manageruserid: '维修员ID',
      },
    })

    const mixin_router_search = ref({ status: '', productmodel: '' })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await getPanelList(rqData)
        total.value = res.total
        list.value = res.records
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {
        placeid: mixin_select_search.value.search.placeid,
      })
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row.sharedpanel)
    }
    const addSuccess = () => {
      reData()
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removePanel({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    const handleShowDevice = (sn: string, prefix: string) => {
      deviceDialogRef.value.open(sn, prefix)
    }
    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }
    const handleSync = async (deviceid: string) => {
      try {
        syncloading.value = true
        await getcrc({ deviceid })
      } finally {
        syncloading.value = false
      }
      reData()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleRemove,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      handleShowDevice,
      deviceDialogRef,
      componentsDialogRef,
      handleSync,
      handleShowComponenet,
      changSearch,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    .el-select {
      width: 150px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>