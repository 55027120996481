<template>
  <el-dialog :destroy-on-close="true" :title="isAdd?'添加':'编辑'+`运营团队`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData"   label-width="120px" ref="formRef">
     
      <el-form-item label="团队名称" class="tip-text" prop="name" >
        <el-input v-model="inputData.name" style="width: 220px;" ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { addOperateHttp ,updateOperateHttp } from '@/api/Operate/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
let id = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const isAdd = ref(true)

    const inputData = ref({
      name: '',
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (add:boolean,item:any) => {
      isAdd.value=add;
      visible.value = true
      if(!add){
        id=item.id;
        inputData.value.name=item.name;
      }
    }
    const submit = async () => {
      try {
        // console.log('是否添加',isAdd.value);
        btnSumitLoading.value = true
        const res = isAdd.value ? await addOperateHttp(inputData.value.name) : await updateOperateHttp({id:id,name:inputData.value.name});
  
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      isAdd,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>