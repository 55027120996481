
   import { defineComponent, ref,readonly} from "vue";
   import { getShareProductInfo,getProductInfoList,getProductIdList} from '@/api/util'
   import routerChangeReData from '@/common/routerChangeReData'
   import{purchaseListModel, getPurchaseList, removePurchase, accextPurchase,exportPurchase} from "@/api/Investment/purchase"
   import shipmentDialog from '@/components/investorment/shipmentDialog.vue'
   import accectPurchaseDialog from "@/components/investorment/accectPurchaseDialog.vue"
   import addPurchaseOrderDialog from './components/addPurchaseOrderDialog.vue';
   import exportDialog from "@/components/export/exportDialog.vue";
   import addNoOrderPurchaseDialog from "./components/addNoOrderPurchaseDialog.vue";
   import { ElMessage, ElMessageBox } from "element-plus";
   import dayjs from 'dayjs'
   let investorOrderId="";
   export default defineComponent({
     name: "purchase",
     components: {
      'v-shipment-dialog':shipmentDialog,
      accectPurchaseDialog,
      addPurchaseOrderDialog,
      exportDialog,
      addNoOrderPurchaseDialog,
     },
     setup() {
      const loading = ref(false);
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const list = ref<purchaseListModel[]>([]);
      const sheareProductInfo = getShareProductInfo()
      const shipmentDialogRef = ref<any>()
      const accectPurchaseDialogRef = ref<any>();
      const addPurchaseOrderDialogRef = ref<any>();
      const addNoOrderPurchaseRef = ref<any>();
      const productList = getProductInfoList()
      const productIdList = getProductIdList()
      const exportDialogRef = ref<any>();
      const mixin_router_search = ref({
        status: '', //投资订单状态，0 已下单；1 已付款；2 已出仓；3 已开始投放；4 已寄出；
        careateBN: '',
        order: 'gmtCreate',
        sort: 'desc',
      })
      const mixin_select_search = ref({
        search: {
          account:'', //投资人手机号
          investorname:'',//投资人姓名
          investoruserid: '', // 投资人用户编号
          orderid: '', //投资订单编码
        },
      })
      const mixin_select_search_label = readonly({
      search: {
          investoruserid: '投资人ID', // 投资人用户编号
          orderid: '订单ID', //投资订单编码
          account:'手机号', 
          investorname:'姓名',
        },
      })
      const searchSelect = ref({search: 'account'})
      const reData = async(rq?: any) => {
        try {
          loading.value = true;
          let rqData: any = {current: page.value,size: limit.value}

          if (rq && JSON.stringify(rq) !== '{}') {
            if (rq.order) {
              const { order, sort } = rq
              delete rq.order
              delete rq.sort
              rqData = { ...rqData, order, sort }
            }
            console.log("筛选时间",rq.careateBN);
            if (rq.careateBN) {
              rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
              rq.endTime =  dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
              delete rq.careateBN
            }
            rqData = { ...rqData, entity: rq }
          }
          const res = await getPurchaseList(rqData);
          console.log('请求列表='+res)
          list.value = res.rows
          total.value = res.total
        } finally {
          loading.value = false;
        }
      }
   
      const { mixinReRouter, onPageChange, handleLimitChange, changSearch} =
        routerChangeReData({
          mixin_router_search,
          mixin_select_search,
          loading,
          limit,
          page,
          reData,
          searchSelect,
      })
      const handelChange = () => {
        page.value = 1
        mixinReRouter()
      }
      
      
      const handleAddNewPurchase = async() => {
        addPurchaseOrderDialogRef.value.open();
      }
     
      const handleAddNoOrderPurchase = async() =>{
        addNoOrderPurchaseRef.value.open();
      }

      const handleSuccess = (msg:string) =>{
        ElMessage.success(msg)
        page.value = 1
        mixinReRouter()
      }
      const handlerShipment = (item:any) =>{
        shipmentDialogRef.value.open(item.account,item.investmentOrderId,item.id,item.quantity);
      }
      const handlerAccept = async (item:any) =>{
        await ElMessageBox.confirm(
          `是否接收采购单id=${item.id}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await accextPurchase({ id :item.id })
        handelChange();
        ElMessage.success(res.msg)
      }
      const handlerDelete = async(item:any) =>{
        
        await ElMessageBox.confirm(
          `是否删除采购单id=${item.id}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )

        const res = await removePurchase({ id :item.id })
        page.value=1;
        reData();
        ElMessage.success(res.msg)
      }
      const handleExportPurchase = () =>{
        exportDialogRef.value.open('采购单历史表')
      }

      const exportFile = async(name:string) =>{
      try {
        // let rqData: any = { current: page.value, size: limit.value }
        
        // if (rq && JSON.stringify(rq) !== '{}') {
        //   rqData = { ...rqData, entity: rq }
        // }

        let searchData: any = {}
        let rqData: any = {current: page.value,size: limit.value,order:'gmtCreate',sort:'desc',fileName:'purchase'}
       
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          entity.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          entity.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
          delete searchData.careateBN
        }
        if(searchData.status!=''&&searchData.status){
          entity.status=searchData.status;
        }
        delete searchData.status
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportPurchase(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
      return {
        mixin_router_search,
        mixin_select_search,
        mixin_select_search_label,
        changSearch,      
        searchSelect,
        limit,
        page,
        total,
        list,
        productList,
        productIdList,
        loading,
        sheareProductInfo,
        shipmentDialogRef,
        accectPurchaseDialogRef,
        addPurchaseOrderDialogRef,
        addNoOrderPurchaseRef,
        exportDialogRef,
        reData,
        handleAddNewPurchase,
        handleAddNoOrderPurchase,
        handelChange,
        onPageChange,
        handleLimitChange,
        handleSuccess,
        handlerShipment,
        handlerDelete,
        handlerAccept,
        handleExportPurchase,
        exportFile,
      }
     }
   })    
   
   