<template>
  <el-dialog :destroy-on-close="true" :title="`新建包装箱`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData"  label-width="120px" ref="formRef">
      <el-form-item label="添加包装箱个数" prop="day">
        <el-input-number :min="0" :step="1" v-model="inputData.count"></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { addBoxHttp } from '@/api/WarehouseManagement/whbox'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const inputData = ref({
      count: 0,
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = () => {
     
      visible.value = true
      console.log('新的确认')
      // inputData.value.day = Math.ceil(
      //   dayjs().diff(_row.receivedate, 'day', true)
      // )
    }
    const submit = async () => {
      try {
        btnSumitLoading.value = true
        const res = await addBoxHttp(inputData.value.count)
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>