import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
export interface SharedorderrenewalList {
    "account": string
    "amount":number,
    "articleequipmentsn": string
    "articleproductid": string
    "articleproductmodel": string
    "articleproductname": string
    "articleproducttype": string
    "creationdate": string,
    "days":number,
    "fortest": true,
    "modificationdate": string,
    "outtradeno": string
    "rechargemode": string
    "rechargeorderid": string
    "renewalid": string
    "sharedorderid": string
    "succeed": true,
    "userid": string
}

export async function sharedorderrenewalList(params: ListReq): Promise<ListRes<SharedorderrenewalList>> {
    params.token = true
    const res = await defHttp.request<ListRes<SharedorderrenewalList>>(
        {
            url: `/sharedorderrenewal/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}