
import { defineComponent, ref } from "vue";
import { detaildModel,getInAndOutDetails,exportInAndOutDetails} from "@/api/Financial/inAndOutTotalMoney";
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
import { toOptional } from "@/types/utils";
import { saveAs } from 'file-saver'
let valueType='';
let name='';
let rechargemode='';
export default defineComponent({
   name: "v-dialog",
   components: {
    exportDialog
   },
   setup() {
    const title = ref('共享收入详情');
    const visible = ref(false);
    const page = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const xmjTotalMoney = ref(0);
    const umbrellaTotalMoney = ref(0);
    const orderTotalMoney = ref(0);
    const selXmjTotalMoney = ref(0);
    const selUmbrellaTotalMoney = ref(0);
    const selOrderTotalMoney = ref(0);
    const exportDialogRef = ref<any>();
    const list = ref<detaildModel[]>([]);
    const search = ref({
      timeBN:'',
      fortest:false
    });
    const open = (_type:string,_name:string,_rechargemode:string) => {
      console.log('开启弹窗')
      visible.value = true;
      valueType=_type;
      rechargemode=_rechargemode;
      xmjTotalMoney.value=0;
      umbrellaTotalMoney.value=0;
      orderTotalMoney.value=0;
      selXmjTotalMoney.value=0;
      selUmbrellaTotalMoney.value = 0;
      selOrderTotalMoney.value = 0;
      list.value=[];
      if(_name!=null){
        name=_name
        title.value=_name+"共享收入详情"
      }
      search.value.timeBN='';
      search.value.fortest=false;
      reData();
    }
    const handleClose = () => {
      visible.value = false
    }
    const handelChange = () =>{
      reData();
    }
    const handleClick = () => {
      getStatisticsList();
    }
    const pageChange = (val:number) => {
      page.value=val;
      getStatisticsList();
    }
    const handleLimitChange = (val:number) =>{
      limit.value=val;
      getStatisticsList();
    }
    const reData = async () => {
      getStatisticsTotal();
      getStatisticsList();
    };

    const getStatisticsTotal = async() => {
      console.log('开启调用')
      try {
        //reLoading.value = true;
        // let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,rechargemode:rechargemode,type:valueType};
        // if (search.value.timeBN) {
        //   rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        //   rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        // }
        // const res = await getInAndOutDetails(rqData);
        // totalInfo.value = res;
        // profitTotalInfo.value=res.alllist[0];
        // profitTimeInfo.value=res.list[0];
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }
    const getStatisticsList = async() => {
      
      try {
        //reLoading.value = true;
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,model:rechargemode,type:valueType};
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        const res = await getInAndOutDetails(rqData);
        console.log('详情信息=',res)
        total.value=res.data.total
        list.value=res.data.records
        xmjTotalMoney.value=res.data.alllist[0]?res.data.alllist[0].phoneholderamount:0;
        umbrellaTotalMoney.value=res.data.alllist[0]?res.data.alllist[0].umbrellaamount:0;
        orderTotalMoney.value=res.data.alllist[0]?res.data.alllist[0].deductionamount:0;
        selXmjTotalMoney.value=res.data.sectionlist[0]?res.data.sectionlist[0].phoneholderamount:0;
        selUmbrellaTotalMoney.value = res.data.sectionlist[0]?res.data.sectionlist[0].umbrellaamount:0;
        selOrderTotalMoney.value = res.data.sectionlist[0]?res.data.sectionlist[0].deductionamount:0;
        // list.value.map(v => {
        //   v.creationtime = dayjs(parseInt(v.creationtime!)).format("YYYY-MM-DD HH:mm:ss")
        //   return v
        // })
       // console.log(res.allList)
      } finally {
        //reLoading.value = false;
      }
    }

    const handleExport = async() =>{
      exportDialogRef.value.open(title.value)
    }
    const exportFile = async(name:string) =>{
      try {
        let rqData: any = { currentPage: page.value, size: limit.value,fortest:search.value.fortest,model:rechargemode,type:valueType,fileName:title.value};
       
        if (search.value.timeBN) {
          rqData.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }

        const res = await exportInAndOutDetails(rqData)

        const blob = new Blob([res.data]);
        saveAs(blob, name)
      } finally {

      }
    }

    return {
      title,
      visible,
      page,
      total,
      limit,
      list,
      search,
      xmjTotalMoney,
      umbrellaTotalMoney,
      orderTotalMoney,
      selXmjTotalMoney,
      selUmbrellaTotalMoney,
      selOrderTotalMoney,
      open,
      handleClose,
      handelChange,
      handleClick,
      pageChange,
      handleLimitChange,
      handleExport,
      exportDialogRef,
      exportFile,
    }
   }
})
