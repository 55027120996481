import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
import { userStore } from "@/store/modules/user";

export interface faultListModel {
    "equipmentId":string, //设备id
    "equipmentSn": string, //设备sn
    "gmtCreate": string, //创建时间
    "gmtModified": string, //更新时间
    "id": number, //故障id
    "sharedOrderId":string,  //订单号
    "status":number, //状态 0:待确认 1：已确认 2：已撤回 3:已完结
    "stopBillingTime": string, //停止计费时间
    "submitUserAccount": string, //上报用户用户账号   
    "submitUserId": string,   //上报用户用户ID
    "submitUserName": string,   //上报用户用户昵称
    "needMaintain":boolean,  //是否需要返厂维修
}
export interface faultDetailsModel {
    "equipmentId":string, //设备id
    "equipmentSn": string, //设备sn
    "gmtCreate": string, //创建时间
    "gmtModified": string, //更新时间
    "id": number, //故障id
    "sharedOrderId":string,  //订单号
    "status":number, //状态 0:待确认 1：已确认 2：已撤回 3:已完结
    "stopBillingTime": string, //停止计费时间
    "submitUserAccount": string, //上报用户用户账号   
    "submitUserId": string,   //上报用户用户ID
    "submitUserName": string,   //上报用户用户昵称
    "content":string,//订单故障
    "excuteList":any[],
    "attachList":any[],
}

export async function faultListHttp(params: ListReq): Promise<ListRes<faultListModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<faultListModel>>(
        {
            url: `/eqbreakdown/page`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {    
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        v.stopBillingTime = v.stopBillingTime?dayjs(v.stopBillingTime).format("YYYY-MM-DD HH:mm:ss"):"暂无";
        return v
    })
    return res
}

//确认故障
export async function faultComfirmHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqbreakdown/comfirm`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "故障确认成功"
}

//完结故障
export async function faultCompleteHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqbreakdown/complete`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "故障完结成功"
}

//撤回故障
export async function faultRevocationHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqbreakdown/revocation`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "故障撤回成功"
}

//驳回故障
export async function faultRejectHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/eqbreakdown/reject`,
            params: {
                token: true,
                ...parmas
            },
        }, 
        {
            prefix: "/shared",          
        }
    );
    return res.msg || "故障撤回成功"
}

//加载故障详情
export async function getFaultDetalsHttp(id: string): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/eqbreakdown/getdetail`,
            method: "GET",
            params: {
                token: true,
                id
            },
        }, 
        {
            prefix: "/shared",  
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }        
        }
    );
    return res
}

export async function exportEqbreakdownInfo(params: ListReq): Promise<any> {
    params.token = true
    const res = await defHttp.request<any>(
        {
            url: `/export/exportEqbreakdownInfo`,
            responseType:'blob',
            params
            
        },{
            prefix: "/analy",
            contentType: 'json',
            isFile:true,
            isTransformRequestResult:false,
        }
        
    );
   
    return res
}