
import { defineComponent, ref, inject } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  sharedorderMasterRefundAudit,
  SharedorderrefundList,
} from '@/api/Order/Refund'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const reExpenditureLoading = ref(false)
    const expenditure = ref(0)
    const auditresult = ref('')
    const row = ref<SharedorderrefundList>()
    const handleClose = () => {
      visible.value = false
    }

    const open = (_row: SharedorderrefundList) => {
      visible.value = true
      row.value = _row

    }
    const toPath_RouterSearchMixin: any = inject('toPath_RouterSearchMixin')
    const submit = async (approved: boolean) => {
      try {
        btnSumitLoading.value = true
        const res = await sharedorderMasterRefundAudit({
          approved,
          auditresult: auditresult.value
            ? auditresult.value
            : approved
            ? '同意'
            : '不同意',
          refundId: row.value?.refundid as string,
        })
        ElMessage.success(res)
        emit('success')
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      submit,
      auditresult,
      reExpenditureLoading,
      expenditure,
      row,
    }
  },
})
