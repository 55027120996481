<template>
  <el-dialog :destroy-on-close="true" :title="`4G接收消息`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="80vw">
    <div class="container">
      <header>
        <el-date-picker
          @change="reData(true)"
          align="right"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          v-model="date"
        ></el-date-picker>
      </header>

      <el-table
        :data="list"
        :default-sort="{prop: 'datatimestamp', order: 'descending'}"
        @filter-change="filterChange"
        @sort-change="sortChange"
        height="560px"
        stripe
        style="width: 100%"
      >
        <!-- v-loading="loading" -->
        <el-table-column type="expand">
          <template #default="props">
            <el-form class="table-expand" inline label-position="left" label-width="120px">
              <el-form-item label="msgid">{{props.row.msgid}}</el-form-item>
              <el-form-item label="content">{{props.row.content}}</el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="deviceid" min-width="20" prop="deviceid"></el-table-column>
        <el-table-column :filters="filters" align="center" column-key="datastream" label="datastream" min-width="20" prop="datastream"></el-table-column>
        <el-table-column align="center" label="body" min-width="80" prop="body"></el-table-column>
        <el-table-column :sort-orders="['ascending', 'descending']" label="发起时间" min-width="20" prop="datatimestamp" sortable="custom"></el-table-column>
        <el-table-column :sort-orders="['ascending', 'descending']" label="接收时间" min-width="20" prop="creationtime" sortable="custom"></el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :page-sizes="[10, 30, 50, 100]"
        :total="total"
        @current-change="reData()"
        @size-change="reData(true)"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>
<script lang = "ts" >
import { fourthgmsgList, FourthgcommandList } from '@/api/Share/Device'
import { defineComponent, ref } from 'vue'
let sort = 'datatimestamp'
let order = 'desc'

let deviceid = '',
  datastream: any[] = []
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const visible = ref(false)
    const list = ref<FourthgcommandList[]>([])
    const filters = [
      { text: 'PTS', value: 'PTS' },
      { text: 'STSIN', value: 'STSIN' },
      { text: 'STSOUT', value: 'STSOUT' },
      { text: 'GETCSQ', value: 'GETCSQ' },
      { text: 'MAC', value: 'MAC' },
    ]
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const date: any[] = []
    const handleClose = () => {
      visible.value = false
      deviceid = ''
      datastream = []
      //   selfDataRef.value.clearValidate();
      //   selfDataRef.value.resetFields();
    }
    const open = (_deviceid: string) => {
      visible.value = true
      deviceid = _deviceid
      reData()
    }
    const reData = async (re?: boolean) => {
      if (re) page.value = 1
      const req: any = {
        limit: limit.value,
        page: page.value,
        deviceid,
        sort,
        order,
        // phoneholdersn
      }
      if (date.length > 0) {
        req.datatimestampbegin = new Date(req.date[0]).getTime()
        req.datatimestampend = new Date(req.date[1]).getTime()
      }
      if (datastream.length > 0) req.datastream = datastream.join(',')
      const { totalCount, result } = await fourthgmsgList(req)
      total.value = totalCount
      list.value = result
    }
    const sortChange = (e: {
      order: 'ascending' | 'descending' | undefined
      prop: string
    }) => {
      switch (e.order) {
        case 'ascending':
          order = 'asc'
          break
        case 'descending':
          order = 'desc'
          break
      }
      reData(true)
    }

    const filterChange = (e: any) => {
      datastream = e.datastream
      reData()
    }
    return {
      visible,
      handleClose,
      open,
      list,
      filters,
      page,
      total,
      limit,
      filterChange,
      date,
      sort,
      order,
      reData,
      sortChange,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>