
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  toRef,
  computed,
} from "vue";
import map from "@/components/echart/map";
const changsha = require("@/assets/json/430100_full.json");
console.log("changsha :>> ", changsha);
const __default__ = defineComponent({
  props: {
    height: {
      type: String,
      default: () => "600px",
    },
    name: {
      type: String,
      default: () => "设备",
    },
    total: {
      type: Number,
      required: true,
      defalut: () => 0,
    },
    mapData: {
      type: Array,
    },
  },
  components: {
    "v-map": map,
  },
  setup(props) {
    const total = toRef(props, "total");
    const mapData = toRef(props, "mapData");
    const name = toRef(props, "name");
    const getRow = computed(() => {
      const row: any = [];
      console.log("row :>> changsha", changsha, row);
      mapData.value?.map((v: any) => {
        const item = changsha.features.find((city: any) => {
          return city.properties.name == v.name;
        });
        if (item) {
          row.push({
            name: v.name,
            value: [...item.properties.centroid, v.value || 0.1],
          });
        }
      });
      return row;
    });
    const option = computed(() => {
      console.log('name.value :>> ', name.value);
      return {
        title: {
          text: `长沙${name.value}分布`,
          top: "10px",
          textStyle: {
            color: "#00f4f4",
            fontSize: 16,
          },
        },
        series: [
          // {
          //   name: "小魔夹分布",
          //   type: "map",
          //   map: "changsha",
          //   // mapType: 'changsha',
          // },
          {
            name: "小魔夹",
            data: getRow.value,
            type: "effectScatter", // 'scatter'
            coordinateSystem: "geo",
            symbolSize: (value: any) => {
              return Math.floor(
                parseFloat((value[2] / total.value).toFixed(2)) * 100
              );
            },
            rippleEffect: {
              brushType: "stroke",
            },
            selectedMode: "single",
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                // color: "rgba(0,0,0,.5)"
                color: "#00BCD4",
              },
            },
          },
        ],
        geo: {
          map: "changsha",
          label: {
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "rgba(39,116,234,0.2)",
              borderColor: "rgb(43,124,211)",
            },
            emphasis: {
              areaColor: "rgba(39,116,234,.5)",
            },
          },
          scaleLimit: { min: 1, max: 20 },
          roam: true,
          // center: [109.97, 31.53],
          center: [113.3, 28.2],
          // center: [112.5842, 28.1149],
          selectedMode: "single",
        },
        visualMap: {
          min: 0,
          max: 1500,
          show: false,
          left: "left",
          top: "bottom",
          text: ["High", "Low"],
          seriesIndex: [0],
          inRange: {
            color: ["#e0ffff", "#006edd"],
          },
          calculable: true,
        },
        tooltip: {
          formatter: function (v: any): string {
            console.log("v :>> ", v);
            var tpl = [];
            tpl.push(v.seriesName + "<br>");
            tpl.push(v.name + "：" + v.value[2]);
            return tpl.join("");
          },
        },
      };
    });
    const mapRef = ref<any>(null);
    const reView = async () => {
      await nextTick();
      mapRef.value.myResize();
    };
    return {
      option,
      mapRef,
      reView,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "801f5fa8-height": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__