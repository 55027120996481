
import { defineComponent, computed, ref,onMounted,inject} from "vue";
import selectItem from './components/selectItem.vue'
import { userRoleList } from '@/api/User/role';
import { faultListHttp } from '@/api/fault/index'
import { getCabinetTypeTotal, getCabinetFaultTotal } from '@/api/Share/Device'
import { getPlaceList } from '@/api/Share/Place'
import { getProgressPrivateOrderTotal } from '@/api/Order/Privatization'
import { userStore } from '@/store/modules/user';
import { useRouter } from "vue-router";
import { getShareProductInfo, ProductInfo,getCabinetProductInfo} from '@/api/util'
import { deepCopy } from '@/utils'
import dayjs from 'dayjs'
export default defineComponent({
  name: "operate-home",
  components : {
    selectItem
  },
  data() {
      return {
        images:[
            {
                src:'img/banner1.jpg',
                desc:'测试'
            },
              {
                src:'img/banner2.jpg',
                desc:'测试'
            },
              {
                src:'img/banner3.png',
                desc:'测试'
            }
        ]
      }
  },
  setup() {
    const nowTime=ref('');
    const router = useRouter();
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const repairmanNum=ref('0')
    const overdueOrderNum=ref('0')
    const faultOrderNum = ref('0')
    const deviceTotal = ref(0)
    const cabinetNum = ref('0')
    const cabinetOneNum = ref('0')
    const cabinetTwoNum = ref('0')
    const umbrellaNum = ref('0')
    const bmgNum = ref ('0')
    const faultDeviceTotalNum = ref(0)
    const faultCabinetNum = ref('0')
    const faultUmbrellaNum = ref('0')
    const placeTotal = ref('0')
    const orderSelectItem = ref<ProductInfo>(deepCopy(getCabinetProductInfo()[0]))
    const toPath = (path: string) => {
      router.push(path);
    };

    const cabinetSelectChange = (e: any) => {
       orderSelectItem.value = e
       //getCabinetNum(orderSelectItem.value.productmodel)
       if(orderSelectItem.value.productmodel=='MGS4G2'){
          cabinetNum.value=cabinetOneNum.value;
       }else if(orderSelectItem.value.productmodel=='MGS4G3'){
          cabinetNum.value=cabinetTwoNum.value;
       }
    }
    /**
    * 获取维修员数量
    */
    const getRepairmanNum = async () => {
      try {
        let rqData: any = { page: '1', limit: '1' }
        rqData.operationId=userStore.getCurrentRole.operationId
        const res = await userRoleList(rqData, "repairman")
        repairmanNum.value = res.totalCount.toString();
      } finally {
        
      }
    }
    const getFaultOrderNum = async () => {
      try {
        
        let rqData: any = { current: "1", size: "1" }
        rqData.order='gmtCreate';
        rqData.sort='desc';
        rqData.entity={status: "0"};
        const res = await faultListHttp(rqData)
        faultOrderNum.value = res.total.toString()
      } finally {
        
      }
    }

    const getProgressPrivateOrderNum = async () => {
      try{
        let rqData: any = { fortest: false}
        const res = await getProgressPrivateOrderTotal(rqData);
        overdueOrderNum.value = res!.toString();
      } finally {

      }
    }

    const getCabinetTotal = async() => {
      try{
        deviceTotal.value = 0;
        let rqData: any = { enabled: true}
        rqData.operationId=userStore.getCurrentRole.operationId
        
        const res = await getCabinetTypeTotal(rqData)
        res.data.records.forEach(any =>{
          if(any.productmodel !=null && any.productmodel=='CV01'){
            bmgNum.value=any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='MGS4G2'){
            cabinetOneNum.value = any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }else if (any.productmodel !=null && any.productmodel=='MGS4G3') {
            cabinetTwoNum.value=any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='UMHD'){
            umbrellaNum.value=any.total.toString();
            deviceTotal.value +=parseInt(any.total);
          }
        })
      } finally {

      }
    }

    const getDeviceFaultTotal = async() => {
      try{
        faultDeviceTotalNum.value = 0;
        faultCabinetNum.value = '0'
        let rqData: any = { enabled: true}
        rqData.operationId=userStore.getCurrentRole.operationId
        
        const res = await getCabinetFaultTotal(rqData)
        res.data.records.forEach(any =>{
          if(any.productmodel !=null && any.productmodel=='CV01'){
           
            faultDeviceTotalNum.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='MGS4G2'){
            faultCabinetNum.value = (parseInt(faultCabinetNum.value)+parseInt(any.total)).toString();
            faultDeviceTotalNum.value +=parseInt(any.total);
          }else if (any.productmodel !=null && any.productmodel=='MGS4G3') {
            faultCabinetNum.value = (parseInt(faultCabinetNum.value)+parseInt(any.total)).toString();
            faultDeviceTotalNum.value +=parseInt(any.total);
          }else if(any.productmodel !=null && any.productmodel=='UMHD'){
            faultUmbrellaNum.value=any.total.toString();
            faultDeviceTotalNum.value +=parseInt(any.total);
          }
        })
      } finally {

      }
    }

     const getPlaceNum = async () => {
      try {
        
        let rqData: any = { current: 1, size: 10 }       
        rqData.operationId=userStore.getCurrentRole.operationId       
        const res = await getPlaceList(rqData)
        placeTotal.value = res.total.toString();
      } finally {
        
      }
    }
    onMounted(()=>{
      nowTime.value=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      //获取总收益
      getRepairmanNum();
      getFaultOrderNum();
      getProgressPrivateOrderNum();
      getCabinetTotal();
      getDeviceFaultTotal();
      getPlaceNum();
    })
    return {
      onMounted,
      toPath_RouterSearchMixin,
      toPath,
      nowTime,
      repairmanNum,
      overdueOrderNum,
      faultOrderNum,
      deviceTotal,
      cabinetNum,
      umbrellaNum,
      bmgNum,
      faultDeviceTotalNum,
      faultCabinetNum,
      faultUmbrellaNum,
      placeTotal,
      orderSelectItem,
      cabinetSelectChange,
    }
  },
 
});
