
import { defineComponent, computed, ref,onMounted,inject} from "vue";
import { userStore } from '@/store/modules/user'
import { getUserInfo, changeRole } from '@/api/User/index'
import { getShareDeviceList } from "@/api/Share/Device";
import { totalProfitModel, getInvsetorTotalProfit, getProfitOnDay } from '@/api/Index/investor'
import dayjs from 'dayjs'
import { useRouter } from "vue-router";
export default defineComponent({
  name: "investor-home",
  setup() {
    const avaterIsLoaded = ref(false)
    const userInfo = computed(() => userStore.userInfo)
    const totalProfit = ref("0");
    const investorProfit = ref(0); 
    const nowTime=ref('');
    const todayProfit = ref('0');
    const yesterdayProfit = ref('0');
    const weekProfit = ref('0');
    const xmjTotal = ref('0');
    const router = useRouter();
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const toPath = (path: string) => {
      router.push(path);
    };

    const avaterLoadingError = async (e: unknown) => {
      console.log('avaterLoadingError', e)
      if (avaterIsLoaded.value) return
      try {
        const { avatarurl } = await getUserInfo()
        userStore.upUserInfo({ avatarurl })
        avaterIsLoaded.value = true
      } catch (error) {
        console.log({ error })
        avaterIsLoaded.value = false
      }
    }

    const getTotalProfit = async() =>{
      let rqData: any = { productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4',fortest:'0',userid:userInfo.value.userid}
       const res = await getInvsetorTotalProfit(rqData)
       console.log('投资人请求数据')
       console.log(res)
       totalProfit.value = res.data.sum
       
      res.data.resList.forEach(function(item,index,array){
        if(item.gainerrole=='investor'){
            investorProfit.value=item.amount
            return
          }
      })
    }

    const getProfitOnDate = async(date:string,type:string,dateType:string) =>{
      let rqData: any = { 
        productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4',
        fortest:'0',
        investoruserid:userInfo.value.userid,
        date:date,
        role:'investor',
        type:type,

      }
       const res = await getProfitOnDay(rqData)      
       if(dateType=='1'){
         todayProfit.value=res.data.sum
         getProfitOnDate(dayjs(new Date().getTime()-24*60*60*1000).format("YYYY-MM-DD"),'1','2')
       }else if(dateType=='2'){
         yesterdayProfit.value=res.data.sum
         getProfitOnDate(dayjs(new Date().getTime()-24*60*60*1000).format("YYYY-MM"),'2','3')
       }else if(dateType=='3'){
         weekProfit.value=res.data.sum
       }
    }

     const getXmjTotal = async () => {
      try {
       
        let rqData: any = { 
            current: '1', 
            size: "1" ,
            entity: {
              fortest: false,
              articleproductid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
              investoruserid: userInfo.value.userid
            } 
          };
       
        const res = await getShareDeviceList(rqData);
        xmjTotal.value = res.total.toString();

      } finally {
       
      }
    };

    onMounted(()=>{
      nowTime.value=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
      //获取总收益
      getTotalProfit()
      //按天查询收益
      getProfitOnDate(dayjs(new Date().getTime()).format("YYYY-MM-DD"),'1',"1")

      //查询小魔夹总数
      getXmjTotal();
    })
    return {
      avaterIsLoaded,
      userInfo,
      avaterLoadingError,
      onMounted,
      toPath_RouterSearchMixin,
      toPath,
      totalProfit,
      investorProfit,
      nowTime,
      todayProfit,
      yesterdayProfit,
      weekProfit,
      xmjTotal,
    }
  },
 
});
