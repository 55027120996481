import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes } from "../../types/requestBaseType"



export interface investorModel {
    userid: string,
    name: string,
    account: string,
    xmjtotal: string,
    sharexmjtotal: string,
    totalwallet: string,
    totalprofit: string,
    totalchange: string,
    totalwithdraw: string,
    totalcost: string,
    rewardtotal:string
}

/**
 * 获取投资人统计
 */
 export async function getInvestorStatistics(parmas: RequestReq): Promise<ListRes<investorModel>> {
    const res = await defHttp.request<ListRes<investorModel>>(
        {
            url: `/finance/getInvestorStatistics`,
            params: {
                token: true,
                ...parmas
            },
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
    );
    return res
}

/**
 * 导出表格
 */
 export async function exportInvestorStatistics(parmas: RequestReq): Promise<any> {
    const res = await defHttp.request<any>(
        {
            url: `/export/exportInvestorStatistics`,
            params: {
                token: true,
                ...parmas
            },
            responseType:'blob',
        }, {
            prefix: "/analy",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            isFile:true,
            isTransformRequestResult:false,
        }
    );
    return res
}