/**登录工具，判断是否登录，是否需要跳转到登录页面
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import type { Router, RouteRecordRaw } from 'vue-router';
import VabProgress from "nprogress"
const {
    progressBar,
    recordRoute,
    routesWhiteList,
    title } = require("@/config/setting")
import { getToken } from '@/utils/localStore'
import { routerStore } from '@/store/modules/router'
import { userStore } from '@/store/modules/user';
import { resetRouter } from './'
import { ElMessage } from 'element-plus';
console.log('object :>> ', {
    progressBar,
    recordRoute,
    routesWhiteList,
    title
});
VabProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
})

function isLogin(): boolean {
    const token = getToken()
    const userRole = userStore.getCurrentRole
    const userInfo = userStore.userInfo
    return !!(token && userRole.name && userInfo.token)
}
export default (router: Router) => {
    router.beforeResolve(async (to, from, next) => {
        // if (progressBar) VabProgress.start()
        VabProgress.start()
        // console.log('路由进来了 :>> ', isLogin());
        // console.log('router.getRoutes() :>> ', router.getRoutes());
        if (isLogin()) {
            if (to.path === '/userlogin' || to.path == '/') {
                
                next({
                    path: '/home',
                    replace: true
                })
                // if (progressBar) VabProgress.done()
            } else {
                const hasPermissions = routerStore.getAddRouters && routerStore.getAddRouters.length > 0
                if (hasPermissions) { // 这个是已经设置过的跳过
                  
                    next()
                } else {
                    
                    try {
                        const addRouters = await routerStore.syncRouters()
                        console.log('add :>> ', addRouters);
                        resetRouter()
                        console.log('router.getRoutes() :>> ', router.getRoutes());
                        // if (progressBar) VabProgress.done()
                        next({
                            // path: to.path,
                            ...to,
                            replace: true
                        })
                    } catch (e) {
                        // if (progressBar) VabProgress.done()
                        ElMessage.error("获取信息失败请重新登陆")
                        userStore.loginOut()
                        next('/userlogin')
                    }
                }
            }
        } else {
            console.log('无登陆状态条状页面 :>> ');
            if (routesWhiteList.indexOf(to.path) !== -1) {
                next()
            } else {
                if (recordRoute) {
                    next(`/userlogin?redirect=${to.path}`)
                } else {
                    next('/userlogin')
                }
                // if (progressBar) VabProgress.done()
            }
        }

        document.title = to.meta.title ? `${to.meta.title}-${title}` : title

    })
    router.afterEach(() => {
        // if (progressBar) VabProgress.done()
        VabProgress.done()
    })
}

