<template>
  <el-dialog :destroy-on-close="true" :title="`确认扣款金额`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef" v-if="row">
      <el-form-item label="续期" prop="day">{{row.renewaltotaldays}}天，扣款{{row.renewalamount}}元</el-form-item>
      <el-form-item label="总共使用天数" prop="day">
        <el-input-number :min="0" :step="1" v-model="inputData.day"></el-input-number>
      </el-form-item>
      <el-form-item label="私有化扣费" prop="day">{{inputData.day-row.renewaltotaldays}}天，扣款{{inputData.day*3-row.renewalamount}}元</el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import {
  SharedorderprivatizationdeductionList,
  sharedorderprivatizationreturnConfirm,
} from '@/api/Order/Privatization'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const row = ref<SharedorderprivatizationdeductionList>()
    const inputData = ref({
      day: 0,
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: SharedorderprivatizationdeductionList) => {
      row.value = _row
      visible.value = true
      inputData.value.day = Math.ceil(
        dayjs().diff(_row.receivedate, 'day', true)
      )
    }
    const submit = async () => {
      if (!row.value) return
      try {
        btnSumitLoading.value = true
        const rentdays = inputData.value.day - row.value.renewaltotaldays
        const res = await sharedorderprivatizationreturnConfirm({
          negotiationid: row.value.negotiationid,
          paymentamount: rentdays * 3,
          rentdays,
        })
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      row,
      submit,
      inputData,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>