
import {  addDeviceToBoxHttp } from '@/api/WarehouseManagement/whbox';
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import { getAdInfoList } from '@/api/util'
let boxId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
   
    const adInfoList = getAdInfoList()

    const inputData = ref({
      sn: '',
      adType:'0',//广告类型
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (id:string) => {
     
      visible.value = true
      boxId=id;
      console.log('新的确认')
    }
    const submit = async () => {
      try {
         btnSumitLoading.value = true
         const res = await addDeviceToBoxHttp({adType:inputData.value.adType,boxId:boxId,equipmentSn:inputData.value.sn})
      
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
      adInfoList,
    }
  },
})
