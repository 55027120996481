
import { defineComponent, ref, onMounted,nextTick} from "vue";
import orderEcharts from './components/orderEcharts.vue'
import loginEcharts from './components/loginEcharts.vue'
export default defineComponent({
    name: "Name",
    components: {
        orderEcharts,
        loginEcharts
    },
    setup() {
        return {
           
        }
    }
})
