import { defHttp } from "@/utils/http/axios";
import type { UserRole } from "@/types/user"
import { ListRes } from "../types/requestBaseType"
/**
 * 获取用户角色
 */
export async function getUserRelos(roleid: string,limit = 40):Promise<UserRole[]> {
    const res = await defHttp.request<ListRes<UserRole>>(
        {
            url: `/roletree/list`,
            params: {
                roleid,
                page: 1,
                limit: limit,
                token: true
            },
        }
    );
    if (res.totalCount > limit) {
        return await getUserRelos(roleid,res.totalCount)
    }
    return res.result
}