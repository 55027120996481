<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" v-model="mixin_router_search.articleproductmodel">
        <el-option :key="item.productid" :label="item.name" :value="item.productmodel" v-for="item of deviceList"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="是否测试" v-model="mixin_router_search.fortest">
        <el-option :value="false" label="正式"></el-option>
        <el-option :value="true" label="测试"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="扣费状态" v-model="mixin_router_search.deducted">
        <el-option :value="true" label="成功"></el-option>
        <el-option :value="false" label="失败"></el-option>       
      </el-select>
      <el-button @click="handleExport" icon="el-icon-plus" type="primary">导出</el-button>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="auto">
            <el-form-item label="用户ID">
              <my-click-data-to :data="scope.row.userid" :params="[{search_userid:scope.row.userid}]" url="/user"></my-click-data-to>
              <my-copy :data="scope.row.userid" />
            </el-form-item>
            <el-form-item label="订单号">
              <my-click-data-to
                :data="scope.row.sharedorderid"
                :params="[{search_sharedorderid:scope.row.sharedorderid}]"
                url="/order/userorder"
              ></my-click-data-to>
              <my-copy :data="scope.row.sharedorderid" />
            </el-form-item>
            <el-form-item label="扣费ID">
              {{scope.row.deductionid}}
              <my-copy :data="scope.row.deductionid" />
            </el-form-item>
            <el-form-item label="担保ID">
              {{scope.row.outorderno}}
              <my-copy :data="scope.row.outorderno" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="SN" min-width="35" prop="articleequipmentsn"></el-table-column>
      <el-table-column label="付款人" min-width="60">
        <template #default="scope">
          {{scope.row.nickname}}-{{scope.row.account}}
        </template>
      </el-table-column>
      <el-table-column label="型号" min-width="35" prop="articleproductname"></el-table-column>
      <el-table-column label="扣费" min-width="35" align="center">
        <template #default="scope" >       
           <color-icon :type="`icon-${scope.row.model}`" style="font-size:40px;color:#50b674"></color-icon>
           <el-tag type="info">¥ {{scope.row.amount}}</el-tag>            
        </template>
      </el-table-column>
      <el-table-column label="扣费时间" min-width="35" prop="deductiondate"></el-table-column>
      <el-table-column align="right" label="扣费状态" min-width="35">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.deducted">成功</el-tag>
          <el-tag type="danger" v-else>失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="right" label="订单类型" min-width="35">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.fortest">测试</el-tag>
          <el-tag type="success" v-else>正式</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="right" label="扣费类型" min-width="35">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.deduction_type==1">押金</el-tag>
          <el-tag type="danger" v-if="scope.row.deduction_type==0">共享订单</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import exportDialog from "@/components/export/exportDialog.vue";
import { SharedorderdeductionList,sharedorderdeductionList,exportSharedOrderDeductionList} from '@/api/Order/duction'
import { getShareProductInfo } from '@/api/util'
export default defineComponent({
  name: 'shareorderdection',
  components: {
    exportDialog
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const exportDialogRef = ref<any>();
    const list = ref<SharedorderdeductionList[]>([])
    const deviceList = getShareProductInfo()
    const searchSelect = ref({
      search: 'articleequipmentsn',
    })
    const mixin_router_search = ref({
      fortest: false,
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {        
        articleequipmentsn: '',
        sharedorderid: '',
        userid: '',
        outorderno:'',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        sharedorderid: '订单号',
        userid: '用户ID',
        outorderno:'担保单号',
      },
    })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderdeductionList(rqData)
        list.value = res.records
        total.value = res.total
        // sharedorderrenewalList
        // sharedorderrene
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch,} =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleExport = async() => {
      exportDialogRef.value.open('扣款记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'duction'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        
        delete searchData.order
        delete searchData.sort
        delete searchData.fortest
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        
        rqData = { ...rqData, entity: {...entity,fortest:mixin_router_search.value.fortest} }
        console.log('导出参数=',rqData)
        const res = await exportSharedOrderDeductionList(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      changSearch,
      onPageChange,
      deviceList,
      handleExport,
      exportDialogRef,
      exportFile,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-left: 24px;
    }
  }
  .el-select{
    width: 130px;
    /deep/.el-input{
      width: 130px;
    }
  }
}
</style>