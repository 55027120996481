
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  getPanelList,
  PanelListRes,
  removePanel,
  getcrc,
} from '@/api/Share/Panel'
import addDialog from './components/addDialog.vue'
import deviceDialog from './components/deviceDialog.vue'
import componentsDialog from './components/componentsDialog.vue'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-dialog': addDialog,
    'v-device-dialog': deviceDialog,
    'v-components-dialog': componentsDialog,
  },
  setup() {
    const loading = ref(false)
    const syncloading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<PanelListRes[]>([])
    const addDialogRef = ref<any>()
    const deviceDialogRef = ref<any>()
    const componentsDialogRef = ref<any>()
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '',
        placeid: '',
        location: '',
        manageruserid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: 'SN',
        placeid: '场所ID',
        location: '场所位置',
        manageruserid: '维修员ID',
      },
    })

    const mixin_router_search = ref({ status: '', productmodel: '' })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await getPanelList(rqData)
        total.value = res.total
        list.value = res.records
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {
        placeid: mixin_select_search.value.search.placeid,
      })
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row.sharedpanel)
    }
    const addSuccess = () => {
      reData()
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removePanel({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    const handleShowDevice = (sn: string, prefix: string) => {
      deviceDialogRef.value.open(sn, prefix)
    }
    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }
    const handleSync = async (deviceid: string) => {
      try {
        syncloading.value = true
        await getcrc({ deviceid })
      } finally {
        syncloading.value = false
      }
      reData()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleRemove,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      handleShowDevice,
      deviceDialogRef,
      componentsDialogRef,
      handleSync,
      handleShowComponenet,
      changSearch,
    }
  },
})
