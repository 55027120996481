import Cookies from 'js-cookie'


export function getToken() {
    const token = sessionStorage.getItem('token')
    if (!token) return null
    // token = JSON.parse(token)
    // let token = Cookies.get('token')
    // base 64 解密
    // token = window.btoa(token)
    return window.atob(token)
}


export function setToken(token: string) {
    // if (!token) return
    // base 64 加密
    // token = window.atob(token)  
    // token = JSON.stringify(token)
    sessionStorage.setItem("token", window.btoa(token));
    // return Cookies.set('token', token, {
    //   expires: 30
    // })
}



export function removeToken() {
    // return Cookies.remove('token')
    return sessionStorage.removeItem('token')
}

export function getLocalStorage(key: string): any {
    let value = window.localStorage.getItem(key)
    // if (value == null || value == undefined) return removeToken()
    // value = b64_to_utf8(value)
    value = value ? JSON.parse(value) : null
    return value
}

export function setLocalStorage(key: string, value: any) {
    if (!value && !key) return
    value = JSON.stringify(value)
    // value = utf8_to_b64(value)
    window.localStorage.setItem(key, value)
}

export function removeLocalStorage(key: string) {
    window.localStorage.removeItem(key)
}


export function setSessionStorage(key: string, value: any) {
    if (!value && !key) return
    value = window.btoa(JSON.stringify(value))
    // value = utf8_to_b64(value)
    window.sessionStorage.setItem(key, value)
}

export function getSessionStorage(key: string) {
    const value = window.sessionStorage.getItem(key)
    // if (value == null || value == undefined) return removeToken()
    // value = b64_to_utf8(value)
    if (!value) return null
    return JSON.parse(window.atob(value))
}

export function removeSessionStorage(key: string) {
    window.sessionStorage.removeItem(key)
}

export function clearSessionStorage() {
    window.sessionStorage.clear()
}