import { defineComponent as _defineComponent } from 'vue'
import { roleList, roleRemove } from '@/api/System/role'
import { ElMessage } from 'element-plus'
import { ref, onMounted } from 'vue'
import vDialog from './components/dialog.vue'

export default _defineComponent({
  expose: [],
  setup(__props) {

const loading = ref(false)
const btnRemoveLoading = ref(false)
const limit = ref(10)
const page = ref(1)
const total = ref(0)
const list = ref<any[]>([])
const dialogRef = ref()
const reData = async (re?: boolean) => {
  if (re) page.value = 1
  try {
    loading.value = true
    let rqData: any = { page: page.value, limit: limit.value }
    const { result, totalCount } = await roleList(rqData)
    total.value = totalCount
    list.value = result
  } finally {
    loading.value = false
  }
}
onMounted(() => {
  reData()
})
const onPageChange = (current: number) => {
  page.value = current
  reData()
}
const handleOpen = (row: any) => {
  dialogRef.value.open(row)
}
const handleLimitChange = (size: number) => {
  if (limit?.value != undefined) limit.value = size
  reData(true)
}
const handleRomve = async (index: number, tweetid: string) => {
  try {
    btnRemoveLoading.value = true
    const res = await roleRemove({ tweetid })
    list.value.splice(index, 1)
    ElMessage.success(res)
  } finally {
    btnRemoveLoading.value = false
  }
}

return { loading, btnRemoveLoading, limit, page, total, list, dialogRef, reData, onPageChange, handleOpen, handleLimitChange, handleRomve, roleList, roleRemove, ElMessage, ref, onMounted, vDialog }
}

})