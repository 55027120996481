
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentorderOfflineinvest,
  investmentorderUpdate,
} from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
import { userList } from "@/api/User/index"
import { userRoleList } from '@/api/User/role'
let index: any; // 编辑的index
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {

    const visible = ref(false);
    const activeStep=ref(1);
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const orderId = ref("");
    const investortype = ref("user");
    const userArray = ref<any>([]);
    const roleArray = ref<any>([]);
    const userDataRef = ref<any>({});
    const selfDataRef = ref<any>({});
    const selectDataRef = ref<any>({});
    const platformDataRef = ref<any>({});
    const userData = ref({
      phone:'',
      name:'',
      investoruserid:'',
    });
    const selectData = ref({
      articleproductid: "56c62a11a6a14d6eb7ab29fbf9f2f8b4",
      launchmode: "PLATFORM",
      investortype: "user",
      fortest: false,
      freegift: false,
      investoruserid: "",
    });
    const selfData = ref({
      mailingcontact: "",
      mailingnumber: "",
      mailingaddress: "",      
    });
    const platformData = ref({
      articlenum: 10,
      singleamount: 399.0,
      investorproportion: 50,
    });
    const rules = readonly({
      mailingcontact: [Rules.required()],
      mailingnumber: [Rules.required()],
      mailingaddress: [Rules.required()],
      articlenum: [Rules.required()],
    });

    const open = (row: any, _index: number) => {
      visible.value = true;
      if (!row) return;
      const {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,

        mailingcontact,
        mailingnumber,
        mailingaddress,
        investoruserid,
        articlenum,
        singleamount,
        investorproportion,
        orderid,
      } = row;
      selectData.value = {
        articleproductid,
        launchmode,
        investortype,
        fortest,
        freegift,
        investoruserid,
      };
      selfData.value = {
        mailingcontact,
        mailingnumber,
        mailingaddress       
      };
      platformData.value = {
        articlenum,
        singleamount,
        investorproportion,
      };
      orderId.value = orderid;
      index = _index;
    };
    const onCheckInvestor = async () => {
      if(userData.value.phone&&userData.value.phone!=''){
        checkInvestorLoading.value=true;
        userData.value.name='';
        userArray.value=[];
        roleArray.value=[];
        getUserInfo(userData.value.phone)
      }else{
        ElMessage.success("请先输入投资人账号");          
      }
    };

    const handleClose = async () => {
      selfDataRef.value.resetFields();
      selfDataRef.value.clearValidate();
      platformDataRef.value.resetFields();
      platformDataRef.value.clearValidate();
      userDataRef.value.resetFields();
      userDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
      index = "";
      activeStep.value=1;
      userArray.value=[];
      roleArray.value=[];
    };

    const onNextClick = async () => {
        if(activeStep.value==1){
          if(investortype.value == "user"){
            if(userData.value.name&&userData.value.name!=''){
              activeStep.value=2
            }else{
              ElMessage.success("请先查询需要下单的投资人账号");          
            }
          }else{
            userData.value.name='平台'
            userData.value.phone='16673103969'
            userData.value.investoruserid='980e6462ee785a6e9842d1e553f67755'
            activeStep.value=2
          }
         
        }else if (activeStep.value==2){
          activeStep.value=3;
        }
    };
    
    const onPrevClick = async () => {
       if(activeStep.value==3){
          activeStep.value=2
        }else if (activeStep.value==2){
          activeStep.value=1;
        }
    };

    
    const getUserInfo = async (account: string) => {
      try {
       
        let rqData: any = { page: "1", limit: "10" ,account:account};
       
        const res = await userList(rqData);
        userArray.value=res.result;
        const userid=userArray.value[0].userid;
        getUserRole(userid,account);
        
      } catch(err){ 
        checkInvestorLoading.value=false;
        ElMessage.success("查询失败，请确认投资人账号是否正确");
        
      }
    };

    const getUserRole = async (userid: string,phone:string) => {
      try {
       let rqData: any = { page: "1", limit: "10" ,investoruserid:userid};

       const res = await userRoleList(rqData, 'investor')

       roleArray.value = res.result;

       userData.value.name=roleArray.value[0].investorname;
       userData.value.investoruserid=roleArray.value[0].investoruserid;
       selectData.value.investoruserid=userid;
       checkInvestorLoading.value=false;
      }catch(err){ 
        checkInvestorLoading.value=false;
        ElMessage.success("查询失败，请确认账号是否有投资人身份");
        
      }
    }

    const submit = async () => {
      try {
        const rqData = {
          ...platformData.value,
          ...selectData.value,    
          investmode: "offline",
          salesmanuserid: userStore.info.userid,
        };
        const submitDate = selectData.value.launchmode == "SELF"?{...rqData,...selfData.value}:{...rqData};
        btnSumitLoading.value = true;
        const res = orderId.value
          ? await investmentorderUpdate({ ...submitDate, orderid: orderId.value })
          : await investmentorderOfflineinvest(submitDate);
        ElMessage.success(res.message);
        handleClose();
        emit("success", {
          isEdit: !!orderId.value,
          data: res.record,
          index,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeArticletype = (e: string) => {  

    };
    const changeInvestortype = (e: string) => {
      if (e == "platform") {
        selectData.value.freegift = false;
        selectData.value.investoruserid = '';
      }
    };
    return {
      activeStep,
      rules,
      userData,
      selectData,
      platformData,
      selfData,
      visible,
      btnSumitLoading,
      checkInvestorLoading,
      open,
      userDataRef,
      selectDataRef,
      selfDataRef,
      platformDataRef,
      onCheckInvestor,
      submit,
      handleClose,
      onNextClick,
      onPrevClick,
      changeArticletype,
      changeInvestortype,
      investortype,
    };
  },
});
