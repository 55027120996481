import { defHttp } from "@/utils/http/axios";
import type { Optional2Required } from "@/types/utils/index"
import type { UserInfo, UserRole } from "@/types/user"
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"

export interface InvestorListListRes {
    creationdate: string,
    value?: string
    investorname: string
    lastlogindate: string
}
export async function userList(params: ListReq): Promise<ListRes<InvestorListListRes>> {
    console.log('params :>> ', params);
    params.token = true
    const res = await defHttp.request<ListRes<InvestorListListRes>>(
        {
            url: `/user/list`,
            params
        }
    );
    res.result = res.result.map(v => {
        v.lastlogindate = dayjs(v.lastlogindate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}



export interface WalletType {
    amount: number
    frozenamount: number
    name: string
    fortest: boolean
    articleproductid: string
    articleproductmodel: "M4S2" | "UM01" | "XMJ"
    articleproductname: string
    balance: number
}
export interface AllWallets {
    M4S2: WalletType,
    UM01: WalletType,
    XMJ: WalletType,
}

export async function userconsumewalletAll(userid: string): Promise<{ userAllWallets: AllWallets, userAllWalletsTest: AllWallets }> {
    const res = await defHttp.request<ListRes<WalletType[]>>(
        {
            url: `/userconsumewallet/infolist`,
            params: {
                "current": 1,
                "entity": {
                    userid
                },
                "size": 20,
                token: true
            }
        }, {
        prefix: "/shared"
    }
    );
    if (!res.records) throw Error("获取钱包信息失败" + res.message)
    const userAllWallets: any = {}
    const userAllWalletsTest: any = {}
    res.records.map((v: any) => {
        switch (v.articleproductmodel) {
            case 'XMJ':
                v.name = "小魔夹"
                break;
            case 'M4S2':
                v.name = `新小魔夹${v.fortest ? "测试" : ''}`
                break;
            case 'UM01':
                v.name = `雨伞钱包${v.fortest ? "测试" : ''}`
                break;
            default:
                v.name = `${v.articleproductname}钱包${v.fortest ? "测试" : ''}`
                break;
        }
        v.amount = parseFloat(v.amount || 0)
        v.frozenamount = parseFloat(v.frozenamount || 0)
        v.balance = v.amount - v.frozenamount
        const _data = {
            amount: v.amount,
            frozenamount: v.frozenamount,
            name: v.name,
            fortest: v.fortest,
            articleproductid: v.articleproductid,
            articleproductmodel: v.articleproductmodel,
            articleproductname: v.articleproductname,
            balance: v.balance,
        }
        if (v.fortest) {
            userAllWalletsTest[v.articleproductmodel] = _data
        } else {
            userAllWallets[v.articleproductmodel] = _data
        }
    })
    return { userAllWallets, userAllWalletsTest }
}


interface loginRes {
    expiretime: number;
    token: string;
    user: Optional2Required<UserInfo>
}
interface loginParams {
    username: string;
    password: string;
    from:string
}
export async function login({ username, password, from }: loginParams): Promise<loginRes> {
    try {
        const res = await defHttp.request<loginRes>(
            {
                url: `/login`,
                method: "POST",
                params: {
                    username,
                    password,
                    from
                },
            },
            {
                prefix: "/login",
                joinParamsToUrl: true,
            }
        );
        return res
    } catch (error) {
        return Promise.reject(new Error(error));
    }
}

export async function getUserInfo(userid: string | boolean = true): Promise<UserInfo> {
    const res = await defHttp.request<RequestRes<UserInfo>>(
        {
            url: `/user/get`,
            params: {
                userid: userid,
                token: true,
            },
        }
    );
    if (!res.entity) throw Error(res.msg)
    const { entity } = res
    entity.lastlogindate = dayjs(entity.lastlogindate).format('YYYY-MM-DD HH:mm:ss')
    entity.isvip=entity.isvip?"是":'否'
    entity.sex=entity.sex=='0'?'男':'女'
    if (typeof entity.roleArray == 'string') entity.roleArray = JSON.parse(entity.roleArray)
    return entity
}

export async function changeRole(userroleid?: string): Promise<string | undefined> {
    try {
        if (!userroleid) throw Error('请输入 userroleid')
        const res = await defHttp.request<RequestRes<unknown>>(
            {
                url: `/userrole/concur/switch`,
                method: "POST",
                params: {
                    userroleid,
                    token: true,
                },
            }
        );
        if (res.code != 200) throw Error(res.message || '切换角色失败')
        return res.message
    } catch (error) {
        console.log('error :>> ', error);
        throw Error(error)
    }
}

export async function getUserAllRole(params: { userid: string, token?: string }, limit = 20): Promise<UserRole[]> {
    const res = await defHttp.request<ListRes<UserRole>>(
        {
            url: `/userrole/list`,
            params: {
                page: 1,
                limit: limit,
                userid: params.userid,
                token: params.token ? params.token : true
            },
        }
    );
    if (res.totalCount && res.totalCount > limit) {
        return await getUserAllRole(params, res.totalCount)
    }
    return res.result
}

export async function replaceUserPhone(userId?: string, newAccount?:string): Promise<string | undefined> {
    try {
        const res = await defHttp.request<RequestRes<unknown>>(
            {
                url: `/user/changeaccountbymanager`,
                method: "POST",
                params: {
                    userId,
                    newAccount,
                    token: true,
                },
            },{
                
                contentType:'json'
            }
        );
        if (res.code != 200) throw Error(res.msg || '更换手机号失败')
        return res.msg
    } catch (error) {
        console.log('error :>> ', error);
        throw Error(error)
    }
}