
import { defineComponent, ref, readonly, inject } from 'vue'
import { faultListModel, faultListHttp ,faultComfirmHttp, faultCompleteHttp, faultRevocationHttp, faultRejectHttp,exportEqbreakdownInfo} from '@/api/fault/index'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import comfirmDialog from './components/comfirmDialog.vue'
import completeDialog from "./components/completeDialog.vue"
import detailsDrawer from './components/detailsDrawer.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import dayjs from 'dayjs'
export default defineComponent({
  name: 'fault',
  components: {
   comfirmDialog,
   completeDialog,
   detailsDrawer,
   exportDialog,
  },
  setup() {
    const detailsDrawerRef = ref<any>()
    const comfirmDialogRef = ref<any>()
    const completeDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const mixin_router_search = ref({
      status: '', //状态 0:待入库 1：已入库 2：已出库
      careateBN:'',
      needMaintain:'',
      order: 'gmtCreate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        equipmentSn:'', //设备sn
        submitUserAccount:'',//上报手机号    
        submitUserName:'',//上报昵称   
        sharedOrderId:'',
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        equipmentSn: '小魔夹SN', 
        submitUserAccount: '上报账号', 
        submitUserName:'上报昵称',
        sharedOrderId:'订单id'
      },
    })
    const searchSelect = ref({search: 'equipmentSn'})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<faultListModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { current: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          if (rq.careateBN) {
            rq.startGmtCreate = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endGmtCreate = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await faultListHttp(rqData)
        list.value = res.rows
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
   
    const handleConfirm = async(idx:number,item:any) =>{
       comfirmDialogRef.value.open(item);
        // await ElMessageBox.confirm(
        //   `确认设备`+item.equipmentSn+'为故障状态',
        //   '提示',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //     distinguishCancelAndClose: true,
        //   }
        // )
        // const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        // const desc = userStore.userInfo.account+'确认为故障状态'
        // const res = await faultComfirmHttp({
        //   breakdownId:item.id,
        //   excuteInfo:desc,
        //   remark:time+'确定为故障状态'
        // })
        // mixinReRouter()
        // ElMessage.success(res)
    }

    const handleComplete = async(idx:number,item:any) =>{
       completeDialogRef.value.open(item);
        // await ElMessageBox.confirm(
        //   `完结设备`+item.equipmentSn+'的故障',
        //   '提示',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //     distinguishCancelAndClose: true,
        //   }
        // )
        // const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        // const desc = userStore.userInfo.account+'确认为故障状态'
        // const res = await faultCompleteHttp({
        //   breakdownId:item.id,
        //   excuteInfo:desc,
        //   remark:time+'完结故障'
        // })
       
        // // list.value[idx].status=3;
        // // console.log("消息回调")
        // mixinReRouter()
        // ElMessage.success(res)
       
    }

    const handleRevocation = async(idx:number,item:any) =>{
      await ElMessageBox.confirm(
          `撤销设备`+item.equipmentSn+'的故障',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const res = await faultRevocationHttp({
          breakdownId:item.id,
          excuteInfo:{},
          remark:time+'撤销故障'
        })
        mixinReRouter()
        ElMessage.success(res)
       
    }
  
    const handleReject = async(idx:number,item:any) =>{
      const desc = userStore.userInfo.account+'驳回故障'
      await ElMessageBox.confirm(
          `驳回设备`+item.equipmentSn+'的故障',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
        const res = await faultRejectHttp({
          breakdownId:item.id,
          excuteInfo:desc,
          remark:time+'驳回故障'
        })
        // list.value[idx].status=4;
        // reload();
        mixinReRouter()
        ElMessage.success(res)
    }

    const handleDetails = async(idx:number,item:any) =>{
      detailsDrawerRef.value.open(item)
    }
   
    const handleExport = async() => {
      exportDialogRef.value.open('故障记录表')

    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'eqbreakdown',order: 'gmtCreate',sort: 'desc'}
        let entity: any = {}
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        if (searchData.careateBN) {
          searchData.startGmtCreate = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          searchData.endGmtCreate =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        console.log('导出参数=',searchData)
        
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            entity[key]=searchData[key]
          }
        })
        console.log('遍历之后=',entity)
        
        rqData = { ...rqData, entity: {...entity} }

        console.log('请求参数=',rqData)
       

        const res = await exportEqbreakdownInfo(rqData)
        console.log('导表=',res.data)
        const blob = new Blob([res.data]);
        console.log('blob的值=',blob)
        saveAs(blob, name)
       
      } finally {
       
      }
    }

    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      changSearch,
      list,
      loading,
      detailsDrawerRef,
      comfirmDialogRef,
      completeDialogRef,
      exportDialogRef,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      handleConfirm,
      handleComplete,
      handleRevocation,
      handleReject,
      handleDetails,
      handleExport,    
      exportFile 
    }
  },
})
