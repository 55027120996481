import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
export interface InvestmentordershipmentListRes {
    status: string
}
export async function investmentordershipmentList(params: ListReq): Promise<ListRes<InvestmentordershipmentListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<InvestmentordershipmentListRes>>(
        {
            url: `/investmentordershipment/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    return res
}
export interface InvestmentorderarticleListRes {
    status: string
}
export async function investmentorderarticleList(params: ListReq): Promise<ListRes<InvestmentorderarticleListRes>> {
    params.token = true
    const res = await defHttp.request<ListRes<InvestmentorderarticleListRes>>(
        {
            url: `/investmentorderarticle/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    return res
}
export async function investmentorderarticleRemove(params: RequestReq): Promise<string | undefined> {
    params.token = true
    const res = await defHttp.request<RequestRes<InvestmentorderarticleListRes>>(
        {
            url: `/investmentorderarticle/remove`,
            params
        }, {
        prefix: "/shared"
    }
    );
    return res.message
}
export async function singleassign<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentordershipment/article/singleassign`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData
}

export async function outwarehouse<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentordershipment/outwarehouse`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData
}
export async function startlaunch<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/investmentordershipment/startlaunch`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    );
    return reData
}


//创建没有投资订单的出货单
export async function addNoInvestorOrderShipmentHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/investmentordershipment/create`,
            params: {
                token: true,
                ...parmas 
            },
        }, 
        {
            prefix: "/shared",
        }
    );
    return res.msg || "添加成功"
}

//小魔夹分配到出货单
export async function singleassignXmjHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/investmentordershipment/equipmentassign`,
            params: {
                token: true,
                ...parmas 
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res.msg || "添加成功"
}


//包装箱分配到出货单
export async function singleassignBoxHttp(parmas: RequestReq): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/investmentordershipment/whboxassign`,
            params: {
                token: true,
                ...parmas 
            },
        }, 
        {
            prefix: "/shared",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        }
    );
    return res.msg || "添加成功"
}

export async function createshipmentNew<T>(params: T) {
    const reData = await defHttp.request<RequestRes>(
        {
            url: `/whpurchase/ship`,
            params: {
                token: true,
                ...params
            }
        }, {
        prefix: "/shared"
    }
    );
    return reData.msg
}