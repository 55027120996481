
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import {
  getShareDeviceList,
  DeviceListRes,
  holdertype,
} from "@/api/Share/Device";
import { userList } from "@/api/User/index";
import addDialog from "./components/addDialog.vue";
import componentsDialog from "./components/componentsDialog.vue";
import showUserInfoDialog from "./components/showUserInfoDialog.vue"
export default defineComponent({
  name: "Name",
  components: {
    "v-add-dialog": addDialog,
    "v-components-dialog": componentsDialog,
    "v-show-user-info-dialog":showUserInfoDialog,
  },
  setup() {
    const loading = ref(false);
    const btnRemoveLoaidng = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const allHoldertype = holdertype;
    const list = ref<DeviceListRes[]>([]);
    const userInfoList=ref<any>();
    const addDialogRef = ref<any>();
    const showUserInfoDialigRef = ref<any>();
    const componentsDialogRef = ref<any>();
    const searchSelect = ref({
      search: "articleequipmentsn",
    });
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: "",
        holderpanelequipmentsn: "",
        phone:'',
        investoruserid: "",      
        holderuserid: "",
        investmentorderid: ""    
      },
    });

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: "设备SN",
        holderpanelequipmentsn: "机柜SN",
        phone:"投资人帐号",
        investoruserid: "投资人ID",
        holderuserid: "用户ID",       
        investmentorderid: "订单号"
      },
    });
    const checkUser = (row:any)=>{
      showUserInfoDialigRef.value.open(row)
    };
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");

    const mixin_router_search = ref({
      socketstatus: "",
      productname: "",
      productid: '56c62a11a6a14d6eb7ab29fbf9f2f8b4', //设备类型
      lockcmd: "",
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, entity: rq };
        }
        const res = await getShareDeviceList(rqData);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
        mixin_select_search.value.search.phone="";
      }
    };

    const getUserInfo = async (account: string) => {
      try {
       
        let rqData: any = { page: "1", limit: "10" ,account:account};
       
        const res = await userList(rqData);
        
        console.log('用户信息')
        console.log(res)
        userInfoList.value = res.result;
        mixin_select_search.value.search.investoruserid=userInfoList.value[0].userid;
        
      } finally {
        console.log('用户信息加载完成')
        mixinReRouter();
      }
    };
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });

    const getRelData = () =>{
      console.log("筛选内容");
      console.log(mixin_select_search.value)
      if(mixin_select_search.value.search.phone){
        getUserInfo(mixin_select_search.value.search.phone);
      }else{
        mixinReRouter();
      }
     
    }

    const handelChange = () => {
      page.value = 1;
      getRelData();
       //mixinReRouter();
    };
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row);
    };
    const addSuccess = (data: { index: number; data: DeviceListRes }) => {
      if (typeof data.index == "number" && data.index > -1) {
        list.value[data.index] = data.data;
      } else {
        list.value.push(data.data);
      }
    };

    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn);
    };
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleEidt,
      checkUser,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      componentsDialogRef,
      showUserInfoDialigRef,
      handleShowComponenet,
      toPath_RouterSearchMixin,
      changSearch,
      allHoldertype,
    };
  },
});
