
   import { defineComponent, ref} from "vue";
   import { getShareProductInfo, getProductInfoList,getProductIdList } from '@/api/util'
   import{purchaseModel, purchaseList, removePurchase, accextPurchase} from "@/api/Investment/purchase"
   import addPurchaseDialog from "./addPurchaseDialog.vue"
   import shipmentDialog from './shipmentDialog.vue'
   import shipmentOldDialog from '@/views/investment/components/shipmentDialog.vue'
   import accectPurchaseDialog from "./accectPurchaseDialog.vue"
   import { ElMessage, ElMessageBox } from "element-plus";
   import { userStore } from '@/store/modules/user'
   let investorOrderId="";
   let articlenum =0;
   export default defineComponent({
     name: "v-dialog",
     components: {
      addPurchaseDialog,
      'v-shipment-dialog':shipmentDialog,
      accectPurchaseDialog,
      shipmentOldDialog,
     },
     setup() {
      const name = ref("");  
      const visible = ref(false);
      const activeName = ref("");
      const loading = ref(false);
      const status = ref("");
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const list = ref<purchaseModel[]>([]);
      const sheareProductInfo = getShareProductInfo()
      const productList = getProductInfoList()
      const productIdList = getProductIdList()
      const addPurchaseDialogRef = ref<any>();
      const shipmentDialogRef = ref<any>()
      const shipmentOldDialogRef = ref<any>()
      const accectPurchaseDialogRef = ref<any>();
      const open = (_name:string,_investmentorderid:string,_articlenum:number) =>{
        visible.value = true;
        name.value=_name;
        investorOrderId=_investmentorderid;
        articlenum=_articlenum;
        reData();
       }
   
       const reData = async() => {
         try {
           loading.value = true;
           let resData: any = {current: page.value,size: limit.value,order:"gmtCreate",sort:"desc"}
           let entity: any = {investmentOrderId:investorOrderId}
           if(status.value){
            entity.status=status.value
           }
           resData={...resData,entity:{...entity}}
           const res = await purchaseList(resData);
           console.log('请求列表='+res)
           list.value = res.rows
           total.value = res.total
         } finally {
           loading.value = false;
         }
       }
   
       const handleClose = () =>{
         visible.value = false;
       }
   
       const handelChange = (val:any) =>{
        page.value=1;
        reData();
       }
       
       
       const handleAddNewPurchase = async() => {
         try {
          addPurchaseDialogRef.value.open(investorOrderId);
         } catch (error) {
           if (error == 'cancel') return
           if (error == 'colse') return
        //    ElMessage.error(error)
         }
       }
      
       const onPageChange = (_page:number) =>{
           page.value=_page
           reData();
       }
       const handleLimitChange = (_size:number) =>{
           limit.value=_size
           reData();
       }
       const handleSuccess = async (msg:string) =>{
         ElMessage.success(msg)
         page.value=1;
         reData();
       }
       const handlerShipment = (item:any) =>{
        shipmentDialogRef.value.open(name.value,investorOrderId,item.id,item.quantity);
       }
       const handlerAccept = async (item:any) =>{
        await ElMessageBox.confirm(
          `是否接收采购单id=${item.id},接收账号是${userStore.info.account}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await accextPurchase({ id :item.id })
        page.value=1;
        reData();
        ElMessage.success(res.msg)
       }
       const handlerOldShipment = () =>{
        shipmentOldDialogRef.value.open(name.value,investorOrderId,articlenum);
       }
       const handlerDelete = async(item:any) =>{
        
        await ElMessageBox.confirm(
          `是否删除采购单id=${item.id}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )

        const res = await removePurchase({ id :item.id })
        page.value=1;
        reData();
        ElMessage.success(res.msg)
       }
       return {
         name,
         visible,
         status,
         activeName,
        
         limit,
         page,
         total,
         list,
         loading,
         addPurchaseDialogRef,
         sheareProductInfo,
         productList,
         productIdList,
         shipmentDialogRef,
         shipmentOldDialogRef,
         accectPurchaseDialogRef,
         open,
         reData,
         handleAddNewPurchase,
         handleClose,
         handelChange,
         onPageChange,
         handleLimitChange,
         handleSuccess,
         handlerShipment,
         handlerOldShipment,
         handlerDelete,
         handlerAccept,
       }
     }
   })    
   
   