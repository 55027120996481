<!-- 卡片背模板 -->
<template>
  <div :style="{height:height}" class="container">
    <div class="box">
      <div class="title">
        <select-item v-if="selectItem" :selectItem="selectItem" :selectItems="selectItems" isBig="isBig" @change="selectTitleChange" :type="noType?'':cardData.title"></select-item>
        <div v-else>{{cardData.title}}</div>
      </div>
      <div class="center">
        <slot name="center"></slot>
      </div>
      <div class="footer1" key="footer1" v-if="cardData.type==1">
        <div @click="box1Click" class="lbox">
          <div class="label">{{cardData.box1.label}}</div>
          <div class="value">{{cardData.box1.value}}</div>
        </div>
        <div @click="box2Click" class="rbox">
          <div class="label">{{cardData.box2.label}}</div>
          <div class="value">{{cardData.box2.value}}</div>
        </div>
      </div>
      <footer class="footer2" key="footer2" v-if="cardData.type==2">
        <div class="tbox">{{cardData.header}}</div>
        <div class="bbox">
          <div class="blbox">{{cardData.label}}</div>
          <div @click="box1Click" class="brbox">{{cardData.value}}</div>
        </div>
      </footer>
      <footer class="footer3" key="footer3" v-if="cardData.type==3">
        <div class="tbox">{{cardData.label}}</div>
        <div @click="box1Click" class="bbox">{{cardData.value}}</div>
      </footer>
    </div>
  </div>
</template>

<script>
import selectItem from "./selectItem.vue";
export default {
  props: {
    cardData: {
      type: Object,
      default: () => {
        return {
          type: 1,
          title: "XXXXX",
          box1: {
            label: "XXXXX",
            value: 132,
          },
          box2: {
            label: "XXXXX",
            value: 132,
          },
        };
      },
    },
    selectItem: {
      type: Object
    },
    selectItems: {
      type: Array
    },
    noType: {
      type: Boolean
    },
    height: {
      type: String,
      default: "200px",
    },
  },
  components: {
    selectItem
  },
  emits: ['selectTitleChange', 'box1-click', 'box2-click'],
  methods: {
    box1Click() {
      this.$emit("box1-click");
    },
    box2Click() {
      this.$emit("box2-click");
    },
    selectTitleChange(e) {
      this.$emit("selectTitleChange", e)
    }
  },
};
</script>
<style lang='scss' scoped>
.container {
  background: url("../../../../assets/img/index/rboxbg.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 350px;
  color: #00f4f4;
  .box {
    padding: 6px;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    .title {
      padding: 14px 0;
      font-size: 18px;
      font-weight: blod;
      text-align: center;
    }
    .center {
      width: 100%;
    }
    .footer1,
    .footer2,
    .footer3 {
      font-size: 14px;
      height: 40px;
      .tbox.label {
        line-height: 20px;
        height: 20px;
      }
    }
    .footer1 {
      // padding: 0.01rem 0.031rem;
      display: flex;
      justify-content: space-between;
      text-align: center;
      .lbox,
      .rbox {
        cursor: pointer;
        width: 50%;
        margin: 0 2px;
        background: #002634;
        .value {
          //   margin-top: 0.02rem;
          //   height: 0.2rem;
          //   line-height: 0.2rem;
          color: #ff6f3e;
          font-size: 12px;
        }
      }
      .rbox {
        .value {
          color: #efefef;
        }
      }
    }
    .footer2 {
      background: #002634;
      text-align: center;
      margin: 0 2px;
      .bbox {
        // display: flex;
        position: relative;
        .blbox {
          cursor: pointer;
          position: absolute;
          top: 0.02rem;
          left: 0.073rem;
        }
        .brbox {
          cursor: pointer;
          color: #ff6f3e;
          font-size: 0.104rem;
        }
      }
    }
    .footer3 {
      background: #002634;
      margin: 0 2px;
      text-align: center;
      .bbox {
        cursor: pointer;
        color: #ff6f3e;
        font-size: 0.104rem;
      }
    }
  }
}
</style>
<style scoped>
/** 插入过程 **/
.footer-enter-active {
  transition: all 1s;
}
/** 移除过程 **/
.footer-leave-active {
  transition: all 1s;
}
/*** 开始插入、移除结束的位置变化 ***/
.footer-enter,
.footer-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>