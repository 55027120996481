<!--微信转账结果-->
<template>
  <div class="container">
  
    <el-table
      :data="list"
      :default-sort="{prop: 'date', order: 'descending'}"
      height="80vh"
      style="width: 100%"
      v-if="list"
    >
      <!-- <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="用户ID">
              <el-button @click="toPath_RouterSearchMixin('/user',[{search_userid:scope.row.userid}])" type="text">{{ scope.row.userid }}</el-button>
              <my-copy :data="scope.row.userid" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="收款人" min-width="55">
        <template #default="scope">
          <span>{{scope.row.reusername}}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="描述" min-width="44">
        <template #default="scope">
             {{scope.row.desc}}
        </template>
      </el-table-column>
      <el-table-column align="left" label="金额" min-width="44">
        <template #default="scope">
            ¥ {{scope.row.amount}} 分
        </template>
      </el-table-column>
      <el-table-column align="center" label="到账结果" min-width="44">
        <template #default="scope">
            <el-tag type="success" v-if="scope.row.resultcode=='SUCCESS'">成功</el-tag>
            <el-tag type="warning" v-else>失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="left" label="失败原因" min-width="66">
        <template #default="scope">
            {{scope.row.errcodedes}} 
        </template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="center" label="创建时间" min-width="55" prop="creationdate" sortable="custom"></el-table-column>
      <el-table-column align="center" label="到账时间" min-width="55" prop="paymenttime"></el-table-column>
     
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import {
  WechatMerchantTransfer,
  getWechatMerchantTransfer,
} from '@/api/Financial/wechatmerchanttransfer'

export default defineComponent({
  setup() {
    const loading = ref(false)
    const btnEnabledLoaidng = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const reusername = ref('')
    const list = ref<any[]>([])
  
  
    const reData = async (name:string) => {
      reusername.value=name;
      try {
        loading.value = true
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value,order:"creationdate",sort:"desc" }
        if (name!=null&&name!="") {
          rqData = { ...rqData, entity: {reusername:name}}
        }
        const res = await getWechatMerchantTransfer(rqData)
        console.log('res.records :>> ', res.records)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
   
    const handelChange = () => {
      page.value = 1
      
    }

    const onPageChange = () =>{
      console.log('页数改变',page.value)
      if(page.value<total.value){
        page.value++
        reData(reusername.value);
      }
    }

    const handleLimitChange = () => {
      reData(reusername.value);
    }
    return {
      list,
      loading,
      page,
      total,
      limit,
      reData,
      handelChange,
      handleLimitChange,
      onPageChange,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

  .el-select{
    width: 130px;
  }
}
</style>