
import { UserInfo } from "@/types/user";
import { AllWallets, userconsumewalletAll, getUserInfo } from "@/api/User";
import { defineComponent, ref,onMounted, toRef} from "vue"
import { toOptional } from "@/types/utils";
export default defineComponent({
  props: ["userid"],
  setup(props){
    const userInfo = ref<toOptional<UserInfo>>({});
    const isHover = ref(false);
    const userid = toRef(props, "userid");
    const reData = async () => {
      try {
       
        console.log("userid :>> ", userid);
        userInfo.value = await getUserInfo(userid.value);
        // const res = await userconsumewalletAll(userid.value);
        // console.log("res :>> ", res);
        // walletInfo.value = res.userAllWallets;
        // walletInfoTest.value = res.userAllWalletsTest;
      } finally {
        //loading.value = false;
      }
    };
    onMounted(() => {
      reData();
    });
    // const route = useRoute()
    // return{
    //   userid:route.query.userid
    // }
    return {
      userInfo,
      isHover,
    }
  }
})
