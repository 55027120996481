<template>
  <el-dialog :destroy-on-close="true" title="设置最新固件版本" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    
    <el-form :model="inputData" :rules="rules" label-width="120px" ref="formRef" style="margin-top: 20px;">
      <el-form-item label="版本号" prop="msg">
        <el-input  v-model="inputData.msg" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, readonly } from 'vue'
import Rules from "@/common/Rules";
let productId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})

    const inputData = ref({
      msg: "",
    })

    const rules = readonly({
      msg: [Rules.required()]
    });
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async (_productId:string) => {
      productId=_productId;
      visible.value = true
    }
   
    const submit = async () => {
      const oneNetMsg = inputData.value.msg;
      console.log('消息传送之前=',oneNetMsg)
      emit('success',productId,oneNetMsg)
      handleClose()
    }
    return {
      visible,
      handleClose,
      open,
      rules,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>