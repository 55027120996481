<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" placeholder="平台投放开始状态" class="header-el-selector" v-model="mixin_router_search.type">
        <el-option label="测试员" value="tester"></el-option>
        <el-option label="代理人" value="agent"></el-option>
        <el-option label="投资人" value="investor"></el-option>
        <el-option label="运营员" value="operator"></el-option>
        <el-option label="城市合伙人" value="partner"></el-option>
        <el-option label="销售员" value="salesman"></el-option>
        <el-option label="维修员" value="repairman"></el-option>
        <el-option label="出货员" value="shipper"></el-option>
        <el-option label="调度员" value="dispatcher"></el-option>
        <el-option label="平台售后" value="postsale"></el-option>
        <el-option label="工厂维修员" value="factoryrepairman"></el-option>
        <el-option label="现场维修员" value="fieldrepairman"></el-option>
      </el-select>
      <el-button @click="handleActivate" icon="el-icon-plus" type="primary">添加</el-button>
    </header>
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column label="姓名" min-width="30">
        <template #default="scope">
          <my-copy :data="scope.row[`${mixin_router_search.type}name`]||scope.row.name"></my-copy>
          {{scope.row[`${mixin_router_search.type}name`]||scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column label="账号" min-width="40">
        <template #default="scope">
          <my-copy :data="scope.row[`${mixin_router_search.type}account`]||scope.row.account"></my-copy>
          {{scope.row[`${mixin_router_search.type}account`]||scope.row.account}}
        </template>
      </el-table-column>
      <el-table-column label="ID" min-width="60">
        <template #default="scope">
          <my-copy :data="scope.row[`${mixin_router_search.type}userid`]||scope.row.userid"></my-copy>
          {{scope.row[`${mixin_router_search.type}userid`]||scope.row.userid}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="50" prop="creationtime"></el-table-column>
      <el-table-column label="更新时间" min-width="50" prop="modificationtime"></el-table-column>
      <el-table-column align="right" label="操作" min-width="60" v-if="mixin_router_search.type=='tester'">
        <template #default="scope">
          <el-button @click="handleRechange(scope.row)" size="mini" type="primary">测试钱包充值</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-activate-dialog :type="mixin_router_search.type" ref="activateDialogRef"></v-activate-dialog>
    <test-wallet-rechange-dialog ref="testWalletRechangeDialogRef"></test-wallet-rechange-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, nextTick } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { userRoleList } from '@/api/User/role'
import testWalletRechangeDialog from './components/testWalletRechangeDialog.vue'
import activateDialog from './components/activateDialog.vue'
export default defineComponent({
  name: 'Name',
  components: {
    'v-activate-dialog': activateDialog,
    testWalletRechangeDialog,
  },
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const activateDialogRef = ref<any>()
    const list = ref<any>([])
    const testWalletRechangeDialogRef = ref<any>()
    const searchSelect = ref({ search: 'name' })
    const mixin_router_search = ref({ status: '', type: 'tester' })
    const mixin_select_search = ref({
      search: {
        name: '',
        account: '',
        userid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        name: '姓名',
        account: '账号',
        userid: 'ID',
      },
    })
    const reData = async (rq?: any) => {
      await nextTick()
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.name) {
            rq[`${mixin_router_search.value.type}name`] = rq.name
            delete rq.name
          }
          if (rq.account) {
            rq[`${mixin_router_search.value.type}account`] = rq.account
            delete rq.account
          }
          if (rq.userid) {
            rq[`${mixin_router_search.value.type}userid`] = rq.userid
            delete rq.userid
          }
          rqData = { ...rqData, ...rq }
        }
        const res = await userRoleList(rqData, mixin_router_search.value.type)
        total.value = res.totalCount
        list.value = res.result
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleActivate = () => {
      activateDialogRef.value.open(mixin_router_search.value.type)
    }
    const handleRechange = (row: any) => {
      testWalletRechangeDialogRef.value.open(row)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleActivate,
      activateDialogRef,
      changSearch,
      handleRechange,
      testWalletRechangeDialogRef,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
 .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>