<template>
  <div class="login-container">
    <div :class="device=='desktop'?'content':'min-content'">
      <div class="login_box">
        <div class="text-box"> 
          <el-image :src="require('@/assets/img/text_logo.png')" style="width: 70%;" fit="scale-down"></el-image>
        </div>
        <div class="login-input-box">
          <div class="login-pwd-box" v-show="!isLoginPwd">
            <el-form :model="fromData" >
              <el-form-item >
                <div class="input-item">
                  <el-image :src="require('@/assets/img/user.png')" style="width: 24px;margin-left: 16px;" fit="scale-down"></el-image>
                  <el-input placeholder="请输入手机号"  ></el-input>
                </div>
              </el-form-item>
              <el-form-item >
                <div class="input-item-code">
                  <div class="input-bg">
                    <el-input placeholder="验证码" ></el-input>
                  </div>
                  <el-button >发送验证码</el-button>
                </div>           
              </el-form-item>
              <el-button :loading="fromData.btnLoginLoading" >登 录</el-button>
            </el-form>
          </div>
          <div class="login-pwd-box" v-show="isLoginPwd">
            <el-form :model="fromData" :rules="rules" ref="loginFormRef">
            <el-form-item prop="username">
              <div class="input-item">
                <el-image :src="require('@/assets/img/user.png')" style="width: 24px;margin-left: 16px;" fit="scale-down"></el-image>
                <el-input placeholder="请输入手机号"  v-model="fromData.username"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <div class="input-item">
                <el-image :src="require('@/assets/img/pwd.png')" style="width: 24px;margin-left: 16px;" fit="scale-down"></el-image>
                <el-input
                  :type="showPassword?'':'password'"
                  @keyup.enter="submit"
                  placeholder="请输入密码"
                  v-model="fromData.password"
                >
                  <template #suffix>
                    <i
                      :style="{color:showPassword?'#3ea243':''}"
                      @click="showPassword=!showPassword"
                      class="el-input__icon el-icon-view"
                    ></i>
                  </template>
                </el-input>
              </div>
            </el-form-item>
            <el-button :loading="fromData.btnLoginLoading" @click="submit()">登陆</el-button>
          </el-form>
          </div>
          <el-button class="switch-button" @click="switchLogin()">{{isLoginPwd?'验证码登录':'密码登录'}}</el-button>
          <!-- <div class="switch_login">
            <span class="switch-text">密码登录</span>
          </div> -->
        </div>
        
      </div>
      <!-- <div class="box"> -->
        <!-- <div class="title" v-if="device=='desktop'">
          <span class="xmj">小魔夹</span>
          <span>&ensp;&ensp;智能防丢 精准定位 防止偷窥 生活助手</span>
        </div>
        <div class="lbox" v-if="device=='desktop'"></div> -->
        <!-- <div class="rbox">
          <div class="r-title">
            <span class="blod">欢迎使用</span> 小魔夹后台管理系统
          </div>
         
        </div> -->
      <!-- </div> -->
      <div class="bbox">
        <div>Copyright © 2022.YOOLKY All rights reserved 湖南云契金典智能科技有限公司</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  unref,
  computed,
  getCurrentInstance,
} from "vue";
import Rule from "@/common/Rules";
import { appStore } from "@/store/modules/app";
import { setSessionStorage } from "@/utils/localStore";
import { login, getUserAllRole } from "@/api/User";
import { userStore } from "@/store/modules/user";
import { routerStore } from "@/store/modules/router";
import { showMessage } from "@/utils/message";
import { useRouter } from "vue-router";
import { resetRouter } from "@/router/index";
interface From {
  username: string | number | "";
  password: string | "";
}
export default defineComponent({
  name: "Login",
  setup() {
    const isLoginPwd=ref(true);
    const loginFormRef = ref<any>(null);
    const fromData = reactive({
      username: "",
      password: "",
      btnLoginLoading: false,
    });
    const rules = reactive({
      username: [...Rule.phone()],
      password: [Rule.required("请输入密码")],
    });
    const showPassword = ref(false);
    const device = computed(() => appStore.getDevice);
    const internalInstance = getCurrentInstance();
    const router = useRouter();
    const switchLogin = () =>{
      isLoginPwd.value=!isLoginPwd.value
    }
    const submit = async () => {
      const loginForm = unref(loginFormRef);
      await loginForm.validate();
      // 登陆
      try {
        fromData.btnLoginLoading = true;
        const { user, token } = await login({
          username: fromData.username,
          password: fromData.password,
          from:"console"
        });
        user.token = token;
        user.roles = await getUserAllRole({ userid: user.userid, token });
        userStore.setUserInfo(user);
        await routerStore.syncRouters();
        resetRouter();
        setSessionStorage("user", {
          username: fromData.username,
          password: fromData.password,
        });
        console.log("结束了 :>> ");
        showMessage(`${user.nickname || user.account} 欢迎您`);
        router.push("/");
      } catch (error) {
        console.log("error :>> ", error);
        showMessage("请确认账号密码是否正确,网络是否正常")
      } finally {
        fromData.btnLoginLoading = false;
      }
    };
    return {
      device,
      fromData,
      showPassword,
      rules,
      submit,
      switchLogin,
      isLoginPwd,
      loginFormRef,
    };
  },
});
</script>
<style lang="scss" scoped>
.login-container {
  font-size: 16px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
  // box-sizing: border-box;
  background: url("../../assets/img/login_bg.jpg") no-repeat;
  background-size:100% 100%;
  @media (max-width: 900px) {
    // background: #2d3a4b;
    // color: #eee;
  }
  
  /deep/.el-input__inner,.el-textarea__inner{
    background: transparent !important;
    border: transparent;
    color: #CCE6FF;
    font-size: 17px;
  }      

  /deep/input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus { 
    -webkit-box-shadow: 0 0 0 1000px rgba(58, 72, 107, 0.0) inset !important; 
    /* -webkit-text-fill-color: #fff; */
    -webkit-transition-delay: 99999s; 
    -webkit-transition: color  99999s ease-out, background-color  99999s ease-out;
    font-size: 17px;
  }
  .content {
    position: relative;
    height: 100%;
    width: 100%;

    .login_box{
      display: flex;
      flex-direction: column;
      position: absolute;
      justify-content: center;
      right: 10%;
      top: 50%;
      margin-top: -12vw;
      width: 24vw;
      height: 20vw;
      background: url("../../assets/img/pwd_bg.png") no-repeat;
      background-size:100% 100%;
      .text-box{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
      }
      .login-input-box{
        height: 70%;
        width: 70%;
        margin-left: 15%;
        .login-pwd-box{
          .input-item{
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: center;
            flex-direction: row;
            border: 1px #41A5FF solid;
            border-radius: 5px;
            
          }
          .input-item-code{
            width: 100%;
            height: 48px;
            margin-top: 4px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            flex-direction: row;

            .input-bg{
              display: flex;
              align-items: center;
              height: 100%;
              border: 1px #41A5FF solid;
              border-radius: 5px;
              margin-right: 24px;
            }

            /deep/.el-button{
              width: 120px;
              height: 48px;
              background-color: #348EEA;
              background: #348EEA;
              border: transparent;
              color: #CCE6FF;
            }
           
          }
          /deep/.el-button{
            width: 100%;
            height: 48px;
            background-color: #348EEA;
            background: #348EEA;
            border: transparent;
            color: #CCE6FF;
          }
        }
        .switch_login{
          width: 120px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 10%;
          margin-top: 16px;
          .switch-text{
            color:#CCE6FF;
            font-size: 17px;
          }
        }
        .switch-button{
          position: absolute;
          right: 10%;
          margin-top: 16px;
          background-color: transparent;
          background: transparent;
          border: transparent;
          color:#CCE6FF;
          font-size: 17px;
        }
      }
      
    }
    .box {
      width: 69vw;
      height: 28vw;
      display: flex;
      position: relative;
      height: 0;
      padding-bottom: 41%;
      // box-shadow: #ccc 2px 2px 5px;
      // background: url("../../assets/img/login_bk.png") no-repeat;
      background-size: 102%;
      // overflow: hidden;
      .title {
        position: absolute;
        top: -60px;
        left: 70px;
        span {
          color: #777d76;
        }
        .xmj {
          font-size: 28px;
          color: #3ea243;
          font-weight: bold;
        }
      }
      .lbox {
        flex: 1;
      }
      .rbox {
        width: 36vw;
        height: 28vw;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        .r-title {
          color: #777d76;
          height: 72px;
          font-size: 16px;
          .blod {
            color: #3ea243;
            font-weight: bold;
          }
        }
        .el-form {
          display: flex;
          flex: 1;
          width: 80%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 312px;
          /deep/.el-input {
            min-width: 312px;
          }
          .radio {
            padding-left: 12px;
          }
          .el-button {
            width: 100%;
            background: #83b374;
            color: #fff;
            &:hover {
              background: #76a867ff;
            }
          }
        }
      }
    }
    .bbox {
      position: fixed;
      bottom: 21px;
      width: 100vw;
      text-align: center;
      color: #9b9b9b;
    }
  }
  .min-content {
    width: 100%;
    font-size: 0.3rem;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      margin: 0 auto;
      .r-title {
        color: #777d76;
        height: 72px;
        line-height: 72px;
        width: 100%;
        text-align: center;
        font-size: 0.5rem;
        .blod {
          color: #3ea243;
          font-weight: bold;
        }
      }
      .rbox {
        width: 100%;
        .el-form {
          display: flex;
          flex: 1;
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .el-input {
            // min-width: 2rem;
            // height: 1rem;
          }
          .radio {
            padding-left: 12px;
          }
          .el-button {
            width: 100%;
            background: #83b374;
            color: #fff;
            &:hover {
              background: #76a867ff;
            }
          }
        }
      }
    }
    .bbox {
      width: 100%;
      position: fixed;
      bottom: 0.2rem;
      font-size: 0.25rem;
      text-align: center;
      color: #9b9b9b;
      left: 50%;
      // transform: translate(-25%);
    }
  }
}
</style>