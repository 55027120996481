export const getStatusName = {
    "0": "已下单",
    "1": "已付款",
    "2": "已受理",
    "3": "已交付",
}
export const getArticletypeName = {
    "phoneholder": "小魔夹",
    "umbrella": "雨伞",
}


// export default function () {

// }