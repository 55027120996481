import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
import { userStore } from "@/store/modules/user";

export interface listModel {
    "endGmtStart":string,//  故障发生时间-结束
    "equipmentMacEnd":string,// 故障解除时插口内设备MAC
    "equipmentMacStart": string, // 故障发生时插口内设备MAC
    "equipmentSnEnd": string, //  故障解除时插口内设备SN
    "equipmentSnStart": string, // 故障发生时插口内设备SN
    "faultCode": string, //  故障码
    "gmtCreate": string, // 创建时间
    "gmtEnd": string, // 故障解除时间
    "gmtModified":string,  // 更新时间
    "gmtStart":string,// 故障发生时间
    "panelSn":string,// 机柜SN
    "remark": string,// 备注信息
    "socketSn":string,// 插口SN
    "startGmtStart":number, //  故障发生时间-开始
    "id":number,
    "status":number,  //状态 0:未解决 1：已解决
}



export async function cabientListHttp(params: ListReq): Promise<ListRes<listModel>> {
    params.token = true
    const res = await defHttp.request<ListRes<listModel>>(
        {
            url: `/sharedsocketfault/page`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.rows = res.rows.map(v => {    
        v.gmtCreate = dayjs(v.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        v.gmtModified = dayjs(v.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        v.gmtEnd = v.gmtEnd?dayjs(v.gmtEnd).format("YYYY-MM-DD HH:mm:ss"):"暂无";
        return v
    })
    return res
}

