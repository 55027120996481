<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`机柜${panelequipmentsn}的设备`"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-table :data="list" height="60vh" style="width: 100%">
     <el-table-column label="仓口号" min-width="30" prop="socketsn"  align="center"></el-table-column>
      <el-table-column label="SN" min-width="30" align="center">
        <template #default="scope">
          
          <div v-if="scope.row.articleequipmentsn == '空'">
            <div v-if="scope.row.equipmentsn">
                {{scope.row.equipmentsn}}
                <hover-show-qr :logo="`icon-${prefix=='uh'?'umbrella':'phoneholder'}`" :text="scope.row.equipmentsn"></hover-show-qr>
            </div>
          </div>
          <div v-else>
            {{scope.row.articleequipmentsn}}
            <hover-show-qr :logo="`icon-${prefix=='uh'?'umbrella':'phoneholder'}`" :text="scope.row.articleequipmentsn"></hover-show-qr>
          </div>
        </template>
      </el-table-column>
     <el-table-column label="电量" min-width="30"   align="center">
       <template  #default="scope">
          {{scope.row.batterypower}}%
       </template>
     </el-table-column>
      <el-table-column label="休眠时间" min-width="35" prop="sleeptime" align="center"></el-table-column>
      <el-table-column label="状态" min-width="60" prop="sleeptime" align="left">
        <template #default="scope">
          <el-tag type="info" v-if="scope.row.socketstatus=='0'">正常无设备</el-tag>
          <div v-else-if="scope.row.socketstatus=='1'">
              <el-tag type="info"  effect="dark"  v-if="scope.row.articleequipmentsn == '空'">私有化设备</el-tag>
              <el-tag type="info"  effect="dark"  v-if="scope.row.fault">小魔夹故障</el-tag>
              <el-tag type="success"  v-else>正常有设备</el-tag>
          </div>
          <!-- <el-tag type="success" v-else-if="scope.row.socketstatus=='1'">正常有设备</el-tag> -->
          <el-tooltip v-else-if="scope.row.socketstatus=='2'" effect="light" content="可通信,充电不正常" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='3'" effect="light" content="识别机仓内有东西，但是无法通讯和充电" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='4'" effect="light" content="读取小魔夹信息超时错误" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='5'" effect="light" content="收到指令成功解锁设备但是驱动电机不能使小魔夹退出" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.socketstatus=='6'" effect="light" content="发出解锁数据后连续查询设备5次不能解锁设备" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
           <el-tooltip v-else-if="scope.row.socketstatus=='7'" effect="light" content="读取MCU忙" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
           <el-tooltip v-else effect="light" content="未知故障" placement="top">
            <el-tag type="danger"  effect="dark" slot="reference">故障状态</el-tag>
          </el-tooltip>
          <!-- <el-tag type="danger" v-else effect="dark" slot="reference">故障状态
            <el-popover
              placement="top-start"
              title="标题"
              width="200"
              trigger="hover"
              content="哈哈哈哈">
            </el-popover>
          </el-tag> -->
          
          <!-- <el-tag type="warning" effect="dark" v-if="scope.row.socketstatus=='2'">可通信,充电不正常</el-tag>
          <el-tag type="danger"  effect="dark" v-if="scope.row.socketstatus=='3'">识别机仓内有东西，但是无法通讯和充电</el-tag>
          <el-tag type="danger"  effect="dark" v-if="scope.row.socketstatus=='4'">收到指令成功解锁设备但是驱动电机不能使小魔夹退出</el-tag>
          <el-tag type="danger"  effect="dark" v-if="scope.row.socketstatus=='5'">发出解锁数据后连续查询设备5次不能解锁设备</el-tag> -->
        </template>
      </el-table-column>
     
    </el-table>
    <!-- <el-pagination
      :current-page="page"
      :page-size="limit"
      :total="total"
      @current-change="onPageChange"
      layout="prev, pager, next"
    ></el-pagination> -->
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref } from "vue";
import { getSocketList, SocketListRes } from "@/api/Share/Socket";
export default defineComponent({
  name: "v-device-dialog",
  setup() {
    const visible = ref(false);
    const page = ref(1);
    const limit = ref(15);
    const loading = ref(false);
    const list = ref<SocketListRes[]>([]);
    const total = ref(0);
    const panelequipmentsn = ref("");
    const prefix = ref("");
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };

    const reData = async () => {
      try {
        loading.value = true;
        const res = await getSocketList({
          current: page.value,
          size: limit.value,
          entity: { panelequipmentsn: panelequipmentsn.value },
        });
        console.log('res :>> ', res);
        total.value = res.total;
        list.value = res.records;
      } finally {
        loading.value = false;
      }
    };
    const open = (sn: string, _prefix: string) => {
      visible.value = true;
      panelequipmentsn.value = sn;
      prefix.value = _prefix;
      reData();
    };
    const onPageChange = (current: any) => {
      page.value = current.value;
      reData();
    };
    return {
      visible,
      panelequipmentsn,
      handleClose,
      open,
      onPageChange,
      prefix,
      page,
      limit,
      total,
      list,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>