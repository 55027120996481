<!--微信转账结果-->
<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
     
    </header>
    <el-table
      :data="list"
      :default-sort="{prop: 'date', order: 'descending'}"
      @sort-change="mixinReRouterTableSortChange"
      height="80vh"
      style="width: 100%"
      v-if="list"
    >
      <!-- <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="用户ID">
              <el-button @click="toPath_RouterSearchMixin('/user',[{search_userid:scope.row.userid}])" type="text">{{ scope.row.userid }}</el-button>
              <my-copy :data="scope.row.userid" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="收款人" min-width="55">
        <template #default="scope">
          <span>{{scope.row.reusername}}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="描述" min-width="44">
        <template #default="scope">
             {{scope.row.desc}}
        </template>
      </el-table-column>
      <el-table-column align="left" label="金额" min-width="44">
        <template #default="scope">
            ¥ {{scope.row.amount}} 分
        </template>
      </el-table-column>
      <el-table-column align="center" label="到账结果" min-width="44">
        <template #default="scope">
            <el-tag type="success" v-if="scope.row.resultcode=='SUCCESS'">成功</el-tag>
            <el-tag type="warning" v-else>失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="left" label="失败原因" min-width="66">
        <template #default="scope">
            {{scope.row.errcodedes}} 
        </template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" align="center" label="创建时间" min-width="55" prop="creationdate" sortable="custom"></el-table-column>
      <el-table-column align="center" label="到账时间" min-width="55" prop="paymenttime"></el-table-column>
     
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  WechatMerchantTransfer,
  getWechatMerchantTransfer,
} from '@/api/Financial/wechatmerchanttransfer'

import { deepCopy } from '@/utils'
export default defineComponent({
  setup() {
    const loading = ref(false)
    const btnEnabledLoaidng = ref(false)
    const btnRemoveLoaidng = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
  
    const searchSelect = ref({
      search: 'reusername',
    })
    const mixin_router_search = ref({
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        reusername: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        reusername: '用户姓名',
      },
    })
   
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await getWechatMerchantTransfer(rqData)
        console.log('res.records :>> ', res.records)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
      mixinReRouterTableSortChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      changSearch,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      btnEnabledLoaidng,
      btnRemoveLoaidng,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

  .el-select{
    width: 130px;
  }
}
</style>