<template>
  <div class="container">
    <header>
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select placeholder="请选择" v-model="searchSelect.search" class="input-select-tip">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelDateType" class="header-select" clearable placeholder="日期选择" v-model="dateType">
        <el-option label="按年查" value="0"></el-option>
        <el-option label="按月查" value="1"></el-option>
        <el-option label="按天查" value="2"></el-option>
      </el-select>     

      <el-date-picker
       @change="handelChange"
        v-model="yearValue"
        type="year"
        placeholder="选择年"
        v-if="dateType=='0'">
     </el-date-picker>
      <el-date-picker
       @change="handelChange"
        v-model="monthValue"
        type="month"
        placeholder="选择月"
        v-if="dateType=='1'">
    </el-date-picker>
      <el-date-picker
        @change="handelChange"
        v-model="dayValue"
        align="right"
        type="date"
        placeholder="选择天"
        v-if="dateType=='2'">
    </el-date-picker>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
        
      <el-table-column label="编号" align="center" min-width="56">
        <template #default="scope">
          {{scope.row.articleequipmentsn}}
        </template>
      </el-table-column>
      
      <el-table-column label="收益" align="center" min-width="44">
        <template #default="scope">￥ {{scope.row.amount}}</template>
      </el-table-column>
      
      
      <el-table-column label="剩余天数" align="center" min-width="50">
        <template #default="scope">{{scope.row.leftoverday}}</template>
      </el-table-column>
    
     
      <el-table-column align="center" label="操作" min-width="90">
        <template #default="scope">
          <el-button
            @click="handleDetail(scope.row)"
            size="mini"
            type="primary"
            style="margin-left: 12px;"
           >查看详情</el-button>
        </template>       
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import { investorProfitDeviceModel, investorProfitDeviceListHttp } from '@/api/Profit/device'
import routerChangeReData from '@/common/routerChangeReData'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'investor-profit-device',
  components: {

  },
  data() {
    return {
        shortcuts: [{
                text: '今天',             
                value: (() => {
                    const date = new Date()
                    console.log(dayjs(date.getTime()).format("YYYY-MM-DD"))
                    return date.getTime()
                })(),
            }, {
                text: '昨天',
                 value: (() => {
                    const date = new Date()
                    return date.getTime() - 3600 * 1000 * 24
                })(),
            }, {
                text: '前天',
                value: (() => {
                    const date = new Date()
                    return date.getTime() - 3600 * 1000 * 48
                })(),
            }
        ]
    }
},
  setup() {
   

    const mixin_router_search = ref({
      fortest: 'false', //
      userid:userStore.userInfo.userid,
      productid:'56c62a11a6a14d6eb7ab29fbf9f2f8b4',
      datestr:'',
    })
    const mixin_select_search = ref({
      search: {
        sn:'', //小魔夹SN
          
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        sn: '编号', // 投资人用户编号
      },
    })
    const searchSelect = ref({})

    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const dateType = ref('0'); //日期选择类型 0年 1月 2日
    const yearValue = ref<any>();
    const monthValue = ref<any>();
    const dayValue = ref<any>();
    const list = ref<investorProfitDeviceModel[]>([])
    const btnRemoveLoading = ref(false)
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }

    const reData = async (rq?: any) => {
      try {
        loading.value = true
         let rqData: any = { currentPage: page.value, size: limit.value }

        if (rq && JSON.stringify(rq) !== '{}') {
        //   if (rq.order) {
        //     const { order, sort } = rq
        //     delete rq.order
        //     delete rq.sort
        //     rqData = { ...rqData, order, sort }
        //   }
          rqData = { ...rqData,  ...rq }
        }
        const res = await investorProfitDeviceListHttp(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
        if(dateType.value=='0'){
            mixin_router_search.value.datestr=dayjs(yearValue.value).format("YYYY")
        }else if(dateType.value=='1'){
            mixin_router_search.value.datestr=dayjs(monthValue.value).format("YYYY-MM")
        }else if(dateType.value=='2') {
            mixin_router_search.value.datestr=dayjs(dayValue.value).format("YYYY-MM-dd")
        }else{
           mixin_router_search.value.datestr=''
        }
      mixinReRouter()
    }
   
    const handelDateType = () =>{
        if(dateType.value=='0'){
            mixin_router_search.value.datestr=dayjs(yearValue.value).format("YYYY")
        }else if(dateType.value=='1'){
            mixin_router_search.value.datestr=dayjs(monthValue.value).format("YYYY-MM")
        }else if(dateType.value=='2') {
            mixin_router_search.value.datestr=dayjs(dayValue.value).format("YYYY-MM-dd")
        }else{
           mixin_router_search.value.datestr=''
        }
        handelChange();
    }

    const handleDetail = (item:any) =>{
      
    }
    



   
    
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,   
      onPageChange,
      handelChange,
      handleLimitChange,
      mixinReRouter,
      searchSelect,
      list,
      loading,
      dateType,
      btnRemoveLoading,
      roleShowPermission,  
      page,
      limit,
      total,   
      toPath_RouterSearchMixin,  
      sheareProductInfo,
      handleDetail,
      handelDateType,
      yearValue,
      monthValue,
      dayValue,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
    .input-with-select{
      width: 320px;
      margin-right: 16px;
      
      /deep/.el-input{
        width: 120px;
      }
    }
    .input-select-tip{
        width: 130px;
    }
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>