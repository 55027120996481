
import { addOperateHttp ,updateOperateHttp } from '@/api/Operate/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
let id = 0;
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref<any>({})
    const isAdd = ref(true)

    const inputData = ref({
      name: '',
    })
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (add:boolean,item:any) => {
      isAdd.value=add;
      visible.value = true
      if(!add){
        id=item.id;
        inputData.value.name=item.name;
      }
    }
    const submit = async () => {
      try {
        // console.log('是否添加',isAdd.value);
        btnSumitLoading.value = true
        const res = isAdd.value ? await addOperateHttp(inputData.value.name) : await updateOperateHttp({id:id,name:inputData.value.name});
  
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      isAdd,
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      submit,
      inputData,
    }
  },
})
