<template>
  <el-dialog :destroy-on-close="true" title="替换用户手机号" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      title="更换手机号，只是针对登录手机号更换，其他信息不做更换"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="form" :rules="rules" label-width="100px" ref="formRef" style="margin-top: 16px;">
      <el-form-item label="用户昵称">
        <el-input style="width: 220px;" placeholder="请输入故障编码" v-model="nickname" disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="原手机号" prop="content">
        <el-input style="width: 220px;" placeholder="请输入故障原因" v-model="oldPhone" disabled="true"></el-input>
      </el-form-item>
     
      <el-form-item label="新手机号">
        <el-input style="width: 220px;" placeholder="请输入新手机号" v-model="form.newAccount"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import Rules from '@/common/Rules'
import {replaceUserPhone} from '@/api/User/index'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
let userId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const nickname = ref('')
    const oldPhone = ref('')
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      newAccount: [Rules.required()],
    }
    const form = ref({
      newAccount: '',
    })
    const open = (_userid: string,_oldPhone:string,_nickname:string) => {
      visible.value = true
      userId=_userid;
      nickname.value=_nickname;
      oldPhone.value=_oldPhone;
    }

    const submit = async () => {
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res= await replaceUserPhone(userId,form.value.newAccount)
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      nickname,
      oldPhone,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>