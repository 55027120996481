<template>
  <el-dialog :destroy-on-close="true" :title="`${deviceSn}接收的消息`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="80vw">
    <div class="container">
      <header>
        <el-select @change="handelChange" class="header-select" clearable placeholder="消息类型" v-model="search.datastream">
          <el-option label="上报PTS" value="PTS"></el-option>
          <el-option label="归还STSIN" value="STSIN"></el-option>
          <el-option label="弹出STSOUT" value="STSOUT"></el-option>
          <el-option label="心跳GETCSQ" value="GETCSQ"></el-option>
          <el-option label="在线MAC" value="MAC"></el-option>
          <el-option label="同步TIME" value="TIME"></el-option>
        </el-select>   

        <el-date-picker
          @change="handelChange"
          align="right"
          class="header-select"
          end-placeholder="创建时间"
          range-separator="至"
          start-placeholder="创建时间"
          type="datetimerange"
          unlink-panels
          v-model="search.careateBN"
        ></el-date-picker>
        <el-button type="primary" icon="el-icon-refresh" style="margin-left: 16px;" @click="reData">刷新</el-button>
      </header>
        <el-table :data="list" :loading="loading" height="65vh" style="width: 100%" v-if="list">
          <el-table-column label="设备SN" align="center" min-width="24">
            <template #default="scope">{{scope.row.cabinetsn}}</template>
          </el-table-column>
          <el-table-column align="center"  label="消息类型" min-width="20" prop="datastream">
            <template #default="scope">
              {{msgType[scope.row.datastream]}}
            </template>  
          </el-table-column>
          <el-table-column label="消息内容" align="center" min-width="170">
            <template #default="scope">
              {{scope.row.body}}
            </template>
          </el-table-column>
          
          <el-table-column label="发送时间" align="center" min-width="32">
            <template #default="scope">{{scope.row.datatimestamp}}</template>
          </el-table-column>
          <el-table-column label="到达时间" align="center" min-width="32">
            <template #default="scope">{{scope.row.creationtime}}</template>
          </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :page-sizes="[10, 30, 50, 100]"
        :total="total"
        @current-change="onPageChange"
        @size-change="handleLimitChange"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, readonly, inject ,onMounted} from 'vue'
import { fourthgmsgList } from '@/api/Share/Device'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
let deviceId='';
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const deviceSn=ref<any>();   
    const list = ref<any[]>([])
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const search = ref({
      careateBN:"",
      datastream:"",
    }) 
    const msgType = readonly({
      'PTS': '上报PTS',
      'STSIN': '归还STSIN',
      'STSOUT': '弹出STSOUT',
      'GETCSQ': '心跳GETCSQ',
      'MAC': '在线MAC',
      'TIME': '同步TIME',
      'SOS':'求救SOS',
      'bat':'电量bat',
      'appversion':'时间',
      'Expired':'租借时间',
      'LBS':'定位',
      'factory':'工厂模式',
    })
    const handleClose = () => {
      visible.value = false
     
    }
    const open = async (_deviceid:string,_sn:string) => {
      visible.value = true
      deviceId=_deviceid;
      deviceSn.value=_sn;
      reData();
    }
    const reData = async () => {
      try {
        loading.value = true
        let rqData: any = { page: page.value, limit: limit.value,deviceid:deviceId,sort: 'creationtime', order: 'desc' }
       
        if (search.value.careateBN) {
          rqData.datatimestampbegin = new Date(search.value.careateBN[0]).getTime()   //dayjs(search.value.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.datatimestampend = new Date(search.value.careateBN[1]).getTime()   //dayjs(search.value.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")  
        }
        if(search.value.datastream){
          rqData.datastream=search.value.datastream
        }
        let { totalCount, result } = await fourthgmsgList(rqData)
        total.value = totalCount
        list.value = result
      } finally {
        loading.value = false
      }
    }
    

    const handelSearch = () => {

    }

    const handelChange = () => {
      page.value = 1
      reData()
    }

    const onPageChange = async(item:any) => {
      page.value=item;
      reData()
    }

    const handleLimitChange = async(item:any) => {
      page.value=item;
      reData()
    }
   
    return {
      visible,
      page,
      limit,
      total,
      list,
      search,
      loading,
      deviceSn,
      msgType,
      handleClose,
      open,
      reData,
      handelSearch,
      handelChange,
      onPageChange,
      handleLimitChange,
    }
  },
})
</script>
<style lang = "scss" scoped >
  .header-select {
    margin-right: 24px;
  }
</style>