
import { defineComponent, ref, readonly, inject } from "vue";
import {
  userguaranteeorderList,
  UserguaranteeorderList,
  wechatpayscoreorderComplete,
  alipayfundauthorderComplete,
} from "@/api/Order/Guarantee";
import { ElMessage, ElMessageBox } from "element-plus";
let userId = ''
let guaranteeId = ''
let searchTitle='guaranteeno'
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const visible = ref(false)
    const list = ref<any[]>([])
    const name = ref("");
    const page = ref(1)
    const limit = ref(10)
    const total = ref(0)
    const guaranteetype = ref("");
    const loading = ref(false);
    const btncloseLoading = ref(false);
    const enabled = ref("");
    const search = ref("");
    const searchSelect = ref({ search: 'guaranteeno' })
    const select_search = ref({
      search: {
        sharedorderid: "",
        guaranteeno: "",
      }
    });
    const select_search_label = readonly({
      search: {
        sharedorderid: "订单号",
        guaranteeno: "担保订单号",
      }
    });
    const changSearch = (type:string) => {
        searchTitle=type;
    }
    const handleClose = () => {
      visible.value = false
    }
    const open = (_userId: string,_name: string,_orderId:string,_guaranteeId:string) => {
      visible.value = true
      userId = _userId
      name.value=_name;
      reData()
    }
    const reData = async () => {
    
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value,order:"creationdate",sort:"desc" };
        let entity :any ={ userid:userId};
        if(guaranteeId!==null&&guaranteeId!==""){
            entity.guaranteeno=guaranteeId;
        }
        if(guaranteetype.value!==null&&guaranteetype.value!==""){
            entity.guaranteetype=guaranteetype.value;
        }
        if(enabled.value!==null&&enabled.value!==""){
            entity.enabled=enabled.value;
        }
        if(search.value!=null&&search.value!==""){
           entity[searchTitle]=search.value
        }
        rqData={...rqData,entity:{...entity}}
        const res = await userguaranteeorderList(rqData);
        list.value = res.records;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    }
    

    const handelChange = (type:string) => {
      console.log('改变='+type); 
      page.value=1;
      reData();
    }

    const onPageChange = (val:number) => {
      page.value=val;
      reData();
    }

    const handleLimitChange = (val:number) =>{
      limit.value=val;
      reData();
    }

    const handleaToClose = async(index:number,item:UserguaranteeorderList) =>{
      try {
        await ElMessageBox.confirm(`是否强制关闭此支付分订单？`, `请确认`, {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        btncloseLoading.value = true;
        if (item.guaranteetype == "ZHIMA") {
          await alipayfundauthorderComplete(item.guaranteeno);
        } else {
          await wechatpayscoreorderComplete(item.guaranteeno);
        }
        ElMessage.success("关闭担保订单成功");
      } finally {
        btncloseLoading.value = false;
      }
    }

    return{
      visible,
      name,
      handleClose,
      open,
      list,
      page,
      total,
      limit,
      enabled,
      search,
      searchSelect,
      select_search,
      select_search_label,
      handelChange,
      loading,
      btncloseLoading,
      reData,
      changSearch,
      guaranteetype,
      onPageChange,
      handleLimitChange, 
      handleaToClose,
    }
  }
})    
