

const Layout = () => import('@/layout/index.vue')
export default [
    // {
    // // 匹配所有路径  vue2使用*   vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    //     path: "/:pathMatch(.*)*",
    //     name: '404',
    //     component: Layout,
    //     hidden: true
    // },
    {
        // 匹配所有路径  vue2使用*   vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
        path: "/:pathMatch(.*)",
        name: "NotFound",
        meta: {
            title: '404',
            icon: 'icon-home',
            auth: true
        },
        hidden: true,
        // component: () => import('@/views/error/404.vue'),
        component: Layout,
        redirect: '/404',
        children: [
            {
                path: '/404',
                name: "404",
                meta: {
                    auth: true,
                    title: '404',
                    icon: 'icon-home',
                },
                hidden: true,
                component: () => import('@/views/error/404.vue'),
            }
        ]
    }
]