<template>
  <el-dialog :destroy-on-close="true" :title="`标题`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-form :model="form" :rules="rules" label-width="180px" ref="formRef">
      <el-form-item label="区域码" prop="areacode">
        <el-input v-model="form.areacode"></el-input>
      </el-form-item>
      <el-form-item label="区域名" prop="areaname">
        <el-input v-model="form.areaname"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts">
import { defineComponent, reactive, ref } from 'vue'
import Rules from '@/common/Rules'
import {
  administrativedivisionSave,
  administrativedivisionUpdate,
} from '@/api/System/city'
import { ElMessage } from 'element-plus'
let index = -1,
  row: any
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false)
    const isAdd = ref(false)
    const formRef = ref<any>()
    const btnSumitLoading = ref(false)
    const form = reactive({
      areacode: '',
      areaname: '',
    })

    const rules = {
      areacode: [Rules.required()],
      areaname: [Rules.required()],
    }
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = (_row: any) => {
      console.log('_row :>> ', _row)
      if (_row.isAdd) {
        isAdd.value = true
      } else {
        form.areacode = _row.areacode
        form.areaname = _row.areaname
        isAdd.value = false
        index = _row.index
      }
      row = {
        superiorcode: _row.superiorcode,
        arealevel: _row.arealevel,
        islastlevel: _row.islastlevel,
        municipality: _row.municipality,
      }
      visible.value = true
    }

    const submit = async () => {
      form.areacode = form.areacode.trim()
      form.areaname = form.areaname.trim()
      await formRef.value.validate()
      try {
        btnSumitLoading.value = true
        const res = isAdd.value
          ? await administrativedivisionSave({ ...row, ...form })
          : await administrativedivisionUpdate({ ...row, ...form })
        ElMessage.success(res)
        handleClose()
        emit('success')
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      formRef,
      form,
      rules,
      submit,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>