
import { defineComponent, ref, readonly, computed } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { getComponentsList, ComponentsListRes } from '@/api/Component/Index'
export default defineComponent({
  name: 'Name',
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any>([])
    const searchSelect = ref({
      search: 'sign',
    })

    const mixin_router_search = ref({ type: '' })
    const mixin_select_search = ref({
      search: {
        equipmentsn: '',
        sign: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        equipmentsn: 'SN',
        sign: 'sign',
      },
    })

    const getSignName = computed(() => {
      let name = ''
      switch (mixin_router_search.value.type) {
        case 'BLE':
          name = 'MAC'
          break
        case '4G':
          name = 'diveceid'
          break
        default:
          name = 'sign'
          break
      }
      return name
    })

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await getComponentsList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      getSignName,
      changSearch,
    }
  },
})
