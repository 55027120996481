<template>
  <el-dialog :destroy-on-close="true" title="删除维修员" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
   <el-alert
      title="删除维修员，需要一个维修员账号来接收被删除维修员绑定的机柜和场所，接收账号和删除账号要在同一个运营团队"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="rules" label-width="100px" ref="formRef" style="margin-top: 20px;" >
      <el-form-item label="接收维修员" prop="newAccount">
        <el-input v-model="inputData.newAccount" placeholder="请输入接收维修员的手机号" style="width: 60%;" @blur="newAccountChange"></el-input>
      </el-form-item>
      <el-form-item label="所在运营" prop="operationName">
        <el-input v-model="inputData.operationName" disabled placeholder="接收维修员所在运营团队" style="width: 60%;"></el-input>
      </el-form-item>
      <el-form-item label="删除维修员" prop="account">
        <el-input v-model="inputData.account" placeholder="请输入删除维修员的手机号" style="width: 60%;" @blur="oldAccountChange"></el-input>
      </el-form-item>
      <el-form-item label="所在运营" prop="oldOperationName">
        <el-input v-model="inputData.oldOperationName" disabled placeholder="删除维修员所在运营团队" style="width: 60%;"></el-input>
      </el-form-item>    
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { userRoleList,  removeRepair} from '@/api/User/role'
import { defineComponent, ref, toRef } from "vue";
import { activateOperator,upDateOperator } from "@/api/User/role";
import { operateModel, getOperateListHttp } from "@/api/Operate/index"
import Rules from "@/common/Rules";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  props: ["type"],
  data() {
    
    return {
      rules: {
        account: [{ required: true, message: '需要删除维修员的手机号', trigger: 'blur' }],
        newAccount: [{ required: true, message: '需要接收维修员的手机号', trigger: 'blur' }]      
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const isAdd = ref(true);
    const operateList = ref<operateModel[]>([])
    const userList = ref<any>([])
    const type = toRef(props, "type");
    const formRef =ref<any>("");
    const inputData = ref({
      account: "",
      newAccount: "",
      operationName: "",
      operationId :"",
      oldOperationName :"",
      oldOperationId :"",
    });
    const handleClose = () => {
      console.log('点击关闭')
      visible.value = false;
      formRef.value.resetFields();
      formRef.value.clearValidate();
    };
    const open = (row:any) => {
     // console.log('类型',typeof(item.operationId))
      visible.value = true;
  
      inputData.value.account=row.repairmanaccount;
      inputData.value.newAccount=""
      inputData.value.operationId=""
      inputData.value.oldOperationId=row.operationId;
      inputData.value.operationName=""
      inputData.value.oldOperationName=""

      reData();
    };
    const newAccountChange = async () =>{
      if(inputData.value.newAccount){
        let rqData: any = { page: 1, limit: 10,repairmanaccount: inputData.value.newAccount}
        const res = await userRoleList(rqData, "repairman")
        userList.value=res.result
        const operationid=userList.value[0].operationId;
        if(operationid){
          inputData.value.operationId=operationid
          operateList.value.forEach(item=>{  
            if(item.id == operationid){
              inputData.value.operationName=item.name
            }
          })
        }
      }
    }

    const oldAccountChange = async () =>{
       if(inputData.value.newAccount){
        let rqData: any = { page: 1, limit: 10,repairmanaccount: inputData.value.account}
        const res = await userRoleList(rqData, "repairman")
        userList.value=res.result
        const operationid=userList.value[0].operationId;
        if(operationid){
          inputData.value.oldOperationId=operationid
          operateList.value.forEach(item=>{  
            if(item.id == operationid){
              inputData.value.oldOperationName=item.name
            }
          })
        }
      }
    }
    const reData = async () => {
      const res = await getOperateListHttp()     
      operateList.value = res.data     
      if(inputData.value.oldOperationId){
        operateList.value.forEach(item=>{  
          if(item.id == parseInt(inputData.value.oldOperationId)){
            inputData.value.oldOperationName=item.name
          }
        })
      }
    }

    const submit = async () => {
      try {
        try {
          await formRef.value.validate();
        } catch (error) {
          console.log(error);
          throw Error("请检查输入");
        }
        
        setTimeout(async ()=>{
          const rqData = {account: inputData.value.account,newAccount:inputData.value.newAccount,operationId:''}
          if(inputData.value.operationId == inputData.value.oldOperationId){
            rqData.operationId=inputData.value.operationId;
          }else{
            ElMessage.error('维修员所在运营团队需要一致')
          }
          btnSumitLoading.value = true;
          const result =  await removeRepair(rqData);
          ElMessage.success(result);
          handleClose();
        },3000)
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      isAdd,
      operateList,
      visible,
      handleClose,
      open,
      newAccountChange,
      oldAccountChange,
      btnSumitLoading,
      inputData,
      submit,
      formRef,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>