import { appStore } from '@/store/modules/app';
import { computed, watch } from 'vue';
import type { ComputedRef } from 'vue'
const {
    body
} = document
const WIDTH = 992 // refer to Bootstrap's responsive design
let timer: any = null
import type { sidebarType } from "@/store/modules/app"
import { emitter } from '@/utils/myMitt';
interface Result {
    device: ComputedRef<string>;
    sidebar: ComputedRef<sidebarType>;
    isMobile: () => boolean;
    resizeHandler: () => void;
}
export default function ResizeHandler(): Result {
    const device = computed(() => appStore.getDevice)
    const sidebar = computed(() => appStore.getSidebar)
    const isMobile = (): boolean => {
        const rect = body.getBoundingClientRect()
        return rect.width - 1 < WIDTH
    }
    const resizeHandler = () => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            if (!document.hidden) {
                const _isMobile = isMobile()
                emitter.emit('resize')
                appStore.toggleDevice(_isMobile ? 'mobile' : 'desktop')
                if (_isMobile) {
                    appStore.closeSideBar({
                        withoutAnimation: false
                    })
                }
            }
        }, 500)
    }
    return {
        device,
        sidebar,
        isMobile,
        resizeHandler
    }
}