<template>
  <el-dialog
    :destroy-on-close="true"
    :title="`测试钱包充值`"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-form :model="inputData" :rules="rules" label-width="100px" ref="inputRef">
      <!-- <el-form-item label="钱包类型" prop="articleproductid">
        <el-select @change="handelChange" clearable placeholder="设备类型" v-model="inputData.articleproductid">
          <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of walletList"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="充值金额" prop="amount">
        <el-input-number :min="1" :step="1" v-model="inputData.amount"></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, toRef } from 'vue'
import { userconsumewalletRecharge } from '@/api/User/role'
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { getShareProductInfo } from '@/api/util'
let row: any = {}
export default defineComponent({
  name: 'v-testWalletRechangeDialog',
  props: ['type'],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
      },
    }
  },
  setup(props: any) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const type = toRef(props, 'type')
    const inputRef = ref<any>('')
    const walletList = getShareProductInfo()
    const inputData = ref({
      amount: 0
    })
    const handleClose = () => {
      visible.value = false
      row = {}
      inputRef.value.resetFields()
      inputRef.value.clearValidate()
    }
    const open = (_row: any) => {
      visible.value = true
      row = _row
      console.log('row :>> ', row);
    }
    const submit = async () => {
      try {
        try {
          await inputRef.value.validate()
        } catch (error) {
          console.log(error)
          throw Error('请检查输入')
        }
        btnSumitLoading.value = true
        const result = await userconsumewalletRecharge({
          ...inputData.value,
          userid:row.testeruserid
        })
        ElMessage.success(result)
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      inputRef,
      walletList
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>