
import { defineComponent, ref, readonly, inject } from "vue";
import routerChangeReData from "@/common/routerChangeReData";
import walletHistoryDialog from "@/components/wallet/walletHistoryDialog.vue";
import detailsDialog from "@/components/wallet/detailsDialog.vue";
import pointDialog from "@/components/wallet/pointDialog.vue";
import replacePhoneDialog from "./components/replacePhoneDialog.vue";
import { userList } from "@/api/User/index";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Name",
  components: {
    walletHistoryDialog,
    detailsDialog,
    pointDialog,
    replacePhoneDialog,
  },
  setup() {
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const list = ref<any>([]);
    const walletHistoryDialogRef = ref<any>();
    const walletDetailDialogRef = ref<any>();
    const pointDialogRef = ref<any>();
    const replacePhoneDialogRef = ref<any>();
    const searchSelect = ref({
      search: "account",
    });
    const mixin_router_search = ref({
      status: "",
      // order: "lastlogindate",
      // sort: "desc",
    });
    const router = useRouter();
    const mixin_select_search = ref({
      search: {
        nickname: "",
        account: "",
        userid: "",
      },
    });
    const mixin_select_search_label = readonly({
      search: {
        nickname: "昵称",
        account: "账号",
        userid: "ID",
      },
    });
    const reData = async (rq?: any) => {
      try {
        loading.value = true;
        let rqData: any = { page: page.value, limit: limit.value };
        if (rq && JSON.stringify(rq) !== "{}") {
          rqData = { ...rqData, ...rq };
        }
        const res = await userList(rqData);
        total.value = res.totalCount;
        list.value = res.result;
      } finally {
        loading.value = false;
      }
    };
    /**
     * 钱包流水
     */
    const onWalletHistoryClick = (userid :string,account:string) => {
      walletHistoryDialogRef.value.open(userid,account)
    }
    /**
     * 钱包明细
     */
    const onWalletDetailClick = (userid :string,account:string) =>{
      walletDetailDialogRef.value.open(userid,account)
    }
    /**
     * 积分记录
     */
    const onWalletPointClick = (userid :string,account:string) => {
      pointDialogRef.value.open(userid,account)
    }
    /**
     * 更换手机号
     */
    const onRepalcePhoneClick = (userid :string,account:string,name:string) =>{
      replacePhoneDialogRef.value.open(userid,account,name)
    }

    const success = async() =>{
      handelChange();
    }
    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      changSearch,
      mixinReRouterTableSortChange,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    });
    const handelChange = () => {
      page.value = 1;
      mixinReRouter();
    };

    const toPath = (path: string) => {
      router.push(path);
    };

    const roleHiddenPermission = inject("roleHiddenPermission");
    const toPath_RouterSearchMixin = inject("toPath_RouterSearchMixin");
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      walletHistoryDialogRef,
      walletDetailDialogRef,
      pointDialogRef,
      handelChange,
      handleLimitChange,
      onPageChange,
      onWalletHistoryClick,
      onWalletDetailClick,
      roleHiddenPermission,
      toPath,
      changSearch,
      success,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      onWalletPointClick,
      onRepalcePhoneClick,
      replacePhoneDialogRef,
    };
  },
});
