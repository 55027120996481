<template>
  <el-dialog :destroy-on-close="true" :title="`退款申请`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
   <el-alert
      :title="tipMsg"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="geRules" label-width="120px" ref="formRef" style="margin-top: 20px;">
      <el-form-item label="退款原因" prop="excuteInfo">
        <el-input :autosize="{ minRows: 2, maxRows: 4}" type="textarea" v-model="inputData.excuteInfo"></el-input>
      </el-form-item>
      <el-form-item label="查看费用" >
        <el-button style="margin-left: 16px;" type="primary" @click="handleChack">查询</el-button>
      </el-form-item>
      <el-form-item label="退款金额" prop="amount">
        <!-- <el-input-number :step="3" v-model="inputData.amount"></el-input-number> -->
        <el-input-number :min="0" :max="costs" :precision="2" :step="3" v-model="inputData.amount"></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <dueCoustDialog ref="dueCoustDialogRef"  @success="handlerDueCoust"></dueCoustDialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, computed } from 'vue'
import { onPrivateOrderReturnMoney } from '@/api/Order/Privatization'
import { userList } from "@/api/User/index";
import Rules from '@/common/Rules'
import { ElMessage, ElMessageBox } from 'element-plus'
import dueCoustDialog from '@/components/order/dueCoustDialog.vue';
import { userStore } from "@/store/modules/user";
import dayjs from 'dayjs'
let overdueId = ''
export default defineComponent({
  name: 'v-dialog',
  emits: ['success'],
  components:{
    dueCoustDialog
  },
  setup(props, { emit }) {
    const inputData = ref({
      remark: '',
      amount: 0,
      handleUserId:'',
      handleAccount:'',
      excuteInfo:'',
      overdueId:'',
    })
    const geRules = computed(() => {
      return {
        reason: [Rules.required()],
        amount: Rules.number(0, costs.value),
      }
    })
    const visible = ref(false)
    const costs = ref(0)
    const isVip = ref<boolean>(false);
    const phone = ref<string>('');
    const startTime = ref<string>('');
    const formRef = ref<any>('')
    const btnSumitLoading = ref(false)
    const userInfoList = ref<any>([]);
    const dueCoustDialogRef = ref<any>();
    const tipMsg = ref<string>("非会员用户，3小时内3元，超过3小时6元一天")
    const handleClose = () => {
      visible.value = false
      overdueId = ''
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const open = async (item:any) => {
      await getUserInfo(item.userId)
      visible.value = true
      inputData.value.amount = item.deductAmount
      costs.value = item.deductAmount
      overdueId = item.id
      startTime.value = item.orderStartTime
     }
    const handleChack = async() => {
      dueCoustDialogRef.value.open(startTime.value,isVip.value,true,phone.value,costs.value,true)
    }
    const handlerDueCoust = async(e:any) =>{
      inputData.value.amount=parseInt(e.data)
    }
    const getUserInfo = async (userid:string) => {     
      let rqData: any = { page: 1, limit: 10, userid:userid };
      const res = await userList(rqData);
      userInfoList.value = res.result;
      isVip.value=userInfoList.value[0].isvip==1;
      phone.value=userInfoList.value[0].account
      tipMsg.value="用户"+phone.value+"是"+(isVip.value?"会员用户，":"普通用户，")+(isVip.value?"计费是：小魔夹3元一天；雨伞3元一天 ":"计费是：小魔夹3小时内3元，超过3小时6元一天；雨伞3元一天")
    };
    const submit = async () => {
      try {
        await formRef.value.validate()
        await ElMessageBox.confirm(
          `请确认退款${inputData.value.amount}元？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        btnSumitLoading.value = true
        const time=dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");  
        inputData.value.remark=time+'发起退款'
        inputData.value.handleUserId=userStore.userInfo.userid!
        inputData.value.overdueId=overdueId
        inputData.value.handleAccount=userStore.userInfo.account!
        const res = await onPrivateOrderReturnMoney({
          ...inputData.value
        })
        ElMessage.success(res)
        emit('success')
        handleClose()
      } finally {
        btnSumitLoading.value = false
      }
    }

    return {
      visible,
      handleClose,
      open,
      tipMsg,
      btnSumitLoading,
      formRef,
      inputData,
      geRules,
      submit,
      costs,
      dueCoustDialogRef,
      handleChack,
      handlerDueCoust,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>