import { defineComponent as _defineComponent } from 'vue'
import { tweetList,tweetRemove } from '@/api/System/tweet'
import vDialog from "./components/dialog.vue"
import { ElMessage } from 'element-plus'
import { onMounted, ref } from 'vue'

export default _defineComponent({
  expose: [],
  setup(__props) {

const loading = ref(false)
const btnLoading = ref(false)
const dialogRef = ref()
const limit = ref(10)
const page = ref(1)
const total = ref(0)
const list = ref<any[]>([])
const reData = async (re?: boolean) => {
  if (re) page.value = 1
  try {
    loading.value = true
    let rqData: any = { page: page.value, limit: limit.value }
    const { result, totalCount } = await tweetList(rqData)
    list.value = result
    total.value = totalCount
  } finally {
    loading.value = false
  }
}
const onPageChange = (current: number) => {
  page.value = current
  reData()
}
const handleLimitChange = (size: number) => {
  if (limit?.value != undefined) limit.value = size
  reData(true)
}
onMounted(() => {
  reData(true)
})

const handleEdit = (row: any) => {
  dialogRef.value.open(row)
}
const handleRomve = async (index: number, tweetid: string) => {
  try {
    btnLoading.value = true
    const res = await tweetRemove({ tweetid })
    list.value.splice(index, 1)
    ElMessage.success(res)
  } finally {
    btnLoading.value = false
  }
}

return { loading, btnLoading, dialogRef, limit, page, total, list, reData, onPageChange, handleLimitChange, handleEdit, handleRomve, tweetList, tweetRemove, vDialog, ElMessage, onMounted, ref }
}

})