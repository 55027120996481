<template>
  <el-dialog
    :destroy-on-close="true"
    :title="title"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-table :data="list" height="40vh" style="width: 100%">
      <el-table-column label="模块" min-width="40" prop="sleeptime">
        <template #default="scope">
          <el-tag type="primary">{{scope.row.type}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="SIGN" min-width="70" prop="sign">
        <template #default="scope">
          <div v-if="scope.row.type=='4G'">deviceId: {{scope.row.sign}} <my-copy :data="scope.row.sign"></my-copy></div>
          <div v-if="scope.row.type=='BLE'">MAC: {{scope.row.sign.toUpperCase()}} <my-copy :data="scope.row.sign.toUpperCase()"></my-copy></div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" min-width="40">
        <template #default="scope">
          <el-button
            :loading="syncLoaidng"
            @click="handleSync(scope.row.sign)"
            size="mini"
            type="primary"
            v-if="scope.row.type=='4G'"
          >同步信息</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      :current-page="current"
      :page-size="size"
      :total="totalCount"
      @current-change="onPageChange"
      layout="prev, pager, next"
    ></el-pagination>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref } from "vue";
import { getComponentsList, ComponentsListRes } from "@/api/Component/Index";
import { getcrc } from "@/api/Share/Panel";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "v-dialog",
  setup() {
    const visible = ref(false);
    const loading = ref(false);
    const syncLoaidng = ref(false);
    const btnSumitLoading = ref(false);
    const title = ref('设备');
    const current = ref(1);
    const size = ref(10);
    const totalCount = ref(0);
    const sn = ref("");
    const list = ref<ComponentsListRes[]>([]);
    const handleClose = () => {
      visible.value = false;
      //   selfDataRef.value.resetFields(); //   selfDataRef.value.clearValidate();
    };
    const reData = async () => {
      try {
        loading.value = true;
        const { records, total } = await getComponentsList({
          current: current.value,
          size: size.value,
          entity: {
            equipmentsn: sn.value,
          },
        });
        list.value = records;
        totalCount.value = total;
      } finally {
        loading.value = false;
      }
    };
    const open = (_sn: string) => {
      visible.value = true;
      title.value=_sn;
      sn.value = _sn;
      reData();
    };
    const onPageChange = (e: any) => {
      current.value = e.value;
      reData();
    };
    const handleSync = async (deviceid: string) => {
      try {
        syncLoaidng.value = true;
        const res =  await getcrc({ deviceid });
        ElMessage.success(res);
      } finally {
        syncLoaidng.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      list,
      btnSumitLoading,
      current,
      totalCount,
      size,
      onPageChange,
      handleSync,
      syncLoaidng,
      title,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>