<template>
  <div class="container">
    <header>
      <!-- <el-button icon="el-icon-plus" @click="handleAdd" type="primary" v-if="roleShowPermission(['ROLE_SALESMAN'])">创建投资订单</el-button> -->
     <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search" >
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" class="header-select" clearable placeholder="投资订单状态" v-model="mixin_router_search.status">
        <el-option label="已下单" value="0"></el-option>
        <el-option label="已付款" value="1"></el-option>
        <el-option label="已出仓" value="2"></el-option>
        <el-option label="已开始投放" value="3"></el-option>
        <el-option label="已寄出" value="4"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.articleproductid">
          <el-option label="小魔夹MS2" value="56c62a11a6a14d6eb7ab29fbf9f2f8b4"></el-option>
          <el-option label="小魔夹MS3" value="811103983bcffe227dc6dddec51f468e"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否邮寄" v-model="mixin_router_search.expressed">
        <el-option label="已邮寄" value="true"></el-option>
        <el-option label="未邮寄" value="false"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="投放类型" v-model="mixin_router_search.launchmode">
        <el-option label="自投放" value="SELF"></el-option>
        <el-option label="平台投放" value="PLATFORM"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否为测试订单" v-model="mixin_router_search.fortest">
        <el-option :value="true" label="测试订单"></el-option>
        <el-option :value="false" label="正式订单"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="投资者类型" v-model="mixin_router_search.investortype">
        <el-option label="用户" value="individual"></el-option>
        <el-option label="公司" value="company"></el-option>
      </el-select>
      <el-select @change="handelChange" class="header-el-selector" clearable placeholder="投资方式" v-model="mixin_router_search.investmode">
        <el-option label="线上投资" value="online"></el-option>
        <el-option label="线下投资" value="offline"></el-option>
      </el-select>
  

      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="创建时间"
        range-separator="至"
        start-placeholder="创建时间"
        type="datetimerange"
        unlink-panels
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left:24px;">新增投资订单</el-button>
    </header>

    <el-table :data="list" :loading="loading" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
            <el-form-item label="创建时间">
              <span>{{ scope.row.creationdate }}</span>
            </el-form-item>
            <el-form-item label="投资人ID" v-if="scope.row.investoruserid">
              <my-click-data-to
                :data="scope.row.investoruserid"
                :params="[{search_userid:scope.row.investoruserid},{type:'investor'}]"
                url="/user/role"
              ></my-click-data-to>
              <my-copy :data="scope.row.investoruserid" />
            </el-form-item>
            <el-form-item label="投资订单号">
              <span>{{ scope.row.orderid }}</span>
              <my-copy :data="scope.row.orderid"></my-copy>
            </el-form-item>
            <div v-if="scope.row.mailingnumber">
              <el-form-item label="联系人">
                <span>{{ scope.row.mailingcontact }}</span>
              </el-form-item>
              <el-form-item label="联系人电话">
                <span>{{ scope.row.mailingnumber }}</span>
              </el-form-item>
              <el-form-item label="收件地址">
                <span>{{ scope.row.mailingaddress }}</span>
              </el-form-item>
            </div>
            <div v-if="scope.row.expressed">
              <el-form-item label="快递公司">
                <span>{{ scope.row.expressCompany }}</span>
              </el-form-item>
              <el-form-item label="快递单号">
                <span>{{ scope.row.expressNo }}</span>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="投资人" min-width="40">
        <template #default="scope">
           <color-icon type="icon-ceshi" v-if="scope.row.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
          {{scope.row.investorname}}{{scope.row.account}}</template>
      </el-table-column>
      <el-table-column label="订单状态" min-width="30" align="center">
        <template #default="scope"> 
          <div class="flex_ca">
            <div v-if="scope.row.status>0&&scope.row.rechargemode" style="margin-right: -50px;">
              <color-icon type="icon-ZHIMA" style="font-size:40px;color:#50b674" v-if="scope.row.rechargemode=='alipay'"></color-icon>
              <color-icon type="icon-PAYSCORE" style="font-size:40px;color:#50b674" v-if="scope.row.rechargemode=='wechatpay'"></color-icon>
              <color-icon type="icon-BALANCE" style="font-size:40px;color:#50b674" v-if="scope.row.rechargemode=='balance'||scope.row.rechargemode=='platform'"></color-icon>
            </div>
            {{getStatusName[scope.row.status]}}
          </div>    
        </template>
      </el-table-column>
      <el-table-column label="是否邮寄" min-width="20">
        <template #default="scope"> 
          {{ scope.row.expressed ?'是':'否'}}
        </template>
      </el-table-column>
      <el-table-column label="物品类型" min-width="28">
        <template #default="scope">{{scope.row.articleproductname}}</template>
      </el-table-column>
      <el-table-column label="投资者" min-width="24">
        <template #default="scope">{{scope.row.investortype=="company"?"公司":"用户"}}</template>
      </el-table-column>
      <el-table-column label="投资方式" min-width="24">
        <template #default="scope">{{scope.row.investmode=="online"?"线上投资":"线下投资"}}</template>
      </el-table-column>
      <el-table-column label="数量" min-width="20">
        <template #default="scope">{{scope.row.articlenum}}</template>
      </el-table-column>
      <el-table-column label="已分配" min-width="20">
        <template #default="scope">{{scope.row.assigned}}</template>
      </el-table-column>
      <el-table-column label="已投放" min-width="20">
        <template #default="scope">{{scope.row.launched}}</template>
      </el-table-column>
      <el-table-column label="未投放" min-width="20">
        <template #default="scope">{{scope.row.notReleased}}</template>
      </el-table-column>
      <el-table-column align="left" label="单价" min-width="20">
        <template #default="scope">¥ {{scope.row.singleamount}}</template>
      </el-table-column>
      <el-table-column align="left" label="订单总额" min-width="24">
        <template #default="scope">¥ {{scope.row.singleamount*scope.row.articlenum}}</template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="32" prop="creationdate"></el-table-column>
      <el-table-column align="left" label="操作" min-width="90">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope">
          <el-button @click="handleEdit(scope.$index,scope.row)" size="mini" v-if="scope.row.status=='0'">编辑</el-button>
          <el-button @click="handleRemove(scope.$index,scope.row.orderid)" size="mini" type="danger" v-show="scope.row.status=='0'">删除</el-button>
          <el-button @click="handleMail(scope.row)" size="mini" type="primary" v-show="scope.row.status!='0'&&!scope.row.expressed">填写邮寄</el-button>
          <el-button
            @click="handleConfirm(scope.$index,scope.row)"
            size="mini"
            type="success"
            v-if="roleShowPermission(['ROLE_FINANCE','ROLE_ADMIN_DVE'])"
            v-show="scope.row.investmode=='offline'&&scope.row.status=='0'"
          >确认收款</el-button>
          <!-- <el-button
            @click="handleCreateBillSales(scope.$index,scope.row)"
            size="mini"
            type="primary"
            v-show="scope.row.status=='1'||scope.row.status=='2'"
          >创建出货单</el-button>
          <el-button
            @click="toPath_RouterSearchMixin('/investment/shipment',[{search_investmentorderid:scope.row.orderid}])"
            size="mini"
            v-show="scope.row.status!='0'"
          >查看出货单</el-button> -->
          <!-- <el-button
            @click="handlerShipment(scope.row)"
            size="mini"
            v-show="scope.row.status!='0'"
          >出货单</el-button> -->
          <el-button
            @click="handlerShipment(scope.row)"
            size="mini"
            v-show="scope.row.status!='0'"
          >采购单</el-button>
          <el-button
            @click="handleConfirmdelivery(scope.$index,scope.row.orderid)"
            size="mini"
            type="success"
            v-if="scope.row.status=='2'"
          >确认交付</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <v-add-order-dialog-new @success="orderDialogSueccess" ref="addOrderDialogRefNew"></v-add-order-dialog-new>
    <v-add-order-dialog @success="orderDialogSueccess" ref="addOrderDialogRef"></v-add-order-dialog>
    <v-order-confrim-dialog @success="orderConfirmDialogSueccess" ref="orderConfrimDialogRef"></v-order-confrim-dialog>
    <v-order-mail-dialog @success="orderMailDialogSueccess" ref="orderMailDialogRef"></v-order-mail-dialog>
    <v-shipment-dialog ref="shipmentDialogRef"></v-shipment-dialog>
    <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
    <purchaseListDialog ref="purchaseListDialogRef"></purchaseListDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, readonly, inject } from 'vue'
import {
  investmentorderList,
  InvestmentorderListRes,
  investmentorderRemove,
  investmentorderCreateshipment,
  confirmdelivery,
  exportInvestmentorder,
} from '@/api/Investment/Order'
import type { toOptional } from '@/types/utils'
import routerChangeReData from '@/common/routerChangeReData'
import { pickerOptions } from './elementConfig'
import { getStatusName, getArticletypeName } from './editData'
import orderDialog from './components/orderDialog.vue'
import orderDialogNew from './components/orderDialogNew.vue'
import orderConfrimDialog from './components/orderConfrimDialog.vue'
import orderMailDialog from './components/orderMailDialog.vue'
import shipmentDialog from '../components/shipmentDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import purchaseListDialog from '@/components/investorment/purchaseListDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userStore } from '@/store/modules/user'
import { getShareProductInfo } from '@/api/util'
import { saveAs } from 'file-saver'
import axios from 'axios'
import myExport from '@/utils/exprotExcel'
import dayjs from 'dayjs'
import { any } from 'vue-types'
export default defineComponent({
  name: 'investment-order',
  components: {
    'v-add-order-dialog': orderDialog,
    'v-add-order-dialog-new': orderDialogNew,
    'v-order-confrim-dialog': orderConfrimDialog,
    'v-order-mail-dialog': orderMailDialog,
    'v-shipment-dialog':shipmentDialog,
    exportDialog,
    purchaseListDialog,
  },
  setup() {
    const mixin_router_search = ref({
      status: '', //投资订单状态，0 已下单；1 已付款；2 已出仓；3 已开始投放；4 已寄出；
      launchmode: '', //投放方式， SELF 自投放； PLATFORM 平台投放；
      expressed:'', //是否邮寄
      fortest: false, //是否用于测试，如：true 是 false 否
      investortype: '', //投资者类型，如：user 用户；platform 平台；
      investmode: '', //投资方式， online 线上投资 offline 线下投资
      freegift: '', //是否为免费赠送，如：true 是 false 否
      articleproductid: '56c62a11a6a14d6eb7ab29fbf9f2f8b4', //设备类型
      careateBN: '',
      order: 'creationdate',
      sort: 'desc',
    })
    const mixin_select_search = ref({
      search: {
        account:'', //投资人手机号
        investorname:'',//投资人姓名
        investoruserid: '', // 投资人用户编号
        orderid: '', //投资订单编码
      },
    })
    const sheareProductInfo = getShareProductInfo()
    const mixin_select_search_label = readonly({
      search: {
        investoruserid: '投资人ID', // 投资人用户编号
        orderid: '订单ID', //投资订单编码
        account:'手机号', 
        investorname:'姓名',
      },
    })
    const searchSelect = ref({search: 'account'})
    const url = require ("@/config/url.ts")
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0) 
    const list = ref<InvestmentorderListRes[]>([])
    const btnRemoveLoading = ref(false)
    const btnConfirmdeliveryLoading = ref(false)
    const addOrderDialogRefNew = ref<any>()
    const addOrderDialogRef = ref<any>()
    const orderConfrimDialogRef = ref<any>()
    const orderMailDialogRef = ref<any>()
    const shipmentDialogRef = ref<any>()
    const exportDialogRef = ref<any>();
    const purchaseListDialogRef = ref<any>();
    const searchData = {
      ...mixin_router_search.value,
      ...mixin_select_search.value,
    }
    type SearchData = typeof searchData

    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = {
          current: page.value,
          size: limit.value,
        }

        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          console.log("筛选时间",rq.careateBN);
          if (rq.careateBN) {
            rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
            rq.endTime =  dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
            delete rq.careateBN
          }
         rqData = { ...rqData, entity: rq }
        }
        const res = await investmentorderList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }

    const { mixinReRouter, onPageChange, handleLimitChange, changSearch} =
      routerChangeReData({
        mixin_router_search,
        mixin_select_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addOrderDialogRefNew.value.open()
    }
    const handleEdit = (index: number, row: any) => {
      addOrderDialogRef.value.open(row, index)
    }
    const orderDialogSueccess = (data: any) => {
      if (data.isEdit) {
        list.value.splice(data.index, 1, data.data)
      } else {
        //list.value.push(data.data)
        page.value = 1
        mixinReRouter()
      }
    }
    const orderMailDialogSueccess = () =>{
      page.value = 1
      mixinReRouter()
    }
    const handleRemove = async (index: number, orderid: string) => {
      try {
        btnRemoveLoading.value = true
        await investmentorderRemove({
          orderid,
        })
        list.value.splice(index, 1)
      } finally {
        btnRemoveLoading.value = false
      }
    }

    const handleConfirmdelivery = async (index: number, orderid: string) => {
      try {
        btnConfirmdeliveryLoading.value = true
        const res = await confirmdelivery({
          orderid,
          salesmanuserid: userStore.info.userid,
        })
        list.value[index].status = '3'
        ElMessage.success(res)
      } finally {
        btnConfirmdeliveryLoading.value = false
      }
    }

    const handleConfirm = async (index: number, row: any) => {
      row.index = index
      orderConfrimDialogRef.value.open(row)
    }
    const orderConfirmDialogSueccess = (index: number) => {
      list.value[index].status = '1'
    }
    const handleMail = (row: any) => {
      orderMailDialogRef.value.open(row)
    }
    const handleCreateBillSales = async (
      index: number,
      { articlenum, orderid }: any
    ) => {
      try {
        const input = await ElMessageBox.prompt('请输入出货数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: `请输入 1-${articlenum} `,
          inputType: 'number',
        })
        console.log('input :>> ', input)
        const _value = parseInt(input.value)
        if (input.value > articlenum || _value < 1)
          return ElMessage.error(`请输入 1-${articlenum}`)
        if (!userStore.info.userid) return userStore.loginOut()
        const res = await investmentorderCreateshipment({
          orderid,
          quantity: _value,
          shipperuserid: userStore.info.userid,
        })
        console.log('res :>> ', res)
        ElMessage.success(res)
      } catch (error) {
        if (error == 'cancel') return
        if (error == 'colse') return
        ElMessage.error(error)
      }
    }
    const handlerShipment = (item:any) => {
      //shipmentDialogRef.value.open(item.investorname||item.account,item.orderid,item.articlenum);
      purchaseListDialogRef.value.open(item.investorname||item.account,item.orderid,item.articlenum)
    }
    const handleExport = async() => {
      exportDialogRef.value.open('小魔夹投资订单表')
      
      // const excel = new myExport(`小魔夹投资订单列表`)
      // const _list = list.value.map((v) => {
      //   const _data = {
      //     姓名: v.investorname,
      //     手机号: v.account,
      //     时间: v.creationdate,
      //     投资方式: v.investmode=="online"?"线上投资":"线下投资",
      //     数量: v.articlenum,
      //     // 分成比: v.investorproportion + '%',
      //     单价: v.singleamount,
      //     总计: v.articlenum * v.singleamount,
      //     状态: getStatusName[v.status],
      //     销售员: v.salesmanuserid,
      //   }
      //   return _data
      // })
      // excel.addWorksheet({ headers: ['小魔夹投资订单'], list: _list })
      // excel.export()
    }
    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {}
        let rqData: any = {fileName:'investorOrder',fortest:mixin_router_search.value.fortest}
        
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
        
        console.log('导出参数=',searchData)
        if (searchData.careateBN) {
          rqData.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        delete searchData.careateBN
        delete searchData.order
        delete searchData.sort
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            rqData[key]=searchData[key]
          }
        })
        //let _url=url.baseUrl+'/v1/analy/client/export/exportInvestmentorderData?token='+userStore.userInfo.token+'&fileName=investor'
        // axios.post(_url, {
        //     ...rqData,
        //     headers: { "Access-Control-Allow-Origin": "*",}
        //   },
          
        //   {responseType: 'blob',}
        //   ).then((res) => {
        //     console.log('导表数据=',res);
        //     let blob = new Blob([JSON.stringify(res)]);
        //     let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
        //     let a = document.createElement('a');
        //     a.href = url;
        //     a.download = '投资订单.xlsx';
        //     a.click();
        //     window.URL.revokeObjectURL(url); // 释放该 url
        // }).catch((err) => {
        //     console.log(err);
        // });

        // await axios.request({
        //   url: _url,
        //   method: 'post',
        //   data: rqData,
        //   headers: {
        //     'Content-Type': 'application/json;charset=UTF-8',
        //     'Access-Control-Allow-Origin':' *',
        //     'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        //     'Access-Control-Allow-Headers':'x-requested-with,content-type'
        //   }
        // }).then((res:any) => {
        //   const blob = new Blob([JSON.stringify(res)],{ type: 'application/octet-stream' });
        //   saveAs(blob, `投资订单表.xls`)
        //     //callBack(res);
        // }).catch((e:any) => {
        //     console.log('错误=',e)
        //     //alert(e);
            
        // })

        const res = await exportInvestmentorder(rqData)
       
        const blob = new Blob([res.data]);
     
        saveAs(blob, name)
        // let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
        // let a = document.createElement('a');
        // a.href = url;
        // a.download = '投资订单.xlsx';
        // a.click();
        // window.URL.revokeObjectURL(url); // 释放该 url
        // let blob = new Blob([res], {
        //   type: res.data.type,
        // });
      } finally {
       
      }
    }
    const roleShowPermission = inject('roleShowPermission')
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    return {
      mixin_router_search,
      mixin_select_search,
      mixin_select_search_label,
      pickerOptions,
      onPageChange,
      handelChange,
      handleLimitChange,
      searchSelect,
      changSearch,
      list,
      loading,
      getStatusName,
      getArticletypeName,
      handleAdd,
      addOrderDialogRefNew,
      addOrderDialogRef,
      orderConfrimDialogRef,
      orderMailDialogRef,
      handleEdit,
      orderDialogSueccess,
      orderMailDialogSueccess,
      handleRemove,
      btnRemoveLoading,
      handleConfirm,
      roleShowPermission,
      orderConfirmDialogSueccess,
      page,
      limit,
      total,
      handleCreateBillSales,
      toPath_RouterSearchMixin,
      handleConfirmdelivery,
      sheareProductInfo,
      handleExport,
      handleMail,
      shipmentDialogRef,
      handlerShipment,
      exportDialogRef,
      exportFile,
      purchaseListDialogRef,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select{
    width: 320px;

    margin-right: 16px;
    /deep/.el-input{
      width: 120px;
    }
  }
  .input-select-tip{
      width: 130px;
  }
  header {
    display: flex;
    flex-wrap: wrap;
    .header-select {
      width: 130px;
      margin: 0 24px 24px 0;
      ~ * {
        margin: 0 24px 24px 0;
      }
     /deep/.el-input {
        width: 130px;
       
      }
       .el-select {
        width: 130px;
        /deep/.el-input{
          width: 130px;
        }
      }
    }
    .content {
      padding: 0;
    }
 
  }
  .no-more {
    color: #aaa;
  }
  .el-table {
    .el-button {
      margin: 0.04rem;
    }
  }

  .header-el-selector {
    width: 120px;
    margin-right: 12px;
     /deep/.el-input {
      width: 120px;
    }
  }
}
</style>