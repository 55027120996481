<template>
  <el-dialog
    :title="`${isEidt?'编辑':'添加'}面板`"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-form :model="inputData" :rules="rules" label-width="120px" ref="fromRef">
      <el-form-item label="设备SN" prop="panelequipmentsn" v-if="!isEidt">
        <el-input v-model="inputData.panelequipmentsn"></el-input>
      </el-form-item>
      <el-form-item label="场所ID" prop="placeid">
        <el-input v-model="inputData.placeid"></el-input>
      </el-form-item>
      <el-form-item label="在场所的位置" prop="location">
        <el-input v-model="inputData.location"></el-input>
      </el-form-item>
      <el-form-item label="维修员ID" prop="manageruserid">
        <el-input v-model="inputData.manageruserid"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, nextTick, readonly, ref } from "vue";
import { placeBindpanel, upDatePanel } from "@/api/Share/Panel";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "v-dialog",
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const isEidt = ref(false);
    let index = -1;
    const inputData = ref({
      panelequipmentsn: "", // 共享面板的设备序号
      placeid: "", // 共享面板放置的场所编码
      location: "", // 共享面板放在于场所的位置，如： 前台; 中央; 左侧; 右侧; A出口; B出口;等等
      manageruserid: "", // 管理人用户编码，即维修员用户编码，如：53
    });
    const rules = readonly({
      panelequipmentsn: [Rules.required()],
      placeid: [Rules.required()],
      location: [Rules.required()],
      manageruserid: [Rules.required()],
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
      console.log("fromRef.value :>> ", fromRef.value);
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      isEidt.value = false;
    };
    const open = (_index: number, row?: any) => {
      visible.value = true;
      if (_index > -1) {
        isEidt.value = true;
        index = _index;
      }
      const _inputData: any = {};
      Object.keys(inputData.value).map((v) => {
        _inputData[v] = row[v];
      });
      inputData.value = Object.assign({}, inputData.value, _inputData);
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        let res: any;
        if (isEidt.value) {
          res = await upDatePanel(inputData.value);
        } else {
          res = await placeBindpanel(inputData.value);
        }
        handleClose();
        await nextTick();
        ElMessage.success(res.message);
        emit("success", {
          index,
          data: res.record,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      isEidt,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>