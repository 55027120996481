
import { appStore } from '@/store/modules/app'
import { computed, defineComponent, toRef } from 'vue'
import type { AppRouteRecordRawChildren } from '@/router/types'
import { useRoute, useRouter } from 'vue-router'
import { TagsViewStore } from '@/store/modules/tagsView'
export default defineComponent({
  name: 'v-item',
  props: ['route', 'mobile'],
  setup(props) {
    const route = toRef(props, 'route')
    const router = useRouter()
    const _route = useRoute()
    // const ctrlFlag = ref(false);
    const sidebar = computed(() => appStore.getSidebar)
    const show = computed(() => !route.value.hidden && route.value.meta)
    const toPath = (url: string) => {
      console.log('url :>> ', url)
      // router.push(url);
    }
    const handleClick = (path: string, children: any) => {
      console.log('path,children :>> ', path, children)
      if (children) return
      const routeData = router.resolve({
        path,
      })
      window.open(routeData.href, '_blank')
    }
    const handleAddTagsView = ({ name, path, meta }: any) => {
      if (path == _route.path) return
      TagsViewStore.addTages({ name, path, meta })
    }
    return {
      show,
      sidebar,
      toPath,
      handleClick,
      handleAddTagsView,
    }
  },
})
