
import { defineComponent, ref, readonly, inject } from 'vue'
import privatizationDrewer from './components/privatizationdrawer.vue'
import routerChangeReData from '@/common/routerChangeReData'
import negotiationInfo from './components/negotiationInfo.vue'
import retrunInfo from './components/retrunInfo.vue'
import confrimRetrunDialog from "./components/confrimRetrunDialog.vue"
import {
  sharedorderprivatizationdeductionList,
  SharedorderprivatizationdeductionList,
  sharedorderprivatizationnegotiationLoad,
  sharedorderprivatizationreturnConfirm,
  sharedorderprivatizationreturnLoad,
  sharedorderprivatizationprocessRelaunchStart,
  sharedorderprivatizationprocessLaunchComplete,
  sharedorderprivatizationprocessDeduction,
} from '@/api/Order/Privatization'
import negotiationDialog from './components/negotiationDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'orderManage',
  components: {
    negotiationDialog,
    negotiationInfo,
    retrunInfo,
    confrimRetrunDialog,
    privatizationDrewer,
  },
  setup() {
    const loading = ref(false)
    const reDeductionLoading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<SharedorderprivatizationdeductionList[]>([])
    const negotiationDialogRef = ref<any>({})
    const confrimRetrunDialogRef = ref<any>({})
    const privatizationDrewerRef= ref<any>()
    const searchSelect = ref({ search: 'articleequipmentsn' })
    const allStatus = readonly({
      '00': '已启动',
      '01': '开始谈判',
      '02': '谈判完成',
      '11': '开始扣款',
      '12': '扣款完成',
      '21': '重新开始投放',
      '22': '投放完成',
      '31': '开始赔偿投资人',
      '32': '赔偿投资人完成',
      '99': '已完结',
    })
    const allStatusColor = readonly({
      '00': 'info',
      '01': 'info',
      '02': 'info',
      '11': 'danger',
      '12': 'waring',
      '21': 'primary',
      '22': 'success',
      '31': 'waring',
      '32': 'primary',
      '99': 'success',
    })
    const mixin_router_search = ref({
      //   fortest: false,
      order: 'receivedate',
      sort: 'desc',
      model: '',
      articleproductmodel: '',
      status: '',
    })
    const mixin_select_search = ref({
      search: {
        articleequipmentsn: '',
        account: '',
        sharedorderid: '',
        userid: '',
      },
    })

    const mixin_select_search_label = readonly({
      search: {
        articleequipmentsn: '设备SN',
        account: '用户账号',
        sharedorderid: '订单号',
        userid: '用户ID',
      },
    })
    const toPath_RouterSearchMixin = inject('toPath_RouterSearchMixin')
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          if (rq.order) {
            const { order, sort } = rq
            delete rq.order
            delete rq.sort
            rqData = { ...rqData, order, sort }
          }
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderprivatizationdeductionList(rqData)
        list.value = res.records
        total.value = res.total
      } finally {
        loading.value = false
      }
    }
     const onShowDrawer = (item:any) => {
      console.log('点击弹出');
      console.log(privatizationDrewerRef)
      privatizationDrewerRef.value.open(item);
    }

    const {
      mixinReRouter,
      onPageChange,
      handleLimitChange,
      mixinReRouterTableSortChange,
      changSearch,
    } = routerChangeReData({
      mixin_select_search,
      mixin_router_search,
      loading,
      limit,
      page,
      reData,
      searchSelect,
    })

    /**
     * 参数筛选改变
     */
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }

    /**
     * 开启沟通按钮
     */
    const handleNegotiation = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }
    const handleNegotiationResult = (sharedorderid: string) => {
      negotiationDialogRef.value.open(sharedorderid)
    }

    /**
     * 是否展开获取订单详情
     * 加载私有化订单
     */
    const handleOpen = async (e: SharedorderprivatizationdeductionList) => {
      list.value[e.idx].open = !list.value[e.idx].open // 开启关闭状态
      if (!list.value[e.idx].open) return
      const status = parseInt(e.status)
      if (status > 0) {
        const _negotiationInfo = await sharedorderprivatizationnegotiationLoad(
          e.negotiationid
        )
        list.value[e.idx].negotiationInfo = _negotiationInfo
      }
      // if (status > 0) {
      //   const _returnInfo = await sharedorderprivatizationreturnLoad(
      //     e.negotiationid
      //   );
      //   list.value[e.idx].returnInfo = _returnInfo;
      // }
    }

    /**
     * 确认归还
     */
    const handleRetrun = async (row: SharedorderprivatizationdeductionList) => {
      confrimRetrunDialogRef.value.open(row)
    }

    /**
     * 开始投放
     */
    const handleRelaunch = async (sharedorderid: string) => {
      const res = await sharedorderprivatizationprocessRelaunchStart(
        sharedorderid
      )
      ElMessage.success(res)
      mixinReRouter()
    }

    /**
     * 投放完成
     */
    const handleRelaunchSuccess = async (sharedorderid: string) => {
      const res = await sharedorderprivatizationprocessLaunchComplete(
        sharedorderid
      )
      mixinReRouter()
      ElMessage.success(res)
    }

    /**
     * 开始扣款
     */
    const handleReDeduction = async (sharedorderid: string) => {
      try {
        reDeductionLoading.value = true
        const res = await sharedorderprivatizationprocessDeduction(
          sharedorderid
        )
        mixinReRouter()
        ElMessage.success(res)
      } finally {
        reDeductionLoading.value = false
      }
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      toPath_RouterSearchMixin,
      mixinReRouterTableSortChange,
      changSearch,
      allStatus,
      allStatusColor,
      handleNegotiation,
      negotiationDialogRef,
      mixinReRouter,
      handleNegotiationResult,
      handleOpen,
      handleRetrun,
      handleRelaunch,
      handleRelaunchSuccess,
      handleReDeduction,
      confrimRetrunDialogRef,
      privatizationDrewerRef,
      onShowDrawer,
    }
  },
})
