<!-- 分配小魔夹 -->
<template>
    <el-dialog
      :destroy-on-close="true"
      :title="`新增无投资订单出货单`"
      @closed="handleClose"
      class="title"
      v-model="visible"
      width="750px"
    >
      
    <el-form :model="inputData" label-width="120px" ref="formRef" :rules="rules">
      <el-form-item label="设备类型" class="tip-text" prop="productid" >
        <el-select  placeholder="请选择设备类型" v-model="inputData.productid">
          <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of productList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="采购数量" prop="quantity">
        <el-input style="width: 220px;" placeholder="请输入采购数量" v-model="inputData.quantity" text="number"></el-input>
      </el-form-item>
    </el-form>
  
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
    </el-dialog>
  </template>
  <script lang="ts">
  import Rules from '@/common/Rules'
  import { defineComponent, ref } from 'vue'
  import { getProductInfoList } from '@/api/util'
  import { ElMessage } from 'element-plus'
  import { userStore } from '@/store/modules/user'
  import { addNoInvestorOrderShipmentHttp} from "@/api/Investment/shipment";
  export default defineComponent({
    name: 'assign-new-dialog',
    emits: ['success'],
    setup(props, { emit }) {
      const visible = ref(false)
      const btnSumitLoading = ref(false)
      const btnCancelLoading = ref(false)
      const productList = getProductInfoList()
      const formRef =ref<any>("");
      const rules = {
        quantity: [Rules.required()],
      }
      const inputData = ref({
        productid: "",
        quantity:'',
      });
      const handleClose = () => {
        btnSumitLoading.value = false
        visible.value = false
        formRef.value.resetFields();
        formRef.value.clearValidate();
      }
      const open = (data: any, _isShow = false) => {
      
        visible.value = true
    
      }
     
      const submit = async() => {
        try {
          try {
            await formRef.value.validate();
          } catch (error) {
            console.log(error);
            throw Error("请检查输入");
          }
          btnSumitLoading.value = true;
          const res = await addNoInvestorOrderShipmentHttp({
            quantity: inputData.value.quantity,
            productId:inputData.value.productid,
            shipperUserId: userStore.info.userid,
          })
          ElMessage.success(res);
          handleClose();
          emit('success',res)
        }finally {
          btnSumitLoading.value = false;
        }
      }
      return {
        visible,
        handleClose,
        open,
        rules,
        productList,
        inputData,
        btnSumitLoading,
        btnCancelLoading,
        submit,
        formRef,
      }
    },
  })
  </script>
  <style lang="scss" scoped>
  .header {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    .input-with-select {
      margin-right: 24px;
      width: 320px;
      .el-select {
        width: 150px;
      }
      ~ * {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
  }
  .el-tag {
    margin-left: 12px;
  }
  </style>