import { defHttp } from "@/utils/http/axios";
import { RequestReq, RequestRes, ListRes, ListReq } from "@/types/requestBaseType"
import dayjs from "dayjs"
import { userStore } from "@/store/modules/user";
import { holdertype } from "@/api/Share/Device"
export interface OrderList {
    articleequipmentsn: string
    articleproductid: string
    articleproductmodel: string
    articleproductname: string
    articleproducttype: string
    autorenewal: boolean
    closed: boolean
    costs: null | number
    creationdate: string
    deductedamount: null | number
    deliveraddress: null | string
    delivercityareacode: null | string
    deliverdate: null | string
    deliverdateBegin: null | string
    deliverdateEnd: null | string
    deliverdistrictareacode: null | string
    deliverlatitude: null | string
    deliverlongitude: null | string
    deliverprovinceareacode: null | string
    deliverstreetareacode: null | string
    firstorder: boolean
    formerholderaccount: null | string
    formerholderpanelequipmentsn: string
    formerholderplaceid: string
    formerholderplacename: string
    formerholdersocketsn: string
    formerholdertype: keyof typeof holdertype
    formerholderuserid: null | string
    formersharedorderid: null | string
    fortest: boolean
    guaranteetype: string
    latterholderaccount: null | string
    latterholderpanelequipmentsn: null | string
    latterholderplaceid: null | string
    latterholderplacename: null | string
    latterholdersocketsn: null | string
    latterholdertype: null | keyof typeof holdertype
    latterholderuserid: null | string
    lattersharedorderid: null | string
    modificationdate: string
    outorderno: string
    paid: boolean
    privatizationexpirationdate: string
    privatizationexpirationdateBegin: null | string
    privatizationexpirationdateEnd: null | string
    privatizationwarned: boolean
    privatizationwarningdate: string
    privatizationwarningid: null | string
    privatized: boolean | number
    profitedamount: null | string
    receiveaddress: string
    receivecityareacode: string
    receivedate: string
    receivedateBegin: null | string
    receivedateEnd: null | string
    receivedistrictareacode: string
    receivelatitude: number
    receivelongitude: number
    receiveprovinceareacode: string
    receiveraccount: string
    receiveruserid: string
    receivestreetareacode: string
    renewalamount: null | number
    renewaldays: null | number
    renewed: boolean
    sharedorderid: string
    transferredenvelopeid: null | string
}

export async function getOrderList(params: ListReq): Promise<ListRes<OrderList>> {
    params.token = true
    const res = await defHttp.request<ListRes<OrderList>>(
        {
            url: `/sharedordermemo/infolist`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.receivedate = dayjs(v.receivedate).format("YYYY-MM-DD HH:mm:ss")
        if (v.deliverdate) v.deliverdate = dayjs(v.deliverdate).format("YYYY-MM-DD HH:mm:ss")
        v.privatizationexpirationdate = dayjs(v.privatizationexpirationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}
export async function sharedsocketMountarticle(params: { articleequipmentsn: string, panelequipmentsn: string, socketsn: string }): Promise<string> {
    const res = await defHttp.request<string>(
        {
            url: `/sharedsocket/mountarticle`,
            params: { token: true, ...params },

        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res
}
export async function sharedordermemoApplyrefund(params: { sharedorderid: string, reason: string, amount: number }): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedordermemo/applyrefund`,
            params: { token: true, ...params, submitteruserid: userStore.userInfo.userid },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },

    }
    );
    return res.message || "申请成功"
}
export async function sharedordermemoSettleup(params: { sharedorderid: string, rentalfee?: number }): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedordermemo/settleup`,
            params: { token: true, ...params },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message || "申请成功"
}
export async function distributeprofit(sharedorderid: string): Promise<string> {
    const res = await defHttp.request<RequestRes>(
        {
            url: `/sharedordermemo/distributeprofit`,
            params: { token: true, sharedorderid },
        }, {
        prefix: "/shared",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    );
    return res.message || "分成成功"
}