<template>
    <div class="container">
      <header>
        <el-input @keyup.enter="handelChange" class="input-with-select" placeholder="请输入搜索内容" v-model="mixin_select_search.search[searchSelect.search]">
          <template #prepend>
            <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
              <el-option :key="item" :label="mixin_select_search_label.search[item]" :value="item" v-for="item of Object.keys(mixin_select_search.search)"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="handelChange" icon="el-icon-search"></el-button>
          </template>
        </el-input>
        <!-- <el-button @click="handleAdd" icon="el-icon-plus" type="primary">绑定设备</el-button> -->
      </header>
      <div style="height: 80vh;">
        <el-table :data="list" max-height="80vh"  style="width: 100%;margin-top: 18px;" v-if="list" >
        <!-- <el-table :data="list" height="80vh" style="width: 100%" v-loading="true"> -->
          <el-table-column type="expand">
            <template #default="scope">
              <el-form class="table-expand" inline label-position="left" label-width="120px">
                <el-form-item label="产品id">
                  <span>{{ scope.row.productId }}</span>
                </el-form-item>
                <el-form-item label="绑定人id">
                  <span>{{ scope.row.userId }}</span>
                  <my-copy :data="scope.row.userId" />
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="SN" min-width="40">
            <template #default="scope">
              <my-copy :data="scope.row.equipmentSn" />
              {{scope.row.equipmentSn}}
              <hover-show-qr :logo="`${scope.row.prefix}`" :text="scope.row.equipmentSn" :key="scope.row.equipmentSn" :productid="scope.row.productId"></hover-show-qr>
            </template>
          </el-table-column>
          <el-table-column label="设备类型" min-width="40" align="center">
            <template #default="scope">
              <el-tag v-if="scope.row.productId">{{productList[productIdList.indexOf(scope.row.productId)].name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="绑定账号" min-width="60" align="center">
            <template #default="scope">
                {{scope.row.userAccount}}
                <my-copy :data="scope.row.userAccount" />
            </template>
          </el-table-column>
          <el-table-column label="绑定人" min-width="60" align="center">
            <template #default="scope">{{scope.row.userName}}</template>
          </el-table-column>
          <el-table-column label="绑定时间" min-width="90" align="center">
            <template #default="scope">{{scope.row.gmtCreate}}</template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right">
            <template #default="scope">
              <el-button
                @click="handleRemove(scope.row)"
                size="mini"
                type="danger"
              >解绑</el-button> 
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination 
      :current-page="page" 
      :page-size="limit" 
      :page-sizes="[10, 30, 50, 100]" 
      :total="total" @current-change="onPageChange" 
      @size-change="handleLimitChange" 
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    </div>
    <addDialog ref="addDialogRef"></addDialog>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, readonly } from "vue";
  import routerChangeReData from "@/common/routerChangeReData";
  import { getPrivateDeviceList, DeviceModel,removePrivateDeivce } from "@/api/Share/Private";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { getAdInfoList,getProductInfoList,getProductIdList} from '@/api/util'
  import { useRouter } from "vue-router";
  import addDialog from "./components/addDialog.vue"
  export default defineComponent({
    name: "Name",
    components: {
      addDialog,
    },
    setup() {
      const loading = ref(false);
      const btnRemoveLoaidng = ref(false);
      const limit = ref(10);
      const page = ref(1);
      const total = ref(0);
      const router = useRouter();
      // const list = ref<DeviceListRes[]>([]);
      const list =ref<DeviceModel[]>([]);

      const adInfoList = getAdInfoList()
      const productList = getProductInfoList()
      const productIdList = getProductIdList()
      const addDialogRef = ref<any>();
      const toPath = (path: string) => {
        router.push(path);
      };
      const searchSelect = ref({
        search: "equipmentSn",
      });
      const mixin_select_search = ref({
        search: {
          equipmentSn: "", //共享面板的设备序号，如：小魔柜SN；雨伞架SN
          userAccount: "",
          userId:""
        },
      });
  
      const mixin_select_search_label = readonly({
        search: {
          equipmentSn: "设备SN",
          userAccount: "手机号",
          userId:"用户id"
        },
      });
  
      const mixin_router_search = ref(
        { socketstatus: "",
          productid:"",
          order: "gmtCreate",
          sort: "desc" 
        });
      const reData = async (rq?: any) => {
        try {
          loading.value = true;
          let rqData: any = { current: page.value, size: limit.value };
          if (rq && JSON.stringify(rq) !== "{}") {
            rqData = { ...rqData, entity: rq };
           // rqData = { ...rqData, ...rq }
          }else{
            rqData = { ...rqData, entity: {} };
          }
          const res = await getPrivateDeviceList(rqData);
          console.log('返回数据=',res)
          
          total.value = res.total;
          list.value = res.rows;
        } finally {
          loading.value = false;
        }
      };
      const {
        mixinReRouter,
        onPageChange,
        handleLimitChange,
        changSearch,
      } = routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      });
      const handelChange = () => {
        page.value = 1;
        mixinReRouter();
      };
   
     
      const handleRemove = async(item:any) =>{
        await ElMessageBox.confirm(
          `是否解绑账号=${item.userAccount}的小魔夹=${item.equipmentSn}`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            distinguishCancelAndClose: true,
          }
        )
        const res = await removePrivateDeivce(item.equipmentSn)
        page.value=1;
        reData();
        ElMessage.success(res.msg)
      }
     
      const handleAdd = async() =>{
        addDialogRef.value.open();
      }
      return {
        mixin_select_search_label,
        mixin_select_search,
        mixin_router_search,
        changSearch,
        searchSelect,
        list,
        loading,
        page,
        total,
        limit,
        adInfoList,
        productList,
        productIdList,
        addDialogRef,
        toPath,
        handelChange,
        handleLimitChange,
        onPageChange,
        handleRemove,
        handleAdd,
        btnRemoveLoaidng,
      };
    },
  });
  </script>
  <style lang="scss" scoped>
  .container {
    padding: 18px;
    .input-with-select {
      margin-right: 24px;
      width: 320px;
      /deep/.el-input {
        width: 120px;
      }
      ~ * {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
  
    .header-el-selector {
      width: 120px;
      margin-right: 12px;
       /deep/.el-input {
        width: 120px;
      }
    }
  
    .el-table{
      /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
      }
    }
  }
  </style>