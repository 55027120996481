<template>
  <el-dialog
    title="生成新二维码"
    @closed="handleClose"
    align="center"
    class="dialog"
    v-model="visible"
    width="750px"
  >
    <el-alert
      title="生成二维码需要长时间，1万带4G和蓝牙预计2.5小时，1万带蓝牙不带4G估计50分钟，点击确定之后，需要等待一段时间，页面关闭也会自动生成"
      type="warning"
      :closable="false"
      show-icon
      style="margin-bottom: 16px;">
    </el-alert>
    <el-form :model="inputData" :rules="rules" label-width="120px" ref="fromRef">
      <el-form-item label="设备类型" class="tip-text" prop="productid" >
        <el-select  placeholder="请选择设备类型" v-model="inputData.productid">
          <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of productList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数量" prop="number">
        <el-input style="width: 220px;" placeholder="请输入数量" v-model="inputData.number" text="number"></el-input>
      </el-form-item>
      <el-form-item label="是否带蓝牙" prop="initble">
        <el-select placeholder="请选择是否带蓝牙" v-model="inputData.initble " class="tip-text">
          <el-option label="带蓝牙" :value='true'></el-option>
          <el-option label="不带蓝牙" :value='false'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是的带4G" prop="init4g">
        <el-select placeholder="请选择是否带蓝牙" v-model="inputData.init4g " class="tip-text">
          <el-option label="带4G" :value='true'></el-option>
          <el-option label="不带4G" :value='false'></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, nextTick, readonly, ref } from "vue";
import { generateNewSn } from '@/api/Share/Device'
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import {getProductInfoList,getProductIdList} from '@/api/util'
export default defineComponent({
  name: "v-dialog",
  emits:['success'],
  setup(peops, { emit }) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const productList = getProductInfoList()
    const productIdList = getProductIdList()
    const inputData = ref({
      productid: "", 
      number: "", 
      initble: "", 
      init4g: "", 
    });
    const rules = readonly({
      productid: [Rules.required()],
      quantity: [Rules.required()],
      initble: [Rules.required()],
      init4g: [Rules.required()],
    });
    const fromRef = ref<any>({});
    const handleClose = async () => {
    
      fromRef.value.resetFields();
      fromRef.value.clearValidate();
      await nextTick();
      visible.value = false;
     
    };
    const open = () => {
      visible.value = true;
      
    };
    const submit = async () => {
      try {
        await fromRef.value.validate();
        btnSumitLoading.value = true;
        console.log('生成参数=',inputData.value)
        const res = await generateNewSn(inputData.value)
        console.log('返回=',res)
        handleClose();
        ElMessage.success(res.message);
        
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      rules,
      fromRef,
      productList,
      productIdList,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>