
import Rules from '@/common/Rules'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'v-dialog',
  emits: ['export'],
  setup(props, { emit }) {
    const visible = ref(false)
    const btnSumitLoading = ref(false)
    const formRef = ref()
    const radio = ref('1')
    const handleClose = () => {
      visible.value = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const rules = {
      name: [Rules.required()],
      descriptiondetails: [Rules.required()],
      identificationId: [Rules.required()],
    }
    const form = ref({
      name: '',
    })
    const open = (name: string) => {
      visible.value = true
      form.value.name=name
    }

    const submit = async () => {
      let fileType="";
      if(radio.value=='1'){
        fileType="xls"
      }else if(radio.value=='2'){
        fileType="xlsx";
      }else if(radio.value=='3'){
        fileType="excel";
      }
      try {
        handleClose()
        emit('export',`${form.value.name}.${fileType}`)
      } finally {
        btnSumitLoading.value = false
      }
    }
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      form,
      rules,
      formRef,
      submit,
      radio,
    }
  },
})
