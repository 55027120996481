  <!-- 菜单配置 -->
  <template>
  <div class="container">
    <header>
      <el-button @click="handleAdd" circle icon="el-icon-plus" type="primary"></el-button>
      <el-button @click="openRoleMenuDialog" icon="el-icon-edit" style="margin-left:50px;" type="primary">角色权限编辑</el-button>
      <el-select @change="handelChange" placeholder="平台投放开始状态" style="margin-left:50px;" v-model="appid">
        <el-option :value="1" label="老系统"></el-option>
        <el-option :value="2" label="新系统"></el-option>
      </el-select>
    </header>
    <el-table :data="tableData" :row-class-name="getRowClassName" :tree-props="{children: 'child'}" height="750" id="systemMenuList" ref="dragTableRef" row-key="treeid" style="width: 100%">
      <el-table-column align="left" label="序号" min-width="20" prop="treeorder"></el-table-column>
      <el-table-column label="标题" min-width="30" prop="name">
        <template #default="scope">
          <span>{{scope.row.name}}</span>
          <my-copy :data="scope.row.treeid"></my-copy>
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="30" prop="code"></el-table-column>
      <el-table-column label="连接地址" min-width="50" prop="url"></el-table-column>
      <el-table-column label="路径" min-width="50" prop="path"></el-table-column>
      <el-table-column label="图标" min-width="40">
        <template #default="scope">
          <span class="iconfont" :class="scope.row.icon"></span>&ensp;&ensp;
          <span>{{scope.row.icon}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="40">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.state=='1'">已启用</el-tag>
          <el-tag type="warning" v-if="scope.row.state=='0'">已禁用</el-tag>
          <span>&ensp;&ensp;</span>
          <el-tag type="success" v-if="scope.row.hidden=='false'">显示</el-tag>
          <el-tag type="warning" v-if="scope.row.hidden=='true'">隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="90">
        <template #default="scope">
          <el-button @click="handleEdit(scope.row)" size="mini">编辑</el-button>
          <!-- <el-button
            size="mini"
            v-if="scope.row.state=='1'"
            type="danger"
            @click="handleOnOff(scope.$index, scope.row)"
            :loading="btnOnOffLoaidng"
          >禁用</el-button>
          <el-button
            size="mini"
            v-else
            type="primary"
            @click="handleOnOff(scope.$index, scope.row)"
            :loading="btnOnOffLoaidng"
          >启用</el-button>-->
          <el-button :loading="btnHiddenLoaidng" @click="handleHidden(scope.$index, scope.row)" size="mini" type="primary" v-if="scope.row.hidden=='true'">点击显示</el-button>
          <el-button :loading="btnHiddenLoaidng" @click="handleHidden(scope.$index, scope.row)" size="mini" type="danger" v-else>点击隐藏</el-button>
          <el-button :loading="btnRemoveLoaidng" @click="handleAddChild(scope.row)" size="mini" type="primary">添加</el-button>
          <el-button :loading="btnRemoveLoaidng" @click="handleRemove(scope.$index, scope.row)" size="mini" type="danger">删除</el-button>
          <span class="el-icon-rank"></span>
        </template>
      </el-table-column>
    </el-table>
    <v-edit @re-data="reData" ref="editMenuRef" :appid="appid"></v-edit>
    <v-role-menu-dialog ref="roleMenuDialogRef" :appid="appid"></v-role-menu-dialog>
  </div>
</template>

<script lang="ts">
import {
  treeLoadTree,
  MenuListResData,
  treeUpdate,
  treeRemove,
} from "@/api/System";
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import Edit from "./components/menuDialog.vue";
import RoleMenuDialog from "./components/roleMenuDialog.vue";
import Sortable from "sortablejs";

/**
 * @type:  oldIndex:string newIndex:string
 */
function getIndexs(evt: any, type: any) {
  // evt = JSON.parse(JSON.stringify(evt));
  console.log("evt :>> ", evt);
  const classIndex =
    type == "newIndex"
      ? evt.to.rows[evt[type]].classList[1]
      : evt.from.rows[evt[type]].classList[1];
  // : evt.clone.classList[1];
  const indexs: number[] = classIndex.split("_")[1].split(",");
  return indexs.map((v) => (typeof v == "number" ? v : parseInt(v)));
}
// import vuedraggable from "./components/vuedraggable";
function checkMenuChange(
  list: MenuListResData[],
  initList: MenuListResData[],
  res: any = []
) {
  list.map((v, index) => {
    const initItem = initList[index];
    const { treeorder, parenttreeid, treeid } = v;
    if (
      treeid == initItem.treeid &&
      (treeorder != initItem.treeorder || parenttreeid != initItem.parenttreeid)
    ) {
      res.push({ treeorder, parenttreeid, treeid });
    }
    if (v.child.length > 0) {
      checkMenuChange(v.child, initList[index].child, res);
    }
  });
}

export default defineComponent({
  name: "Name",
  components: {
    "v-edit": Edit,
    "v-role-menu-dialog": RoleMenuDialog,
  },
  setup() {
    const loading = ref(false);
    const btnRemoveLoaidng = ref(false);
    const btnHiddenLoaidng = ref(false);
    const tableData = ref<MenuListResData[]>();
    const initTableData = ref<MenuListResData[]>();
    const sortable = ref({});
    const { changeMenuPassward } = require("@/config/setting.ts");
    const dragTableRef = ref({
      $el: document,
    });
    const appid = ref(2);
    const roleMenuDialogRef = ref<HTMLElement | any>();
    const editMenuRef = ref<HTMLElement | any>();
    const setSort = () => {
      const el: unknown = dragTableRef.value.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      sortable.value = Sortable.create(el as HTMLElement, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        // fallbackOnBody: true,
        // dragoverBubble: true,
        // forceFallback: true,
        setData: function (dataTransfer: any) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData("Text", "");
          console.log("dataTransfer :>> ", dataTransfer);
        },
        onEnd: (evt: any) => {
          getIndexs(evt, "newIndex");
          const newIndexs = getIndexs(evt, "newIndex");
          const oldIndexs = getIndexs(evt, "oldIndex");
          console.log("evt :>> ", oldIndexs, newIndexs);
        },
      });
    };
    const getRowClassName = (e: any) => {
      return `indexs_${e.row.indexs}`;
      // return this.tableData[index].indexs
    };
    const reData = async () => {
      try {
        loading.value = true;
        const data = await treeLoadTree({ appid: appid.value });
        tableData.value = data
        console.log("tableData :>> ", tableData);
        initTableData.value = JSON.parse(JSON.stringify(data));
        await nextTick();
        setSort();
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };

    const openRoleMenuDialog = async () => {
      try {
        // 这里有bug 先跳过

        // const res = await ElMessageBox.prompt("请输入密码", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   inputPattern: /^[a-zA-Z]\w{5,17}$/,
        //   inputErrorMessage: "密码错误",
        //   inputType: "password",
        // });
        // console.log("res :>> ", res);
        // if (res.value != changeMenuPassward) return ElMessage.error("密码错误");
        // console.log("roleMenuDialogRef.value :>> ", roleMenuDialogRef.value);
        roleMenuDialogRef.value.open();
      } catch (error) {
        if (error == "cancel") return;
        if (error == "colse") return;
        ElMessage.error(error);
      }
    };
    const handleEdit = (e: MenuListResData) => {
      editMenuRef.value.open({ ...e, isEdit: true });
    };

    const handleAdd = () => {
      editMenuRef.value.open({
        isEdit: false,
        parenttreeid: "0",
      });
    };
    const handleAddChild = (e: MenuListResData) => {
      editMenuRef.value.open({
        isEdit: false,
        parenttreeid: e.treeid,
      });
    };
    const handleHidden = async (index: number, item: MenuListResData) => {
      try {
        btnHiddenLoaidng.value = true;
        const _rqData = JSON.parse(JSON.stringify(item));
        _rqData.child = _rqData.child.join(",");
        _rqData.hidden = _rqData.hidden == "true" ? "false" : "true";
        const result = await treeUpdate({ ..._rqData, appid: appid.value });
        ElMessage.success(result);
        reData();
      } finally {
        btnHiddenLoaidng.value = false;
      }
    };
    const handleOnOff = async (index: number, item: MenuListResData) => {
      try {
        btnHiddenLoaidng.value = true;
        const _rqData = JSON.parse(JSON.stringify(item));
        _rqData.child = _rqData.child.join(",");
        _rqData.state = _rqData.state == "1" ? "0" : "1";
        const result = await treeUpdate({ ..._rqData, appid: appid.value });
        ElMessage.success(result);
        reData();
      } finally {
        btnHiddenLoaidng.value = false;
      }
    };
    const deleteArrItem = (type: string, indexs: number[], index = 0) => {
      const arr = type == "initTableData" ? initTableData : tableData;
      if (!arr.value) return;
      if (indexs.length - index == 1) {
        return arr.value.splice(indexs[index], 1);
      } else if (arr.value[indexs[index]].child) {
        deleteArrItem(type, indexs, index + 1);
      }
    };
    const handleRemove = async (index: number, item: MenuListResData) => {
      console.log(index);
      await ElMessageBox.confirm("确定删除当前菜单项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      try {
        btnRemoveLoaidng.value = true;
        const result = await treeRemove({
          treeid: item.treeid,
          appid: appid.value,
        });
        ElMessage.success(result);
        deleteArrItem("tableData", JSON.parse(JSON.stringify(item.indexs)));
        deleteArrItem("initTableData", JSON.parse(JSON.stringify(item.indexs)));
      } finally {
        btnRemoveLoaidng.value = false;
      }
    };
    onMounted(() => {
      reData();
    });
    const handelChange = async () => {
      await nextTick();
      reData();
    };
    return {
      dragTableRef,
      roleMenuDialogRef,
      openRoleMenuDialog,
      btnRemoveLoaidng,
      btnHiddenLoaidng,
      getRowClassName,
      loading,
      editMenuRef,
      handleEdit,
      handleAdd,
      handleAddChild,
      handleHidden,
      tableData,
      handleOnOff,
      handleRemove,
      reData,
      appid,
      handelChange,
    };
  },
  methods: {},
});
</script>
<style lang="scss" scoped>
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}
</style>
<style lang='scss' scoped>
.container {
  padding: 16px;
  header {
    padding: 32px 0;
  }
}
</style>