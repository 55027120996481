<template>
  <el-dialog :destroy-on-close="true" :title="account+`钱包流水`" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <div class="container">
      <header>
        <el-select @change="handelChange" class="header-el-selector" clearable placeholder="是否为正式" v-model="search.fortest">
          <el-option :value="true" label="测试"></el-option>
          <el-option :value="false" label="正式"></el-option>
        </el-select>
        <el-date-picker
          @change="reData()"
          align="right"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          v-model="search.timeBN"
        ></el-date-picker>
      </header>

      <el-table
        :data="list"
        height="560px"
        stripe
        style="width: 100%"
      >
       <el-table-column align="center" label="正式/测试" min-width="20">
        <template #default="scope">
          <color-icon type="icon-ceshi" v-if="search.fortest" />
          <color-icon type="icon-zhengshiban" v-else />
        </template>
        </el-table-column>  
        <el-table-column align="center" label="费用" min-width="20" prop="amount"></el-table-column>
        <el-table-column align="center" label="变更前" min-width="20" prop="oldAmount"></el-table-column>
        <el-table-column align="center" label="变更后" min-width="20" prop="newAmount"></el-table-column>
        <el-table-column align="center" label="交易信息" min-width="30" prop="remark"></el-table-column>
        <el-table-column align="center" label="交易时间" min-width="40" prop="gmtCreate" ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-size="size"
        :page-sizes="[10, 30, 50, 100]"
        :total="totalCount"
        @current-change="pageChange"
        @size-change="handleLimitChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>
<script lang = "ts" >
import { userconsumewalletwalletbillModel, userconsumewalletwalletbill } from '@/api/Financial/walletBill'
import { defineComponent, ref } from 'vue'
import dayjs from 'dayjs'
let sort = 'desc'
let order = 'gmt_create'
let userId = ''
export default defineComponent({
  name: 'v-dialog',
  setup() {
    const visible = ref(false)
    const list = ref<userconsumewalletwalletbillModel[]>([])
    const account = ref("");
    const current = ref(1)
    const size = ref(10)
    const totalCount = ref(0)
    const search = ref({
      fortest: false, //是否用于测试，如：true 是 false 否
      timeBN: ''
    })
    const handleClose = () => {
      visible.value = false
    }
    const open = (_userId: string,_account: string) => {
      visible.value = true
      userId = _userId
      account.value=_account;
      reData()
    }
    const reData = async () => {
      let rqData : any = {current:current.value,size:size.value,order:order,sort:sort}
      let entity : any = {userId:userId,fortest:search.value.fortest};
      if (search.value.timeBN) {
        entity.startTime = dayjs(search.value.timeBN[0]).format("YYYY-MM-DD HH:mm:ss") 
        entity.endTime =  dayjs(search.value.timeBN[1]).format("YYYY-MM-DD HH:mm:ss") 
      }
      rqData={...rqData,entity:{...entity}}
      console.log('请求参数',rqData)
      const { total, rows } = await userconsumewalletwalletbill(rqData)
      totalCount.value = total
      list.value = rows
    }
    

    const handelChange = () => {
      current.value=1;
      reData();
    }

    const pageChange = (val:number) => {
      current.value=val;
      reData();
    }

    const handleLimitChange = (val:number) =>{
      size.value=val;
      reData();
    }

   
    return {
      visible,
      account,
      handleClose,
      open,
      list,
      current,
      totalCount,
      size,
      search,
      handelChange,
      sort,
      order,
      reData,
      pageChange,
      handleLimitChange,
    }
  },
})
</script>
<style lang = "scss" scoped >
</style>