<template>
  <div class="container">
    <header>
      <el-input
        @keyup.enter="handelChange"
        class="input-with-select"
        placeholder="请输入搜索内容"
        v-model="mixin_select_search.search[searchSelect.search]"
      >
        <template #prepend>
          <el-select @change="changSearch('search')" placeholder="请选择" v-model="searchSelect.search">
            <el-option
              :key="item"
              :label="mixin_select_search_label.search[item]"
              :value="item"
              v-for="item of Object.keys(mixin_select_search.search)"
            ></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button @click="handelChange" icon="el-icon-search"></el-button>
        </template>
      </el-input>
      <el-select @change="handelChange" clearable placeholder="设备类型" class="header-el-selector" v-model="mixin_router_search.productid">
        <!-- <el-option label="二代小魔柜" value="MGS4G2"></el-option>
        <el-option label="一代小魔柜" value="MGS4G"></el-option>
        <el-option label="雨伞架" value="UMHD"></el-option>
        <el-option label="便民柜" value="CV01"></el-option> -->
        <el-option :key="item.productid" :label="item.name" :value="item.productid" v-for="item of sheareProductInfo"></el-option>
     </el-select>
      
      <el-select @change="handelChange" clearable placeholder="在线状态" class="header-el-selector" v-model="mixin_router_search.online">
         <el-option label="在线" value="1"></el-option>
         <el-option label="离线" value="0"></el-option>
      </el-select>
      <el-select @change="handelChange" clearable placeholder="有无故障" class="header-el-selector" v-model="mixin_router_search.orderby">
         <el-option label="有故障" value="faultnum"></el-option>
         <el-option label="正常" value="normalnum"></el-option>
      </el-select>
      <!-- <el-select @change="handelChange" clearable placeholder="城市选择" class="header-el-selector" v-model="mixin_router_search.cityareacode">
        <el-option :key="item.cityareacode" :label="item.name" :value="item.cityareacode" v-for="item of cityInfoList"></el-option>
      </el-select> -->
      <el-date-picker
        @change="handelChange"
        align="right"
        class="header-select"
        end-placeholder="开始时间"
        range-separator="至"
        start-placeholder="结束时间"
        type="datetimerange"
        unlink-panels        
        v-model="mixin_router_search.careateBN"
      ></el-date-picker>
      <el-button @click="handleAdd" icon="el-icon-plus" type="primary" style="margin-left: 16px;">添加</el-button>
      <my-select-city :maxLevel="3" @cityCodeChange="cityCodeChange"></my-select-city>
    </header>
    <!-- <el-table :data="list" height="80vh" style="width: 100%" v-if="list" :cell-style="getCellClass"> -->
    <el-table :data="list" height="80vh" style="width: 100%" v-if="list">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form class="table-expand" inline label-position="left" label-width="120px">
             <!-- <el-form-item label="地址" v-if="scope.row.address">
                {{scope.row.address}}
                <my-copy :data="scope.row.address" />
            </el-form-item> -->
            <el-form-item label="蓝牙" v-if="scope.row.mac">    
              {{scope.row.mac}}         
              <my-copy :data="scope.row.mac" />
            </el-form-item>
             <el-form-item label="4G" v-if="scope.row.deviceid">    
              {{scope.row.deviceid}}        
              <my-copy :data="scope.row.deviceid" /> 
              <!-- <el-button  @click="handleSync(scope.row.deviceid,scope.row.panelequipmentsn)"  :loading="syncloading" size="mini" type="primary" v-if="scope.row.deviceid">同步机柜</el-button> -->
            </el-form-item>
            <el-form-item label="型号" v-if="scope.row.productmodel">    
              {{scope.row.productmodel}}         
            </el-form-item>
            <el-form-item label="场所ID" v-if="scope.row.placeid">
              <my-click-data-to
                :data="scope.row.placeid"
                :params="[{search_placeid:scope.row.placeid}]"
                url="/share/place"
              ></my-click-data-to>
              <my-copy :data="scope.row.placeid" />
            </el-form-item>
            <el-form-item label="产品ID" v-if="scope.row.productid">
              <my-click-data-to
                :data="scope.row.productid"
                :params="[{search_productid:scope.row.productid}]"
                url="/share/product"
              ></my-click-data-to>
              <my-copy :data="scope.row.productid" />
            </el-form-item>
            <el-form-item label="其它操作">
              <el-button :disabled="!scope.row.deviceid" @click="handleSync(scope.row.deviceid,scope.row.panelequipmentsn)" size="mini" type="primary">同步机柜</el-button>
              <!-- <el-button :disabled="!scope.row.deviceid" @click="toPath(`/oneNet/report?deviceid=${scope.row.deviceid}`)" size="mini" type="primary">机柜上报消息</el-button> -->
              <!--<el-button :disabled="!scope.row.deviceid" @click="toPath(`/oneNet/send?deviceid=${scope.row.deviceid}`)" size="mini" type="primary">机柜下发消息</el-button> -->
              <!-- <el-button :disabled="!scope.row.deviceid" @click="handleFourthgcommand(scope.row.deviceid)" size="mini" type="primary">机柜下发消息</el-button> -->
              <el-button :disabled="!scope.row.deviceid" @click="handle4GUpdate(scope.row.deviceid,scope.row.panelequipmentsn)" size="mini" type="primary">4G上报消息</el-button>
              <el-button :disabled="!scope.row.deviceid" @click="handle4GSend(scope.row.deviceid,scope.row.panelequipmentsn)" size="mini" type="primary">4G下发消息</el-button>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="center" label="图片" width="120" >
        <template #default="scope"> 
            <div >
              <el-image :src="scope.row.placeImage" v-if="scope.row.isHasPic" style="width: 80px;" fit="scale-down" :lazy="true"> </el-image>
              <el-image :src="require('@/assets/img/shop.png')" style="width: 80px;" fit="scale-down" v-else></el-image>
            </div>   
        </template>
      </el-table-column>
      <el-table-column label="SN" width="120">
        <template #default="scope">
          {{scope.row.panelequipmentsn}}
          <hover-show-qr
            :key="scope.row.panelequipmentsn"
            :logo="`${scope.row.productmodel}`"
            :text="scope.row.panelequipmentsn"
          ></hover-show-qr>
        </template>
      </el-table-column>
       <el-table-column label="名称" width="210" >
        <template #default="scope">        
          <span @click="handlePlaceDetails(scope.row.placeid,scope.row.name)" style="margin-left: 16px;" class="span-click">{{scope.row.name}}</span>
          <my-copy :data="scope.row.name" />
        </template>
      </el-table-column>
      <el-table-column label="地址" width="240" >
        <template #default="scope">
          {{scope.row.address}}
          <my-copy :data="scope.row.address" />
        </template>
      </el-table-column>
       <el-table-column align="center" label="小魔夹数量" width="160">
        <template #header>
          <el-tag type="success">有</el-tag>
          <!-- <el-tag type="info">空</el-tag> -->
          <el-tag type="danger">故障</el-tag>
          <el-tag type="warning">私有</el-tag>
        </template>
        <template #default="scope">
          <el-tag type="success">{{scope.row.normalnum}}</el-tag>
          <!-- <el-tag type="info">{{12-scope.row.supplynum-scope.row.faultnum-scope.row.privatizednum}}</el-tag> -->
          <el-tag type="danger">{{scope.row.faultnum||0}}</el-tag>
          <el-tag type="warning">{{scope.row.num||0}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" width="120">
        <template #default="scope">
          <!-- <span
            :class="'icon-panel-'+scope.row.productmodel"
            class="iconfont"
            v-my-color="scope.row.articlenum>0?'primary':'info'"
          ></span>&ensp; -->
          <span v-if='scope.row.productmodel=="mgs4g"'>一代机柜</span>
          <span v-if='scope.row.productmodel=="MGS4G2"'>二代机柜</span>
          <span v-if='scope.row.productmodel=="MGS4G3"'>二代机柜</span>
          <span v-if='scope.row.productmodel=="UMHD"'>伞架</span>
          <span v-if='scope.row.productmodel=="CV01"'>便民柜</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="120">
        <template #default="scope">
           <el-tag type="success" v-if="scope.row.online==1">在线 - {{scope.row.onlineLongText}}</el-tag>
           <el-tag type="warning" v-if="scope.row.online==0">离线 - {{scope.row.lastofflineText}}</el-tag>
        </template>
      </el-table-column>          
      <el-table-column label="商家联系人" align="center" width="120">
        <template #default="scope">
          {{scope.row.contactname}}
        </template>
      </el-table-column>
      <el-table-column label="商家电话" align="center" width="120">
        <template #default="scope">
          {{scope.row.contactmobile}}
        </template>
      </el-table-column>
      <el-table-column label="在线时长" align="center" width="160">
        <template #default="scope">
          {{scope.row.onlineTimeLongText}}
        </template>
      </el-table-column>
      <el-table-column label="离线时长" align="center" width="160">
        <template #default="scope">
          {{scope.row.offlineTimeLongText}}
        </template>
      </el-table-column>
      <el-table-column label="租借次数" align="center" width="120">
        <template #default="scope">
          {{scope.row.borrowSecond}}
        </template>
      </el-table-column>
      <el-table-column label="归还次数" align="center" width="120">
        <template #default="scope">
          {{scope.row.returnSecond}}
        </template>
      </el-table-column>
      <el-table-column label="环比增长" align="center" width="120">
        <template #default="scope">
          待开发
        </template>
      </el-table-column>
      <el-table-column label="维修专员" align="center" width="160">
        <template #default="scope">
          {{scope.row.repairmanName}}
        </template>
      </el-table-column>
      <el-table-column label="运营专员" align="center" width="160">
        <template #default="scope">
          {{scope.row.operatorName}}
        </template>
      </el-table-column>
      <el-table-column label="运营团队" align="center" width="160">
        <template #default="scope">
          {{scope.row.operationname}}
        </template>
      </el-table-column>
      <el-table-column label="投放时间" align="center" width="180">
        <template #default="scope">
          {{scope.row.createTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作"  fixed="right" width="240">
        <template #header>
          <el-button @click="handleExport()" icon="el-icon-tickets" size="mini" type="primary">导出</el-button>
        </template>
        <template #default="scope" >
          <!-- <span @click="handleDetails(scope.row.panelequipmentsn,scope.row)" class="span-click">机柜设备</span>
          <span @click="handle4GUpdate(scope.row.deviceid,scope.row.panelequipmentsn)" style="margin-left: 16px;" class="span-click">4G上报消息</span>
          <span @click="handle4GSend(scope.row.deviceid,scope.row.panelequipmentsn)" style="margin-left: 16px;" class="span-click">4G下发消息</span>
          <span @click="handleRemove(scope.$index,scope.row.panelequipmentsn)"  style="margin-left: 16px;" class="span-click">删除</span> -->
          <!-- <el-button  @click="handleSync(scope.row.deviceid)"  :loading="syncloading" size="mini" type="primary" v-if="scope.row.deviceid">同步机柜</el-button> -->
          <!-- <el-button @click="handleShowDevice(scope.row.panelequipmentsn,scope.row.productmodel)" size="mini" type="primary">机柜内设备</el-button> -->
          <!-- <el-button @click="handleShowDevice(scope.row)" size="mini" type="primary">机柜内设备</el-button> -->
          <el-button @click="handleDetails(scope.row.panelequipmentsn,scope.row)" size="mini" type="primary">机柜设备</el-button>        
          <!-- <el-button @click="handleEidt(scope.$index,scope.row)" size="mini" type="primary">编辑</el-button>         -->
          <!-- <el-button :disabled="!scope.row.deviceid" @click="handle4GUpdate(scope.row.deviceid,scope.row.panelequipmentsn)" size="mini" type="primary">4G上报</el-button>
          <el-button :disabled="!scope.row.deviceid" @click="handle4GSend(scope.row.deviceid,scope.row.panelequipmentsn)" size="mini" type="primary">4G下发</el-button> -->
          <el-button
            :loading="btnRemoveLoaidng"
            @click="handleRemove(scope.$index,scope.row.panelequipmentsn)"
            size="mini"
            type="danger"
          >删除</el-button>                    
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 30, 50, 100]"
      :total="total"
      @current-change="onPageChange"
      @size-change="handleLimitChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
  <v-add-dialog @success="addSuccess" ref="addDialogRef"></v-add-dialog>
  <v-device-dialog ref="deviceDialogRef"></v-device-dialog>
  <!-- <v-components-dialog ref="componentsDialogRef"></v-components-dialog> -->
  <!-- <showDeviceDrawerVue ref=showDeviceDrawerRef></showDeviceDrawerVue> -->
  <fourthgmsg-dialog ref="fourthgmsgDialogRef"></fourthgmsg-dialog>
  <fourthgcommand-dialog ref="fourthgcommandDialogRef"></fourthgcommand-dialog>
  <cabinetDatailsDialog ref ="cabinetDetailsDialogRef"></cabinetDatailsDialog>
  <oneNetReportDialog ref="oneNetReportDialogRef"></oneNetReportDialog>
  <oneNetSendDialog ref="oneNetSendDialogRef"></oneNetSendDialog>
  <placeDetailsDialog ref="placeDetailsDialogRef"></placeDetailsDialog>
  <exportDialog @export="exportFile" ref="exportDialogRef"></exportDialog>
</template>
<script lang="ts">
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import {
  getPanelList,
  GetContainInfoList,
  PanelListRes,
  removePanel,
  getcrc,
  getContainInfoList,
  getContainList,
  exportContainInfoList,
} from '@/api/Share/Panel'
import addDialog from './components/addDialog.vue'
import deviceDialog from './components/deviceDialog.vue'
import componentsDialog from './components/componentsDialog.vue'
import showDeviceDrawerVue from './components/showDeviceDrawer.vue'
import fourthgmsgDialog from '../components/fourthgmsgDialog.vue'
import fourthgcommandDialog from '../components/fourthgcommandDialog.vue'
import cabinetDatailsDialog from '@/components/device/cabinetDatailsDialog.vue'
import oneNetReportDialog from '@/components/device/oneNetReportDialog.vue'
import oneNetSendDialog from '@/components/device/oneNetSendDialog.vue'
import placeDetailsDialog from '@/components/place/placeDetailsDialog.vue'
import exportDialog from "@/components/export/exportDialog.vue";
import { ElMessage } from 'element-plus'
import myExport from '@/utils/exprotExcel'
import { getPanelProductInfo ,getCityInfoList } from '@/api/util'
import { userStore } from '@/store/modules/user'
import { useRouter } from "vue-router";
import mySelectCity from '@/components/cityList/index.vue'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'Name',
  components: {
    'v-add-dialog': addDialog,
    'v-device-dialog': deviceDialog,
    'v-components-dialog': componentsDialog,
    showDeviceDrawerVue,
    fourthgmsgDialog,
    fourthgcommandDialog,
    mySelectCity,
    cabinetDatailsDialog,
    oneNetReportDialog,
    oneNetSendDialog,
    placeDetailsDialog,
    exportDialog,
  },

  setup() {
    const loading = ref(false)
    const syncloading = ref(false)
    const btnRemoveLoaidng = ref(false)
    const router = useRouter();
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<GetContainInfoList[]>([])
    const addDialogRef = ref<any>()
    const deviceDialogRef = ref<any>()
    const componentsDialogRef = ref<any>()
    const showDeviceDrawerRef = ref<any>()
    const fourthgmsgDialogRef = ref<any>()
    const fourthgcommandDialogRef = ref<any>()
    const cabinetDetailsDialogRef = ref<any>()  
    const oneNetReportDialogRef = ref<any>()
    const oneNetSendDialogRef = ref<any>()
    const placeDetailsDialogRef = ref<any>();
    const exportDialogRef = ref<any>();
    const searchSelect = ref({
      search: 'panelequipmentsn',
    })
    const sheareProductInfo = getPanelProductInfo()  //共享设备类型
    const cityInfoList = getCityInfoList();       //城市集合
    const mixin_select_search = ref({
      search: {
        panelequipmentsn: '',
        name:'',
        contactname: '',
        contactmobile: '',
        repairmanname:'',
        operationname:'',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        panelequipmentsn: '设备SN',
        name:'场所名称',    
        contactname: '联系人',
        contactmobile: '联系人帐号',
        repairmanname: '维修员',
        operationname:'运营团队'
      },
    })
   const toPath = (path: string) => {
      router.push(path);
    };
    const mixin_router_search = ref({ status: '', productid: '',cityareacode:'',districtareacode:'',online:'',orderby:"",careateBN:""})
    const reData = async (rq?: any) => {
      console.log("开始搜索")
      console.log(rq)
      try {
        loading.value = true
        let rqData: any = { currentPage: page.value, size: limit.value ,fortest: false}
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          rq.operationId=userStore.getCurrentRole.operationId
        }
        if (rq && JSON.stringify(rq) !== '{}') {
          if(rq.orderby){
            rq.sort='desc'
          }
          if(rq.online&&rq.online=="0"){
            rq.sort='desc';
            rq.orderby='offlineTime'
          }
          if (rq.careateBN) {
            rq.startTime = dayjs(rq.careateBN[0]).format("YYYY-MM-DD HH:mm:ss")
            rq.endTime = dayjs(rq.careateBN[1]).format("YYYY-MM-DD HH:mm:ss")
            delete rq.careateBN
          }
          rqData={...rqData,...rq}
        }
        // rqData={...rqData,...mixin_router_search.value}
        // Object.keys(rqData).map((key)=>{
        //   if(rqData[key]==''|| rqData[key]==null ||rqData[key] == undefined){
        //       delete rqData[key]
        //   }
        // }) 
        //const res = await getContainInfoList(rqData)
        const res = await getContainList(rqData)
        total.value = res.total
        list.value = res.list
      } finally {
        loading.value = false
      }
    }
    const handleExport = async () => {
      exportDialogRef.value.open('机柜统计表')
      // const excel = new myExport('小魔柜列表')
      // const _list = list.value.map((v) => {
      //   const _data = {
      //     机柜SN: v.panelequipmentsn,
      //     机柜mac:v.mac,
      //     商户名: v.name,
      //     联系人:v.contactname,
      //     联系电话:v.contactmobile,
      //     正常数: v.normalnum,
      //     故障数: v.faultnum||0,
      //     私有数: v.num||0,
      //     空仓数: v.emptynum,
      //     当前位置: v.address,
      //     在线离线: v.online ? '在线' : '离线',
      //     机柜名称: v.productname,
      //     城市编号: v.cityareacode,
      //     县区编号: v.districtareacode,
      //     在线时间: '无',
      //     在线时长: '无',
      //     离线时间: '无',
      //     离线时长: '无',
      //   }
      //   if (v.online) {
      //     _data.在线时间 = v.lastonlineDate
      //     _data.在线时长 = v.onlineLongText
      //   } else {
      //     _data.离线时间 = v.lastofflineDate
      //     _data.离线时长 = v.lastofflineText
      //   }
      //   return _data
      // })
      // excel.addWorksheet({ headers: ['小魔柜列表'], list: _list })
      // excel.export()
    }

    const exportFile = async(name:string) =>{
      try {
        let searchData: any = {currentPage: page.value, size: limit.value ,fortest: false}
        if(userStore.getCurrentRole.name=='ROLE_OPERATOR'){
          searchData.operationId=userStore.getCurrentRole.operationId
        }
        let rqData: any = {fileName:'cabientList'}
        
        searchData={...searchData,...mixin_router_search.value,...mixin_select_search.value.search}
      
        if (searchData.careateBN) {
          rqData.startTime = dayjs(searchData.careateBN[0]).format("YYYY-MM-DD HH:mm:ss") 
          rqData.endTime =  dayjs(searchData.careateBN[1]).format("YYYY-MM-DD HH:mm:ss") 
        }
        if(searchData.orderby){
          searchData.sort='desc'
        }
         if(searchData.online&&searchData.online=="0"){
          searchData.sort='desc';
          searchData.orderby='offlineTime'
        }
        delete searchData.careateBN
        Object.keys(searchData).forEach((key:any) => {
          if(searchData[key]){
            rqData[key]=searchData[key]
          }
        })
        

        const res = await exportContainInfoList(rqData)
       
        const blob = new Blob([res.data]);
     
        saveAs(blob, name)
       
      } finally {
       
      }
    }

    const { mixinReRouter, onPageChange, handleLimitChange, changSearch } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    const handleAdd = () => {
      addDialogRef.value.open(-1, {
        placeid: "",
      })
    }
    const handleEidt = (index: number, row: any) => {
      addDialogRef.value.open(index, row)
    }
    const addSuccess = () => {
      reData()
    }
    const handleRemove = async (index: number, panelequipmentsn: string) => {
      try {
        btnRemoveLoaidng.value = true
        const res = await removePanel({
          panelequipmentsn,
        })
        list.value.splice(index, 1)
        ElMessage.success(res.message)
      } finally {
        btnRemoveLoaidng.value = false
      }
    }
    // const handleShowDevice = (item:any) => {
    //   showDeviceDrawerRef.value.open(item)
    // }

    const handleShowDevice = (sn:string,prefix: string) => {
      deviceDialogRef.value.open(sn,prefix)
    }
    const handleShowComponenet = (sn: string) => {
      componentsDialogRef.value.open(sn)
    }
    const handleSync = async (deviceid: string,sn:string) => {
      try {
        syncloading.value = true
        const res=await getcrc({ deviceid })
        ElMessage.success(sn+" "+res);
      } finally {
        syncloading.value = false
      }
      //reData()
    }
    const cityCodeChange = (code: string) => {
      if(code&&code.length==4){
        mixin_router_search.value.cityareacode=code;
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }else if(code&&code.length==6){
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=code
        page.value = 1
        mixinReRouter()
      }else{
        mixin_router_search.value.cityareacode='';
        mixin_router_search.value.districtareacode=''
        page.value = 1
        mixinReRouter()
      }
      
    }
    const handleFourthgmsg = (deviceid: string) => {
      fourthgmsgDialogRef.value.open(deviceid)
    }
    const handleFourthgcommand = (deviceid: string) => {
      fourthgcommandDialogRef.value.open(deviceid)
    }
    const handleDetails = (sn:string,item:any) => {
  
      cabinetDetailsDialogRef.value.open(sn,item)
    }
    const getCellClass =(obj:any) =>{
      if(obj.columnIndex==1){
        return "padding:6px 0 0 0;"
        }else{
          return ""
        }
    }

    /**
     * 机柜上报消息 
     */
    const handle4GUpdate = (deviceId:string,sn:string) =>{
      oneNetReportDialogRef.value.open(deviceId,sn)
    }    
    /**
     * 机柜下发消息
     */
    const handle4GSend = (deviceId:string,sn:string) =>{
      oneNetSendDialogRef.value.open(deviceId,sn)
    }

    const handlePlaceDetails = (placeId:string,name:String) =>{
      placeDetailsDialogRef.value.open(placeId,name)
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      handleAdd,
      handleEidt,
      handleRemove,
      addSuccess,
      addDialogRef,
      btnRemoveLoaidng,
      handleShowDevice,
      deviceDialogRef,
      componentsDialogRef,
      showDeviceDrawerRef,
      cabinetDetailsDialogRef,
      oneNetReportDialogRef,
      oneNetSendDialogRef,
      placeDetailsDialogRef,
      handleSync,
      handleDetails,
      handleShowComponenet,
      changSearch,
      sheareProductInfo,
      cityInfoList,
      syncloading,
      handleFourthgmsg,
      fourthgmsgDialogRef,
      fourthgcommandDialogRef,
      handleFourthgcommand,
      toPath,
      handleExport,
      cityCodeChange,
      getCellClass,
      handle4GUpdate,
      handle4GSend,
      handlePlaceDetails,
      exportDialogRef,
      exportFile,
    }
  },
})
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  .input-with-select {
    margin-right: 24px;
    width: 320px;
    /deep/.el-input {
      width: 120px;
    }
    ~ * {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .header-el-selector {
      width:120px ;
      margin-right: 12px;
      /deep/.el-input{
         width: 120px;
      }
    }
  .el-table{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
    }
  }  
  .span-click {
    color: #409eff;
    cursor:pointer;
  }
}
</style>