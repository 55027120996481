
import { defineComponent, ref, nextTick } from "vue";
import { investorList, InvestorListListRes } from "@/api/User/investor";
import { add } from "@/api/ERP/user";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const visible = ref(false);
    const loading = ref(false);
    const btnSumitLoading = ref(false);
    const nomore = ref(false);
    const sharchType = ref("investorname");
    const searchData = ref("");
    const limit = ref(10);
    const page = ref(1);
    const searchList = ref<InvestorListListRes[]>([]);
    const selectData = ref({
      name: "",
      account: "",
      userid: "",
    });
    let mCb: any;
    const handleClose = () => {
      visible.value = false;
      mCb = null;
    };
    const onSearch = (e: any) => {
      selectData.value = {
        name: e.investorname,
        account: e.investoraccount,
        userid: e.investoruserid,
      };
    };

    const loadData = async (re = false, queryString?: any) => {
      //   if (!sharchType.value) return ElMessage.error("请选择搜索类型");
      //   if (!searchData.value) return ElMessage.error("请输入搜索内容");
      if (re) {
        page.value = 1;
        searchList.value = [];
      }
      try {
        loading.value = true;
        const reData: any = {
          limit: limit.value,
          page: page.value,
        };
        reData[sharchType.value] = searchData.value;
        const { total, result } = await investorList(reData);
        const _result = result.map((v) => {
          v.value = v.investorname;
          return v;
        });
        searchList.value.push(..._result);
        if (page.value * limit.value >= total) nomore.value = true;
        await nextTick();
        if (!result.length) return;
        mCb && mCb(searchList.value);
      } finally {
        loading.value = false;
      }
    };
    const handleSearch = async () => {
      await loadData(true);
    };

    const loadmore = async () => {
      if (nomore.value) return;
      page.value++;
      await loadData();
    };

    const querySearchAsync = async (queryString: any, cb: any) => {
      if (!mCb) mCb = cb;
      await loadData(true, queryString);
    };
    const open = async () => {
      visible.value = true;
      await loadData(true);
    };

    const submit = async () => {
      try {
        btnSumitLoading.value = true;
        const res = await add(selectData.value || {});
        emit("success", res.data);
        ElMessage.success(res.message);
        handleClose();
        console.log("res :>> ", res);
      } finally {
        btnSumitLoading.value = false;
      }
    };

    return {
      visible,
      open,
      handleClose,
      sharchType,
      searchData,
      querySearchAsync,
      handleSearch,
      loadmore,
      onSearch,
      selectData,
      submit,
    };
  },
});
