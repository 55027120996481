import { defHttp } from "@/utils/http/axios";
import { ListRes, ListReq, RequestRes } from "../../types/requestBaseType"
import dayjs from "dayjs"


export interface WechatMerchantTransfer {
    partnertradeno: string,
    mchappid: string,
    mchid: string,
    deviceinfo: string,
    noncestr: string,
    sign: string,
    openid: string,
    checkname: string,
    reusername: string,
    amount: number,
    desc: string,
    spbillcreateip: string,
    returncode: string,
    mcreturnmsghappid: string,
    resultcode: string,
    errcode: string,
    errcodedes: string,
    paymentno: string,
    paymenttime: string,
    creationdate: string,
    modificationdate: string,
}
/**
 * 获取用户角色
 */
export async function getWechatMerchantTransfer(params: ListReq): Promise<ListRes<WechatMerchantTransfer>> {
    params.token = true
    const res = await defHttp.request<ListRes<WechatMerchantTransfer>>(
        {
            url: `/wechatmerchanttransfer/list`,
            params
        }, {
        prefix: "/shared"
    }
    );
    res.records = res.records.map(v => {
        v.creationdate = dayjs(v.creationdate).format("YYYY-MM-DD HH:mm:ss")
        v.modificationdate = dayjs(v.modificationdate).format("YYYY-MM-DD HH:mm:ss")
        v.paymenttime = dayjs(v.paymenttime).format("YYYY-MM-DD HH:mm:ss")
        v.modificationdate = dayjs(v.modificationdate).format("YYYY-MM-DD HH:mm:ss")
        return v
    })
    return res
}