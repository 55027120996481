
import { defineComponent, ref } from "vue";
import { CouponModel, getUserCouponList } from "@/api/Financial/coupon";
export default defineComponent({
  setup() {
    const loading = ref(false);
    const limit = ref(10);
    const page = ref(1);
    const total = ref(0);
    const usedStatus = ref('');
    const list = ref<CouponModel[]>([]);

    const open = () =>{
      console.log("调用打开")
    }

    const reData = async (status:string) => {
      console.log("查询状态=",status)
      usedStatus.value=status
      try {
        loading.value = true;
        let rqData: any = { current: page.value, size: limit.value,order:'gmtCreate',sort:'desc',usedStatus: status};
        const res = await getUserCouponList(rqData);
        console.log("优惠卷记录 :>> ", res.records);
        list.value = res.rows;
        total.value = res.total;
      } finally {
        loading.value = false;
      }
    };

    const onPageChange = () =>{

    }
    const handleLimitChange = () =>{

    }
    return {
      list,
      loading,
      page,
      total,
      limit,
      reData,
      open,
      onPageChange,
      handleLimitChange,
    };
  },
})
