import { ref } from "vue"
import { increaseLastAndCurrentOfDayAndMonth } from "@/api/Index/platfrom";
export default function getUserGrowth() {
    const userGrowth = ref({
        data: {
            columns: ["name", "value"],
            rows: [
                {
                    name: "今天",
                    value: 0,
                },
                {
                    name: "昨天",
                    value: 0,
                },
            ],
        },
        type: 1,
        title: "新增用户",
        box1: {
            label: "本月新增",
            value: 0,
        },
        box2: {
            label: "上月新增",
            value: 0,
        },
    });
    const reUserGrowth = async () => {
        const res = await increaseLastAndCurrentOfDayAndMonth();
        userGrowth.value.data = {
            columns: ["name", "value"],
            rows: [
                {
                    name: "今天",
                    value: res.today,
                },
                {
                    name: "昨天",
                    value: res.yesterday,
                },
            ],
        }
        userGrowth.value.box1.value = res.thisMonth;
        userGrowth.value.box2.value = res.lastMonth;
    };
    return {
        reUserGrowth,
        userGrowth
    }
}