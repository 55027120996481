
import { defineComponent, nextTick, readonly, ref } from "vue";
import {
  investmentCabinetOrder,
  investmentorderUpdate,
} from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
import { userStore } from "@/store/modules/user";
import { operateModel, operateListHttp, removeOperateHttp} from '@/api/Operate/index'
import { userList } from "@/api/User/index"
import { userRoleList } from '@/api/User/role'
let index: any; // 编辑的index
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {
    const searchUsertype = ref("phone");
    const visible = ref(false);
    const activeStep=ref(1);
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const orderId = ref("");
    const roleArray = ref<any>([]);
    const userDataRef = ref<any>({});
    const selfDataRef = ref<any>({});
    const selectDataRef = ref<any>({});
    const platformDataRef = ref<any>({});

    const userData = ref({
      name:'',
      teamname:'',
      operationid:'',

    });
    const selectData = ref({
      articleproductid: "9775f7dd993646eaa4977537b0735e8f",
      launchmode: "PLATFORM",
      investortype: "user",
      fortest: false,
      freegift: true,
      operationid:'',
      salesmanuserid:''
    });
    const selfData = ref({
      mailingcontact: "",
      mailingnumber: "",
      mailingaddress: "",      
    });
    const platformData = ref({
      articlenum: 10,
      singleamount: 0.0,
    });
    const rules = readonly({
      articlenum: [Rules.required()],
    });

    const open = (row: any, _index: number) => {
      visible.value = true;
      index = _index;
    };
    const onCheckInvestor = async () => {
      if(userData.value.name){
        checkInvestorLoading.value=true;   
        roleArray.value=[];
        getUserRole()
      }else{
        ElMessage.success("请先输入运营员名称再查询");          
      }
    };

    const handleClose = async () => {
      selectDataRef.value.resetFields();
      selectDataRef.value.clearValidate();
      selfDataRef.value.resetFields();
      selfDataRef.value.clearValidate();
      platformDataRef.value.resetFields();
      platformDataRef.value.clearValidate();
      userDataRef.value.resetFields();
      userDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
      index = "";
      activeStep.value=1;
      roleArray.value=[];
    };

    const onNextClick = async () => {
        if(activeStep.value==1){
          if(userData.value.operationid&&userData.value.operationid!=''){
            activeStep.value=2
          }else{
            ElMessage.success("请先查询需要下单的运营员账号");          
          }
        }else if (activeStep.value==2){
          activeStep.value=3;
        }
    };
    
    const onPrevClick = async () => {
       if(activeStep.value==3){
          activeStep.value=2
        }else if (activeStep.value==2){
          activeStep.value=1;
        }
    };

    
   

    const getUserRole = async () => {
      try {
        let rqData: any = { current: "1", size: "10",order: "gmt_create",sort: "desc",entity:{name:userData.value.name}}
       
        const res = await operateListHttp(rqData)
        console.log('运营员信息=',res)
        roleArray.value = res.rows;
        userData.value.teamname=roleArray.value[0].name;
        userData.value.operationid=roleArray.value[0].id; 
        selectData.value.operationid=roleArray.value[0].id;
        checkInvestorLoading.value=false;
      }catch(err){ 
        checkInvestorLoading.value=false;
        ElMessage.success("查询失败，请确认输入的运营团体是否存在");
        
      }
    }

    const submit = async () => {
      try {
        const rqData = {
          operationid: userData.value.operationid,
          articlenum: platformData.value.articlenum,
          singleamount:platformData.value.singleamount,
          articleproductid:selectData.value.articleproductid,
          fortest:selectData.value.fortest,
          freegift:selectData.value.freegift,
          launchmode:selectData.value.launchmode,
          salesmanuserid: userStore.info.userid
        };
       
        let submitDate = {...rqData};

        if(selfData.value.mailingaddress){
          submitDate={...submitDate,...selfData.value}
        }
      
        btnSumitLoading.value = true;
        const res = await investmentCabinetOrder(submitDate);
        ElMessage.success(res.msg);
        handleClose();
        emit("success", {
          isEdit: !!orderId.value,
          data: res.record,
          index,
        });
      } finally {
        btnSumitLoading.value = false;
      }
    };
    const changeArticletype = (e: string) => {  

    };
    const changeInvestortype = (e: string) => {
      if (e == "platform") {
        
      }
    };
    return {
      searchUsertype,
      activeStep,
      rules,
      userData,
      selectData,
      platformData,
      selfData,
      visible,
      btnSumitLoading,
      checkInvestorLoading,
      open,
      userDataRef,
      selfDataRef,
      selectDataRef,
      platformDataRef,
      onCheckInvestor,
      submit,
      handleClose,
      onNextClick,
      onPrevClick,
      changeArticletype,
      changeInvestortype,
    };
  },
});
