<template>
  <el-dialog :destroy-on-close="true" title="添加普通会员" @closed="handleClose" align="center" class="dialog" v-model="visible" width="750px">
    <el-alert
      title="添加普通会员请认真确认上级，添加之后，需要扫码上级账号的二维码进行投资或者平台下单，购买一个小魔夹以上才会激活会员"
      type="warning"
      show-icon
      :closable="false">
    </el-alert>
    <el-form :model="inputData" :rules="rules" style="margin-top: 16px;" label-width="100px" ref="inputRef">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="inputData.name" style="width: 220px;"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input v-model="inputData.account" style="width: 220px;"></el-input>
      </el-form-item>
      <el-form-item label="上级账号"  prop="superioraccount">
        <el-input v-model="inputData.superioraccount" style="width: 220px;" ></el-input>
        <el-button type="primary" @click="onCheckInvestor" style="margin-left: 20px;" :loading="checkInvestorLoading" >查询投资人</el-button>
      </el-form-item>
       <el-form-item label="上级姓名" prop="superiorname">
        <el-input v-model="inputData.superiorname" style="width: 220px;" :disabled="true"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnSumitLoading" @click="submit" type="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang = "ts" >
import { defineComponent, ref, toRef } from "vue";
import { activateRole } from "@/api/User/role";
import Rules from "@/common/Rules";
import { userRoleList } from '@/api/User/role'
import { ElMessage } from "element-plus";
import { userStore } from '@/store/modules/user'
export default defineComponent({
  name: "v-dialog",
  props: ["type"],
  data() {
    return {
      rules: {
        account: [Rules.required()],
        name: [Rules.required()],
        superioraccount:[Rules.required()]
      },
    };
  },
  setup(props: any) {
    const visible = ref(false);
    const btnSumitLoading = ref(false);
    const checkInvestorLoading=ref(false);
    const type = toRef(props, "type");
    const list = ref<any>([])
    const inputRef = ref<any>("");
    const inputData = ref({
      account: "",
      name: "",
      superioraccount:"",
      superiorname:"",
    });
    const handleClose = () => {
      visible.value = false;
      inputRef.value.resetFields();
      inputRef.value.clearValidate();
    };
    const open = () => {
      visible.value = true;
      inputData.value.superiorname='';
    };
    const onCheckInvestor = async () => {
      if(inputData.value.superioraccount&&inputData.value.superioraccount!=''){
        checkInvestorLoading.value=true;
        getUserInfo(inputData.value.superioraccount)
      }else{
        ElMessage.success("请先输入上级账号");          
      }
    };
    const getUserInfo = async (phone:string) => {
      try {
        let rqData: any = { page: 1, limit: 10, investoraccount:phone }
        const res = await userRoleList(rqData, "investor")
        checkInvestorLoading.value = false
        list.value = res.result
        inputData.value.superiorname=list.value[0].investorname;
      } finally {
        checkInvestorLoading.value = false
      }
    }
    const submit = async () => {
      try {
        try {
          await inputRef.value.validate();
        } catch (error) {
          console.log(error);
          throw Error("请检查输入");
        }
        if(!inputData.value.superiorname||inputData.value.superiorname==''){
          ElMessage.warning("请先搜索上级账号");          
          return 
        }
        btnSumitLoading.value = true;
        const _data: any = {};
        _data[`${type.value}account`] = inputData.value.account.trim();
        _data[`${type.value}name`] = inputData.value.name.trim();
        _data['superioraccount'] = inputData.value.superioraccount.trim();
        _data['type'] = 'superior';
        console.log('激活参数',_data);
        const result = await activateRole(_data, type.value);
        ElMessage.success(result);
        handleClose();
      } finally {
        btnSumitLoading.value = false;
      }
    };
    return {
      visible,
      handleClose,
      open,
      btnSumitLoading,
      inputData,
      submit,
      inputRef,
      checkInvestorLoading,
      onCheckInvestor,
    };
  },
});
</script>
<style lang = "scss" scoped >
</style>