<!--主页面模板-->
<template>
  <!-- <div class="layout" v-loading.fullscreen.lock="getLoading"> -->
  <!-- <div class="layout" v-loading="true"> -->
  <div class="layout">
    <div @click="handleClickOutside" class="drawer-bg" v-if="device==='mobile'&&sidebar.opened" /><!--浏览是手机类型-->
    <v-nav-menu :isShow="isShow"></v-nav-menu> <!--侧边栏-->
    <div class="main-container">
      <!--顶部头像栏-->
      <div :style="{backgroundPosition:!sidebar.opened?'-200px 0':''}" class="home-header" v-if="isShow">
        <div class="box">
          <div class="left-box">
            <span :class="{'icon-shrink':sidebar.opened}" @click="toggleMenu" class="iconfont icon-menu" ></span>
            <v-breadcrumb></v-breadcrumb><!--导航路由提示-->
          </div>
          <div class="right-box">
            <v-user-info :isShow="isShow"></v-user-info>
          </div>
        </div>
      </div>
      <div class="header" v-else>
        <div class="left-box">
          <span :class="{'icon-shrink':sidebar.opened}" @click="toggleMenu" class="iconfont icon-menu" ></span>
          <v-breadcrumb></v-breadcrumb>
        </div>
        <div class="center-box"> 
          <el-image :src="require('@/assets/img/company_text.png')" style="width: 80%;" fit="scale-down"></el-image>
        </div>
        <div class="right-box">
          <v-user-info></v-user-info>
        </div>
      </div>

      <tags-view v-if="showTagsView" /> <!--标签-->
      <router-view :key="key" v-slot="{ Component }">
        <transition appear mode="out-in" name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
import NavMenu from './components/NavMenu/index.vue'  //侧边导航栏
import Breadcrumb from './components/Breadcrumb/index.vue' //导航路由显示
import TagsView from './components/TagsView/index.vue' //标签控件
import UserInfo from './components/header.vue'  //用户信息控件
import { defineComponent, computed, watch, ref, onMounted } from 'vue'
import { appStore } from '@/store/modules/app'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'v-layout',
  setup() {
    const isShow = ref(false)
    const getLoading = computed(() => appStore.getLoading) //获取加载 没有使用
    const device = computed(() => appStore.getDevice)   //获取浏览的设备
    const sidebar = computed(() => appStore.getSidebar)  //获取边栏
    const route = useRoute()
    const key = computed(() => route.path)  //获取导航对应的页面
    const showTagsView = computed(() => false)
    const checkPath = () => {
      if (route.path == '/home') {
        isShow.value = typeof route.name == 'string' ? ['adminIndex', 'platformIndex'].indexOf(route.name) > -1 : false
      } else {
        isShow.value = false
      }
    }
    watch(
      () => route.path,
      (v, oldV) => {
        if (v === oldV) return
        checkPath()
      }
    )
    onMounted(() => {
      checkPath()
    })
    const toggleMenu = () => {
      appStore.toggleSideBar()
    }
    const handleClickOutside = () => {
      appStore.toggleSideBar()
    }
    return {
      isShow,
      getLoading,
      device,
      sidebar,
      key,
      toggleMenu,
      handleClickOutside,
      showTagsView,
    }
  },
  components: {
    'v-nav-menu': NavMenu,
    'v-breadcrumb': Breadcrumb,
    'v-user-info': UserInfo,
    'tags-view': TagsView,
  },
})
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  scrollbar-width: none; // firefox下滚动条不显示
  -ms-overflow-style: none; // IE下滚动条不显示.
  //设置侧边栏滚动条不显示 chrome
  &::-webkit-scrollbar {
    display: none;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .menu-warp {
    z-index: 1000;
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .main-container {
    overflow: scroll;
    flex: 1;
    display: flex;
    flex-direction: column;
    .home-header {
      // width: 100%;
      min-height: 4px;
      padding: 6px 12px 6px 6px;
      // background: #001333;
      background: url('../assets/img/homebg.png');
      // background-size: 100%;
      overflow: hidden;
      .box {
        width: 100%;
        height: 45px;
        background: url('../assets/img/headerbg.png') no-repeat;
        background-size: 100% 100%;
        z-index: 999;
        top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // align-items: baseline;
        .center-box {
          text-align: center;
          font-size: 32px;
          font-weight: bold;
          margin-top: 20px;
          color: #00c0ff;
        }
        .left-box {
          display: flex;
          align-items: center;
          padding-left: 12px;
          .icon-menu {
            margin-right: 6px;
            font-size: 24px;
            color: #00f4f4;
            transition: all 0.6s;
            cursor: pointer;
          }
          .icon-shrink {
            transform: rotate(90deg);
          }
        }
      }
    }
    .header {
      display: flex;
      z-index: 999;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      min-height: 50px;
      padding: 0 16px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
      .left-box {
        display: flex;
        align-items: center;
        .icon-menu {
          margin-right: 16px;
          font-size: 24px;
          color: #444;
          transition: all 0.6s;
          cursor: pointer;
        }
        .icon-shrink {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>