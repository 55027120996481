
import { defineComponent, ref, readonly } from 'vue'
import routerChangeReData from '@/common/routerChangeReData'
import { sharedorderrenewalList } from '@/api/Order/Renewal'
import { getShareProductInfo } from '@/api/util'
export default defineComponent({
  name: 'renewal',
  setup() {
    const loading = ref(false)
    const limit = ref(10)
    const page = ref(1)
    const total = ref(0)
    const list = ref<any[]>([])
    const deviceList = getShareProductInfo()
    const searchSelect = ref({
      search: 'account',
    })
    const mixin_router_search = ref({
      fortest: false,
    })
    const mixin_select_search = ref({
      search: {
        account: '',
        articleequipmentsn: '',
        sharedorderid: '',
        renewalid: '',
      },
    })
    const mixin_select_search_label = readonly({
      search: {
        account: '账号',
        articleequipmentsn: '设备',
        sharedorderid: '订单号',
        renewalid: '续费ID',
      },
    })
    const reData = async (rq?: any) => {
      try {
        loading.value = true
        let rqData: any = { current: page.value, size: limit.value }
        if (rq && JSON.stringify(rq) !== '{}') {
          rqData = { ...rqData, entity: rq }
        }
        const res = await sharedorderrenewalList(rqData)
        list.value = res.records
        total.value = res.total
        // sharedorderrenewalList
        // sharedorderrene
      } finally {
        loading.value = false
      }
    }
    const { mixinReRouter, onPageChange, handleLimitChange } =
      routerChangeReData({
        mixin_select_search,
        mixin_router_search,
        loading,
        limit,
        page,
        reData,
        searchSelect,
      })
    const handelChange = () => {
      page.value = 1
      mixinReRouter()
    }
    return {
      mixin_select_search_label,
      mixin_select_search,
      mixin_router_search,
      searchSelect,
      list,
      loading,
      page,
      total,
      limit,
      handelChange,
      handleLimitChange,
      onPageChange,
      deviceList,
    }
  },
})
