<template>
  <div :style="{width:pieHeight,height:pieHeight}" class="ringwarp">
    <v-pie
      :option="option"
      :style="{width:pieHeight,height:pieHeight}"
      key="areaPie"
      ref="areaPieRef"
    ></v-pie>
  </div>
</template>


<script lang="ts">
import { computed, defineComponent, ref, toRef, onMounted, nextTick } from 'vue'
import pie from '@/components/echart/pie'
export default defineComponent({
  props: {
    pieHeight: {
      type: String,
      default: () => '180px',
    },
    chartData: {
      type: Array,
    },
  },
  components: {
    'v-pie': pie,
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    const option = computed(() => {
      console.log('chartDatachartDatachartDatachartDatachartData :>> ', chartData.value);
      return {
        // legend: {
        //   top: "bottom",
        // },
        color: [
          'rgb(36,106,173)',
          '#1976D2',
          'rgb(17,46,98)',
          '#00F4F4',
          '#005674',
        ],

        dataset: chartData.value,
        series: [
          {
            name: '面积模式',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            itemStyle: {
              // borderRadius: 8,
            },
            // labelLine: {
            //   show: true,
            // },
            label: {
              fontSize: 10,
              show: true,
              position: 'inside',
              formatter: '{@name}:{@num}'
            },
          },
        ],
      }
    })
    const areaPieRef = ref<any>(null)
    const reView = () => {
      areaPieRef.value.myRender()
    }
    return {
      option,
      areaPieRef,
      reView,
    }
  },
})
</script>


<style lang="scss" scoped>
.ringwarp {
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 86, 116, 0.1);
  border-radius: 50%;
  position: relative;
  .chart-container {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 36px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transform: translate(-50%, -50%);
    font-size: 12px;
    .number {
      font-weight: bold;
    }
    .now .number {
      font-weight: bold;
      color: #ff6f3e;
    }
  }
}
</style>