
import { defineComponent, nextTick, readonly, ref } from "vue";
import { confirmexpress } from "@/api/Investment/Order";
import { ElMessage } from "element-plus";
import Rules from "@/common/Rules";
export default defineComponent({
  name: "Name",
  setup(props, { emit }) {

    const visible = ref(false);

    const btnSumitLoading = ref(false);
    const orderId = ref("");
    const mailDataRef = ref<any>({});

 
    const mailData = ref({
      expressCompany: "",
      expressNo: "",
      orderid:""
    });
    const rules = readonly({
      expressCompany: [Rules.required()],
      expressNo: [Rules.required()]
    });

    const open = (row: any) => {
      console.log('订单信息',row)
      visible.value = true;
      if (!row) return;
      const { orderid } = row;
     
      orderId.value = orderid;
    };
 

    const handleClose = async () => {

      mailDataRef.value.resetFields();
      mailDataRef.value.clearValidate();
      await nextTick();
      visible.value = false;
      orderId.value = "";
      btnSumitLoading.value = false;
    };



   
    const submit = async () => {
      try {
      
        mailData.value.orderid=orderId.value;
        btnSumitLoading.value = true;
        const res = await confirmexpress({expressCompany: mailData.value.expressCompany, expressNo: mailData.value.expressNo,orderid:mailData.value.orderid})
        ElMessage.success(res);
        handleClose();
        emit("success");
      } finally {
        btnSumitLoading.value = false;
      }
    };
 
    return {
      rules,
      visible,
      btnSumitLoading,
      open,
      mailData,
      mailDataRef,
      submit,
      handleClose,

    };
  },
});
